::-webkit-scrollbar {
	width: 8px;
	height: 8px;
}
.sdmCustomLoader {
	width: 420px;
	height: 420px;
	background: url(../../assets/CustomizedLoaderSDMPlus.gif) no-repeat;
	background-size: contain;
	float: left;
}
.CustomLoadingSpinner {
	position: absolute;
	top: 16%;
	left: 33%;
}
/* Track */
::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 0px #fff;
	-webkit-border-radius: 10px;
	border-radius: 10px;
	box-shadow: inset 0 0 0px #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
	-webkit-border-radius: 10px;
	border-radius: 10px;
	background: #d6d6d6;
	-webkit-box-shadow: inset 0 0 0px #fff;
	box-shadow: inset 0 0 0px #fff;
}
::-webkit-scrollbar-thumb:window-inactive {
	background: #d6d6d6;
}

.templateDetailContainer {
	margin-top: 100px;
}
:focus {
	outline: 1px dotted #333 !important;
	box-shadow: none !important;
}
label.NotificationChkboxContainer input[type='checkbox']:focus {
	max-height: 15px !important;
	margin: 4px 0 0 3px !important;
	min-width: 15px !important;
	outline: 1px dotted #000 !important;
	outline-offset: 1px;
	min-height: auto;
	opacity: 0.5;
}
label.checkBoxContainer.filterChkboxContainer input:focus {
	opacity: 0.5;
	width: 20px !important;
	height: 20px !important;
	margin: 7px 0px -4px -12px;
}
#filtersRightPanel
	label.NotificationChkboxContainer
	input[type='checkbox']:focus {
	width: 12px !important;
	max-height: 12px !important;
	margin: 8px 0 0 3px !important;
}
#filtersRightPanel label.checkBoxContainer.filterChkboxContainer input:focus {
	width: 12px !important;
	height: 12px !important;
	margin: 9px 11px -4px -10px;
}
.hashTagsContainer span i:focus {
	outline: 1px dotted #fff !important;
}
::-ms-input-placeholder { 
	color: #000 !important;
   }
   textarea::placeholder {
	color: #000 !important;  
  }
  ::placeholder {
	color: #000 !important;
	opacity: 1; 
  }
@media screen and (-ms-high-contrast: active) {
	.leftNavContainer {
		border-right: 1px solid;
	}
	.sdmNavBar.navbar,
	.navSearchBox {
		border: 1px solid;
	}
	.leftNavMenu li {
		border-bottom: 1px solid;
		border-left: 1px solid;
	}
	.recentActivitiesCont,
	.favoritesCont,
	.tutorialCont,
	.feedbackAndFavoriteDiv,
	#feedbackComp .handlebrdShadowoutside {
		border: 1px solid;
	}
	.dropDownList {
		border-top: 1px solid;
	}
	button {
		border: 1px solid !important;
	}
	.globalFeedbackThumbUp {
		background: url(../../assets/ThumsUpOrangeFeedback.svg) no-repeat;
		background-size: contain;
	}
	.globalFavoriteOutlineIcon {
		background: url(../../assets/globalFavoriteOutline.svg) no-repeat;
		background-size: contain;
	}
	.globalFavoriteIcon {
		background: url(../../assets/favoritesSolidWhite.svg) no-repeat;
		background-size: contain;
	}
	.dropdown-toggle::after {
		border-top: 0.3em solid;
		border-right: 0.3em solid transparent;
		border-bottom: 1.3px solid transparent !important;
		border-left: 0.3em solid transparent;
		border-bottom-left-radius: 6px;
		border-bottom-right-radius: 6px;
	}
	.helpContainer,
	.feedbackContainer {
		border: 1px solid;
	}
	.custom-control-label {
		position: relative;
		margin-bottom: 0;
		vertical-align: unset !important;
	}
	.custom-switch {
		padding-left: 2.25rem;
	}
	.custom-control {
		position: relative;
		display: block;
		min-height: 1.5rem;
		padding-left: 1.5rem;
	}
	.custom-control-input {
		position: absolute;
		z-index: -1;
		opacity: 0;
	}
	.custom-control-label::after {
		position: absolute;
		top: 0.25rem;
		left: -1.5rem;
		display: block;
		width: 1rem;
		height: 1rem;
		content: '';
		background: no-repeat 50%/50% 50%;
	}
	.custom-switch .custom-control-label::after {
		top: calc(0.25rem + 2px) !important;
		left: calc(-2.25rem + 5px) !important;
		width: calc(1rem - 2px) !important;
		height: calc(1rem - 2px) !important;
		background-color: #adb5bd !important;
		border-radius: 0.5rem;
		transition: background-color 0.15s ease-in-out,
			border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
			-webkit-transform 0.15s ease-in-out;
		transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out,
			border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
		transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out,
			border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
			-webkit-transform 0.15s ease-in-out;
	}
	.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
		background-color: #f58220 !important;
		-webkit-transform: translateX(0.75rem);
		transform: translateX(0.75rem);
	}
	.custom-control-label::before {
		position: absolute;
		top: 0.25rem;
		left: -1.5rem;
		display: block;
		width: 1rem;
		height: 18px !important;
		pointer-events: none;
		content: '';
		background-color: #fff;
		border: #888888 solid 1px !important;
	}
	.custom-control-label::before,
	.custom-file-label,
	.custom-select {
		transition: background-color 0.15s ease-in-out,
			border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	}
	.custom-control-input:focus ~ .custom-control-label::before {
		box-shadow: none !important;
	}
	.custom-switch .custom-control-label::before {
		left: -2.25rem;
		width: 34px !important;
		pointer-events: all;
		border-radius: 1.5rem !important;
	}
	.custom-control-input:checked ~ .custom-control-label::before {
		color: #fff;
		border-color: #888888 !important;
		background-color: #fff !important;
		cursor: pointer !important;
	}
	.toggleSwitch {
		vertical-align: middle;
	}
}

@media screen and (-ms-high-contrast: black-on-white) {
	.leftNavContainer {
		border-right: 1px solid;
	}
	.sdmNavBar.navbar,
	.navSearchBox {
		border: 1px solid;
	}
	.leftNavMenu li {
		border-bottom: 1px solid;
		border-left: 1px solid;
	}
	.recentActivitiesCont,
	.favoritesCont,
	.tutorialCont,
	.feedbackAndFavoriteDiv,
	#feedbackComp .handlebrdShadowoutside {
		border: 1px solid;
	}
	.globalFeedbackThumbUp {
		background: url(../../assets/ThumsUpBlackFeedback.svg) no-repeat;
		background-size: contain;
	}
	.globalFavoriteOutlineIcon {
		background: url(../../assets/globalFavoriteOutlineBlack.svg) no-repeat;
		background-size: contain;
	}
	.globalFavoriteIcon {
		background: url(../../assets/favoritesSolidBlack.svg) no-repeat;
		background-size: contain;
	}
}

@media screen and (-ms-high-contrast: white-on-black) {
	.leftNavContainer {
		border-right: 1px solid;
	}
	.sdmNavBar.navbar,
	.navSearchBox {
		border: 1px solid;
	}
	.leftNavMenu li {
		border-bottom: 1px solid;
		border-left: 1px solid;
	}
	.recentActivitiesCont,
	.favoritesCont,
	.tutorialCont,
	.feedbackAndFavoriteDiv,
	#feedbackComp .handlebrdShadowoutside {
		border: 1px solid;
	}
	.globalFeedbackThumbUp {
		background: url(../../assets/ThumsUpOrangeFeedback.svg) no-repeat;
		background-size: contain;
	}
	.globalFavoriteOutlineIcon {
		background: url(../../assets/globalFavoriteOutline.svg) no-repeat;
		background-size: contain;
	}
	.globalFavoriteIcon {
		background: url(../../assets/favoritesSolidWhite.svg) no-repeat;
		background-size: contain;
	}
}
