.ck-editor__editable_inline {
	min-height: 200px;
}

.activityEditor .ck-content .table {
	width: auto;
}

:root {
	--ck-z-default: 100;
	--ck-z-modal: calc(var(--ck-z-default) + 999);
}
.ck .ck-editor__main h1 {
	font-size: 34px;
}
.ck .ck-editor__main h2 {
	font-size: 32px;
}
.ck .ck-editor__main h3 {
	font-size: 26px;
}