.phaseLeftNavMenu li span {
	width: 100%;
	float: left;
}
.phaseLeftNavMenu a {
	display: inline-flex;
	padding: 20px 15px;
	color: #333;
	text-decoration: none;
	width: 100%;
}
span.phaseEditDeletMore {
	display: inline-flex;
	width: auto !important;
	float: none !important;
	clear: both;
	position: relative;
	cursor: pointer;
}
span.phaseEditDeletMore .showMoreMenu {
	top: 45px;
	width: 89px;
	padding: 0;
	right: 0;
}
span.phaseReferred {
	width: 13px !important;
	height: 13px;
	background: url(../../assets/ReferChainIcon.svg) no-repeat;
	background-size: contain;
	margin-top: 23px;
	margin-right: 10px;
}
.phaseLeftNavMenu a:hover {
	color: #333;
	text-decoration: none;
}
.phaseLeftNavMenu a.activePhase {
	background: #ededed;
}
.phaseLeftNavMenu a.activePhase ~ .phaseReferredDiv {
	background: #ededed;
}
.phaseLeftNavMenu hr {
	border-bottom: 1px solid #cccaca;
	width: 90%;
	border-top: none;
	clear: both;
	margin: 0 auto !important;
}
.swimlineIcon {
	float: left;
	width: 30px;
	height: 30px;
	background: url(../../assets/Icon_Swimlane.png) no-repeat;
	background-size: contain;
}
.solutionMethodTitle button {
	width: 30px;
	height: 30px;
	padding: 0;
	margin: 0;
	border: none;
	box-shadow: none;
	background: none;
}
.phaseAddIcon {
	float: left;
	width: 22px;
	height: 22px;
	background: url(../../assets/addPhaseIcon.svg) no-repeat;
	background-size: contain;
	cursor: pointer;
	margin: 4px 2px;
}
.phaseCollaboratorAddIcon {
	float: left;
	width: 23px;
	height: 23px;
	background: url(../../assets/MethodOwnerIcon.svg) no-repeat;
	background-size: contain;
	cursor: pointer;
	margin: 4px 2px;
}
.swimlineIconactive {
	float: left;
	width: 30px;
	height: 30px;
	background: url(../../assets/Icon_Swimlane_Active.png) no-repeat;
	background-size: contain;
}
.phaseCreationRightPopup .modal-dialog {
	margin-left: auto !important;
	margin: 0;
	max-width: 60%;
}
.phaseCreationRightPopup .modal-content {
	height: 100vh;
}
.phaseCreationRightPopup .modal-header {
	border: none;
	padding: 10px 20px;
}
span.nonDraftPhase {
	display: none;
}
span.draftPhaseText {
	position: absolute;
	left: -1px;
	background: #0078d4;
	width: auto !important;
	font-size: 8px;
	color: #fff;
	padding: 0px 7px;
	top: -2px;
	border-bottom-right-radius: 3px;
}
/* li.draftPhase a.activePhase {
	background: #f6ece1;
} */
span.phaseEditDeletMore span.showMoreArrow {
	right: -54px;
}
.phaseLeftNavMenu li.nonDraftPhase a {
	width: 85%;
}
/* .phaseLeftNavMenu li.coOwner a {
    width: 85%;
} */
span.addNewPhaseDiv span.phaseAddIcon:hover ~ .tabsTitleToolTip {
	display: block;
}
span.phaseCollaboratorDiv
	span.phaseCollaboratorAddIcon:hover
	~ .tabsTitleToolTip {
	display: block;
}
span.addNewPhaseDiv,
span.phaseCollaboratorDiv {
	position: relative;
}
span.addNewPhaseDiv .tabsTitleToolTip,
span.phaseCollaboratorDiv .tabsTitleToolTip {
	left: -82px;
	white-space: nowrap;
	padding: 4px 18px;
}
span.addNewPhaseDiv .tabsTitleToolTip span.tabsToolTipArrow,
span.phaseCollaboratorDiv .tabsTitleToolTip span.tabsToolTipArrow {
	left: 77px;
}
.phaseLeftNavMenu li > div {
	flex: 1;
	display: flex;
}
span.phaseEditDeletMore i {
	padding: 20px 5px;
}
.phaseLeftNavMenu a.activePhase ~ span.phaseEditDeletMore {
	background: #ededed;
}
/* li.draftPhase a.activePhase ~ span.phaseEditDeletMore {
    background: #f6ece1;
} */
.solutionMethodTitle.coOwnersLeftMenu {
	display: flex;
	flex-direction: column;
}
.solutionMethodTitle.coOwnersLeftMenu {
	display: flex;
	flex-direction: column;
	padding: 15px 5px 10px 10px;
}
span.phaseEditDeletMore i:focus {
	outline-offset: -1px;
}
span.phaseEditDeletMore:focus {
	outline-offset: -1px;
}
.phaseEditDeletMore .showMoreMenu li:focus {
	outline-offset: -1px;
}
li.draftPhase span.phaseReferred {
	margin-right: 0;
}
ul.phaseLeftNavMenu.nonOwner {
	height: calc(100vh - 8rem);
}
ul.phaseLeftNavMenu .isRefferedPhase > div {
	background: #a5a0ff;
}
ul.phaseLeftNavMenu .isRefferedPhase a {
	color: #22243e;
}
ul.phaseLeftNavMenu .isRefferedPhase > div a.activePhase,
.phaseLeftNavMenu .isRefferedPhase > div a.activePhase ~ .phaseReferredDiv,
.phaseLeftNavMenu .isRefferedPhase a.activePhase ~ span.phaseEditDeletMore {
	background: #a5a0ff !important;
}

.phaseLeftMenu.phaseLeftMenuDeliveryManagement ul.phaseLeftNavMenu li > div {
	background: #a5a0ff;
}
.phaseLeftMenu.phaseLeftMenuDeliveryManagement
	ul.phaseLeftNavMenu
	li
	> div
	ul.menuItemUL
	li
	.ellipseMenuItem {
	background: none !important;
}
.phaseLeftMenu.phaseLeftMenuDeliveryManagement
	ul.phaseLeftNavMenu
	li
	> div
	a.activePhase,
.phaseLeftMenu.phaseLeftMenuDeliveryManagement
	.phaseLeftNavMenu
	li
	> div
	a.activePhase
	~ .phaseReferredDiv,
.phaseLeftMenu.phaseLeftMenuDeliveryManagement
	.phaseLeftNavMenu
	li
	a.activePhase
	~ span.phaseEditDeletMore {
	background: #a5a0ff !important;
}
.activePhaseBox {
	border-left: 5px solid #766e6e;
}