.deliverables-container h2 {
	padding: 12px;
    border: 1px solid #c4c4c4;
    border-bottom: 0;
    width: calc(100% - var(--ck-sample-sidebar-width));
    margin-bottom: 0;
	font-size: 14px;
}
#previewTempList li{    
    word-wrap: break-word;
}

