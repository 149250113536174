.topicBulderMainContainer {
	display: flex;
	background: #f3f2f2;
	flex-direction: column;
	margin-top: 60px;
}
.topicBulderContainer {
	display: flex;
	min-height: 80vh;
	background: #fff;
	flex-direction: column;
	margin: 60px 20px 0px 20px;
}
.topicBulderHeaderContainer {
	display: flex;
	padding: 10px 14px;
}
.topicBulderHeaderContainer h4 {
	flex: 1;
	/* margin-left: 13px; */
}
.topicBulderHeaderButtons button {
	border: none;
	font-size: 13px;
	padding: 5px 20px;
	margin-left: 10px;
	border-radius: 4px;
	background: #666;
	color: #fff;
}
.topicBulderHeaderButtons button:last-child {
	background: #ffa12d;
	color: #000;
}
.topicBulderFieldsContainer {
	/* padding: 20px; */
}
.topicAdditionCont {
	flex-direction: column;
	display: flex;
}
.cloneTopicButtonAction {
	display: inline-flex;
	border: 1px solid #e3e3e3;
	background: #e3e3e3;
	padding: 8px 18px 8px 20px;
	flex-direction: column;
	margin-bottom: 24px;
	border-radius: 4px;
}
.cloneTopicButtonAction .selectAddTopicButton {
	color: #333;
}
.addTopicButtonAction {
	display: inline-flex;
	/* border: 1px solid #4d87f6;
	background: #f1f5f9; */
	padding: 8px 0px 0px 0px;
	flex-direction: column;
	margin-bottom: 24px;
	border-radius: 4px;
}
.selectAddTopicButton {
	color: #0262ab;
	cursor: pointer;
	font-size: 14px;
}
.addTopicButtonAction.expanded {
	width: 100%;
}
.addTopicFields {
	display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 5px;
    padding: 5px;
}
.addTopicFieldsDiv {
	margin-top: 15px;
	display: flex;
	flex-direction: column;
	flex: 1 1;
}
span.AddTopicFieldLabel {
	font-weight: 600;
	margin-bottom: 4px;
	font-size: 14px;
}
.addTopicFields input {
	width: 100%;
	max-height: 40px;
	border: 1px solid #e8e9ec;
	font-size: 12px;
	padding: 5px;
}
.addTopicFields .isVisibleDiv input {
	width: auto;
}
.addTopicFields.processGroupDiv {
	display: flex;
	flex-direction: row;
}
.addTopicFieldsDiv.addTopicTitlePart {
	display: flex;
	flex-direction: row;
}
.addTopicFieldsDiv.addTopicTitlePart div:first-child {
	flex: 1;
}
.addTopicFieldsDiv div#topicsHashtagsInput {
	font-size: 12px;
}
.isVisibleDiv {
	margin: 27px 10px 0px 20px;
}
.isVisibleDiv input {
	margin-right: 6px;
}
.addTopicFieldsDiv .dropDownList.coOwner {
	top: 1px;
}
.addTopicFieldsDiv .dropDowncontainer.coOwner,
.addTopicFieldsDiv .dropDowncontainer.reviewer,
.addTopicFieldsDiv .dropDowncontainer.approver {
	width: 100%;
}
.addTopicFieldsDiv ul.afterSaveListTopic.collaboratorsApprovers {
	background: #fff;
	padding: 13px;
}
.addTopicFieldsDiv ul.afterSaveListTopic.collaboratorsApprovers i {
	font-size: 12px;
	font-weight: bolder;
	margin-left: 8px;
	vertical-align: middle;
	cursor: pointer;
}
.dropDowncontainer.topicBuilderProcessGroup {
	width: 100%;
}
.dropDowncontainer.topicBuilderProcessGroup .dropDownBox {
	padding: 4.5px 9px !important;
}
.dropDowncontainer.topicBuilderProcessGroup:hover {
	background-color: unset !important;
}
.dropDowncontainer.topicBuilderProcessGroup .wrapHeader {
	width: auto;
}
.dropDowncontainer.topicsBuilderAddTools,
.dropDowncontainer.topicsBuilderRoles {
	width: 100%;
	margin-right: 0;
}
.dropDowncontainer.topicsBuilderAddTools input,
.dropDowncontainer.topicsBuilderRoles input {
	width: 98%;
}
.dropDowncontainer.topicsBuilderAddTools .NotificationChkboxContainer,
.dropDowncontainer.topicsBuilderRoles .NotificationChkboxContainer {
	font-size: 18px;
	margin-bottom: 0;
}
.addTopicFieldsDiv .Detailswrapper.templateWrapActive {
	border: none;
	background: #fff;
	padding: 15px;
	min-height: 110px;
}
.dropDowncontainer.topicBuilderProcessGroup span.complexityLabel {
	margin-left: 25px;
}
.dropDowncontainer.topicBuilderProcessGroup .NotificationChkboxContainer {
	font-size: 13px;
	margin-bottom: 0;
}
.dropDowncontainer.topicBuilderProcessGroup .CustomCheckmark {
	top: 3px;
	left: 0;
}
.topicsOwnerPreviewPopUpModalDiv {
	display: flex;
}
.topicsOwnerPreviewPopUpModalDiv > div:first-child {
	flex: 1;
}
.topicsOwnerPreviewPopUpModalDiv .associateContentPanel {
	font-size: 13px;
	display: flex;
	flex-direction: column;
	width: 300px;
	background: #f2f2f2;
	min-height: calc(100vh - 8rem);
	margin-left: 10px;
}
.addTopicFieldsDiv.relatedContentBuilder .Detailswrapper.templateWrapActive ul {
	margin-bottom: 0;
	list-style: none;
	padding: 0;
}
.addTopicFieldsDiv.relatedContentBuilder
	.Detailswrapper.templateWrapActive
	ul
	li {
	background: #0078d4;
	color: #fff;
	padding: 4px 10px;
	border-radius: 10px;
	display: flex;
	margin-top: 10px;
}
.topicBuilderRelatedContent {
	display: flex;
	flex: 1;
}
.topicBuilderRelatedContent div {
	margin-right: auto;
}
.addTopicFieldsDiv.relatedContentBuilder
	.Detailswrapper.templateWrapActive
	ul
	li
	i {
	margin-right: 10px;
	cursor: pointer;
	font-weight: bold;
	font-size: 12px;
}
.topicsOwnerPreviewPopUpModalDiv h4.topicTitle {
	flex: 1;
}
.topicPreviewAndSubmitPopUp.topicBuilderPreviewPopUp .topicDescTitlePart {
	margin-bottom: 6px;
}
.topicsOwnerPreviewPopUpModalDiv .associateContentPanel div h5 {
	font-size: 14px;
	padding: 20px;
	text-align: center;
	color: #2b314c;
	border-bottom: 1px solid #e4e4e4;
}
.topicPreviewAndSubmitPopUp.topicBuilderPreviewPopUp .topicDescriptionDiv {
	padding: 0px 0px 10px 20px;
	min-height: calc(100vh - 8rem);
	display: flex;
	flex-direction: row;
}
.addTopicFieldsDiv .Detailswrapper.templateWrapActive .cantFindToolsFields {
	display: flex;
	flex-direction: column;
	margin-top: 0;
}
.addTopicFieldsDiv
	.Detailswrapper.templateWrapActive
	.cantFindToolsFields
	div:last-child {
	display: flex;
}
.AddTopicFieldContenUrl {
	display: flex;
	flex-direction: column;
	width: 50%;
}
.AddTopicFieldContentButtons {
	display: flex;
	justify-content: flex-end;
	align-items: center
}
.relatedContentBuilder .Detailswrapper.templateWrapActive {
	min-height: 145px;
}
.topicPreviewAndSubmitPopUp.topicBuilderPreviewPopUp .modal-title.h4 {
	display: flex;
}
.topicOwnerPreviewPopupTitle {
	display: flex;
	flex-direction: column;
}
.addTopicFieldsDiv.topicBuilderAddToolsContainer .cantFindToolsFields {
	display: flex;
	flex-direction: row !important;
	margin-top: 10px !important;
}
.addTopicFieldsDiv ul.afterSaveList.beforEditSavedList li,
.addTopicFieldsDiv ul.afterSaveList li a {
	background: #0078d4;
	color: #fff;
}
.addTopicButtonAction .selectAddTopicButton {
	cursor: default;
}
.topicBulderMainContainer .phaseBreadcrumb {
	background: #fff;
	position: fixed;
	width: 100%;
	z-index: 99;
}
.AddTopicFieldContentButtons button:first-child {
	background: #80807d80;
	color: #000;
}
.topicBuilderPreviewPopUp .discardAndPreviewButton {
	margin-top: 13px;
}
.topicPreviewAndSubmitPopUp.topicBuilderPreviewPopUp
	.topicDescriptionDiv
	div#previewDescription {
	margin-top: 19px;
}
.topicPreviewAndSubmitPopUp.topicBuilderPreviewPopUp .modal-dialog {
	max-width: 1300px;
}
.topicPreviewAndSubmitPopUp.topicBuilderPreviewPopUp
	.topicDescriptionDiv
	div#previewDescription
	img {
	width: 100%;
}
.topicBuilderRelatedContent div:nth-child(2) {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 500px;
}
.dropDowncontainer.topicOwner {
	width: 100%;
}
.dropDowncontainer.topicOwner label.NotificationChkboxContainer {
	font-size: 13px;
	margin-bottom: 0;
}
.dropDowncontainer.topicOwner
	label.NotificationChkboxContainer
	span.CustomCheckmark {
	top: 4px;
}
.dropDowncontainer.topicOwner
	label.NotificationChkboxContainer
	.complexityLabel.userName {
	margin-left: 25px;
	width: 300px;
	display: inline-flex;
}
.phaseCreationRightPopup
	.dropDowncontainer.topicOwner
	label.NotificationChkboxContainer
	.complexityLabel.userName {
	width: auto;
}
.topicBuilderHeaderVersionDiv {
	display: flex;
	margin-right: 45px;
	line-height: 29px;
	font-size: 13px;
	flex: 1;
}
.topicBulderHeaderButtons {
	display: flex;
}
.topicBuilderHeaderVersionDiv ul {
	display: flex;
	list-style: none;
	margin: 0 35px 0 1px;
}
.topicBuilderHeaderVersionDiv ul li label.checkBoxContainer {
	padding-left: 21px;
	margin-left: 11px;
}
.topicBuilderHeaderVersionDiv ul li label.checkBoxContainer span.settingsLabel {
	font-size: 13px;
}
.topicBulderContainer .topicsMenu li {
	white-space: nowrap;
	padding: 10px 24.5px 5px 21px;
}
.topicBulderContainer .topicsMenuContainer {
	padding-left: 20px;
}
.dropDowncontainer.topicType,
.dropDowncontainer.topicCategory {
	width: 100%;
}

.dropDowncontainer.topicType .dropDownBox,
.dropDowncontainer.topicCategory .dropDownBox {
	padding: 6px 9px !important;
	border: 1px solid #e4e4e4;
	margin-top: 0;
}
.dropDowncontainer.topicCategory .dropDownBox span.wrapHeader {
	width: 90%;
}
.topicSequenceList {
	padding: 20px 20px;
}
.topicBulderContainer .topicsMenu span {
	padding: 0 30px;
}
.addRoleNameContainer.topicCategoryName
	label.NotificationChkboxContainer
	input[type='checkbox']:focus {
	height: 15px;
	margin: 2px 0 0 3px !important;
}
.dropDownList.coOwner .NotificationChkboxContainer .CustomCheckmark {
	top: 5px;
}
.addBusinessRule{
	margin-right:10px;
}

.topicsRelatedContentDiv{
display:flex;
cursor: pointer;
}
.topicsRelatedContentDivActive{
	color:	#a95813 !important;
    border-bottom: 1px solid #a95813 !important ;
	width: 50%;
    text-align: left;
	font: normal normal normal 14px/20px Segoe UI;
}
.topicAssociateContentPanel{
	font-size: 13px;
    display: flex;
    flex-direction: column;
}
.topicAssociateContentPanel ul {
    padding: 0;
    margin-bottom: 0px !important;
    display: flex;
    flex-direction: column;
}
.topicAssociateContentPanel ul li {
    list-style: none;
    float: left;
    display: flex;
    width: 100%;
    padding: 2px 1px;
    color: #0078d4;
    /* border-bottom: 1px solid #dee2e6; */
    /* padding: 4px 10px; */
}
.topicAssociateContentPanel ul li div:first-child {
    flex: 1 1;
}
.topicAssociateContentPanel ul li i {
    font-size: 22px;
    vertical-align: middle;
    margin-right: 2px;
}
.topicAssociateContentPanel ul li div:first-child a {
    color: #0262ab !important;
}
.topicsRelatedContentDivAssociate{
    border-bottom: 1px solid #7E7E7E !important ;
	text-align: left;
	font: normal normal normal 14px/20px Segoe UI;
	letter-spacing: -0.14px;
	color: #7E7E7E;
	opacity: 1;
	width: 50%;
}
.topicsRelatedContentDiv h5{
	font-size: 13px !important;
}
.topicBulderPolygonFieldsContainer{
	width: 75%;
	
}
.polygonMainCountainer{
	display: flex;
	background: white;
    border-top: 1px solid #F2F2F2
}

.hexagonSaveContainer{
	border-top: 1px solid #00000029;
	display:flex;
	justify-content: space-between;
	padding: 0 14px 0px;
	background-color: white;
}

.hexagonSaveContainerbutton{
	width: 150px;
	height: 35px;
	margin-top: 10px !important;
	border-radius: 7px;
}

.hexagonCancelButton{
	background-color:white !important;
	color:#333328 !important;
	border: 1px solid #D6D6D6 !important;
	font: normal normal normal 16px/21px Segoe UI;
    letter-spacing: -0.16px;
    opacity: 1;
	font-weight: 500;
}
.hexagonSaveButton{
	background-color:#666666 !important;
	color:white !important;
	border: 1px solid #D6D6D6;
	font: normal normal normal 16px/21px Segoe UI;
    letter-spacing: -0.16px;
    color: #333333;
    opacity: 1;
	font-weight: 500;
}
.hexagonHeaderContainer{
	position: absolute;
    right: 25px;
    top: 74px;
}
.hexagonBuilderMainContainer{
	margin:unset
}
.hexagonBulderContainer{
	margin-top: 10px !important
}
.hexagonBulderHeaderContainer{
	margin-top: 30px;
}
.polygonPreivewHeader{
	border-top:1px solid#00000029;
	height: 56px;
}
.polygonTopicsOwnerPreviewPopUpModalDiv{
	height: 100%;
	width: 75%;
	overflow: hidden;
	overflow-x: scroll;
	overflow-y: scroll;
}
.polygonTopicPreivewRightContainer{
	width: 25%;
}
.hexagonTopicBuilderPreviewPopUp .modal-header{
	height: 86px;
}
.polygonAssociateContentPanel{
	/* position: absolute; */
	right: 0px;
	height: 30%;
	overflow: scroll;
}
.polygonAssociateContentPanel ::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
}
.polygonDesc{
	/* width: 70%; */
}
.relatedContentOpenIcon{
	background: url(../../assets/icon-Open.svg) no-repeat;
	height: 30px;
	width: 30px;
	white-space: pre;
	position:absolute;
	right: 0px;
}
.relatedContentCloseIcon{
	background: url(../../assets/icon-Close.svg) no-repeat;
	height: 30px;
	width: 30px;
	white-space: pre;
	position: relative;
	position:absolute;
	right: 300px;
}
.simpleTopicPreview .topicDescriptionDiv{
	height: 75vh !important;
	min-height: 0px !important;
	width: 100%;
}
.simpleTopicPreview .polygonAssociateContentPanel{
	height: 67vh !important;
	overflow: scroll;
	padding-top: 15px;
}
.simpleTopicPreview .topicsOwnerPreviewPopUpModalDiv{
	width: 75%;
}

.topicsAssociateContentDiv{
	padding-top: 10px;
    padding-bottom: 10px;
    border-top: 1px solid #f2f2f2;
}
.topicAssociateContentAnchor{
	display: inline-flex;
    background: #f1f5f9;
    border-radius: 3px;
    border: 1px solid #4d87f6;
    text-decoration: none;
    margin-top: 7px;
	max-width: -webkit-fill-available;
    margin: 5px 5px 0px 0px;
}
.topicAssociateContentAnchor span {
    padding: 2px 10px;
}
