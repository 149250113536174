.phaseShowMore{
    box-shadow: 0px 1px 10px 5px #0000001f;
    margin: 12px;
    padding: 20px;
    height: calc(100vh - 366px) !important;
    border-radius: 11px;
    font-size: 13px;
    overflow: hidden;
    overflow-y: auto;
}
.phaseShowMore i {
    text-align: right;
    cursor: pointer;
    font-size: 16px;
    margin-bottom: 5px;
    float: right;
}
.phaseShowMore h3 {
    font-weight: bold;
}
.phaseShowMore h3.parentSolutionMethod {
    font-weight: 600;
    margin-top: 40px;
}