.viewDivresuable {
    min-width: 40px;
    max-width: 46px;
    height: 30px;
    border: none;
    border-radius: 4px;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0 0 5px;
    outline: none;

}

.viewWrapper {
    display: flex;
    align-items: center;
    gap: 3px;
}

.viewIcon {
    width: 17px;
    height: 17px;
    background-size: contain;
    display: inline-block;
    background: url(../../assets/ViewIcon.svg) no-repeat center center;
}

.viewIconTemplate {
    width: 17px;
    height: 17px;
    background-size: contain;
    display: inline-block;
    background: url(../../assets/ViewIconTemplate.svg) no-repeat center center;
}


.favoriteDivResuable {
    width: 28px;
    height: 30px;
    background: #F5F5F5;
    border: 1px solid #D8D8D8;
    border-radius: 4px;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 5px;
    margin: 0px 4px 0px 5px;
    outline: none;
}

.favoriteDivResuable:focus {
    outline: none !important;
    /* Removes the focus outline */
    border: 1px solid #D8D8D8 !important;
    /* Removes any border */
}

.likeDivReusable:focus {
    outline: none !important;
    /* Removes the focus outline */
    border: 1px solid #D8D8D8 !important;
    /* Removes any border */
}

.dislikeDivReusable:focus {
    outline: none !important;
    /* Removes the focus outline */
    border: 1px solid #D8D8D8 !important;
    /* Removes any border */
}

.viewDivresuable:focus {
    outline: none !important;
    /* Removes the focus outline */
    border: none !important;
    /* Removes any border */
}


.favoriteWrapper {
    display: flex;
    align-items: center;
}

.favoriteOutline,
.favoriteFilled {
    width: 17px;
    height: 17px;
    background-size: contain;
    display: inline-block;
}

.favoriteOutline {
    background: url(../../assets/favoriteIcon.svg) no-repeat center center;
}

.favoriteFilled {
    background: url(../../assets/favoritefilledIcon.svg) no-repeat center center;
}


.likeDivReusable {
    min-width: 50px;
    max-width: 55px;
    height: 30px;
    background: #F5F5F5;
    border: 1px solid #D8D8D8;
    border-radius: 4px;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 5px;
    margin: 0px 4px 0px 0px;
}

.likeWrapper {
    display: flex;
    align-items: center;
    gap: 3px;
}

.likeIcon,
.likedIcon {
    width: 17px;
    height: 17px;
    background-size: contain;
    display: inline-block;
}

.likeIcon {
    background: url(../../assets/like.svg) no-repeat center center;
}

.likedIcon {
    background: url(../../assets/liked.svg) no-repeat center center;
}

.likeCount {
    font-size: 14px;
    color: #333;
}

.dislikeDivReusable {
    min-width: 50px;
    max-width: 55px;
    height: 30px;
    background: #F5F5F5;
    border: 1px solid #D8D8D8;
    border-radius: 4px;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 5px;
    margin: 0px 0px 0px 0px;
}

.dislikeWrapper {
    display: flex;
    align-items: center;
    gap: 3px;
}

.dislikeIcon,
.dislikedIcon {
    width: 17px;
    height: 17px;
    background-size: contain;
    display: inline-block;
}

.dislikeIcon {
    background: url(../../assets/dislike.svg) no-repeat center center;
}

.dislikedIcon {
    background: url(../../assets/disliked.svg) no-repeat center center;
}

.dislikeCount {
    font-size: 14px;
    color: #333;
}

.viewCount {
    font-size: 14px !important;
}

.FeedbackTemplateIcons {
    padding: 5px !important;
}

.viewDivresuableTemplate {
    background: white !important;
}
.feedback-icons{
    min-width: 165px;
}
.FeedbacKdisabled {
    pointer-events: none;
    opacity: 0.6;
  }
  