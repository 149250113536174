.phaseEditor .ck-editor__editable_inline {
	min-height: 200px;
}

.phaseEditor .ck-content .table {
	width: auto;
}

:root {
	--ck-z-default: 100;
	--ck-z-modal: calc(var(--ck-z-default) + 999);
}
