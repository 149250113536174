.ReAuthPopUp .modal-header {
  flex-direction: column;
  padding: 40px 40px;
}
.ReAuthPopUp .modal-title.h4 {
  font-size: 18px;
}
.ReAuthPopUp .ms-Spinner-circle.ms-Spinner--large {
  width: 50px;
  height: 50px;
  border-width: 9px;
  border-color: #f58220 #f582206b #f582206b;
  margin: 10px auto;
}
.ReAuthPopUp .ms-Stack {
  width: 100%;
}
.ReAuthPopUp .modal-content {
  width: 300px;
}
.modal-dialog.ReAuthPopUp {
  width: 300px;
}
