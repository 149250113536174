.modal-dialog.phaseCreationRightPopup.manageRoles.addSolMethod.ManageTutorials.modal-lg.modal-dialog-centered {
	max-width: 80%;
}
.ManageTutorials input {
	border: 1px solid #ccc;
	padding: 6px;
	width: 230px;
}
.ManageTutorials .addRoleFields {
	width: 870px;
	margin-bottom: 10px;
}
.ManageTutorials .dropDowncontainer {
	width: 200px !important;
}
.ManageTutorials .dropDowncontainer .dropDownBox {
	margin-top: 0;
}
.ManageTutorials label.NotificationChkboxContainer span.CustomCheckmark {
	left: 13px;
	top: 7px;
}
.ManageTutorials .entryCriteriaList {
	width: 100% !important;
}
.ManageTutorials .dropDowncontainer .dropDownBox {
	padding: 6px 9px !important;
}
.ManageTutorials .criteriaName {
	width: 230px !important;
}
.ManageTutorials .criteriaDescription {
	width: 200px;
}
.ManageTutorials .criteriaName,
.ManageTutorials .criteriaDescription,
.ManageTutorials .criteriaIcon {
	margin: 0 20px 0 0;
}
.ManageTutorials .criteriaHeader {
	padding: 6px 18px;
}
.ManageTutorials .criteriaSaveUpdate button {
	margin-top: 3px;
}
.ManageTutorials
	.criteriaIsActive
	label.NotificationChkboxContainer
	span.CustomCheckmark {
	left: 27px;
	top: 1px !important;
}
.ManageTutorials span.deliverPlaybookDeleteIcon {
	margin-left: 6px;
	margin-top: 4px;
}
.ManageTutorials .criteriaEditDeleteIcons {
	padding: 0 !important;
}
.ManageTutorials .criteriaSaveUpdate {
	width: 119px;
	text-align: center;
}
.ManageTutorials .criteriaTable,
.ManageTutorials .addRolesListContainer ul li,
.ManageTutorials.entryCriteriaList ul li .criteriaTable {
	overflow: initial !important;
}
.ManageTutorials
	.addRoleFields
	.addRoleNameContainer
	label.NotificationChkboxContainer
	input {
	width: auto;
}
