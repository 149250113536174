.phaseName {
    color: #737373 !important;
    text-align: left;
    font: normal normal bold 16px/21px Segoe UI;
    letter-spacing: -0.16px;
    opacity: 1;
}

.NonRealizedphaseDescription {
    margin-left: 2px;
    margin-right: -5px;
    padding-top: 7px;
    padding-inline: 15px;
    text-align: left !important;
    font: normal normal normal 13px/17px Segoe UI !important;
    letter-spacing: -0.13px !important;
    /* color: #888888 !important; */
    opacity: 1 !important;
    background: white;
    height: 100%;
}

.NonRealizedphaseDescription span {
    text-align: left !important;
    font: normal normal normal 13px/17px Segoe UI !important;
    letter-spacing: -0.13px !important;
    /* color: #737373 !important; */
    opacity: 1 !important;
}
.phaseDescription {
    margin-left: 2px;
    margin-right: -5px;
    padding-top: 7px;
    padding-inline: 15px;
    text-align: left !important;
    font: normal normal normal 13px/17px Segoe UI !important;
    letter-spacing: -0.13px !important;
    color: #888888 !important;
    opacity: 1 !important;
    background: white;
    height: 100%;
}

.phaseDescription span {
    text-align: left !important;
    font: normal normal normal 13px/17px Segoe UI !important;
    letter-spacing: -0.13px !important;
    color: #737373 !important;
    opacity: 1 !important;
}

.newPhaseLeftContainer {
    width: 50%;
    border-right: 1px solid #f0f0f0;
}

.newPhaseRightContainer {
    width: 50%;
    padding-top: 25px;
    padding-left: 23px;
}

.newPhaseContainer {
    display: flex;

}

.newActivitiesTitleOfActivity {
    text-align: left;
    font: normal normal normal 13px/17px Segoe UI;
    letter-spacing: 0.1px;
    color: #3D3C3C;
    opacity: 1;
}

.activityGroupParent {
    margin-right: 0px;
    width: 96% !important;

}

.activitytypeColor {
    border-left: 4px solid #9928DB !important;
}

.activitytypeColorEnd {
    border-right: 8px solid #A9B9C6;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    background: #D5DEEA 0% 0% no-repeat padding-box;
    opacity: 0.16;

}

.newActivityContainer {
    width: 96% !important;
}

.newActivityTitle i {
    color: #9928DB !important;
}

.newActivityTypeColor {
    border-left: 8px solid #9928DB !important;
}

.activityListMainContainer {
    width: 100% !important;
}

.mergedPhaseLandPage .activityListMainContainer {
    margin-right: 0px !important;
}

._breadcrumbForNonRealized {
    background: #fff;
    padding-left: 1px;
    padding-bottom: 7px;
}