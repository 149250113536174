.modal-dialog.phaseCreationRightPopup.manageRoles.manageFeatures.modal-lg.modal-dialog-centered {
	max-width: 90%;
}
.manageFeatures .addRoleFields {
	width: 100%;
}
.manageFeatures .dropDownBox {
	padding: 6px 9px !important;
	margin-top: 0;
}
.manageFeatures .dropDowncontainer {
	width: 200px;
	margin-right: 0;
}
.manageFeatures .addRoleNameContainer.featureDescription textarea {
	border: 1px solid #ccc;
	font-size: 12px;
	height: 80px;
	display: flex;
	width: 280px;
}
.manageFeatures label.NotificationChkboxContainer {
	font-size: 13px;
}
.manageFeatures label.NotificationChkboxContainer span.complexityLabel {
	margin-left: 24px;
}
.manageFeatures label.NotificationChkboxContainer span.CustomCheckmark {
	top: 3px;
	left: 18px;
}
.manageFeatures .addRoleNameContainer > input,
.manageFeatures label.NotificationChkboxContainer,
.manageFeatures .dropDowncontainer,
.manageFeatures .criteriaTable .criteriaIcon input,
.manageFeatures span.deliverPlaybookDeleteIcon {
	margin-top: 23px;
}
.manageFeatures
	.addRolesListContainer.addDeliveryPlaybookContainer
	.entryCriteriaList {
	font-size: 12px;
	width: 100%;
}
.manageFeatures
	.addRolesListContainer.addDeliveryPlaybookContainer
	.criteriaIcon {
	background: inherit;
}
.criteriaDescription {
	width: 300px;
}
.criteriaIsActive,
.criteriaIsFlyout {
	width: 76px;
}
.criteriaDescription textarea {
	width: 280px;

	border: 1px solid #ccc;
	font-size: 12px;
	height: 80px;
}
.manageFeatures
	.addRolesListContainer.addDeliveryPlaybookContainer
	.entryCriteriaList
	.criteriaName {
	width: 200px;
	overflow: initial;
	background: inherit;
}
.manageFeatures .entryCriteriaList ul li .criteriaTable {
	overflow: initial;
}
.manageFeatures .criteriaHeader {
	padding: 6px 18px;
}
.criteriaSaveUpdate button {
	border: none;
	background: #0078d4;
	color: #fff;
	padding: 5px 10px;
	border-radius: 4px;
	margin-top: 23px;
}
.manageFeatures .addDeliveryPlaybookContainer .criteriaIcon,
.manageFeatures .addDeliveryPlaybookContainer .criteriaEditDeleteIcons,
.manageFeatures .addDeliveryPlaybookContainer .criteriaName {
	margin: 0 20px 0 0;
}
.manageFeatures .addDeliveryPlaybookContainer .criteriaEditDeleteIcons {
	padding: 0;
}
.manageFeatures span.deliverPlaybookDeleteIcon {
	margin-left: 10px;
}
.manageFeatures .entryCriteriaList ul li {
	overflow: initial;
}
.manageFeatures span.addRoleIcon {
	margin: 62px 2px 4px 2px;
}
.manageFeatures .NotificationChkboxContainer .CustomCheckmark:after {
	top: -1px;
}
