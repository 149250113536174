.searchResultsMainContainer {
	display: flex;
	flex-direction: column;
	min-height: 80vh;
}

.searchResultsBreadcrumb {
	border-bottom: 1px solid #e4e4e4;
	background: #fff;
}

.searchTabsMenuContainer {
	display: flex;
	background: #f8f9fa;
	margin-bottom: 45px;
}

.SearchResultsTopMenu {
	position: sticky;
	width: 100%;
	z-index: 999;
	top: 45px;
}

.searchMenu {
	float: left;
	padding: 8px;
	margin: 0;
	padding: 0;
	list-style: none;
	font-size: 13px;
	margin: 0 200px;
	flex: 1;
	width: 56%;
}

.searchMenu li {
	float: left;
	padding: 24px 15.5px 4px 21px;
	list-style: none;
	text-align: center;
	flex-grow: 1;
	flex-basis: 0;
	cursor: pointer;
	color: #666666;
	margin-right: 17px;
	white-space: nowrap;
}

.selectedSearchMenu {
	border-bottom: 3px solid #f58220;
	opacity: 1 !important;
	color: #333333 !important;
	font-weight: bolder;
}

.searchMenu li h6 {
	font-size: 13px;
}

.searchResultsWrapper {
	display: flex;
	background: #e6e6e6;
	min-height: 77vh;
	padding-top: 20px;
	/* margin-top: 90px; */
	/* flex-direction: column; */
}

.searchedContentResults {
	margin: 0 400px 0 20px;
	width: 100%;
}

.searchedOthersResults {
	margin: 0 20px 0 20px;
	width: 100%;
}

.tabsWrapper {
	display: flex;
	min-height: 110px;
	background: #fff;
	margin-bottom: 5px;
	width: 100%;
	flex: 1;
	padding: 10px;
}

.imageOfSearchResult {
	float: left;
	width: 35px;
	height: 35px;
	margin-left: 7px;
	background-size: contain;
	margin-bottom: 10px;
}

.tabsImageWrapper {
	padding: 7px 10px 7px 13px;
	width: 104px;
	order: 1;
}

.tabTitle {
	display: flex;
}

.tabTitle a {
	padding-top: 8px;
	color: #0070c0 !important;
	font-size: 14px;
	font-weight: 600;
	position: relative;
	/* flex: 1; */
}

.detailsOfTabs {
	display: flex;
}

.favoriteAndLikeDiv {
	display: block;
	float: left;
}

.favoriteDiv {
	float: left;
	padding: 5px;
	background: #f2f2f2;
	margin-right: 5px;
	cursor: pointer;
}

.likeDiv {
	float: left;
	padding: 3px 5px 7px 6px;
	background: #f2f2f2;
	cursor: pointer;
}

.likeDiv i {
	font-size: 13px;
	height: 14px;
	float: left;
	color: #a4afb7;
}

.tabsRightWrapper {
	float: right;
	width: 200px;
	display: flex;
	order: 3;
	margin-top: -21px;
}

.descriptionOfTabs {
	padding-top: 10px;
}

.showMoreButtonTabs {
	border: 2px solid #999;
	float: left;
	padding: 0px 7px;
	border-radius: 7px;
	box-sizing: border-box;
	height: 30px;
}

.tabsMoreIcon {
	cursor: pointer;
	float: right;
	position: relative;
}

.tabsMoreIcon i {
	vertical-align: middle;
	font-weight: bolder;
}

.tabsShowMoreMenu {
	list-style-type: none !important;
	list-style: none !important;
	background-color: white !important;
	border: 1px solid lightgrey !important;
	width: 273px;
	right: -71px;
	box-shadow: 0px 1px 4px #ccc;
	z-index: 99;
	top: 34px;
	position: absolute !important;
	font-size: 13px;
	min-height: 53px;
}

.activityTabsSearch .tabsShowMoreMenu {
	width: 273px;
	right: -19px;
	top: 31px;
	padding: 10px;
	min-height: 53px;
}

.activityTabsSearch .tabsShowMoreArrow {
	right: 18px;
}

.tabsShowMoreArrow {
	width: 26px;
	height: 12px;
	background-size: contain;
	position: absolute;
	top: -12px;
	right: 70px;
	background: url(../../assets/beakImage.png) no-repeat;
}

.tabsShowMoreMenu ul {
	padding-left: 0px !important;
	margin-bottom: 0px !important;
}

.tabsShowMoreMenu li {
	list-style: none;
	cursor: pointer;
	float: left;
	display: block;
	width: 100%;
	padding: 10px 1px;
	border-bottom: 1px solid #e4e4e4;
}

.activityTabsSearch .tabsShowMoreMenu li {
	list-style: none;
	cursor: pointer;
	float: left;
	display: block;
	width: 100%;
	margin-bottom: 4px;
	background: #e1f0d9;
	padding: 4px 4px 4px 8px;
	border-bottom: none !important;
}

.tabContributer {
	float: left;
	margin-right: 10px;
	/* margin-left: 41px; */
}

.contributerButton {
	display: inline-block;
	float: right;
	border: 1px solid #f58220;
	padding: 5.5px 8px;
	color: #f58220;
	border-radius: 4px;
	line-height: normal;
	font-size: 13px;
	cursor: pointer;
	height: 30px;
	box-sizing: border-box;
}

.contributerButton i {
	transform: rotate(270deg);
	font-weight: 800;
	font-size: 16px;
}

.contributerButton span {
	padding-left: 5px;
}

.contributerButton:hover {
	background: #f58220;
}

.contributerButton:hover i,
.contributerButton:hover span {
	color: #fff;
}

.tabsDescriptionWrapper {
	order: 2;
	flex: 1;
}

.otherDetailsOfTab {
	flex: 1;
	display: flex;
	flex-direction: column;
}

.phaseDetails {
	margin-top: 13px;
	margin-right: 72px;
}

span.phaseLabel {
	font-weight: 600;
	font-size: 14px;
}

.phaseDetails .phaseName {
	font-size: 12px;
}

.samplesImage {
	float: left;
	width: 20px;
	height: 20px;
	background-size: contain;
	background: url(../../assets/SamplesIcon.svg) no-repeat;
}

.localizationImage {
	float: left;
	width: 20px;
	height: 20px;
	background-size: contain;
	background: url(../../assets/localizationIcon.svg) no-repeat;
}

.curatedIpImage {
	float: left;
	width: 20px;
	height: 20px;
	background-size: contain;
	background: url(../../assets/CuratedipIcon.svg) no-repeat;
}

.complexityImage {
	float: left;
	width: 22px;
	height: 22px;
	background-size: contain;
	background: url(../../assets/ComplexityIcon.svg) no-repeat;
}

.tabsShowMoreMenu li span {
	margin-left: 20px;
}

.SearchPagination {
	background: #e6e6e6;
}

.searchTopicDetails span.topicLabel {
	font-weight: 600;
	font-size: 14px;
}

.searchTopicDetails {
	margin-top: 5px;
}

.showMoreButtonTabs:hover {
	background: #e5e5e5;
}

.showMoreExpanded .showMoreButtonTabs {
	background: #e5e5e5;
}

.descriptionOfTabs p {
	font-size: 12px;
}

.tabExpandedViewIcon i {
	margin-top: 4px;
	margin-left: 20px;
	font-size: 12px;
	cursor: pointer;
}

.tabExpandedViewIcon {
	display: inline-flex;
	vertical-align: bottom;
}

.searchedOthersResults .spinner {
	position: absolute;
	top: 60%;
	left: 50%;
}

.searchedContentResults .spinner {
	position: absolute;
	top: 60%;
	left: 50%;
}

.spinner .spinner-border {
	display: inline-block;
	vertical-align: text-bottom;
	border: 2px solid #f58220;
	border-right-color: transparent;
	border-radius: 50%;
	animation: spinner-border 0.75s linear infinite;
}

.loadingSpinner {
	margin-left: 10px;
	position: absolute;
	right: 31px;
	background-color: white;
	margin-top: 3px;
	padding: 0 5px;
}

.noSearchOthers{
	position: relative;
	top: 25%;
	left: 45% !important;
	text-align: center;
	width: 150px;
}

.noSearch {
	position: relative;
	top: 10%;
	left: 60%;
	text-align: center;
	width: 150px;
}

.noClickEvents {
	pointer-events: none;
}

span.topicCatogry {
	font-size: 12px;
}

.hidePagination {
	display: none;
}

.tabTitle font {
	padding-top: 8px;
	font-size: 14px;
	font-weight: 600;
	position: relative;
}

.tabsWrapper.activityTabsSearch .tabsShowMore {
	margin-left: 84px;
}

.relatedSearchSuggestion {
	list-style-type: none !important;
	list-style: none !important;
	background-color: white !important;
	border: 1px solid lightgrey !important;
	width: 367px;
	right: -383px;
	box-shadow: 0px 1px 4px #ccc;
	z-index: 99;
	top: 2px;
	position: absolute !important;
	font-size: 13px;
	display: flex;
	flex-direction: column;
	min-height: 100px;
}

.tabsWrapper.activityTabsSearch,
.tabsWrapper.topicsTabsSearch,
.tabsWrapper.solmethodTabsSearch,
.tabsWrapper.phaseTabsSearch,
.tabsWrapper.templateTabsSearch {
	position: relative;
}

.relatedSearchSuggestion ul li {
	list-style: none;
	float: left;
	display: block;
	width: 100%;
	padding: 7px 1px;
}

.relatedSearchSuggestion ul {
	padding: 10px 15px;
	margin-bottom: 0px !important;
}

.relatedSearchSuggestion h6 {
	border-bottom: 1px solid #e4e4e4;
	padding-bottom: 10px;
	font-size: 14px;
	padding: 15px;
	margin-bottom: 0;
	line-height: 20px;
}

.searchSuggestionComplexityDropdown {
	display: inline-block;
	float: right;
}

.relatedTitles {
	display: inline-block;
	float: left;
	width: 65%;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.activityRecommandations .relatedTitles {
	width: 100%;
}

.searchSuggestionImage {
	float: left;
	width: 20px;
	height: 20px;
	background-size: contain;
	margin-right: 10px;
}

.tabsbeakArrow {
	width: 27px;
	height: 13px;
	background-size: contain;
	position: absolute;
	top: 42px;
	left: -24px;
	background: url(../../assets/beakImage.png) no-repeat;
	transform: rotate(90deg);
}

.relatedTitlesforTopics {
	font-size: 13px;
	font-weight: 600;
}

.relatedPhaseName {
	font-size: 12px;
	color: #666;
	display: flex;
	float: left;
	margin-left: 30px;
	margin-top: 5px;
}

.titleOfSuggestionWrap.activityRecommandations {
	border-top: 10px solid #e4e4e4;
}

.searchSuggestionComplexityDropdown .dropDowncontainer {
	width: 100px !important;
	margin-right: 0px !important;
}

.searchSuggestionComplexityDropdown .dropDowncontainer .wrapHeader {
	width: 60px !important;
}

.relatedSearchSuggestion a {
	color: #000 !important;
}

.relatedSearchSuggestion a:hover {
	text-decoration: underline;
}

.searchSuggestionComplexityDropdown .dropDowncontainer .dropDownBox {
	margin-top: 0;
}

.tabsWrapper:last-child .relatedSearchSuggestion,
.tabsWrapper:nth-last-child(2) .relatedSearchSuggestion,
.tabsWrapper:nth-last-child(3) .relatedSearchSuggestion {
	bottom: 0px;
	top: auto;
}

.tabsWrapper:last-child .relatedSearchSuggestion .tabsbeakArrow,
.tabsWrapper:nth-last-child(2) .relatedSearchSuggestion .tabsbeakArrow,
.tabsWrapper:nth-last-child(3) .relatedSearchSuggestion .tabsbeakArrow {
	bottom: 50px;
	top: auto;
}

span.noSearchSuggestions {
	padding: 10px 15px;
	font-size: 12px;
}

.relatedSearchSuggestion h6 span {
	color: #0070c0 !important;
}

.tabsWrapper:first-child .relatedSearchSuggestion,
.tabsWrapper:nth-child(2) .relatedSearchSuggestion,
.tabsWrapper:nth-child(3) .relatedSearchSuggestion {
	top: 0 !important;
	bottom: auto;
}

.tabsWrapper:first-child .relatedSearchSuggestion .tabsbeakArrow,
.tabsWrapper:nth-child(2) .relatedSearchSuggestion .tabsbeakArrow,
.tabsWrapper:nth-child(3) .relatedSearchSuggestion .tabsbeakArrow {
	top: 50px !important;
	bottom: auto;
}

.searchFltersDiv {
	display: inline-flex;
	padding-top: 16px;
}

.searchComplexityFlters,
.searchToolsFlters {
	display: inline-flex;
	font-size: 13px;
}

.searchFltersDiv .dropDowncontainer:hover {
	background: none !important;
}

.searchFilterContainer {
	margin-bottom: 0px;
	display: none;
}

.searchFilterContainer .filterTagsContainer {
	border-bottom: none;
}

.searchResultsFilters {
	display: none;
}

.toolsDetails .phaseName {
	font-size: 12px;
}

.searchTabsMenuContainer button.filtersButton {
	margin: 15px 15px 0px 1px;
}

.searchResultsWrapper div#filtersRightPanel {
	position: fixed;
}

.relatedSearchSuggestion a:focus {
	outline-offset: -1px;
}

/* .searchResultsWrapper.searchTutorialsWrapper .searchedContentResults {
	margin: 0 200px;
} */
.searchLocalizedSubMenu {
	display: flex;
	background: #fff;
	margin-bottom: 10px;
}

.LocalizedSubMenu {
	float: left;
	padding: 8px;
	margin: 0;
	padding: 0;
	list-style: none;
	font-size: 13px;

	flex: 1 1;
}

.LocalizedSubMenu li {
	float: left;
	padding: 11px 15.5px 4px 21px;
	list-style: none;
	text-align: center;
	flex-grow: 1;
	flex-basis: 0;
	cursor: pointer;
	color: #666666;
	margin-right: 17px;
	white-space: nowrap;
}

.LocalizedSubMenu li h6 {
	font-size: 13px;
}

.tabsWrapper.templateTabsSearch.sampleTabs .star-container svg {
	width: 10px !important;
	height: 10px !important;
}

.tabsWrapper.templateTabsSearch.sampleTabs .star-container {
	padding: 1px !important;
}

.tabsWrapper.templateTabsSearch.sampleTabs .tabsRightWrapper,
.tabsWrapper.templateTabsSearch.localizedTabs .tabsRightWrapper {
	width: 105px;
	margin-top: -13px;
}

.tabTitle .ComplexityShowContainer a {
	padding-top: 0px;
	margin: 0 10px;
	font-size: 12px;
	margin-top: 0px;
}

.ComplexityShowContainer {
	margin-left: auto;
}

.tabTitle .ComplexityShowContainer span {
	display: inline-flex;
	vertical-align: super;
	margin: 0 5px;
	background: #eaf7ff;
	border-radius: 4px;
}

.tabTitle .ComplexityShowContainer a:hover {
	text-decoration: none;
}

.filtersCategoryPanel label.NotificationChkboxContainer:hover~.tabsTitleToolTip {
	display: block;
}

.filtersCategoryPanel ul li .NotificationChkboxContainer {
	position: relative;
}

.filtersCategoryPanel ul li .tabsTitleToolTip {
	top: 25px;
	left: 10px;
	padding: 6px 10px;
}

.filtersCategoryPanel ul li {
	position: relative;
}

.filtersCategoryPanel ul li .tabsTitleToolTip .tabsToolTipArrow {
	left: 8px;
}

.filtersCategoryPanel ul li .tabsTitleToolTip>span:nth-child(2) {
	display: flex;
	flex-direction: column;
}

.keywordIcon {
	width: 25px;
	height: 25px;
	background: url(../../assets/approverInfoIcon.svg) no-repeat;
	float: left;
	background-size: contain;
	margin-right: 8px;
	margin-left: 20px;
}

.searchedKeywordDiv {
	display: flex;
	padding: 9px;
	background: #f3e9de;
	border-radius: 4px;
	margin-bottom: 20px;
	font-size: 13px;
	color: #333;
	font-weight: 600;
	line-height: 23px;
}

.searchTabsMenuContainer .raciFiltersPart {
	margin: 17px 20px 0 0;
}

.searchedContentResults .tabsWrapper .tabsRightWrapper {
	margin-top: 4px !important;
	justify-content: end;
	margin-right: 20px;
}

.filtersCategoryPanel.noFilter {
	border-bottom: 0;
}

button.ClearFiltersButton {
	width: 22px !important;
	margin-left: 6px;
	height: 22px !important;
	background: url(../../assets/ClearFilters.svg) no-repeat !important;
	background-size: contain !important;
	float: right;
	cursor: pointer;
	border: 0 !important;
	box-shadow: none !important;
}

.raciFiltersPart:has(.disableClearFilter):has(.clearFilterText) {
	display: none;
}

.filtersPanelBody:has(.noFilterApplicable) {
	display: flex;
	justify-content: center;
	align-items: center;
}