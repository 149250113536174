.notificationsRightPanel {
	overflow: hidden;
	border-top: 1px solid #e5e5e5;
	overflow-y: auto;
	/* height: 81vh; */
	height: 100%;
}
div#rightPanel.notificationPanel {
	width: 100%;
	max-width: 600px;
    
	min-height: 30vh;
	min-width: 300px;
    overflow: auto;

}
div#rightPanel.notificationSettings {
	width: 100%;
	max-width: 600px;
    
	min-width: 300px;
    overflow: auto;
}
ul.listOfNotifications {
	list-style: none;
	padding: 0;
	margin: 0;
}
.notifyTitleAndAuthorDiv {
	display: flex;
	flex-direction: column;
	flex: 1;
}
.notifyTypeAndRequest {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}
.listOfNotifications li {
	display: flex;
	padding: 8px 30px;
	border-bottom: 1px solid #e5e5e5;
	cursor: pointer;
	background: #f3f2f1;
}
.notifyTitle {
	font-size: 13px;
	font-weight: 600;
	padding-bottom: 8px;
}
.notifyAuthor {
	font-size: 12px;
	color: #666666;
}
.notifyType {
	font-size: 13px;
	text-transform: uppercase;
	color: #666666;
	font-weight: 600;
	padding-bottom: 8px;
}
.notifyRequest {
	font-size: 13px;
	color: #0070c0;
}
.authorImage {
	width: 18px;
	height: 18px;
	background-size: contain;
	border-radius: 50%;
	float: left;
	margin-right: 5px;
}
#notificationContainer table.ntfGrid {
	width: 100%;
	border-collapse: collapse;
}
.notificationSettings .ntfGrid tr td {
	border: 1px solid #ddd;
	text-align: center;
	vertical-align: middle;
	padding: 15px 10px;
	font-size: 11px;
}
.notificationSettings .ntfGrid tr th {
	border: 1px solid #ddd;
	text-align: center;
	vertical-align: middle;
	padding: 13px 10px;
	font-size: 11px;
}

.NotificationChkboxContainer {
	display: block;
	position: relative;
	cursor: pointer;
	font-size: 22px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.NotificationChkboxContainer input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	visibility: hidden;
}
.NotificationChkboxContainer input:checked ~ .CustomCheckmark {
	background-color: #3191e7;
	border: 1px solid #3191e7;
}
.CustomCheckmark {
	position: absolute;
	height: 15px;
	width: 15px;
	background-color: #fff;
	border: 1px solid rgba(102, 102, 102, 1);
}
.NotificationChkboxContainer input:checked ~ .CustomCheckmark:after {
	display: block;
}
.NotificationChkboxContainer input[type='radio']:focus {
	opacity: 0.5;
	margin: 1px 0px 2px -14px;
	outline: 1px dotted;
	width: 14px;
	height: 14px;
	outline-offset: 1px;
}
.CustomCheckmark:after {
	content: '';
	position: absolute;
	display: none;
}
.NotificationChkboxContainer .CustomCheckmark:after {
	position: absolute;
	left: 1px;
	opacity: 1;
	top: 0px;
	color: #fff;
	font-size: 11px;
	font-weight: normal;
	content: '\E73E';
	font-family: FabricMDL2Icons;
}
div#rightPanel.notificationPanel.notificationExpand {
	width: 600px;
	overflow: scroll;
}
.ratingSampleInNotification {
	display: flex;
	width: 100%;
	padding: 10px 0px;
}
.ratingSampleInNotification p.favoriteDesc {
	margin-bottom: 0;
	font-size: 13px;
	font-weight: 600;
}
.listOfsampleRatingNotifications {
	list-style: none;
	padding: 0;
	margin: 0;
	cursor: pointer;
}
.listOfsampleRatingNotifications li {
	display: flex;
	border-bottom: 1px solid #e5e5e5;
}
.ratingSampleInNotification div {
	margin-left: 19px;
}
.ratingSampleInNotification > div > span {
	font-size: 12px;
	color: #666;
}
.ratingSamplePopUp .modal-dialog {
	max-width: 300px;
}
.ratingSamplePopUp .titlePartOfSample {
	font-size: 14px;
	font-weight: 600;
	margin-bottom: 10px;
}
.ratingSamplePopUp .detailsPartOfSample {
	display: flex;
	flex-direction: column;
	font-size: 12px;
	color: #888;
	margin-bottom: 30px;
}
button.ratingSubmit {
	display: flex;
	margin-top: 20px;
	margin: 30px auto 10px auto;
	border: none;
	background: #1687ff;
	padding: 3px 26px;
	border-radius: 3px;
	color: #fff;
}
.ratingSamplePopUp .star-container svg {
	width: 30px !important;
	height: 30px !important;
}
.ratingSamplePopUp .star-ratings {
	margin-left: 34px;
}
.feedbackSubmitted {
	font-size: 13px;
	font-weight: 600;
	text-align: center;
}
.feedbackSubmitted button {
	display: flex;
	margin-top: 20px;
	margin: 30px auto 10px auto;
	border: none;
	background: #1687ff;
	padding: 3px 26px;
	border-radius: 3px;
	color: #fff;
}

#notificationContainer table.ntfGrid {
	width: 100%;
	border-collapse: collapse;
}

.ntfGrid tr th,
.ntfGrid tr td {
	border-bottom: 1px solid #ddd;
	vertical-align: middle;
	padding: 15px 10px;
	font-size: 11px;
}

.readNotification {
	background: #fff !important;
}

.newTableRow,
.newTableChildRow {
	display: flex;
	display: -webkit-flex;
	flex-direction: row;
	-webkit-flex-direction: row;
	flex-wrap: no-wrap;
	-webkit-flex-wrap: no-wrap;
	width: 100%;
	padding: 0;
}
.newTableRow {
	border-collapse: collapse;
}
.newTableChildRow .newTableRow > .newWrapper {
	border-bottom: 1px solid #e5e5e5;
	border-top: none;
}
.newTitleComment,
.newTitleCommentModuleReporter,
.newAttributes {
	flex-grow: 1;
	-webkit-flex-grow: 1;
	width: 319px;
}
.newWrapper {
	display: flex;
	display: -webkit-flex;
	flex-direction: row;
	-webkit-flex-direction: row;
	position: relative;
	min-height: 60px;
}
.newWrapper .newWrapper,
.newWrapper .newWrapper .newWrapper {
	background: none !important;
	border: none !important;
}
.newActTd {
	padding: 11px 20px !important;
	font-family: 'Segoe UI';
	font-weight: 400;
	font-style: normal;
	font-size: 12px;
	line-height: 18px;
	align-self: center;
	overflow: hidden;
	text-align: left !important;
	cursor: unset;
}
.newColumn {
	flex-grow: 0;
	-webkit-flex-grow: 0;
	flex-shrink: 0;
	-webkit-flex-shrink: 0;
	vertical-align: top;
	/* cursor: pointer; */
}
.newTitle {
	width: 100%;
}
.newTitle a {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	padding: 2px 0 3px 0px;
	font-family: 'Segoe UI';
	font-weight: 400;
	color: #333333;
	font-style: normal;
	font-size: 13px;
	vertical-align: middle;
}
.newColumn.notifyRequest.newActTd {
	font-size: 13px;
	color: #0070c0;
	width: 150px;
}
.newColumn.notifyType.newActTd {
	width: 200px;
}
.newColumn.notifyTypeDate.newActTd {
	width: 150px;
	color: #666666;
}
.newColumn.notifyAuthor.newActTd {
	width: 200px;
}
.notificationExpand .listOfNotifications li {
	padding: 0 !important;
	border-bottom: none !important;
}
.notificationExpand .listOfNotifications li .ratingSampleInNotification {
	padding: 11px 20px;
	border-bottom: 1px solid #e5e5e5;
}
.listOfNotifications li:focus {
	outline: 1px dotted #333;
	outline-offset: -1px;
}
.NotificationChkboxContainer span.checkmark {
	left: 50%;
	top: 1px;
}
.notificationTabMenu {
	display: flex;
	border-bottom: 1px solid #e4e4e4;
	margin: 0px 20px 20px 20px;
}
.notificationTabMenu ul {
	font-size: 13px;
	display: flex;
	list-style: none;
	margin-bottom: 0;
	margin-top: 1px;
}
.notificationTabMenu ul li {
	padding: 8px 15px;
	margin-right: 23px;
	cursor: pointer;
}
table#ntfGrid.notifyContribSettings {
	margin: 20px;
	width: 30%;
}
table#ntfGrid.notifyApprAndReviwerSettings {
	width: 80%;
	margin: 20px;
}
.selectedNotifySettingsMenu {
	border-bottom: 4px solid #f58220;
	opacity: 1 !important;
	color: #333 !important;
	font-weight: 600;
}
.noNotification {
	margin: auto;
	padding: 15px;
	text-align: center;
}
.noNotification .noNotificationImg img{
	margin-top: 8%;
	width: 100%;
	height: auto;
	max-width: 120px;
}
.NotificationChkboxContainer1 {
	display: block;
	position: relative;
	cursor: pointer;
	font-size: 12px;
	padding-top: 10px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.NotificationChkboxContainer1 input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	visibility: hidden;
}
.NotificationChkboxContainer1 input:checked ~ .CustomCheckmark {
	background-color: #3191e7;
	border: 1px solid #3191e7;
}

.NotificationChkboxContainer1 input:checked ~ .CustomCheckmark:after {
	display: block;
}
.NotificationChkboxContainer1 .CustomCheckmark:after {
	position: absolute;
	left: 1px;
	opacity: 1;
	top: 0px;
	color: #fff;
	font-size: 11px;
	font-weight: normal;
	content: '\E73E';
	font-family: FabricMDL2Icons;
}

