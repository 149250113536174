.raciGridViewMainContainer {
	font-family: Segoe UI;
}

.raciGridViewMainContainer .raciGridBreadcrumb {
	box-shadow: 0 0 1px 0 rgb(0 0 0 / 25%);
	position: fixed;
	top: 50px;
	width: 100%;
	background: #fff;
	z-index: 99;
	height: 40px;
	display: flex;
}

.raciGridViewMainContainer .raciGridBreadcrumb button.filtersButton {
	margin-top: 7px;
	margin-right: 15px;
}

.raciGridViewContainer {
	background: #f2f2f2;
	display: flex;
	min-height: 80vh;
	overflow: hidden;
	margin-top: 90px;
}

.raciGridView {
	display: flex;
	width: 100%;
	padding: 15px 20px;
	background: #f2f2f2;
}

.raciGridViewContainer .raciGridView:first-child {
	position: fixed;
	z-index: 9;
	padding: 10px 10px 0px 20px;
	background: #f2f2f2;
}

.genericTableIndex,
.genericTableMethodology,
.genericTablePhaseName,
.genericTableSubPhase,
.genericTableGroupName {
	width: 40px;
	display: flex;
	justify-content: center;
}

.genericTablePhaseNameGroup.phaseCollapsed {
	margin-right: 13px;
}

.genericTableIndex .genericTableValue {
	position: absolute;
	top: 7px;
}

.raciGridView .genericTableIndex .genericTableValue,
.raciGridView .genericTableMethodology .genericTableValue,
.raciGridView .genericTablePhaseName .genericTableValue,
.raciGridView .genericTableSubPhase .genericTableValue,
.raciGridView .genericTableGroupName .genericTableValue {
	transform: rotate(-90deg);
	white-space: nowrap;
	font-weight: 600;
	font-size: 12px;
}

.raciGridView .genericTableHeader .genericTableIndex .genericTableValue,
.raciGridView .genericTableHeader .genericTableMethodology .genericTableValue,
.raciGridView .genericTableHeader .genericTablePhaseName .genericTableValue,
.raciGridView .genericTableHeader .genericTableSubPhase .genericTableValue,
.raciGridView .genericTableHeader .genericTableGroupName .genericTableValue {
	display: flex;
	justify-content: flex-start;
	position: relative;
	width: 100% !important;
}

.raciGridView .genericTableHeader .genericTableIndex .genericTableValue span,
.raciGridView .genericTableHeader .genericTableMethodology .genericTableValue span,
.raciGridView .genericTableHeader .genericTablePhaseName .genericTableValue span,
.raciGridView .genericTableHeader .genericTableSubPhase .genericTableValue span,
.raciGridView .genericTableHeader .genericTableGroupName .genericTableValue span {
	width: 100%;
	position: absolute;
	bottom: auto;
	top: auto;
	right: 49px;
}

.genericTableHeader .genericTableActivity .genericTableValue span {
	position: absolute;
	right: 51px;
	top: -8px;
}

.raciGridView .genericTableHeader {
	min-height: 130px;
	padding: 10px 0;
	font-size: 12px;
	margin-bottom: 0;
	box-shadow: 0px 3px 10px #0000001f;
	background: #f9f9f9;
	display: flex;
	color: #000;
	border-radius: 4px;
	font-weight: 600;
	white-space: nowrap;
	align-items: center;
}

.raciGridView .genericTableList {
	display: flex;
	flex-direction: column;
	margin: 0;
	font-size: 12px;
	width: 100%;
	background: #fff;
	padding: 10px 10px 0px 10px;
}

.raciGridView .genericTableHeader .genericTablePhase {
	width: auto;
}

.raciGridView .genericTableHeader .genericTableValue {
	transform: rotate(-90deg);
	width: 60px;
	line-height: 17px;
}

.genericTableComplexityMenuSub .genericTablePreview .genericTableValue {
	transform: unset;
	white-space: normal;
	width: 120px;
	text-align: center;
}

.genericTableComplexityMenuSub .genericTablePreview {
	width: 63px;
}

.genericTableComplexityMenuHeader {
	background: #FAEABD;
	text-align: center;
	display: flex;
	padding: 8px 3px;
	font-size: 14px;
	margin-bottom: 5px;
}

.genericTableComplexityMenuContainer {
	display: flex;
	flex-direction: column;
	margin-right: 5px;
	background-color: #f9f9f9;
	overflow-y: auto;
	box-shadow: 0px 3px 6px #00000029;
}

.genericTableComplexityMenuSub {
	display: flex;
	min-height: 128px;
	width: 195px;
	overflow-y: auto;
}

.genericTableComplexityMenuSub::-webkit-scrollbar {
	height: 8px;
}

.genericTableComplexityMenuHeader>div {
	flex: 1;
	display: flex;
	justify-content: center;
	font-weight: 600;
	color: #4D4D4D;
}

.genericTableComplexityRolesMenu.roleGroupExpanded.shortOneRoleMenu .genericTableComplexityMenuHeader>div>div {
	width: 107px;
}

.genericTableComplexityRolesMenu.roleGroupExpanded .genericTableComplexityMenuHeader>div>div {
	white-space: normal;
	line-height: 15px;
	word-break: break-all;
	word-break: break-word;
}

.genericTableComplexityMenu {
	padding: 0 10px 0 0;
	display: flex;
	flex-direction: row;
}

.genericTableContainer .genericTableActivity {
	box-shadow: 6px 2px 10px #0000001f;
}

.genericTableActivity {
	width: 221px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	margin-top: 10px;
}

.genericTableComplexityRolesMenu {
	display: flex;
	flex-direction: column;
	background: #ffffff;
	margin: 0px 6px 0 0;
	padding: 8px;
}

.genericTableComplexityRolesMenu>div:last-child {
	display: flex;
}

.genericTableComplexityRolesMenu .genericTableComplexityMenuHeader {
	background: none;
}

.genericTableComplexityRolesMenu.nonRoleGroups>div {
	margin-top: 40px;
}

.genericTableContainer {
	display: flex;
	padding: 0px !important;
	background: #f6f6fb;
	min-height: 30px;
	align-items: center;
	position: relative;
}

.raciGridView ul li {
	margin-top: 6px;
}

.genericTableComplexityMenu .genericTablePreview {
	width: 100%;
	color: #000000;
	font-weight: 600;
	margin: 0 5px;
	font-size: 12px;
	padding-top: 5px;
	width: 120px;
}

.genericTableHeader .genericTablePreview {
	color: #000;
	padding: 0 3px;
	display: flex;
	justify-content: center;
}

.genericTablePhaseNameGroup {
	display: flex;
	background: #e8f0f9;
	padding: 10px 5px;
	flex-direction: column;
	margin: 10px 0;
}

.genericTableComplexityRaciShow {
	display: flex;
	padding: 0;
	min-height: 28px;
}

.genericTableGroupNameGroup,
.genericTableSubGroupNameGroup {
	flex-direction: column;
}

.genericTableGroupNameGroup {
	background: #dae8f6;
	position: relative;
	margin-bottom: 5px;
	margin-top: 5px;
}

.genericTableSubGroupNameGroup {
	background: #c0daf3;
	margin: 10px 0 0px 0;
	position: relative;
}

.genericTablePhaseNameGroup.refredPhase.phaseExpanded .genericTableGroupNameGroup.raciReferredGroup .genericTableGroupName .genericTableRefferedValue span:first-child {
	display: none;
}

.genericTablePhaseNameGroup.refredPhase.phaseExpanded .genericTableGroupNameGroup.raciReferredGroup .genericTableGroupName .genericTableRefferedValue {
	top: -10px;
}

.groupNameCOlorCont>div {
	display: flex;
}

.genericTableGroupNameGroup .genericTablePhaseName {
	background: #eef3f8;
}

.genericTableSubGroupNameGroup .genericTableGroupName {
	background: #dae8f6;
}

.genericTableComplexityMenuHeader i[data-icon-name='Clear'] {
	font-size: 10px;
	font-weight: bold;
	color: #0078d4;
	cursor: pointer;
}

.genericTableContainer i[data-icon-name='Clear'] {
	font-size: 14px;
	font-weight: bold;
	color: #0078d4;
	cursor: pointer;
}

.raciGridView i[data-icon-name='DoubleChevronLeft12'],
.raciGridView i[data-icon-name='DoubleChevronRight12'] {
	color: #f58220;
	font-size: 13px;
	cursor: pointer;
	margin: 0 10px;
}

.raciGridView .genericTableHeader .genericTablePhaseName .genericTableValue {
	position: static;
}

.raciGridView ul {
	display: flex;
	list-style: none;
	padding: 0px 0px 15px 0px;
	flex-direction: column;
	margin: 0;
	background: #f2f2f2;
	width: 100%;
	overflow: hidden;
	overflow-y: auto;
}

.raciRolesGroupUi {
	display: flex;
	padding: 0 6px;
	margin: 3px;
}

.raciTableRow,
.raciTableRow:hover {
	background-color: #f8fafc;
}

.genericTableGroupName .genericTableValue {
	position: absolute;
	left: auto;
	top: 50%;
}

.genericTablePhaseName .genericTableValue {
	position: absolute;
	top: 50%;
}

.genericTableHeader .genericTableGroupName .genericTableValue {
	position: static;
}

.genericTableActivity .genericTableValue {
	overflow: hidden;
	text-overflow: ellipsis;
	width: 180px;
	padding: 4px 0 0 10px;
	display: inline-block;
}

.genericTableHeader .genericTableActivity .genericTableValue {
	width: 60px !important;
	overflow: unset;
	text-overflow: unset;
	position: relative;
}

.genericTableComplexityRolesMenu.roleGroupCollapsed i.roleGroupClear {
	margin-left: 0 !important;
}

.genericTableComplexityRaciShowColumns .genericTableComplexityMenu {
	padding: 0;
	margin-right: 10px;
}

.genericTableHeader .genericTableActivity {
	overflow: unset;
	text-overflow: unset;
}

.genericTableComplexityRaciShow>div {
	display: flex;
}

.genericTableActivity {
	padding: 5px 0;
	background: #fff;
}

.genericTableComplexityRaciShow {
	overflow-x: hidden;
	overflow: -moz-scrollbars-none;
	-ms-overflow-style: none;
	scrollbar-width: none;
}

.genericTableComplexityRaciShowColumns {
	width: 100%;
	overflow: -moz-scrollbars-none;
	-ms-overflow-style: none;
	scrollbar-width: none;
}

.raciGridView .genericTableHeader .genericTableActivity {
	background: inherit;
	width: 219px;
}

.genericTableGroupNameGroup>.genericTableSubGroupNameGroup>div:first-child .genericTableComplexityRaciShowColumns {
	margin-top: 10px;
}

.genericTableGroupNameGroup:last-child>.genericTableSubGroupNameGroup:last-child .genericTableComplexityRaciShowColumns {
	margin-bottom: 10px;
}

.genericTableSubGroupNameGroup .subGroupColorCont>div {
	display: flex;
}

.genericTableSubGroupNameGroup .subGroupColorCont {
	flex-direction: column;
	display: flex;
	background: #c0daf3;
	margin: 10px 0;
}

.groupNameCOlorCont {
	background: #dae8f6;
	margin: 10px 0;
}

.genericTableSubPhase .genericTableValue {
	text-overflow: ellipsis;
	position: absolute;
	top: 50%;
	overflow: hidden;
}

.raciGridView .genericTableHeader .genericTableSubPhase .genericTableValue {
	position: static;
	text-overflow: unset;
	overflow: inherit;
}

.genericTableSubPhase {
	position: relative;
}

.separation>div {
	display: flex;
	flex-direction: row;
}

.separation {
	flex-direction: column;
}

.genericTablePhaseName {
	position: relative;
}

.separation .genericTableGroupNameGroup .genericTableGroupName {
	position: relative;
	display: flex;
	float: left;
	clear: both;
	width: 40px !important;
	justify-content: center;
}

.separation .genericTableSubGroupNameGroup .genericTableSubPhase {
	position: relative;
	display: flex;
	float: left;
	clear: both;
	width: 40px !important;
	justify-content: center;
}

.separation .genericTableGroupNameGroup {
	position: static;
	padding-bottom: 10px;
}

.separation .genericTableSubGroupNameGroup {
	position: static;
	padding-bottom: 10px;
}

.separation .genericTablePhaseNameGroup {
	position: static;
}

.separation .genericTablePhaseNameGroup .genericTablePhaseName {
	position: relative;
	display: flex;
	float: left;
	clear: both;
	width: 40px !important;
	justify-content: center;
}

.raciMessageBar {
	flex: 1;
}

.raciMessageBar .ms-MessageBar {
	margin-right: auto;
	margin-left: auto;
	width: 410px;
	background: none;
	margin-top: 4px;
}

.raciMessageBar .ms-MessageBar-content {
	background: #FFE5E2;
	color: #723636 !important;
}

.raciMessageBar span.ms-MessageBar-innerText span {
	color: #723636;
	font-weight: 600;
}

.raciGridViewContainer .raciGridView:last-child {
	margin-top: 200px;
	font-size: 13px;
	padding: 0px 10px 0px 10px;
	overflow: hidden;
}

.separation .genericTableActivity .genericTableValue {
	font-size: 12px;
}

.genericTableComplexityMenu.complexity2 .genericTableComplexityMenuSub {
	width: 100%;
}

.genericTableComplexityMenu.complexity3 .genericTableComplexityMenuSub {
	width: 100%;
}

.genericTableComplexityMenu.complexity4 .genericTableComplexityMenuSub {
	width: 100%;
}

.genericTableComplexityMenu.complexity1 .genericTableComplexityMenuSub {
	width: 100%;
	height: 128px;
	box-shadow: 0px 3px 6px #00000029;
}

.raciGridView .genericTableContainer i {
	vertical-align: top;
}

.genericTableComplexityRaciShowColumns .genericTablePreview {
	padding: 5px 5px;
	max-width: 120px !important;
	display: flex;
	justify-content: center;
	width: 120px;
	min-width: 120px;
}

.genericTableComplexityRaciShow.complexity2 .genericTableComplexityMenu {
	overflow-x: hidden;
	overflow: -moz-scrollbars-none;
	-ms-overflow-style: none;
	scrollbar-width: none;
}

.genericTableComplexityRaciShow.complexity1 .genericTableComplexityMenu {
	overflow-x: hidden;
	overflow: -moz-scrollbars-none;
	-ms-overflow-style: none;
	scrollbar-width: none;
}

.genericTableComplexityRaciShow .genericTableComplexityMenu::-webkit-scrollbar {
	height: 0px;
	background: transparent;
	overflow: -moz-scrollbars-none;
	-ms-overflow-style: none;
	scrollbar-width: none;
}

.genericTableComplexityRaciShow.complexity3 .genericTableComplexityMenu {
	overflow-x: hidden;
	overflow: -moz-scrollbars-none;
	-ms-overflow-style: none;
	scrollbar-width: none;
}

.genericTableComplexityRaciShow.complexity4 .genericTableComplexityMenu {
	overflow-x: hidden;
	overflow: -moz-scrollbars-none;
	-ms-overflow-style: none;
	scrollbar-width: none;
}

.genericTableComplexityMenuContainer.complexityCollapsed {
	width: 30px;
	overflow-y: inherit;
}

.raciGridViewMainContainer .raciGridBreadcrumb>div {
	display: flex;
	justify-content: end;
}

.genericTableComplexityMenuContainer.complexityExpanded .genericTableComplexityMenuHeader i[data-icon-name='Clear'] {
	margin-left: 8px;
}

.raciFiltersPart {
	font-size: 13px;
	margin: 10px 20px 0 0;
}

.raciFiltersPart button {
	border: 1px solid #ccc;
	background: #fff;
	height: 20px;
	width: 20px;
	vertical-align: middle;
	display: inline-flex;
	align-items: center;
	font-weight: bold;
	box-shadow: 0px 0px 5px #0000001f;
	margin-left: 8px;
}

.genericTableSubGroupNameGroup.shortSubGroup .genericTableSubPhase .genericTableValue {
	overflow: hidden;
	text-overflow: ellipsis;
	width: 35px;
}

.genericTableComplexityRolesMenu.shortTwoRoleMenu .genericTableComplexityMenuHeader>div {
	width: 110px;
	overflow: hidden;
	text-overflow: ellipsis;
}

.genericTableGroupName.shortTwoGroup .genericTableRefferedValue span:first-child {
	display: none;
}

.genericTableGroupName.shortThreeGroup .genericTableRefferedValue span:first-child {
	display: none;
}

.genericTablePreview.shortOneRoleMenu {
	margin-right: 121px;
}

.genericTablePhaseNameGroup.phaseCollapsed .genericTablePhaseName .genericTableValue {
	display: flex;
	transform: rotate(360deg);
	top: 0;
	left: 2px;
}

.genericTableMethodology.methodCollapsed .genericTableValue {
	transform: rotate(0deg);
	position: absolute;
	top: 5px;
	left: 18px;
}

.genericTableMethodology.methodCollapsed .genericTableValue i {
	transform: rotate(-90deg);
	margin: 3px 13px;
	vertical-align: middle;
}

.genericTableContainer.refferedMethodology .genericTablePhaseNameGroup.refredPhase.phaseExpanded .genericTableActivity.refredActivity {
	border-left: 0;
}

.genericTablePhaseNameGroup.refredPhase .genericTableGroupNameGroup.raciReferredGroup.groupExpanded {
	border-left: 0;
}

.genericTablePhaseNameGroup.phaseCollapsed .genericTablePhaseName .genericTableValue i {
	transform: rotate(-90deg);
}

.separation.methodCollapsed .genericTableValue {
	justify-content: end;
	font-weight: 600;
	margin-right: 15px;
}

.separation.methodCollapsed {
	width: 100%;
}

.genericTablePhaseNameGroup.phaseCollapsed .separation.phaseCollapsed .genericTableValue {
	justify-content: end;
	font-weight: 600;
	margin-right: 15px;
}

.genericTablePhaseNameGroup.phaseCollapsed .separation.phaseCollapsed {
	width: 100%;
}

.genericTableGroupName.shortGroup .genericTableValue {
	overflow: hidden;
	text-overflow: ellipsis;
	width: 35px;
}

.raciGridViewMainContainer .raciGridBreadcrumb .filtersPanelBody {
	/* height: calc(100vh - 7.2rem); */
	height: calc(100vh - 10rem);
}

.separation.methodExpanded {
	width: 100%;
}

.genericTablePhaseNameGroup.phaseCollapsed .separation.phaseCollapsed span.activityReferredIcon {
	margin-top: 4px;
	margin-left: 5px;
}

.genericTableGroupName .genericTableValue {
	display: inline-flex;
}

.genericTableContainer.refferedMethodology .genericTableRefferedValue {
	position: absolute;
	top: 44px;
}

.genericTableContainer.refferedMethodology .genericTableActivity {
	width: 133px;
}

.genericTableRefferedValue {
	position: absolute;
	top: 7px;
}

.genericTableContainer.refferedMethodology .genericTableGroupNameGroup.raciReferredGroup .genericTableActivity.refredActivity span.activityReferredIcon {
	width: 23px;
}

.genericTableGroupNameGroup.raciReferredGroup .genericTableSubGroupNameGroup.refredSubGroup .genericTableRefferedValue span:first-child {
	display: none;
}

.genericTableSubGroupNameGroup.refredSubGroup {
	position: relative;
}

.genericTableGroupNameGroup.raciReferredGroup .genericTableSubGroupNameGroup.refredSubGroup .genericTableRefferedValue {
	top: -11px;
	left: -3px;
}

.genericTableMethodology.methodExpanded.shortSolutionMethod .genericTableValue span {
	overflow: hidden;
	text-overflow: ellipsis;
	width: 70px;
	display: block;
}

.genericTableMethodology.methodExpanded.shortSolutionMethod .genericTableValue {
	display: flex;
}

.genericTableComplexityRaciShow .genericTableComplexityMenu.raciComplexityCollapsed {
	width: 30px !important;
}

.genericTableComplexityMenuContainer.complexityCollapsed .genericTableComplexityMenuSub.complexityCollapsed {
	display: none;
}

.genericTableComplexityMenuContainer.complexityCollapsed .genericTableComplexityMenuHeader {
	height: 168px !important;
	position: relative;
}

.genericTableComplexityMenuContainer.complexityExpanded>.genericTableComplexityMenuHeader i[data-icon-name='Clear'] {
	margin-top: 3px;
}

.genericTableComplexityMenuContainer.complexityCollapsed .genericTableComplexityMenuHeader>div>div {
	bottom: 18px !important;
}

.genericTablePreview.raciRoleGroupCollapsed {
	margin-right: 90px;
}

.genericTableComplexityRolesMenu.roleGroupCollapsed .genericTableComplexityMenuHeader>div {
	width: 108px;
	overflow: hidden;
	text-overflow: ellipsis;
}

.genericTableComplexityMenuContainer.complexityCollapsed.firstCollapsed .genericTableComplexityRolesMenu.roleGroupCollapsed .genericTableComplexityMenuHeader>div {
	width: auto;
}

.genericTableComplexityMenuContainer.complexityCollapsed.firstCollapsed .genericTableComplexityMenuSub.complexityCollapsed {
	background: unset;
}

.genericTableComplexityRolesMenu .genericTableComplexityMenuHeader i.roleGroupClear {
	margin-top: 3px;
}

.genericTableComplexityMenuSub {
	margin: 4px 0px 0 0;
}

.genericTableGroupName.shortOneGroup .genericTableValue span {
	overflow: hidden;
	text-overflow: ellipsis;
	width: 32px;
	display: block;
	margin-top: -4px;
	text-align: center;
}

.genericTableSubGroupNameGroup.shortThreeSubGroup .genericTableSubPhase .genericTableValue span {
	width: 94px;
	overflow: hidden;
	text-overflow: ellipsis;
	display: block;
	text-align: center;
}

.genericTableSubGroupNameGroup.shortOneSubGroup .genericTableSubPhase span {
	width: 30px;
	display: block;
	overflow: hidden;
	text-overflow: ellipsis;
	text-align: center;
}

.genericTableGroupName.shortThreeGroup .genericTableValue span {
	width: 108px;
	display: block;
	overflow: hidden;
	text-overflow: ellipsis;
	text-align: center;
}

.genericTableGroupName.shortTwoGroup .genericTableValue span {
	width: 77px;
	display: block;
	overflow: hidden;
	text-overflow: ellipsis;
	text-align: center;
}

.genericTableSubGroupNameGroup.shortTwoSubGroup .genericTableSubPhase .genericTableValue span {
	width: 68px;
	display: block;
	overflow: hidden;
	text-overflow: ellipsis;
	text-align: center;
}

.genericTablePhaseName.shortPhase .genericTableValue span {
	width: 55px;
	overflow: hidden;
	display: inline-block;
	text-overflow: ellipsis;
	vertical-align: super;
	text-align: center;
}

.ClearFiltersButton {
	width: 22px;
	margin-left: 6px;
	height: 22px;
	background: url(../../assets/ClearFilters.svg) no-repeat;
	background-size: contain;
	float: right;
	cursor: pointer;
}

.genericTablePhaseName.shortOnePhase .genericTableValue span {
	width: 31px;
	display: inline-block;
	overflow: hidden;
	text-overflow: ellipsis;
	text-align: center;
}

.genericTablePhaseName.shortOnePhase .genericTableValue i {
	margin-right: 2px;
}

.genericTablePhaseName.shortThreePhase .genericTableValue span {
	width: 100px;
	display: inline-block;
	overflow: hidden;
	text-overflow: ellipsis;
	text-align: center;
}

.genericTablePhaseNameGroup.refredPhase.phaseExpanded .genericTablePhaseName.shortThreePhase .genericTableValue {
	position: absolute;
	bottom: 42px;
	top: auto;
}

.genericTablePhaseNameGroup.refredPhase.phaseExpanded .genericTablePhaseName.shortThreePhase .genericTableValue span {
	text-align: unset;
}

.genericTablePhaseNameGroup.refredPhase.phaseExpanded .genericTablePhaseName.shortTwoPhase .genericTableValue {
	position: absolute;
	bottom: 23px;
	top: auto;
}

.genericTablePhaseNameGroup.refredPhase.phaseExpanded .genericTablePhaseName.shortTwoPhase .genericTableValue span {
	text-align: unset;
}

.genericTableMethodology.methodExpanded.shortOneSolutionMethod .genericTableValue span {
	width: 44px;
	display: inline-block;
	overflow: hidden;
	text-overflow: ellipsis;
	text-align: center;
}

.genericTableMethodology.methodExpanded.shortOneSolutionMethod .genericTableValue i {
	margin: 0 3px 0 0;
}

.genericTablePhaseName.shortTwoPhase .genericTableValue span {
	width: 64px;
	display: inline-block;
	overflow: hidden;
	text-overflow: ellipsis;
}

.genericTableMethodology.methodExpanded.shortTwoSolutionMethod .genericTableValue span {
	width: 100px;
	display: inline-block;
	overflow: hidden;
	text-overflow: ellipsis;
}

.genericTableMethodology.methodExpanded.shortThreeSolutionMethod .genericTableValue span {
	width: 162px;
	display: inline-block;
	overflow: hidden;
	text-overflow: ellipsis;
}

.genericTableActivity.refredActivity {
	display: flex;
}

.genericTableActivity.refredActivity span.activityReferredIcon {
	margin-left: 9px;
	margin-top: 9px;
	margin-right: 0;
	width: 19px;
}

.genericTablePhaseNameGroup.phaseCollapsed .genericTablePhaseName.shortOnePhase .genericTableValue span {
	width: auto;
	display: flex;
	margin-left: 8px;
}

.genericTablePhaseName.shortTwoPhase .genericTableRefferedValue span:first-child {
	display: none;
}

.genericTablePhaseName.shortTwoPhase .genericTableRefferedValue {
	top: -8px !important;
}

.genericTableContainer.refferedMethodology .genericTableActivity .genericTableValue {
	width: 105px;
}

.genericTableGroupName.shortOneGroup .genericTableRefferedValue span:first-child {
	display: none;
}

.genericTableGroupName.shortOneGroup .genericTableRefferedValue {
	top: -12px !important;
}

.genericTablePhaseNameGroup .genericTableContainer.refferedMethodology .genericTableRefferedValue {
	left: auto;
}

.genericTableSubGroupNameGroup.refredSubGroup.subGroupExpanded.shortOneSubGroup .genericTableSubPhase span {
	width: 23px;
}

.genericTablePhaseNameGroup.refredPhase.phaseExpanded .genericTableContainer.refferedMethodology .genericTableMethodology.methodExpanded .genericTableRefferedValue {
	top: 31px;
}

.genericTableComplexityMenuContainer.complexityCollapsed .genericTableComplexityMenuHeader>div {
	bottom: 20px !important;
}

.genericTableGroupNameGroup.raciReferredGroup.groupExpanded .genericTableRefferedValue {
	top: 6px;
}

.genericTableRefferedValue {
	display: flex;
	transform: rotate(-90deg);
	height: 29px;
	font-weight: 600;
	font-size: 12px;
	margin-left: 9px;
	margin-top: 7px;
}

.genericTableRefferedValue span.activityReferredIcon {
	margin-top: 5px;
	margin-left: 10px;
}

.genericTablePhaseNameGroup.refredPhase.phaseExpanded .genericTableRefferedValue {
	top: 7px;
}

.genericTableActivity.refredActivity {
	border-left: 3px solid #5f56f5;
}

.genericTableContainer.refferedMethodology {
	border-left: 3px solid #5f56f5;
}

.genericTableGroupNameGroup.raciReferredGroup {
	border-left: 3px solid #5f56f5;
}

.genericTablePhaseNameGroup.refredPhase.phaseExpanded {
	border-left: 3px solid #5f56f5;
}

.genericTableCloseIconValue {
	position: absolute;
	top: 7px;
	left: 4px;
}

.genericTableContainer.refferedMethodology .genericTablePhaseNameGroup.refredPhase.phaseExpanded {
	border-left: 0;
}

.genericTableContainer.refferedMethodology .genericTablePhaseNameGroup.refredPhase.phaseExpanded .genericTableGroupNameGroup.raciReferredGroup {
	border-left: 0;
}

.genericTableContainer.refferedMethodology .genericTableGroupNameGroup.raciReferredGroup .genericTableActivity.refredActivity {
	border-left: 0;
}

.genericTableRefferedValue {
	position: absolute;
}

.separation .genericTableSubGroupNameGroup .genericTableSubPhase .genericTableValue {
	display: inline-flex;
}

.raciGridView .genericTableSubGroupNameGroup.shortOneSubGroup .genericTableSubPhase i[data-icon-name='DoubleChevronLeft12'],
.raciGridView .genericTableSubGroupNameGroup.shortOneSubGroup .genericTableSubPhase i[data-icon-name='DoubleChevronRight12'] {
	margin: 0 5px;
}

.genericTableComplexityRolesMenu.roleGroupCollapsed .genericTableComplexityMenuHeader>div>div,
.genericTableComplexityMenuContainer.complexityCollapsed .genericTableComplexityMenuHeader>div>div {
	width: 85px;
	overflow: hidden;
	text-overflow: ellipsis;
	transform: rotate(-90deg);
	position: absolute;
	bottom: 8px;
}

.genericTableComplexityRolesMenu.roleGroupCollapsed .genericTableComplexityMenuHeader,
.genericTableComplexityMenuContainer.complexityCollapsed .genericTableComplexityMenuHeader {
	width: 30px;
	display: flex;
	flex-direction: column-reverse;
	height: 95px;
	align-items: center;
	position: relative;
	justify-content: flex-end;
}

.genericTablePreview.raciRoleGroupCollapsed {
	margin-right: 0px;
	padding: 15px;
	max-width: 30px !important;
}

.genericTableComplexityRolesMenu.roleGroupExpanded.shortOneRoleMenu i[data-icon-name='DoubleChevronLeft12'],
.genericTableComplexityRolesMenu.roleGroupExpanded.shortOneRoleMenu i[data-icon-name='DoubleChevronRight12'] {
	display: none;
}

.genericTableMethodology.methodExpanded.shortZeroSolutionMethod .genericTableRefferedValue span:first-child {
	display: none;
}

.genericTableMethodology.methodExpanded.shortZeroSolutionMethod .genericTableValue i {
	margin: 0 5px 0 1px;
}

.genericTableMethodology.methodExpanded.shortZeroSolutionMethod .genericTableValue span {
	width: 28px;
	display: inline-block;
	overflow: hidden;
	text-overflow: ellipsis;
	text-align: center;
}

.genericTableMethodology.methodExpanded.shortZeroSolutionMethod .genericTableRefferedValue {
	top: -14px !important;
}

.genericTablePhaseNameGroup.refredPhase.phaseExpanded .genericTableContainer.refferedMethodology .genericTableMethodology.methodExpanded.shortZeroSolutionMethod .genericTableRefferedValue {
	top: -14px !important;
}

.genericTablePhaseName.shortZeroPhase .genericTableValue span {
	width: 15px;
	display: inline-block;
	overflow: hidden;
	text-overflow: ellipsis;
	text-align: center;
}

.genericTablePhaseName.shortZeroPhase .genericTableValue i {
	margin: 0 5px 0 0;
	display: none;
}

.genericTablePhaseName.shortZeroPhase .genericTableRefferedValue span:first-child {
	display: none;
}

.genericTablePhaseName.shortZeroPhase .genericTableRefferedValue {
	top: -21px !important;
}

.separation.phaseExpanded.NoActivitiesPresent .genericTableNoActivitiesValue {
	flex: 1;
	justify-content: center;
	font-weight: 600;
	color: #0078d4;
}

.separation.phaseExpanded.NoActivitiesPresent {
	display: flex;
	flex-direction: row;
	width: 84%;
}

.separation.phaseExpanded.NoActivitiesPresent .genericTableValue {
	display: none;
}

.genericTablePhaseNameGroup.refredPhase.phaseExpanded .genericTableRefferedValue {
	top: -21px;
}

.genericTablePhaseNameGroup.refredPhase.phaseExpanded .genericTableRefferedValue>span:first-child {
	display: none;
}

.genericTableMethodology.methodExpanded.shortSolutionMethod .genericTableRefferedValue>span:first-child {
	display: none;
}

.genericTableMethodology.methodExpanded.shortSolutionMethod .genericTableRefferedValue {
	top: 0;
}

.genericTableGroupName.shortFourGroup .genericTableValue span {
	width: 150px;
	overflow: hidden;
	text-overflow: ellipsis;
}

.genericTablePhaseNameGroup.phaseCollapsed .genericTablePhaseName .genericTableValue span {
	width: auto !important;
}

.genericTableMethodology.methodExpanded.shortSolutionMethodshortThreeSolutionMethod .genericTableValue span {
	width: 100px;
	overflow: hidden;
	text-overflow: ellipsis;
	display: inline-block;
}

.genericTableMethodology.methodExpanded.shortSolutionMethodshortThreeSolutionMethod .genericTableValue {
	position: absolute;
}

.genericTablePhaseName.shortFourPhase .genericTableValue span {
	width: 150px;
	overflow: hidden;
	text-overflow: ellipsis;
	display: inline-block;
}

.genericTableMethodology.methodExpanded.shortSolutionMethod.shortThreeSolutionMethod .genericTableValue span {
	width: 93px;
}

.genericTableMethodology.methodExpanded.shortSolutionMethod.shortTwoCollapsedSolutionMethod .genericTableValue span {
	width: 54px;
}

.genericTableContainer.refferedMethodology .genericTableMethodology.methodExpanded.shortTwoSolutionMethod .genericTableRefferedValue {
	top: 0 !important;
}

.genericTableMethodology.methodExpanded.shortTwoSolutionMethod .genericTableValue {
	position: absolute;
	bottom: 53px;
}

.genericTableMethodology.methodExpanded.shortSolutionMethod.shortOneCollapsedSolutionMethod .genericTableValue {
	position: absolute;
}

.genericTableMethodology.methodExpanded.shortSolutionMethod.shortOneCollapsedSolutionMethod .genericTableValue span {
	width: 20px;
}

.genericTablePhaseNameGroup.refredPhase.phaseExpanded .separation.phaseExpanded {
	width: 97%;
}

#box-section {
	width: 28.4%;
	padding-right: 15px;
	max-width: 28%;
}

#header-complexity {
	width: 71.3%;
	padding-right: 5px;
	padding-left: 0;
}

.box {
	height: 174px;
	position: relative;
	overflow: hidden;
}

.box1 {
	width: 100%;
	position: absolute;
	opacity: 0.8;
	background-color: #f9f9f9;
	padding: 5px 10px;
	height: 174px;
}

.box2 {
	z-index: 10;
	margin: 30px 10px;
	background-color: #EEF3F8;
	width: 100%;
	height: 133px;
}

.box3 {
	z-index: 12;
	margin: 57px 20px;
	width: 100%;
	background-color: #DAE8F6;
	height: 89px;
}

.box4 {
	z-index: 14;
	margin: 83px 30px;
	width: 100%;
	height: 51px;
	background-color: #C0DAF3;
}

.box5 {
	z-index: 16;
	margin: 109px 40px;
	width: 100%;
	height: 38px;
	background-color: #ffffff;
}

.accordion {
	margin-bottom: 15px;
	box-sizing: border-box;
}

.accordion .card {
	border: 0;
}

.accordion .card-body {
	padding: 5px 0px 15px 8px;
}

.accordion button:focus {
	text-decoration: none;
}

.methodology-accordion.card {
	background-color: #F9F9F9;
	border: 0;
	border-radius: 0;
	padding-right: 8px;
}

.card-header {
	display: flex;
}

.methodology-accordion .card-header {
	background-color: inherit;
	border: 0;
	padding: 7px;
}

.methodology-accordion .card-header button {
	padding: 0;
	color: #2B314C;
	font-size: 16px;
	font-weight: bold;
}

.methodology-arrow-icon,
.phase-arrow-icon {
	margin-right: 10px;
	font-size: 12px;
	color: #f58220;
}

.phase-accordion.card {
	background-color: #EEF3F8;
	/* padding-right: 5px; */
}

.phase-accordion .accordion-heading span {
	font-size: 14px;
	color: #6c6c6c;
}

.activityValue {
	box-shadow: 0px 3px 6px #00000029;
	color: #646465;
	font-size: 12px;
	padding: 5px;
	background-color: white;
}

.activityValue a {
	color: #646465;
}

.activityGrid {
	/* padding: 0px 15px; */
	margin-bottom: 5px;
}

.groupGrid {
	background-color: #DAE8F6;
	padding: 10px 5px 10px 10px;
	margin-bottom: 10px;
	border-left: 3px solid transparent;
}

.subgroupGrid {
	background-color: #C0DAF3;
	padding: 10px 0px 10px 10px;
	margin-bottom: 10px;
	border-left: 3px solid transparent;
}

.groupGrid-heading {
	color: #666666;
	font-size: 12px;
	padding: 5px 0;
	font-weight: bold;
}

.accordion .activityReferredIcon {
	float: right;
	margin-top: 4px;
	margin-right: 0;
	background: url(../../assets/linkIcon.svg) no-repeat;
}

.referredMethodologyText,
.referredphaseText,
.nonreferredphaseText,
.nonreferredMethodologyText {
	font-weight: bold;
	color: #6c6c6c;
}

.referredMethodologyText .activityReferredIcon,
.referredphaseText .activityReferredIcon {
	margin-left: 10px;
}

.raci-table-container {
	display: flex;
	flex-flow: column nowrap;
	background-color: transparent;
}

.raci-table-container .table-row {
	display: flex;
	flex-flow: row nowrap;
	width: 100%;
	border-bottom: 1px solid #dadada;
}

.raci-table-container .heading {
	background-color: #ececec;
	color: #3e3e3e;
	font-weight: bold;
}

.raci-table-container .row-item {
	display: flex;
	flex: 1;
	font-size: 12px;
	font-weight: 600;
	text-transform: uppercase;
	padding: 5px 0;
	justify-content: center;
	align-items: center;
	transition: all 0.15s ease-in-out;
}

.methodologyName {
	font-size: 14px;
	color: #6c6c6c;
	font-weight: 700;
	margin-top: 2px;
}

.raci-table-container .table-row:last-child,
.row-sub-container .row-item:last-child {
	border-bottom: 0;
}

.methodology-accordion .genericTableCloseIconValue {
	position: relative;
	top: initial;
	left: initial;
	margin: 5px 10px 0px 5px;
	cursor: pointer;
}

.methodology-accordion .genericTableCloseIconValue:hover i {
	font-weight: bold;
}

.accordion-heading {
	text-decoration: none;
}

.accordion-heading:hover i {
	font-weight: bold;
}

.accordion .btn-link:hover {
	text-decoration: none;
}

.referredMethodologyAccordion,
.referredPhaseAccordion {
	border-left: 3px solid #5F56F5;
}

.methodologyAccordion,
.phaseAccordion {
	border-left: 3px solid transparent;
}

.rotateArrow {
	transform: rotate(180deg);
	-o-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	-webkit-transform: rotate(180deg);
}

.iconDisable {
	color: #cccccc;
	display: none;
}

.activityLink {
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 88%;
	overflow: hidden;
	float: left;
	/* height: 23px; */
	padding: 2px;
}

.activityLink a:focus,
.activityLink a:active {
	outline: 1px dotted;
}

.orphanRole {
	width: 120px !important;
}

.genericTableGroupNameGroup.raciReferredGroup.groupExpanded .genericTableGroupName.shortThreeGroup .genericTableRefferedValue {
	top: -8px;
}

.genericTableGroupNameGroup.raciReferredGroup.groupExpanded .genericTableGroupName.shortTwoGroup .genericTableRefferedValue {
	top: -8px;
}

.genericTableGroupNameGroup.raciReferredGroup.groupExpanded .genericTableGroupName.shortFourGroup .genericTableRefferedValue {
	top: -8px;
}

.genericTableGroupNameGroup.raciReferredGroup.groupExpanded .genericTableGroupName.shortFourGroup .genericTableRefferedValue span:first-child {
	display: none;
}

.solutionMethodName {
	font-weight: 600;
}

.phase-accordion.card .activityRow {
	margin-left: 0;
}

.groupGrid .activityRow {
	margin-left: initial !important;
}

.accordion-container {
	padding: 5px 15px 5px 10px;
	background-color: white;
}

.accordion-container .accordion-container {
	padding: 0;
	background-color: inherit;
}

.activityValue.level2 {
	max-width: 25%;
}

.activityValue.level3 {
	padding-left: 5px;
	max-width: 24.5%;
}

.activityValue.level4 {
	max-width: 23.8%;
}

.activityValue.level5 {
	max-width: 23.1%;
}

.activityValue.level6 {
	max-width: 22.6%;
}

.raciRolesGroupUi.shortOneRoleMenu {
	margin: 0 4px;
}

.raciTableRow .genericTableComplexityMenu.raciComplexityCollapsed {
	min-width: 23px;
}

.genericTablePreview.raciRoleGroupCollapsed {
	min-width: 42px;
}

.disableIcon {
	opacity: 0.6;
	visibility: hidden;
	pointer-events: none;
	color: #6c6c6c !important;
}

.groupGrid .rightGrid {
	padding-right: 5px;
}

.groupGrid.raciReferredGroup .rightGrid {
	padding-right: 0px;
}

.groupGrid.raciReferredGroup {
	padding-right: 0;
}

.subgroupGrid .rightGrid {
	padding-right: 0;
}

.referredPhaseAccordion.accordion .rightGrid {
	padding-right: 0;
}

@media screen and (min-width: 1090px) and (max-width: 1094px) {
	#box-section {
		width: 28.8%;
		max-width: 28.8%;
	}

	#header-complexity {
		width: 70.3%;
	}
}

@media screen and (min-width: 1241px) and (max-width: 1365px) {
	#box-section {
		width: 28.6%;
		max-width: 28.6%;
	}

}

@media screen and (min-width: 1516px)and (max-width: 1707px) {
	#box-section {
		width: 27%;
		max-width: 27%;
	}
}

@media screen and (min-width: 1367px)and (max-width: 1720px) {
	#box-section {
		width: 27.7%;
		max-width: 27.7%;
	}
}

@media screen and (min-width: 1519px) and (max-width: 1600px) {
	/* .genericTableComplexityRaciShow.complexity1 .genericTableComplexityMenu {
		width: 1050px;
	} */

	/* .genericTableComplexityMenu.complexity1 .genericTableComplexityMenuSub {
		width: 1050px;
	} */

	/* .genericTableComplexityRaciShowColumns {
		width: 1050px;
	} */

	/* .genericTableComplexityMenu.complexity2 .genericTableComplexityMenuSub {
		width: 515px;
	} */

	/* .genericTableComplexityRaciShow.complexity2 .genericTableComplexityMenu {
		width: 515px;
	}
 */
	/* .genericTableComplexityMenu.complexity3 .genericTableComplexityMenuSub {
		width: 342px;
	} */

	/* .genericTableComplexityRaciShow.complexity3 .genericTableComplexityMenu {
		width: 350px;
	} */

	/* .genericTableComplexityMenu.complexity4 .genericTableComplexityMenuSub {
		width: 255px;
	}
 */
	/* .genericTableComplexityRaciShow.complexity4 .genericTableComplexityMenu {
		width: 265px;
	} */
	/* #box-section {
		width: 28%;
	} */
}

@media screen and (min-width: 1601px) and (max-width: 1700px) {
	.genericTableActivity {
		width: 320px;
	}

	.genericTableActivity .genericTableValue {
		width: 300px;
		padding: 0px 0 0 10px;
	}
}

@media screen and (min-width: 1701px) and (max-width: 1800px) {
	.genericTableActivity {
		width: 420px !important;
	}

	.genericTableActivity .genericTableValue {
		width: 400px;
		padding: 0px 0 0 10px;
	}
}

@media screen and (min-width: 1801px) and (max-width: 1900px) {
	.genericTableActivity {
		width: 520px !important;
	}

	.genericTableActivity .genericTableValue {
		width: 500px;
		padding: 0px 0 0 10px;
	}
}

@media screen and (min-width: 1901px) and (max-width: 2000px) {
	.genericTableActivity {
		width: 620px;
	}

	.genericTableActivity .genericTableValue {
		width: 600px;
		padding: 0px 0 0 10px;
	}
}

@media screen and (min-width: 2001px) and (max-width: 2100px) {
	.genericTableActivity {
		width: 620px;
	}

	.genericTableActivity .genericTableValue {
		width: 600px;
		padding: 0px 0 0 10px;
	}
}

@media screen and (min-width: 2100px) and (max-width: 2200px) {
	.genericTableActivity {
		width: 620px;
	}

	.genericTableActivity .genericTableValue {
		width: 600px;
		padding: 0px 0 0 10px;
	}
}

@media screen and (min-width: 1367px) {
	#box-section {
		width: 28%;
	}
}

@media screen and (min-width: 1567px) {
	#box-section {
		width: 27%;
	}

	.activityLink {
		max-width: 88%;

	}
}

@media screen and (min-width: 1633px) {
	.activityValue.level4 {
		max-width: 24%;
	}

	#header-complexity {
		width: 72.5%;
	}
}

.mandatoryIcon {
	background: url(../../assets/icon-M.svg) no-repeat;
	height: 20px;
	width: 13px;
	white-space: pre;
	position: relative;
	top: 5px
}

.businessIcon {
	background: url(../../assets/icon-B\ 1.svg) no-repeat;
	height: 20px;
	width: 13px;
	white-space: pre;
	position: relative;
	top: 5px;
}

.raci {
	display: inline-block;
	top: 12px;
}

.businessIconTopics {
	top: 7px !important
}

.raciIcont {
	height: 20px;
	width: 13px;
	display: inline-block;
}

.topicIcon {
	width: 13px;
}