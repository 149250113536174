.customToolsEditor {
	min-height: 200px;
	margin: 0;
	width: 100%;
	display: flex;
	word-break: break-word;
}
.historicalPreview .customToolsEditor {
	margin-bottom: 30px;
}
.activityCrowdSourcing .customToolsEditor {
	margin: 0 0 32px 10px;
}
.toolsHeaderEditor {
	border-bottom: 1px solid #c4c4c4;
	padding: 8px 10px;
	float: left;
	width: 100%;
}
.toolsHeaderEditor h6 {
	margin-bottom: 0;
	font-size: 14px;
	font-weight: 400;
	display: inline-block;
	float: left;
	margin: 4px 2px;	
}
.selectTools h6{
	position: relative;
    top: 8px;
}
.ToolsContentContainer {
	display: flex;
	padding: 10px;
}
.ToolsContentContainer ul li {
	position: relative;
}
.ToolsContentContainer ul {
	display: flex;
	flex-direction: column;
	width: 100%;
}
.templateContentContainer {
	display: flex;
	padding: 10px;
}
.templateContentContainer ul li {
	position: relative;
}
.templateContentContainer ul {
	display: flex;
	flex-direction: column;
	width: 100%;
}
.sideBarCard {
	padding: 7px;
	width: 270px;
	transition: top 0.3s ease, box-shadow 0.3s ease;
	border-radius: 2px;
	background: #fff;
	box-shadow: 0 1px 1px 1px #e6e6e6;
	margin: 4px;
}
.sideBarCardLatest{
	/* width: 251px !important; */
	width: 251px !important;
}
.sideBarCardHeader i {
	float: right;
	cursor: pointer;
	padding: 1px 3px;
}
div#toolsAndGuidanceMultiSelectFilter .dropDowncontainer {
	margin-left: 30px;
}
.ToolsContentContainer ul li > i {
	border: 1px solid #ccc;
	font-size: 8px;
	margin-left: 10px;
	cursor: pointer;
}
.templateContentContainer ul li > i {
	border: 1px solid #ccc;
	font-size: 8px;
	margin-left: 10px;
	cursor: pointer;
}

li.userSelectedToolsAndGuidance .userAddedItem a {
	background: #b2fbb8;
	border-top: 1px solid #4ce858;
	padding: 1px 6px;
	border-bottom: 1px solid #4ce858;
}
.userDeletedToolsAndGuidance a {
	background: rgba(231, 92, 127, 0.35);
	border-top: 1px solid rgba(174, 30, 66, 0.35);
	padding: 1px 6px;
	border-bottom: 1px solid rgba(174, 30, 66, 0.35);
}
li.userDeletedCriteria {
	background: rgba(231, 92, 127, 0.35);
	border-top: 1px solid rgba(174, 30, 66, 0.35);
	padding: 1px 6px !important;
	border-bottom: 1px solid rgba(174, 30, 66, 0.35);
}
li.userAddedCriteria {
	background: #b2fbb8;
	border-top: 1px solid #4ce858;
	padding: 1px 6px !important;
	border-bottom: 1px solid #4ce858;
}

.sideBarCardHeader > span {
	font-size: 10px;
	font-weight: 700;
	max-width: 150px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	color: #757575;
}
.sideBarCard .itemStatus {
	font-size: 13px;
	padding: 2px 28px 0px 28px;
}
.sideBarCardHeader img.userImg {
	width: 24px;
	height: 24px;
	margin-top: 0;
	border: 2px solid #3670ab;
	margin-right: 8px;
}
li.userSelectedToolsAndGuidance .userAddedItem > i {
	cursor: pointer;
	font-size: 8px;
	border: 1px solid #ccc;
}
.userAddedItem.sideBarCard {
	border-left: 3px solid #5ad86b;
}
.sideBarCard .itemStatus span:first-child {
	font-weight: 700;
	font-size: 13px;
}
.userDeletedToolsAndGuidance.sideBarCard {
	border-left: 3px solid #d85a79;
}

.historicalPreview .userDeletedToolsAndGuidance.sideBarCard {
	border-left: 7px solid #d85a79;
}

.historicalPreview .userAddedItem.sideBarCard {
	border-left: 7px solid #5ad86b;
}

.sideBarCard i[data-icon-name='Cancel']:hover {
	color: #d85a79;
	background: #e4e4e4;
	font-weight: bold;
}

.sideBarCard i[data-icon-name='Accept']:hover {
	color: #5ad86b;
	background: #e4e4e4;
	font-weight: bold;
}
.previewDescOfActivity.copyToComplexityDiv {
	display: flex;
	width: 100%;
}
/* .previewDescOfActivity.copyToComplexityDiv .sourceComplexityContainer {

} */

.destinationComplexityContainer .Detailswrapper.complexitySelection {
	display: flex;
	padding: 10px 10px 0 10px;
}
.destinationComplexityContainer
	.Detailswrapper.complexitySelection
	.NewActivitycomplexityWRapper {
	display: flex;
	flex-direction: column;
}
.destinationComplexityContainer
	.Detailswrapper.complexitySelection
	.NewActivitycomplexityWRapper
	label.NotificationChkboxContainer
	i {
	font-weight: 600;
	margin-left: 10px;
	vertical-align: text-bottom;
	margin-top: 1px;
}
.showMoreMenu.complexityPopUp {
	width: 623px;
	left: auto;
	right: -238px;
}
.showMoreMenu.complexityPopUp span.showMoreArrow {
	left: 58%;
}
.selectingComplexityMessageBar p {
	font-size: 14px;
	color: #d90000;
	margin-bottom: 15px;
	background: #fdf4d2;
	padding: 9px 15px;
	font-weight: 600;
	border-radius: 6px;
	display: flex;
	line-height: 23px;
}
.showMoreMenu .selectingComplexitySections li {
	width: 30%;
	margin: 7px;
	background: none;
	border: none !important;
	padding: 0 !important;
	margin-bottom: 15px;
}
.selectingComplexitySectionsDiv h6 {
	color: #333;
	text-align: center;
	margin-bottom: 17px;
	font-size: 18px;
}
.Detailswrapper.complexitySelection .selectingComplexitySections ul {
	margin-bottom: 0;
	width: 100%;
	display: table;
}
.selectingComplexitySections {
	margin-bottom: 30px;
}
.Detailswrapper.complexitySelection ul li {
	/* background: #fff; */
	padding: 7px;
	/* border: 1px solid #0078d4; */
	display: flex;
	white-space: nowrap;
}
.destinationComplexityContainer
	.Detailswrapper.complexitySelection
	.NewActivitycomplexityWRapper
	> span {
	margin-bottom: 8px;
}
.previewDescOfActivity.copyToComplexityDiv
	.sourceComplexityContainer
	.Detailswrapper.complexitySelection {
	min-height: 76px;
}
.PreviewAndSubmitPopUp.activityPreviewAndSubmitModal .modal-dialog {
	max-width: 100%;
}
.previewDescOfActivity.copyToComplexityDiv
	.slectingSourceDestinationBar
	.Detailswrapper.complexitySelection
	h6 {
	margin-top: 18px;
	flex: 1;
}
.previewDescOfActivity.copyToComplexityDiv
	.Detailswrapper.complexitySelection
	> div
	> div {
	margin-top: 22px;
	color: #0078d4;
	font-weight: 600;
	display: flex;
}
.destinationComplexityContainer
	.Detailswrapper.complexitySelection
	.NewActivitycomplexityWRapper
	> div {
	display: flex;
}
.slectingSourceDestinationBar
	.Detailswrapper.complexitySelection:first-child
	> div {
	color: #0072c9;
	font-weight: 600;
	margin-top: 48px;
}
.slectingSourceDestinationBar
	.Detailswrapper.complexitySelection:first-child
	> h6 {
	margin-top: 48px !important;
	text-align: right;
}
.Detailswrapper.complexitySelection .NewActivitycomplexityWRapper > div > div {
	color: #0072c9;
	font-weight: 600;
}
.complexityDataSelection {
	position: relative;
	margin-left: 10px;
}
.complexityDataSelection i {
	font-weight: bold;
	color: #333;
	cursor: pointer;
}
.previewDescOfActivity.copyToComplexityDiv.showFullActivityPreview
	.resultedActivityComplexityContainer
	> div {
	width: 50%;
	padding-right: 5px;
}
.previewDescOfActivity.copyToComplexityDiv.doNotShowFullActivityPreview {
	flex-direction: column;
}
.slectingSourceDestinationBar {
	width: 100%;
	display: flex;
}
.slectingSourceDestinationBar .Detailswrapper.complexitySelection {
	width: 50%;
}
.slectingSourceDestinationBar
	.Detailswrapper.complexitySelection
	.NewActivitycomplexityWRapper {
	flex-direction: column;
}
.previewDescOfActivity.copyToComplexityDiv.showFullActivityPreview {
	flex-direction: column;
}
.slectingSourceDestinationBar .Detailswrapper.complexitySelection {
	width: 50%;
	padding: 10px 20px 0 20px;
}
.slectingSourceDestinationBar .Detailswrapper.complexitySelection:last-child {
	border-bottom: 1px solid #0078d4;
}
.PreviewAndSubmitPopUp.activityPreviewAndSubmitModal.copyComplexityWrapperModal
	.activityDescTitlePart
	.activityTitleRightPart {
	display: flex;
	flex-direction: column;
}
.PreviewAndSubmitPopUp.activityPreviewAndSubmitModal.copyComplexityWrapperModal
	.activityDescTitlePart {
	border: none;
}
.resultedActivityComplexityContainer {
	display: flex;
}
.Detailswrapper.complexitySelection ul li.selectedComplexities {
	border: 1px solid #0078d4;
	background: #fff;
	border-bottom: 1px solid #fff;
}
.Detailswrapper.complexitySelection ul {
	margin-bottom: -1px;
}
.Detailswrapper.complexitySelection ul li span.filterLabel {
	color: #333;
}
.Detailswrapper.complexitySelection
	ul
	li:first-child
	.showMoreMenu.complexityPopUp {
	left: -133px;
}
.Detailswrapper.complexitySelection
	ul
	li:first-child
	.showMoreMenu.complexityPopUp
	span.showMoreArrow {
	left: 19%;
}
.Detailswrapper.complexitySelection
	ul
	li:nth-child(2)
	.showMoreMenu.complexityPopUp {
	left: -253px;
}
.Detailswrapper.complexitySelection
	ul
	li:nth-child(2)
	.showMoreMenu.complexityPopUp
	span.showMoreArrow {
	left: 39%;
}
.aprroverStatusIconForCopy {
	width: 25px;
	height: 25px;
	background: url(../../assets/RedAlert.svg) no-repeat;
	float: left;
	background-size: contain;
	margin-right: 10px;
}
li.DisabledSelectComplexity {
	opacity: 0.7;
}
li.DisabledSelectComplexity label.NotificationChkboxContainer {
	cursor: unset;
}
.Detailswrapper.complexitySelection ul li span.filterLabel {
	cursor: pointer;
}
.Detailswrapper.complexitySelection ul {
	margin-bottom: -1px !important;
}
.rejectionRequiredFields {
	display: flex;
	flex-direction: column;
	margin-left: 30px;
}
.rejectionRequiredFields div {
	display: flex;
	border: 1px solid #ccc;
	min-height: 77px;
}
.rejectionRequiredFields textarea {
	border: none !important;
	margin-left: 0 !important;
}
.requiredField {
	color: red;
}
.rejectionRequiredFields.rejected > div {
	border: 1px solid red;
}

.deliverables-container h2 {
	padding: 12px;
    border: 1px solid #c4c4c4;
    border-bottom: 0;
    width: calc(100% - var(--ck-sample-sidebar-width));
    margin-bottom: 0;
	font-size: 14px;
}
