#rightPanel {
	width: 300px;
	position: fixed;
	top: 50px;
	right: 0;
	height: 93%;
	flex-direction: column;
	min-width: 0;
	word-wrap: break-word;
	background-color: #fff;
	background-clip: border-box;
	border: 1px solid rgba(0, 0, 0, 0.125);
	border-radius: 0.25rem;
	overflow: auto;
}

/* Accessibility fix  */
@media only screen and (min-width: 323px) and (max-width: 649px) {
	#rightPanel {
		width: 100% !important;
		height: -webkit-fill-available;
	}
}

/* To fix accessibility */
.closeButtonIcon {
	border: none;
	background: white;
	float: right;
}

#rightPanel .rightPanelTitle {
	font-size: 18px;
	font-weight: 600;
	font-family: 'Segoe UI', sans-serif;
	padding: 16px 10px;
	display: flex;
}

.rightPanelBody {
	display: flex;
	width: 100%;
	flex-direction: column;
	font-family: 'Segoe UI', sans-serif;
	/* height: 70%; */

}

.backButton {
	box-shadow: none;
	background: #fff;
	border: none;
	vertical-align: middle;
}

button.backButton i {
	font-size: 17px;
	margin-right: 6px;
}

button.closeButtonIcon i {
	font-weight: bold;
	vertical-align: sub;
}

.notifySettingsButton {
	display: inline-block;
	margin-right: 50px;
	color: #f58220;
	border-radius: 4px;
	line-height: normal;
	font-weight: normal;
	font-size: 14px;
	cursor: pointer;
}

.notifySettingsButton i {
	margin-left: 15px;
	vertical-align: middle;
	margin-bottom: 2px;
}

.notificationExpandFwdButton i {
	vertical-align: unset;
	font-size: 12px;
	font-weight: 600;
	margin-right: 9px;
	margin-top: 5px;
}

.notificationExpandFwdButton {
	display: inline-block;
	cursor: pointer;
}

.notificationPanel h6 {
	display: inline-block;
}

#rightPanel .rightPanelTitle h6 {
	flex: 1;
}

.notificationSettingsIcon {
	float: left;
	width: 34px;
	height: 28px;
	background: url(../../assets/SettingsIcon.svg) no-repeat;
	background-size: contain;
}

#rightPanelClose:focus,
.notifySettingsButton:focus {
	outline: 1px dotted #333;
}

.fai-Textarea textarea {
	outline: none !important;
}

#h6DropdownMainId {
	z-index: 9999;
	outline: none !important;
}