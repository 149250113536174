.mySettingsRightPanel {
	margin-top: 20px;
	padding: 20px;
	margin-top: 43px;
}
.settingsContainer {
	background: #f8f8f9;
	padding: 10px;
}
.settingsContainer h4 {
	font-size: 15px;
}
.settingsContainer h4 i {
	margin-right: 13px;
}

.selectionOfSolMethod {
	display: flex;
	flex-direction: column;
	padding-top: 10px;
}
.selectionOfSolMethod label.checkBoxContainer input[type='radio']:focus {
	opacity: 0.5;
	margin: 8px 0 0 -30px;
}
.selectionOfSolMethod label.checkBoxContainer input[type='radio'] {
	margin: 0 0 0 -30px;
}
.selectionOfSolMethod label.checkBoxContainer .settingsLabel {
	margin-left: 30px;
}
.consultingSetting {
	display: flex;
	flex-direction: column;
}
.checkBoxContainer {
	display: block;
	/* align-items: center; */
	position: relative;
	padding-left: 30px;
	padding-bottom: 12px;
	cursor: pointer;
	font-size: 23px !important;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	line-height: 1;
	margin-bottom: 0;
}
.checkBoxContainer .settingsLabel {
	position: relative;
	margin-left: 0px;
	display: block;
	font-size: 14px;
	display: inline-block;
	font-weight: 400;
}
.checkBoxContainer input {
	opacity: 0;
	cursor: pointer;
}
.checkmark {
	position: absolute;
	top: 8px;
	left: 0;
	height: 14px;
	width: 14px;
	background-color: #fff;
	border: 1px solid rgba(102, 102, 102, 1);
	border-radius: 50%;
	margin-top: 3px;
}
.checkmarkRadioButton{
	margin-top:0!important;
	top: 9px!important;
}
.checkBoxContainer .checkmark:after {
	position: absolute;
	opacity: 1;
	left: 2px;
	top: 2px;
	color: #fff;
	font-size: 21px;
	font-weight: normal;
	content: '';
	height: 8px;
	width: 8px;
	background-color: #3291e7;
	border: 2px solid #3291e7;
	border-radius: 50%;
}
.checkBoxContainer input:checked ~ .checkmark:after {
	display: block;
}
.checkmark:after {
	content: '';
	position: absolute;
	display: none;
}
.checkBoxContainer input:checked ~ .checkmark {
	border: 1px solid #3291e7;
}
label.checkBoxContainer:focus {
	outline: 1px dotted #333;
}
