.templateModal {
	width: 80%;
	max-width: 80% !important;
}

#templatePreviewIframe {
	position: relative;
	width: 100%;
	height: 70vh;
}

.previewIcon {
	float: left;
	width: 17px;
	height: 12px;
	background-size: contain;
	background: url(../../assets/PreviewIcon.svg) no-repeat;
}

.previewIconDisabled {
	float: left;
	width: 17px;
	height: 12px;
	background-size: contain;
	background: url(../../assets/PreviewIconDisabled.svg) no-repeat;
}

.previewIconBlack {
	display: none;
	float: left;
	width: 17px;
	height: 12px;
	background-size: contain;
	background: url(../../assets/EyeHover.svg) no-repeat;
}

.templateTabsSearch .tabTitle button:hover .previewIconBlack {
	display: block;
}

.templateTabsSearch .tabTitle button:hover .previewIcon {
	display: none;
}

.templateTabsSearch .tabTitle button {
	border: none;
	background: #fff;
	padding: 0;
	margin: 13px 0 0 18px;
	position: relative;
}

.templateTabsSearch .tabTitle button:focus {
	outline: 1px dotted #333 !important;
}

.tabTitle a:focus {
	outline: 1px dotted #333;
}

/* FeedBack PopUp Starts Here*/

.templateFeedbackPopUp .modal-dialog.modal-sm.modal-dialog-centered {
	max-width: 370px;
}

.templateFeedbackPopUp .modal-header {
	background: #0078d4;
	color: #fff;
	border-radius: unset;
}

.templateFeedbackPopUp .modal-content {
	border: none;
	border-radius: unset;
}

.templateFeedbackPopUp .modal-body p {
	text-align: center;
	padding: 0px 20px 20px 20px;
}

.templateFeedbackPopUp textarea {
	width: 320px;
	min-height: 120px;
	background: #f5f6fa;
	border: none;
	font-size: 13px;
	padding: 5px;
}

.templateFeedbackPopUp textarea:focus {
	outline: none;
}

.templateFeedbackPopUp .modal-body {
	padding: 24px;
}

.templateFeedbackPopUp button {
	width: 152px;
	padding: 7px;
	border: none;
}

.templateFeedbackPopUp button.feedbackPopUpSubmit {
	color: #fff;
	background: #0078d4;
	margin-left: 15px;
}

.templateFeedbackPopUp button.feedbackPopUpCancel {
	background: #43425d;
	color: #fff;
	margin-top: 18px;
}

.feedbackThumbUp {
	float: left;
	width: 30px;
	height: 28px;
	background-size: contain;
	background: url(../../assets/ThumsUpGrey.svg) no-repeat;
}

.feedbackThumbDown {
	float: left;
	width: 30px;
	height: 28px;
	background-size: contain;
	background: url(../../assets/ThumsDownGrey.svg) no-repeat;
}

.feedbackThumbUpOrange {
	float: left;
	width: 30px;
	height: 28px;
	background-size: contain;
	background: url(../../assets/ThumsUpOrange.svg) no-repeat;
}

.feedbackThumbDownOrange {
	float: left;
	width: 30px;
	height: 28px;
	background-size: contain;
	background: url(../../assets/ThumsDownOrange.svg) no-repeat;
}

.feedbackYesDiv {
	display: flex;
	flex-direction: column;
	margin-left: auto;
	margin-right: 45px;
	cursor: pointer;
}

.feedbackNoDiv {
	display: flex;
	flex-direction: column;
	margin-right: auto;
	margin-left: 20px;
	cursor: pointer;
}

.templateFeedbackPopUpDiv {
	display: flex;
	margin-bottom: 30px;
}

.feedbackSubmittedOk {
	background: #3b86ff;
	color: #fff;
	margin: 0 80px;
}

/* FeedBack PopUp Ends Here*/

.templateModal .table thead th {
	background: #333;
	color: #fff;
}

.templateModal .imageOfSearchResult {
	margin-left: 0;
	margin-right: 5px;
	width: 20px;
	height: 20px;
	margin-bottom: 0;
}

.templateModal .table-striped tbody tr:nth-of-type(odd) {
	background-color: #f3f2f1;
}

.templateModal .table {
	font-size: 12px;
	overflow: auto;
}

.templateModal tbody {
	font-size: 11px;
	color: #131212;
	font-family: 'Segoe UI', 'SF Pro Text';
}

.templateModal .modal-title {
	font-size: 15px;
	width: 100%;
	font-weight: 600;
}

.templateModal .modal-header {
	padding: 15px 15px;
	border-bottom: none;
}

.templateModal .table td button.templateViewDocument {
	border: none;
	background: none;
	color: #0062ad;
}

.templateModal .table td button.templateViewDocument:focus {
	outline: none;
}

.templateModal .submitSampleButton {
	border: none;
	background: #0078d4;
	color: #fff;
	padding: 3px 10px;
	border-radius: 3px;
	font-size: 13px;
	margin-left: auto;
	margin-right: 29px;
	white-space: nowrap;
}

.favoriteStar {
	float: left;
	width: 14px;
	height: 14px;
	background-size: contain;
	background: url(../../assets/favoriteStar.svg) no-repeat;
}

.favoriteSolid {
	float: left;
	width: 14px;
	height: 14px;
	background-size: 14px 14px;
	background: url(../../assets/favoritesSolidYellow.svg) no-repeat;
}

.disabledTemplateOption {
	background: grey !important;
	pointer-events: none !important;
	cursor: none !important;
	opacity: 0.5;
}

.typeAndPubDataOfTemplate {
	display: flex;
}

.typeOfTemplate {
	margin-right: 0px;
}

.publishedDateOfTemplate {
	margin-left: 30px;
}

.typeOfTemplate span:first-child,
.publishedDateOfTemplate span:first-child {
	font-weight: 600;
	font-size: 14px;
}

.typeAndPubDataOfTemplate span {
	font-size: 12px;
}

.templateTabsSearch .tabTitle a:hover .tabsTitleToolTip {
	display: block;
}

.tabsTitleToolTip {
	list-style-type: none !important;
	list-style: none !important;
	background-color: #000 !important;
	border: 1px solid #000 !important;
	width: auto;
	left: 15%;
	box-shadow: 0px 1px 4px #ccc;
	z-index: 99;
	top: 34px;
	position: absolute !important;
	font-size: 13px;
	padding: 6px 27px;
	color: #fff;
	border-radius: 3px;
	display: none;
}

.tabsToolTipArrow {
	float: left;
	width: 31px;
	height: 9px;
	background-size: contain;
	position: absolute;
	top: -10px;
	left: 39px;
	background: url(../../assets/TooltipBeak.png) no-repeat;
}

.templateTabsSearch .tabTitle button:hover .tabsTitleToolTip,
.templateTabsSearch .tabTitle button:focus .tabsTitleToolTip {
	display: block;
}

.templateTabsSearch .tabTitle button .tabsTitleToolTip {
	top: 18px;
	left: -50px;
}

button.previewDownload {
	border: none;
	background: #0078d4;
	color: #fff;
	padding: 5px 10px;
	border-radius: 3px;
	margin-top: 8px;
	font-size: 13px;
	float: right;
}

button.previewDownload a {
	color: #fff;
	text-decoration: none !important;
}

.templateModal .modal-content .container {
	padding: 0px 0 0 0 !important;
	margin-top: 17px;
	margin: 0;
	max-width: 100%;
}

.existingHashTags {
	display: flex;
}

.existingHashTags span {
	color: #0078d4;
	cursor: pointer;
	margin: 2px 5px;
}

.dragDropButton {
	border: none;
	background: #0078d4;
	color: #fff;
	padding: 3px 20px;
	border-radius: 3px;
	font-size: 13px;
}

.sampleSubmitButton {
	border: none;
	background: #0078d4;
	color: #fff;
	padding: 3px 20px;
	border-radius: 3px;
	font-size: 13px;
	float: right;
	margin-top: 10px;
}

.sampleSubmitTemplatePopUp .modal-header {
	border-bottom: 1px solid #e5e5e5;
}

.sampleSubmitTemplatePopUp .modal-body {
	font-size: 13px;
}

.templateModal .modal-content .container li {
	color: #000;
}

.templateModal .modal-content .container li button {
	border: none;
	background-color: rgb(240, 242, 248);
	margin-left: 10px;
}

.templateFeedbackPopUp .modal-header i {
	cursor: pointer;
	color: #fff;
	font-weight: bolder;
}

.templateModal .modal-header i {
	color: #333;
	font-weight: bold;
	cursor: pointer;
}

.templateModal .modal-header div {
	display: inline-block;
	float: right;
}

.submitSampleFilterDiv {
	display: flex;
	font-size: 12px;
}

.sampleSubmitTemplatePopUp .detailsOfSampleTemplate {
	font-size: 12px;
}

.regionDiv,
.CountryDiv,
.languageDiv {
	display: flex;
}

.regionDiv>span,
.CountryDiv>span,
.languageDiv>span {
	margin-top: 9px;
}

.submitSampleFilterDiv .dropDowncontainer {
	margin-left: 25px;
}

.errorMsg {
	color: Red;
}

.submitSampleFilterDiv .dropDownBox {
	padding: 5px 9px !important;
}

.submitSampleFilterDiv .dropDownList {
	top: 36px;
}

.noClickEvents {
	pointer-events: none;
}

.sampleTemplateRating {
	display: flex;
	flex-direction: row;
	cursor: pointer;
	/* width:225px; */
}

.sampleTemplateRating .star {
	stroke: #f9bc2d;
	stroke-width: 2px;
	stroke-linejoin: round;
}

.sampleTemplateRating .star-container {
	vertical-align: unset !important;
}

.sampleTemplateRating span {
	margin-left: 7px;
}

.sampleSubmitTemplatePopUp.modal-header {
	border-bottom: 1px solid #e5e5e5;
	font-weight: 600;
}

.ratingWrapper {
	border: none;
	padding: 10px;
	border-radius: 5px;
}

button.templateViewDocument:disabled {
	color: grey !important;
}

.hideSampleModal {
	display: none;
}

.modal-dialog.templateFeedbackPopUp.modal-dialog-centered {
	max-width: 370px;
}

ul.menuItemUL li:hover {
	text-decoration: underline;
}

li.curatedIpLi {
	padding: 0 !important;
}

li.curatedIpLi a {
	padding: 10px 1px;
	display: flex;
}

span.inProgressTemplate {
	font-size: 14px;
	font-weight: 600;
	position: relative;
	padding-top: 8px;
}

.templateModal .table td a {
	color: #0062ad;
}

.filedropContainer {
	background: #f3f2f1 !important;
	color: #666666 !important;
}

button.previewDownload a:focus {
	outline: 1px dotted #333;
	outline-offset: 7px;
}

.contributerSubmitSampleButton {
	display: inline-block;
	float: right;
	border: 1px solid #f58220;
	padding: 7px 8px;
	color: #f58220;
	border-radius: 4px;
	line-height: normal;
	font-size: 13px;
	cursor: pointer;
	position: relative;
}

.contributerSubmitSampleButton i {
	font-size: 16px;
}

.contributerSubmitSampleButton span {
	padding-left: 5px;
}

.contributerSubmitSampleButton:hover {
	background: #f58220;
}

.contributerSubmitSampleButton:hover i,
.contributerSubmitSampleButton:hover span {
	color: #fff;
}

.detOfContributionTemplate textarea {
	width: 100%;
	border: none;
	min-height: 16vh;
	font-size: 13px;
}

.detOfContributionTemplate {
	padding: 22px 20px;
}

.templateModal .modal-content .TemplateContributionPopUp .container {
	border: 1px solid #e8e9ec;
	padding-top: 0 !important;
	border-radius: 4px;
}

.templateModal .modal-content .TemplateContributionPopUp .container div>span {
	/* color: rgb(189, 189, 189); */
	margin-right: 20px;
	font-size: 13px;
}

.templateModal .modal-content .TemplateContributionPopUp .container h6 {
	margin-bottom: 20px;
	font-size: 13px;
}

.templateTabsSearch .contributerSubmitSampleButton:hover .tabsTitleToolTip,
.templateTabsSearch .contributerButton:hover .tabsTitleToolTip,
.templateTabsSearch .contributerSubmitSampleButton:focus .tabsTitleToolTip,
.templateTabsSearch .contributerButton:focus .tabsTitleToolTip {
	display: block;
}

.contributerButton {
	position: relative;
}

.templateTabsSearch .contributerSubmitSampleButton .tabsTitleToolTip {
	left: -42px;
	top: 38px;
	padding: 6px 10px;
	white-space: nowrap;
}

.templateTabsSearch .contributerButton .tabsTitleToolTip {
	left: -53px;
	top: 38px;
	padding: 6px 10px;
	white-space: nowrap;
}

.templateTabsSearch .contributerButton .tabsTitleToolTip .tabsToolTipArrow {
	left: 52px;
}

.contributerSubmitSampleButton {
	display: inline-block;
	float: right;
	border: 1px solid #f58220;
	padding: 7px 8px;
	color: #f58220;
	border-radius: 4px;
	line-height: normal;
	font-size: 13px;
	cursor: pointer;
}

.contributerSubmitSampleButton i {
	font-size: 16px;
}

.contributerSubmitSampleButton span {
	padding-left: 5px;
}

.contributerSubmitSampleButton:hover {
	background: #f58220;
}

.contributerSubmitSampleButton:hover i,
.contributerSubmitSampleButton:hover span {
	color: #fff;
}

.detOfContributionTemplate {
	padding: 22px 20px;
}

.templateModal .modal-content .TemplateContributionPopUp .container {
	border: 1px solid #e8e9ec;
	padding-top: 0 !important;
	border-radius: 4px;
}

.templateModal .modal-content .TemplateContributionPopUp .container div>span {
	/* color: #666666; */
	margin-right: 20px;
	font-size: 13px;
}

.templateModal .modal-content .TemplateContributionPopUp .container h6 {
	margin-bottom: 20px;
	font-size: 13px;
}

.templateContributionFileNameContainer {
	display: flex;
	flex-direction: row;
	font-size: 13px;
}

.templateContributionFileName {
	margin-right: 30px;
	border: 2px solid #999999;
	padding: 5px;
	border-radius: 5px;
}

.templateContributionFileName button {
	margin-left: 5px;
	border: none;
	background: none;
}

.templateContributionErrorMsgContainer {
	font-size: 13px;
}

.templateSubmitDiscard {
	float: right;
}

.templateSubmitButton {
	border: none;
	background: #0078d4;
	color: #fff;
	padding: 3px 20px;
	border-radius: 3px;
	font-size: 13px;
	margin-top: 10px;
}

.templateDiscardButton {
	border: none;
	color: #0070c0;
	background-color: #f3f2f1;
	padding: 3px 20px;
	border-radius: 3px;
	font-size: 13px;
	margin-top: 10px;
	border: 1px solid #d3d3d3;
	margin-right: 5px;
}

.discardPopupButtons button {
	padding: 3px 10px !important;
}

.templateDiscardPopup.modal-body p {
	padding: 0;
	margin-bottom: 0;
	font-size: 13px;
}

.templateDiscardPopup.modal-body .discardPopUpButtons button {
	width: auto;
	padding: 3px 20px;
}

.templateDiscardPopup.modal-body .discardPopUpButtons {
	text-align: center;
	margin-top: 50px;
}

.templateDiscardPopup.modal-body {
	padding: 34px;
}

.contributionCommentInfo {
	color: #000 !important;
	float: right;
	margin-right: 0px !important;
}

.tabSubmitSampleButton {
	margin-right: 10px;
}

.disabledContributorButton,
.disabledContributorButton:hover {
	border: 1px solid #666666 !important;
	color: #666666 !important;
	background: #fff !important;
	cursor: default !important;
}

.disabledContributorButton i {
	color: #666666 !important;
}

.contributerButton.disabledContributorButton .tabsTitleToolTip {
	left: -112px;
}

.contributerButton.disabledContributorButton .tabsTitleToolTip .tabsToolTipArrow {
	left: 111px;
}

.sampleSubmitTemplatePopUp .detailsOfSampleTemplate label.checkBoxContainer {
	display: inline-block;
}

.sampleSubmitTemplatePopUp .detailsOfSampleTemplate p {
	display: flex;
}

.localizedDropdown {
	display: flex;
}

.hideLocalizedTemplateModal {
	display: none;
}

span.labelDropdown {
	line-height: 31px;
	margin-right: 10px;
}

.templateContributionSelection label.checkBoxContainer {
	display: inline-block;
	padding-bottom: 5px;
	margin-right: 30px;
}

.templateContributionSelection .checkmark {
	height: 18px;
	width: 18px;
	top: 7px;
}

.templateContributionSelection .checkBoxContainer .checkmark:after {
	left: 3px;
	top: 3px;
	height: 10px;
	width: 10px;
}

.templateModal .modal-content {
	border: none;
	overflow: auto;
}

.templatesingleSelectionPopUp .modal-dialog.modal-md.modal-dialog-centered {
	width: 400px;
}

.templatesingleSelectionPopUp .modal-body {
	padding: 20px;
}

.commonContContrButton {
	margin-bottom: 15px;
	box-shadow: 0px 0px 6px 0px #0000001f;
	padding: 3px;
}

.commonContContrButton .filterChkboxContainer .checkmark {
	height: 20px;
	width: 20px;
	top: 7px;
	left: 18px;
}

.commonContContrButton .checkBoxContainer .checkmark:after {
	height: 14px;
	width: 14px;
}

.singleContributionModal.modal-header {
	border-bottom: 0;
	font-weight: 600;
}

.singleContributionModal.modal-header i {
	cursor: pointer;
	padding: 1px;
	font-weight: bolder;
}

.modal-dialog.hashTagstemplateModal {
	max-width: 800px;
}

.hashTagstemplateModal .detailsOfHashtagsTemplate {
	font-size: 12px;
}

.templatesDetailsHasttagInput {
	display: flex;
	padding: 7px 0;
	flex-direction: column;
}

.templatesDetailsHasttagInput p {
	margin-bottom: 5px;
}

.templatesDetailsHasttagInput input {
	flex: 1;
	border: 1px solid #e4e4e4;
	padding: 4px;
	font-size: 12px;
}

.templatesHasttagsContainer {
	display: flex;
	font-size: 13px;
	flex-direction: column;
	margin-bottom: 20px;
}

.templatesDetailsHasttagInput {
	display: flex;
	padding: 7px 0;
}

.hashTagstemplateModal .modal-body {
	padding: 20px 40px;
}

.hashTagstemplateModal .modal-title {
	font-size: 18px;
	padding-left: 20px;
}

.detOfContributionTemplateforHashtags {
	padding: 22px 20px;
	border: 1px solid #e4e4e4;
}

.detOfContributionTemplateforHashtags input {
	border: none;
	width: 100%;
	font-size: 13px;
}

.detOfContributionTemplateforHashtags h6 {
	font-size: 13px;
}

.detOfContributionTemplateforHashtags p {
	font-size: 12px;
}

p.noHashtagsDescription {
	font-size: 12px;
	margin-bottom: 0;
}

p.noHashtagsDescription a {
	color: #006ee5;
}

.addHashtagsIcon {
	float: left;
	width: 16px;
	height: 16px;
	background: url(../../assets/AddHashTagsIcon.svg) no-repeat;
	background-size: contain;
	/* margin-top: 6px; */
}

.templatesDetailsHasttagButtons button {
	border: none;
	margin-right: 10px;
	font-size: 13px;
	padding: 4px 14px;
	border-radius: 4px;
}

.templatesDetailsHasttagButtons button:last-child {
	background: #0078d4;
	color: #fff;
	margin-right: 0;
}

.templatesDetailsHasttagButtons {
	display: flex;
	justify-content: flex-end;
}

.modal-dialog.hashTagstemplateModal .modal-header i {
	cursor: pointer;
}

.addHashtagsIconContainer .addHashtagsIcon:hover~.tabsTitleToolTip {
	display: block;
}

.addHashtagsIconContainer {
	position: relative;
	padding: 2px 2px;
	/* margin-top: -2.5px; */
	display: flex;
	align-items: center;
}

.addHashtagsIconContainer .tabsTitleToolTip {
	left: 1px;
	top: 25px;
	white-space: nowrap;
	padding: 6px 12px;
	text-align: center;
}

.addHashtagsIconContainer .tabsTitleToolTip span {
	color: #fff;
	margin-right: 0;
	left: -10px;
}

.modal-dialog.templateRejectModalPopUp.reviewerRejectMessage.templateApproval {
	max-width: 500px;
}

.templateRejectModalPopUp.reviewerRejectMessage.templateApproval.modal-dialog .modal-content {
	min-height: auto;
}

.templateModal .hashTagsContainer>span {
	/* color: #fff !important; */
	margin-right: 10px !important;
	font-size: 11px !important;
}

.templateDeleteIcon {
	float: left;
	width: 35px;
	margin-right: 9px;
	height: 30px;
	background: url(../../assets/TemplateDeleteButton.svg) no-repeat;
	background-size: contain;
	cursor: pointer;
	box-sizing: border-box;
}

.templateRejectModalPopUp.templateDeleteModal .modal-content {
	padding: 20px;
}

.templateRejectModalPopUp.templateDeleteModal .modal-content .modal-title {
	display: flex;
	width: 100%;
}

.templateRejectModalPopUp.templateDeleteModal .modal-content .modal-title>span {
	flex: 1;
	font-size: 18px;
	text-align: left;
}

.templateRejectModalPopUp.templateDeleteModal .modal-content .modal-header {
	padding: 0;
}

.templateRejectModalPopUp.templateDeleteModal .modal-content .modal-title>div i {
	font-size: 19px;
	vertical-align: super;
	cursor: pointer;
}

.templateRejectModalPopUp.templateDeleteModal .templateDeleteButtons button {
	border: none;
	color: #0070c0;
	background-color: #f3f2f1;
	padding: 3px 40px;
	border-radius: 3px;
	font-size: 13px;
	margin-top: 10px;
	border: 1px solid #d3d3d3;
	margin-right: 15px;
}

.templateRejectModalPopUp.templateDeleteModal .templateDeleteButtons button:last-child {
	background: #0078d4;
	color: #fff;
}

.templateModal span.previewIconBlack {
	display: block;
	margin: 2px 5px 0 5px;
	cursor: pointer;
}

.templateModal td i {
	float: left;
	margin-right: 10px;
	cursor: pointer;
}

.templateModal span.ActivityDeleteIcon {
	width: 20px;
	height: 20px;
	margin-top: -3px;
}

.deleteSamplesPopUp {
	position: absolute;
	background: #fff;
	padding: 10px;
	width: 310px;
	border-radius: 10px;
	left: -131px;
	top: 0;
	z-index: 9;
	font-size: 13px;
}

.deleteSamplesPopUp button {
	border: none;
	background: #ccc;
	color: #0078d4;
	padding: 4px 10px;
	border-radius: 4px;
	margin-right: 10px;
}

.templateModal .table td {
	position: relative;
}

.deleteSamplesPopUp>div {
	text-align: center;
}

.editRoleDetailsDiv .deleteSamplesPopUp {
	left: -230px;
}

.ChildTemplateDeleteIcon {
	width: 9px;
	height: 10px;
	margin-top: 2px;
	background: url(../../assets/ChildTemplateDeleteIcon.svg) no-repeat;
	background-size: contain;
	cursor: pointer;
	float: left;
	margin-left: 10px;
}

.templateEditIcon {
	width: 35px;
	height: 36px;
	background: url(../../assets/TemplateEditbuttonActive.svg) no-repeat;
	background-size: contain;
	cursor: pointer;
	float: left;
}

.templateEditIconInActive {
	width: 35px;
	height: 30px;
	background: url(../../assets/TemplateEditButtonInactive.svg) no-repeat;
	background-size: contain;
	cursor: pointer;
	float: left;
	box-sizing: border-box;
}

.templateEditIconDiv {
	display: flex;
	position: relative;
}

.templateEditIconDiv:hover .tabsTitleToolTip,
.templateEditIconDiv:focus .tabsTitleToolTip {
	display: block;
}

.templateEditIconDiv .tabsTitleToolTip {
	white-space: nowrap;
	left: -40px;
	top: 41px;
}

.phaseCreationRightPopup.addNewTemplate .middleDetailsContainer span.complexityLabel.cloneActivityTitle {
	color: #006cbe;
}

.actionStyle {
	min-width: 10em;
}

.ratingStyle {
	min-width: 14em;
}

.tabsTitleToolTipLatest {
	white-space: nowrap !important;
}


._templateCard .imageOfSearchResult {
	width: 34px;
	height: 34px;
	margin: 0;
}

._templateCard .card-body {
	margin-left: 105.5px;
	margin-right: 10px;
	/* height: 32px; */
	text-align: left;
	font: normal normal normal 12px/16px Segoe UI;
	letter-spacing: -0.12px;
	color: #0A0A0A;
	opacity: 1;
}

._templateCard .card-body .phaseLabel {
	display: block;
	height: 16px;
	text-align: left;
	font: normal normal 600 12px/16px Segoe UI;
	letter-spacing: -0.12px;
	color: #0A0A0A;
	opacity: 1;
	font-weight: bolder;
}

._templateCard .card-body .phaseName {
	height: 20px;
	text-align: left;
	color: #000000 !important;
	opacity: 1;
	font: normal normal normal 12px/18px Segoe UI;
	letter-spacing: -0.12px;
}

._templateCard .card-body .activityName {
	height: 20px;
	text-align: left;
	color: #0178D4 !important;
	opacity: 1;
	font: normal normal normal 12px/18px Segoe UI;
	letter-spacing: -0.12px;
}

._firstLine {
	height: 21px;
	text-align: left;
	font: normal normal 600 16px/21px Segoe UI;
	letter-spacing: -0.16px;
	opacity: 1;
	font-weight: 400;
	width: 32vw;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

._firstLine button {
	border: none;
}

._firstLine .tabTitle {
	display: block;
}

._expandCollapsButton {
	font-size: 13px;
	color: #535353 !important;
	opacity: 1;
	cursor: pointer;
}

._inlineBorderBox {
	border-left: 1.5px solid #D8D8D8;
	border-right: 1.5px solid #D8D8D8;
	margin-inline: 10px;
	padding-inline: 5px 10px;
	height: 35px;
	display: flex;
	align-items: center;
}

._templateCard .date-text {
	padding-top: 4px;
	white-space: nowrap;
	font: normal normal normal 12px/16px Segoe UI;
	letter-spacing: -0.12px;
	color: #000000;
}

._templateCard .tabsRightWrapper {
	width: auto !important;
	margin-top: 2px;
}

._secondLine {
	max-width: 32vw;
	padding-block: 5px 8px;
	margin-left: -2px;
}

._templateTitle {
	display: inline-block;
	padding-top: 0 !important;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 25vw;
}

.tabTitle>button {
	position: absolute;
	background: none;
	top: 10px !important;
}

._templateCard .tabsShowMoreMenu {
	right: -1rem !important;
	text-align: left;
}

._templateCard .tabsShowMoreArrow {
	right: 1rem !important;
}

._displayPopup {
	overflow: initial !important;
}

._templateCard .imageOfTemplateDiv {
	padding-right: 15px;
}

._activityList {
	padding-top: 12px;
}

._templateCard .viewDivresuable {

	padding-right: 5px !important;
}

._AddTemplate,
._Edit,
._Delete,
._Options,
._OptionsHover,
._AddTemplateDisabled,
._EditDisabled {
	width: 24px;
	height: 24px;
	background-size: contain;
	background-repeat: no-repeat;
	border: 0 !important;
	padding: 0;
	margin: 0;
	justify-content: center;
	flex-direction: column;
}

._AddTemplate {
	background-image: url(../../assets/_AddTemplate.svg);
}


._AddTemplateDisabled,
._AddTemplateDisabled:hover {
	cursor: not-allowed;
	background-image: url(../../assets/_AddTemplateDisabled.svg) !important;
}

._Edit {
	background-image: url(../../assets/_Edit.svg);
}

._EditDisabled,
._EditDisabled:hover {
	cursor: not-allowed;
	background-image: url(../../assets/_EditDisabled.svg);
}

._Delete {
	background-image: url(../../assets/_Delete.svg);
	margin-right: 10px;
}

._Options {
	background-image: url(../../assets/_Options.svg);
}

._OptionsHover {
	background-image: url(../../assets/_OptionsHover.svg);
}

._Options:hover {
	background-image: url(../../assets/_OptionsHover.svg);
}

._AddTemplate:hover {
	background-image: url(../../assets/_AddTemplateHover.svg);
}

._disabledButton {
	cursor: not-allowed;
	opacity: 0.8;
}

.templatesListCont .accordion {
	margin-bottom: 5px !important;
}