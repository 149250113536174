.methodOwnerMainContainer {
	display: flex;
	min-width: 581px;
    min-height: 300px;
    overflow: auto;
}
.methodOwnerPage {
	display: flex;
	background: #f2f2f2;
	min-height: 80vh;
	width: 100%;
	flex-direction: column;
}
.methodOwnerContainer {
	display: flex;
	font-family: 'Segoe UI', sans-serif;
	flex: 1 1;
	background: #fff;
	width: 100%;
	min-height: 82vh;
	flex-direction: column;
}
.phaseCreationRightPopup .modal-body {
	padding: 0;
}
.phaseCreationRightPopup .modal-content {
	height: 100vh;
	border: none;
	border-radius: 0;
	/* overflow: hidden; */
	/* overflow-y: auto; */
	overflow: scroll;;
}
.methodLeftContainer {
	margin-right: 2px;
	margin-top: 7px;
	margin-bottom: 3px;
}
.middleDetailsContainer {
	flex: 1 1;
	/* max-height: 100vh; */
	/* overflow: scroll; */
	/* overflow-y: auto; */
	padding: 4px 25px 0 25px;
}

@media only screen and (min-width: 323px) and (max-width: 1181px)  {
	#addTemplateContainerId.middleDetailsContainer {	
		width:600px !important;
		height:90vh !important;
		overflow-y: auto;		
	}
	#adjustWidthId.methodOwnerMiddleContainer {
		width:600px !important;
	}
}

#addTemplateSaveContinueId.saveContinueDetailsContainer{
	position: relative !important;
	top:100% !important;
	

}
.methodOwnerMiddleContainer {
	padding: 30px 20px 20px 20px;
	background: #fff;
}
.rightSideDetailsContainer {
	padding: 0 0 0 2px !important;
	order: 3;
	width: 250px;
	display: flex;
	flex-direction: column;
	background: #007bff;
}
.showTopicsYammerMenu {
	position: absolute;
	right: 0;
	height: 100%;
	display: flex;
}
.yammerFeedContainer {
	padding: 0 0 0 2px !important;
	order: 3;
	width: 300px;
	display: flex;
	flex-direction: column;
	background: #f2f2f2;
	position: relative;
}
.methodPhaseLeftMenu li {
	display: flex;
	padding: 6px 15px;
	color: #333;
	text-decoration: none;
	font-size: 12px;
	background: #fff;
	cursor: pointer;
	width:50%;
	white-space: nowrap;
	text-align: center;
}
.methodPhaseLeftMenu li span {
	width: 100%;
	float: left;
	white-space: nowrap;
  	overflow: hidden;
  	text-overflow: ellipsis;
}
/* .methodPhaseLeftMenu a:hover {
	color: #333;
	text-decoration: none;
} */
.methodPhaseLeftMenu hr {
	border-bottom: 1px solid #f2f2f2;
	width: 100%;
	border-top: none;
	clear: both;
	margin: 0 auto !important;
}
.methodPhaseLeftMenu {
	margin: 0;
	padding: 0px;
	overflow: hidden;
	height: inherit;
	overflow-y: auto;
	display: flex;
	box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.12);
	padding-left: 9px;
}
.methodPhaseOtherDetails {
	margin-top: 20px;
}

/* .App {
	width: 100%;
	margin: 0 auto;
  } */

.drag-container {
	text-align: center;
}

.head {
	display: inline-block;
	margin: 0;
	padding: 0;
	background-color: #cccccc;
	width: 100%;
}

.droppable {
	position: absolute;
	width: 200px;
	height: 200px;
	right: 0;
	top: 10;
	background-color: #9e9e9e;
	border-left: 2px dotted red;
}

.draggable {
	width: 100px;
	height: 100px;
	background-color: yellow;
	margin: 10px auto;
}

.inProgress {
	position: absolute;
	width: 200px;
	height: 200px;
	left: 0;
	top: 10;
	background-color: #eeeeee;
	border-right: 2px dotted red;
}
.phaseBuilderBar {
	background: #fff;
	position: fixed;
	box-shadow: 0px 3px 10px #0000001f;
	width: 100%;
	padding: 10px;
	display: flex;
}
.phaseBuilderBar h6 {
	flex: 1;
}
.methodPhaseDetailsTab {
	display: flex;
	flex-direction: column;
}
.phaseDetailsFields {
	display: flex;
	flex-direction: column;
	margin-right: 100px;
}
.methodPhaseDetailsTitlePart h4 {
	margin-bottom: 30px;
}
.phaseDetailsFields > div,
.phaseCollabaratorsFields > div {
	margin-bottom: 20px;
	font-size: 13px;
}
.phaseDetailsFields h6,
.phaseCollabaratorsFields h6 {
	font-size: 14px;
}
.phaseDetailsFields div input,
.phaseCollabaratorsFields div input {
	width: 97%;
	padding: 4px;
}
/* width: 97% */
.collabaratorPersonaImage img {
	width: 25px;
	height: 25px;
	border-radius: 50%;
	margin: 0px;
}
span.collabaratorPersonaTitle {
	font-size: 13px;
	font-weight: 600;
	margin-left: 10px;
	line-height: 22px;
}
.methodPhaseCollabaratorsTab {
	margin-right: 100px;
}
#methodPhaseCollabaratorsTabId.methodPhaseCollabaratorsTab{
	width:500px;
}

.collabaratorsChildContainers ul li i {
	font-weight: bolder;
	vertical-align: middle;
	margin-left: 10px;
	font-size: 11px;
	cursor: pointer;
	height: 13px;
}
.collabaratorsChildContainers {
	border: 1px solid #e8e9ec;
	width: 97%;
}
/* width=97% */
.collabaratorsChildContainers ul {
	margin-bottom: 0;
	padding: 10px;
	list-style: none;
}
.collabaratorsChildContainers ul li {
	padding: 5px;
}
.sequentialPhaseList .sequentialdraggblePhaseList {
	display: flex;
	flex-direction: column;
	margin: 0;
	list-style: none;
	padding: 0;
	color: #4d4f5c;
}
.sequentialPhaseList .sequentialdraggblePhaseList .sequentialPhaseItem {
	padding: 8px 3px;
	border: 1px solid #f0f2f8;
	border-radius: 4px;
	font-size: 14px;
	margin-bottom: 15px;
}
.sequentialPhaseList {
	margin-right: 160px;
	margin-top: 20px;
}
.sequentialPhaseList .sequentialdraggblePhaseList .sequentialPhaseItem i {
	margin: 0 12px;
	font-size: 14px;
	color: #888;
}
.entryExitCriteriadiv {
	display: flex;
	flex-direction: column;
	font-size: 13px;
}
.otherDetailsEnableComplexity span.complexityLabel {
	font-size: 13px;
	margin-left: 24px;
}
.otherDetailsEnableComplexity .CustomCheckmark {
	border: 1px solid #007bff;
	border-radius: 3px;
}
.otherDetailsEnableComplexity {
	margin-bottom: 20px;
}
.entryCriteriaFields {
	display: flex;
	flex-direction: column;
}
.entryCriteriaFields input {
	width: 100%;
	max-height: 40px;
	border: 1px solid #e8e9ec;
	margin-top: 4px;
	font-size: 12px;
}
.entryCriteriaFields .entryCriteriadropdown {
	width: 100%;
	max-height: 40px;
}
.entryCriteriaFields textarea {
	width: 600px;
	min-height: 80px;
	border: 1px solid #e8e9ec;
	margin-top: 4px;
	font-size: 12px;
	padding: 8px;
}
.entryCriteriaFieldsDesc {
	width: 100% !important;
    display: flex;    
    flex-direction: row;
    justify-content: space-between;
	padding-right:10px;

}
.specificForTask{
	flex-direction: column;
	
}
.entryCriteriaFieldsDesc input {
	width: 100%;
	max-height: 32px;
	border: 1px solid #e8e9ec;
	margin-top: 20px;
	font-size: 12px;	
}
.entryCriteriaFieldsDesc .entryCriteriadropdown {
	width:100%;
	max-height: 40px;
}
.entryCriteriaFieldsDesc textarea {
	width: 600px;
	min-height: 80px;
	border: 1px solid #e8e9ec;
	margin-top: 4px;
	font-size: 12px;
	padding: 8px;
}
.saveContinueDetailsContainer {
	font-size: 13px;
	color: #007bff;
	position: relative;
	bottom: 0px;
	width: 100%;
	right: 0;
	padding: 10px 10px;
	box-shadow: 1px -4px 5px -3px rgba(0, 0, 0, 0.12);
	min-height: 42px;
}
.saveContinueDetailsContainer .forwardButton {
	border: none;
	background: none;
	color: #0078d4;
	float: right;
	margin-right: 111px;
}
.saveContinueDetailsContainer .backwardButton {
	border: none;
	background: none;
	color: #0078d4;
	float: left;
	margin-left: 34px;
}

span.saveContinueButton {
	float: right;
	/* cursor: pointer; */
	font-weight: bold;
}
span.backDetailsButton {
	/* cursor: pointer; */
	font-weight: bold;
	/* margin-left: 34px; */	
}
span.backDetailsButton i,
span.saveContinueButton i {
	vertical-align: middle;
	font-weight: bolder;
}
span.saveContinueButton i {
	margin-left: 10px;
}
span.backDetailsButton i {
	margin-right: 10px;
}
.entryCriteriaName {
	margin-top: 15px;
	display: flex;
	flex-direction: column;
	flex: 1;
}
.entryCriteriaNameTask {
	margin-top: 17px;
	display: flex;
	/* flex-direction: column; */
	flex: 1;
	width:100%;
}
.wrapperTask{
	display: flex;
    background: #fff;
    padding: 1px 31px;
    border-radius: 4px;
    margin: 0;
}
.entryCriteriaNameTask input{
	width: 100%;
    max-height: 32px;
    border: 1px solid #e8e9ec;
    margin-top: 17px;
    font-size: 12px;
    height: 32px;    
}
.entryCriteriaDescr {
	margin-top: 15px;
	margin-bottom: 15px;
}
/* .entryCriteriaDescrDesc{
	margin-top: 15px;
} */
.entryCriteriaDescrDesc{
	/* margin-top: 15px; */
	width: 100%;
	max-height: 32px;
	/* border: 1px solid #e8e9ec; */
	margin-top: 4px;
	font-size: 12px;
	height:32px;
	
}
.entryCriteriaDescrOutput{
	margin-right: 10px;    
	margin-top: 15px;
	width: 100%;
	max-height: 32px;
	/* border: 1px solid #e8e9ec; */
	margin-top: 4px;
	font-size: 12px;
	height:32px;
	
}
.entryCriteriaDescrOutput input{
	width: 100%;
    max-height: 32px;
    border: 1px solid #e8e9ec;
    margin-top: 17px;
    font-size: 12px;
    height: 32px;    
}
.entryCriteriaDdown {
	width: 200px;
	margin-top: 15px;
	margin-left: 10px;
}
.entryCriteriaDdown .dropDownBox {
	padding: 5px 9px !important;
	border: 1px solid #e8e9ec;
}
.entryCriteriaDdown .dropDownList {
	top: 36px;
}
.criteriaButtons {
	margin-top: 20px;
	margin-bottom: 13px;
}
.criteriaHeader {
	display: flex;
	padding: 6px;
	background: #4d88f6;
	color: #fff;
	border-radius: 4px;
	font-size: 13px;
	font-weight: 600;
	white-space: nowrap;
}
.criteriaName,
.criteriaEditDeleteIcons,
.criteriaType {
	width: 175px;
}
.criteriaDesc {
	width: 300px;
}
.criteriaIcon {
	width: 40px;
	text-align: center;
}
.entryCriteriaList ul li .criteriaTable {
	display: flex;
	padding: 4px;
	overflow: hidden;
}
.entryCriteriaDiv {
	display: flex;
}
.criteriaButtonsTask button {
	border: none;
	font-size: 12px !important;
	padding: 4px 13px;
	margin-right: 10px;
	background: #0078d4;
	color: #fff;
	font-weight: 600;
	border-radius: 4px;
}
.criteriaButtonsTask button:first-child {
    background: #e4e4e4;
    font-weight: 600;
    color: #000;
}
.criteriaButtonsTask{
	position:absolute;
	right: 6em;
}
.entryCriteriaFieldsTask {
	display: flex;
	flex-direction: row;
	width:100%;
}
.entryCriteriaFieldsTask input {
	width: 100%;
	max-height: 40px;
	border: 1px solid #e8e9ec;
	margin-top: 4px;
	font-size: 12px;
}
.entryCriteriaDivTask{
	display: flex;
	width:676px;
}
.entryCriteriaDivDesc{
	display: flex;
	width:676px;
}
.entryCriteriaDivDesc .entryNameAndTypeDiv{
	padding-right:10px;
}
.entryCriteriaDivTask .entryNameAndTypeDiv{
	padding-right:10px;
	display: flex;
	width:100%
}
.DetailswrapperTitle.entryExit .entryCriteriaDivDesc {
	display: flex;
	background: #fff;
	padding: 1px 31px;
	border-radius: 4px;
	justify-content: space-between;
}
.DetailswrapperTitle.entryExit .entryCriteriaDiv {
	display: flex;
	background: #fff;
	padding: 1px 31px;
	border-radius: 4px;
}
.DetailswrapperRightSec .criteriaButtons {
	margin-top: 0;
}
.criteriaButtonAction {
	display: inline-flex;
	border: 1px solid #4d87f6;
	background: #f1f5f9;
	padding: 8px 54px 8px 20px;
	flex-direction: column;
	margin-bottom: 24px;
	border-radius: 4px;
}
/* .criteriaButtonAction.exitCriteria {
	padding: 8px 64px 8px 20px;
} */
.selectCriteriaButton {
	color: #0078d4;
	cursor: pointer;
}
span.CriteriaFieldLabel {
	font-weight: 600;
}
.entryCriteriaList ul {
	display: flex;
	list-style: none;
	padding: 0;
	flex-direction: column;
	border: 1px solid #e8e9ec;
}
.entryCriteriaList {
	display: flex;
	flex-direction: column;
	margin: 10px 0;
	width: 676px;
}
.entryCriteriaList ul li {
	display: flex;
	overflow: hidden;
}
.criteriaButtons button {
	border: none;
	font-size: 12px !important;
	padding: 4px 13px;
	margin-right: 10px;
	background: #0078d4;
	color: #fff;
	font-weight: 600;
	border-radius: 2px;
}
.criteriaButtons button:first-child {
	background: #e4e4e4;
	font-weight: 600;
	color: #000;
}
.middleDetailsContainer input {
	border: 1px solid #3b3d41;
	font-size: 12px;
	min-height: 32px;
	padding-left: 8px;
	color:#55585B;
}
.middleDetailsContainer input::placeholder{
	color:#55585B!important
}
.methodLeftContainer li.activePhaseTab {
	border-bottom: 2px solid #0078d4;
	font-weight: bold;
	color: #0078d4;
}
.swimlaneUploadIcon {
	float: left;
	width: 137px;
	height: 100px;
	background: url(../../../assets/uploadIcon.svg) no-repeat;
	background-size: contain;
}
.swimlaneDownloadIcon {
	float: right;
	width: 20px;
	height: 20px;
	background: url(../../../assets/swimdownloadIcon.svg) no-repeat;
	background-size: contain;
}
.swimlaneDownloadOrange {
	float: right;
	width: 15px;
	height: 15px;
	background: url(../../../assets/swimdownloadIconOrange.svg) no-repeat;
	background-size: contain;
	margin-left: 10px;
}
button.SwimDowndTemplateButton {
	border: 1px solid #ccc;
	background: none;
	font-size: 12px;
	padding: 5px 10px;
	float: right;
	border-radius: 10px;
	color: #f58220;
}
.filedropContainer img {
	width: 100%;
	margin-bottom: 24px;
}
.swimlaneDownloadDiv {
	width: 100%;
	margin-bottom: 10px;
}
.filedropContainer.swimlaneDropCont {
	border-color: #0078d4 !important;
}
.criteriaEditDeleteIcons {
	text-align: end;
	padding-right: 20px;
	position: relative;
}
.criteriaEditDeleteIcons i {
	margin-right: 10px;
	cursor: pointer;
	font-weight: bold;
}
.criteriaEditDeleteIcons i:hover {
	color: #0078d4;
}
.entryNameAndTypeDiv {
	display: flex;
}
.entryNameAndTypeDivDesc{
	display: flex;
	padding-right:10px

}
.entryNameAndTypeDivTask{
	display: flex;
	padding-right:10px;
	width:100%

}
.otherDetailsContainer {
	margin-right: 150px;
}
.otherDetailsEnableComplexity
	.NotificationChkboxContainer
	.CustomCheckmark:after {
	top: 0;
	font-size: 10px;
}
.methodOwnerMiddleContainer .container {
	padding: 0;
}
h4.uploadSwimlaneTitle {
	font-size: 16px;
	margin-bottom: 20px;
}
.upLoadManageSwimlaneDiv {
	display: flex;
	margin-bottom: 20px;
}
.upLoadManageSwimlaneDiv a {
	flex: 1;
	font-size: 13px;
}
.phaseCreationRightPopup .modal-header i {
	font-size: 15px;
	margin-top: 11px;
	cursor: pointer;
}
.phaseCreationRightPopup .modal-header i:hover{
   color: black;
   font-weight: bolder;
}
span.saveContinueButton.fadeSaveContinueButton {
	opacity: 0.5;
	/* pointer-events: none; */
}
.entryExitCriteriadiv h6 {
	margin-bottom: 20px;
}
.visiChangeApproveButtonDiv button.dragDropButton {
	padding: 5px 50px;
}
button.visioSentForApproveButton {
	background: #323130;
	color: #fff;
	font-size: 13px;
	padding: 5px 20px;
	border: none;
	margin-left: 20px;
	border-radius: 3px;
}
p.visioApproveStatus {
	margin-top: 17px;
	font-weight: 600;
	font-size: 14px;
	float: left;
}
.swimlaneApprStatusDiv button.swimlaneHistoryButton {
	margin-top: 18px;
}
p.visioApproveStatus span {
	color: #f58220;
	margin-left: 21px;
}
.phasePublishModal .modal-dialog {
	max-width: 500px;
}
.phasePublishModal .modal-body {
	padding: 30px 30px;
	font-size: 13px;
}
.phasePublishModal h6 {
	font-size: 13px;
	margin-left: 23px;
	font-weight: normal;
}
.phasePublishModal ul {
	margin-left: 23px;
}
.phasePublishButtons {
	float: right;
}
.phasePublishButtons button {
	border: none;
	background: #e4e4e4;
	padding: 4px 10px;
	margin-left: 10px;
	font-size: 13px;
	border-radius: 4px;
	color: #0078d4;
}
.phasePublishButtons button:last-child {
	background: #0078d4;
	color: #fff;
}
.phasePublishModal .modal-header i {
	cursor: pointer;
}
.phaseEditDeletMore .showMoreMenu li {
	background: #fff;
	margin: 0;
	font-size: 11px;
	padding: 5px 4px 4px 12px;
	text-decoration: none !important;
}
.phaseEditDeletMore .showMoreMenu li .ellipseMenuItem {
	width: 100%;
}
.swimApprovalHistoryDiv {
	display: flex;
	width: 100%;
	background: #fff;
	margin-top: 20px;
}
.swimApprovalHistoryDiv ul li {
	display: flex;
	border-bottom: 1px solid #e4e4e4;
	font-size: 13px;
	padding: 10px 10px;
	flex-direction: column;
	margin: 0px 30px 5px 30px;
	line-height: 22px;
}
.swimApprovalHistoryDiv ul {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 0;
	margin-bottom: 0;
}
.swimlaneHistoryFields {
	display: flex;
	width: 100%;
}
.swimlaneHistoryFieldsDiv {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}
.swimlaneComments {
	display: flex;
	width: 100%;
}
button.swimlaneHistoryButton {
	border: 1px solid #666;
	font-size: 12px;
	border-radius: 4px;
	padding: 1px 10px;
	margin-left: 20px;
	background: #e4e4e4;
	font-weight: 600;
}
button.swimlaneHistoryButton i {
	font-size: 9px;
	margin-left: 5px;
}
.swimlaneApprStatusDiv {
	display: block;
}
span.swimlaneHistoryDtAndTitle span:last-child {
	color: #0078d4;
	font-weight: 600;
	margin-left: 25px;
}
.swimlaneHistoryOwnerDiv span:first-child {
	/* font-weight: 600; */
	color: #333;
	margin-right: 0px;
}
.swimlaneHistoryOwnerDiv span:last-child {
	margin-left: 14px;
}
.swimlaneHistStatus i {
	font-size: 12px;
	vertical-align: initial;
	font-weight: bold;
	margin-top: 3px;
	margin-left: 6px;
	cursor: pointer;
}
.swimlaneHistStatus span {
	margin-top: 10px;
	display: flex;
}
span.swimlaneHistoryComments span {
	color: #333;
	margin-right: 18px;
}
span.swimlaneHistoryComments span i {
	font-size: 6px;
	margin-right: 12px;
	margin-left: 25px;
}
.Rejected {
	color: #c40313;
}
.InProgress {
	color: #fdb864;
}
.Approved {
	color: #0078d4;
}
/* .phaseLeftNavMenu li.draftPhase a {
    width: 85% !important;
} */
.sequentialPhaseItem.new {
	border: 1px solid #0078d4 !important;
}
.upLoadManageSwimlaneDiv div i {
	margin-top: 5px;
	margin-right: 24px;
	cursor: pointer;
}
.upLoadManageSwimlaneDiv div i:hover ~ .tabsTitleToolTip,
.upLoadManageSwimlaneDiv div i:focus ~ .tabsTitleToolTip {
	display: block;
}
h4.uploadSwimlaneTitle > div {
	display: inline;
	float: right;
}
h4.uploadSwimlaneTitle div i {
	margin-top: 5px;
	margin-right: 24px;
	cursor: pointer;
}
h4.uploadSwimlaneTitle div .uploadSwimlaneInfoIconHover:hover .tabsTitleToolTip {
	display: block;
}
span.uploadSwimlaneInfoIcon {
	position: relative;
}
.upLoadManageSwimlaneDiv div .tabsTitleToolTip {
	width: 435px;
	left: -208px;
	top: 26px;
	padding: 5px;
}
.upLoadManageSwimlaneDiv div .tabsTitleToolTip li {
	padding: 2px 0;
}
.upLoadManageSwimlaneDiv div i:hover ~ .tabsTitleToolTip {
	display: block;
}
.upLoadManageSwimlaneDiv div .tabsTitleToolTip span.tabsToolTipArrow {
	left: 196px;
}

.dropDowncontainer.coOwner,
.dropDowncontainer.reviewer,
.dropDowncontainer.approver,
.dropDowncontainer.owner {
	width: 97%;
	margin-right: 50px;
	background: #fff;
}
.dropDowncontainer.coOwner input,
.dropDowncontainer.reviewer input,
.dropDowncontainer.approver input,
.dropDowncontainer.owner input {
	border: none;
	width: 94%;
}

.dropDowncontainer.coOwner input,
.dropDowncontainer.reviewer input,
.dropDowncontainer.approver input,
.dropDowncontainer.owner input {
	width: 90%;
}

.dropDowncontainer.coOwner .NotificationChkboxContainer input[type='checkbox'],
.dropDowncontainer.reviewer .NotificationChkboxContainer input[type='checkbox'],
.dropDowncontainer.owner .NotificationChkboxContainer input[type='checkbox'],
.dropDowncontainer.approver
	.NotificationChkboxContainer
	input[type='checkbox']:focus {
	outline: 1px dotted black !important;
	width: 15.5px;
	height: 15.5px;
	bottom: 4px;
	margin-right: 1px !important;
	left: -3px;
}

.dropDowncontainer.coOwner i,
.dropDowncontainer.reviewer i,
.dropDowncontainer.approver i,
.dropDowncontainer.owner i {
	font-size: 9px;
}
div#coOwner label.NotificationChkboxContainer,
div#approver label.NotificationChkboxContainer,
div#owner label.NotificationChkboxContainer,
div#reviewer label.NotificationChkboxContainer {
	font-size: unset !important;
	margin-bottom: 0;
}

div#coOwner span.CustomCheckmark,
div#approver span.CustomCheckmark,
div#owner span.CustomCheckmark,
div#reviewer span.CustomCheckmark {
	top: 4px;
	left: 0;
}
.phaseCollabaratorsFields .NotificationChkboxContainer .CustomCheckmark:after {
	top: -1px;
}

.userEmail {
	margin-left: 25px;
}

.dropDownList.coOwner {
	top: -14px;
}

.collaboratorDropDownList{
	top:1px !important
}

.complexityLabel.userName {
	margin-left: 25px;
}
.collabaratorsMainContainers .dropDownList.coOwner {
	top: 2px;
}
.phaseCollabaratorsFields p {
	font-size: 13px;
}
.phaseCollabaratorsFields p .aprroverStatusIcon {
	margin-right: 15px;
}
.phaseCollabaratorsFields p {
	font-size: 13px;
	color: #f58220;
	margin-bottom: 30px;
}
.phaseCollabaratorsFields p {
	font-size: 12px;
	color: #333;
	margin-bottom: 30px;
	background: #fdf4d2;
	padding: 4px;
	font-weight: 600;
	border-radius: 6px;
}
button.SwimDowndTemplateButton {
	border: 1px solid #ccc;
	background: none;
	font-size: 12px;
	padding: 5px 10px;
	float: right;
	border-radius: 10px;
	color: #333;
	background: #f58220;
	font-weight: 600;
}
.swimlaneDownloadOrange {
	float: right;
	width: 15px;
	height: 15px;
	background: url(../../../assets/swimdownloadIcon.svg) no-repeat;
	background-size: contain;
	margin-left: 10px;
}

button.SwimDowndTemplateButton {
	font-size: 12px;
	float: right;
	color: rgb(51, 51, 51);
	font-weight: 600;
	border-width: 1px;
	border-style: solid;
	border-color: rgb(204, 204, 204);
	border-image: initial;
	padding: 5px 10px;
	border-radius: 10px;
	background: rgb(245, 130, 32);
}

.saveContinueDetailsContainer .backwardButton {
	border: none;
	background: none;
	color: #0078d4;
	float: left;
	margin-left: 34px;
}
#SaveAndBackId.saveContinueDetailsContainer {
	font-size: 13px;
	color: #007bff;
	position: absolute;
	bottom: 0px;
	width: 100%;
	right: 0;
	padding: 10px 10px;
	box-shadow: 1px -4px 5px -3px rgba(0, 0, 0, 0.12);
	/* min-height: 42px; */	
	display:flex;
	justify-content: space-between;
	height: 3em;
    top: 98%;
}
#SaveAndBackId .saveContinueDetailsContainer .forwardButton {
	border: none;
	background: none;
	color: #0078d4;
	/* float: right;
	margin-right: 111px; */
	width:50%;		
}
#SaveAndBackId .saveContinueDetailsContainer .backwardButton {
	border: none;
	background: none;
	color: #0078d4;
	/* float: left;
	margin-left: 34px; */
	width:50%;	
}


#SaveAndBackId span.backDetailsButton {
	/* cursor: pointer; */
	font-weight: bold;
	/* margin-left: 34px; */
	float:left;
	
}
#SaveBackSolutionMethodId.saveContinueDetailsContainer{
	position:relative;
	top:20px;
}
#SaveContinueTemplateAddId.saveContinueDetailsContainer{
	position:relative;
	top:20px;
}
#saveContinueCollaboratorId.saveContinueDetailsContainer{
	/* position:relative;
	top:calc(100vh - 6.8rem); */	
    position: relative;
    bottom: 0;
}
#saveContinueCollaboratorId.saveContinueDetailsContainer .forwardButton{
	border: none;
    background: none;
    color: #0078d4;
    /* float: right; */
    position: absolute;
    left: 50%;

}
#collaboratorId.methodOwnerMainContainer{
	height: 98vh;
    overflow: scroll;	
}
.selectCriteriaButton {
	color: #0068b7;
	cursor: pointer;
}
.criteriaButtonAction {
	display: inline-flex;
	border: 1px solid #4d87f6;
	background: #fafcff;
	padding: 10px;
	flex-direction: column;
	margin-bottom: 24px;
	border-radius: 4px;
}
.addNewPhaseButtonContainer {
	display: inline-flex;
	border: 1px solid #4d87f6;
	background: #f1f5f9;
	padding: 8px 54px 8px 20px;
	flex-direction: column;
	margin-bottom: 24px;
	border-radius: 4px;
}
.addNewPhaseActionButton {
	color: #0078d4;
	cursor: pointer;
	font-size: 14px;
}
.addNewPhaseButtonContainer.exportToJsonDiv .addNewPhaseActionButton {
	color: #0072c9;
}
.dropDowncontainer.activityReferProjectSelectionDropdown,
.dropDowncontainer.activityReferSolutionMethodDropdown {
	width: 100%;
}
.addNewPhaseButtonContainer .phaseDetailsFields {
	margin-top: 20px;
}
.addNewPhaseButtonContainer
	.phaseEditor
	.ck.ck-reset.ck-editor.ck-rounded-corners {
	width: 600px;
}
.addNewPhaseButtonContainer .phaseDetailsFields {
	margin-right: 0;
}
.addNewPhaseButtonContainer label.NotificationChkboxContainer {
	font-size: inherit;
}
.addNewPhaseButtonContainer label.NotificationChkboxContainer .CustomCheckmark {
	top: 4px;
	left: 0;
}
.addNewPhaseButtonContainer
	label.NotificationChkboxContainer
	span.complexityLabel.cloneActivityTitle {
	margin-left: 22px;
}
.showMoreMenu.collapsed {
	min-height: 30px;
}
.addNewPhaseButtonContainer .cloneActivityFiltersWrapper {
	display: flex;
	flex-direction: column;
}
.addNewPhaseButtonContainer .AddActivityCloneButtonSection {
	text-align: end;
	margin: 20px;
}
.addNewPhaseButtonContainer .cloneActivityResultsWrapper ul {
	margin: 0;
	padding: 0;
	display: table;
}
.addNewPhaseButtonContainer .cloneActivityResultsWrapper li {
	cursor: pointer;
	list-style: none;
	padding: 10px 5px;
	display: inline-flex;
	width: 50%;
}
.addNewPhaseButtonContainer
	.cloneActivityResultsWrapper
	li
	label.NotificationChkboxContainer {
	font-size: inherit;
	width: 89%;
	overflow: hidden;
	text-overflow: ellipsis;
}
.addNewPhaseButtonContainer .cloneActivityResultsWrapper {
	display: flex;
	flex-direction: column;
	margin-top: 20px;
	position: relative;
	min-height: 50px;
	height: 350px;
	overflow: hidden;
	overflow-y: auto;
}
.entryExit .entryCriteriaList ul li .criteriaTable > div {
	border-bottom: 1px solid #fff;
	padding: 10px 13px;
	border-right: 1px solid #fff;
	text-align: center;
}
.DetailswrapperTitle.entryExit .criteriaHeader > div {
	border-bottom: 1px solid #fff;
	padding: 10px 6px;
	border-right: 1px solid #fff;
	text-align: center;
}
.DetailswrapperTitle.entryExit .criteriaHeader,
.entryExit .entryCriteriaList ul li .criteriaTable,
.DetailswrapperTitle.entryExit .entryCriteriaList ul {
	padding: 0;
}
.manageSwimlanesContainer .genericTableDownload span.preview {
	outline-offset: 1px;
}
.manageSwimlanesContainer .genericTableList .genericTableContainer {
	padding: 6px 18px !important;
}
.manageSwimlanesContainer .genericTablePreview {
	text-align: center;
}
.manageSwimlanesContainer span.preview {
	width: 40px !important;
	display: flex;
}
.otherDetailsEnableComplexity
	label.NotificationChkboxContainer
	input[type='checkbox']:focus {
	margin: 14px 0 0 1px !important;
	width: 15px !important;
}
.criteriaButtonAction .entryCriteriaFields {
	padding: 8px 64px 8px 20px;
}
.entryExitCriteriadiv .selectCriteriaButton:focus {
	outline-offset: 11px;
}
.phaseCreationRightPopup .addNewPhaseButtonContainer {
	padding: 0;
}
.phaseCreationRightPopup .addNewPhaseButtonContainer .addNewPhaseActionButton {
	padding: 10px;
}
.phaseCreationRightPopup .addNewPhaseButtonContainer .addActivityFields,
.phaseCreationRightPopup .addNewPhaseButtonContainer .phaseDetailsFields {
	padding: 8px 54px 8px 20px;
}
.phaseCreationRightPopup
	.addNewPhaseButtonContainer
	.addNewPhaseActionButton:focus {
	outline-offset: 1px;
}
.addMargin{
	margin: 10px, 0, 10px,0 !important;
}
/* Added for Table responsive */
.tableHeader{
	background: #f3f5fe;
	border-radius: 4px;
	font-size: 13px;
	font-weight: 600;
	white-space: nowrap;
	margin-bottom:10px
	}
	.tableHeader th{
		font-weight: 600;
		padding: 6px 18px !important;
	}		
	.tableRow{		
	margin-bottom:10px;
	background: #f3f5fe;
	/* border: 1px solid #e8e9ec; */
	}	
	.tablestyle{
		border-collapse: separate;
    	border-spacing:0 10px;
	}
	.tablestyle input{
		width:100%
	}
	.tablestyle textarea{
	width: 280px;
    border: 1px solid #ccc;
    font-size: 12px;
    height: 80px;
	}
	.tablestyle th {
		padding: .75rem;
		vertical-align: middle;
		/* border-top: 1px solid #dee2e6; */
	}
	.tablestyle td{
		padding: 0;
		vertical-align: middle;
		/* border-top: 1px solid #dee2e6; */
	}	
	.tablestyle .table thead th,.tablestyle .table td{
		border: none !important;
	}
	.tablestyle th,.tablestyle td{
		border: none !important;
	}	
	.margin-top20px{
		margin-top:20px !important;
	}
	.margin-top20px button{
		margin-top:0 !important;

	}
	.font12px{
		font-size:12px !important;
	}
	td.dataContainer{
		vertical-align: middle !important;
	}
	/* Added for Table responsive */

 #phaseBuilderModal .saveContinueDetailsContainer {
	display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.collabaratorPersonalTitleEndUserView{
	display: inline-block;
	width: fit-content;
    height: 30px;
	background: #F3F3F3 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 6px;
    opacity: 1;
	text-align: center;
	vertical-align:middle;
	line-height: 27px !important;
	text-align: left !important;
	padding-inline: 15px !important;
	overflow: hidden;
}
.collabaratorsChildContainersEndUserView{
	border: none;
}
.collabaratorsChildContainersEndUserView ul{
	list-style: none;
	display: flex;
	flex-direction: row;
	padding-left: 0 !important;
	gap: 10px;
}

.collabaratorsChildContainersEndUserViewCoOwner{
	border: none;
}
.collabaratorsChildContainersEndUserViewCoOwner ul{
	list-style: none !important;
	display: flex;
	flex-direction: row;
	gap: 10px;
}

.collaboratorsTitle span{
	color: #328ed4;
}

.methodLeftContainerEndUserView{
	box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.12);
	position: relative;
    bottom: 10px;
}
.collaboratorsImg{
	display: inline-block;
	background: url(../../../assets/Collab-Icn.svg) no-repeat;
	width: 40px; 
	height: 40px;
	cursor: pointer;
}
.topicOwnerCollaboratorsNewView{
	margin-left: 0 !important;
}
.topicTitleNewView{
	margin-right: auto !important;
	width: 60%;
    word-wrap: break-word;
	font-size: 1.5rem!important;
}
.topicsOwnerDetailsNewView{
	padding-right: 20px;
	border-left: 0px ;
	padding-left: 0px !important;


}
.newDisplay ul {
display: flex !important;
flex-wrap: wrap !important;
}

.activityTitleContainer{
	display: flex;
}
.editIconActivity{
	background: url(../../../assets/icn-Edit.svg) no-repeat;
	width: 33px;
	height: 33px;
	position:relative;
	bottom: 10px;
	margin-left: 15px;
	cursor: pointer;
}

.newSaveContinueSpan{
	background-color:#4185b9;
	color: #FFFFFF;
	margin-right: 50%;
}
.newTopicCollaboratorsButtons{
	display: flex !important;
	padding: 0px !important;
}
.newTopicCollaboratorsButtons button{
	flex-grow: 1;
	width: 50%;
	box-sizing: border-box;
}
.newTopicCollaboratorsButtons button:nth-of-type(1){
	background-color: white;
	color:#A2A2A2;
	border:none
}

.newTopicCollaboratorsButtons button:nth-of-type(2){
	background-color:#4185b9;
	color: #FFFFFF;
	margin-right: 0px !important;
}
.collabaratorPersonalTitleEndUserViewHyperLink {
    color: #0078d4;; 
    cursor: pointer; 
	margin-bottom: 0px !important;
	margin-top: 200px !important;
}

.collabaratorPersonalTitleEndUserViewHyperLink:hover {
    text-decoration: underline !important; 
    color: #0078d4;; 
}
.collaboratorsTitleDiv{
	width: 80%;
	word-wrap: break-word;
}
.activityHyperLinkName{
	padding-top: 5px;
}

.newCollaboratorsImg{
	border-left: 1px solid #e4e4e4;
    padding-left: 12px;
    display: block
}
.criteriaButtonsNew{
	display: flex;
	margin-left: 5px;
}