.announceMainDiv {
  display: flex;
  flex-direction: column;
  font-family: 'Segoe UI', sans-serif;
  padding: 0 10px;
}
.announcement {
  width: 100%;
  background: #f2f5f9;
  padding: 5px;
  margin-bottom: 10px;
}
.announceMainDiv h4 {
  font-size: 14px;
  margin-bottom: 20px;
}
.announceMainDiv h5 {
  font-size: 13px;
  margin-bottom: 10px;
}
.announceMainDiv p {
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 0;
}
.featureMainDiv {
  display: flex;
  flex-direction: column;
  font-family: 'Segoe UI', sans-serif;
  margin-top: 15px;
  padding: 0 10px;
}
.featureUpdate {
  width: 100%;
  background: #fcf8f0;
  padding: 5px;
  margin-bottom: 10px;
}
.featureMainDiv h4 {
  font-size: 14px;
  margin-bottom: 20px;
}
.featureMainDiv h5 {
  font-size: 13px;
  margin-bottom: 10px;
}
.featureMainDiv p {
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 0;
}
.announcementsEquals{
max-height: calc(100vh - 26.5rem);
    overflow: hidden;
    overflow-y: auto;
}

/* Accessibility fix  */
@media only screen and (min-width: 323px) and (max-width: 875px)  {
	.announcementsEquals{
    max-height: none !important;
    overflow: auto !important;
    }
}