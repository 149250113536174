.newActivityDetailsMainDiv {
	display: flex;
	flex-direction: column;
	width: 100%;
}
.newActivityDetailsContainer {
	display: flex;
	/* min-height: 80vh; */
	height: calc(100vh - 17.6rem);
	background: #f5f5f5;
	margin-top: 19px;
	padding: 30px 60px 130px 60px;
	flex-direction: column;
	overflow-y: scroll;
}
.activityEditSavePreviewButtons {
	padding: 5px 5px;
}
.fixedPreviewSaveBanner {
	position: sticky;
	top: 62px;
	width: 100%;
	background: #fff;
	box-shadow: 0px 1px 4px #0000001f;
	z-index: 999;
	display: flex;
	flex-direction: column;
}
#previewSaveBanner.fixedPreviewSaveBanner{
	width: 100%;
	background: #fff;
	box-shadow: 0px 1px 4px #0000001f;
	z-index: 100;
	display: flex;
	flex-direction: column;
	position:static !important;
}
.activityEditSavePreviewButtons {
	align-self: flex-end;
}
.fixedPreviewSaveBanner > div {
	margin-right: 100px;
}
.fixedPreviewSaveBanner .activityEditBreadcrumb {
	flex: 1;
	border-bottom: 1px solid #f2f2f2;
	margin-right: 0;
}
.fixedPreviewSaveBanner > div button {
	border: none;
	background: #666;
	color: #fff;
	border-radius: 4px;
	padding: 3px 20px;
	margin-right: 10px;
}
.fixedPreviewSaveBanner > div button:last-child {
	background: #f58220;
	padding: 3px 40px;
	color: #000;
}
.DetailsTabList {
	display: flex;
	flex-direction: column;
	padding: 10px 0;
}
.DetailsTab {
	display: flex;
	flex-direction: column;
	width: 100%;
}
.Detailswrapper {
	min-height: 50px;
	background: #fff;
	margin-bottom: 1px;
	display: flex;
	font-size: 13px;
	padding: 14px;
	width: 100%;
}
.DetailswrapperHeading {
	width: 20%;
	text-align: right;
	color: #666;
	padding-right: 70px;
}
span.addingInputButton {
	cursor: pointer;
	color: #0078d4;
}
.NewActivitycomplexityWRapper ul {
	/* display: flex; */
	list-style: none;
}
.NewActivitycomplexityWRapper {
	display: flex;
}
.NewActivitycomplexityWRapper .checkBoxContainer {
	padding-left: 0;
}
.NewActivitycomplexityWRapper .errorMsg {
	color: red !important;
	font-size: 11px;
}
.NewActivitycomplexityWRapper ul li {
	margin-right: 35px;
	position: relative;
}
.NewActivitycomplexityWRapper ul li span.filterLabel {
	padding-left: 23px;
}
.DetailswrapperTitle {
	width: 60%;
	flex: 1;
}
.DetailswrapperRightSec {
	width: 20%;
	text-align: right;
}
.newActivityfieldsWrapper > div input {
	width: 90%;
}
.DetailswrapperRightSec button {
	background: #e3e3e3;
	border: none;
	padding: 4px 15px;
	border-radius: 4px;
	margin-right: 10px;
}
.DetailswrapperRightSec button:last-child {
	background: #0078d4;
	color: #fff;
}
.addMoreTools {
	cursor: pointer;
	color: #0078d4;
}
.newActivityfieldsWrapper > div {
	display: flex;
}
.newActivityfieldsWrapperOwner > div{
	display: unset !important;
}
.newActivityfieldsWrapperCoOwner > div{
	display: unset !important;
}
.newActivityfieldsWrapper > div input {
	width: 90%;
	border: 1px solid #e8e9ec;
	padding: 5px 10px;
	font-size: 12px;
}
.newActivityfieldsWrapper > div i {
	font-size: 15px;
	font-weight: bolder;
	margin-left: 5px;
	cursor: pointer;
}
/* .newActivityfieldsWrapper>div div {
    display: flex;
    width: 50%;
    margin-right: 10px;
    flex-direction: column;
} */
.newActivityfieldsWrapper h6 {
	font-size: 14px;
	font-weight: 400;
}
.DetailswrapperTitle .activityEditor {
	width: auto;
}
.raciChildContainers ul li i {
	font-weight: bolder;
	vertical-align: middle;
	margin-left: 10px;
	font-size: 11px;
	cursor: pointer;
}
.raciChildContainers {
	border: 1px solid #e8e9ec;
	margin-top: 2px;
	margin-right: 30px;
	background: #fff;
	min-height: 109px;
}
.raciChildContainers ul {
	margin-bottom: 0;
	padding: 10px;
	list-style: none;
}
.raciChildContainers ul li {
	padding: 5px;
}
.raciParentContainers span {
	display: flex;
}
.raciParentContainers {
	display: flex;
	flex-direction: column;
}
.raciMainContainers {
	display: flex;
	flex-direction: column;
	width: 50%;
	margin-bottom: 20px;
}
ul.newActivityTemplateList {
	display: flex;
	flex-direction: column;
	padding: 0;
	list-style: none;
}
ul.newActivityTemplateListWithLink {
	display: flex;
	flex-direction: column;
	padding-left: 15px;
	list-style: none;
}
ul.newActivityTemplateList li .newActivityTemplateListItem {
	background: #fff;
	border: 1px solid #e8e9ec;
	padding: 6px 10px;
	width: 90%;
	margin-top: 10px;
}
ul.newActivityTemplateList li {
	display: flex;
	flex-direction: column;
}
ul.newActivityTemplateList li .addNewTemplatesToActivity {
	margin-top: 0;
}

ul.newActivityTemplateList li img {
	width: 20px;
	height: 20px;
	margin-right: 10px;
	margin-bottom: 0;
}
i.editActivityTemplateIcon {
	font-size: 12px;
	float: right;
}
i.NewdownloadTemplate {
	transform: rotate(90deg);
}
ul.newActivityTemplateList li i {
	font-weight: bold;
	margin-left: 10px;
	cursor: pointer;
}
ul.newActivityTemplateList li span {
	margin-top: 0px;
}
.addNewTemplatesToActivity {
	display: flex;
	flex-direction: column;
	background: #fff;
	padding: 10px 20px;
	margin-top: 15px;
	width: calc(100% - 4.6rem);
	margin-bottom: 20px;
}
.addNewTemplatesFields .dropDowncontainer.templateFocusDropdown,
.addNewTemplatesFields .dropDowncontainer.phasesDropdown {
	margin-right: 0;
	margin-left: 15px;
}
.DetailswrapperTitle .activityEditor {
	width: auto;
	width: calc(100% - 4.6rem);
}
.addNewTemplatesFields .dropDowncontainer.templateTypeDropdown,
.addNewTemplatesFields .dropDowncontainer.templateEditTypeDropdown,
.addNewTemplatesFields .dropDowncontainer.templateEditFocusDropdown,
.addNewTemplatesFields .dropDowncontainer.complexityDropdown {
	margin-right: 15px;
}
div#templateEditFocusDropdown .dropDowncontainer.templateEditFocusDropdown {
	margin-left: 15px;
	margin-right: 0 !important;
}
.addNewTemplatesFields.addNewTemplatesFieldsHashTags input {
	border: 1px solid #e8e9ec;
	padding: 5px 10px;
	font: normal normal normal 14px/17px Segoe UI;
letter-spacing: -0.13px;
color: #333333;
}
.addNewTemplatesFields > div:last-child h6 {
	margin-left: 15px;
}
.addNewTemplatesFieldsName > div:last-child {
	display: flex;
}
.addNewTemplatesFieldsName > div:last-child label.NotificationChkboxContainer {
	font-size: unset;
}
.addNewTemplatesFieldsName
	> div:last-child
	label.NotificationChkboxContainer
	span.CustomCheckmark {
	top: 4px;
}
.addNewTemplatesFieldsName > div:first-child {
	flex: 1;
}
.addNewTemplatesFields {
	display: flex;
	margin-top: 12px;
}
.addNewTemplatesFields > div {
	width: 49%;
}
.dropDowncontainer.phasesDropdown {
	padding: 2px 0px 2px 0px !important;
}
div#templateTypeDropdown,
div#templateFocusDropdown,
div#complexityDropdown,
div#phasesDropdown,
div#templateEditTypeDropdown,
div#templateEditFocusDropdown {
	display: flex;
}
.addNewTemplatesToActivity .dropDowncontainer.phasesDropdown {
	margin-right: 0;
	margin-left: 15px;
}
.addNewTemplatesToActivity .dropDowncontainer.phasesDropdown input {
	padding: 5px 10px;
	font-size: 12px;
}
.addNewTemplatesFieldsButton button {
	border: none;
	background: #0078d4;
	color: #fff;
	padding: 4px 40px;
	border-radius: 4px;
	margin-right: 15px;
}
.addNewTemplatesFieldsButton {
	text-align: center;
	margin-top: 20px;
}
.Detailswrapper.templateWrapActive {
	border: 1px solid #0078d4;
	background: #f1f5f9;
	min-height: 145px;
}
ul.afterSaveList {
	padding: 0;
	list-style: none;
	margin-bottom: 0;
}
ul.afterSaveList li {
	padding: 0px 0 10px 0;
	font-size: 13px;
}

.dropDowncontainer.toolsDropdown,
.dropDowncontainer.responsibleRolesList,
.dropDowncontainer.accountableRolesList,
.dropDowncontainer.consultedRolesList,
.dropDowncontainer.informedRolesList,
.dropDowncontainer.topicsDropdown,
.dropDowncontainer.topicsCategorySelectionDropdown,
.dropDowncontainer.proceduresDropdown,
.dropDowncontainer.proceduresCategorySelectionDropdown,
.dropDowncontainer.templatesDropdown,
.dropDowncontainer.templateTypeDropdown,
.dropDowncontainer.templateFocusDropdown,
.dropDowncontainer.complexityDropdown,
.dropDowncontainer.phasesDropdown,
.dropDowncontainer.templatesList,
.dropDowncontainer.templateEditTypeDropdown,
.dropDowncontainer.templateEditFocusDropdown {
	width: 100%;
	margin-right: 50px;
	background: #fff;
	border: 1px solid #e8e9ec;
}
.dropDowncontainer.toolsDropdown input,
.dropDowncontainer.topicsDropdown input,
.dropDowncontainer.topicsCategorySelectionDropdown input,
.dropDowncontainer.proceduresDropdown input,
.dropDowncontainer.proceduresCategorySelectionDropdown input,
.dropDowncontainer.templatesDropdown input,
.dropDowncontainer.templatesList input {
	border: none;
	width: 94%;
}
.dropDowncontainer.toolsDropdown input,
.dropDowncontainer.topicsDropdown input,
.dropDowncontainer.topicsCategorySelectionDropdown input,
.dropDowncontainer.proceduresDropdown input,
.dropDowncontainer.proceduresCategorySelectionDropdown input,
.dropDowncontainer.templatesDropdown input,
.dropDowncontainer.templatesList input {
	border: none;
	width: 94%;
}

.dropDowncontainer.responsibleRolesList input,
.dropDowncontainer.accountableRolesList input,
.dropDowncontainer.consultedRolesList input,
.dropDowncontainer.informedRolesList input,
.dropDowncontainer.phasesDropdown input {
	border: none;
	width: 90%;
}
.dropDowncontainer.toolsDropdown i,
.dropDowncontainer.responsibleRolesList i,
.dropDowncontainer.accountableRolesList i,
.dropDowncontainer.consultedRolesList i,
.dropDowncontainer.informedRolesList i,
.dropDowncontainer.topicsDropdown i,
.dropDowncontainer.topicsCategorySelectionDropdown i,
.dropDowncontainer.proceduresDropdown i,
.dropDowncontainer.proceduresCategorySelectionDropdown i,
.dropDowncontainer.templatesDropdown i,
.dropDowncontainer.templatesList i,
.dropDowncontainer.phasesDropdown i {
	font-size: 9px;
}
.NewActivitycomplexityWRapper
	.NotificationChkboxContainer
	.CustomCheckmark:after {
	top: -1px;
}
div#toolsDropdown label.NotificationChkboxContainer,
div#topicsDropdown label.NotificationChkboxContainer,
div#topicsCategorySelectionDropdown label.NotificationChkboxContainer,
div#proceduresDropdown label.NotificationChkboxContainer,
div#proceduresCategorySelectionDropdown label.NotificationChkboxContainer,
div#templatesList label.NotificationChkboxContainer,
div#complexityDropdown label.NotificationChkboxContainer,
div#phasesDropdown label.NotificationChkboxContainer {
	font-size: unset !important;
	margin-bottom: 0;
}
div#toolsDropdown
	label.NotificationChkboxContainer
	input[type='checkbox']:focus,
div#topicsDropdown
	label.NotificationChkboxContainer
	input[type='checkbox']:focus,
div#topicsCategorySelectionDropdown
	label.NotificationChkboxContainer
	input[type='checkbox']:focus,
div#proceduresDropdown
	label.NotificationChkboxContainer
	input[type='checkbox']:focus,
div#proceduresCategorySelectionDropdown
	label.NotificationChkboxContainer
	input[type='checkbox']:focus,
div#templatesList
	label.NotificationChkboxContainer
	input[type='checkbox']:focus,
div#templatesDropdown
	label.NotificationChkboxContainer
	input[type='checkbox']:focus,
div#addNewTemplatesWithOutOutputdata
	label.NotificationChkboxContainer
	input[type='checkbox']:focus,
div#complexityDropdown
	label.NotificationChkboxContainer
	label.NotificationChkboxContainer
	input[type='checkbox']:focus,
div#phasesDropdown
	label.NotificationChkboxContainer
	label.NotificationChkboxContainer
	input[type='checkbox']:focus {
	width: 15.5px !important;
	height: 15.5px !important;
	top: -1px !important;
	left: -3px !important;
}
.addNewTemplatesWithOutOutputdata
	label.NotificationChkboxContainer
	input[type='checkbox']:focus {
	width: 15.5px !important;
	height: 15.5px !important;
	top: -1px !important;
	left: -2px !important;
}
.complexityDropdown
	label.NotificationChkboxContainer
	input[type='checkbox']:focus,
.phasesDropdown label.NotificationChkboxContainer input[type='checkbox']:focus {
	width: 15.5px !important;
	height: 15.5px !important;
	top: -2px !important;
	left: -3px !important;
}
div#toolsDropdown span.CustomCheckmark,
div#topicsDropdown span.CustomCheckmark,
div#topicsCategorySelectionDropdown span.CustomCheckmark,
div#proceduresDropdown span.CustomCheckmark,
div#proceduresCategorySelectionDropdown span.CustomCheckmark,
div#templatesList span.CustomCheckmark,
div#complexityDropdown span.CustomCheckmark,
div#phasesDropdown span.CustomCheckmark {
	top: 2px;
	left: 0;
}
div#topicsCategorySelectionDropdown,
#proceduresCategorySelectionDropdown,
#topicsDropdown,
#proceduresDropdown {
	width: 50%;
}

.newActivityfieldsWrapper.topicsSelectionDropdown {
	display: flex;
	margin-bottom: 20px;
}
.DetailswrapperTitle .NotificationChkboxContainer .CustomCheckmark:after {
	top: -1px;
}
.dropDowncontainer.templatesList {
	margin-right: 65px;
}
.dropDowncontainer.templatesList input {
	width: 97%;
}
i.cloneActivityIcon {
	margin-top: 2px;
	cursor: pointer;
}
i.cloneActivityIcon:hover ~ .tabsTitleToolTip {
	display: block;
}
.NewActivitycomplexityWRapper ul li .tabsTitleToolTip {
	width: 181px;
	left: -49px;
	padding: 6px 15px;
}
.NewActivitycomplexityWRapper ul li label.NotificationChkboxContainer {
	margin-bottom: 0;
	font-size: unset;
}
.NewActivitycomplexityWRapper ul li span.CustomCheckmark {
	top: 3px;
	border-radius: 4px;
}
ul.afterSaveList.beforEditSavedList {
	flex: 1;
	margin-top: 15px;
}
.cantFindToolsFields {
	margin-top: 15px;
}
ul.afterSaveList.beforEditSavedList li {
	display: inline-block;
	background: #fff;
	padding: 4px 13px;
	margin-right: 10px;
	border-radius: 28px;
	margin-bottom: 10px;
}
ul.afterSaveList.beforEditSavedList li i {
	font-size: 10px;
	margin-left: 10px;
}
ul.afterSaveList li a {
	color: #333;
}
ul.activityComplexityMenuWrapper {
	float: left;
	display: flex;
	margin: 0;
	padding: 0;
	list-style: none;
}
ul.activityComplexityMenuWrapper li.activeComplexity {
	border-bottom: 4px solid #f58220;
	font-weight: 600;
}
ul.activityComplexityMenuWrapper li {
	float: left;
	padding: 10px 9.5px 4px 11px;
	list-style: none;
	text-align: center;
	cursor: pointer;
	color: #666;
}
.newActivityfieldsWrapper span.complexityLabel {
	font-size: 13px;
	padding-left: 23px;
	padding-top: 5px;
}
.newActivityfieldsWrapper span.CustomCheckmark {
	top: 8px;
}
.cantFindToolsFields input:first-child {
	margin-right: 20px;
}
.cantFindToolsFields input {
	margin-right: 10px;
}
.cantFindToolsFields button {
	border: none;
	padding: 4px 15px;
	border-radius: 4px;
	margin-right: 10px;
	background: #0078d4;
	color: #fff;
}
.addNewTemplatesToActivity .dropDownBox {
	margin-top: 0;
	padding: 6px 9px !important;
	border: none;
}
.addNewTemplatesToActivity .dropDownBox i {
	font-size: 9px;
}
.raciMainContainers .dropDowncontainer.responsibleRolesList,
.raciMainContainers .dropDowncontainer.accountableRolesList,
.raciMainContainers .dropDowncontainer.consultedRolesList,
.raciMainContainers .dropDowncontainer.informedRolesList {
	margin-right: 30px;
}
div#informedRolesList,
div#consultedRolesList,
div#accountableRolesList,
div#responsibleRolesList {
	display: flex;
}
.Detailswrapper .otherDetailsContainer {
	margin-right: 0;
}
.addNewTemplatesFieldsName {
	margin-top: 15px;
	display: flex;
}
.deliverablesTemplatesButtons {
	margin: 20px auto;
}
.addNewTemplatesDescription textarea {
	width: 100% !important;
	border: 1px solid #e4e4e4;
	margin-bottom: 20px;
	min-height: 75px;
	font: normal normal normal 14px/17px Segoe UI;
letter-spacing: -0.13px;
color: #333333;
}
.addNewTemplatesDescription {
	width: 100% !important;
}
.addNewTemplatesDescription h6 {
	margin-left: 0 !important;
}
.addNewTemplatesToActivity button.dragDropButton {
	margin-left: 20px;
}
.addNewTemplatesToActivity .entryCriteriaDdown {
	width: 50%;
}
.DetailswrapperTitle .entryCriteriaFields input {
	padding: 6px;
}
.DetailswrapperTitle .criteriaHeader {
	padding: 10px 6px;
	background: #f6f6fb;
	color: #333;
}
.DetailswrapperTitle .entryCriteriaList ul {
	padding: 5px 0;
	background: #f0f2f8;
}
.dropDowncontainer.processGroupDropdown ul {
	/* display: flex; */
	list-style: none;
	flex-direction: column;
	height: 146px;
}
.dropDowncontainer.processGroupDropdown {
	margin-left: 20px;
}
input.DetailswrapperNewActivityTitle {
	width: 92%;
	background: #fff;
	border: 1px solid #e8e9ec;
	padding: 5px 10px;
}
.DetailsTabList .dropDownList {
	z-index: 99;
}
.actCloneComplexityDpDown {
	margin: 20px auto;
}
.actCloneComplexityDpDown .dropDownBox {
	background: #0078d4;
	padding: 5px 9px !important;
	color: #fff;
}
.actCloneComplexityDpDown .dropDownList {
	background: #0078d4;
	color: #fff;
	top: 37px;
	width: 100%;
}
.actCloneComplexityDpDown .dropDownList li {
	color: #fff;
}
.actCloneComplexityDpDown .dropDownList ul li:hover {
	background: #0078d4;
}
.actCloneComplexityDpDown .dropDowncontainer {
	width: 200px;
}
.actCloneComplexityDpDown label.NotificationChkboxContainer {
	font-size: unset !important;
	margin-bottom: 0;
}
.actCloneComplexityDpDown
	.dropDowncontainer.destinationComplexityDropdown
	.CustomCheckmark {
	top: 1px;
}
.actCloneComplexityDpDown span.complexityLabel {
	padding-left: 23px;
}
.cloneActivityDetailsPopup
	.dropDowncontainer
	.NotificationChkboxContainer
	.CustomCheckmark:after {
	background: #fff;
	color: #0078d4;
}
.cloneActivityDatasWrapper {
	padding: 0 30px;
}
.cloneModalTitle {
	/* display: flex; */
}
.cloneModalTitle i {
	cursor: pointer;
	margin-top: 12px;
	font-weight: bold;
}
.cloneModalTitle p {
	font-weight: 600;
	margin-right: 30px;
}
.cloneActivityDetailsPopup .resltedActCloseListwrapper {
	padding: 0 30px;
}
.cloneActivityDetailsPopup .resltedActCloseList {
	width: 320px !important;
}
ul.inputsForSmmWRapper li {
	padding-top: 6px;
	overflow: unset;
}
.processGroupDropdownContainer {
	display: flex;
}
.DetailswrapperTitle .criteriaName {
	width: 330px;
}
div#processGroupDropdown .dropDownBox {
	margin-top: 0;
}
ul.inputsForSmmWRapper {
	padding: 0;
}
ul.afterSaveList li i {
	cursor: pointer;
}
.DetailswrapperTitle .entryCriteriaDdown .dropDownBox {
	margin-top: 0;
}
.DetailswrapperTitle .entryCriteriaDdown {
	margin-top: 20px;
}
.DetailswrapperTitle .dropDowncontainer.exitCriteriadropdown {
	width: 100%;
}
.DetailswrapperTitle span.CustomCheckmark {
	border-radius: 4px;
}
.DetailswrapperHeading span {
	color: red;
}
.Detailswrapper.complexitySelection {
	/* background: #f5f8fb; */
	/* border: 1px solid #e1eaf3;
	padding-left: 66px; */
	margin-bottom: 0px;
}
.Detailswrapper.complexitySelection ul {
	margin-bottom: 0;
}
.newActivityTemplateList .previewIconBlack {
	display: block;
	margin-top: -4px;
}
.newActivityTemplateListItemIcons {
	display: flex;
	margin-top: 15px !important;
}
span.newActivityTemplateListItemIcons button {
	border: none;
	background: none;
	padding-right: 0px;
}
.mergedPhaseLandPage span.activityEditArrangeMode {
	margin-right: 150px;
}
.newActivityTemplateListItemIcons a i {
	margin-left: 4px !important;
	width: 10px;
	height: 22px;
}
.newActivityTemplateListItemIcons a {
	display: flex;
}
.newActivityTemplateListItemIcons i[data-icon-name='Cancel'] {
	margin-left: 10px;
	margin-top: 2px;
}


.topicsAndProceduresDropdown {
	display: flex;
}

.dropDownList.categoryList {
	width: 50%;
	right: 0;
}

.lockMessage a {
	color: black;
}

.addMoreTools.addNewTemplate {
	display: flex;
	margin: 10px;
}
.topicsName {
	font-weight: 500;
}
.newActivityTemplateListWithLink img.imageOfSearchResult {
	width: 20px;
	height: 20px;
	margin-right: 10px;
	margin-bottom: 7px;
}
.addNewTemplatesFields.addNewTemplatesFieldsHashTags {
	display: flex;
	flex-direction: column;
}
.addNewTemplatesFields.addNewTemplatesFieldsHashTags input,
.addNewTemplatesFields.addNewTemplatesFieldsHashTags .hashTagsContainer {
	width: 100% !important;
	margin-bottom: 0px;
}
.addNewTemplatesWithOutOutputdata {
	display: flex;
}
.addNewTemplatesNameTitle {
	display: flex;
	flex-direction: column;
}
.addNewTemplatesWithOutOutputdata h6 {
	flex: 1;
}
.addNewTemplatesNameTitle input {
	width: 100% !important;
	border: 1px solid #e8e9ec;
	padding: 5px 10px;
	/* font-size: 12px;
	 */
	 font: normal normal normal 14px/17px Segoe UI;
	 letter-spacing: -0.13px;
	 color: #333333;
}
.newActivityTemplateListItemEditForm {
	display: flex;
	width: 100%;
}
.existingHashTagsOfActivity.methodOwner {
	pointer-events: none;
}
.addNewTemplatesFields.addNewTemplatesFieldsHashTags
	.hashTagsWithCancelCheckMark
	.hashtagsCancelIcon
	i {
	margin-left: 0;
}

.newActivityfieldsWrapper.addApproversReviwers div#approver,
.newActivityfieldsWrapper.addApproversReviwers div#reviewer {
	display: flex;
	flex-direction: column;
}

.addApproversReviwers .dropDownList.coOwner {
	top: 1px;
	width: 93%;
}

.newActivityfieldsWrapper.addApproversReviwers {
	margin-bottom: 20px;
}
ul.afterSaveListTopic.collaboratorsApprovers {
	list-style: none;
	padding-left: 1px;
	line-height: 30px;
}
.DetailswrapperRightSec .accessabilityCheckDiv {
	margin-right: 0;
	margin-left: 101px;
	margin-top: 26px;
}
.accessabilityCheckDiv:hover > div {
	color: #0078d4;
	text-decoration: underline;
}
.delTemplateAccessabilityIcon {
	float: left;
	width: 29px;
	height: 29px;
	background: url(../../assets/DoaccessabilityInfoIcon.svg) no-repeat;
	background-size: contain;
	margin: 0 5px;
}
.delTemplateAccessabilityDiv {
	display: flex;
	padding: 5px;
	box-shadow: 0px 1px 4px #0000001f;
	margin-bottom: 10px;
	background: #fff;
	width: 90%;
	margin-top: 5px;
}
.delTemplateAccessabilityDiv > div:first-child {
	flex: 1;
	line-height: 27px;
	font-weight: 600;
}
.delTemplateAccessabilityDiv .accessabilityCheckDiv {
	margin-right: 10px;
	margin-top: 4px;
}
.entryExitCriteriadiv .delTemplateAccessabilityDiv {
	width: 100%;
	margin-top: 10px;
	margin-bottom: 0;
}
.entryAndInputTabs {
	display: flex;
}
.entryAndInputTabs ul {
	display: flex;
	list-style: none;
	margin: 0;
	padding: 0;
}
.entryAndInputTabs ul li {
	padding: 0px 10px 5px 10px !important;
	cursor: pointer;
	font: normal normal 600 14px / 19px Segoe UI;
    letter-spacing: -0.14px;
    color: #222629;
    opacity: 1;
}
.entryAndInputTabs ul li.selectedEntryTab,
.entryAndInputTabs ul li.selectedExitTab {
	border-bottom: 3px solid #ffa12d;
	/* font-weight: 600; */
	font: normal normal 600 14px/19px Segoe UI;
letter-spacing: -0.14px;
color: #222629;
opacity: 1;
}
.DetailswrapperTitle.entryExit .entryNameAndTypeDiv .entryCriteriaDdown {
	width: 100%;
	margin-left: 0;
}
.DetailswrapperTitle.entryExit .criteriaDesc {
	width: 393px;
	flex: 1;
	text-align: left !important;
	padding: 10px 10px !important;
}
.entryExit .entryCriteriaList ul li:last-child .criteriaTable > div {
	border-bottom: 0;
}
.DetailswrapperTitle.entryExit .criteriaEditDeleteIcons {
	border-right: none !important;
}
h6.sub-heading.criteriaNameLabel {
	font-size: 14px;
	margin-left: 8px;
	margin-top: 5px;
}
div#entryCriteria h2.criteraType,
div#previewEntryCriteria h2.criteraType,
div#exitCriteria h2.criteraType,
div#previewExitCriteria h2.criteraType {
	font-size: 14px !important;
	margin-left: 8px;
	margin-top: 10px;
}
.DetailswrapperTitle.entryExit .entryExitCriteriadiv h6 {
	margin-bottom: 10px;
	font-size: 14px;
}
.entryExitCriteriaButtons button {
	border: none;
	padding: 4px 45px;
	margin-right: 10px;
	background: #0078d4;
	color: #fff;
	border-radius: 4px;
}
.entryExitCriteriaButtons {
	text-align: center;
	margin-bottom: 20px;
}

.entryExitCriteriaButtonsDesc button {
	border: none;
	padding: 4px 45px;
	margin-right: 10px;
	background: #0078d4;
	color: #fff;
	border-radius: 4px;
}
.entryExitCriteriaButtonsDesc {
	text-align: center;
	margin-bottom: 20px;
	margin-top:20px;
	display:flex;
}
.entryExitCriteriaButtonsDesc .addButton{
	padding: 4px 20px;
	margin-right: 10px;
	height: 32px;

}
.entryExitCriteriaButtonsDesc .cancelButton{
	padding: 4px 13px;
	margin-right: 0;
	height: 32px;

}

.entryExitCriteriaButtonsTask button {
	border: none;
	padding: 4px 45px;
	margin-right: 10px;
	background: #0078d4;
	color: #fff;
	border-radius: 4px;
}
.entryExitCriteriaButtonsTask {
	text-align: center;
	margin-bottom: 20px;
	margin-top:20px;
	display:flex;
}
.entryExitCriteriaButtonsTask .addButton{
	padding: 4px 20px;
	margin-right: 10px;
	height: 32px;

}
.entryExitCriteriaButtonsTask .cancelButton{
	padding: 4px 13px;
	margin-right: 0;
	height: 32px;

}
.DetailswrapperTitle.entryExit .entryCriteriaList ul li .criteriaTable {
	flex: 1;
}
.criteriaType {
	width: 110px !important;
}
.messageOfEntryExitIcon {
	float: right;
	width: 17px;
	height: 29px;
	background: url(../../assets/i-icon.svg) no-repeat;
	background-size: contain;
	margin: 0 5px;
	cursor: pointer;
}
.messageOfEntryExitHoverIcon {
	display: none;
	float: right;
	width: 17px;
	height: 29px;
	background: url(../../assets/i-icon.svg) no-repeat;
	background-size: contain;
	margin: 0 5px;
	cursor: pointer;
}
.descOfMessageOfEntryExit:hover .messageOfEntryExitIcon,
.descOfMessageOfEntryExit:focus .messageOfEntryExitIcon {
	display: none;
}
.descOfMessageOfEntryExit:hover .messageOfEntryExitHoverIcon,
.descOfMessageOfEntryExit:focus .messageOfEntryExitHoverIcon {
	display: block;
}
.messageOfEntryExit {
	position: relative;
}
.messageOfEntryExit .showMoreMenu {
	display: none;
	width: 800px;
	left: -20px;
	top: 39px;
}
.descOfMessageOfEntryExit:hover ~ .showMoreMenu {
	display: block;
	z-index: 999;
}
.messageOfEntryExit .showMoreMenu span.showMoreArrow {
	left: 18px;
}
span.messageOfEntryExit {
	color: #333 !important;
}
span.messageOfEntryExit .showMoreMenu li {
	background: none;
	text-align: left;
}
.activityEditSavePreviewButtons {
	display: flex;
	align-self: auto !important;
	font-size: 13px;
}
.activityVersioningDiv.topicBuilderHeaderVersionDiv p {
	margin-bottom: 0;
}
.activityVersioningDiv.topicBuilderHeaderVersionDiv p:first-child {
	flex: 1;
	margin-left: 56px;
}
.activityPublishSaveAsDraftUpdateButton {
	background: #f58220;
	padding: 3px 15px;
	border-radius: 4px;
	cursor: pointer;
}
button.activitySaveasDraftDpButton {
	padding: 6px 26px !important;
}
.activityPublishSaveAsDraftUpdateButton button {
	margin-right: 0 !important;
}
.activityPublishSaveAsDraftUpdateButton i {
	font-size: 12px;
	color: #000;
	vertical-align: middle;
}
.activityPublishSaveAsDraftUpdateButton button {
	background: #f58220 !important;
	color: #000 !important;
}
button.activitySaveasDraftDpButton {
	position: absolute;
	top: 30px;
	white-space: nowrap;
}
.activityPublishSaveAsDraftButtons {
	position: relative;
}
.activityVersioningDiv.topicBuilderHeaderVersionDiv
	ul
	li
	label.checkBoxContainer {
	padding-bottom: 0;
}
ul.activityComplexityMenuWrapper
	.activityVersioningDiv.topicBuilderHeaderVersionDiv
	ul
	li {
	padding: 0px 9.5px 10px 11px;
}
li.activityVersioningLi {
	margin-left: auto;
}
.cloneActivityDetailsPopup
	.resltedActCloseList
	.NotificationChkboxContainer
	input:focus {
	margin: 14px 0 0 1px !important;
}
.treeViewCheckBoxParent {
	display: flex;
}
.treeViewCheckBoxParent span.parentRoleText {
	align-items: end;
	margin-left: auto;
}
.treeViewCheckBox ul.treeViewCheckBoxChild {
	list-style: none;
	padding-left: 18px;
}
.rolegroupModal .singleContributionModal.modal-header {
	border-bottom: 1px solid #eceaea;
}
.dropDowncontainer.treeView span.searchIcon {
	background: #d7e7ff;
	border-radius: 50%;
	width: 19px;
	height: 19px;
	cursor: pointer;
	margin: 5px 5px;
}
.dropDowncontainer.treeView span.searchIcon i {
	transform: rotate(-90deg);
	font-weight: bolder;
	font-size: 9px;
	margin-left: 7px;
	vertical-align: text-top;
	margin-top: 1px;
}
.dropDowncontainer.treeView {
	display: flex;
	width: 100%;
}
.activityRaciRoleGroupContainer
	.activityListMainContainer
	span.titleOfActivity {
	padding: 5px 5px 5px 0px;
	display: flex !important;
}
.activityRaciRoleGroupContainer .activityChildContainer .activityChildTitle {
	width: 100%;
}
.dropDowncontainer.treeView .dropDownList {
	left: 0;
}
.newActivityfieldsWrapper
	.dropDowncontainer.treeView
	.dropDownList
	span.complexityLabel {
	padding-left: 0px;
}
.raciChildContainers .activityRaciRoleGroupContainer {
	padding: 0 5px;
	font-size: 12px;
}
.raciChildContainers .activityListMainContainer.activityGroupParent {
	margin-top: 5px;
}
.activityRaciRoleGroupContainer
	.activityContainer.activityGroup
	.activityTitle
	i[data-icon-name='cancel'] {
	font-weight: bold;
	margin-right: 5px;
	font-size: 12px;
}
.raciChildContainers {
	max-height: 150px;
	overflow-y: auto;
	padding-bottom: 5px;
}
.activityRaciRoleGroupContainer
	.activityListMainContainer
	span.titleOfActivity
	span {
	flex: 1;
	font-size: 12px;
}
.activityRaciRoleGroupContainer
	.activityListMainContainer
	span.titleOfActivity
	i {
	font-size: 12px;
}
.raciChildContainers .activityRaciRoleGroupContainer {
	max-height: initial;
	overflow-y: auto;
}
span.parentRoleText {
	margin: 6px 5px 0 0;
	font-size: 11px;
	color: #838080;
	margin-left: auto;
}
.dropDowncontainer.treeView i {
	margin-top: 9px;
}
.newActivityfieldsWrapper .raciParentContainers input[type='checkbox']:focus {
	margin: 9px 0 0 1px !important;
}
.otherDetailsAddActivity
	.NotificationChkboxContainer
	input[type='checkbox']:focus {
	margin: 14px 0 0 1px !important;
}
.activityRaciRoleGroupContainer.orphanRoleEdit span.titleOfActivity {
	padding: 5px 5px 5px 23px;
}
.raciParentContainers
	.dropDowncontainer.treeView
	label.NotificationChkboxContainer {
	margin-bottom: 0;
}
.raciParentContainers .dropDowncontainer.treeView .dropDownList ul li {
	padding: 3px 1px 6px 12px !important;
}
.activityRaciRoleGroupContainer .activityChildContainer span.titleOfActivity {
	font-size: 12px;
}
.ractMatrix
	.activityRaciRoleGroupContainer
	.activityChildContainer
	.titleOfActivity {
	padding: 4px 5px 4px 0px !important;
	cursor: default;
	margin-left: 0;
}
.raciMainContainers .dropDowncontainer .NotificationChkboxContainer input[type="checkbox"] {
    width: auto !important;
}

#addTemplateContainerId .newActivityTemplateListItemIcons a i {
	width: 15px;
    margin: 0 5px;
}

#addTemplateContainerId .newActivityTemplateListItemIcons a {
	padding-right: 5px;
}

#addTemplateContainerId .newActivityTemplateListItemIcons i[data-icon-name='Cancel'] {
	margin-left: 2px;
}
.complexity-button {
    display: flex;
    align-items: center;
    padding: 0px 15px;
    border: 1px solid #d4d4d4;
    background-color: #f5f5f5;
    cursor: pointer;
    outline: none;
    font-size: 14px;
    transition: background-color 0.3s ease;

    /* Adjust spacing between icon and text */
    gap: 5px;
}

.complexity-button:hover {
    background-color: #666;
}
.complexity-button:hover .custom-icon-button i {
    color: white !important;
}

.complexity-button:hover .custom-icon-button svg {
    stroke: white !important;
}

.complexity-button:hover span {
    color: white;
}
.forGuidanceImg img{
max-width: 100% !important;
height: auto !important;
}
.BusinessRulewrapperTitle{
	display: flex;
	flex-direction: column;
}
.cantFindToolsFieldsBusiness{
	flex-direction: column;
}   
.AddTopicFieldContenUrlContainerBusinessRule{
	display: flex;
}
.BusinessRuleriteriaHeader{
	padding: 10px 6px;
	background: #f6f6fb;
	color: #333;
	display: flex;
    padding: 6px;
    border-radius: 4px;
    font-size: 13px;
    font-weight: 600;
    white-space: nowrap;
	justify-content: space-between;
}
.criteriaDescBusinessRule{
	padding-left: 6px;
}
.editEntryExitCriteriaBusinessRule ul{
	background-color:#f0f2f8
}
.editEntryExitCriteriaBusinessRule li{
 display: flex;
 justify-content: space-between;
}
.editEntryExitCriteriaBusinessRule{
	display: flex;
	justify-content: space-between;
}
.criteriaTableBusinessRule{
	justify-content: space-between;
}
.businessRuleDetailsWrapper{
	justify-content: space-between;
}
.CustomCheckmarkOwner{
	top: 2px;
	left: 10px !important;
	border-radius: 10px !important;
	position: absolute;
    height: 15px;
    width: 15px;
    background-color: #fff;
    border: 1px solid rgba(102, 102, 102, 1);
}
.CustomCheckmarkActivityOwnerCreation{
	left: 0 !important;
	border-radius: 10px !important;
}

.methodOrActivityOwnerContainer{
	margin-top: 10px;
	padding-left: unset !important;
}

.methodOrActivityOwnerContainerEditingMode{
	margin-top: 10px;
	padding-left: 10px !important;
}
.collabaratorPersonaImagePadding{
	padding-left: 13px !important;
}
.hidePhaseDropDown{
	display: none;
}
.deliverablesPhaseContainer{
	border: 1px solid #e8e9ec;
	height: 31px;
	margin-left: 15px;
}
.deliverablesPhaseContainer input{
	width: 100% !important;
	height: 100%;
	padding-left: 10px;
	font: normal normal normal 14px/17px Segoe UI;
letter-spacing: -0.13px;
color: #333333;
}
/* .dropdown-container {
	display: flex;
	align-items: center;
	border: 1px solid #ccc;
	padding: 4px;
	border-radius: 4px;
	position: relative;
}

.dropdown-input {
	border: none;
	outline: none;
	flex-grow: 1;
}

.icon-container {
	display: flex;
	align-items: center;
}

.icon-container .ms-Icon {
	cursor: pointer;
	margin-left: 8px;
}

.dropDownList {
	position: absolute;
	width: 100%;
	background-color: white;
	border: 1px solid #ccc;
	max-height: 200px;
	overflow-y: auto;
	z-index: 1000;
}

.NotificationChkboxContainer {
	display: flex;
	align-items: center;
	padding: 4px 8px;
}

.CustomCheckmark {
	cursor: pointer;
	margin-right: 8px;
}

.complexityLabel {
	flex-grow: 1;
} */
.businessRuleIcon{
	align-items: center !important;
}
._horizontalRow{
    margin: 5px 0px 5px 0px;
    padding: 1px;
    border: none;
    height: 0.2px;
	border-radius: 6px;
    background-color: #FFFFFF;
}
._errorMessageList{
	font: normal normal normal 16px/21px Segoe UI;
	letter-spacing: -0.16px;
}
._errorMessageList ul{
    padding-left: 20px;
	line-height: 1.2;
}
._errorMessageList ul>li{
	padding-top: 5px;
}
.Toastify__toast {
    right: 70px;
    width: 400px;
}


._messageOfEntryExit .showMoreMenu {
	width: 20.5vw;
	top: 35px !important;
	left: -100px;
	text-align: left;
	padding-left: 30px;
	font: normal normal normal 13px/16px Segoe UI;
	letter-spacing: 0px;
	color: #1A1A1A;
	opacity: 1;
}
._messageOfEntryExit .showMoreMenu span.showMoreArrow {
	left: 110px;
}
span._messageOfEntryExit .showMoreMenu li {
list-style-type: disc!important;
}
._messageOfEntryExit .showMoreMenu span{
    margin-left: -15px !important;
}
._messageOfEntryExit .showMoreMenu li{
	display: list-item;
	padding: 0px;
}

._horizontalLineForRACI{
    height: 1px;
    background: #000;
    margin: 4px 0px;
	margin-left: -15px !important;
}