.templatesLandingPage {
	display: flex;
	flex-direction: column;
}

.templatesBreadcrumb {
	border-bottom: 1px solid #e4e4e4;
	padding: 0 5px;
	background: #fff;
}

.templatesDetailsContainer {
	display: flex;
	padding: 10px 20px;
	background: #fafafa;
}

.templatesTitlePart {
	padding: 10px 0;
}

.templatesTitlePart h4 {
	font-size: 18px;
	line-height: 26px;
}

.templatesSearch i {
	margin-right: 10px;
	padding: 4px 1px 1px 5px;
	color: #bcbccb;
}

.templatesSearch input {
	flex-grow: 1;
	border: none;
	background-color: #fff;
	overflow: hidden;
	font-size: 13px;
}

.templatesSearch input:focus {
	outline: none;
}

.templatesSearch {
	display: flex;
	width: 260px;
	padding: 1px;
	background-color: #fff;
	position: relative;
	border: 1px solid #e0e0e0;
	border-radius: 4px;
}

.templatesFilterPart {
	display: flex;
	padding: 10px 0;
	width: 100%;
}

.solutionMethodFilterDiv {
	margin-left: 50px;
	display: flex;
}

.phasesFilterDiv {
	margin-left: 20px;
	display: flex;
}

.templatecomplexityFilterDiv {
	margin-left: 20px;
	display: flex;
}

.solutionMethodFilterDiv>span,
.phasesFilterDiv>span,
.templatecomplexityFilterDiv>span {
	font-size: 14px;
	padding-top: 5px;
}

.templatesFilterPart .dropDownBox {
	padding: 5px 9px !important;
	margin-top: 1px;
}

.templatesFilterPart .dropDowncontainer {
	margin-left: 20px;
}

.dropDowncontainer.solutionMethodFilter {
	width: 150px;
}

.solutionMethodFilter .dropDownList,
.phaseFilter .dropDownList {
	width: 270px;
	right: 0;
}

.dropDowncontainer.phaseFilter {
	width: 150px;
}

.templatesResultPageContainer {
	display: flex;
	width: 100%;
	flex: 1 1;
	background: #f2f2f2;
	padding: 0px 20px 20px 20px;
	min-height: 70vh;
	/* margin-top: 110px; */
	flex-direction: column;
}

.templatesList {
	order: 1;
	flex: 1;
	padding-right: 26px;
	position: relative;
}

.listOfTemplateDownload {
	order: 2;
	width: 23vw;
	border-left: 1px solid #e4e4e4;
	padding: 0 20px;
	position: relative;
}

.downloadedWrapper {
	margin-bottom: 15px;
	border-bottom: 1px solid #d3d3d3;
	padding-bottom: 15px;
}

.downloadedWrapper h6 {
	color: #0070c0;
	font-size: 14px;
	line-height: 20px;
}

.noRecentlyDownloaded {
	width: 120px;
	height: 120px;
}

.tabsShowMoreMenu li a {
	color: #333;
}

.templatePagination {
	background: #f2f2f2;
}

.titleOfDownloads h6 {
	font-size: 14px;
}

.templatePhaseDetails {
	color: #666666;
	font-size: 14px;
	font-weight: 600;
}

.downloadedWrapper div:last-child {
	color: #666666;
	font-size: 12px;
}

.noResultsFound {
	position: absolute;
	top: 35%;
	left: 40%;
}

.templateDownloadList .noResultsFound {
	position: absolute;
	top: 11%;
	left: 40%;
}

.templatesFiltersAndBreadcrumbCont {
	position: sticky;
	width: 100%;
	z-index: 999;
	margin-top: 50px;
}

.templatesListCont {
	min-height: 77vh;
}

.titleOfDownloads {
	margin-bottom: 25px;
}

.templatesDetailPageContainer {
	display: flex;
	width: 100%;
	flex: 1 1;
	background: #f2f2f2;
	padding: 20px;
	min-height: 70vh;
	margin-top: 39px;
}

.tabTitle font {
	padding-top: 8px;
	font-size: 14px;
	font-weight: 600;
	position: relative;
}

.templateDownloadList .downloadedWrapper a:focus {
	outline: 1px dotted #333;
	display: block;
}

.templateListMainContainer {
	display: flex;
}

.templateFilterContainer .filterTagsContainer {
	border: none;
	margin-bottom: 3px;
}

.templatesLandingPage div#filtersRightPanel {
	position: fixed;
}

.templatesFilterPart>div {
	flex: 1;
	margin-left: 26px;
}

.dropDowncontainer.countryFilter .dropDownList {
	height: 300px;
	overflow: hidden;
}

.templatesSearch .dropDowncontainer.hashtagsDropDownContainer {
	margin: 0;
	width: 100%;
	font-size: 13px;
}

.templatesSearch .dropDowncontainer.hashtagsDropDownContainer input {
	width: 224px !important;
	border: none;
	padding: 5px;
}

.templatesSearch .dropDowncontainer.hashtagsDropDownContainer .dropDownList.hashtagDpList {
	left: -23px;
}

.sampleSubmitTemplatePopUp .detOfContributionTemplateforHashtags {
	margin-top: 18px;
}

.accessabilityIcon {
	float: left;
	width: 17px;
	height: 15px;
	background: url(../../assets/AccessabilityIcon.svg) no-repeat;
	background-size: contain;
	cursor: pointer;
}

.accessabilityHoverIcon {
	display: none;
	float: left;
	width: 17px;
	height: 17px;
	background: url(../../assets/AccessabilityHoverIcon.svg) no-repeat;
	background-size: contain;
	cursor: pointer;
}

.accessabilityCheckDiv>div:hover .accessabilityIcon {
	display: none;
}

.accessabilityCheckDiv>div:hover .accessabilityHoverIcon {
	display: block;
}

.accessabilityCheckDiv>div {
	margin-top: 2px;
	line-height: 14px;
	cursor: pointer;
}

.accessabilityCheckDiv {
	display: flex;
	margin-top: 9px;
	font-size: 13px;
	flex: unset !important;
	margin-right: 30px;
}

.accessabilityCheckText {
	margin-left: 8px;
}

.modal-dialog.AccessabilityModal {
	max-width: 800px;
}

.modal-dialog.AccessabilityModal .modal-header .modal-title {
	font-size: 17px;
	margin-left: 20px;
}

.AccessabilityCheckImage {
	float: left;
	width: 93px;
	margin-left: 20px;
	margin-right: 98px;
	height: 113px;
	background: url(../../assets/AccessabilityCheckImage.svg) no-repeat;
	background-size: contain;
}

.checkingAccessabilitySteps {
	display: flex;
}

.AccessabilityAltText {
	display: flex;
	width: 411px;
	height: 323px;
	margin: 0 auto;
	background: url(../../assets/AccessabilityEditAlternativeText.svg) no-repeat;
	background-size: contain;
}

.accessabilityAddAltText {
	display: flex;
	margin: 0 auto;
	width: 239px;
	height: 232px;
	background: url(../../assets/AccessabilityAddAlternativeText.svg) no-repeat;
	background-size: contain;
}

.accessabilityWrapText {
	display: flex;
	margin: 0 auto;
	width: 468px;
	height: 358px;
	background: url(../../assets/AccessabilityWrapTextImage.svg) no-repeat;
	background-size: contain;
}

.accessabilityTableDesign {
	display: flex;
	margin: 0 auto;
	width: 642px;
	height: 114px;
	background: url(../../assets/AccessabilityTableDesignImage.svg) no-repeat;
	background-size: contain;
}

.AccessabilityModal li {
	display: flex;
	flex-direction: column;
}

.checkingAccessabilityStepsMainont {
	display: flex;
	flex-direction: column;
	padding: 10px 23px;
}

.AccessabilityModal li i {
	font-size: 12px;
	margin-right: 10px;
	margin-left: 17px;
}

.checkingAccessabilitySteps>div {
	display: flex;
	flex-direction: column;
	flex: 1;
}

.checkingAccessabilitySteps>div ol {
	display: flex;
	flex-direction: column;
}

.checkingAccessabilitySteps>div ol li {
	display: flex;
	flex-direction: row;
}

.AccessabilityModal li b {
	margin: 0 5px;
}

.checkingAccessabilityStepsButton {
	display: flex;
}

.checkingAccessabilityStepsButton button {
	border: none;
	font-size: 14px;
	padding: 4px 20px;
	border-radius: 4px;
	background: #0078d4;
	color: #fff;
	margin: 0 auto;
}

.modal-dialog.AccessabilityModal .modal-header i {
	cursor: pointer;
}

.templatesDetailsContainer .contributorAddTemplates {
	display: flex;
	font-size: 12px;
	white-space: nowrap;
	line-height: 36px;
}

.templatesDetailsContainer .contributorAddTemplates span.contributorAddTemplatesButton {
	margin-top: 0;
	background: #fff;
	border-radius: 4px;
	margin-left: 15px;
}

.phaseCreationRightPopup.addNewTemplate .modal-dialog {
	max-width: 90%;
}

.phaseCreationRightPopup.addNewTemplate .middleDetailsContainer {
	display: flex;
	/* max-height: none; */
}

.phaseCreationRightPopup.addNewTemplate .methodOwnerMiddleContainer {
	width: 50%;
	padding: 10px;
}

.phaseCreationRightPopup.addNewTemplate .middleDetailsContainer input {
	width: 100%;
}

.phaseCreationRightPopup.addNewTemplate .addActivityFields {
	width: 548px;
	margin-top: 0;
}

.phaseCreationRightPopup.addNewTemplate .cloneActivityButtonAction {
	margin-bottom: 0;
}

.templatesDetailsContainer .contributorAddTemplates>div>span {
	font-weight: 600;
}

.phaseCreationRightPopup.addNewTemplate .cloneActivityResultsWrapper ul {
	margin: 0;
	padding: 0;
	display: block;
	width: 100%;
}

.templatesFilterPart .raciFiltersPart {
	margin: 6px 20px 0 0;
	display: flex;
}

.topicsLeftMenuIcon i:focus {
	outline: 1px dotted #fff !important;
}