.phaseLandPageMainContainer {
	width: 100%;
	display: flex;
	font-family: 'Segoe UI', sans-serif;
	padding: 0 !important;
	flex: 1;
	height: 100%;
	/* overflow-y: scroll; */
	scrollbar-width: none;
}

.descrpOfProgramDeliveryPhases .phaseLeftMenu{
	width: 20%;
	background: #f6f6f6;
	padding: 0 !important;
	order: 1;
	position: relative;
	height: auto;
}

.phaseLeftMenu {
	width: 20%;
	background: #f6f6f6;
	padding: 0 !important;
	order: 1;
	position: relative;
	height: calc(100vh - 3.6rem);
}

.solutionMethodTitle {
	font-size: 13px;
	padding: 15px 5px 15px 10px;
	display: flex;
	border-bottom: 1px solid #dcdada;
}

.tagsContainer span.disabledComplexity {
	opacity: 0.7;
}

.solutionMethodTitle h4 {
	font-size: 13px;
	flex: 1;
	padding-top: 5px;
}

.solutionMethodTitle i {
	font-size: 14px;
	font-weight: bold;
	cursor: pointer;
	color: #000;
}

.solutionMethodTitle a span {
	padding: 2px;
	background-color: #fff !important;
}

.solutionMethodTitle a {
	padding-top: 4px;
}

.phaseLeftNavMenu {
	margin: 0;
	padding: 0px;
	overflow: hidden;
	height: auto;
	overflow-y: hidden;
	scrollbar-width: thin;
	scrollbar-color: transparent transparent;
}

.phaseLeftNavMenu:hover {
	scrollbar-width: thin;
	scrollbar-color: #aaa #fff;
}

.phaseLeftNavMenu li {
	list-style: none;
	color: #333;
	font-size: 12px;
	width: 100%;
	position: relative;
	display: flex;
	flex-direction: column;
}

.phaselandMiddleContainer {
	flex: 1;
	order: 2;
	padding: 0 !important;
	margin-left: 0px;
	width: 100%;
}

.phaselandMiddleContainer.deliveryMargin {
	margin-right: 150px;
}

.descrpOfPhases {
	width: 100%;
	display: flex;
	/* height: 100%; */
}

.descrpOfProjectDeliveryPhases {
	width: 100%;
	display: flex;
	/* height: auto !important; */
	height: 100%;
}

.descrpOfProgramDeliveryPhases {
	width: 100%;
	display: flex;
	height: calc(100vh - 110px) !important;
}

.descrpOfMCEMPhases {
	width: 100%;
	display: flex;
	height: 100%;
}

.phaseDescription {
	transition: all 0.3s;
}

.phaseDescription.expand {
	flex-grow: 1;
}

.deliveryManagementDescription {
	width: 50%;
	padding: 10px;
	flex-grow: 1;
}

.breadcrumbsAndFilters {
	display: flex;
	border-bottom: 1px solid #dcdada;
	height: 40px;
	position: relative;
}

.filters {
	display: flex;
	float: right;
	margin-left: auto;
	font-size: 13px;
	padding: 3px;
}

.filtersLabel {
	padding: 7px 5px 0 0;
}

.dropDowncontainer.complexityFilter,
.dropDowncontainer.complexityDisabled {
	width: 100px;
	margin-right: 20px;
}

.dropDowncontainer.roleFilter {
	margin-right: 40px;
}

button.showMoreButton {
	border: none;
	background: no-repeat;
	color: #0078d4;
}

.complexityIcon {
	display: inline-block;
	font-size: 14px;
	font-weight: bold;
	color: #505ec7;
	padding-top: 8px;
	text-align: center;
	cursor: pointer;
	margin-right: 5px;
	margin-bottom: -3px;
}

.complexityIcon i {
	color: #81bc06 !important;
	font-weight: bolder;
}

.breadcrumbsAndFilters .complexityIcon {
	padding-top: 11px;
}

.phaseSwimlineView img {
	width: 100%;
}

.phaseSwimlineView {
	position: relative;
	overflow: hidden;
	overflow-y: auto;
	height: calc(100vh - 6.4rem);
	background: #fff;
	padding-top: 20px;
}

.phaseSwimlineView button {
	position: absolute;
	top: 11px;
	right: 11px;
	padding: 0;
	margin: 0;
	border: none;
}

.swimlineCollapseIcon {
	float: left;
	width: 37px;
	height: 37px;
	background: url(../../assets/visioCollapse.svg) no-repeat;
	background-size: contain;
}

.swimlineExpandIcon {
	float: left;
	width: 37px;
	height: 37px;
	background: url(../../assets/visioExpand.svg) no-repeat;
	background-size: contain;
}

.hideFilters {
	display: none !important;
}

button.filtersButton {
	border: none;
	background: #0078d4;
	color: #fff;
	padding: 2px 11px;
	border-radius: 2px;
	float: right;
	font-size: 12px;
	margin-top: 0px;
	margin-right: 11px;
	height: 26px;
}

.filterTagsContainer {
	display: flex;
	border-bottom: 1px solid #dcdada;
	padding: 4px;
	/* max-height: 66px; */
}

.filterTagsContainerNew {
	padding: 4px 4px 3px 9px !important;
}

.filterTagsContainerTemplateNew {
	padding: 4px 4px 3px 0px !important;
}

.tagsContainer {
	flex: 1;
}

.staticComplexity {
	border: 1.3px solid #0078d4;
	border-radius: 2px;
	padding: 2px 8px;
	font-size: 11px;
	margin: 2px 5px;
	background: #ebf3ff;
	float: left;
	font-weight: 600;
}

/* .tagsContainer span {
	border: 1.3px solid #0078d4;
	border-radius: 2px;
	padding: 2px 8px;
	font-size: 11px;
	margin: 2px 5px;
	background: #ebf3ff;
	float: left;
	font-weight: 600;
} */
button.filtersButton i {
	font-size: 10px;
	margin-left: 10px;
	vertical-align: bottom;
}

.tagsContainer span i {
	font-size: 9px;
	font-weight: bolder;
	margin-left: 3px;
	vertical-align: text-bottom;
	cursor: pointer;
}

/* .phaselandMiddleContainer.matrixView span.staticComplexity {
	float: right;
} */
.hideFiltersRightPanel {
	display: none;
}

.filterPhasesView.tagFilterHeight .contentContOfDeliveryManagment {
	height: calc(100vh - 10.3rem);
}

.filterPhasesView.tagFilterHeight .solMethodDescrOnLandPage {
	height: calc(100vh - 10.3rem);
}

.mergedPhaseLandPage .phaselandMiddleContainer {
	margin-left: 0px;
}

.mergedPhaseLandPage .phaseLeftMenu {
	width: 280px;
}

.statusOfActivities {
	display: flex;
	margin-top: 10px;
	padding: 20px 20px 10px 0;
}

span.mergedActivityStatus {
	width: 12px;
	height: 12px;
	margin-right: 5px;
	background: #f58220;
	float: left;
	margin-top: 4px;
}

span.ParentmergedActivityStatus {
	width: 12px;
	height: 12px;
	margin-right: 5px;
	margin-left: 30px;
	background: #505ec7;
	float: left;
	margin-top: 4px;
}

button.phasePublishButton {
	border: none;
	background: #f58220;
	font-size: 13px;
	float: right;
	position: absolute;
	right: 9%;
	margin-top: 5px;
	padding: 5px 30px;
	/* top: 5px; */
	border-radius: 5px;
	font-weight: 600;
}

button.phaseMCEMPublishButton {
	border: none;
	background: #f58220;
	position: relative;
	padding: 0px 25px;
	border-radius: 5px;
	font-weight: 600;
	float: right;
	font-size: 12px;
	margin-top: 0px;
	margin-right: 11px;
	height: 26px;
}

.phaseLeftNavMenu li:last-child {
	/* padding-bottom: 39px; */
}

.phaseLeftNavMenu li.moreItemLi:last-child {
	margin-bottom: 0;
	padding-bottom: 0;
}

.visioNotFound {
	text-align: center;
}

.filterTagsContainer.deleteMessageBar {
	background: #f58220;
	padding: 2px;
	font-size: 13px;
	color: #fff;
}

.filterTagsContainer.deleteMessageBar button {
	border: none;
	background: #0078d4;
}

.filterTagsContainer.deleteMessageBar button {
	border: none;
	background: #0078d4;
	padding: 2px 20px;
	height: 25px;
	margin-top: 2px;
	margin-right: 20px;
	border-radius: 4px;
	color: #fff;
}

.filterTagsContainer.deleteMessageBar div button:first-child {
	background: #1c2139;
}

.filterTagsContainer.deleteMessageBar p {
	margin: 6px auto 0 10px;
	color: #000;
}

.filterTagsContainer.deleteMessageBar i {
	font-size: 20px;
	color: #fff;
	margin-left: 21px;
}

.filterTagsContainer .globalHashtagsSearch i {
	margin-right: 5px;
	padding: 4px 1px 1px 5px;
	font-size: 12px;
}

.filterTagsContainer .globalHashtagsSearch {
	margin: 0 15px 0 0;
	height: 28px;
	font-size: 12px;
}

.filterTagsContainer .dropDowncontainer.hashtagsDropDownContainer input {
	padding: 3px 0;
}

.filterTagsContainer .dropDownList.hashtagDpList {
	width: 200px;
	left: -5px;
	top: 27px;
}

.dropDowncontainer.hashtagsDropDownContainer {
	position: relative;
}

.spinner.hashtagSpinner .spinner {
	position: absolute;
	top: 15%;
	right: 2%;
	left: auto;
}

.spinner.hashtagSpinner {
	position: absolute;
	top: 15%;
	right: 2%;
	left: auto;
}

.spinner.hashtagSpinner .spinner-border {
	width: 16px;
	height: 16px;
}

.deliveryMangementDescription p a {
	color: #0078d4;
}

/* metrix view CSS */

.deliveryPlayBookMetrixView {
	padding: 10px 20px;
	display: flex;
	box-shadow: 0px 0px 5px #0000001f;
	flex-direction: column;
	/* height: calc(100vh - 366px) !important; */
	cursor: auto;
	padding-bottom: 0px;
	/* min-height: 60vh; */
	flex-direction: column;
	/* height: 60vh; */
	cursor: auto;
}

.deliveryPlayBookProgramMetrixView {
	padding: 3px 20px;
	display: flex;
	box-shadow: 0px 0px 5px #0000001f;
	flex-direction: column;
	height: calc(100vh - 245px) !important;
	/* overflow-y: auto; */
	cursor: auto;
	padding-bottom: 0px;
	flex-direction: column;
	cursor: auto;
}

.dpLegends {
	display: flex;
	font-size: 13px;
	margin-right: 20px;
	white-space: nowrap;
	margin-bottom: 11px;
	cursor: pointer;
	padding: 2px 10px;
	border-radius: 4px;
}

.dpLegendsColorIndicator {
	width: 12px;
	height: 12px;
	margin-right: 5px;
	float: left;
	margin-top: 4px;
	background: #ffa12d;
}

.deliveryPlayBookLegendsContainer {
	display: flex;
	flex-wrap: wrap;
}

.MetrixViewContainer {
	flex: 1;
	border: 1px solid #f5f5f5;
	min-height: 50vh;
}

.MetrixViewSMContainer {
	width: 33%;
	border: 1px solid #f5f5f5;
	font-size: 13px;
	box-shadow: 0px 3px 3px #00000029;
	transition: all 0.2s ease-in;
}

.dPlayBookMetrixViewContainer {
	display: -webkit-box;
	margin-top: 15px;
	/* height: calc(100vh - 346px) !important; */
	width: 100%;
	overflow-x: auto;
	overflow-y: auto;
	scrollbar-width: thin;
}

.dPlayBookMetrixProjectViewContainer {
	/* height: calc(100vh - 420px) !important; */
	display: -webkit-box;
	margin-top: 15px;
	width: 100%;
	overflow-x: auto;
	overflow-y: auto;
	scrollbar-width: thin;
}

.dPlayBookMetrixProjectViewContainer:hover {
	scrollbar-width: thin;
	scrollbar-color: #aaa #fff;
}

.metrixViewContainerHeader {
	display: flex;
	padding: 10px 0 10px 10px;
	background: #f5f5f5;
	box-shadow: 0px 0px 3px #00000029;
	position: relative;
}

.metrixViewContainerHeader h6,
.metrixViewContainerHeader h3 {
	flex: 1;
	font-size: 15px;
	text-align: center;
	margin: 0;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	padding: 0 10px;
}

.metrixViewContainerHeader i {
	color: #ba5704;
	cursor: pointer;
	font-size: 15px;
	margin-right: 2px;
}

.metrixViewGroupsContainer {
	display: flex;
	padding: 10px;
	/* height: calc(100vh - 393px) !important; */
	overflow-y: auto;
	/* min-height: 70%; */
}

.metrixProjectViewGroupsContainer {
	display: flex;
	padding: 10px;
	/* height: calc(100vh - 404px) !important;
	overflow-y: auto;
	scrollbar-width: thin;
	scrollbar-color: transparent transparent; */
}

.metrixProjectViewGroupsContainer:hover {
	scrollbar-width: thin;
	scrollbar-color: #aaa #fff;
}

.phaselandMiddleContainer.matrixView {
	margin-left: 0;
}

.phaselandMiddleContainer.matrixView .phaseBreadcrumb {
	width: 100% !important;
	background: #fff;
	z-index: 99;
}

.phaselandMiddleContainer.matrixView .filterTagsContainer {
	position: fixed;
	width: 96%;
	z-index: 9;
	margin-top: 40px;
	background: #fff;
}

.MetrixViewSMContainer.collapsePhaseView {
	width: 200px;
}

.MetrixViewSMContainer.collapsePhaseView .complexityOfActivity {
	display: none;
}

.phaselandMiddleContainer.matrixView .breadcrumbsAndFilters {
	position: fixed;
	width: 100%;
	z-index: 99;
	background-color: white;
}

.matrixView {
	float: left;
	width: 29px;
	height: 29px;
	background: url(../../assets/MethodMatrixView.svg) no-repeat;
	background-size: contain;
}

.bladeView {
	float: left;
	width: 29px;
	height: 29px;
	background: url(../../assets/MethodBladeView.svg) no-repeat;
	background-size: contain;
	cursor: pointer;
}

.matrixViewActive {
	float: left;
	width: 29px;
	height: 29px;
	background: url(../../assets/MethodMatrixViewActive.svg) no-repeat;
	background-size: contain;
	cursor: pointer;
}

.bladeViewActive {
	float: left;
	width: 29px;
	height: 29px;
	background: url(../../assets/MethodBladeViewActive.svg) no-repeat;
	background-size: contain;
	cursor: pointer;
}

.MethodswimlineIcon {
	float: left;
	width: 29px;
	height: 29px;
	background: url(../../assets/MethodVisioIcon.svg) no-repeat;
	background-size: contain;
	cursor: pointer;
}

.MetrixViewSMContainer.collapsePhaseView .activityTitle {
	flex: 1;
}

.matrixbeakArrow {
	width: 51.208px;
	height: 70px;
	position: absolute;
	top: -13px;
	left: auto;
	right: -45px;
	z-index: 1;
	background: url(../../assets/matrixBladeBeakIcon.svg) no-repeat;
	background-size: contain;
}

.viewChangeDiv {
	display: flex;
	border-radius: 6px;
	background: #ffffff;
	display: flex;
	margin-left: auto;
	margin-top: 6px;
	position: absolute;
	right: 10px;
	z-index: 99;
}

.viewChangeDefaultSelect {
	display: flex;
}

.viewChangeDiv>span {
	cursor: pointer;
}

.viewChangeDefaultSelect div {
	position: absolute;
}

.phaselandMiddleContainer.matrixView .filterTagsContainer div#filtersRightPanel {
	top: -40px;
}

.metrixViewGroupsContainer .titleOfActivity a {
	/* font-size: 12px; */
	padding: 1px 0;
}

.MetrixViewSMContainer:last-child .metrixViewContainerHeader span.matrixbeakArrow {
	display: none;
}

.addNewSubGroupButton {
	display: flex;
}

.metrixViewGroupsContainer .activityChildContainer,
.referredSolutionMethod .activityChildContainer {
	min-height: 37px;
	position: relative;
}

.metrixViewGroupsContainer .activityChildContainer .spinner,
.referredSolutionMethod .activityChildContainer .spinner {
	left: 43%;
	top: 36%;
}

.metrixViewGroupsContainer .activityChildContainer .spinner .spinner-border,
.referredSolutionMethod .activityChildContainer .spinner .spinner-border {
	width: 15px;
	height: 15px;
}

.mergedPhaseLandPage .metrixViewGroupsContainer .activityListMainContainer {
	margin-right: 0px;
}

.metrixViewGroupsContainer .activityContainer.activityGroup .activityTitle:focus {
	outline-offset: -1px;
}

.activityContainer.activityGroup .activityTitle i:focus {
	outline-offset: -1px;
}

.viewChangeSelectDropDown {
	list-style-type: none !important;
	list-style: none !important;
	background-color: white !important;
	border: 1px solid lightgrey !important;
	width: 130px;
	right: -83px;
	box-shadow: 0px 1px 4px #ccc;
	z-index: 99;
	top: 31px;
	position: absolute !important;
	font-size: 12px;
	padding: 10px;
	min-height: 53px;
	display: flex;
	flex-direction: column;
}

span.viewChangeSelectIcons {
	display: flex;
	flex-direction: column;
}

.viewChangeDefaultSelect span.showMoreArrow {
	top: 20px;
	right: -17px;
	z-index: 999;
}

.viewChangeSelectIconContainer {
	display: flex;
	/* flex-direction: column; */
}

.dpLegends:hover span {
	color: #0078d4;
	text-decoration: underline;
}

.PhasesCollapseExpandContainer span.viewChangeSelectIconContainer {
	flex-direction: column;
}

.PhasesCollapseExpandContainer .checkBoxContainer .checkmark:after {
	left: 1px;
	top: 1px;
}

span.viewChangeSelectTitle {
	font-size: 12px;
	font-weight: 600;
	margin-bottom: 3px;
}

.viewChangeDefaultSelect i {
	font-size: 14px;
	vertical-align: bottom;
	margin-right: 2px;
	cursor: pointer;
	font-weight: bold;
	margin-top: 5px;
}

span.viewChangeSelectIcons {
	display: flex;
	flex-direction: column;
	font-size: 11px;
	margin-right: 10px;
	margin-left: 11px;
}

.phaselandMiddleContainer.matrixView div#filtersRightPanel {
	position: fixed;
}

.selectedcolourfilter {
	background: #cee8ff;
}

.PhasesCollapseExpandContainer {
	display: flex;
	justify-content: flex-end;
	font-size: 14px;
}

.filterColseIcon {
	float: right;
	width: 25px;
	height: 25px;
	background: url(../../assets/CloseIcon.svg) no-repeat;
	background-size: contain;
	cursor: pointer;
	margin-left: 6px;
}

.expandForwardIcon {
	float: right;
	width: 25px;
	height: 25px;
	background: url(../../assets/ForwardIcon.svg) no-repeat;
	background-size: contain;
	cursor: pointer;
	margin-left: 6px;
}

.backwardIcon {
	float: right;
	width: 25px;
	height: 25px;
	background: url(../../assets/BackwordIcon.svg) no-repeat;
	background-size: contain;
	cursor: pointer;
	margin-left: 6px;
}

.PhasesCollapseExpandContainer>div {
	margin-right: 17px;
	cursor: pointer;
	padding-top: 2px;
}

.viewChangeSelectIconContainer span.filterLabel {
	padding-left: 0;
	white-space: nowrap;
	/* margin-left: -12px; */
}

.viewChangeSelectIconContainer label.checkBoxContainer.filterChkboxContainer {
	font-size: unset;
	padding-bottom: 8px;
	padding-left: 0;
	margin-top: 5px;
}

.PhasesCollapseExpandContainer span.viewChangeSelectDropDown {
	right: -67px;
}

.viewChangeSelectIconContainer span.checkmark {
	left: 79px;
	top: 3px;
}

.PhasesCollapseExpandContainer span.viewChangeSelectIcons {
	margin: 0;
}

.MetrixViewSMContainer.collapsePhaseView:first-child .metrixViewGroupsContainer .activityListMainContainer .showMoreMenu {
	width: 210px;
}

.MetrixViewSMContainer.collapsePhaseView:first-child .metrixViewGroupsContainer .activityListMainContainer .showMoreMenu a.templateLink {
	display: flex;
}

.PhasesCollapseExpandContainer i {
	margin-top: 2px;
}

.raciWrapper span.activityReferredIcon {
	margin-top: 5px;
	margin-right: 5px;
}

.metrixViewContainerHeader span.activityReferredIcon {
	margin-top: 4px;
	margin-right: 17px;
}

.metrixViewGroupsContainer .activityGroupParent .activityChildTitle span.titleOfActivity a {
	display: block;
}

.metrixViewGroupsContainer .activityListMainContainer.activityGroupParent.renderSolutionMethod span.activityReferredIcon {
	margin-right: 23px;
}

.metrixViewGroupsContainer .activityListMainContainer.activityGroupParent .activityChildContainer span.activityReferredIcon {
	margin-right: 3px;
}

.activityListMainContainer.orphanActivityMatrix span.activityReferredIcon {
	margin-right: 2px;
	width: 23px;
}

.extractRolesMethodologyIcon {
	float: left;
	width: 29px;
	height: 29px;
	background: url(../../assets/ExtractIcon.svg) no-repeat;
	background-size: contain;
	cursor: pointer;
}

.modal-dialog.extractRolesMainModal {
	max-width: 450px;
}

.modal-dialog.extractRolesMainModal .modal-title {
	font-size: 16px;
}

.modal-dialog.extractRolesMainModal .extractSummeryButton {
	padding: 5px 20px;
	margin-top: 20px;
}

.solMethodDescrOnLandPage .ms-MessageBar.ms-MessageBar--warning.ms-MessageBar-multiline,
.deliveryMangementDescription .ms-MessageBar.ms-MessageBar--warning.ms-MessageBar-multiline {
	display: flex;
	margin-bottom: 45px;
	font-size: 12px;
}

.solMethodDescrOnLandPage .ms-MessageBar.ms-MessageBar--warning.ms-MessageBar-multiline .ms-MessageBar-content,
.deliveryMangementDescription .ms-MessageBar.ms-MessageBar--warning.ms-MessageBar-multiline .ms-MessageBar-content {
	background: #e5ad67;
	display: flex;
	border-radius: 7px;
}

.solMethodDescrOnLandPage .ms-MessageBar.ms-MessageBar--warning.ms-MessageBar-multiline .ms-MessageBar-content button,
.deliveryMangementDescription .ms-MessageBar.ms-MessageBar--warning.ms-MessageBar-multiline .ms-MessageBar-content button {
	border: none;
	background: #ffffff;
	color: #0078d4;
	padding: 3px 10px;
	border-radius: 4px;
	margin-left: 10px;
}

.solMethodDescrOnLandPage .ms-MessageBar.ms-MessageBar--warning.ms-MessageBar-multiline .ms-MessageBar-content .ms-MessageBar-text p,
.deliveryMangementDescription .ms-MessageBar.ms-MessageBar--warning.ms-MessageBar-multiline .ms-MessageBar-content .ms-MessageBar-text p {
	font-size: 11px;
	color: #2b314c;
	font-weight: 600;
}

.solMethodDescrOnLandPage .ms-MessageBar.ms-MessageBar--warning.ms-MessageBar-multiline.supportMethodConceptsBanner span.ms-MessageBar-innerText {
	font-weight: 600;
}

.solMethodDescrOnLandPage .ms-MessageBar.ms-MessageBar--warning.ms-MessageBar-multiline.supportMethodConceptsBanner {
	margin-bottom: 10px;
	min-height: 27px;
}

.supportMethodConceptsBanner .ms-MessageBar-icon {
	margin: 3px 0px 5px 8px;
}

.solMethodDescrOnLandPage .ms-MessageBar.ms-MessageBar--warning.ms-MessageBar-multiline.supportMethodConceptsBanner .ms-MessageBar-icon i {
	color: #000;
}

.solMethodDescrOnLandPage .ms-MessageBar.ms-MessageBar--warning.ms-MessageBar-multiline.supportMethodConceptsBanner .ms-MessageBar-text {
	margin: 5px 12px 5px 8px;
}

.phaselandMiddleContainer .raciFiltersPart {
	margin: 3px 10px 0 0;
}

.topicsMenuWrapper .raciFiltersPart {
	margin: 10px 10px 10px 10px;
	display: flex;
}

.PhasesCollapseExpandContainer span.viewChangeSelectIcons label.checkBoxContainer.filterChkboxContainer input[type='radio']:focus {
	width: 12px !important;
	height: 12px !important;
	margin-left: 25px;
	margin-top: 3px;
}

.selectAddActivityButton:focus {
	outline-offset: 9px;
}

.otherDetailsAddActivity label.NotificationChkboxContainer {
	display: inline-block;
}

.supportMethodConceptsBanner.raciViewBanner .ms-MessageBar-text {
	color: #fff;
}

.ms-MessageBar.supportMethodConceptsBanner.raciViewBanner {
	background: #2b78bc;
	border-radius: 5px;
	margin: 0px 0 10px 0;
	min-height: auto;
}

.supportMethodConceptsBanner.raciViewBanner .ms-MessageBar-content .ms-MessageBar-icon i {
	margin-top: 6px;
	color: #fff;
}

.matrixToggleFocus {
	outline: none;
}

.matrixToggleFocus:focus {
	padding: 2px;
	border: 1px solid #253B62;
}

.toggleViewWithVisio {
	top: 11px !important;
	right: 54px !important;
	height: 37px !important;
}

.noVisiofileavailable {
	color: #253B62;
	display: flex;
	justify-content: center;
	align-items: center;
	height: calc(100vh - 22.4rem);
}

.activeCollapseExpandPhases {
	padding-left: 4px;
	border: 1px solid #e0e0e0;
	background: #f4f4f4;
	border-radius: 4px;
}

._nonRealizedPage {
	padding: 0px !important;
	margin-top: -10px;
	scrollbar-width: none;
	/* height: 100% !important; */
}

.NewFilterButton {
	display: flex;
	align-items: center;
	flex-direction: row-reverse;
}

._NonRealizedValueFilters {
	float: right !important;
}

._NonrealizedComplexity {
	margin-left: 17px !important;
}

.NewFilterButton {
	display: flex;
	align-items: center;
	flex-direction: row-reverse;
}

._NonRealizedValueFilters {
	float: right !important;
}

._NonrealizedComplexity {
	margin-left: 17px !important;
}