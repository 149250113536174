.topicsDescriptionDetails.topicEditPage .sidebar {
    border: none;
    background: none;
}
.topicTitlePartDiv {
    width: calc(100% - 18.4rem);
    display: flex;
    padding: 9px 18px 5px 18px;
    border-right: 2px solid #f2f2f2;
}
.topicsDescriptionDetails.topicEditPage .topicDescTitlePart {
    padding: 0;
}
.topicsDescriptionDetails.topicEditPage .topicDescTitlePart .discardAndPreviewButton{
    margin-right: 0px;
}