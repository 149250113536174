.deliveryMangementDescription {
  display: flex;
  flex-direction: column;
  position: relative;
  transition: all 0.3s ease-out;
  width: 50%;
}
.deliveryMangementDescription.collapsed {
  width: 0;
}
.deliveryMangementDescription.methodActive {
  width: 100%;
}

.contentContOfDeliveryManagment {
  padding: 20px 25px 10px 25px;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  font-size: 13px;
  overflow-y: auto;
  height: calc(100vh - 6.5rem);
}
.descrpOfPhases.filterPhasesView .contentContOfDeliveryManagment {
  height: calc(100vh - 8.9rem);
}
/*.contentContOfDeliveryManagment:hover {
  overflow-y: overlay;
}*/
.deliveryMangementDescription {
  display: flex;
  flex-direction: column;
  position: relative;
}
.deliveryMangementDescription h4 {
  font-size: 18px;
}
.deliveryMangementDescription p {
  font-size: 13px;
  float: left;
  margin-bottom: 0;
  display: contents;
}
.deliveryMangementDescription .collapsingIcon {
  position: absolute;
  left: -18px;
  top: 57%;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  padding: 5px;
  box-shadow: -5px 0px 4px 1px #0000001f;
  cursor: pointer;
  background: white;
  z-index: 9;
}
.deliveryMangementDescription .roundingIcon {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #665eff;
  float: left;
  text-align: center;
  color: #fff;
  font-size: 12px;
  padding-top: 3px;
  padding-left: 1px;
  font-weight: bolder;
}
.solutionMethodDescription p a {
  color: #0078d4;
}
.solutionMethodCollaboratorContainer{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
