.descrpOfPhases {
	width: 100%;
	display: flex;
	min-height: inherit;
}

.solutionMethodDescription {
	display: flex;
	flex-direction: column;
	position: relative;
	/* border-right: 1px solid #dcdada; */
	transition: all 0.3s ease-out;
	width: 50%;
}

.solutionMethodDescription.collapsed {
	width: 0;
}

.solutionMethodDescription.methodActive {
	width: 100%;
}

.solMethodDescrOnLandPage {
	padding: 20px 25px 10px 25px;
	overflow: hidden;
	/* height: calc(100vh - 295px); */
	display: flex;
	flex-direction: column;
	font-size: 13px;
	overflow-y: hidden;
	scrollbar-width: thin;
	scrollbar-color: transparent transparent;
}

/* .setHeightForcfully {
	height: calc(100vh - 295px) !important
} */

.solMethodDescrOnLandPage:hover {
	scrollbar-width: thin;
	scrollbar-color: #aaa #fff;
}

.descrpOfPhases.filterPhasesView .solMethodDescrOnLandPage {
	height: calc(100vh - 8.9rem);
}

.solutionMethodDescription h4 {
	font-size: 18px;
}

.solutionMethodDescription>p {
	font-size: 13px;
	float: left;
	margin-bottom: 0;
	display: contents;
}

.solutionMethodDescription .collapsingIcon {
	position: absolute;
	right: -17px;
	top: 47%;
	width: 35px;
	height: 35px;
	border-radius: 50%;
	padding: 5px;
	box-shadow: 5px 0px 4px 1px #0000001f;
	cursor: pointer;
	background: white;
	z-index: 999;
}

.solutionMethodDescription .roundingIcon {
	width: 25px;
	height: 25px;
	border-radius: 50%;
	background: #f58220;
	float: left;
	text-align: center;
	color: #fff;
	font-size: 12px;
	padding-top: 3px;
	padding-left: 2px;
	font-weight: bolder;
}

.solutionMethodDescription.noDelivery {
	width: 100%;
	margin-left: 15px;
	overflow-x: hidden;
	max-width: 100%;
	height: 100%;
}

.tagsContainer p {
	margin: 0;
	padding: 0 15px;
	font-weight: 600;
	display: inline-block;
	float: left;
}

.tagsContainer h2 {
	margin: 0;
	padding: 0 15px;
	font-weight: 600;
	display: inline-block;
	float: left;
	font-size: 1rem !important
}