.ck-copilot-textarea {
    width: 100% !important;
    margin-bottom: 10px !important;
}

/* .ck-dialog_copilot_content{
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 1px 20px #00000029 !important;
    border: 1px solid #0078D4 !important;
    border-radius: 12px !important;
    opacity: 1 !important;
} */

.ck-copilot-prompt-button {
    background: #ffa12d !important;
    color: #000 !important;
    border: none !important;
    font-size: 13px !important;
    padding: 5px 20px !important;
    /* margin-left: 10px !important; */
    border-radius: 4px !important;
    margin-bottom: 10px !important;
}

.ck-copilot-result p {
    white-space: normal !important;
}

.ck-copilot-result-label span {
    font-weight: bold !important;
    font-size: 15px !important;
}

.ck-copilot-dialog {
    width: 50% !important;
    height: 50% !important;
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 1px 20px #00000029 !important;
    border: 1px solid #0078D4 !important;
    border-radius: 12px !important;
    opacity: 1 !important;
}

.ck-copilot-dialog .ck-form__header {
    height: 15% !important;
}

.ck-copilot-dialog-alert {
    width: 25% !important;
    height: 24% !important;
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 1px 20px #00000029 !important;
    border: 1px solid #0078D4 !important;
    border-radius: 12px !important;
    opacity: 1 !important;
}

.ck-copilot-dialog .ck-dialog__content {
    height: 74% !important;
}

.ck-copilot-dialog .ck-dialog__actions {
    height: 10% !important;
    display: flex !important;
    justify-content: flex-end !important;
    align-items: center !important;
}

.ck-copilot-dialog-content {
    white-space: initial !important;
    width: 100% !important;
    /* max-width: '500px'; */
    /* min-height: '200px'; */
    /* max-height: '280px'; */
    overflow-y: auto !important;
    overflow-x: hidden !important;
    height: 100% !important;
    position: absolute !important;
}

.ck-copilot-action {
    height: 29.5px !important;
    background: #ffa12d !important;
    color: #000 !important;
    border: none !important;
    font-size: 13px !important;
    padding: 5px 20px !important;
    margin-left: 10px !important;
    border-radius: 4px !important;
}

.ck-copilot-discard {
    height: 29.5px !important;
    border: none !important;
    font-size: 13px !important;
    padding: 5px 20px !important;
    margin-left: 10px !important;
    border-radius: 4px !important;
    background: #666 !important;
    color: #fff !important;
}