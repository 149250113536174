.helpandSupportRightPane {
	display: flex;
	flex-direction: column;
	padding: 0 10px;
}
.helpContainer,
.feedbackContainer {
	width: 100%;
	min-height: 200px;
	box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.12);
	margin-bottom: 20px;
	padding: 10px;
}
.helpandSupportRightPane button {
	text-align: center;
	background: #0078d4;
	color: #fff;
	padding: 5px 16px;
	border: none;
	line-height: 20px;
	border-radius: 4px;
}

.helpandSupportRightPane button:focus {
    outline: 1px solid #737373 !important; /* Example focus color */
}

.supportImgContainer,
.feedbackImgContainer {
	display: flex;
}
.supportImg {
	width: 80px;
	height: 80px;
	background-size: cover;
	background: url(../../assets/Icon_Support.png) no-repeat;
	float: left;
	margin: 20px auto;
}
.feedbackImg {
	width: 76px;
	height: 80px;
	background-size: cover;
	background-size: contain;
	background: url(../../assets/Icon_Feedback.png) no-repeat;
	margin: 20px auto;
}
.helpContainer a,
.feedbackContainer a {
	display: flex;
	justify-content: center;
	text-decoration: none !important;
}
.helpContainer h6,
.helpContainer .knownIssuesLink {
	display: inline-flex;
}
.helpContainer .knownIssuesLink {
	float: right;
	font-size: 14px;
}
.ineedsupButton {
	background: #0078d4;
	border: none;
    border-radius: 4px;
    color: #fff;
    line-height: 20px;
    padding: 5px 13px;
    text-align: center;
	width: 136px;
	margin: 0 auto;
}
.ineedsupButton:hover {
	color: #fff;
}
