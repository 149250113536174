.contributionPanel {
  width: 300px;
  padding: 10px;
  text-align: start;
  background: #e4e4e4;
}
.yammerPersonaImage {
  float: left;
  width: 40px;
  height: 40px;
  /* background: url(./logo.svg) no-repeat; */
  background-size: contain;
}
span.yammerPersonaTitle {
  font-size: 13px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-left: 6px;
  font-weight: 600;
  margin-right: 6px;
}
.ms-Icon--Like::before {
  content: "";
}
.ms-Icon--Like::before {
  content: "\E8E1";
}
span.yammerPersonaMiddlePart {
  display: flex;
  flex-direction: column;
}
.yammerPersonaHeader {
  display: flex;
  /* background: #fff; */
  padding: 10px;
}
span.yammerPersonaTime {
  color: #666;
  font-size: 10px;
  white-space: nowrap;
  float: right;
  margin-left: auto;
  margin-top: 4px;
}
.yammerContainer {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 1px #00000029;
}
.postFromYameer {
  font-size: 12px;
  padding: 5px;
  color: #555;
  background: #fff;
}
.YammerReply {
  margin-left: 15px;
  padding: 5px;
}
.statusOfActivities {
  display: flex;
}
span.mergedActivityStatus {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  margin-left: 10px;
  background: yellow;
  float: left;
}
span.ParentmergedActivityStatus {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  margin-left: 10px;
  background: red;
  float: left;
}
.yammerActivities {
  display: flex;
  margin: 0 46px;
  font-size: 12px;
  padding-bottom: 10px;
  color: #0071c6;
}
span.yammerLike {
  margin: 2px 10px;
}
span.yammerPersonaMessage {
  margin: 2px 10px;
}
.yammerreplyComment {
  display: flex;
  margin: 10px;
}
.yammerreplyComment input {
  width: 100%;
  padding: 2px;
  border: none;
}
.yammerPersonaImage img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin: 0px;
}
.yammerCollaborationPanel {
  height: calc(100vh - 10rem);
  overflow: hidden;
  overflow-y: auto;
  background: #f5f6fa;
}
span.yammerPersonaTitleAndMessage {
  display: flex;
  flex-direction: column;
}
span.yammerLike i {
  cursor: pointer;
  margin-right: 7px;
  vertical-align: text-top;
}
span.yammerPersonaMessage i {
  cursor: pointer;
  margin-right: 7px;
  vertical-align: text-top;
}
.yammerChildContainer {
  background: #fff;
}
.yammerUserMessage {
  font-size: 11px;
  padding: 2px 6px;
}
.replytoMsgContainer {
  display: flex;
  margin-top: 10px;
  padding: 10px 20px 10px 10px;
}
textarea.yammerTextArea {
  width: auto;
  display: flex;
  height: 32px;
  font-size: 11px;
  background: #f2f2f2;
  border: 1px solid #ccc;
  flex: 1;
  margin-top: 4px !important;
  margin-left: 6px;
  padding: 6px;
}
.yammerMsgContentUpdate {
  display: flex;
  padding: 10px;
}
textarea.yammerTextAreaContentUpdate {
  border: 1px solid #e7e8eb;
  flex: 1;
  font-size: 11px;
  min-height: 44px;
  margin-right: 2px;
}
.yammerMsgContentUpdate button {
  background: #0071c6;
  width: 60px;
  height: 44px;
  border: 1px solid #0071c6;
  font-size: 14px;
  color: #fff;
  position: relative;
}
.yammerTitlePartDiv {
  display: flex;
  width: 233px;
}
.yammerLogout {
  float: left;
  width: 22px;
  height: 20px;
  background-size: contain;
  margin-right: 7px;
  /* background: url(../../assets/LogoutIcon.svg) no-repeat; */
  cursor: pointer;
}
.yammerLogout i {
  color: #f58220;
  font-size: 17px;
  font-weight: bold;
}
.disableExtractComplexity div#extractMethodologyComplexityDropdown {
  color: #666;
}
.yammerCustoImage {
  float: left;
  width: 72px;
  height: 78px;
  background-size: contain;
  margin-right: 7px;
  margin-top: 2px;
  background: url(../../assets/YammerImage.png) no-repeat;
}
.yammerLoginContainer {
  display: flex;
  flex-direction: column;
  padding: 20px;
  position: absolute;
  top: 26%;
  left: 1%;
}
.yammerImageSection {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}
.yammerLoginContainer button {
  border: none;
  width: 48px;
  background: none;
  color: #0168b7;
  text-decoration: underline;
  margin: 0 auto;
  font-size: 16px;
}
.yammerImageSection h6 {
  font-size: 20px;
  color: #43425d;
}
.yammerLoginContainer p {
  color: #212121;
  font-size: 14px;
  margin-top: 10px;
  padding: 20px;
}
.yammerMsgContentUpdate button .spinner .spinner-border {
  width: 1rem;
  height: 1rem;
  border: 2px solid #fff;
  border-right-color: transparent;
}
.yammerMsgContentUpdate button .spinner {
  top: 9px;
  left: 35% !important;
}
.noRelatedContentMessage {
  text-align: center;
  color: #0262ab;
}

.selectedYammerPanel {
  border-bottom: 3px solid #a95813;
  color: #a95813;
  font-weight: 600;
}
.associateContentPanel ul li span {
  display: inline-flex;
}
.yammerMenuCollapsedHamberger:focus, .showYammerHamberger:focus {
  outline: 2px solid blue;  /* Adds a visible outline to focused elements */
}
.yammerMenuCollapsedHamberger, .showYammerHamberger {
  z-index: 10;  /* Ensure they are above other elements */
}
