  /* Styles for the dropdown label */
  .dropdown-label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }

  /* Styles for the custom radio button and label */
  .custom-radio {
    margin-right: 10px;
  }

  .custom-option-label {
    margin: 0;
    font-size: 15px;
    font-weight: normal;
  }

  /* Styles for the buttons */
  .btn-cancel {
    flex: 1;
    padding: 10px;
    margin: 0;
    /* Remove margin if you want the buttons to be aligned without space */
    border: none;
    color: #A2A2A2;
    font-weight: bold;
    /* Make text bold */
    cursor: pointer;
    border-radius: 0px;
    /* Rounded corners */
    font-size: 16px;
    /* Adjust font size as needed */
  }

  .btn-next {
    flex: 1;
    background: #0078D4 0% 0% no-repeat padding-box !important;
    padding: 10px !important;
    margin: 0 !important;
    /* Remove margin if you want the buttons to be aligned without space */
    border: none !important;
    color: #FFFFFF !important;
    font-weight: bold !important;
    /* Make text bold */
    cursor: pointer !important;
    border-radius: 0px !important;
    /* Rounded corners */
    font-size: 16px !important;
    /* Adjust font size as needed */
    box-shadow: 0px 0px 6px #00000029;
  }

  .dropdown-container {
    /* padding: 15px;  */
    margin-bottom: 20px;
  }

  .modal-body-container {
    padding-left: 25px;
    /* 50px padding on the left */
    padding-right: 25px;
    /* 50px padding on the right */
  }

  /* If you want all items inside the modal-body-container to have the same padding */
  .modal-body-container>* {
    padding-left: 50px;
    /* 50px padding on the left */
    padding-right: 50px;
    /* 50px padding on the right */
  }

  /* Alternatively, if you want to only target direct child divs (such as your dropdown containers) */
  .modal-body-container>div {
    padding-left: 25px;
    /* 50px padding on the left */
    padding-right: 25px;
    /* 50px padding on the right */
  }

  /* Style for the buttons in the footer to align with the dropdowns */
  .modal-footer {
    padding-left: 50px;
    /* 50px padding on the left */
    padding-right: 50px;
    /* 50px padding on the right */
    display: flex;
    justify-content: space-between;
    /* This will put space between the buttons */
    padding: 10px 20px;
    /* Adjust padding to position the buttons within the footer */
    border-top: 1px solid #e9ecef;
    /* If you want a border at the top of the footer */
  }

  .add-new-quicklink-container {
    display: flex;
    justify-content: flex-start;
    /* Aligns button to the left */
    margin-top: 20px;
    /* Space above the button */
  }

  .btn-add-new {
    padding: 10px 20px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    /* Adjust font size as needed */
  }

  .btn-add-new:hover {
    background-color: #0056b3;
  }

  /* Tabs container */
  .tabs-container {
    display: flex;
    margin-bottom: 20px;
  }

  /* Tab styling */
  .tab {
    flex-grow: 1;
    text-align: center;
    padding: 10px 0;
    cursor: pointer;
    border: none;
    background: #f0f0f0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    margin-right: 2px;
    /* To prevent tabs from sticking to each other */
    font-weight: normal;
  }

  /* Active tab styling */
  .tab.active {
    background: white;
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid white;
    font-weight: bold;
  }

  /* Non-active tab styling */
  .tab:not(.active):hover {
    background: #e9ecef;
  }

  /* Quick Link form styling */
  .quicklink-form {
    padding: 15px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    background: white;
    margin-bottom: 15px;
  }

  /* Input fields styling */
  .quicklink-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ced4da;
    border-radius: 4px;
  }

  /* Button styling */
  .quicklink-button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  .quicklink-button:hover {
    background-color: #0056b3;
  }

  /* List of links styling */
  .quicklinks-list {
    list-style-type: none;
    padding: 0;
    margin-top: 20px;
  }

  .quicklinks-list-item {
    background: #f8f9fa;
    padding: 10px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    margin-bottom: 5px;
  }

  .quicklink-container label {
    display: block;
    margin-bottom: 5px;
  }

  .quicklink-input {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ced4da;
    border-radius: 4px;
  }

  .quicklink-actions {
    display: flex;
    justify-content: end;
    /* Aligns the children (buttons) to the end of the container (right side) */
    gap: 10px;
    /* Adds space between the buttons */
  }

  .cancel-button {
    background-color: #6c757d;
    /* Bootstrap's secondary button color */
    color: white;
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    cursor: pointer;
    font-weight: bold;
    margin-right: 10px;
    /* Ensure some space between the buttons */
  }

  .cancel-button:hover {
    background-color: #5a6268;
    /* A slightly darker shade for hover */
  }

  /* Add Link Button */
  .add-link-button {
    background-color: #007bff;
    /* Bootstrap's primary button color */
    color: white;
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    cursor: pointer;
    font-weight: bold;
  }

  .add-link-button:hover {
    background-color: #0069d9;
    /* A slightly darker shade for hover */
  }

  /* Additional styles for the horizontal rule and added quicklinks display */
  .hr {
    border: none;
    height: 1px;
    background-color: #ced4da;
    /* A light grey color for the horizontal line */
    margin-top: 10px;
    /* Spacing above the horizontal line */
  }

  .quicklink-added-container {
    margin-top: 20px;
  }

  .quicklinks-list {
    list-style: none;
    padding-left: 0;
  }

  .quicklinks-list-item {
    background: #f8f9fa;
    border: 1px solid #ced4da;
    padding: 8px;
    border-radius: 4px;
    margin-bottom: 5px;
  }

  .added-quicklinks-display {
    padding-top: 10px;
  }

  .quicklink-container {
    background-color: #f3f3f3;
    margin-right: 25px;
    margin-left: 25px;
    padding-bottom: 20px;
    padding-top: 10px;
  }

  .internal-activities-container {
    background-color: #f3f3f3;
    padding: 0;
    margin-left: 25px;
    margin-right: 25px;
    border-radius: 4px;
    width: auto;
    padding-bottom: 25px;
  }

  .activity-info {
    position: relative;
    padding: 8px;
    background-color: #e9ecef;
    border-radius: 4px;
    font-size: 0.9rem;
    margin-bottom: 10px;
    width: calc(100% + 50px);
    right: 25px;
    box-sizing: border-box;
  }

  .basic-multi-select {
    margin-bottom: 10px;
  }

  .activity-actions {
    display: flex;
    justify-content: flex-end;
    /* Align buttons to the right */
    gap: 10px;
    margin-top: 10px;
  }

  .activity-cancel-button {
    background-color: #f8f9fa;
    color: #333;
    padding: 0px 12px;
    cursor: pointer;
    border: 1px solid #D6D6D6;
    border-radius: 8px;
  }

  .activity-add-button {
    background-color: #007bff;
    color: white;
    border: 1px solid #007bff;
    padding: 0px 12px;
    border-radius: 8px;
    cursor: pointer;
  }

  .activity-cancel-button:hover,
  .activity-add-button:hover {
    opacity: 0.9;
    /* Slight opacity change for hover effect */
  }

  .added-activities-display {
    margin-top: 10px;
  }

  .added-activities-list {
    margin-top: 10px;
  }

  .added-activity-item {
    margin-bottom: 5px;
    border-radius: 4px;
  }

  /* Remove border from the last item */
  .added-activity-item:last-child {
    border-bottom: none;
  }

  .activitiesList {
    display: block;
    width: 100%;
    margin-bottom: 15px;
  }

  .activityContainer {
    display: flex;
    position: relative;
    width: 100%;
  }

  /* .activityGroup {
  cursor: pointer;
} */
  .activityListMainContainer {
    background: #f6f6fb;
    border-radius: 3px;
    display: block;
    margin-top: 10px;
  }

  .mergedPhaseLandPage .activityListMainContainer {
    margin-right: 150px;
  }

  .activityTitle {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    float: left;
    display: inline-grid;
    width: 100%;
  }

  .activitytypeColor {
    border-left: 4px solid #f58220;
    border-top-left-radius: 3px;
  }

  .moreEllipsis {
    float: right;
    position: relative;
  }

  .moreEllipsis i {
    vertical-align: middle;
    padding-top: 8px;
    font-weight: bolder;
    margin-right: 10px;
  }

  .activityTitle i {
    color: #f58220;
    font-size: 10px;
    vertical-align: middle;
    cursor: pointer;
    float: left;
    padding-top: 2px;
    padding: 12px 0px 9px 5px;
  }

  .activityChildContainer {
    display: flex;
    flex-direction: column;
    border-left: 4px solid #fccd95;
    background: #f9f9fc;
  }

  .activityChildList {
    display: block;
  }

  .activityChildContainer .activityChildTitle {
    font-size: 13px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-flex;
    clear: both;
    width: 56%;
    position: relative;
    flex: 1;
  }

  .titleOfActivity {
    float: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 4px;
    display: inline-grid;
    padding: 9px 5px 9px 5px;
    cursor: pointer;
    text-align: left;
    font: normal normal 600 14px/19px Segoe UI;
    letter-spacing: -0.14px;
    color: #2B314C;
    opacity: 1;
  }

  .titleOfActivity a {
    text-decoration: none;
    color: #333;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    float: left;
  }

  .titleOfActivity a:hover {
    text-decoration: underline;
    color: #333;
  }

  .complexityOfActivity {
    display: inline-flex;
    text-align: right;
    margin-left: auto;
  }

  .raciWrapper {
    display: inline-block;
    width: 25px;
    font-size: 14px;
    font-weight: bold;
    color: #505ec7;
    padding-top: 7px !important;
    text-align: center;
    cursor: pointer;
  }

  .raciWrapper.complexityIcon i {
    color: #81bc06 !important;
    font-weight: bolder;
    margin-right: 20px;
  }

  .showMoreMenu {
    list-style-type: none !important;
    list-style: none !important;
    background-color: white !important;
    border: 1px solid lightgrey !important;
    width: 273px;
    right: -4px;
    box-shadow: 0px 1px 4px #ccc;
    z-index: 99;
    top: 31px;
    position: absolute !important;
    font-size: 13px;
    padding: 10px;
    min-height: 53px;
  }

  .showMoreMenu ul {
    padding-left: 0px !important;
    margin-bottom: 0px !important;
  }

  li.moreItemLi.templateInProgress a.templateLink {
    pointer-events: unset;
    display: flex;
    cursor: default;
  }

  .showMoreMenu li {
    list-style: none;
    float: left;
    display: block;
    width: 100%;
    margin-bottom: 4px;
    background: #e1f0d9;
    padding: 4px 4px 4px 8px;
  }

  .ellipseMenuItemImg {
    margin-right: 10px;
    float: left;
    height: 20px;
    width: 17px;
    margin-top: 2px;
  }

  .showMoreArrow {
    width: 26px;
    height: 12px;
    background-size: contain;
    position: absolute;
    top: -12px;
    right: 4px;
    background: url(../../assets/beakImage.png) no-repeat;
  }

  .faded {
    opacity: 0.2 !important;
    pointer-events: none;
  }

  .delivery .activitytypeColor {
    border-left: 4px solid #665eff !important;
  }

  .delivery .activityTitle i {
    color: #665eff;
  }

  .orangeDelivery .activityChildContainer {
    border-left: 4px solid #fccd95;
  }

  .mainTitleOfActivity {
    margin-top: 25px;
  }

  .hide {
    opacity: 0 !important;
    pointer-events: none;
  }

  .templateLink,
  .templateLink:hover {
    text-decoration: none;
    color: #000;
  }

  .templateLink:hover span {
    text-decoration: underline !important;
  }

  .templateInProgress {
    background-color: #f5f5f5 !important;
    text-decoration: none !important;
  }

  .ellipseMenuItem {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 86%;
    float: left;
  }

  .activityContainer .activityGroup {
    cursor: pointer;
  }

  .activityGroupParent span.titleOfActivity {
    font-weight: 500;
    display: block;
    margin-left: 4px !important;
    flex: 1;
    padding: 9px 5px 9px 0px;
  }

  .activityGroupParent .activityChildContainer .activityContainer.activityGroup span.titleOfActivity {
    font-weight: 500;
    padding: 9px 5px 9px 10px;
  }

  .activityGroupParent .activityChildContainer span.titleOfActivity {
    font-weight: 400;
    display: inline-grid;
    padding: 8.5px 5px 8.5px 5px;
  }

  .activityChildContainer>div {
    display: flex;
  }

  .activityGroupParent .activityTitle {
    width: 100%;
    display: flex;
  }

  .activityListMainContainer span.titleOfActivity {
    margin-left: 0px;
  }

  .complexityLegend {
    width: 17px;
    height: 15px;
    background-size: contain;
    background: url(../../assets/complexityLegend.svg) no-repeat;
    float: right;
    margin-left: 9px;
  }

  .templateInProgress .templateLink:hover span {
    text-decoration: none !important;
  }

  li.noTemplatesShowMore {
    cursor: default !important;
    text-decoration: none !important;
  }

  .titleOfActivity a:focus {
    outline-offset: -1px;
  }

  .activityChildTitle.noActivities .titleOfActivity {
    background: #fae7d3;
    margin: 1px 0;
    margin-left: 0 !important;
  }

  .activityChildTitle.noActivities {
    width: 100%;
  }

  a.ActivityEditPhaseLand i {
    font-size: 12px;
    margin-top: 8px;
    color: #333;
    margin-right: 10px;
  }

  i.phaseLandEditMode {
    font-size: 12px;
    margin-left: 6px;
    vertical-align: bottom;
    cursor: pointer;
    margin-top: 8px;
    margin-right: 10px;
  }

  i.ActivityDeletePhaseLand {
    cursor: pointer;
    margin-right: 6px;
  }

  .ActivityDeletePhaseLand.disable {
    opacity: 0.5;
  }

  .activityGroupAdditionDiv .activityTitle,
  .activityGroupAdditionDiv .activityChildTitle {
    flex: 1;
  }

  .activityGroupAdditionDiv .activityContainer {
    margin-right: 0px;
    position: relative;
  }

  .activityChildContainer .activityChildTitle {
    width: 100%;
    display: flex;
  }

  .draftActivity {
    position: absolute;
    background: #0078d4;
    top: 0px;
    left: 1px;
    font-size: 8px;
    color: #fff;
    padding: 0px 5px;
  }

  a.ActivityEditPhaseLand,
  .ActivityDeleteContainer {
    position: relative;
  }

  a.ActivityEditPhaseLand i:hover~.tabsTitleToolTip,
  i.ActivityDeletePhaseLand:hover~.tabsTitleToolTip {
    display: block;
  }

  a.ActivityEditPhaseLand .tabsTitleToolTip,
  .ActivityDeleteContainer .tabsTitleToolTip {
    left: -53px;
    white-space: nowrap;
    top: 25px;
    padding: 6px 7px;
    font-size: 12px;
  }

  .ActivityEditIcon {
    float: left;
    width: 23px;
    height: 23px;
    background: url(../../assets/editIcon.svg) no-repeat;
    background-size: contain;
    cursor: pointer;
    margin-top: 6px;
  }

  .ActivityEditIconDisabled {
    float: left;
    width: 23px;
    height: 23px;
    background: url(../../assets/editIconDisabled.svg) no-repeat;
    background-size: contain;
    cursor: pointer;
    margin-top: 6px;
  }

  .ActivityDeleteIconDisabled {
    float: left;
    width: 24px;
    height: 24px;
    background: url(../../assets/TopicOwnerDeleteIconDisabled.svg) no-repeat;
    background-size: contain;
    cursor: pointer;
    margin-top: 6px;
    margin-left: 3px;
  }

  .ActivityDeleteIcon {
    float: left;
    width: 29px;
    height: 29px;
    background: url(../../assets/TopicOwnerDeleteIcon.svg) no-repeat;
    background-size: contain;
    cursor: pointer;
    margin-top: 3px;
  }

  .activityOwnerHistoryIcon {
    float: left;
    width: 29px;
    height: 29px;
    background: url(../../assets/TopicHistoryIcon.svg) no-repeat;
    background-size: contain;
    cursor: pointer;
    margin-top: 3px;
  }

  .activityReferredIcon {
    float: left;
    width: 13px;
    height: 13px;
    background: url(../../assets/ReferChainIcon.svg) no-repeat;
    background-size: contain;
    margin-top: 12px;
    margin-right: 10px;
  }

  span.ActivityEditIcon.disable,
  span.ActivityDeleteIcon.disable {
    opacity: 0.7;
    pointer-events: none;
  }

  .phaseCreationRightPopup.methodOwnerCollaborator.topicOwnerHistoryPopUp .modal-title.h4 span {
    font-size: 16px;
  }

  .subGroupContainer .activityListMainContainer.activityGroupParent {
    width: 100%;
    flex-direction: column;
  }

  .subGroupContainer {
    box-shadow: 0px 2px 1px #0000001f;
  }

  .addNewSubGroupButton button {
    float: left;
    display: inline-flex;
    border: none;
    color: #fff;
    margin: 15px 0;
    padding: 5px 31px 5px 27px;
    background: #0078d4;
    border-radius: 4px;
  }

  .activityGroupParent .activityTitle span.ActivityEditIcon {
    margin-top: 0;
    width: 24px;
    height: 22px;
    margin: 8px 4px;
  }

  .activityGroupParent .activityTitle span.phaseAddIcon {
    margin: 8px 4px;
  }

  span.groupColor {
    width: 14px;
    background: #f58220;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .addNewSubGroupButton span {
    display: flex;
    color: #0262ab;
    font-weight: 600;
    margin: 10px 0;
  }

  .addNewSubGroupButton i {
    font-size: 12px;
    font-weight: bold;
    margin: 12px 5px;
    color: #0078d4;
  }

  .activityChildContainer .activityContainer.activityGroup span.activitytypeColor {
    border-left: 4px solid #f58220;
  }

  .compact-picker.compactPicker .flexbox-fix {
    display: none !important;
  }

  .phaseCreationRightPopup .modal-header .modal-title.h4 h3 {
    font-size: 23px;
  }

  .topicsCrowdsourceHistoryModal.adminHistoryPreview .innerContent ul {
    padding-left: 28px;
  }

  .topicsCrowdsourceHistoryModal.adminHistoryPreview .myContributionPreviewContainer h6 {
    margin: 20px 0;
  }

  .contributorAddTemplates>span:first-child {
    font-weight: 600;
    font-size: 12px;
  }

  span.contributorAddTemplatesButton {
    display: flex;
    border: 1px solid #e4e4e4;
    width: 170px;
    padding: 4px 11px;
    line-height: 27px;
    margin-top: 8px;
    font-size: 12px;
    cursor: pointer;
  }

  span.contributorAddTemplatesButtonTask {
    display: flex;
    border: 1px solid #e4e4e4;
    width: 110px;
    padding: 4px 11px;
    line-height: 27px;
    margin-top: 8px;
    font-size: 12px;
    cursor: pointer;
  }

  span.contributorAddTemplatesButton span.phaseAddIcon {
    margin-left: 14px;
  }

  span.contributorAddTemplatesButtonTask span.phaseAddIcon {
    margin-left: 14px;
  }

  .phaseCreationRightPopup.addTemplate .collabaratorsParentContainers input {
    width: 90%;
  }

  .topicsCrowdsourceHistoryModal .addingTemplatesEditAcivity .templateContentContainer ul li>div {
    display: flex;
  }


  .activityRoleGroupModalContainer {
    display: flex;
    width: 100%;
  }

  .activityRoleGroupModalContainer .roleCategoryContainer {
    width: 25%;
    padding: 0 5px;
  }

  .fade.templatesingleSelectionPopUp.rolegroupModal.modal.show .modal-dialog.modal-md.modal-dialog-centered {
    max-width: 1100px !important;
    width: 1100px;
  }

  .fade.templatesingleSelectionPopUp.rolegroupModal.modal.show .modal-dialog.modal-md.modal-dialog-centered .modal-content {
    min-height: 300px;
  }

  .NotificationChkboxContainer input:indeterminate~.CustomCheckmark {
    background-color: #3191e7;
    border: 1px solid #3191e7;
  }

  .treeViewCheckBox .treeViewCheckBoxParent .NotificationChkboxContainer span.CustomCheckmark.isIndeterminate:after {
    position: absolute;
    left: 3px;
    opacity: 1;
    top: -5px;
    color: #fff;
    font-size: 13px;
    font-weight: bold;
    content: "\2212";
    font-family: FabricMDL2Icons;
  }

  .NotificationChkboxContainer input:indeterminate~.CustomCheckmark::after {
    display: block;
  }

  .NotificationChkboxContainer input:checked~.CustomCheckmark.isIndeterminate:after {
    display: block;
  }

  .CustomCheckmark.isIndeterminate {
    position: absolute;
    height: 15px;
    width: 15px;
    background-color: #fff;
    border: 1px solid rgba(102, 102, 102, 1);
  }

  .activityBladeRaciButton span {
    letter-spacing: 4px;
    font-weight: 600;
    color: #0078d4;
  }

  .activityBladeRaciButton i {
    color: #0078d4;
    font-size: 13px;
  }

  .activityBladeRaciButton i {
    color: #0078d4;
    font-size: 10px;
    margin-right: 5px;
    padding: 3px 4px 0px 0 !important;
  }

  .rolegroupModal .activityRaciRoleGroupContainer .activityTitle {
    font-size: 12px;
  }

  .roleGroupModalClose {
    margin: 10px 0;
    text-align: center;
  }

  .roleGroupModalClose button {
    background: #f1eded;
    font-size: 13px;
    padding: 3px 30px;
    border: 1px solid #ccc;
    border-radius: 4px;
    color: #0078d4;
  }

  .rolegroupModal .activityRaciRoleGroupContainer .activityChildContainer .activityChildTitle {
    font-size: 12px;
  }

  span.parentRoleText {
    align-items: end;
    margin-left: auto;
  }

  .ulTask {
    display: block !important;
    list-style-type: none !important;
    margin-block-start: 0em !important;
    margin-block-end: 0em !important;
    margin-inline-start: 0px !important;
    margin-inline-end: 0px !important;
    padding-inline-start: 30px !important;
  }

  .liTask {
    margin-inline-start: -30px !important;
  }

  ul li div ul li {
    list-style: disc;
  }

  div ul li ul li {
    list-style: disc;
  }

  div.descT ul {
    display: block !important;
    list-style-type: none !important;
    margin-block-start: 0em !important;
    margin-block-end: 0em !important;
    margin-inline-start: 0px !important;
    margin-inline-end: 0px !important;
    padding-inline-start: 20px !important;
  }

  .activityStyle {
    margin-left: 0 !important;
    padding-left: 0 !important;
    white-space: normal;
  }

  .edit-quicklink-form {
    padding: 2%;
  }

  .input-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
  }

  .input-label {
    margin-bottom: 5px;
    font-size: 14px;
    color: #333;
  }

  .save-button,
  .cancel-button {
    padding: 6px 12px;
    border: none;
    border-radius: 4px;
    margin-right: 5px;
    cursor: pointer;
    font-size: 14px;
  }

  .save-button {
    background-color: #007bff;
    color: white;
  }

  .cancel-button {
    background-color: #6c757d;
    color: white;
  }

  .quicklink-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    margin-bottom: 5px;
  }

  .edit-icon {
    color: #007bff;
    cursor: pointer;
  }

  /* Additional styles for hover effects */
  .save-button:hover {
    background-color: #0056b3;
  }

  .cancel-button:hover {
    background-color: #5a6268;
  }

  /* Styles for the quick link group and activity */
  .quicklink-group h4 {
    font-size: 16px;
    color: #333;
    margin-bottom: 5px;
  }

  .quicklink-activity {
    background-color: #f8f9fa;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 4px;
  }

  /* Adjust the size of the Fluent UI icon if necessary */
  .edit-icon {
    font-size: 16px;
    /* Adjust the size as needed */
  }

  .input-label {
    display: block;
    /* Ensure the label is on its own line */
    margin-bottom: 5px;
    /* Space between label and input */
    font-weight: bold;
    /* Optional: if you want the label to be bold */
  }

  .button-group {
    display: flex;
    justify-content: flex-end;
    /* Aligns buttons to the right */
    gap: 10px;
    margin-top: 15px;
    /* Space above the button group */
  }

  .edit-group-name-form {
    display: flex;
    /* Align children in a row */
    align-items: center;
    /* Center children vertically */
    margin-bottom: 10px;
    /* Add some space below the form */
  }


  .group-edit-icon {
    margin-left: 8px;
    /* Space out icons from the input field */
    cursor: pointer;
    /* Change cursor to pointer on hover */
  }

  .group-action-icon {
    margin-left: 5px;
    /* Add some space between the icons and the text field */
    color: #0078d7;
    /* Color for the icon */
  }

  /* Specific styles for icon buttons */
  .group-action-icon .ms-Button-icon {
    font-size: 14px;
    /* Adjust icon size as needed */
  }

  .group-action-icon .ms-Button {
    min-width: 32px;
    /* Set a minimum width for the button */
    height: 32px;
    /* Set height to match the text field height */
  }

  .delete-warning,
  .delete-confirmation {
    padding: 10px;
    margin: 10px 0;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #ffdede;
    /* or any color you prefer */
  }

  .warning-icon,
  .close-icon {
    cursor: pointer;
  }

  .confirm-delete-btn,
  .cancel-delete-btn {
    margin-left: 10px;
    padding: 5px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  .confirm-delete-btn {
    background-color: #d9534f;
    /* Bootstrap 'btn-danger' color */
    color: white;
  }

  .cancel-delete-btn {
    background-color: #f0ad4e;
    /* Bootstrap 'btn-warning' color */
    color: white;
  }

  .addGroupButtonAction {
    display: inline-flex;
    border: 1px solid #4d87f6;
    background: #f1f5f9;
    /* padding: 8px 54px 8px 20px; */
    flex-direction: column;
    margin-bottom: 24px;
    border-radius: 4px;
    margin-left: 25px;
  }

  .addGroupButtonAction .selectAddGroupButton {
    padding: 8px 54px 8px 20px;
  }

  .selectAddGroupButton {
    color: #0262ab;
    cursor: pointer;
    font-size: 14px;
  }

  .editGroupName {
    display: flex;
    margin-left: 2%;
    width: 70%;
  }

  .editGroupName input {
    border-radius: 3px;
    width: 100%;
    border: 1px dashed #0078D4;
    text-align: left;
    font: normal normal normal 16px/21px Segoe UI;
    letter-spacing: -0.16px;
    color: #333333;
  }

  .editGroupName i {
    float: left;
    font-size: 20px;
    font-weight: bold;
    border-radius: 3px;
    width: 8%;
    border: 1px dashed #0078D4;
    color: black !important;
    cursor: pointer;
    padding: 0px 0px 0px 5px !important
  }

  .editGroupName :hover {
    content: 'Group Name' !important;
  }

  .deleteGroupWarning {
    border: 1px solid #FF1313;
    border-radius: 6px;
  }

  .deleteGroupWarning i:first-child {
    color: #FF1313;
    float: left;
    padding-left: 6px;
    padding-right: 6px;
  }

  .deleteGroupWarning span {
    color: #FF1313;
  }

  .deleteGroupWarning i {
    color: #FF1313;
    float: right;
    padding-right: 6px;
    cursor: pointer;
  }

  .deleteGroupPopUp {
    background: black;
    /* padding: 7px; */
    width: 346px;
    border-radius: 8px;
    z-index: 9;
    font-size: 13px;
    display: flex;
    color: white;
    margin-right: 2%;
    align-items: center;
    justify-content: center;
  }

  .deleteGroupPopUp p {
    margin-top: 8px;
  }

  .deleteGroupPopUp span {
    margin-top: 8px;
    margin-left: 8px;
  }

  .deleteGroupPopUp button:first-child {
    border: none;
    font-size: 12px !important;
    margin-right: 10px;
    margin-left: 10px;
    background: #9A9A9A;
    color: black;
    font-weight: 600;
    border-radius: 6px;
    padding: 4px 14px;
  }

  .deleteGroupPopUp button {
    background: #9A9A9A;
    font-weight: 600;
    color: #000;
    border-radius: 6px;
    padding: 4px 14px;
  }

  .deleteGroupPopUp button:hover {
    background: #ffffff;
  }

  .groupIcons i {
    padding: 6px;
    font-size: 16px;
    color: #9A9A9A !important;
  }

  .input-link-edit {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    width: 40%;
    float: left;
    margin-right: 13px;
  }

  .input-link-url-edit {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    width: 58%;
    float: left;
  }

  .edit-quicklink-input {
    border: 1px dashed #0078D4 !important;
    border-radius: 3px !important;
    box-shadow: 0px 0px 2px #00000040 !important;
    text-align: left;
    font: normal normal normal 15px/20px Segoe UI !important;
    letter-spacing: -0.15px;
    color: #343434;
    opacity: 1;
  }

  .btn-group {
    margin-left: 70%;
    margin-top: 2%;
  }

  .cancel-button {
    background: #e4e4e4;
    font-weight: 600;
    color: #000;
    padding: 5px 20px;
    border-radius: 6px;
  }

  .save-button {
    border: none;
    font-size: 12px !important;
    margin-right: 10px;
    background: #0078d4;
    color: #fff;
    font-weight: 600;
    border-radius: 6px;
    padding: 5px 20px;
  }

  .linkIcons i {
    padding: 2px;
    font-size: 16px;
    color: #9A9A9A !important;
  }

  .addGroupLeftPanelAction {
    background: #f1f5f9;
    /* padding: 8px 54px 8px 20px; */
    flex-direction: column;
    margin-bottom: 30px;
    border-radius: 4px;
  }

  .addGroupButton {
    /* background-color: #ffffff;
  border: 1px solid #0078D4;
  float: left;
	cursor: pointer;
	font-size: 24px;
  padding: 5px;
  border-radius: 4px;
  width: 5%;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  text-align: center; */
    background-color: #ffffff;
    border: 1px solid #0078D4;
    float: left;
    cursor: pointer;
    font-size: 20px;
    padding: 1px 5px 5px;
    border-radius: 4px;
    width: 5%;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .addButtonHeight {
    font-size: 27px !important;
    height: 38px;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important
  }

  .addGroupForm {
    background: #F3F3F3 0% 0% no-repeat padding-box;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    padding: 10px;
    width: 99%;
  }

  .addGroupFields {
    display: flow-root;
    margin-top: 10px;
    font-size: 13px;
  }

  .addGroupFields input {
    height: 30px;
    border: 1px solid #0078D4;
    font-size: 12px;
    border-radius: 3px;
  }

  .addDuplicateGroup {
    display: flow-root;
    margin-top: 10px;
    font-size: 13px;
  }

  .addDuplicateGroup input {
    max-height: 40px;
    border: 1px solid red;
    font-size: 12px;
    border-radius: 3px;
  }

  .addGroupForm span {
    margin-left: 10%;
    color: red;
  }

  .addLinkFields {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    font-size: 1rem;
  }

  #group-select {
    float: left !important;
    width: 94% !important;
  }

  .entryGroupName {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    width: 50%;
    float: left;
    margin-left: 10%;

  }

  .entryGroupName ::placeholder {
    padding-left: 12px;
  }

  .addedClass {
    margin-top: 1px !important;

  }

  .groupButtons {
    margin-left: 15px;
    float: left;
  }

  .groupButtons button {
    color: #000;
    padding: 5px 20px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #D6D6D6;
    border-radius: 8px;
    font-size: 12px !important;
  }

  .groupButtons button:first-child {
    border: none;
    font-size: 12px !important;
    margin-right: 10px;
    background: #0078d4;
    color: #fff;
    font-weight: 600;
    border-radius: 6px;
    border-radius: 8px;
    padding: 5px 20px;
  }

  .groupDeleteIcon {
    float: right;
    width: 16px;
    height: 16px;
    background: url(../../assets/video-delete.svg) no-repeat;
    background-size: contain;
    cursor: pointer;
    margin-top: 9px;
    margin-left: 8px;
    color: #acacac;
  }

  .previewQuickPanel {
    margin-left: 30%;
    width: 35%;
  }

  .preivewQuicKPhase {
    background: #253B62 0% 0% no-repeat padding-box;
    padding: 10%;
    border-radius: 4px;
  }

  .preivewQuicKPhase i {
    display: flex;
    color: white;
    font-size: 22px;
    margin-left: 40%;
  }

  .preivewQuicKPhase span {
    color: white;
    padding: 15%;
  }

  .previewGroupsQuickPanel {
    margin-top: 3%;
  }

  .previewGroupsQuick {
    background: #F3F3F3 0% 0% no-repeat padding-box;
    padding: 5% 10% 0% 10%;
    border-radius: 4px;
  }

  .previewGroupsQuick span {
    text-align: left;
    font: normal normal 600 18 px Segoe UI;
    letter-spacing: 0.22px;
    color: #05132C;
    word-wrap: break-word;
  }

  .previewQuickPanelLinks {
    margin-top: 3%;
    display: flex;
    flex-direction: column;
    margin-left: 10%;
  }

  .previewQuickPanelLinks span {
    text-align: left;
    text-decoration: underline;
    font: normal normal normal 14px/19px Segoe UI;
    letter-spacing: 0.11px;
    color: #6E90CB;
    opacity: 1;
    margin-left: 10%;
    display: flex;
    margin-top: 2%;
  }

  .managequicklinkaddbtn {
    display: flex !important;
    justify-content: flex-end !important;
    gap: 10px !important;
    border-radius: 8px !important;
  }

  .managequicklinkaddbtn button:first {
    border: 1px solid #D6D6D6 !important;
    border-radius: 8px !important;
  }

  .managequicklinkaddbtn button {
    background: #0079D4 0% 0% no-repeat padding-box;
    border-radius: 8px !important;
  }

  .addDuplicateLinkFields {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    font-size: 13px;
  }

  .addDuplicateLinkFields #group-link-text {
    border: 1px solid red !important;
  }

  .addDuplicateLinkFields span {
    text-align: right;
    color: red;
  }

  .addDuplicateLinkFields input {
    width: 100%;
    max-height: 40px;
    border: 1px solid #0078D4;
    font-size: 12px;
    border-radius: 3px;
  }

  .leftGroupSaveButton {
    border: none;
    font-size: 12px !important;
    color: #fff;
    margin-right: 10px;
    padding: 5px 20px;
    background: #0079D4 0% 0% no-repeat padding-box;
    border-radius: 8px;
  }

  .preivewPhase {
    background: #253B62 0% 0% no-repeat padding-box;
    padding: 10%;
    border-radius: 4px;
    text-align: center;

  }

  /* .preivewPhase span{
    color: white;
    padding: -
    text-align: justify;
  
  } */
  .previewLeftPanel {
    margin-left: 30%;
    width: 35%;
  }

  .previewQuickLink {
    margin-top: 1%;
    overflow-x: hidden;

  }

  .previewQuickLink a {
    margin-top: 2%;
    word-wrap: break-word;
    color: #6E90CB !important;
    font-size: 16px;
    text-align: left !important
  }

  .previewGroupsPanel::-webkit-scrollbar-thumb {
    background: #4FE4FD;
  }

  .previewGroupsPanel::-webkit-scrollbar-track {
    background: #253B62;
  }

  .previewGroupsPanel::-webkit-scrollbar-track-piece:end {
    background-color: #F9F9F9
  }

  .previewGroupsPanel {
    margin-top: 2%;
    border-radius: 4px;
    max-height: 500px;
    overflow-y: scroll;
    text-wrap: wrap;

  }

  .quickLink {
    background-color: #F9F9F9 !important;
    display: flex;
    flex-direction: column;

  }

  .quickLink p {
    color: #05132C !important;
    height: 21px;
    padding: auto !important;
    text-align: left !important;


  }

  .quickLink a {
    color: #6E90CB !important;
    font-size: 16px;
    text-align: left !important;
    text-wrap: wrap;
    display: flex;
    overflow-x: hidden;
    flex-wrap: wrap;
  }

  .quickLinkPhase {
    display: flex;
    flex-direction: column;
    font: normal normal 600 16px/21px Segoe UI;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    opacity: 1;
  }

  .duplicateQuickUrl {
    text-align: left;
    font: normal normal normal 15px/20px Segoe UI;
    letter-spacing: -0.15px;
    color: #FF0000;
    opacity: 1;

  }

  .preivewPhase span {
    padding: 5% !important;
  }

  .quickLinkSelectGroup {
    position: relative;
    color: red;
    bottom: 15px;
  }

  .linkText input {
    border: 1px solid #0078D4 !important
  }

  .linkUrl input {
    border: 1px solid #0078D4 !important
  }

  .confirmDelete :hover {
    background-color: blue !important;
    border: 3px solid red !important
  }

  .optionsDisabled {
    border: 1px solid #A2A2A2
  }

  .MCEMEdit {
    width: 14px;
    height: 14px;
  }