.genericTableList {
	display: flex;
	flex-direction: column;
	margin: 10px 0;
	font-size: 12px;
	width: 100%;
}
.genericTableHeader {
	display: flex;
	padding: 6px 18px;
	background: #f3f5fe;
	color: #000;
	margin-bottom: 10px;
	border-radius: 4px;
	font-size: 13px;
	font-weight: 600;
	white-space: nowrap;
	align-items: center;
}
.genericTableApproveSentBack,
.genericTableTitle {
	width: 200px;
}
.genericTableDownload,
.genericTablePreview,
.genericTableUpload,
.genericTablePhase {
	width: 150px;
}
.genericTableTitle {
	flex: 1;
}
.genericTableList ul {
	display: flex;
	list-style: none;
	padding: 0;
	flex-direction: column;
	margin: 0;
}
.genericTableList ul li {
	width: 100%;
}
.genericTableContainer {
	display: flex;
	padding: 18px !important;
	background: #f6f6fb;
}
.modal-dialog.phaseCreationRightPopup.manageRoles.manageSwimlanesContainer.modal-lg.modal-dialog-centered {
	max-width: 90%;
}
.genericTableApproveSentBack button {
	border: none;
	background: #0078d4;
	color: #fff;
	padding: 5px 10px;
	border-radius: 4px;
}
.genericTablePreview span.previewIconBlack {
	display: inline-block;
	margin-top: 4px;
	float: none;
}
.genericTableApproveSentBack div i {
	vertical-align: middle;
	color: #0078d4;
}
.genericTableContainer i {
	font-weight: bold;
	margin-left: 5px;
	cursor: pointer;
}
.SwimlanePreviewDiv {
	width: 100%;
	background: #f6f6fb;
}
ul.phaseSwimlaneHistory .genericTableDownload,
ul.phaseSwimlaneHistory .genericTablePreview,
ul.phaseSwimlaneHistory .genericTableUpload,
ul.phaseSwimlaneHistory .genericTablePhase {
	flex: 1;
}
.genericTableApproveSentBack {
	display: flex;
}
span.sentBack {
	color: #e50000;
	font-weight: 600;
	margin-right: 20px;
}
.SwimlaneSentBackCommentsDiv ul {
	float: right;
	width: 300px;
}
.SwimlaneSentBackCommentsDiv {
	width: 100%;
	background: #f6f6fb;
	/* height: 120px; */
	display: flex;
	justify-content: end;
}
.SwimlaneSentBackCommentsDiv span {
	float: right;
	width: 215px;
}
span.approved {
	color: #0072c9;
	font-weight: 600;
}
.phaseSwimlaneFilters {
	margin-bottom: 20px;
	display: flex;
}
.genericTableDownload span.preview i {
	transform: rotate(90deg);
	margin-left: 25px;
	font-size: 12px;
}
.genericTableApproveSentBack button:last-child {
	background: #f2f2f2;
	border: 1px solid #ccc;
	color: #006cbe;
	margin-left: 10px;
}
.SwimlaneSentBackCommentsDiv .swimlaneApprovalComments {
	float: right;
	display: flex;
	flex-direction: column;
	margin-right: 20px;
}
.SwimlaneSentBackCommentsDiv .swimlaneApprovalComments button {
	border: none;
	background: #0078d4;
	color: #fff;
	padding: 5px 10px;
	border-radius: 4px;
	width: 60px;
	margin-left: auto;
	margin-top: 5px;
}
.SwimlaneSentBackCommentsDiv .swimlaneApprovalComments textarea {
	width: 300px;
	border: 1px solid #ccc;
	min-height: 62px;
}
.SwimlaneSentBackCommentsDiv .swimlaneApprovalComments h6 {
	font-size: 12px;
}
.genericTableUpload a {
	color: #0068d9;
}
.phaseSwimlaneFilters
	.multiSelectdropDown
	.NotificationChkboxContainer
	.CustomCheckmark:after {
	position: absolute;
	left: 2px;
	opacity: 1;
	top: 0px;
}
.middleDetailsContainer
	.phaseSwimlaneFilters
	.phaseSwimlaneFilters
	.multiSelectdropDown
	label.NotificationChkboxContainer
	input {
	min-height: auto;
}
.phaseSwimlaneFilters
	label.NotificationChkboxContainer
	input[type='checkbox']:focus {
	margin: 4px 0 0 0px !important;
}
