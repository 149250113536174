.ddContainer {
	position: relative;
	width: 250px;
	float: left;
	margin-left: 20px;
}
.ddBox {
	display: block;
	min-height: 25px;
	line-height: 18px;
	overflow: hidden;
	cursor: pointer;
	position: relative;
	padding: 3px 9px !important;
	width: 150px;
	border: 1px solid #ccc;
	box-shadow: none;
	text-decoration: none;
	background: #fff;
}
.chkboxUl {
	list-style: none;
	padding: 12px 0 5px 0;
	top: 34px;
	z-index: 99999;
	-moz-box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.247058823529412);
	-webkit-box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.247058823529412);
	box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.247058823529412);
	position: absolute;
	background: #fff;
	border: 1px solid #ccc;
	border-top: 0;
	width: 100%;
}
.chkboxUl ul {
	max-height: 280px;
	overflow-y: auto;
	overflow-x: hidden;
	width: 100%;
	margin: 0;
	padding: 0;
}
.chkboxUl ul li {
	width: 100%;
	display: block;
	cursor: pointer;
	margin: 0;
	float: left;
}
.chkboxUl ul li label {
	font-family: 'SegoeUI', 'Segoe UI';
	font-weight: 400;
	font-style: normal;
	font-size: 13px;
	margin-bottom: 0px;
	vertical-align: middle;
	padding-top: 0px;
	cursor: pointer;
	width: 100%;
	position: relative;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	padding-bottom: 0px;
	color: #333333;
	float: left;
	line-height: 21px;
}
.divCheckBoxFilter {
	position: relative;
}
.chkboxUl ul li input {
	width: auto;
	margin: 0;
	height: auto;
	margin-right: 5px;
}
.styled-checkbox + label:before {
	content: '';
	margin-right: 6px;
	font-family: FabricMDL2Icons;
	display: inline-block;
	vertical-align: top;
	width: 18px;
	height: 18px;
	background: white;
	border: 1px solid rgba(102, 102, 102, 1);
	margin-right: 10px;
	margin-top: 3px;
}
.styled-checkbox:checked + label:after {
	position: absolute;
	top: 3px;
	left: 12px;
	background-color: #00b395;
	border: 1px solid #00b395;
	height: 18px;
	width: 18px;
	color: #fff;
	line-height: 12px;
	font-size: 12px;
	font-weight: normal;
	content: '\E73E';
	font-family: FabricMDL2Icons;
	margin-top: 3px;
	line-height: 16px;
	font-size: 16px;
}
.chkboxUl ul li .divCheckBoxFilter label.CheckBoxFilter {
	padding: 3px 12px 6px 12px;
}
.styled-checkbox {
	position: absolute;
	opacity: 0;
}
.styled-checkbox:checked + label:before {
	background: #ffffff;
}
.toolsAndGuidanceMultiSelectFilter span.complexityLabel {
	font-size: 12px;
	padding-left: 23px;
}
.CustomCheckmark {
	top: 13px;
	left: 1.5px;
}

.toolsAndGuidanceMultiSelectFilter .CustomCheckmark {
	top: 3px;
	left: 0;
}
.toolsAndGuidanceMultiSelectFilter label.NotificationChkboxContainer {
	margin-bottom: 0;
	font-size: unset;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.toolsAndGuidanceMultiSelectFilter
	.NotificationChkboxContainer
	.CustomCheckmark:after {
	top: -1px;
	color: #f58220;
	font-size: 9px;
}
.toolsAndGuidanceMultiSelectFilter span.complexityLabel {
	font-size: 13px;
	padding-left: 23px;
}
.toolsAndGuidanceMultiSelectFilter .CustomCheckmark {
	top: 3px;
	left: 0;
	background-color: #fff !important;
	border: 1px solid #f58220 !important;
	height: 13px;
	width: 13px;
}

.toolsAndGuidanceMultiSelectFilter
	.NotificationChkboxContainer
	.CustomCheckmark:after {
	top: -1px;
}
.filtersApplyButtons {
	display: block;
	float: right;
	margin-right: 10px;
	margin-top: 8px;
	margin-bottom: 11px;
}
.filtersApplyButtons button {
	border: none;
	background: none;
	margin-right: 10px;
	width: 56px;
	padding: 2px;
	border-radius: 4px;
	font-size: 12px;
}
.toolsAndGuidanceMultiSelectFilter .dropdownHeader,
.dropDowncontainer:hover {
	font-size: unset !important;
}
.filtersApplyButtons button:first-child {
	background: #666;
	color: #fff;
}
.filtersApplyButtons button:last-child {
	background: #f58220;
	color: #fff;
	color: #000;
}
.dropDowncontainer.toolsAndGuidanceMultiSelectFilter .dropDownList {
	width: 200px;
	top: 28px;
}
/* Changes for replacing Fluent Combobox for Tools dropdown */
#toolDropdown{	
	width: 100%;
    display: flex;    
    justify-content: space-between;
	height: 18px;
    position: relative;
    left: 79px;
    bottom: 20px;
}

#toolDropdown .ms-ComboBox-container .ms-Label{	
    margin-right:10px;    
}
#toolDropdown .ms-Dropdown-container .ms-Dropdown{
	width: 200px;
}
#toolDropdown .ms-ComboBox-container .ms-ComboBox{
	width: 200px;
}
#toolDropdownFluent .ms-ComboBox-container{	
	display: flex;
}
#toolDropdown .ms-Callout-container .ms-Callout-main .ms-Checkbox-checkbox{
	border:1px solid #f58220;
	background: #ffffff;
	}
	.filtersApplyButtonsFluent {
		display: block;		
		margin-right: 5%;
		margin-top: 8px;
		margin-bottom: 11px;
		position: relative;
		right: 21px;
	}
	.filtersApplyButtonsFluent button {
		border: none;
		background: none;
		margin-right: 10px;
		width: 56px;
		padding: 2px;
		border-radius: 4px;
		font-size: 12px;
	}
	
	.filtersApplyButtonsFluent button:first-child {
		background: #666;
		color: #fff;
	}
	.filtersApplyButtonsFluent button:last-child {
		background: #f58220;
		color: #fff;
		color: #000;
	}
	/* Changes for replacing Fluent Combobox for Tools dropdown End*/