.dropdown-submenu {
	position: relative;
}
.dropdown-submenu .dropdown-menu {
	top: 0;
	left: 100%;
	margin-top: -1px;
}
.dropdown-menu {
	display: block;
}
.dropdownmenu {
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 1000;
	float: left;
	min-width: 160px;
	padding: 5px 0;
	margin: 2px 0 0;
	font-size: 14px;
	text-align: left;
	list-style: none;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #ccc;
	border: 1px solid rgba(0, 0, 0, 0.15);
	border-radius: 4px;
	-webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
	box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}
.caret {
	display: inline-block;
	width: 0;
	height: 0;
	margin-left: 2px;
	vertical-align: middle;
	border-top: 4px dashed;
	border-top: 4px solid\9;
	border-right: 4px solid transparent;
	border-left: 4px solid transparent;
}
.dropdownmenu li a {
	padding: 8px !important;
}
.dropdownmenu li {
	padding: 0px !important;
	float: left;
	width: 100%;
}
.dropdownmenu.right {
	top: 0;
	left: 100%;
}
.dropdownmenu.right {
	width: 260px;
}
.dropdownmenu.right a.deliveryPlayBook {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.dropdownmenu a {
	padding: 8px !important;
	width: 100%;
	float: left;
}
ul#More a.nav-link .caret {
	transform: rotate(-90deg);
}
.dropdownmenu a:hover {
	text-decoration: none;
	background: #e4e4e4 !important;
}

ul#More li:first-child a.nav-link .caret {
	margin-left: 41px;
}
.addDeliveryPlaybookButton {
	display: inline-flex;
	width: 100%;
	border-top: 1px solid #e4e4e4;
	padding: 14px 10px;
}
.addDeliveryPlaybookButton button {
	background: #e4e4e4;
	border: #080808;
	color: #333;
	font-size: 13px;
}
.addDeliveryPlaybookButton a {
	display: flex;
}
.editDelevieryPlayBookIcon {
	float: right;
	width: 23px;
	height: 23px;
	background: url(../../assets/editIcon.svg) no-repeat;
	background-size: contain;
	cursor: pointer;
	margin-left: 10px;
}
.addDeliveryPlaybookButton button:hover,
.addDeliveryPlaybookButton button:active,
.addDeliveryPlaybookButton button:focus {
	color: #333 !important;
	background-color: #e4e4e4 !important;
	border-color: #000 !important;
}
.addRolesListContainer.addDeliveryPlaybookContainer .criteriaIcon {
	width: 250px;
	text-align: left;
}
.addRolesListContainer.addDeliveryPlaybookContainer .criteriaName {
	width: 400px;
	text-align: left;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.addRolesListContainer.addDeliveryPlaybookContainer .entryCriteriaList {
	font-size: 12px;
	width: 725px;
}
.addRolesListContainer.addDeliveryPlaybookContainer .criteriaTable {
	padding: 18px !important;
	background: #f6f6fb;
}
.addDeliveryPlaybookContainer .criteriaIcon,
.addDeliveryPlaybookContainer .criteriaEditDeleteIcons,
.addDeliveryPlaybookContainer .criteriaName {
	background: #fff;
	margin: 0 10px 0 0;
	border-radius: 4px;
	/* padding: 5px; */
	max-height: 32px;
}
.addDeliveryPlaybookContainer .criteriaHeader .criteriaIcon,
.addDeliveryPlaybookContainer .criteriaHeader .criteriaEditDeleteIcons,
.addDeliveryPlaybookContainer .criteriaHeader .criteriaName {
	background: none;
	text-align: left;
}
.addDeliveryPlaybookContainer .criteriaHeader {
	background: #f3f5fe;
	color: #000;
	margin-bottom: 10px;
}
.addDeliveryPlaybookContainer .criteriaEditDeleteIcons {
	width: 47px;
	background: none;
	padding: 3px;
}
.addRolesListContainer.addDeliveryPlaybookContainer ul li {
	margin-bottom: 10px;
}
.addRolesListContainer.addDeliveryPlaybookContainer ul {
	border: none;
}
.addRolesListContainer.addDeliveryPlaybookContainer .criteriaName div p {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.deliverPlaybookDeleteIcon {
	float: left;
	width: 26px;
	height: 26px;
	background: url(../../assets/TopicOwnerDeleteIcon.svg) no-repeat;
	background-size: contain;
	cursor: pointer;
	margin-left: 20px;
}
.addDeliveryPlaybookPopUp .addRoleFields {
	width: 700px;
}
.addDeliveryPlaybookPopUp .addRoleButtonAction {
	padding: 8px 4px 8px 20px;
}
.addRolesListContainer.addDeliveryPlaybookContainer .criteriaTable input {
	width: 100%;
}
.addRoleFields > div:nth-child(2) {
	flex: 1;
}
.modal-dialog.phaseCreationRightPopup.manageRoles li.activePhaseTab {
	width: 50%;
}



