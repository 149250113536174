.react-hooks-paginator {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 1rem 0;
}
.react-hooks-paginator .page-item {
  list-style: none;
}

.react-hooks-paginator .page-item:hover .page-link,
.react-hooks-paginator .page-item.active .page-link {
  background-color: #3895d3;
  border-color: #3895d3;
  color: #fff;
}

.react-hooks-paginator .page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.react-hooks-paginator .page-link {
  border: 1px solid #dee2e6;
  color: #4a4a4a;
  margin-left: -1px;
  outline: none;
  padding: 0.5rem 0.75rem;
  text-decoration: none;
  transition: all 0.2s;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
  cursor: pointer;
}

.breakPagination {
  pointer-events: none;
}

.disabled {
  background-color: grey !important ;
  pointer-events: none;
}
