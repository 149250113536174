._phasesTilesContainer {
    width: 100%;
    height: 47px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 8px;
    box-sizing: border-box;
}

._tilesWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 3px;
}

._phaseTile {
    flex: 1;
    min-width: 0;
    height: 40px;
    transition: all 0.3s ease;
    padding: 0 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
    position: relative;
}

._phaseLink {
    display: contents;
}

._phaseLink:focus,
._phaseTile:focus {
    outline: none !important;
}

._MCEMStages {
    min-width: 0;
    width: 160px;
    height: 40px;
    transition: all 0.3s ease;
    padding: 0 8px;
    display: flex;
    align-items: center;
    cursor: unset !important;
    justify-content: space-between;
}

._phaseTile:hover {
    text-decoration: none;
}

._phaseName {
    font: normal normal 600 14px/21px Segoe UI;
    line-height: 21px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
    text-align: center;
    padding: 0 4px;
}

._bgPrimary {
    background-color: #0078D4 !important;
}

._bgPrimaryDark {
    background-color: #314F7F;
}

._textPrimary {
    color: #253b62 !important;
}

._activePhaseTile {
    height: 46px !important;
    margin-bottom: -6px !important;
    outline: none !important;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.hoverPhaseText {
    margin-top: 3px;
    background: #DBE8FF 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 8px #00000042;
    border-radius: 6px;
    opacity: 1;
    z-index: 999;
    text-align: center;
    font: normal normal 600 16px/21px Segoe UI;
    letter-spacing: 0px;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    padding: 10px;
}

.hoverPhaseText p {
    color: #1B2C46 !important;
    margin: 0;
}