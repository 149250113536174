.AdminMainPageContainer {
	margin-top: 62px;
}
.manageProcessGroup .addRoleNameContainer input {
	width: 100% !important;
}
.manageProcessGroup .addRoleFields > div:nth-child(2) {
	flex: none;
}
.manageProcessGroup .addRoleFields .addRoleNameContainer {
	flex: 1;
	margin-bottom: 10px;
}
.manageProcessGroup .addRoleTitleEditPart input {
	width: 94%;
	margin-right: 10px;
}
.manageProcessGroup .addRolesListContainer ul .addRoleButtonAction {
	padding: 6px 14px 4px 20px;
}
.adminMenuTitleContainer {
	background: #fafafa;
	display: flex;
	border-bottom: 1px solid #e4e4e4;
	padding: 20px 30px;
}
.adminMenuMainWrapper {
	display: flex;
	flex-direction: column;
	padding: 30px 50px;
	background: #f2f2f2;
}
.adminMenuTileConatiner {
	display: flex;
	flex-direction: column;
}
.adminMenuTiles {
	display: flex;

	flex-wrap: wrap;
}
.adminMenuIcon {
	width: 27px;
	height: 33px;

	background: url(../../assets/adminMenuTileIcon.svg) no-repeat;
	background-size: contain;
	float: left;
}
.menuTilePart {
	background: #fff;
	margin: 0 20px 20px 0;
	width: 280px;
	padding: 10px 0;
	display: flex;
	cursor: pointer;
	align-items: center;
}
.menuTilePart i {
	float: right;
	margin-left: auto;
	margin-right: 5px;
	font-weight: bold;
	font-size: 13px;
	margin-top: 7px;
}
.menuTilePart h6 {
	font-size: 13px;
	margin-top: 7px;
}
.adminMenuSearch {
	border: 1px solid #ccc;
	margin-left: 30px;
	border-radius: 4px;
	background: #fff;
}
.adminMenuSearch input {
	border: none;
	font-size: 13px;
	padding: 3px;
}
.adminMenuSearch i {
	color: #a7a2a2;
	padding: 2px 4px 0 4px;
	font-size: 14px;
	vertical-align: text-top;
}
.adminMenuTileConatiner > h6 {
	margin-bottom: 13px;
	font-size: 15px;
}
.editRoleDetailsDiv {
	position: relative;
}
.modal-dialog.phaseCreationRightPopup.manageRoles.RaciGrid.modal-lg.modal-dialog-centered {
	max-width: 95% !important;
}
.RaciGridFiltersPanel .cloneActivityButtonAction {
	width: 100%;
	background: #f1f5f9;
	padding: 8px 3px 18px 20px;
}
.RaciGridFiltersPanel .addActivityFields {
	width: 100%;
}

.RaciGridFiltersPanel .multiSelectdropDown.CloseMultiSelectDp {
	width: 254px;
	margin-bottom: 20px;
	margin-left: 15px;
}
.RaciGridFiltersResults
	.addRolesListContainer.addDeliveryPlaybookContainer
	.entryCriteriaList {
	width: 100%;
}
.RaciGridFiltersResults .addRolesListContainer .criteriaIcon {
	width: 300px;
}
.RaciGridFiltersResults .criteriaName,
.RaciGridFiltersResults .criteriaDescription,
.RaciGridFiltersResults .criteriaIsActive,
.RaciGridFiltersResults .criteriaIsFlyout,
.RaciGridFiltersResults .criteriaEditDeleteIcons {
	width: 200px;
}
.raciExportDiv {
	padding: 0px 5px;
}
.raciExportDiv button {
	font-size: 12px;
	padding: 5px 10px;
	background-color: #0078d4;
	white-space: nowrap;
}
.raciExportDiv button i {
	margin-left: 11px;
	vertical-align: inherit;
	font-size: 11px;
	margin-top: 0;
}
.exportToJsonDiv .addActivityFields > div {
	display: flex;
}
.exportToJsonDiv .addActivityFields > div span.complexityLabel {
	padding-left: 10px;
	font-weight: 600;
}
.generatingButtons button {
	font-size: 13px;
	background: #0078d4;
	margin-right: 65px;
	margin-top: 15px;
	margin-bottom: 15px;
}
.ManageTutorials label.NotificationChkboxContainer span.CustomCheckmark:after {
	top: -1px;
}
.modal-dialog.phaseCreationRightPopup.manageRoles.manageFeatures.manageAnnouncements.modal-lg.modal-dialog-centered {
	max-width: 80%;
}
.modal-dialog.manageLeftPanel{
	max-width: 60% !important;
}
.manageAnnouncements .criteriaIcon {
	width: 350px !important;
}
.manageAnnouncements .addRoleFields .addRoleNameContainer:first-child input {
	width: 350px;
}
.toggleFiltersListContainer ul li {
	background: #f1f5f9;
	padding: 10px 11px;
	width: 200px;
	margin-right: 10px;
	border-radius: 6px;
}
.toggleFiltersListContainer ul {
	display: flex;
	flex-wrap: wrap;
	margin-top: 20px;
}
.addRolesListContainer.addDeliveryPlaybookContainer
	ul
	li
	label.NotificationChkboxContainer {
	font-size: 13px;
}
.addRolesListContainer.addDeliveryPlaybookContainer
	ul
	li
	label.NotificationChkboxContainer
	span.CustomCheckmark {
	top: 7px;
}
span.complexityLabel {
	margin-left: 24px !important;
}
.manageToggleFilters .phaseSwimlaneFilters {
	flex-direction: column;
}
.manageAnnouncements .topicSequenceList {
	overflow: hidden;
	overflow-y: auto;
	height: calc(100vh - 7rem);
}
.manageAnnouncements .saveContinueDetailsContainer {
	background: #fff;
}
.phaseSwimlaneFilters .dropDowncontainer.activityReferProjectSelectionDropdown,
.phaseSwimlaneFilters .dropDowncontainer.activityReferSolutionMethodDropdown {
	width: 284px;
}
.phaseSwimlaneFilters .multiSelectdropDown.CloseMultiSelectDp {
	width: 300px;
}
.phaseSwimlaneFilters .dropDownBox {
	padding: 6px 9px !important;
}
.phaseSwimlaneFilters span.wrapHeader {
	width: 90%;
}
.phaseSwimlaneFilters .multiSelectdropDown label.NotificationChkboxContainer {
	overflow: initial;
}
.addRolesListContainer.addDeliveryPlaybookContainer
	.phaseSwimlaneFilters
	ul
	li
	label.NotificationChkboxContainer
	span.CustomCheckmark {
	top: 3px;
}
.phaseSwimlaneFilters .multiSelectdropDown span.complexityLabel {
	padding-left: 0;
}
.phaseSwimlaneFilters .multiSelectdropDown .dropDownList {
	top: 38px;
}
.phaseSwimlaneFilters .multiSelectdropDown .dropDownList ul li {
	margin-bottom: 0;
}
.manageToggleFilters
	.phaseSwimlaneFilters
	.multiSelectdropDown.CloseMultiSelectDp {
	width: 100%;
	margin-left: 0;
}
.manageToggleFilters .dropDowncontainer {
	width: 100% !important;
}
.toggleFiltersListContainer label.NotificationChkboxContainer {
	margin-bottom: 0;
}
/* .toggleFiltersListContainer
	label.NotificationChkboxContainer
	span.CustomCheckmark {
	top: 3px;
} */
.toggleFiltersListContainer ul li:hover {
	background: #cee4fb;
}
.manageToggleFilters
	.addRolesListContainer.addDeliveryPlaybookContainer
	ul
	li
	label.NotificationChkboxContainer
	span.CustomCheckmark {
	top: 3px;
}
.manageToggleFilters .NotificationChkboxContainer .CustomCheckmark:after {
	top: 0px;
}
.gridViewResultsTable .genericTablePreview {
	width: 68px;
	white-space: normal;
}
.gridViewResultsTable .genericTableUpload,
.gridViewResultsTable .genericTableApproveSentBack {
	width: 100px;
}
.modal-dialog.phaseCreationRightPopup.manageRoles.RaciGrid.activityGrid.modal-lg.modal-dialog-centered {
	max-width: 95% !important;
}
.gridViewInfoIcon i:hover ~ .tabsTitleToolTip {
	display: block;
}
.gridViewInfoIcon {
	position: relative;
	margin-left: auto;
}
.gridViewResultsTable .genericTableTitle {
	display: flex;
}
.RaciGridFiltersResults .gridViewResultsTable .genericTablePreview {
	width: 150px;
}
.gridViewResultsTable .genericTableApproveSentBack,
.gridViewResultsTable .genericTableDownload {
	width: 160px;
	margin: 0 5px;
}
.gridViewResultsTable .genericTablePhase {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.gridViewResultsTable .dropDownBox {
	padding: 6px 9px !important;
	margin-top: 0;
}
.gridViewResultsTable
	.genericTablePreview
	label.NotificationChkboxContainer
	span.CustomCheckmark {
	left: 18px;
	border-radius: 4px;
	width: 17px;
	height: 17px;
}
.gridViewResultsTable .genericTableUpload {
	margin: 0 0px 0 10px;
}
.gridViewResultsTable
	.genericTablePreview
	.NotificationChkboxContainer
	.CustomCheckmark:after {
	left: 2px;
	top: 0;
}
.gridViewResultsTable .genericTableTitle > i {
	margin-left: 10px;
	color: #0078d4;
	margin-right: 12px;
}
.gridViewResultsTable .genericTableTitle .tabsTitleToolTip {
	width: 280px;
	left: -47px;
	top: 32px;
}
.getActivityGridDetails button i {
	transform: rotate(135deg);
}
.getActivityGridDetails button {
	padding: 0 31px;
}
.RaciGridFiltersPanel .cloneActivityFiltersWrapper {
	display: flex;
	flex-direction: column;
	background: #f1f5f9;
	box-shadow: none;
	padding-top: 0;
	position: relative;
	min-height: auto;
}
.RaciGridFiltersPanel .cloneActivityFiltersWrapper .cloneActivityFieldsWrapper {
	margin: 0 0 0 14px;
	background: none;
	border: none;
}
.activityGrid .methodOwnerMiddleContainer {
	padding: 30px 0px 20px 0px;
}
.RaciGridFiltersPanel
	.cloneActivityFiltersWrapper
	> div
	.cloneActivityFieldsWrapper {
	background: #f1f5f9;
	border: none;
	margin: 0 0 0 34px;
	float: right;
}
.resultsCountOfGrid {
	white-space: nowrap;
	margin: 12px 0 0 10px;
	color: #706e6e;
}
.RaciGridFiltersPanel
	.cloneActivityFiltersWrapper
	.cloneActivityFieldsWrapper
	input {
	width: 81%;
	border: 1px solid #e8e9ec !important;
}
.cloneActivityFieldsWrapper.showGridFiltersToggle {
	position: absolute;
	right: 15px;
	top: -7px;
}
.getActivityGridDetails {
	margin-left: 20px;
}
.cloneActivityFieldsWrapper.showGridFiltersToggle i.cloneDropDownArrow {
	font-size: 13px !important;
	font-weight: bold;
	color: #0078d4;
	margin: 6px 10px 0 10px !important;
}
.countOfActivitiesInGrid {
	display: flex;
	flex-direction: column;
	margin-right: 10px;
	width: 44px;
}
.countOfActivitiesInGrid span {
	color: #0072c9;
	font-size: 10px;
}
.countOfActivitiesInGrid i {
	color: #0078d4;
}
.cloneActivityFieldsWrapper.raciGridSearchBox input {
	width: 74.5% !important;
}
.getActivityGridDetails button {
	background: #0078d4;
}
.getGridDataButton {
	width: 70px;
	height: 33px;
	background: url(../../assets/GridViewArrow.svg) no-repeat;
	background-size: contain;
	float: left;
	cursor: pointer;
}
.getGridDataExportButton {
	width: 109px;
	height: 33px;
	background: url(../../assets/GridViewexport.svg) no-repeat;
	background-size: contain;
	float: left;
}
.RaciGridFiltersResults .genericTablePreview {
	width: 170px !important;
	margin: 0 5px;
}
.RaciGridFiltersResults
	.gridViewResultsTable
	.genericTablePreview
	label.NotificationChkboxContainer
	span.CustomCheckmark {
	left: 0px;
	border-radius: 0px;
	width: 15px;
	height: 15px;
}
.RaciGridFiltersResults
	.addRolesListContainer.addDeliveryPlaybookContainer
	ul
	li
	label.NotificationChkboxContainer
	span.CustomCheckmark {
	top: 3px;
}
.RaciGridFiltersResults
	.addRolesListContainer.addDeliveryPlaybookContainer
	ul
	li
	label.NotificationChkboxContainer {
	font-size: 13px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.RaciGridFiltersResults
	.addRolesListContainer.addDeliveryPlaybookContainer
	ul
	li {
	overflow: unset;
	margin-bottom: 0;
}
.RaciGridFiltersResults
	.addRolesListContainer.addDeliveryPlaybookContainer
	ul
	li
	label.NotificationChkboxContainer {
	margin-bottom: 0;
}
.addRolesListContainer.addDeliveryPlaybookContainer.AddEditManageSolMethod {
	display: flex;
	padding: 10px 157px;
}
.AddEditManageSolMethod .adminMenuTileConatiner > h6 {
	background: #f1f5f9;
	margin: 0 40px 15px 19px;
	padding: 5px;
	text-align: center;
	border-radius: 21px;
}
.AddEditManageSolMethod .adminMenuTileConatiner .adminMenuTiles {
	padding: 0 20px;
}
.AddEditManageSolMethod .adminMenuTileConatiner .adminMenuTiles .menuTilePart {
	background: #f1f5f9;
	border-radius: 4px;
	max-height: 58px;
}
.AddEditManageSolMethod a.menuTilePart {
	color: #000;
}
.addRolesListContainer.addDeliveryPlaybookContainer.manageACtivities
	.addActivityFields {
	background: #f1f5f9;
}
.addRolesListContainer.addDeliveryPlaybookContainer.manageACtivities
	.addActivityFields {
	background: #f1f5f9;
	margin: 0 auto;
}
.addRolesListContainer.addDeliveryPlaybookContainer.manageACtivities
	.addActivityFields
	.cloneActivityResultsWrapper {
	background: #fff;
}
.addRolesListContainer.addDeliveryPlaybookContainer.manageACtivities
	.addActivityFields
	.cloneActivityFiltersWrapper,
.addRolesListContainer.addDeliveryPlaybookContainer.manageACtivities
	.addActivityFields
	.cloneActivityContainer {
	margin: 12px 29px;
}
.addRolesListContainer.addDeliveryPlaybookContainer.manageACtivities ul li {
	margin-bottom: 0;
}
.addRolesListContainer.addDeliveryPlaybookContainer.manageACtivities
	ul
	li
	label.NotificationChkboxContainer {
	margin-top: 0;
}
.addRolesListContainer.addDeliveryPlaybookContainer.manageACtivities
	ul
	li
	label.NotificationChkboxContainer
	span.CustomCheckmark {
	top: 4px;
	left: 2px;
}
.manageFeatures
	.manageACtivities
	label.NotificationChkboxContainer
	span.complexityLabel {
	margin-left: 2px;
}
.addRolesListContainer.addDeliveryPlaybookContainer.manageACtivities
	.addActivityFields
	.cloneActivityResultsWrapper
	ul {
	display: table;
}
.addRolesListContainer.addDeliveryPlaybookContainer.manageACtivities
	.addActivityFields
	.cloneActivityResultsWrapper
	ul
	li {
	display: inline-flex;
	width: 50%;
}
.toggleFiltersListContainer
	label.NotificationChkboxContainer
	span.CustomCheckmark {
	width: 17px;
	height: 17px;
	border-radius: 4px;
	background: #d7e7ff;
	border: 1px solid #b9d2f9;
}
.toggleFiltersListContainer
	.NotificationChkboxContainer
	input:checked
	~ .CustomCheckmark {
	background-color: #3e88ff;
	border: 1px solid #3e88ff;
}
.gridViewResultsTable .genericTableTitle .tabsTitleToolTip h5 {
	font-size: 16px;
}
.gridViewResultsTable .genericTableTitle .tabsTitleToolTip h6 {
	font-size: 14px;
}
.SwimlanePreviewDiv img {
	width: 100%;
}
.countOfActivitiesInGrid i {
	margin-left: 15px !important;
}
.manageAnnouncements label.NotificationChkboxContainer input[type='checkbox'] {
	width: 0;
}
.gridViewResultsTable
	.genericTablePreview
	label.NotificationChkboxContainer
	input[type='checkbox'] {
	width: 0;
}
.addNewPhaseButtonContainer.exportToJsonDiv .addRoleNameContainer {
	margin-right: 42px;
}
.RaciGridFiltersPanel .NotificationChkboxContainer .CustomCheckmark:after {
	left: 1px;
}
.RaciGridFiltersPanel
	.dropDownList
	.NotificationChkboxContainer
	span.complexityLabel {
	padding-left: 1px;
}
.RaciGridFiltersPanel .wrapHeader {
	width: 200px;
}
.modal-dialog.phaseCreationRightPopup.manageRoles.manageFeatures.manageAnnouncements.manageActivities.modal-lg.modal-dialog-centered {
	max-width: 60%;
}
.manageActivities .NotificationChkboxContainer .CustomCheckmark:after {
	top: -1px;
	left: 1px;
}
.addRolesListContainer.addDeliveryPlaybookContainer.manageACtivities
	ul
	li
	label.NotificationChkboxContainer
	a {
	color: #006ee5;
}
.gridViewResultsTable
	.genericTablePreview
	.dropDownList
	.NotificationChkboxContainer
	.CustomCheckmark:after {
	left: 1px;
	top: -1px;
}
.manageRoles.rolesGroup .addRoleFields {
	width: 818px;
}
.manageRoles.rolesGroup .addRoleFieldsTutorials{
	width: 688px !important;
}
.modal-dialog.phaseCreationRightPopup.manageRoles.rolesGroup.modal-lg.modal-dialog-centered {
	max-width: 70%;
}
.manageRoles.rolesGroup .dropDowncontainer .dropDownBox {
	margin-top: 0;
	padding: 6px 9px !important;
}
.manageRoles.rolesGroup .addRoleFields .addRoleNameContainer:first-child input {
	width: auto;
	flex: 1;
}
.dropDowncontainer.addRolesGroup {
	width: 100%;
}
.manageRoles.rolesGroup .dropDowncontainer label.NotificationChkboxContainer {
	font-size: 13px;
	margin-bottom: 0;
}
.manageRoles.rolesGroup
	.dropDowncontainer
	label.NotificationChkboxContainer
	.CustomCheckmark {
	top: 4px;
}
.manageRoles.rolesGroup
	.dropDowncontainer
	.NotificationChkboxContainer
	.CustomCheckmark:after {
	top: -1px;
}
/* .manageRoles.rolesGroup .addRoleButtonAction {
	width: 100%;
} */
.manageRoles.rolesGroup .addRoleNameContainer {
	flex: 1 1;
}
.manageRoles.rolesGroup .NotificationChkboxContainer input {
	min-height: auto;
}
.manageRoles.rolesGroup .dropDowncontainer .dropDownBox span.wrapHeader {
	width: 80%;
}
.manageRoles.rolesGroup .entryCriteriaList {
	width: 100% !important;
}
.manageRoles.rolesGroup .criteriaHeader {
	padding: 6px 18px;
}
.manageRoles.rolesGroup .entryCriteriaList ul li {
	overflow: unset;
}
.manageRoles.rolesGroup
	.addRolesListContainer.addDeliveryPlaybookContainer
	.criteriaIcon {
	flex: 1;
	width: auto;
}
.manageRoles.rolesGroup
	.addRolesListContainer.addDeliveryPlaybookContainer
	.criteriaTable {
	width: 100%;
	overflow: unset;
}
.manageRoles.rolesGroup .criteriaSaveUpdate button {
	margin-top: 0px;
}
.manageRoles.rolesGroup .criteriaSaveUpdate {
	width: 100px;
	text-align: center;
}
.manageRoles.rolesGroup .dropDownList ul {
	max-height: 300px;
}
.manageRoles.rolesGroup .middleDetailsContainer {
	max-height: calc(100vh - 8.8rem);
	padding: 4px 25px 15px 25px;
}
.dropdownmenu.right li a.dropdownLink {
	overflow: hidden;
	text-overflow: ellipsis;
}
.modal-dialog.phaseCreationRightPopup.manageRoles.manageProceduresArtifacts.modal-lg.modal-dialog-centered {
	max-width: 95%;
}
.manageProceduresContainer {
	display: flex;
}
.manageProceduresContainer .toggleFiltersListContainer ul.phaseSwimlaneHistory {
	margin: 0;
	list-style: none;
	font-size: 13px;
}
.manageProceduresContainer
	.toggleFiltersListContainer
	ul.phaseSwimlaneHistory
	label.NotificationChkboxContainer {
	font-size: 13px;
}
.manageProceduresContainer
	.toggleFiltersListContainer
	ul.phaseSwimlaneHistory
	li {
	margin-bottom: 10px;
}
.manageProceduresFiltersContainer .multiSelectdropDown.CloseMultiSelectDp {
	width: 100%;
	margin-left: 0;
	margin-bottom: 10px;
}
.manageProceduresFiltersContainer .cloneActivityButtonAction {
	position: relative;
	background: #f1f5f9;
	width: 100%;
}
.manageProceduresFiltersContainer .cloneActivityFiltersWrapper {
	display: flex;
	flex-direction: column;
	background: none;
	padding-top: 0;
	box-shadow: none;
}
.manageProceduresEntityLinkContainer
	.toggleFiltersListContainer
	label.NotificationChkboxContainer
	span.CustomCheckmark {
	top: 2px;
}
.manageProceduresArtifacts .methodOwnerMiddleContainer {
	padding: 20px 0;
}
.manageProceduresArtifacts .cloneActivityFieldsWrapper.showGridFiltersToggle {
	background: none;
	border: none;
	top: 0;
}
.manageProceduresFiltersContainer .cloneActivityFiltersWrapper input {
	width: 100% !important;
}
.manageProceduresFiltersContainer
	.multiSelectdropDown.CloseMultiSelectDp
	span.wrapHeader {
	width: 90%;
}
.manageProceduresFiltersContainer .cloneActivityResultsWrapper {
	font-size: 13px;
}
.manageProceduresFiltersContainer .cloneActivityResultsWrapper ul li {
	width: 46%;
	display: inline-flex;
}
.manageProceduresFiltersContainer .cloneActivityResultsWrapper ul {
	display: table;
}
.manageProceduresEntityLinkedContainer {
	display: flex;
}
.manageProceduresEntityLinkContainer {
	display: flex;
	padding: 0 0 0 20px;
	width: 50%;
}
.manageProceduresEntityLinkContainer .toggleFiltersListContainer {
	width: 100%;
}
.manageProceduresFiltersContainer {
	width: 50%;
}
.manageProceduresEntityLinkedContainer > div {
	width: 50%;
}
.manageProceduresEntityLinkedContainer > div input {
	width: 292px;
	background: #fff;
	border-radius: 4px;
	margin-left: 10px;
}
.manageProceduresEntityLinkedContainer > div .dropDownBox {
	padding: 6px 9px !important;
	margin-top: 0;
}
.cloneActivityResultsWrapper.selectProcedure {
	display: flex;
	flex-direction: row;
}
.cloneActivityResultsWrapper.selectProcedure .selectedProcedure {
	flex: 1;
}
.cloneActivityResultsWrapper.selectProcedure h6 {
	color: #808993;
	font-size: 13px;
}
.cloneActivityResultsWrapper.selectProcedure .selectedProcedure > span {
	color: #0078d4;
	font-weight: 600;
}
.manageProceduresFiltersContainer
	.cloneActivityResultsWrapper.resultsProcedures {
	margin-top: 0;
	max-height: 200px;
	overflow-y: auto;
	padding-bottom: 120px;
}
.manageProceduresFiltersContainer
	.cloneActivityResultsWrapper
	ul
	li
	.cloneInfiActivity
	.tabsTitleToolTip
	span.detailsCloneActivitySol {
	display: flex;
}
.manageProceduresFiltersContainer
	.cloneActivityResultsWrapper.resultsProcedures
	ul
	li:hover {
	background: #b3d6f1;
	border-radius: 4px;
}
.manageProceduresFiltersContainer
	.cloneActivityResultsWrapper
	li
	span.cloneActivityTitle:hover {
	font-weight: normal;
}
.manageProceduresEntityLinkedContainer .dropDowncontainer li,
.manageProceduresEntityLinkedContainer .dropDowncontainer li:hover {
	background: none;
}
.manageProceduresEntityLinkContainer ul.phaseSwimlaneHistory {
	max-height: 400px;
	overflow: hidden;
	overflow-y: auto;
	margin-top: 10px !important;
}
.manageProceduresContainer
	.toggleFiltersListContainer
	ul.phaseSwimlaneHistory
	li {
	width: 250px;
}
.manageProceduresFiltersContainer .addActivityFields {
	width: 580px;
}
.templatesFilterPart .raciFiltersPart > span:first-child {
	margin-left: auto;
}
.manageProceduresContainer
	.toggleFiltersListContainer
	ul.phaseSwimlaneHistory
	li
	span.complexityLabel {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 200px;
	display: block;
}
.manageProceduresFiltersContainer
	.cloneActivityResultsWrapper.resultsProcedures
	li.selectedProcedure {
	background: #b3d6f1;
	border-radius: 4px;
}
.manageProceduresFiltersContainer
	.cloneActivityFieldsWrapper.showGridFiltersToggle
	i[data-icon-name='Search'] {
	transform: rotate(-88deg);
	margin-right: 4px;
	font-size: 14px;
	font-weight: bold;
}
.manageProceduresFiltersContainer
	.cloneActivityResultsWrapper
	ul
	li
	.cloneInfiActivity
	.tabsTitleToolTip
	h5 {
	font-size: 15px;
}
.manageProceduresFiltersContainer
	.cloneActivityResultsWrapper
	ul
	li
	.cloneInfiActivity
	.tabsTitleToolTip {
	padding: 10px;
}
.manageProceduresFiltersContainer
	.cloneActivityResultsWrapper
	ul
	li
	.cloneInfiActivity
	.tabsTitleToolTip
	h6 {
	font-size: 14px;
}
.filtersCategoryPanel ul li .tabsTitleToolTip span {
	display: flex;
}
.modal-dialog.phaseCreationRightPopup.manageRoles.rolesGroup.modal-lg.modal-dialog-centered
	.criteriaSaveUpdate
	button {
	margin-top: 41px;
}
.RaciGrid .genericTableContainer {
	padding: 6px 18px !important;
}
.RaciGrid.activityGrid .genericTablePreview label.NotificationChkboxContainer {
	margin-top: -14px;
}
.editRoleGroupModal .criteriaSaveUpdate button {
	margin-top: 0 !important;
}
.addRoleNameContainer
	label.NotificationChkboxContainer
	input[type='checkbox']:focus {
	width: 15px;
	margin: 4px 0 0 18px !important;
}
.ManageTutorials
	.addRoleNameContainer
	label.NotificationChkboxContainer
	input[type='checkbox']:focus {
	width: 15px !important;
	margin: 8px 0 0 13px !important;
}
.criteriaIsActive
	label.NotificationChkboxContainer
	input[type='checkbox']:focus {
	margin: 2px 0 0 27px !important;
}
.criteriaIsFlyout
	label.NotificationChkboxContainer
	input[type='checkbox']:focus {
	margin: 8px 0 0 18px !important;
}
.manageFeatures
	.criteriaIsActive
	label.NotificationChkboxContainer
	input[type='checkbox']:focus {
	margin: 8px 0 0 19px !important;
}
.RaciGrid.activityGrid
	.gridViewResultsTable
	.genericTablePreview
	label.NotificationChkboxContainer
	input[type='checkbox']:focus {
	margin: 9px 0 0 18px !important;
	width: 17px !important;
	outline-offset: 2px;
}
.RaciGrid.activityGrid
	.multiSelectdropDown
	.dropDownList
	label.NotificationChkboxContainer
	input[type='checkbox'] {
	width: auto !important;
	min-height: auto !important;
}
.historicalPreview .ractMatrix .activityChildContainer.orphanRole {
	border-left: 0;
}
.manageActivities
	.addRolesListContainer.addDeliveryPlaybookContainer.manageACtivities
	.addActivityFields
	.cloneActivityResultsWrapper
	ul
	li {
	padding: 3px 5px;
}
.manageActivities
	.addRolesListContainer.addDeliveryPlaybookContainer.manageACtivities
	.addActivityFields
	.cloneActivityResultsWrapper {
	padding-bottom: 100px;
}
.manageActivities
	.middleDetailsContainer
	.cloneActivityResultsWrapper
	li
	.tabsTitleToolTip {
	width: 232px;
}
.manageDeliveryPlayBookModal .roleAdditionContainer .addRoleButtonAction {
	padding: 0;
}
.manageDeliveryPlayBookModal
	.roleAdditionContainer
	.addRoleButtonAction
	.selectAddRoleButton {
	padding: 10px;
}
.manageDeliveryPlayBookModal
	.roleAdditionContainer
	.addRoleButtonAction
	.selectAddRoleButton:focus {
	outline-offset: 1px;
}
.manageDeliveryPlayBookModal
	.roleAdditionContainer
	.addRoleButtonAction
	.addRoleFields {
	padding: 8px 14px 8px 20px;
}
.manageDeliveryPlayBookModal
	.roleAdditionContainer
	.addRoleButtonAction
	.selectAddRoleButton
	span {
	outline-offset: 12px;
}
.activityAdditionDiv
	.cloneActivityButtonAction
	.addActivityFields
	.dropDownList
	label.NotificationChkboxContainer
	input[type='checkbox']:focus {
	margin: 2px 0 0 2px !important;
}
.activityAdditionDiv
	.cloneActivityButtonAction
	.addActivityFields
	.dropDownList
	label.NotificationChkboxContainer
	span.CustomCheckmark {
	left: 2px;
}
.activityAdditionDiv .addActivityButtonAction .selectAddActivityButton:focus {
	outline-offset: 1px;
}
.modal-dialog.manageQuickLinkPanel{
    max-width: 65% !important;
}

/* Tutorial Sequence Category Component CSS */
.tutorialCategoryContainer{
	padding: 20px 30px;
    max-height: 78vh;
    overflow-y: auto;
}
.sequentialTutorialCategoryList{
    margin-top: 20px;
}
.sequentialdraggbleTutorialCategoryList{
	display: flex;
    flex-direction: column;
    margin: 0;
    list-style: none;
    padding: 0;
    color: #4d4f5c;
}
.tutorialCategorySequenceContainer{
	background: #F6F6FB 0% 0% no-repeat padding-box;
	margin-bottom: 15px;
}
.sequentialTutorialCategoryItem{
	height: 37px;
	padding: 6px 15px;
    border: 1px solid #f0f2f8;
    border-radius: 4px;
    font-size: 14px;
	position: relative;
	background: #F6F6FB 0% 0% no-repeat padding-box;
	border-radius: 2px;
	opacity: 1;
}
.sequentialTutorialCategoryItem .tutorialCategoryTitleSpan{
	padding-left: 15px;
	font: normal normal normal 13px/20px Segoe UI;
	letter-spacing: 0px;
	color: #000000;
	opacity: 1;
}
.sequentialTutorialVideoContainer{
	margin: 10px 15px 0px;
	background: #F6F6FB 0% 0% no-repeat padding-box;
	border-radius: 2px;
	opacity: 1;
}
.expandTutorialCategory{
	float: right;
    opacity: 1;
    font-size: 12px;
    padding-top: 4px;
}
.sequentialTutorialVideoItem{
	height: 32px;
	padding: 4px 15px;
    border: 1px solid #f0f2f8;
    border-radius: 4px;
    font-size: 14px;
	position: relative;
	background: #F6F6FB 0% 0% no-repeat padding-box;
	border: 1px solid #D4D4D4;
	border-radius: 2px;
	opacity: 1;
}
.sequentialTutorialVideoItem .tutorialVideoTitleSpan{
	padding-left: 15px;
	font: normal normal normal 13px/20px Segoe UI;
	letter-spacing: 0px;
	color: #000000;
	opacity: 1;
}
.sequentialTutorialVideoContainer:last-child{
	margin-bottom: 10px;
}
.saveTutorialsSequenceContainer{
	display: flex;
    position: relative;
    bottom: 0px;
    width: 100%;
    right: 0;
    padding: 10px 10px;
    box-shadow: 1px -4px 5px -3px rgba(0, 0, 0, 0.12);
    min-height: 50px;
	background: #fff;
}
.saveTutorialSequenceBtn{
	margin-left: auto;
	margin-right: auto;
	border: none;
    background: none;
    color: #0078d4;
    float: right;
}
.saveTutorialSequenceBtnSpan{
	text-align: left;
	font: normal normal 600 14px/38px Segoe UI;
	letter-spacing: 0px;
	/* color: #3191E7; */
	opacity: 1;
}