h.activityAdditionDiv,
.activityGroupAdditionDiv {
	display: flex;
	flex-direction: column;
	font-size: 13px;
}
.activityAdditionCont {
	display: flex;
}
.cloneActivityButtonAction {
	display: inline-flex;
	border: 1px solid #e3e3e3;
	background: #f3f3f3;
	padding: 10px;
	flex-direction: column;
	margin-bottom: 24px;
	border-radius: 4px;
}
.addActivityButtonAction {
	display: inline-flex;
	border: 1px solid #4d87f6;
	background: #f1f5f9;
	/* padding: 8px 54px 8px 20px; */
	flex-direction: column;
	margin-bottom: 24px;
	border-radius: 4px;
}
.addActivityButtonAction .selectAddActivityButton {
	padding: 8px 54px 8px 20px;
}
.selectAddActivityButton {
	color: #0262ab;
	cursor: pointer;
	font-size: 14px;
}
.activityAdditionCont .dropDowncontainer.coOwner,
.activityAdditionCont .dropDowncontainer.reviewer,
.activityAdditionCont .dropDowncontainer.approver,
.activityAdditionCont .collabaratorsChildContainers {
	width: 100%;
}
.activityAdditionCont .dropDownList.coOwner {
	top: 2px;
}
.addActivityFields {
	display: flex;
	flex-direction: column;
	width: 600px;
	margin-top: 10px;
	font-size: 13px;
}
.addActivityFields input {
	width: 100%;
	max-height: 40px;
	border: 1px solid #e8e9ec;
	font-size: 12px;
}
.addActivityFields .entryCriteriadropdown {
	width: 100%;
	max-height: 40px;
}
.otherDetailsAddActivity span.complexityLabel {
	font-size: 13px;
	margin-left: 24px;
}
.otherDetailsAddActivity .CustomCheckmark {
	border: 1px solid #007bff;
	border-radius: 3px;
	top: 14px;
}

.otherDetailsAddActivity {
	margin-bottom: 20px;
}
.otherDetailsAddActivity
	.NotificationChkboxContainer
	input[type='checkbox']:focus {
	outline: 1px dotted black !important;
	height: 15.5px !important;
	width: 15.5px !important;
}
.otherDetailsAddActivity .NotificationChkboxContainer .CustomCheckmark:after {
	top: 0;
	font-size: 10px;
}
.checkboxForActivity{
	top: 0px !important;

	margin-top: 10px !important;
}
.otherDetailsAddActivity .CheckboxForActivityCreate{
padding: 8px 0px 2px 0px !important;
}
span.AddActivityFieldLabel {
	font-weight: 600;
	margin-bottom: 4px;
	font-size: 14px;
}
.activityAdditionDiv h6 {
	font-size: 14px;
	margin-bottom: 20px;
}
.newlyAddedActivityListHeader {
	display: flex;
	padding: 6px;
	color: #333;
	border-radius: 4px;
	font-size: 13px;
	font-weight: 600;
	white-space: nowrap;
	border-bottom: 1px solid #e8e9ec;
	border-top: 1px solid #e8e9ec;
}
.newActivityGroup ul {
	display: flex;
	list-style: none;
	padding: 0;
	flex-direction: column;
}
.newActivityGroup {
	display: flex;
	flex-direction: column;
	margin: 10px 0;
	width: 676px;
}
.newActivityGroup ul li {
	display: flex;
	overflow: hidden;
	border: 1px dashed #ffa12d;
	margin-top: 10px;
}
.newActivityGroup ul li .newActivityGroupTable {
	display: flex;
	padding: 4px;
	overflow: hidden;
}
.newActivityIcon {
	width: 40px;
	text-align: center;
}
.newActivityName {
	flex: 1;
}

.activityGroupAdditionDiv .activityChildContainer .activityChildTitle {
	width: 100%;
	position: relative;
}
.activityGroupAdditionDiv [data-rbd-drag-handle-context-id='1'] {
	width: 100%;
}
.activityGroupAdditionDiv .activityChildContainer span.activityAddtoGroup {
	margin-right: 10px;
	display: flex;
}
.activityGroupAdditionDiv
	.activityChildContainer
	span.activityAddtoGroup
	.dropDownBox
	i {
	margin-top: 0;
}
.activityGroupAdditionDiv
	.activityChildContainer
	span.activityAddtoGroup
	.dropDowncontainer {
	margin-right: 0;
}
.activityGroupAdditionDiv
	.activityChildContainer
	span.activityAddtoGroup
	.dropDownBox {
	margin-top: 0;
	padding: 0px 9px !important;
}
.orphanActivity {
	margin-top: 10px;
}
.cloneActivityButtonAction .selectAddActivityButton {
	color: #333;
	padding: 10px;
}
.cloneActivityFieldsWrapper {
	display: flex;
	background: #fff;
	border: 1px solid #e8e9ec;
	margin-top: 4px;
}
.cloneActivityFieldsWrapper input {
	border: none !important;
	margin-top: 0;
}
.cloneActivityFieldsWrapper i {
	font-size: 16px;
}
i.cloneDropDownArrow {
	font-size: 8px !important;
	margin: 10px 10px 0 10px !important;
}
.cloneActivityFieldsWrapper i {
	font-size: 16px;
	margin-top: 4px;
}
.entryCriteriaName {
	margin-top: 15px;
	display: flex;
	flex-direction: column;
	flex: 1 1;
}
.cloneActivityFiltersWrapper {
	box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.12);
	min-height: 130px;
	background: #fff;
	padding-top: 20px;
	margin-top: 2px;
}
.multiSelectdropDown {
	position: relative;
	width: 200px;
	float: left;
	margin-left: 30px;
}
.dropDowncontainer.projectSelectionDropdown,
.dropDowncontainer.solutionMethodDropdown,
.dropDowncontainer.solutionMethodphasesDropdown,
.dropDowncontainer.complexityDrodown {
	width: 100% !important;
	margin-bottom: 15px;
}
.cloneActivityFiltersWrapper .dropDownBox {
	padding: 5px 9px !important;
}
.cloneActivityFiltersWrapper .dropDownList,
.dropDowncontainer.activitySelectionDropdown .dropDownList {
	top: 32px;
	padding: 3px 0;
}
.dropDowncontainer.activitySelectionDropdown .dropDownBox {
	padding: 5px 9px !important;
}
.multiSelectDropDownBox {
	display: flex;
	min-height: 25px;
	overflow: hidden;
	width: 100%;
	cursor: pointer;
	position: relative;
	padding: 5px 9px !important;
	border: 1px solid #ccc;
	box-shadow: none;
	text-decoration: none;
	background: #fff;
	border-radius: 3px;
	margin-top: 4px;
	font-size: 12px;
}
.multiSelectdropDownList ul {
	overflow-y: auto;
	overflow-x: hidden;
	width: 100%;
	margin: 0 !important;
	padding: 0 !important;
	max-height: 400px;
}
.multiSelectdropDownList {
	list-style: none;
	display: none;
	padding: 12px 0 5px;
	top: 34px;
	z-index: 99999;
	box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.247058823529412);
	position: absolute;
	background: #fff;
	border: 1px solid #ccc;
	border-top: 0;
	width: 100%;
	border-radius: 3px;
}
.multiSelectdropDownList ul li {
	display: block;
	margin: 0;
	list-style: none;
	font-family: SegoeUI, Segoe UI;
	font-weight: 400;
	font-style: normal;
	font-size: 12px;
	margin-bottom: 0;
	vertical-align: middle;
	padding-left: 30px;
	padding-top: 0;
	cursor: pointer;
	width: 100%;
	position: relative;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	padding-bottom: 0;
	color: #333;
	float: left;
	padding: 3px 12px 6px !important;
}
.multiSelectdropDown label.NotificationChkboxContainer {
	margin-bottom: 0;
	font-size: unset;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.multiSelectdropDown .CustomCheckmark {
	top: 3px;
	left: 0;
	height: 15px;
	width: 15px;
}
.multiSelectdropDown span.complexityLabel {
	font-size: 13px;
	padding-left: 23px;
}
.multiSelectdropDown .NotificationChkboxContainer .CustomCheckmark:after {
	position: absolute;
	left: 0px;
	opacity: 1;
	top: -1px;
	color: #fff;
	font-size: 10px;
	font-weight: normal;
	content: '\E73E';
	font-family: FabricMDL2Icons;
}
.multiSelectDropDownBox span.wrapHeader {
	width: 100%;
}
.cloneActivityResultsWrapper {
	display: flex;
	flex-direction: column;
	margin-top: 20px;
	position: relative;
	min-height: 50px;
}
.cloneActivityResultsWrapper h6 {
	font-weight: normal;
	font-size: 14px;
}
.cloneActivityResultsWrapper ul {
	margin: 0;
	padding: 0;
}
.cloneActivityResultsWrapper li {
	cursor: pointer;
	list-style: none;
	padding: 10px 5px;
	display: flex;
}
.cloneActivityResultsWrapper li span.cloneActivityTitle:hover,
.cloneActivityResultsWrapper li span.cloneActivityTitle:focus {
	font-weight: 800;
}
.resltedActCloseListwrapper {
	display: flex;
}
.resltedActCloseListwrapper > div {
	display: flex;
}
.resltedActCloseListwrapper {
	display: flex;
	flex-direction: column;
}
span.cloneCheckBoxLabel {
	margin-left: 25px;
	font-size: 13px;
}
.resltedActCloseList {
	width: 160px !important;
}
.resltedActCloseList ul {
	list-style: none;
	padding: 0;
}
.addActivityFields .dropDowncontainer.addToGroup {
	width: 202px;
}
.addActivityFields .dropDownBox {
	padding: 5px 9px !important;
	margin-top: 0;
}
.addActivityFields .dropDownList {
	top: 37px;
}
.cloneActivityFieldsWrapper i {
	cursor: pointer;
}
.resltedActCloseListwrapper
	.NotificationChkboxContainer
	.CustomCheckmark:after {
	top: -1px;
}
span.cloneActivityBackToresults {
	color: #0078d4;
	text-decoration: underline;
	cursor: pointer;
}
span.cloneActivityBackToresults i {
	font-size: 12px;
	margin-right: 5px;
	vertical-align: middle;
}
.resltedActCloseList
	label.NotificationChkboxContainer
	input[type='checkbox']:focus {
	margin: 14px 0 0 3px !important;
}
.cloneActivityDatasWrapper p {
	margin-top: 10px;
}
.AddActivityCloneButtonSection {
	text-align: center;
	margin-bottom: 20px;
}
.AddActivityCloneButton {
	border: none;
	background: #0078d4;
	padding: 2px 10px;
	border-radius: 4px;
	color: #fff;
	font-size: 13px;
}
span.closeShowMoreLess {
	color: #0262ab;
	cursor: pointer;
	margin: 10px 3px;
}
.cloneActivityResultsWrapper li i {
	margin-left: 4px;
	vertical-align: middle;
}
.hideCloneFilter {
	display: none;
}
span.activityAddtoGroup span.phaseAddIcon {
	float: right;
	margin-left: 6px;
	width: 22px;
	height: 22px;
	margin-top: 6px;
}
span.activityAddtoGroup {
	line-height: 31px;
	margin-left: 20px;
}
.PreviewAndSubmitPopUp .discardAndPreviewButton {
	cursor: pointer;
}
.draggbleActivityItem {
	width: 100%;
}
.middleDetailsContainer
	.cloneActivityResultsWrapper
	li
	i:hover
	~ .tabsTitleToolTip,
.middleDetailsContainer
	.cloneActivityResultsWrapper
	li
	i:focus
	~ .tabsTitleToolTip {
	display: block;
	font-weight: normal;
}
.middleDetailsContainer .cloneActivityResultsWrapper li .tabsTitleToolTip {
	left: -182px;
	top: 30px;
	width: 250px;
	padding: 12px 20px;
}
.middleDetailsContainer
	.cloneActivityResultsWrapper
	li
	.tabsTitleToolTip
	span.tabsToolTipArrow {
	right: 43px;
	left: auto;
}
.cloneInfiActivity {
	position: relative;
}
span.detailsOfCloneActivity {
	display: flex;
	flex-direction: column;
}
span.detailsCloneActivitySol span:first-child {
	width: 100px;
	display: inline-flex;
}
.dropDowncontainer.complexityDrodown span.wrapHeader {
	width: auto;
}
.middleDetailsContainer .cloneActivityResultsWrapper li .tabsTitleToolTip h5 {
	font-size: 16px;
}
span.cloneActivityTitle {
	width: 251px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.selectedGroupActivity {
	background-color: #eeeeee;
}

/* border: solid 1px slategrey; */
.hashtagsContainer {
	display: flex;
	padding: 10px;
}
.hashtagsContainer span {
	border: 1px solid #0078d4;
	padding: 4px 10px;
	margin-right: 10px;
	border-radius: 28px;
}
#hashtagMessage {
	margin-top: 0;
	margin-bottom: 0.1rem;
}
.referActivityContainer
	.dropDowncontainer.activityReferProjectSelectionDropdown,
.dropDowncontainer.activityReferSolutionMethodDropdown,
.dropDowncontainer.activityReferSolutionMethodphasesDropdown,
.dropDowncontainer.activityReferComplexityDrodown {
	width: 100%;
}
.referActivityContainer .cloneActivityFiltersWrapper {
	padding-bottom: 20px;
}
.referActivityContainer label.NotificationChkboxContainer {
	font-size: inherit;
}
.referActivityContainer label.NotificationChkboxContainer .CustomCheckmark {
	top: 3px;
	left: 0;
	border: 1px solid #0078d4;
}
.referActivityContainer
	label.NotificationChkboxContainer
	span.complexityLabel.cloneActivityTitle {
	margin-left: 24px;
	color: #0078d4;
}
.referActivityContainer
	.cloneActivityResultsWrapper
	li
	label.NotificationChkboxContainer
	span.complexityLabel.cloneActivityTitle:hover {
	font-weight: 800;
}
.compactPickerDiv > div {
	width: 50px;
	height: 30px;
	margin-left: 5px;
	padding: 7px;
	background: #fff;
	display: flex;
	flex-direction: column;
}
.compactPickerDiv {
	display: flex;
}
.compactPickerDiv > div > div {
	right: 247px;
	display: flex;
	padding-top: 4px;
}
.compact-picker.compactPicker {
	left: 0;
	background: #fff;
}
.compactPickerDiv i {
	cursor: pointer;
	font-size: 10px;
	font-weight: bold;
	margin-left: 2px;
	margin-top: 3px;
}
span.selectedColorForGroup {
	width: 12px;
	height: 12px;
	margin: 3px 4px;
	border-radius: 4px;
}
.activityGroupAdditionDiv .activityChildContainer {
	flex-direction: row;
}
.removeFromSubgroupIcon {
	float: left;
	width: 29px;
	height: 29px;
	background: url(../../../assets/removeFromIcon.svg) no-repeat;
	background-size: contain;
	cursor: pointer;
	margin-top: 3px;
}
.activityGroupAdditionDiv .subGroupContainer .activityChildContainer > div {
	width: 100%;
}
.referActivityGroupMainContainer {
	display: flex;
	flex-direction: column;
	background: #e9eef6;
	padding: 10px 10px;
	font-size: 13px;
}
.referActivityGroupContainer {
	display: flex;
}
.cloneActivityButtonAction.referSolMethod .cloneActivityFiltersWrapper {
	background: #f3f3f3;
	box-shadow: none;
	min-height: 100px;
}
.cloneActivityButtonAction.referSolMethod .cloneActivityContainer {
	display: none;
}
.cloneActivityButtonAction.referSolMethod .multiSelectdropDown {
	width: 250px;
}
.referActivityGroupContainer i {
	cursor: pointer;
	color: #ffa12d;
	margin: 2px 5px 0px 0px;
}
.referActivityChildContainer {
	padding-left: 30px;
}
.referActivityGroupMainContainer
	label.NotificationChkboxContainer
	span.CustomCheckmark {
	top: 4px;
	left: 0;
}
.compactPickerDiv > div span.AddActivityFieldLabel {
	margin-top: -29px;
	margin-left: -8px;
}
.subgroupFields .entryCriteriaName.subgroupActivitiesDp {
	border-bottom: 1px solid #e4e4e4;
	padding-bottom: 15px;
}
.activityListMainContainer span.activityAddtoGroup span.phaseAddIcon {
	margin-right: 10px;
}
.phaseCreationRightPopup .modal-header .modal-title.h4 {
	font-weight: bold;
	font-size: 23px;
}
.activityGroupAdditionDiv .activityContainer span.phaseAddIcon {
	margin: 6.5px 2px;
}
.activityGroupAdditionDiv .activityContainer span.ActivityEditIcon {
	margin: 7px 5px 6px 2px;
}
li.coOwner span.phaseReferred {
	margin-right: 0;
}
.referActivityChildContainer li {
	display: flex;
	flex-direction: column;
}
span.phaseAddIcon.disabled {
	opacity: 0.5;
	background-color: #fff !important;
}
.activityAdditionCont.referActivityContainer .cloneActivityFiltersWrapper {
	display: flex;
	flex-direction: column;
	padding-bottom: 0;
}
.activityAdditionCont.referActivityContainer
	.cloneActivityFiltersWrapper
	.AddActivityCloneButtonSection {
	text-align: end;
	padding: 20px;
	background: #f3f3f3;
	margin-bottom: 0;
	margin-top: 20px;
}
.activityAdditionCont.referActivityContainer label.NotificationChkboxContainer {
	font-size: inherit;
	width: 89%;
	overflow: hidden;
	text-overflow: ellipsis;
	margin-bottom: 0;
}
.activityAdditionCont.referActivityContainer
	label.NotificationChkboxContainer
	input[type='checkbox']:focus {
	outline: 1px dotted black !important;
	height: 15.5px;
	width: 15.5px;
	margin: 3.5px 0px 0px 2px !important;
}
.activityAdditionCont.referActivityContainer
	.NotificationChkboxContainer
	.CustomCheckmark:after {
	top: -1px;
	left: 0;
}
.activityAdditionCont.referActivityContainer .cloneActivityResultsWrapper li {
	cursor: pointer;
	list-style: none;
	padding: 10px 5px;
	display: inline-flex;
	width: 42%;
}
.activityAdditionCont.referActivityContainer .cloneActivityResultsWrapper ul {
	margin: 0;
	padding: 0;
	display: table;
}
.activityAdditionCont.referActivityContainer .cloneActivityResultsWrapper {
	display: flex;
	flex-direction: column;
	margin-top: 20px;
	position: relative;
	min-height: 50px;
	height: 350px;
	overflow: hidden;
	overflow-y: auto;
}
.activityAdditionCont.referActivityContainer.referSoloMethod
	.cloneActivityResultsWrapper {
	height: auto;
}
.activityAdditionCont.referActivityContainer.linkExtGroups
	.cloneActivityResultsWrapper
	li {
	width: auto;
	display: flex;
}
.activityAdditionCont.referActivityContainer.linkExtGroups
	.cloneActivityResultsWrapper
	ul {
	display: unset;
}
.AddActivityCloneButtonSection.actvityAddResultsButton {
	display: flex;
}
.AddActivityCloneButtonSection.actvityAddResultsButton .AddActivityCloneButton {
	margin-left: auto;
}
.activityGroupAdditionDiv.refergroupContainer
	.activityAdditionCont.referActivityContainer
	.cloneActivityResultsWrapper
	li {
	display: flex;
	width: auto;
}
.addNewTemplate.modal span.complexityLabel {
	padding-left: 0;
}
.addNewTemplate.modal
	.dropDownList
	.NotificationChkboxContainer
	.CustomCheckmark:after {
	top: -1px;
	left: 1.5px;
}
.phaseCreationRightPopup.addNewTemplate
	.activityAdditionCont.referActivityContainer
	.dropDownList
	label.NotificationChkboxContainer {
	width: 100%;
}
.phaseCreationRightPopup.addNewTemplate
	.activityAdditionCont.referActivityContainer
	.cloneActivityResultsWrapper
	.CustomCheckmark:after {
	left: 1px;
}
.manageActivities .tabsTitleToolTip span.tabsToolTipArrow {
	left: 91px !important;
	right: auto !important;
}
.manageActivities .tabsTitleToolTip {
	left: -100px !important;
}
.manageActivities .tabsTitleToolTip span.detailsOfCloneActivity {
	font-size: 12px;
}
.activityBuilderModal
	.activityAdditionCont.referActivityContainer
	.cloneActivityResultsWrapper
	label.NotificationChkboxContainer
	input[type='checkbox']:focus {
	margin: 2px 0 0 2px !important;
}
.activityBuilderModal
	.activityAdditionCont.referActivityContainer
	.cloneActivityResultsWrapper
	label.NotificationChkboxContainer
	.CustomCheckmark {
	left: 2px;
}
.activityBuilderModal .activityAdditionDiv .cloneActivityButtonAction,
.activityBuilderModal .activityGroupAdditionDiv .cloneActivityButtonAction {
	padding: 0;
}
.activityBuilderModal .activityAdditionDiv .selectAddActivityButton:focus,
.activityBuilderModal .activityGroupAdditionDiv .selectAddActivityButton:focus {
	outline-offset: 0;
}
.activityBuilderModal
	.activityAdditionDiv
	.cloneActivityButtonAction
	.addActivityFields,
.activityBuilderModal
	.activityAdditionDiv
	.addActivityButtonAction
	.addActivityFields,
.activityBuilderModal
	.activityGroupAdditionDiv
	.cloneActivityButtonAction
	.addActivityFields,
.activityBuilderModal
	.activityGroupAdditionDiv
	.addActivityButtonAction
	.addActivityFields {
	padding: 10px 20px;
}
.activityBuilderModal
	.addActivityFields
	.NotificationChkboxContainer
	input[type='checkbox']:focus {
	margin: 12px 0 0 2px !important;
}
.phaseCreationRightPopup label.NotificationChkboxContainer input[type="checkbox"] {
    width: auto !important;
}
.phaseCreationRightPopup label.NotificationChkboxContainer input[type=checkbox]:focus {
    margin: 3px 0 0 2px!important;
}
.phaseCreationRightPopup .dropDownList ul li {
    padding: 3px 12px 3px!important;
}
.phaseCreationRightPopup label.NotificationChkboxContainer {
    padding: 2px 0px 2px 0px;
}