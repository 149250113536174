.addPolygonTopicFields {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.polygonPreviewDesc {
    width: 100% !important;
    max-width: 100%;
    overflow-wrap: break-word;
    word-wrap: break-word;
    overflow-x: scroll;
}

.topicPreview {
    height: 69vh !important;
    min-height: 0px !important;
}

.topicPreview .topicDescriptionDiv {
    height: 69vh !important;
    min-height: 0px !important;
    width: 100%;
}

.addPolygonTopicRightFields {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.addPolygonTopicRightFields input {
    width: 100%;
    max-height: 40px;
    border: 1px solid #e8e9ec;
    font-size: 12px;
    padding: 5px;
font: normal normal normal 12px/16px Segoe UI;
letter-spacing: -0.12px;
color: #222629;
opacity: 1;
}

.addPolygonTopicFieldsDiv {
    width: 100%;
    padding: 10px 14px 0px 14px;
}

.addPolygonTopicRightFieldsDiv {
    width: 100%;
    padding: 10px 15px 0px 15px;
}
.addPolygonTopicRightFieldsDiv ul{
    margin-bottom: 0px;
}

.addTopicFieldLabel {
    font-weight: 600;
    margin-bottom: 4px;
    font-size: 14px;
    width: 15%;
    text-align: left;
}


.addPolygonTopicRightLabel {
    font-weight: 600;
    margin-bottom: 4px;
    font-size: 14px;
    width: 20%;
}
.polygonCollaboratorsApprovers{
    padding: 5px;
    margin: 0px;
}
.polygonCollaboratorsApprovers li{
    border: 1px solid #f2f2f2;
    border-radius: 24px;
    width: fit-content;
    padding: 0px 10px;
    list-style: none;
}
.polygonUserDropdown{
    top: 2px;
    width: 103%;
}
.polygonOwnerDropUser{
    top: 2px;

}

.collabaratorPersonaImage{
    position: relative;
    top: -2px;
}
.polygonCollaboratorsApprovers li span:last-child{
    position: relative;
    margin: 5px;
    top: 3px;
}
.polygonCollaboratorsApprovers img{
    margin-bottom: 3px;
}

.addPolygonTopicFieldsDiv input {
    width: 100%;
    max-height: 40px;
    font-size: 12px;
    padding: 5px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #B9B9B9;
    border-radius: 2px;
    opacity: 1;
    margin-top: 2px;
}

.addPolygonTopicFieldsDiv .hashtagsDropDownContainerWithTags {
    width: 100%;
    margin-right: 0;
    display: flex;
    flex-wrap: wrap;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #B9B9B9;
    border-radius: 2px;
    opacity: 1;
}

.addPolygonTopicFieldsDiv div#topicsHashtagsInput {
    font-size: 12px;
    margin-top: 2px;
}

.addPolygonTopicFieldsDiv .ck-editor {
    width: 100%;
    margin-top: 2px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 0.800000011920929px solid #B9B9B9;
    border-radius: 2px;
    opacity: 1;
}

.polygonStepsButtons {
    padding: 10px 0px;
}

.polygonBackBtn {
    border: none;
    font-size: 13px;
    padding: 5px 20px;
    border-radius: 4px;
    background: #666;
    color: #fff;
}

.addPolygonTopicFieldsDiv .ck .ck-editor__editable_inline {
    min-height: 432px;
}

.polygonContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 280px;
}

.polygonTopicRightContainer {
    width: 25%;
    min-width: 310px;
    border-left: 1px solid #f2f2f2;
    justify-content: center;
}

.polygonTopicRightContent {
    display: block;
    width: 100%;
    overflow: auto;
    overflow-x: hidden !important;
    height: 435px;
    position: relative;
}
.polygonbasicTopicRightContent {
    display: block;
    width: 100%;
    overflow: scroll;
    overflow-x: hidden !important;
    height: 800px;
    position: relative;
    /* overflow-y: hidden !important; */
    scrollbar-width: none;
}

.polygonTopicCheckbox {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.polygonTopicIsVisible {
    height: 40px;
    opacity: 1;
    padding: 10px 0px 10px 0px;
    width: 100%;
    display: flex;
    margin-left: -9px !important;
}

.isVisibleDivPolygon {
    margin: 0px 10px 0px 20px;
}

.isVisibleDivPolygon input[type='checkbox']:checked {
    background: #0178D4 0% 0% no-repeat padding-box;
    border-radius: 2px;
    opacity: 1;
}

.polygonTopicCollaboratorLabel {
    font: normal normal 600 14px/19px Segoe UI;
    height: 40px;
    background: #F2F2F2 0% 0% no-repeat padding-box;
    opacity: 1;
    padding: 10px 0px 10px 10px;
}

.polygonTopicRelatedContentLabel {
    font: normal normal 600 14px/19px Segoe UI;
    height: 40px;
    background: #F2F2F2 0% 0% no-repeat padding-box;
    opacity: 1;
    padding: 10px 0px 10px 10px !important;
    display: flex;
}

.polygonTopicRelatedContentLabel .leftIcons {
    position: relative;
    left: 60%;
}

.polygonTopicBusinessRuleLabel .leftIcons {
    position: relative;
    left: 65%;
}

.polygonTopicModalRelatedContenTitle {
    text-align: left;
    font: normal normal 600 16px/21px Segoe UI;
    letter-spacing: -0.16px;
    color: #222629;
    opacity: 1;
}

.addPolygonTopicFieldContenUrl {
    display: flex;
    flex-direction: column;
    width: 44%;
    margin-right: 10px;
}

.addPolygonTopicFieldContenUrlLabel {
    display: flex;
    flex-direction: column;
    width: 44%;
    margin-right: 10px;
}

.addPolygonTopicFieldContenUrl input {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #CECECE;
    opacity: 1;
    height: 32px;
}

span.addPolygonTopicFieldLabel {
    font-weight: 600;
    margin-bottom: 4px;
    font-size: 14px;
}

.relatedContentClose {
    cursor: pointer;
    font-size: small;
}

.addPolygonTopicRelatedContentLabel {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.addPolygonTopicRelatedInputContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.polygonInputContainer {
    width: 100%;
}

.addPolygonTopicRelatedInput {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 3px;
}

.relatedContentDelete {
    width: 32px;
    height: 32px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #CECECE;
    opacity: 1;
    margin-right: 5px;
}

.relatedContentDeleteIcon {
    color: #0178D4;
    width: 13px;
    height: 13px;
    opacity: 1;
    font-weight: bold !important;
}

.relatedContentAdd {
    width: 32px;
    height: 32px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #CECECE;
    opacity: 1;
    position: absolute;
    /* bottom: 19px; */
    right: 15px;
}

.relatedContentAddIcon {
    color: #0178D4;
    width: 13px;
    height: 13px;
    opacity: 1;
    font-weight: bolder !important;
}

.cancelRelatedContent {
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    border: 1px solid #CECECE;
    border-radius: 8px;
    opacity: 1;
    text-align: left;
    font: normal normal normal 16px/21px Segoe UI;
    letter-spacing: -0.16px;
    color: #333333;
    padding: 5px 25px;
}

.cancelBusinessContent {
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    border: 1px solid #CECECE;
    border-radius: 8px;
    opacity: 1;
    text-align: left;
    font: normal normal normal 16px/21px Segoe UI;
    letter-spacing: -0.16px;
    color: #333333;
    padding: 5px 25px;
}
.typeBox{
    padding: 5px !important;
}
.typeBox #topicType .dropDownBox{
    border: 1px solid #3b3d41;
}
.relatedContentAnchor {
    display: flex;
    background: #f1f5f9;
    width: 98%;
    border-radius: 3px;
    border: 1px solid #4d87f6;
    text-decoration: none;
    margin-top: 7px;
}

.relatedContentAnchor:hover {
    text-decoration: none;
}

.relatedContentAnchor span {
    padding: 2px 10px;
}

.addBtnRelatedContent {
    background: #0079D4 0% 0% no-repeat padding-box;
    border-radius: 8px;
    opacity: 1;
    text-align: left;
    font: normal normal normal 16px/21px Segoe UI;
    letter-spacing: -0.16px;
    color: #FFFFFF;
    padding: 4px 30px;
    margin-left: 5px;
    border-color: #0079D4;
}

.addBtnBusinessContent {
    background: #0079D4 0% 0% no-repeat padding-box;
    border-radius: 8px;
    opacity: 1;
    text-align: left;
    font: normal normal normal 16px/21px Segoe UI;
    letter-spacing: -0.16px;
    color: #FFFFFF;
    padding: 4px 30px;
    margin-left: 5px;
    border-color: #0079D4;
}

.accordion {
    margin-bottom: 0px;
}

.accordion .card {
    margin-bottom: 3px;
    margin-right: 5px;
}

.errorBusinessRule {
    display: flex;
    flex-direction: row;
}

.errorBusinessTitle {
    width: 45%;
    margin-right: 10px;
}

.errorBusinessUrl {
    width: 45%;
}

/* Pentagon Shape Component CSS */
.polygonShape .pentagonTopicList {
    right: 300px;
    margin-top: 82px;
    position: absolute;
}

.polygonPreviewArea .pentagonTopicList {
    right: 268px;
    margin-top: 43px;
    top: 0px !important;
    position: absolute;

}

.pentagonTopicList {
    right: 300px;
    top: 130px;
    position: absolute;
}

.pentagonTopicList a{
    outline: none !important;
}

.selectedPolygon {
    background: #9BC4F0 0% 0% no-repeat padding-box !important;
    color: black !important;
}

.pentagonTopicListItem1 {
    color: black;
    cursor: pointer;
    display: flex;
    position: absolute;
    top: 53px;
    left: 78px;
    width: 105px;
    height: 101px;
    background: #EBEBEB 0% 0% no-repeat padding-box;
    opacity: 1;
    clip-path: polygon(100% 50%, 65.45% 98.55%, 9.55% 80.39%, 9.55% 20.61%, 65.45% 2.45%);
    transform: translate(-14px, -5px) rotate(18deg);
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 10px;
}

.pentagonTopicListItem1:hover {
    background: #9BC4F0 0% 0% no-repeat padding-box;
    color: black;
}

.pentagonTopicList span {
    text-align: center;
    font: normal normal normal 12px/14px Segoe UI;
    letter-spacing: -0.12px;
    /* color: #2B304D; */
    opacity: 1;
}

.pentagonTopicTitle1 {
    transform: rotate(-18deg);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    /* Number of lines before truncating */
    -webkit-box-orient: vertical;
    height: auto;
    /* Adjust based on content */
    max-height: 40px;
    /* Adjust the max height as needed */
    line-height: 1.2;
    /* Adjust line height */
    width: 100%;
    /* Full width */
    text-align: center;
    /* Center the text */
    white-space: normal;
    /* Allow wrapping */
    word-break: break-word;
    max-width: 59px;
}

.pentagonTopicListItem2 {
    color: black;
    cursor: pointer;
    display: flex;
    position: absolute;
    top: 53px;
    left: 168px;
    width: 105px;
    height: 101px;
    background: #EBEBEB 0% 0% no-repeat padding-box;
    opacity: 1;
    clip-path: polygon(100% 50%, 65.45% 98.55%, 9.55% 80.39%, 9.55% 20.61%, 65.45% 2.45%);
    transform: translate(-3px, -4px) rotate(19deg);
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 10px;
}

.pentagonTopicListItem2:hover {
    background: #9BC4F0 0% 0% no-repeat padding-box;
    color: black;
}

.pentagonTopicTitle2 {
    transform: rotate(-18deg);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    /* Number of lines before truncating */
    -webkit-box-orient: vertical;
    height: auto;
    /* Adjust based on content */
    max-height: 40px;
    /* Adjust the max height as needed */
    line-height: 1.2;
    /* Adjust line height */
    width: 100%;
    /* Full width */
    text-align: center;
    /* Center the text */
    white-space: normal;
    /* Allow wrapping */
    word-break: break-word;
    max-width: 59px;
}

.pentagonTopicListItem3 {
    color: black;
    cursor: pointer;
    display: flex;
    position: absolute;
    top: 140px;
    left: 196px;
    width: 105px;
    height: 101px;
    background: #EBEBEB 0% 0% no-repeat padding-box;
    opacity: 1;
    clip-path: polygon(100% 50%, 65.45% 98.55%, 9.55% 80.39%, 9.55% 20.61%, 65.45% 2.45%);
    transform: translate(1px, 4px) rotate(18deg);
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 10px;
}

.pentagonTopicListItem3:hover {
    background: #9BC4F0 0% 0% no-repeat padding-box;
    color: black;
}

.pentagonTopicTitle3 {
    transform: rotate(-18deg);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    /* Number of lines before truncating */
    -webkit-box-orient: vertical;
    height: auto;
    /* Adjust based on content */
    max-height: 40px;
    /* Adjust the max height as needed */
    line-height: 1.2;
    /* Adjust line height */
    width: 100%;
    /* Full width */
    text-align: center;
    /* Center the text */
    white-space: normal;
    /* Allow wrapping */
    word-break: break-word;
    max-width: 59px;
}

.pentagonTopicListItem4 {
    color: white;
    cursor: pointer;
    display: flex;
    position: absolute;
    top: 118px;
    left: 115px;
    width: 105px;
    height: 101px;
    background: #9974d8 0% 0% no-repeat padding-box;
    opacity: 1;
    clip-path: polygon(100% 50%, 65.45% 98.55%, 9.55% 80.39%, 9.55% 20.61%, 65.45% 2.45%);
    transform: rotate(54deg);
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 10px;
}

.pentagonTopicListItem4:hover {
    background: #9BC4F0 0% 0% no-repeat padding-box;
    color: black;
}

.pentagonTopicTitle4 {
    transform: rotate(-55deg);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    /* Number of lines before truncating */
    -webkit-box-orient: vertical;
    height: auto;
    /* Adjust based on content */
    max-height: 40px;
    /* Adjust the max height as needed */
    line-height: 1.2;
    /* Adjust line height */
    width: 100%;
    /* Full width */
    text-align: center;
    /* Center the text */
    white-space: normal;
    /* Allow wrapping */
    word-break: break-word;
    max-width: 59px;
}



.pentagonTopicListItem5 {
    color: black;
    cursor: pointer;
    display: flex;
    position: absolute;
    top: 193px;
    left: 123px;
    width: 105px;
    height: 101px;
    background: #EBEBEB 0% 0% no-repeat padding-box;
    opacity: 1;
    clip-path: polygon(100% 50%, 65.45% 98.55%, 9.55% 80.39%, 9.55% 20.61%, 65.45% 2.45%);
    transform: translate(-7px, 10px) rotate(18deg);
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 10px;
}

.pentagonTopicListItem5:hover {
    background: #9BC4F0 0% 0% no-repeat padding-box;
    color: black;
}

.pentagonTopicTitle5 {
    transform: rotate(-18deg);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    /* Number of lines before truncating */
    -webkit-box-orient: vertical;
    height: auto;
    /* Adjust based on content */
    max-height: 40px;
    /* Adjust the max height as needed */
    line-height: 1.2;
    /* Adjust line height */
    width: 100%;
    /* Full width */
    text-align: center;
    /* Center the text */
    white-space: normal;
    /* Allow wrapping */
    word-break: break-word;
    max-width: 59px;
}

.pentagonTopicListItem6 {
    color: black;
    cursor: pointer;
    display: flex;
    position: absolute;
    top: 140px;
    left: 50px;
    width: 105px;
    height: 101px;
    background: #EBEBEB 0% 0% no-repeat padding-box;
    opacity: 1;
    clip-path: polygon(100% 50%, 65.45% 98.55%, 9.55% 80.39%, 9.55% 20.61%, 65.45% 2.45%);
    transform: translate(-17px, 3px) rotate(18deg);
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 10px;
}

.pentagonTopicListItem6:hover {
    background: #9BC4F0 0% 0% no-repeat padding-box;
    color: black;
}

.pentagonTopicTitle6 {
    transform: rotate(-18deg);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    /* Number of lines before truncating */
    -webkit-box-orient: vertical;
    height: auto;
    /* Adjust based on content */
    max-height: 40px;
    /* Adjust the max height as needed */
    line-height: 1.2;
    /* Adjust line height */
    width: 100%;
    /* Full width */
    text-align: center;
    /* Center the text */
    white-space: normal;
    /* Allow wrapping */
    word-break: break-word;
    max-width: 59px;
}

/* Trianle Shape Component CSS */
.triangleContent {
    position: relative;
    display: inline-flex;
    list-style-type: none;
}

.polygonPreviewArea .triangleContent {
    right: 280px;
    position: absolute;
    display: inline-flex;
    list-style-type: none;
    margin-top: 76px;
}

.polygonContainer .triangleContent {
    top: 15px;
    right: 40px;
    position: relative;
    display: inline-flex;
    list-style-type: none;
}

.polygonShape .triangleContent {
    right: 316px;
    position: absolute;
    display: inline-flex;
    list-style-type: none;
    margin-top: 76px;
}
.polygonSelectedShape .triangleContent{
    position: relative;
    display: inline-flex;
    list-style-type: none;
    right: 40px;
}
.triangleContent a{
    outline: none !important;
}

.triangleUp2 {
    color: black;
    background: #EBEBEB;
    width: 138px;
    height: 119px;
    left: 112px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

.triangleUp2:hover {
    background: #9BC4F0 0% 0% no-repeat padding-box;
    color: black;
}

.triangleUpTopicTitle2 {
    font: normal normal normal 12px / 17px Segoe UI;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-inline-box;
    position: relative;
    /* top: 50px; */
    -webkit-line-clamp: 3;
    /* Number of lines before truncating */
    -webkit-box-orient: vertical;
    height: auto;
    /* Adjust based on content */
    max-height: 45px;
    /* Adjust the max height as needed */
    line-height: 1.2;
    /* Adjust line height */
    width: 100%;
    /* Full width */
    text-align: center;
    /* Center the text */
    white-space: normal;
    /* Allow wrapping */
    word-break: break-word;
    max-width: 59px;
}

.triangleUp3 {
    color: black;
    background: #EBEBEB;
    width: 138px;
    height: 119px;
    top: 120px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

.triangleUp3:hover {
    background: #9BC4F0 0% 0% no-repeat padding-box;
    color: black;
}

.triangleUpTopicTitle3 {
    font: normal normal normal 12px / 17px Segoe UI;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-inline-box;
    position: relative;
    /* top: 50px; */
    -webkit-line-clamp: 3;
    /* Number of lines before truncating */
    -webkit-box-orient: vertical;
    height: auto;
    /* Adjust based on content */
    max-height: 45px;
    /* Adjust the max height as needed */
    line-height: 1.2;
    /* Adjust line height */
    width: 100%;
    /* Full width */
    text-align: center;
    /* Center the text */
    white-space: normal;
    /* Allow wrapping */
    word-break: break-word;
    max-width: 59px;
}

.triangleUp1 {
    color: black;
    background: #EBEBEB;
    width: 138px;
    height: 119px;
    left: 184px;
    top: 120px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

.triangleUp1:hover {
    background: #9BC4F0 0% 0% no-repeat padding-box;
    color: black;
}

.triangleUpTopicTitle1 {
    font: normal normal normal 12px / 17px Segoe UI;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-inline-box;
    position: relative;
    /* top: 50px; */
    -webkit-line-clamp: 3;
    /* Number of lines before truncating */
    -webkit-box-orient: vertical;
    height: auto;
    /* Adjust based on content */
    max-height: 45px;
    /* Adjust the max height as needed */
    line-height: 1.2;
    /* Adjust line height */
    width: 100%;
    /* Full width */
    text-align: center;
    /* Center the text */
    white-space: normal;
    /* Allow wrapping */
    word-break: break-word;
    max-width: 59px;
}

.triangleDown {
    color: white;
    cursor: pointer;
    transform: rotate(180deg);
    background: #9974d8;
    width: 138px;
    height: 119px;
    top: 121px;
    left: 112px;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}

.triangleDown:hover {
    background: #9BC4F0 0% 0% no-repeat padding-box;
    color: black;
}

.triangleDownTopicTitle {
    font: normal normal normal 12px / 17px Segoe UI;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-inline-box;
    position: relative;
    top: 22px;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    height: auto;
    max-height: 50px;
    line-height: 1.2;
    width: 100%;
    text-align: center;
    white-space: normal;
    word-break: break-word;
    max-width: 59px;
    transform: rotate(180deg);
}

/* Square Shape Component CSS */
.squareContent {
    position: absolute;
    list-style-type: none;
}
.polygonSelectedShape .squareContent{
    right: 272px;
    position: absolute;
    list-style-type: none;
}
.polygonContainer .squareContent{
    top: 160px;
    right: 272px;
    position: absolute;
    list-style-type: none;
}

.polygonPreviewArea .squareContent{
    margin-top: 84px;
    right: 236px;
    position: absolute;
    list-style-type: none;
}

.polygonShape .squareContent{
    margin-top: 84px;
    right: 272px;
    position: absolute;
    list-style-type: none;
}
.squareContent a{
    outline: none !important;
}

.squareTopicli1 {
    color: black;
    cursor: pointer;
    flex-wrap: wrap;
    align-content: center;
    display: flex;
    justify-content: center;
    text-align: center;
    position: absolute !important;
    top: 3px !important;
    left: -2px !important;
    width: 138px;
    height: 138px;
    background: #EBEBEB 0% 0% no-repeat padding-box;
    opacity: 1;
    transform: matrix(0.50, -0.50, 0.50, 0.50, 0, 0);
    clip-path: inset(0% 0% 0% 0%);
}

.squareTopicli1:hover {
    background: #9BC4F0 0% 0% no-repeat padding-box;
    color: black;
}

.squareTopicli2 {
    color: black;
    cursor: pointer;
    flex-wrap: wrap;
    align-content: center;
    display: flex;
    justify-content: center;
    text-align: center;
    position: absolute !important;
    top: 3px !important;
    left: 138px !important;
    width: 138px;
    height: 138px;
    background: #EBEBEB 0% 0% no-repeat padding-box;
    opacity: 1;
    transform: matrix(0.50, -0.50, 0.50, 0.50, 0, 0);
    clip-path: inset(0% 0% 0% 0%);
}

.squareTopicli2:hover {
    background: #9BC4F0 0% 0% no-repeat padding-box;
    color: black;
}

.squareTopicli3 {
    color: white;
    cursor: pointer;
    flex-wrap: wrap;
    align-content: center;
    display: flex;
    justify-content: center;
    text-align: center;
    position: absolute !important;
    top: 73px !important;
    left: 68px !important;
    width: 138px;
    height: 138px;
    background: #9974d8 0% 0% no-repeat padding-box;
    opacity: 1;
    transform: matrix(0.50, -0.50, 0.50, 0.50, 0, 0);
    clip-path: inset(0% 0% 0% 0%);
}

.squareTopicli3:hover {
    background: #9BC4F0 0% 0% no-repeat padding-box;
    color: black;
}

.squareTopicli4 {
    color: black;
    cursor: pointer;
    flex-wrap: wrap;
    align-content: center;
    display: flex;
    justify-content: center;
    text-align: center;
    position: absolute !important;
    top: 143px !important;
    left: 138px !important;
    width: 138px;
    height: 138px;
    background: #EBEBEB 0% 0% no-repeat padding-box;
    opacity: 1;
    transform: matrix(0.50, -0.50, 0.50, 0.50, 0, 0);
    clip-path: inset(0% 0% 0% 0%);
}

.squareTopicli4:hover {
    background: #9BC4F0 0% 0% no-repeat padding-box;
    color: black;
}

.squareTopicli5 {
    color: black;
    flex-wrap: wrap;
    align-content: center;
    display: flex;
    justify-content: center;
    text-align: center;
    position: absolute !important;
    top: 143px;
    left: -3px !important;
    width: 138px;
    height: 138px;
    background: #EBEBEB 0% 0% no-repeat padding-box;
    opacity: 1;
    transform: matrix(0.50, -0.50, 0.50, 0.50, 0, 0);
    clip-path: inset(0% 0% 0% 0%);
    cursor: pointer;
}

.squareTopicli5:hover {
    background: #9BC4F0 0% 0% no-repeat padding-box;
    color: black;
}

.squareTopicLiTitle {
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    position: relative;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    height: auto;
    max-height: 90px;
    line-height: 1.2;
    width: 100%;
    text-align: center;
    white-space: normal;
    word-break: break-word;
    max-width: 90px;
    transform: rotate(45deg);
    font: normal normal normal 14px / 17px Segoe UI;
}

/* Square Shape Component CSS */
.hexagonContent {
    position: absolute;
    list-style-type: none;
}

.polygonShape .hexagonContent {
    margin-top: 82px;
    right: 272px;
    position: absolute;
}

/* .polygonPreviewArea .hexagonContent{
    position: absolute;
    list-style-type: none;
    top: 70px;
    left: 12%;
} */

@media (min-width: 1024px) and (max-width: 1199px) {
    .polygonPreviewArea .hexagonContent{
        margin-top: 82px;
    right: 235px;
    position: absolute;

    }
}

@media (min-width: 1199px) and (max-width: 1920px) {
    .polygonPreviewArea .hexagonContent{
        margin-top: 82px;
        right: 235px;
        position: absolute;
    }
}

.polygonContainer .hexagonContent{
    position: absolute;
    list-style-type: none;
    /* right: 21%;
    top: 27%; */
    right: 282px;
    top: 178px;
}
.polygonSelectedShape .hexagonContent{
    right: 282px;
    position: absolute;
    list-style-type: none;
}
.hexagonContent a{
    outline: none !important;
}

.hexagonTopicItem1 {
    color: black;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    text-align: center;
    position: absolute !important;
    width: 100px;
    height: 100px;
    background: #EBEBEB 0% 0% no-repeat padding-box;
    opacity: 1;
    /* clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%); */
    clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
    transform: matrix(0, -1, 1, 0, 0, 0);
}

.hexagonTopicItem1:hover {
    background: #9BC4F0 0% 0% no-repeat padding-box;
    color: black;
}

.hexagonTopicItem2 {
    color: black;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    text-align: center;
    position: absolute !important;
    left: 131px !important;
    width: 100px;
    height: 100px;
    background: #EBEBEB 0% 0% no-repeat padding-box;
    opacity: 1;
    /* clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%); */
    clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
    transform: matrix(0, -1, 1, 0, 0, 0);
}

.hexagonTopicItem2:hover {
    background: #9BC4F0 0% 0% no-repeat padding-box;
    color: black;
}

.hexagonTopicItem3 {
    color: black;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    text-align: center;
    position: absolute !important;
    top: 76px !important;
    left: 176px !important;
    width: 100px;
    height: 100px;
    background: #EBEBEB 0% 0% no-repeat padding-box;
    opacity: 1;
    /* clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%); */
    clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
    transform: matrix(0, -1, 1, 0, 0, 0);
}

.hexagonTopicItem3:hover {
    background: #9BC4F0 0% 0% no-repeat padding-box;
    color: black;
}

.hexagonTopicItem4 {
    color: white;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    text-align: center;
    position: absolute !important;
    top: 76px !important;
    left: 85px !important;
    width: 100px;
    height: 100px;
    background: #9974d8 0% 0% no-repeat padding-box;
    opacity: 1;
    /* clip-path: polygon(24% 0, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%); */
    clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
    transform: matrix(0, -1, 1, 0, 0, 0);
}

.hexagonTopicItem4:hover {
    background: #9BC4F0 0% 0% no-repeat padding-box;
    color: black;
}

.hexagonTopicItem5 {
    color: black;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    text-align: center;
    position: absolute !important;
    top: 152px !important;
    left: 131px !important;
    width: 100px;
    height: 100px;
    background: #EBEBEB 0% 0% no-repeat padding-box;
    opacity: 1;
    /* clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%); */
    clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
    transform: matrix(0, -1, 1, 0, 0, 0);
}

.hexagonTopicItem5:hover {
    background: #9BC4F0 0% 0% no-repeat padding-box;
    color: black;
}

.hexagonTopicItem6 {
    color: black;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    text-align: center;
    position: absolute !important;
    top: 152px !important;
    left: 40px !important;
    width: 100px;
    height: 100px;
    background: #EBEBEB 0% 0% no-repeat padding-box;
    opacity: 1;
    /* clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%); */
    clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
    transform: matrix(0, -1, 1, 0, 0, 0);
}

.hexagonTopicItem6:hover {
    background: #9BC4F0 0% 0% no-repeat padding-box;
    color: black;
}

.hexagonTopicItem7 {
    color: black;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    text-align: center;
    position: absolute !important;
    top: 76px !important;
    left: -6px !important;
    width: 100px;
    height: 100px;
    background: #EBEBEB 0% 0% no-repeat padding-box;
    opacity: 1;
    /* clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%); */
    clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
    transform: matrix(0, -1, 1, 0, 0, 0);
}

.hexagonTopicItem7:hover {
    background: #9BC4F0 0% 0% no-repeat padding-box;
    color: black;
}

.hexagonTopicItemTitle {
    letter-spacing: -0.12px;
    /* color: #2B304D; */
    opacity: 1;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    position: relative;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    height: auto;
    max-height: 60px;
    line-height: 1.2;
    width: 100%;
    text-align: center;
    white-space: normal;
    word-break: break-word;
    max-width: 60px;
    transform: rotate(90deg);
    font: normal normal normal 12px / 17px Segoe UI;
}

.chevronContentSpan {
    padding-right: 0px !important;
}

.clickableIcon {
    cursor: pointer;

}
.previewTopicsOwnerDetails img {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	margin-top: 2px;
}
.previewTopicsOwnerDetails {
	display: flex;
	padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 15px;
}
.previewAssociateContent{
    margin-top: 92px;
}
.previewAssociateSquare{
    margin-top: 110px;
}
.previewAssociateHexa{
    margin-top: 80px;
}
.previewAssociateTraingle{
    margin-top: 65px;
}
.hashtagPreview{
    border: 1px solid #3191E7;
    border-radius: 4px;
opacity: 1;
color: #3191E7;
padding:4px;
margin-right: 5px;
}
.previewNormalTopicsOwnerDetails{
    display: flex;
    padding-left: 71px !important;
    padding-top: 10px;
    padding-bottom: 15px;
}
.normalTopicPreview{
    display: flex;
    flex-direction: column;
}
.addMarginBusinnessRelative{
    margin-right: 43px !important;
}


.accordion>.userPolygonCard{
    overflow:visible !important ;
}
.polygonDropdownStyle{
    max-height: 200px !important;
}
/* .previewModalBorder{
    border-right: 2px solid #f2f2f2;
    
} */
.coownerViewTitle{
    top: -1px !important;
    margin-left: 10px !important;
}
.addPolygonTopicFields{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 5px;
}
.addPolygonTopicFields .topicBuilderProcessGroup {
    padding: 10px;
}
.addPolygonTopicFields .topicBuilderProcessGroup .dropDownBox {
    border: 1px solid #e4e4e4;
    padding: 4.5px 9px !important;
}
.addPolygonTopicFields ul.afterSaveList.beforEditSavedList {
    padding: 0px 10px;
    margin-top: 0px;
}
.addPolygonTopicFields .polygonTopicCategory {
    padding: 10px;
    width: 100%;
    height: 30px;
}
.addPolygonTopicFields .polygonTopicCategory .dropDownBox{
    padding: 4.5px 9px !important;
    border: 1px solid #e4e4e4;
}
.dropDowncontainer.polygonTopicCategory .dropDownBox span.wrapHeader {
	width: 90%;
}
.addPolygonTopicFields .polygonTopicCategory .dropDownList{
    list-style: none;
    display: none;
    padding: 12px 0 5px 0;
    top: 0px !important;
    z-index: 99999;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.247058823529412);
    position: relative;
    background: #fff;
    border: 1px solid #ccc;
    border-top: 0;
    width: 100%;
    border-radius: 3px;
}
.hideArrowForEmpty{
    cursor: default !important;
}
.ChevronStyleForTopic{
    margin-left: 4px !important;
}
.polygonLatestPreview{
    width: 100% !important;
}
.PreviewScreenPolygon{
    position: fixed !important;
}
.polygontopicOnwer{
    position: relative !important;
    float: left !important;
}
.PreviewContentLatest{
    display: flex !important;
    align-items: normal  !important;
}
.PreviewPopUpHeight{
    height: auto !important;
}
.polygonPreviewMain{
    max-height: -webkit-fill-available;
}

.polygonPreviewRightContainer{
    height: calc(100vh - 12rem);
    overflow-x: hidden !important;
    padding: 10px 0px 33px 5px !important;
}
.polygonPreviewImageContainer{
    position: relative !important;
}
.topicPolygonDescriptionPreviewDiv{
    overflow-y: auto !important ;
    height: auto;
    min-height:  calc(100vh - 12rem);
}
._PolygonBorder{
    border-right: 1px solid rgba(0, 0, 0, .125);
    padding: 10px 0px 0px 15px !important;
}
._topicRightPreviewDescriptionDiv{
    padding: 10px 15px 33px 15px !important;

}
._topicsCrowdSourcingContainerPolygon{
    height: auto !important;
}