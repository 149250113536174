.activityPreviewButton {
	background: #f58220;
	padding: 2px 18px !important;
	background-color: #f58220 !important;
	border-color: #f58220 !important;
	color: #ffffff;
	margin-left: 20px;
	font-size: 14px !important;
}
.discardAndPreviewButton {
	float: right;
	margin-left: auto;
	margin-right: 32px;
}
.PreviewAndSubmitPopUp .modal-dialog {
	max-width: 1100px;
}
.PreviewAndSubmitPopUp .descOfActivity {
	height: 66vh;
}
.PreviewAndSubmitPopUp .previewDescOfActivity {
	float: left;
	margin: 5px 17px 15px 15px;
}
.PreviewAndSubmitPopUp .activityDescriptionDetails {
	height: 81vh;
	overflow: hidden;
	overflow-y: auto;
	padding: 0 40px !important;
}
.PreviewAndSubmitPopUp .modal-title {
	width: 100%;
	font-size: 21px;
	font-weight: 600;
}
.PreviewAndSubmitPopUp .modal-body {
	padding: 0 !important;
}
.PreviewAndSubmitPopUp .modal-header {
	padding: 10px;
}
.PreviewAndSubmitPopUp button:focus {
	box-shadow: none !important;
}
.historicalPreview .discardAndPreviewButton a {
	color: #333;
	font-size: 17px;
}
.historicalPreview .discardAndPreviewButton {
	margin-right: 5px;
}
.historicalPreview .discardAndPreviewButton a i {
	font-weight: 600;
}
.historicalPreview .modal-header .modal-title {
	font-size: 19px;
	font-weight: 600;
}
.reviwerRejectedRatingContainer {
	display: flex;
	flex-direction: column;
	padding: 20px 100px;
}
.reviwerRejectedRatingTab {
	display: flex;
	border: 1px solid #666;
	min-height: 100px;
	padding: 15px;
	margin-bottom: 20px;
}
.reviwerRejectedRatingDetails {
	display: flex;
	border-left: 1px solid #ccc;
	padding: 7px 20px;
}
.reviwerRejectedUserDetails {
	display: flex;
	padding: 20px;
	width: 300px;
}
.reviwerRejectedRatingDetails .reviwerRejectedRating {
	width: 250px;
	text-align: center;
}
.reviwerRejectedEarnedPoints {
	text-align: center;
}
.reviwerRejectedRatingDetails h6 {
	color: #666;
	font-size: 14px;
}
.reviwerRejectedUserDetails img {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	margin-right: 10px;
}
.reviwerRejectedUserDetails h6 {
	line-height: 35px;
}
.reviwerRejectedRatingButton {
	text-align: center;
	margin-bottom: 40px;
}

.topicsImgDimensions img{
	width: 200px !important;
	height: 200px !important;
	}
.topicsImgDimensions .ck-widget__type-around{
	display: none !important;
}
