.dropDownList.hashtagDpList {
	top: 36px;
	width: 250px;
	background-color: #f4f4f4;
}

.dropDownList.hashtagDpList ul {
	max-height: 200px;
}

.dropDowncontainer.hashtagsDropDownContainer {
	width: 100%;
	margin-right: 0;
}

.dropDowncontainer.hashtagsDropDownContainer input {
	width: 100%;
	padding: 7px;
	border: 1px solid #e4e4e4;
}

div#activityHashtagsInput {
	flex: 1;
}

.dropDownList.hashtagDpList ul li {
	color: #0078d4;
}

.topicsMenuWrapper .dropDownList.hashtagDpList {
	width: 224px;
	left: -27px;
}

.tutorialUploadScreen .modal-body .dropDownList.hashtagDpList ul li {
	line-height: normal;
}

.addActivityFields p.errorMsg {
	margin-bottom: 0;
	font-size: 12px;
}

.dropDowncontainer.hashtagsDropDownContainerWithTags {
	width: 100%;
	margin-right: 0;
	display: flex;
	border: 1px solid #3b3d41;
	flex-wrap: wrap;
	background: #fff;
}

.dropDowncontainer.hashtagsDropDownContainerWithTags input {
	border: none !important;
	padding: 7px;
}

.dropDowncontainer.hashtagsDropDownContainerWithTags .hashTagsContainer {
	margin-bottom: 0;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}

.activityEditHashtagsInput .hashTagsContaineredit {
	margin-bottom: 0;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}

.hashTagsWithCancelCheckMark>div {
	display: flex;
}

.dropDowncontainer.hashtagsDropDownContainerWithTags input:focus {
	outline: 0px dotted !important;
}

.dropDowncontainer.hashtagsDropDownContainerWithTags input::placeholder {
	color: #55585B !important
}

.submitAndcancelFocus {
	outline: none !important;
}

.submitAndcancelFocus:focus {
	outline: none !important;
	border: 2px dotted #253B62 !important;
}

.dropDowncontainer,
.hashtagsDropDownContainerWithTags {
	outline: none !important;
}

.dropDowncontainer:focus,
.hashtagsDropDownContainerWithTags :focus {
	outline: none !important;
	border: 2px dotted #253B62 !important;
}