.leftNavContainer {
	background-color: #2b314c;
	flex: 0 0 auto;
	position: relative;
	height: calc(100% - 5.9rem);
	transition: all 0.2s ease-in;
	z-index: 999;
}
.leftNavContainer.collapse {
	display: block !important;
	width: 55px;
}
.leftNavContainer.expand {
	width: 246px;
	transition-duration: 200ms;
}
.leftNavMenu li {
	float: left;
	padding: 15px 24.5px 15px 21px;
	list-style: none;
	text-align: center;
	color: #fff;
	flex-grow: 1;
	flex-basis: 0;
	cursor: pointer;
}
.leftNavMenu li.extractRolesDiv > i:focus {
	outline: 1px dotted #fff !important;
}
.leftNavMenu li.extractRolesDiv .showMoreMenu li.moreItemLi:focus {
	outline: 1px dotted #000 !important;
}
ul.leftNavMenu {
	display: flex;
	margin: 0;
	padding: 0;
	background-color: #1c2139;
}
.NavLink {
	color: white;
	text-decoration: none;
	display: flex;
}
.NavLink:hover,
.NavLink:focus {
	color: white;
	text-decoration: none;
	color: #f58220 !important;
}
.selectedProject {
	border-bottom: 4px solid #f58220;
	color: #f58220 !important;
}
.selectedSolution {
	color: #f58220 !important;
}
.leftNavPhaseMenu {
	margin: 10px 0;
	padding: 0;
	width: 246px;
}
.leftNavPhaseMenu li {
	list-style: none;
	background-color: #2b314c;
	color: #fff;
	padding: 13px 6px 13px 13px;
	line-height: 30px;
	font-size: 12px;
	text-align: left;
	/* overflow: hidden; */
	white-space: nowrap;
	text-overflow: ellipsis;
	width: 100%;
	display: flex;
}
.leftNavPhaseMenu li a span {
	margin-left: 10px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}
.leftNavPhaseMenu li i {
	font-size: 12px;
	vertical-align: bottom;
}
i.extractMoreIcon {
	display: none;
	cursor: pointer;
}
.collapseArrow {
	float: right;
	right: 0;
}
.leftNavBottom {
	color: #fff;
	font-size: 12px;
}
/* .expandArrow,
.collapseArrow {
  padding: 13px 13px 13px 19px;
  color: #f58220;
  position: absolute;
  bottom: 0;
  cursor: pointer;
} */
.leftNavbottom span {
	padding: 10px 13px 10px 14px;
	color: #f58220;
	position: absolute;
	bottom: 4px;
	cursor: pointer;
	right: 6px;
}
.leftMenuContainer {
	padding: 0 0px 10px 0px;
	overflow: hidden;
	height: calc(100vh - 10.4rem);
	display: flex;
	flex-direction: row;
	overflow-y: scroll;
	border-bottom: 0.5px solid #363b52;
}
.leftMenuContainer::-webkit-scrollbar {
	width: 0 !important ;
	display: none;
}
.leftMenuContainer {
	overflow: -moz-scrollbars-none;
	-ms-overflow-style: none;
	scrollbar-width: none;
}
.hideProject {
	display: none;
}
.leftNavPhaseMenu li a img {
	width: 20px;
	height: 20px;
}

.NavLink:hover .solutionMethodIcon path,
.NavLink:focus .solutionMethodIcon path {
	fill: #f58220 !important;
}

.selectedSolution .solutionMethodIcon path {
	fill: #f58220 !important;
}
.leftNavMenu li:focus,
.leftNavPhaseMenu li a:focus {
	outline: 1px dotted #fff !important;
	outline-offset: -1px;
}
.leftNavPhaseMenu li a.NavLink {
	width: 93%;
}
.leftNavbottom span:focus {
	outline: 1px dotted #fff !important;
	outline-offset: -2px;
}
.phaseLeftNavMenu a:focus {
	outline-offset: -1px;
}

.leftNavPhaseMenu li:hover,
.leftNavPhaseMenu li:focus-within {
	background-color: #1c2139;
}

.leftNavPhaseMenu li:hover .solutionMethodIcon svg rect,
.leftNavPhaseMenu li:focus-within .solutionMethodIcon svg rect {
	fill: #1c2139;
}
.leftNavPhaseMenu li.selectedExtractMethodology .solutionMethodIcon svg rect {
	fill: #1c2139;
}
.selectedExtractMethodology {
	background-color: #1c2139 !important;
}
.leftNavPhaseMenu li:hover i.extractMoreIcon,
.leftNavPhaseMenu li:focus-within i.extractMoreIcon {
	display: block;
}
.leftNavPhaseMenu li a:hover ~ i.extractMoreIcon,
.leftNavPhaseMenu li a:focus ~ i.extractMoreIcon {
	color: #f58220;
}

.extractPopupMainContainer {
	display: flex;
	width: 300px;
	padding: 10px;
	flex-direction: column;
	position: absolute;
	background: #fff;
	color: #000;
	box-shadow: 3px 2px 48px #00000026;
	bottom: 20px;
}

.extractPopupHeader {
	display: flex;
	/* flex: 1; */
}
.extractPopupHeaderh6 {
	margin: 4px;
}
.extractPopupHeaderi {
	margin-left: auto;
}
.extractDetailsButton {
	width: 190px;
	background: #43425d;
	padding: 5px 20px;
	text-align: center;
	margin-left: auto;
	margin-right: auto;
	/* margin-top: 20px; */
	cursor: pointer;
}
.extractMethodButtons {
	display: flex;
	flex-direction: column;
	color: #fff;
}
.extractSummeryButton {
	background: #0078d4;
	width: 190px;
	padding: 5px 20px;
	text-align: center;
	margin-left: auto;
	margin-right: auto;
	margin-top: 10px;
	cursor: pointer;
}
.extractFileContainerlabel.checkBoxContainer.filterChkboxContainer {
	margin-right: 27px;
}
.extractSelOfComplexity {
	display: flex;
	flex-direction: column;
	margin-bottom: 17px;
}
.extractFileType {
	display: flex;
	flex-direction: column;
	margin-bottom: 10px;
	margin-top: 5px;
}
.extractFileContainer {
	display: flex;
	margin-top: 5px;
}
.extractSelOfComplexity .dropDownList ul li {
	background-color: #fff;
	line-height: normal;
}
.extractSelOfComplexity .dropDownBox {
	padding: 6px 9px !important;
	margin-top: 3px;
}
.extractPopupBody {
	padding: 10px 44px;
	display: flex;
	flex-direction: column;
}
.extractPopupBody .dropDowncontainer.toolsAndGuidanceMultiSelectFilter {
	margin-left: 0px !important;
	width: 315px;
}
.extractPopupBody
	.dropDowncontainer.toolsAndGuidanceMultiSelectFilter
	.dropDownList {
	width: 315px;
	top: 55px;
}
.extractPopupBody
	.dropDowncontainer.toolsAndGuidanceMultiSelectFilter
	.dropDownBox {
	padding: 5px 9px !important;
	margin-top: 10px;
}
.extractRolesMainWrapperModal label.NotificationChkboxContainer span.CustomCheckmark {
    height: 15px;
    left: 2px;
    top: 3px;
    width: 15px;
}
.dropDowncontainer.extractMethodologyComplexityDropdown {
	width: 100%;
	margin-right: 0;
}
.extractFileContainer label.checkBoxContainer.filterChkboxContainer {
	padding-left: 7px;
	/* padding-top: 2px; */
	margin-right: 12px;
}
.extractFileContainer .filterChkboxContainer .checkmark {
	width: 14px;
	height: 14px;
}
.extractPopupHeader h6 {
	flex: 1;
	font-size: 14px;
	white-space: normal;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 80%;
	margin-bottom: 0;
	margin-top: 3px;
	line-height: 19px;
	padding: 0 4px;
}
.extractPopupHeader i {
	font-size: 14px !important;
	cursor: pointer;
}
.extractSelOfComplexity .dropDownBox i {
	margin-top: 0px;
}
.leftNavContainer.collapse li:hover i.extractMoreIcon,
.leftNavContainer.collapse li:focus-within i.extractMoreIcon {
	visibility: hidden;
}
.extractMethodologyMessage {
	color: #05132C !important;
	white-space: normal !important;
	line-height: 15px !important;
	font-size: 12px !important;
	text-align: center !important;
	padding: 1rem;
    font: normal normal 600 16px/21px Segoe UI;
}
.selectedExtractMethodology i.extractMoreIcon {
	display: block;
}
.extractFileContainer span.checkmark {
	top: 5px;
	left: 7px;
}
.extractFileContainer span.filterLabel {
	padding-left: 19px;
}
.extractFileType > span {
	font-weight: 600 !important;
    line-height: 12px !important;
    border: none !important;
    background: none !important;
    padding: 0px !important;
    margin: 0px !important;
    font-size: 1rem !important;
}
.extractSelOfComplexity > span {
	font-weight: 600;
}
/* .leftNavPhaseMenu li:last-child div#extractMethodologyId,
.leftNavPhaseMenu li:nth-last-child(2) div#extractMethodologyId,
.leftNavPhaseMenu li:nth-last-child(3) div#extractMethodologyId,
.leftNavPhaseMenu li:nth-last-child(4) div#extractMethodologyId {
	bottom: 0;
} */

ul.leftNavPhaseMenu li:nth-child(1) #extractMethodologyId.extractPopupMainContainer,
ul.leftNavPhaseMenu li:nth-child(2) #extractMethodologyId.extractPopupMainContainer,
ul.leftNavPhaseMenu li:nth-child(3) #extractMethodologyId.extractPopupMainContainer,
ul.leftNavPhaseMenu li:nth-child(4) #extractMethodologyId.extractPopupMainContainer {
	bottom: auto;
}

ul.leftNavPhaseMenu.support li:nth-last-child(3) div#extractMethodologyId {
	bottom: auto;
}
.leftNavbottom {
	position: relative;
    height: 46px;
}
.leftNavbottom a {
	position: absolute;
	bottom: 15px;
	left: 24px;
	color: #fff;
	font-size: 13px;
}
.leftNavbottom a:hover,
.leftNavbottom a:focus {
	color: #fff;
}
.leftNavbottom a:focus {
	outline: 1px dotted #fff !important;
}
.leftNavMenu li.extractRolesDiv {
	padding: 16px 0;
}
li.extractRolesDiv .extractPopupMainContainer {
	top: 8px;
	text-align: left;
}
li.extractRolesDiv .extractPopupMainContainer .extractSummeryButton {
	padding: 5px 20px;
}
.extractPopupBody > p {
	font-size: 13px;
	margin-bottom: 0;
	padding-bottom: 0;
	margin-top: 20px;
}
ul.leftNavMenu .showMoreMenu {
	width: 170px;
	top: 48px;
}
ul.leftNavMenu .showMoreMenu ul.menuItemUL li.moreItemLi {
	padding: 3px;
	background: none;
	color: #000;
	font-size: 12px;
	text-align: left;
}
.modal-dialog.phaseCreationRightPopup.manageRoles.modal-lg.modal-dialog-centered {
	margin-left: auto !important;
	margin: 0;
	max-width: 60%;
}
.phaseCreationRightPopup.manageRoles .saveContinueDetailsContainer {
	display: flex;
}
.phaseCreationRightPopup.manageRoles
	.saveContinueDetailsContainer
	.forwardButton {
	margin-right: auto;
	margin-left: auto;
}
.roleAdditionContainer {
	display: flex;
}
.addRoleButtonAction {
	display: inline-flex;
	border: 1px solid #4d87f6;
	background: #f1f5f9;
	padding: 8px 14px 8px 20px;
	flex-direction: column;
	margin-bottom: 24px;
	border-radius: 4px;
}
.selectAddRoleButton {
	color: #0262ab;
	cursor: pointer;
	font-size: 14px;
}
.addRoleFields {
	display: flex;
	width: 688px;
	margin-top: 10px;
	font-size: 13px;
}
.addRoleNameContainer {
	margin-top: 15px;
	display: flex;
	flex-direction: column;
	/* flex: 1 1; */
	margin-right: 20px;
}
span.AddRoleFieldLabel {
	font-weight: 600;
	margin-bottom: 4px;
	font-size: 14px;
}
.addRoleIcon {
	float: left;
	width: 22px;
	height: 22px;
	background: url(../../assets/addPhaseIcon.svg) no-repeat;
	background-size: contain;
	cursor: pointer;
	margin: 42px 2px 4px 2px;
}
.addRolesListContainer ul {
	margin: 0;
	padding: 0;
	list-style: none;
}
.addRolesListContainer ul .addRoleButtonAction {
	border: 1px solid #f6f6fb;
	background: #f6f6fb;
	width: 100%;
	padding: 4px 14px 4px 20px;
	margin-bottom: 15px;
}
.deleteRoleIcon {
	float: left;
	width: 29px;
	height: 29px;
	background: url(../../assets/TopicOwnerDeleteIcon.svg) no-repeat;
	background-size: contain;
	cursor: pointer;
	margin-top: 3px;
}
.editRoleIcon {
	float: left;
	width: 23px;
	height: 23px;
	background: url(../../assets/editIcon.svg) no-repeat;
	background-size: contain;
	cursor: pointer;
	margin-top: 6px;
}
.addRoleTitleEditPart {
	display: flex;
}
.addRoleTitleEditPart h6 {
	flex: 1;
	margin-bottom: 0;
	line-height: 31px;
	font-size: 14px;
}
.addRolesListContainer ul li {
	width: 100%;
}
.addRoleFields .addRoleNameContainer:first-child input {
	width: 250px;
	max-height: 32px;
}
.selectAddRoleButton i {
	float: right;
}
.editSolutionMethodDiv {
	position: relative;
	width: 12px;
}
.editSolutionMethodDiv .showMoreMenu {
	width: 154px;
	padding: 5px 10px;
	min-height: auto !important;
}
.editSolutionMethodDiv .showMoreMenu li.moreItemLi {
	background: none;
	color: #000;
	padding: 0;
	cursor: pointer;
	line-height: 22px;
}
.editSolutionMethodDiv .showMoreMenu li.moreItemLi .ellipseMenuItem {
	width: 98%;
}
.addSolMethodFieldsContainer {
	display: flex;
}
.addSolMethodFieldsCheckBoxContainer ul {
	display: flex;
	padding: 0;
	list-style: none;
	margin-top: 20px;
}
.addSolMethodFieldsCheckBoxContainer
	ul
	label.NotificationChkboxContainer
	span.CustomCheckmark {
	top: 3px;
}
.addSolMethodFieldsCheckBoxContainer
	ul
	label.NotificationChkboxContainer
	span.complexityLabel {
	margin-left: 22px;
}
.addSolMethodFieldsCheckBoxContainer ul li {
	margin-right: 15px;
}
.addSolMethodFieldsCheckBoxContainer ul label.NotificationChkboxContainer {
	font-size: 13px;
}
.addSolMethodFieldsCheckBoxContainer
	ul
	label.NotificationChkboxContainer
	.CustomCheckmark:after {
	top: -1px;
}
.addSolMethodFieldsCheckBoxContainer {
	margin-top: 20px;
}
.addSolMethodFieldsContainerMain .addSolMethodFieldsContainerDiv {
	display: flex;
}
.addSolMethodFieldsContainerMain {
	display: flex;
	flex-direction: column;
}
.addSolMethodFieldsContainerMain
	.addSolMethodFieldsContainerDiv
	.addRoleNameContainer {
	flex: 1;
}
.addSolMethodFieldsContainer .addRoleNameContainer {
	flex: 1;
}
.solutionMethodSequenceContainer {
	padding: 20px 30px;
	max-height: 78vh;
	overflow-y: auto;
}
.addSolMethod .methodPhaseLeftMenu li {
	width: 23%;
}
.showMoreMenu.hideAdminMenu {
	display: none;
}
.showMoreMenu.hideMethodEditMenu {
	display: none;
}
ul.leftNavMenu .showMoreMenu ul.menuItemUL li.moreItemLi .ellipseMenuItem {
	width: 100%;
}
.dropDowncontainer.methodType {
	width: 100%;
}
.addSolMethod .addRoleNameContainer .dropDowncontainer {
	width: 100%;
}
.addSolMethod .addRoleNameContainer .dropDowncontainer .dropDownBox {
	padding: 6px 9px !important;
}
.addSolMethod .addRoleNameContainer .dropDowncontainer .dropDownList {
	top: 37px;
}
.addSolMethod
	.addRoleNameContainer
	.dropDowncontainer
	.dropDownBox
	span.wrapHeader {
	width: 90%;
}
.SolMethodAppoverReviwereContainer .addRoleNameContainer input {
	width: 100%;
}
.SolMethodAppoverReviwereContainer
	.addRoleNameContainer
	ul.afterSaveListTopic.collaboratorsApprovers {
	border-right: 1px solid #e4e4e4;
	border-bottom: 1px solid #e4e4e4;
	border-left: 1px solid #e4e4e4;
	padding: 5px 10px;
}
.SolMethodAppoverReviwereContainer
	.addRoleNameContainer
	ul.afterSaveListTopic.collaboratorsApprovers
	i {
	font-size: 11px;
	vertical-align: middle;
	margin-left: 5px;
	font-weight: bold;
}
.addSolMethod
	.SolMethodAppoverReviwereContainer
	.addRoleNameContainer
	.dropDowncontainer
	.dropDownList {
	top: 1px;
}
.addSolMethod
	.SolMethodAppoverReviwereContainer
	.addRoleNameContainer
	.dropDowncontainer
	.dropDownList
	label.NotificationChkboxContainer {
	font-size: 13px;
}
.editSolutionMethodDiv i.extractMoreIcon:focus {
	outline: 1px dotted #fff !important;
}
.supportMessage-leftmenu {
	color: #D6D6D6;
	font-size: 14px;
	padding: 10px 15px;
	position: absolute;
    top: 30%;
}
.supportMessage-leftmenu .errorIcon {
	text-align: left;
	font-size: 30px;
	color: #61667A;
}
.leftNavContainer.collapse .supportMessage-leftmenu {
	display: none;
}
 