.mainContainer {
	display: flex !important;
	flex-direction: row;
	/* margin-top: -2px; */
	min-height: 90vh;
}

.rightSecContainer {
	margin-left: 0px;
	transition: all 0.2s ease-in;
	flex-grow: 1;
	min-height: calc(100vh - 158px);
	overflow: auto;
	height: calc(100vh - 158px);
}

.rightSecContainer.expand {
	margin-left: 0px;
	transition-duration: 200ms;
}

.welcomeGreetCont {
	width: auto;
	border: 1px solid #f3f5fe;
	border-radius: 25px;
	background-color: #f3f5fe;
	display: inline-flex;
}

.welcomeGreetInfo {
	padding: 20px;
	margin-left: 15px;
	margin-top: 30px;
	width: 78%;
}

.welcomeGreetInfo button {
	background-color: #665eff;
	border-radius: 0;
	font-size: 14px;
	color: #fff;
	padding: 15px;
	width: 180px;
	height: 40px;
	border-color: #665eff;
}

.welcomeGreetInfo button:hover {
	background-color: #665eff;
	color: #fff !important;
}

.welcomeGreetInfo button i {
	margin-left: 27px;
	vertical-align: middle;
}

.welcomeGreetInfo p {
	font-size: 12px;
	line-height: 2;
	margin-bottom: 20px;
}

.welcomeimg {
	width: 681px;
	height: 322px;
	background: url(../../assets/welcomeSDMImg.png) no-repeat;
	background-size: contain;
	margin-right: 49px;
	margin-top: 20px;
}

.customizedScroll::-webkit-scrollbar {
	width: 0 !important;
	display: none;
	-ms-overflow-style: none;
	scrollbar-width: none;
}

.customizedScroll {
	-ms-overflow-style: none;
}

.customizedScroll {
	overflow: -moz-scrollbars-none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin-bottom: 0.5rem;
	font-weight: 600;
	line-height: 1.2;
}

.pageNotFound {
	text-align: center;
	margin-top: 15%;
}