.activityPreviewButton {
	background: #f58220;
	padding: 2px 18px !important;
	background-color: #f58220 !important;
	border-color: #f58220 !important;
	color: #ffffff;
	margin-left: 20px;
	font-size: 14px !important;
}
.discardAndPreviewButton {
	float: right;
	margin-left: auto;
	margin-right: 32px;
}
.topicPreviewAndSubmitPopUp .modal-dialog {
	max-width: 1100px;
}
.topicPreviewAndSubmitPopUp .descOfActivity {
	height: 66vh;
}
.topicPreviewAndSubmitPopUp .previewDescOfActivity {
	float: left;
	margin: 5px 17px 15px 15px;
}
.topicPreviewAndSubmitPopUp .activityDescriptionDetails {
	height: 81vh;
	overflow: hidden;
	overflow-y: auto;
	padding: 0 40px !important;
}
.topicPreviewAndSubmitPopUp .modal-title {
	width: 100%;
	font-size: 21px;
	font-weight: 600;
}
.topicPreviewAndSubmitPopUp .modal-body {
	padding: 0 !important;
}
.topicPreviewAndSubmitPopUp .modal-header {
	padding: 10px;
}
.topicPreviewAndSubmitPopUp button:focus {
	box-shadow: none !important;
}

.topicPreviewAndSubmitPopUp.historicalPreview .modal-dialog {
	max-width: 1300px;
}

.topicPreviewAndSubmitPopUp .topicsCrowdSourcingContainer,
.PreviewAndSubmitPopUp.historicalPreview .topicsCrowdSourcingContainer {
	position: static;
	margin-top: 0;
	padding: 0;
	min-height: 75vh;
}
.topicPreviewAndSubmitPopUp .topicDescriptionDiv {
	height: calc(100vh - 8rem);
}
.topicPreviewAndSubmitPopUp .topicDescTitlePart,
.PreviewAndSubmitPopUp.historicalPreview .topicDescTitlePart {
	padding: 9px 1px 5px 1px;
	margin-bottom: 20px;
}
