.phaseCreationRightPopup.addTemplate.hideTemplateBuilder {
	display: none !important;
}
.phaseCreationRightPopup.addTemplate .collabaratorsParentContainers h6 {
	font-size: 14px;
	margin-bottom: 9px;
}
.phaseCreationRightPopup.addTemplate .collabaratorsParentContainers {
	margin-bottom: 21px;
}
.phaseCreationRightPopup.addTemplate .hashtagsCancelIcon {
	display: none;
}
.phaseCreationRightPopup.addTemplate
	.dropDowncontainer.hashtagsDropDownContainerWithTags {
	width: 90%;
}
.phaseCreationRightPopup.addTemplate
	.collabaratorsParentContainers
	div#topicsHashtagsInput
	p {
	font-size: 12px;
	margin-top: 4px;
}
.phaseCreationRightPopup.addTemplate
	.collabaratorsParentContainers
	button.dragDropButton {
	width: 122px;
	margin: 0 auto;
}
.phaseCreationRightPopup.addTemplate
	.collabaratorsParentContainers
	> div
	> div {
	display: flex;
	flex-direction: column;
}
.phaseCreationRightPopup.addTemplate
	.collabaratorsParentContainers
	> div.addtemplateDragDrop
	> div {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.phaseCreationRightPopup.addTemplate
	.collabaratorsParentContainers
	> div.addtemplateDragDrop
	> div
	span {
	font-size: 12px;
	text-align: center;
	margin-bottom: 6px;
}

.phaseCreationRightPopup.addTemplate
	.saveContinueDetailsContainer
	.forwardButton {
	display: flex;
	margin: 0 auto;
	float: none;
	background: #0078d4;
    color: #fff;
	border-radius: 3px;
	padding: 5px 8px;
}
.phaseCreationRightPopup.addTemplate .methodPhaseLeftMenu li {
	width: 20%;
}
#addTemplateId li {
	width: 50% !important;
}
.phaseCreationRightPopup.addTemplate .methodPhaseLeftMenu li.selectedTab {
	color: #0078d4;
	font-weight: 600;
	border-bottom: 2px solid #0078d4;
}
.dropDowncontainer.activityTemplateBuilder {
	width: 90%;
}
.dropDowncontainer.activityTemplateBuilder input {
	width: 95%;
}
.dropDowncontainer.activityTemplateBuilder span.complexityLabel {
	font-size: 13px;
	margin-left: 24px;
}
.dropDowncontainer.activityTemplateBuilder label.NotificationChkboxContainer {
	font-size: unset;
}
.dropDowncontainer.activityTemplateBuilder
	label.NotificationChkboxContainer
	.CustomCheckmark {
	top: 4px;
	left: 0;
}
.phaseCreationRightPopup.addTemplate .methodOwnerMiddleContainer {
	display: flex;
	flex-direction: column;
}
.phaseCreationRightPopup.addTemplate ul.newActivityTemplateList li {
	display: flex;
	flex-direction: column;
	font-size: 13px;
	width: 90%;
}
.dropDowncontainer.activityTemplateBuilder input {
	width: 95%;
	border: none;
}
.dropDowncontainer.activityTemplateBuilder {
	width: 90%;
	border: 1px solid #3b3d41;
}
.dropDowncontainer.activityTemplateBuilder i {
	font-size: 12px;
	margin-left: 5px;
	font-weight: 600;
}
.phaseCreationRightPopup.addTemplate .newActivityTemplateListItemIcons a {
	display: flex;
	color: inherit;
	text-decoration: none !important;
}
.phaseCreationRightPopup.addTemplate .newActivityTemplateListItemIcons a:hover {
	color: inherit;
}
.phaseCreationRightPopup.addTemplate
	.dropDowncontainer.activityTemplateBuilder
	.dropDownList {
	max-height: 400px;
}
.phaseCreationRightPopup.addTemplate
	.dropDowncontainer.activityTemplateBuilder
	.dropDownList
	ul {
	max-height: 355px;
}
.phaseCreationRightPopup.addTemplate .spinner {
	right: 31px;
	left: auto;
	top: 0;
}
.phaseCreationRightPopup.addTemplate
	.dropDowncontainer.activityTemplateBuilder
	i {
	font-size: 12px;
	margin-left: 5px;
	font-weight: 600;
	float: right;
	margin-right: 10px;
	margin-top: 6px;
}
.phaseCreationRightPopup.addTemplate
	.dropDowncontainer.activityTemplateBuilder
	input {
	width: 91%;
}
.phaseCreationRightPopup.addTemplate .spinner .spinner-border {
	width: 15px;
	height: 15px;
}
.DetailswrapperTitle.entryExit .entryCriteriaList.editEntryExitCriteria {
	display: flex;
	background: #fff;
	padding: 1px 31px;
	border-radius: 4px;
	margin: 0;
}
#addtemp{
	outline: none !important;
}
.activityBuilderPopupHeading{
	font-size: 23px;
	font-weight: 700;
}
.uploadTemplateInst{
	font-size: 12px;
}