.myContributionsContainer {
	display: flex;
	flex-direction: column;
}
.myContributionsLeftMenu {
	display: flex;
}
.myContributionsLeftMenu ul {
	margin: 0;
	list-style: none;
	padding: 7px 21px 0 30px;
}
.myContributionsLeftMenu ul li {
	padding: 8px 50px 8px 20px;
	color: #2b314c;
	font-size: 14px;
	cursor: pointer;
	border-radius: 5px;
	white-space: nowrap;
}
ul.myContributionsContentMenuTabs {
	float: left;
	padding: 8px;
	display: flex;
	margin: 0;
	padding: 0;
	list-style: none;
}
ul.myContributionsContentMenuTabs li {
	float: left;
	padding: 10px 24.5px 10px 21px;
	list-style: none;
	text-align: center;
	cursor: pointer;
	color: #666;
	font-size: 14px;
}
.myContributionsRightContent {
	width: 100%;
}
.myContributionsContentMenuContainer {
	border-bottom: 1px solid #e9e9f0;
	display: flex;
	width: 100%;
}
.myContributionListDiv .newTableChildRow .newTableRow > .newWrapper {
	border: none;
}
.myContributionListDiv .newWrapper {
	min-height: auto;
}
.myContributionListDiv .newTableChildRow.header .newColumn.newActTd {
	font-weight: 600;
}
.newColumn.date.newActTd,
.newColumn.status.newActTd {
	width: 200px;
}
.myContributionsRightContent .newColumn.date.newActTd,
.myContributionsRightContent .newColumn.status.newActTd {
	width: 170px;
}
.myContributionListDiv .newTableChildRow .newTableRow > .newWrapper:last-child {
	flex-grow: 1;
}
.activeMyContributionArtifactTab {
	background-color: #eaeaea;
	font-weight: 600;
}
.activeMyContributionCrowdSourcingTab {
	border-bottom: 3px solid #f58220;
	color: #333 !important;
	font-weight: 600;
}
.myContibutionsMainContainer {
	display: flex;
	padding-top: 20px;
}
.myContributionsContainer .phaseBreadcrumb {
	background: #fff;
	box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.2470588235294);
	width: 100%;
	margin-top: 62px;
}
.myContrTitleSearch i {
	margin-right: 10px;
	padding: 1px 1px 1px 5px;
	font-size: 10px;
}
.myContrTitleSearch input[type='text'] {
	padding: 2px;
	background-color: #f5f5f5;
	border: none;
}
.myContributionsLeftMenu ul li h6 {
	font-size: 14px;
	font-weight: 400;
	margin-bottom: 2px;
}

.myContrTitleSearch {
	display: inline-block;
	padding: 2px;
	background-color: #f5f5f5;
	margin-left: 25px;
}
li.activeMyContributionArtifactTab h6 {
	font-weight: 600 !important;
}
.rejectedStatus {
	color: #c40313;
}
.pendingStatus {
	color: #02619b;
}
.acceptedStatus {
	color: #0e7c41;
}
.myContributionPreviewContainer {
	display: block;
	flex-direction: column;
	font-size: 13px;
	padding: 5px 15px 0px 15px;
}
.myContributionPreviewContainer h6 {
	font-size: 16px;
	font-weight: 600;
	margin: 20px 10px;
}
.myContributionPreviewContainer .sidebar {
	background: none;
	border: none;
}
.noContributions {
	text-align: center;
	display: flex;
	flex-direction: column;
	margin: 60px auto;
}
.historicalPreviewContainer {
	/* margin-top: 62px !important;*/
} 
.fullWidthBreadcrumb {
	border-bottom: 1px solid #dcdada;
	width: 100%;
	margin-top: 64px;
}
.historicalPreviewContainer h4 {
	padding: 15px 10px;
}
.historicalPreviewContainer .ractMatrix {
	margin: 12px 5px 0 8px;
}
.historicalPreviewContainer .descOfActivity {
	margin: 0 !important;
	height: calc(100vh - 12.5rem);
}
.historicalPreviewContainer .topicDescriptionDiv {
	height: calc(100vh - 10rem);
}
.historicalPreviewContainer .ractMatrix {
	width: calc(100% - 18.7rem) !important;
}
.historicalPreviewContainer .nonEditable {
	width: calc(100% - 18.1rem);
	margin-left: 0;
}
.historicalPreviewContainer .ractMatrix {
	width: calc(100% - 18.7rem) !important;
}
.historicalPreviewContainer .nonEditable {
	width: calc(100% - 18.1rem);
	margin-left: 0;
}
.myContributionTemplatePreview {
	width: 100%;
}
.templateCrowdSourcingPreview .status {
	margin-top: 7px;
	font-weight: 600;
}
.templateCrowdSourcingPreview .myContributionTemplatePreview a {
	color: #02619b !important;
}
.mycontributionChildWrapper .newColumn {
	cursor: unset;
	overflow: hidden;
	text-overflow: ellipsis;
	display: flex;
}
.noContributions img {
	width: 200px;
	height: 200px;
	margin: 0 auto;
}
.myContributionPreviewContainer
	.ms-MessageBar.ms-MessageBar--warning.ms-MessageBar-multiline {
	padding: 6px;
	margin: 20px 0px;
}

.myContributionPreviewContainer
	.PreviewAndSubmitPopUp
	.activityDescriptionDetails {
	height: auto !important;
	overflow: hidden !important;
	padding: 0 !important;
}

.historicalPreviewContainer
	.myContributionHistoricalPreview
	.topicDescriptionDiv {
	height: calc(100vh - 6.9rem);
}

.historicalPreviewContainer .myContributionHistoricalPreview .descOfActivity {
	height: calc(100vh - 9.9rem);
}
.historicalPreviewContainer h4#templateHistoryPreview {
	display: inline-block;
}

.myContributionPreviewContainer .toolsHeaderEditor h6 {
	margin: 4px 3px;
}

.contribtionDocStatusMainCont {
	list-style-type: none !important;
	list-style: none !important;
	background-color: white !important;
	border: 1px solid lightgrey !important;
	width: 340px;
	right: 160px;
	box-shadow: 0px 1px 4px #ccc;
	z-index: 99;
	top: 8px;
	position: absolute !important;
	font-size: 13px;
	padding: 10px;
	min-height: 200px;
	color: #4d565c;
	/* display: none; */
}
.contribtionDocStatusMainCont_complexity {
	

	background-color: red;
    height: 11px;
    width: 10px;
    position: absolute;
    left: 25px;
    top: 26px;
    z-index: 9999;
    /* padding: 10px; */
    color: black;
}



.contribtionDocStatusArrow_complexity {
	
    width: auto;
    position: absolute;
    left: 163px;
    z-index: 9999;
    padding: 6px;
    color: black;
    margin-top: 53px;
    right: -14px;
    background: url(../../assets/beakImage.png) no-repeat;
    z-index: 10000;



	
}

.complexityMessage {
	white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 4px;
    font-size: 11px;
    margin-top: 64px;
    margin-left: 90px;
    border: 1px solid #b7a9a9;
    background-color: #fff !important;

}



.contribtionDocStatusArrow {
	width: 26px;
	height: 12px;
	background-size: contain;
	position: absolute;
	top: 5px;
	right: -19px;
	transform: rotate(90deg);
	background: url(../../assets/beakImage.png) no-repeat;
}
.contribtionDocStatusMainCont ul {
	padding-left: 0px !important;
	margin-bottom: 0px !important;
	display: flex;
	flex-direction: column;
}
.contribtionDocStatusMainCont li {
	list-style: none;
	float: left;
	display: block;
	width: 100%;
	margin-bottom: 4px;
	padding: 4px 4px 4px 8px;
}
.docStatusListCont {
	display: flex;
	padding: 10px;
	text-align: left;
}
.docStatusPersonaImage {
	float: left;
	width: 30px;
	height: 30px;
	background-size: contain;
}
.docStatusPersonaImage img {
	width: 30px;
	height: 30px;
	border-radius: 50%;
	margin: 0px;
}
span.docReviewStatusCont {
	display: flex;
	flex-direction: column;
	padding-left: 3px;
}
span.contribtionDocStatus {
	font-size: 13px;
	white-space: nowrap;
	font-weight: normal;
	margin-top: 1px;
	margin-left: 8px;
}
span.docStatusPersonaTitle {
	font-size: 13px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	margin-left: 6px;
	font-weight: bold;
}
.docStatusTime {
	font-size: 11px;
	padding: 2px 6px;
	color: #808495;
}
span.statusDottedLine {
	width: 28px;
	height: 28px;
	border: 1px dashed #707070;
	border-radius: 50%;
	margin-left: 18px;
}
.docPendingForApproval {
	display: flex;
	margin: 10px 0;
}
button.approvalHistoryButton {
	width: 119px;
	font: normal normal normal 12px / 20px Segoe UI;
	letter-spacing: -0.12px;
	color: #0178D4;
	border: none;
	padding: 4px;
	margin-left: 20px;
	position: relative;
	border-radius: 4px;
	font-weight: 500;
	opacity: 1;
}
button.approvalHistoryButton .contribtionDocStatusMainCont {
	top: 34px;
	left: 0px;
}
button.approvalHistoryButton span.contribtionDocStatusArrow {
	left: 43px;
	top: -12px;
	transform: rotate(0deg);
}
span.pendingForApprovalStatus {
	margin-left: 9px;
	margin-top: 4px;
	color: #4c87f5;
	font-weight: 600;
}

.newColumn.status.newActTd {
	position: relative;
	overflow: inherit;
}
.mycontributionChildWrapper:last-child .contribtionDocStatusMainCont,
.mycontributionChildWrapper:nth-last-child(2) .contribtionDocStatusMainCont,
.mycontributionChildWrapper:nth-last-child(3) .contribtionDocStatusMainCont,
.mycontributionChildWrapper:nth-last-child(4) .contribtionDocStatusMainCont {
	top: -152px;
}
.mycontributionChildWrapper:last-child .contribtionDocStatusArrow,
.mycontributionChildWrapper:nth-last-child(2) .contribtionDocStatusArrow,
.mycontributionChildWrapper:nth-last-child(3) .contribtionDocStatusArrow,
.mycontributionChildWrapper:nth-last-child(4) .contribtionDocStatusArrow {
	top: 162px;
}
.mycontributionChildWrapper:first-child .contribtionDocStatusMainCont,
.mycontributionChildWrapper:nth-child(2) .contribtionDocStatusMainCont,
.mycontributionChildWrapper:nth-child(3) .contribtionDocStatusMainCont,
.mycontributionChildWrapper:nth-child(4) .contribtionDocStatusMainCont {
	top: 8px;
}
.mycontributionChildWrapper:first-child .contribtionDocStatusArrow,
.mycontributionChildWrapper:nth-child(2) .contribtionDocStatusArrow,
.mycontributionChildWrapper:nth-child(3) .contribtionDocStatusArrow,
.mycontributionChildWrapper:nth-child(4) .contribtionDocStatusArrow {
	top: 5px;
}

.approvalHistory {
	font-weight: bold;
	text-align: left;
	color: #2b314c;
	font-size: 15px;
	margin-left: 10px;
	margin-bottom: 18px;
}
.approverAndReviwerPopUp .modal-dialog {
	max-width: 800px;
}
.approverAndReviwerPopUp .modal-body {
	padding: 20px 60px;
	min-height: 60vh;
}
.approverAndReviwerPopUp .modal-body p {
	font-weight: 600;
	font-size: 16px;
	margin-bottom: 30px;
}
.approverAndReviwerPopUp .modal-body ul {
	padding: 0;
	list-style: none;
}
.approverAndReviwerPopUp .modal-body ul li {
	padding: 8px 0;
	display: flex;
	font-size: 13px;
	border-top: 1px solid #e4e4e4;
}
.approverAndReviwerPopUp .modal-body ul li:last-child {
	border-bottom: 1px solid #e4e4e4;
}
.approverAndReviwerPopUp .modal-header {
	font-size: 21px !important;
	font-weight: 600;
	padding-left: 40px;
}
.approverAndReviwerPopUp .modal-body ul li span {
	flex: 1;
}
.approverAndReviwerPopUp .modal-header i {
	cursor: pointer;
	font-weight: 600;
}
.newColumn.status.newActTd.contributionsInfo.pendingStatus {
	text-decoration: underline;
	cursor: pointer;
}
.newColumn.status.newActTd.contributionsInfo.acceptedStatus {
	cursor: pointer;
}
.templateCrowdSourcingPreview
	.status.rejectedStatus
	> span:hover
	~ .tabsTitleToolTip {
	display: block;
}
.status.rejectedStatus {
	position: relative;
	cursor: pointer;
}
.tabsTitleToolTip .templateRejectionComments {
	display: flex;
	flex-direction: column;
}
.status.rejectedStatus .tabsTitleToolTip {
	width: 300px;
	left: -40px;
}
.tabsTitleToolTip .templateRejectionComments > div:first-child {
	display: flex;
}
.tabsTitleToolTip
	.templateRejectionComments
	> div:first-child
	span:first-child {
	flex: 1;
}
.newColumn.status.newActTd.contributionsInfo.rejectedStatus {
	cursor: pointer;
}
.rejectedCommentsSection {
	margin-left: 0 !important;
	width: 300px;
	background: #fff;
	display: flex;
	padding: 10px;
	box-shadow: 0px 1px 4px #ccc;
}
.templateHistoryIframe {
	display: flex;
}
.rejectedCommentsSectionPopOver {
	background-color: #fff !important;
	width: 300px !important;
	box-shadow: 0px 2px 4px #ccc;
	font-size: 13px;
	padding: 10px 15px;
	color: #333;
}
.templateRejectionComments > div > span:last-child {
	float: right;
}
.rejectedCommentsActionButton {
	color: #0078d4;
	cursor: pointer;
	display: flex;
}
.rejectedCommentsActionButton span {
	padding-top: 6px;
	display: flex;
	text-decoration: underline;
}
.rejectionCard.myRejectedComments {
	display: flex;
	padding: 15px 50px;
}
.reasonedCommentsForRejection {
	display: flex;
}
.rejectedPersonaDetails {
	display: flex;
	flex-direction: column;
}
.rejectedPersonaDetails p {
	margin: 5px;
	text-align: center;
}
.rejectedPersonaDetails p:first-child {
	font-size: 14px;
	font-weight: 600;
}
.reasonedCommentsForRejection {
	display: flex;
	flex: 1;
	border: 1px solid #e4e4e4;
	background: #fff;
	padding: 10px;
}
.rejectionCard.myRejectedComments .sideBarCard {
	margin-right: 30px;
	box-shadow: 0px 1px 3px 2px #e6e6e6;
}
p.myRejectedCommentsSection {
	flex: 1;
	margin: 0 0 0 20px;
	padding: 6px;
}
.rejectedMyContributionConatiner {
	border: 1px solid #e4e4e4;
	min-height: 60vh;
	margin: 10px 50px;
	overflow: hidden;
	overflow-y: auto;
	padding: 10px 10px 40px 15px;
}
.suggestionStatus.myContributionSuggestionStatus {
	cursor: default;
}
.rejectedCommentsActionButton i {
	font-size: 12px !important;
	margin: 8px 3px 1px 5px;
}
.templateRejectionComments > div:first-child {
	font-weight: 600;
}
.templateRejectionComments > div:last-child span:first-child {
	font-weight: 600;
}

.rejectionCard.myRejectedComments .sideBarCard .itemStatus {
	display: flex;
	line-height: 21px;
}
.newColumn.points.newActTd {
	width: 74px;
	text-align: center;
	justify-content: center;
}
/* .deliverables-container .ck.ck-reset.ck-editor.ck-rounded-corners {
	border: 1px solid #c4c4c4 !important;
} */
.deliverables-container.mycontribution-pr {
	margin-left: 0;
	margin-bottom: 20px;
}
.deliverables-container h2{
	padding: 12px;
    border: 1px solid #c4c4c4;
    border-bottom: 0;
    width: calc(100% - var(--ck-sample-sidebar-width));
    margin-bottom: 0;
	font-size: 14px;
}
