.externalAppMainDiv {
  display: flex;
  flex-direction: column;
  font-family: 'Segoe UI', sans-serif;
  margin-top: 20px;
  padding: 0 10px;
}
.commonContApps {
  margin-bottom: 15px;
  box-shadow: 2px 1px 8px 2px #0000001f;
}
.commonContApps i {
  float: right;
  margin-top: 6px;
}
.commonContApps a:focus {
  outline: 1px dotted #333;
}
.commonContApps a {
  padding: 10px;
  display: flex;
  color: #333 !important;
  text-decoration: none !important;
}
span.externalAppTitle {
  flex: 1;
}
.commonContApps a:hover {
  color: #333 !important;
  text-decoration: none !important;
}
.extImgForOse {
  float: left;
  width: 30px;
  height: 30px;
  background: url(../../assets/OSEImage.png) no-repeat;
  background-size: contain;
  margin-right: 15px;
}
.extImgForVirtuso {
  float: left;
  width: 28px;
  height: 28px;
  background: url(../../assets/virtuso.png) no-repeat;
  background-size: contain;
  margin-right: 15px;
}
.extImgForDevops {
  float: left;
  width: 30px;
  height: 30px;
  background: url(../../assets/devops.png) no-repeat;
  background-size: contain;
  margin-right: 15px;
}
.extImgForCampus {
  float: left;
  width: 28px;
  height: 27px;
  margin-right: 15px;
  margin-left: 2px;
  background: url(../../assets/campus_new.jpg) no-repeat;
  background-size: contain;
}
.forwardIcon {
  float: left;
  width: 17px;
  height: 17px;
  background-size: contain;
  margin-top: 9px;
  background: url(../../assets/ArrowForOtherTools.svg) no-repeat;
}
.msbrIcon {
  float: left;
  width: 30px;
  height: 30px;
  background: url(../../assets/MSBRIcon.png) no-repeat;
  background-size: contain;
  margin-right: 15px;
}
.copilotextract
{
  margin-left: 3px;
  margin-right: 17px;
  margin-top: 0 !important;
  height: 25px !important;
  width: 25px !important;
}
.cursorPointer
{
  cursor: pointer;
}
