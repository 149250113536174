.copilot-modal{
    overflow-x: hidden !important;
    overflow-y: hidden !important;
}
.copilot-modal .modal-dialog{
    width: 50% !important;
    height: 50% !important;
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 1px 20px #00000029 !important;
    border: 1px solid #0078D4 !important;
    border-radius: 12px !important;
    opacity: 1 !important;
    margin: 10.75rem auto;
    max-width: none;
}
.copilot-write-prompt-modal{
    overflow-x: hidden !important;
    overflow-y: hidden !important;
}
.copilot-write-prompt-modal .modal-dialog{
    width: 70% !important;
    height: 70% !important;
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 1px 20px #00000029 !important;
    border: 1px solid #0078D4 !important;
    border-radius: 12px !important;
    opacity: 1 !important;
    margin: 6.75rem auto;
    max-width: none;
}
.copilot-modal .modal-content{
    border: none !important;
    border-radius: 12px !important;
    height: 100% !important;
}
.copilot-write-prompt-modal .modal-content{
    border: none !important;
    border-radius: 12px !important;
    height: 100% !important;
}
.copilot-modal .modal-header{
    padding: 10px 20px;
}
.copilot-write-prompt-modal .modal-header{
    padding: 10px 20px;
}
.copilot-modal .modal-header .close{
    display: block;
    margin: -4px 0px;
    padding: 0px;
}
.copilot-write-prompt-modal .modal-header .close{
    display: block;
    margin: -4px 0px;
    padding: 0px;
}
.copilot-modal .modal-header .close span{
    /* font-weight: 100;
    font-size: small; */
    color: #707070 !important;
}
.copilot-write-prompt-modal .modal-header .close span{
    /* font-weight: 100;
    font-size: small; */
    color: #707070 !important;
}
.copilot-modal .modal-title{
    text-align: left;
    font: normal normal 600 14px/19px Segoe UI;
    letter-spacing: -0.14px;
    color: #333333;
    opacity: 1;
}
.copilot-write-prompt-modal .modal-title{
    text-align: left;
    font: normal normal 600 14px/19px Segoe UI;
    letter-spacing: -0.14px;
    color: #333333;
    opacity: 1;
}
.copilot-modal-body{
    height: 72%;
    padding: 5px 20px !important;
    /* overflow-y: auto;
    overflow-x: hidden; */
}
.copilot-modal-prompt{
    display: grid !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
    height: 90% !important;
    overflow-x: hidden !important;
    overflow-y: auto !important;
}
.copilot-modal-label{
    text-align: left;
    font: normal normal 600 14px/19px Segoe UI;
    letter-spacing: -0.14px;
    color: #333333;
}
.copilot-modal-body .container{
    /* padding: 10px 20px; */
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}
.copilot-modal .modal-footer{
    padding: 5px 20px;
    border-top: none;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: normal;
}
.copilot-modal-btn-generate{
    background: #ffa12d !important;
    color: #000 !important;
    border: none !important;
    font-size: 13px !important;
    padding: 5px 20px !important;
    /* margin-left: 10px !important; */
    border-radius: 4px !important;
    /* margin-bottom: 10px !important; */
}
.copilot-ai-prompt-label{
    position: absolute;
    bottom: 0;
}

.copilot-modal-btn-discard {
    border: none !important;
    font-size: 13px !important;
    padding: 5px 20px !important;
    margin-left: 10px !important;
    border-radius: 4px !important;
    background: #666 !important;
    color: #fff !important;
}
.copilot-modal-aiTextarea{
    resize: none !important;
}
.copilot-modal-aiDivArea{
    height: 39% !important;
}
.copilot-modal-write-prompt{
    height: 33% !important;
    margin-bottom: 5px;
    display: flex;
    flex-direction: column;
}
.copilot-modal-result-prompt{
    height: 60% !important;
}
.copilot-modal-result-prompt .copilot-modal-aiDivArea{
    height: 80% !important;
}
.copilot-modal-writeAPrompt{
    height: 89%;
    overflow-y: scroll;
    overflow-x: hidden;
}