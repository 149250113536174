button.activityDiscardButton.btn.btn-primary {
	background: #666 !important;
	padding: 2px 18px !important;
	background-color: #666 !important;
	border-color: #666 !important;
	font-size: 14px !important;
	color: #fff;
}

button.activityDiscardButton.btn.btn-primary:focus,
.activityPreviewButton.btn-primary:focus {
	outline: 1px dotted #333;
}

.activityPreviewButton.btn-primary {
	background: #f58220;
	padding: 2px 18px !important;
	background-color: #f58220 !important;
	border-color: #f58220 !important;
	color: #000 !important;
	margin-left: 20px;
	font-size: 14px !important;
}

.discardAndPreviewButton {
	float: right;
}

.nonEditable {
	background: #f3f3f3;
	padding: 10px;
	margin: 10px 10px 42px 10px;
}

.activityCrowdSourcing .innerContent h2 {
	padding: 10px 10px 12px 10px;
	height: 40px;
}

.activityCrowdSourcing button:focus {
	box-shadow: none !important;
}

.spinner {
	position: absolute;
	top: 50%;
	left: 50%;
}

.spinner .spinner-border {
	display: inline-block;
	vertical-align: text-bottom;
	border: 2px solid #f58220;
	border-right-color: transparent;
	border-radius: 50%;
	animation: spinner-border 0.75s linear infinite;
}

.nonEditableIcon {
	width: 22px;
	height: 22px;
	background-size: contain;
	display: inline-flex;
	vertical-align: middle;
	opacity: 0.3;
	background: url(../../assets/NonEditableOrange.svg) no-repeat;
}

.nonEditableSection {
	display: inline-flex;
	margin-left: 20px;
	vertical-align: middle;
	position: relative;
}

.nonEditableSection:hover span.nonEditableIcon,
.nonEditableSection:focus span.nonEditableIcon {
	opacity: 1;
}

.nonEditableSection:hover .nonEditableIconToolTip,
.nonEditableSection:focus .nonEditableIconToolTip {
	display: block;
}

.nonEditableIconToolTip {
	list-style-type: none !important;
	list-style: none !important;
	background-color: #000 !important;
	border: 1px solid #000 !important;
	width: 250px;
	left: 44px;
	box-shadow: 0px 1px 4px #ccc;
	z-index: 99;
	top: -2px;
	position: absolute !important;
	font-size: 12px;
	font-weight: 400;
	padding: 6px 15px;
	color: #fff;
	border-radius: 3px;
	display: none;
}

.nonEditablaToolTipArrow {
	float: left;
	width: 31px;
	height: 11px;
	background-size: contain;
	position: absolute;
	top: 10px;
	left: -22px;
	transform: rotate(-90deg);
	background: url(../../assets/TooltipBeak.png) no-repeat;
}

.activityCrowdSourcing .nonEditable {
	width: calc(100% - 18.6rem);
}

.activityCrowdSourcing .ractMatrix {
	width: calc(100% - 18.2rem);
}

.activityCrowdSourcing .topicDetailsCkEditor .sidebar {
	background: none;
	border: none;
}

li.userDeletedToolsAndGuidance span.templateEntityName {
	background: #f6cad5;
}

li.userAddedItem span.templateEntityName {
	background: #b2fbb8;
	border-top: 1px solid #4ce858;
	padding: 1px 6px;
	border-bottom: 1px solid #4ce858;
}

.addingTemplatesEditAcivity .ToolsContentContainer ul li {
	display: flex;
	padding: 3px 0;
}

.addingTemplatesEditAcivity .templateContentContainer ul li {
	padding: 3px 0;
}

.addingTemplatesEditAcivity .newActivityTemplateListItemIcons {
	margin-top: 0 !important;
}

.addingTemplatesEditAcivity .newActivityTemplateListItemIcons .previewIconBlack {
	display: block;
}

span.templateEntityName {
	width: 501px;
}

.userAddedItem #entryCriteriaSanitizer,
.userAddedItem #entryCriteriaSanitizer p {
	border-top: 1px solid #4ce858;
	/* padding: 1px 6px; */
	border-bottom: 1px solid #4ce858;
	background-color: #b2fbb8;
	width: fit-content;

}

.userDeletedToolsAndGuidance #entryCriteriaSanitizer,
.userDeletedToolsAndGuidance #entryCriteriaSanitizer p {
	background: rgba(231, 92, 127, 0.35);
	border-top: 1px solid rgba(174, 30, 66, 0.35);
	/* padding: 1px 6px; */
	border-bottom: 1px solid rgba(174, 30, 66, 0.35);
	width: fit-content;

}

#criteriaDesc p,
#entryCriteriaSanitizer p {
	margin-bottom: 0%;
}


.addingTemplatesEditAcivity .newActivityTemplateListItemIcons a i {
	margin-left: 4px !important;
	width: 10px;
	height: 19px;
	text-decoration: none !important;
	font-weight: bold;
}

.addingTemplatesEditAcivity .newActivityTemplateListItemIcons a {
	text-decoration: none !important;
	margin: 0 7px;
}

.addingTemplatesEditAcivity .newActivityTemplateListItemIcons i[data-icon-name='Cancel'] {
	font-weight: bold;
	cursor: pointer;
	margin-top: 0;
}

.addingTemplatesEditAcivity .templateContentContainer ul li div {
	display: flex;
}

.userAddedItemPreview a {
	background: #b2fbb8;
	border-top: 1px solid #4ce858;
	padding: 1px 6px;
	border-bottom: 1px solid #4ce858;
}

.criteria-tab-container {
	width: calc(100% - 18.6rem);
	margin-left: 13px;
}

ul.entry-criteria-tabs,
ul.exit-criteria-tabs {
	margin: 0px;
	padding: 0px;
	list-style: none;
}

ul.entry-criteria-tabs li,
ul.exit-criteria-tabs li {
	background: none;
	color: #222;
	display: inline-block;
	padding: 10px 10px;
	cursor: pointer;
}

ul.entry-criteria-tabs li.currentEntry,
ul.exit-criteria-tabs li.currentExit {
	background: white;
	border-bottom: 2px solid #f58220;
}

ul.entry-criteria-tabs li.currentEntry,
ul.exit-criteria-tabs li.currentExit {
	background: white;
	border-bottom: 2px solid #f58220;
}

.entry-criteria-tab-content,
.exit-criteria-tab-content {
	display: none;
	background: white;
	padding: 15px;
	border: 1px solid #F1F1F1;
	min-height: 200px;
	overflow-y: auto;
}

.entry-criteria-tab-content.currentEntry,
.exit-criteria-tab-content.currentExit {
	display: inherit;
}

.entry-criteria-tab-content.currentEntry,
.exit-criteria-tab-content.currentExit {
	display: inherit;
}

.entry-cri-main-container {
	width: 100%;
	height: 100%;
	display: flex;
	margin: 0;
	justify-content: left;
	align-items: flex-start;
	height: 200px;
	padding: 15px 30px 0 0;
}

.entryExitCriTab {
	display: flex;
}

.task-main-container {
	width: 100%;
	height: 100%;
	display: flex;
	margin: 0;
	justify-content: left;
	align-items: flex-start;
}

.task-tab-container {
	border: 1px solid #F1F1F1;
	padding: 15px 30px 15px 15px;
}

#tab-tasks .add-details {
	margin-bottom: 10px;
}

#task-section .contributorAddTemplatesButton {
	top: 10px;
}

.table-container {
	display: flex;
	flex-flow: column nowrap;
	background-color: white;
	width: 80%;
	border: 1px solid white;
}

.table-row {
	display: flex;
	flex-flow: row nowrap;
	width: 100%;
	border-bottom: 1px solid white;
	background: #f7f7fb;
}

.heading {
	background-color: #f7f7fb;
	color: #3e3e3e;
	font-weight: bold;
	pointer-events: none;
}

.row-item {
	display: flex;
	/* flex: 1; */
	font-size: 13px;
	padding: 10px;
	justify-content: left;
	align-items: left;
	transition: all 0.15s ease-in-out;
	border: 1px solid white;
}

.table-row:hover {
	background-color: #cef6d1;
	cursor: pointer;
}

/* .row-item:hover {
	cursor: pointer;
	background-color: #F0F0F0;
  /*   box-shadow: 0px 1px 4px rgba(0, 0, 0, .08); 
  } */

.row-sub-container {
	display: flex;
	flex-flow: column nowrap;
	flex: 1;
}

.row-sub-container .row-item {
	padding: 8px 0;
	border-bottom: 1px solid #dadada;
}

.table-row:last-child,
.row-sub-container .row-item:last-child {
	border-bottom: 0;
}

.row-item.edit-delete-column {
	color: #a5a5a5;
}

.edit-icon {
	margin-right: 10px;
}

.add-details select {
	width: 20%;
	padding: 8px;
	background: #f7f7f7;
	border-color: #eee;
}

.add-details .add-description {
	width: 30%;
	padding: 8px;
	background: #f7f7f7;
	border: 1px solid #eee;
	/* color: #ccc; */
	margin-right: 30px;
}

.add-details .add-descriptionTask {
	width: 30%;
	padding: 8px;
	background: #f7f7f7;
	border: 1px solid #eee;
	/* color: #ccc; */
	margin-right: 30px;
}

.add-details .add-button {
	border: 1px solid #3191e7;
	background: white;
	color: #3191e7;
	padding: 2px 15px;
	margin-right: 20px;
	/* box-shadow: 2px 2px 1px #ccc; */
}

.add-details .add-icon {
	vertical-align: middle;
	line-height: 0;
}

.criteria-output-container {
	border: 1px solid #eee;
	width: calc(100% - 18.6rem);
	margin-left: 13px;
	padding: 15px 30px 30px 0;
}

.entryCriteria-heading {
	display: flex;
	width: calc(100% - 17.6rem);
	padding-bottom: 0;
	margin-bottom: 2px;
	align-items: flex-end;
}

.entryCriteria-heading span {
	flex: 1 1;
}

.entryCriteria-heading .contributorAddTemplatesButton {
	position: relative;
	top: 50px;
}

.contributorAddTemplatesButtonTask {
	position: relative;
	top: 10px !important
}


.exitCriteria-heading {
	display: flex;
	width: calc(100% - 17.6rem);
	padding-bottom: 0;
	margin-bottom: 2px;
	align-items: flex-end;
}

.exitCriteria-heading span {
	flex: 1 1;
}

.exitCriteria-heading .contributorAddTemplatesButton {
	position: relative;
	top: 50px;
	left: 0px;
}

.addtask {
	display: flex;
	width: calc(100% - 17.6rem);
	padding-bottom: 0;
	margin-bottom: 2px;
	align-items: flex-end;
}

.addtask span {
	flex: 1 1;
}

.addtask .contributorAddTemplatesButton {
	position: relative;
	top: 12px;
	left: 7px;
}

.addtaskdetails {
	margin-bottom: 15px;
}

.addtaskdetails .add-description {
	width: 50%;
}

.addtask-edit-icon {
	float: right;
	color: #a6a6a6;
}

.addtask-edit-icon span {
	margin-right: 10px;
}

.taskList {
	display: flex;
	flex-direction: row;
}

.taskList .taskDescription {
	width: 500px;
}

.addtask span.contributorAddTemplatesButton {
	width: 136px;
}

#innerContent .entryCriteria-heading .contributorAddTemplates span.contributorAddTemplatesButton {
	width: auto;
}

.hiddenEntryCriteria {
	display: none !important;
}

.hiddenExitCriteria {
	display: none !important;
}

.addedCriteria {
	background: #cef6d1;
}

.deletedCriteria {
	background: #f6cad5;
}

#entryCriteria ul li p {
	margin-bottom: 0;
}

#exitCriteria ul li p {
	margin-bottom: 0;
}

.no-border {
	border: 0 !important;
}

.w15 {
	width: 15%;
}

.w75 {
	width: 75%;
	word-break: break-word;
}

.w10 {
	width: 10%;
}

.w90 {
	width: 90%;
	word-break: break-word;
}

.entryCriteriadropdown,
.exitCriteriadropdown {
	margin-right: 0;
}

.tooltipInfoContribution {
	position: relative;
	display: inline-block;
	margin-right: 20px;
	cursor: pointer;
}

.infoIcon {
	margin-left: 10px;
}

.tooltiptextContribution {
	visibility: hidden;
	width: 350px;
	background-color: black;
	color: #fff;
	text-align: left;
	border-radius: 3px;
	padding: 5px 0;
	position: absolute;
	font-size: 13px;
	z-index: 1;
	font-weight: normal;
	left: 31px;
	bottom: -21px;
	padding: 10px;
	box-shadow: 0px 1px 4px #ccc;
}

.tooltipInfoContribution .tooltiptextContribution::after {
	content: "";
	position: absolute;
	top: 45%;
	right: 100%;
	margin-top: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: transparent black transparent transparent;
}

.tooltipInfoContribution:hover .tooltiptextContribution {
	visibility: visible;
}

.deliverables-container {
	margin-left: 10px;
	margin-top: 0;
}

.deliverables-container .centered.topicDetailsCkEditor {
	padding: 0 !important;
}

.deliverables-container h2 {
	padding: 12px;
	border: 1px solid #c4c4c4;
	border-bottom: 0;
	width: calc(100% - var(--ck-sample-sidebar-width));
	margin-bottom: 0;
	font-size: 14px;
}

.my-unique-editor .ck.ck-tooltip {
	left: 90% !important;
}

.my-unique-editor .ck.ck-toolbar>.ck-toolbar__items {
	padding-left: 12px;
}