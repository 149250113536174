.activityAdditionCont.referActivityContainer
	.cloneActivityFiltersWrapper.hideTemplateUploadFilter {
	display: none;
}
.phaseCreationRightPopup.addNewTemplate .modal-header {
	box-shadow: 0 5px 5px -3px rgb(0 0 0 / 12%);
	margin-bottom: 5px;
}
.phaseCreationRightPopup.addNewTemplate .collabaratorsParentContainers {
	margin-bottom: 12px;
}
/* .phaseCreationRightPopup.addNewTemplate
	.dropDowncontainer.hashtagsDropDownContainerWithTags {
	min-height: 60px;
} */
.phaseCreationRightPopup.addNewTemplate button.dragDropButton {
	width: 122px;
	margin: 0 auto;
}
.phaseCreationRightPopup.addNewTemplate div.addtemplateDragDrop > div {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.phaseCreationRightPopup.addNewTemplate div.addtemplateDragDrop > div span {
	font-size: 12px;
	text-align: center;
	margin-bottom: 6px;
}
.phaseCreationRightPopup.addNewTemplate
	.activityAdditionCont.referActivityContainer
	.cloneActivityResultsWrapper {
	height: 350px;
}
.phaseCreationRightPopup.addNewTemplate
	.activityAdditionCont.referActivityContainer
	.cloneActivityFiltersWrapper
	.AddActivityCloneButtonSection {
	text-align: end;
	padding: 7px;
	background: #f3f3f3;
	margin-bottom: 0;
	margin-top: 20px;
	justify-content: flex-end;
}
.phaseCreationRightPopup.addNewTemplate
	.activityAdditionCont.referActivityContainer
	.cloneActivityFiltersWrapper
	.AddActivityCloneButtonSection
	h6 {
	float: right;
	font-weight: 400;
	font-size: 13px;
}
.phaseCreationRightPopup.addNewTemplate .saveContinueDetailsContainer {
	text-align: center;
}
.phaseCreationRightPopup.addNewTemplate
	.saveContinueDetailsContainer
	.forwardButton {
	float: none;
}
.phaseCreationRightPopup.addNewTemplate
	.activityAdditionCont.referActivityContainer
	label.NotificationChkboxContainer {
	width: 81%;
}
.phaseCreationRightPopup.addNewTemplate .collabaratorsParentContainers h6 {
	font-size: 14px;
}
.templateFocusTypeDiv {
	display: flex;
}
.templateFocusTypeDiv .collabaratorsParentContainers {
	width: 49%;
	margin-right: 15px;
}
.phaseCreationRightPopup.addNewTemplate
	.collabaratorsParentContainers
	textarea {
	width: 100%;
	font-size: 12px;
	border: 1px solid #e8e9ec;
}
.templateFocusTypeDiv .collabaratorsParentContainers:last-child {
	margin-right: 0;
}
#submitNewtemp .methodOwnerMainContainer{
	overflow: initial;
}
.templateReferSolutionMethodDropdown .wrapHeader {
	width: 164px;
   }
