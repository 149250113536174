:root {
    --ck-sample-base-spacing: 2em;
    --ck-sample-color-white: #fff;
    --ck-sample-color-green: #279863;
    --ck-sample-container-width: 1285px;
    --ck-sample-sidebar-width: 290px;
    --ck-sample-editor-min-height: 200px;
    --ck-z-default: 100;
    --ck-z-modal: calc(var(--ck-z-default) + 999);
}

body,
html {
    padding: 0;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
        'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
        'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    font-size: 16px;
    line-height: 1.5;
}

body {
    height: 100%;
    color: #2d3a4a;
}

body * {
    box-sizing: border-box;
}

a {
    color: #38a5ee;
}

/* --------- STYLES TO DISPLAY THE EDITOR BEFORE LOAD ---------------------------------------------------------------------------- */

.row-editor>ckeditor>div:first-child {
    border: 1px solid hsl(0, 0%, 70%);
}

.row-editor>ckeditor>div:first-child,
.row-editor>ckeditor {
    background: #fff;
    width: 100%;
    min-height: var(--ck-sample-editor-min-height);
}

/* We need to use an extra `<main>` because of bigger `ck-reset` specificity. */
main .ck.ck-editor {
    /* Because of sidebar `position: relative`, Edge is overriding the outline of a focused editor. */
    position: relative;
    z-index: 10;
    width: calc(100% - var(--ck-sample-sidebar-width));
}

@media only screen and (min-width: 324px) and (max-width: 873px) {
    main .ck.ck-editor {
        min-width: 200px !important;
        overflow: auto;
    }
}


.row-editor .image.image-style-side {
    float: right;
    width: 50%;
}

.ck-content .image-style-side {
    max-width: 50%;
    float: right;
    margin-left: var(--ck-image-style-spacing);
}

.ck-content .image.image_resized {
    max-width: 100%;
}

.ck-content .image.image_resized {
    display: block;
    box-sizing: border-box;
}

.ck-content .image.image_resized img {
    width: 100%;
}

.ck-content .image.image_resized>figcaption {
    display: block;
}

.row-editor .image img {
    width: 100%;
    max-width: 100%;
}

.centered {
    /* Hide overlapping comments. */
    overflow: hidden;
    margin: 0 auto;
    padding: 0 var(--ck-sample-base-spacing);
}

.centered.topicDetailsCkEditor {
    padding: 0px 0px 0px 10px !important;
}

.topicDetailsCkEditor .row {
    display: flex;
    position: relative;
    flex-wrap: unset !important;
    margin: 0 !important;
}

.row.row-info {
    display: flex;
    position: relative;
    background: hsl(0, 0%, 98%);
    border: 1px solid hsl(0, 0%, 77%);
    margin: 1em auto;
    padding: 10px;
    align-items: center;
}

button.get-data,
button.remove-license-key {
    background: #fff;
    font-size: 14px;
    font-weight: bold;
    padding: 10px 15px;
    border: 1px solid hsl(0, 0%, 77%);
    border-radius: var(--ck-border-radius);
    margin-left: 1em;
    cursor: pointer;
    transition: 200ms ease background;
}

button.get-data:hover,
button.remove-license-key:hover {
    background: hsl(0, 0%, 90%);
}

.sidebar {
    padding: 0 10px;
    position: relative;
    min-width: var(--ck-sample-sidebar-width);
    font-size: 20px;
    background: hsl(0, 0%, 98%);
    border: 1px solid hsl(0, 0%, 77%);
    border-left: 0;
    border-top: 0;
    overflow: hidden;
    /* min-height: 100%; */
    overflow-y: auto;
    /* height: 439px; */

    /* #2733. Do not overlap the left border if the sidebar is longer than content. */
    box-shadow: -1px 0 0 0 hsl(0, 0%, 77%);
}

.sidebar.narrow {
    min-width: 60px;
}

.sidebar.hidden {
    display: none;
}

.row-presence {
    width: 100%;
    border: 1px solid hsl(0, 0%, 77%);
    border-bottom: 0;
    background: hsl(0, 0%, 98%);
    padding: var(--ck-spacing-small);

    /* Make `border-bottom` as `box-shadow` to not overlap with the editor border. */
    box-shadow: 0 1px 0 0 hsl(0, 0%, 77%);

    /* Make z-index bigger than `.editor` to properly display tooltips. */
    z-index: 20;
}

.presence .ck.ck-presence-list__counter {
    order: 2;
    margin-left: var(--ck-spacing-large);
}

.row-editor>div:first-child,
/* Classic demo. */

main .ck-editor[ role='application'] .ck.ck-content {
    background: #fff;
    font-size: 1em;
    line-height: 1.6em;
    min-height: var(--ck-sample-editor-min-height);
    padding: 1.5em 2em;
    /* height: 400px; */
}

main .ck.ck-editor__main {
    overflow-x: auto;
}

/* --------- SAMPLE GENERIC STYLES ---------------------------------------------------------------------------- */

header .centered {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    min-height: 8em;
}

header h1 a {
    font-size: 20px;
    display: flex;
    align-items: center;
    color: #2d3a4a;
    text-decoration: none;
}

header h1 img {
    display: block;
    height: 64px;
}

header nav ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

header nav ul li {
    display: inline-block;
}

header nav ul li+li {
    margin-left: 1em;
}

header nav ul li a {
    font-weight: bold;
    text-decoration: none;
    color: #2d3a4a;
}

header nav ul li a:hover {
    text-decoration: underline;
}

main .message {
    padding: var(--ck-sample-base-spacing) 0;
    background: var(--ck-sample-color-green);
    color: var(--ck-sample-color-white);
}

main .message::after {
    content: '';
    z-index: -1;
    display: block;
    height: 10em;
    width: 100%;
    background: var(--ck-sample-color-green);
    position: absolute;
    left: 0;
}

main .message h1 {
    position: relative;
    padding-top: 1em;
    font-size: 2em;
}

footer {
    margin: calc(2 * var(--ck-sample-base-spacing)) var(--ck-sample-base-spacing);
    font-size: 0.8em;
    text-align: center;
    color: rgba(0, 0, 0, 0.4);
}

/* --------- RWD ---------- */
@media screen and (max-width: 800px) {
    :root {
        --ck-sample-base-spacing: 1em;
    }

    header h1 {
        width: 100%;
    }

    header h1 img {
        height: 40px;
    }

    header nav ul {
        text-align: right;
    }

    main .message h2 {
        font-size: 1.5em;
    }

    main .row .ck.ck-editor__editable[role='textbox'] {
        padding: 0.5em 1em 1em;
    }
}

.topicsCrowdSourcingContainer .ck.ck-sticky-panel .ck-sticky-panel__content_sticky {
    top: 160px !important;
}

/* @ckeditor/ckeditor5-table/theme/tablecolumnresize.css */
.ck-content .table .ck-table-resized {
    table-layout: fixed;
}

/* @ckeditor/ckeditor5-table/theme/tablecolumnresize.css */
.ck-content .table table {
    overflow: hidden;
}

/* @ckeditor/ckeditor5-table/theme/tablecolumnresize.css */
.ck-content .table td,
.ck-content .table th {
    overflow-wrap: break-word;
    position: relative;
}

/* @ckeditor/ckeditor5-table/theme/tablecaption.css */
.ck-content .table>figcaption {
    display: table-caption;
    caption-side: top;
    word-break: break-word;
    text-align: center;
    color: var(--ck-color-selector-caption-text);
    background-color: var(--ck-color-selector-caption-background);
    padding: .6em;
    font-size: .75em;
    outline-offset: -1px;
}

/* @ckeditor/ckeditor5-table/theme/table.css */
.ck-content .table {
    margin: 0.9em auto;
    display: table;
}

/* @ckeditor/ckeditor5-table/theme/table.css */
.ck-content .table table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    height: 100%;
    border: 1px double hsl(0, 0%, 70%);
}

/* @ckeditor/ckeditor5-table/theme/table.css */
.ck-content .table table td,
.ck-content .table table th {
    min-width: 2em;
    padding: .4em;
    border: 1px solid hsl(0, 0%, 75%);
}

/* @ckeditor/ckeditor5-table/theme/table.css */
.ck-content .table table th {
    font-weight: bold;
    background: hsla(0, 0%, 0%, 5%);
}

/* @ckeditor/ckeditor5-table/theme/table.css */
.ck-content[dir="rtl"] .table th {
    text-align: right;
}

/* @ckeditor/ckeditor5-table/theme/table.css */
.ck-content[dir="ltr"] .table th {
    text-align: left;
}

/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-content .todo-list {
    list-style: none;
}

/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-content .todo-list li {
    position: relative;
    margin-bottom: 5px;
}

/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-content .todo-list li .todo-list {
    margin-top: 5px;
}

/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label>input {
    -webkit-appearance: none;
    display: inline-block;
    position: relative;
    width: var(--ck-todo-list-checkmark-size);
    height: var(--ck-todo-list-checkmark-size);
    vertical-align: middle;
    border: 0;
    left: -25px;
    margin-right: -15px;
    right: 0;
    margin-left: 0;
}

/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-content[dir=rtl] .todo-list .todo-list__label>input {
    left: 0;
    margin-right: 0;
    right: -25px;
    margin-left: -15px;
}

/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label>input::before {
    display: block;
    position: absolute;
    box-sizing: border-box;
    content: '';
    width: 100%;
    height: 100%;
    border: 1px solid hsl(0, 0%, 20%);
    border-radius: 2px;
    transition: 250ms ease-in-out box-shadow;
}

/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label>input::after {
    display: block;
    position: absolute;
    box-sizing: content-box;
    pointer-events: none;
    content: '';
    left: calc(var(--ck-todo-list-checkmark-size) / 3);
    top: calc(var(--ck-todo-list-checkmark-size) / 5.3);
    width: calc(var(--ck-todo-list-checkmark-size) / 5.3);
    height: calc(var(--ck-todo-list-checkmark-size) / 2.6);
    border-style: solid;
    border-color: transparent;
    border-width: 0 calc(var(--ck-todo-list-checkmark-size) / 8) calc(var(--ck-todo-list-checkmark-size) / 8) 0;
    transform: rotate(45deg);
}

/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label>input[checked]::before {
    background: hsl(126, 64%, 41%);
    border-color: hsl(126, 64%, 41%);
}

/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label>input[checked]::after {
    border-color: hsl(0, 0%, 100%);
}

/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label .todo-list__label__description {
    vertical-align: middle;
}

/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label.todo-list__label_without-description input[type=checkbox] {
    position: absolute;
}

/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-editor__editable.ck-content .todo-list .todo-list__label>input,
.ck-editor__editable.ck-content .todo-list .todo-list__label>span[contenteditable=false]>input {
    cursor: pointer;
}

/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-editor__editable.ck-content .todo-list .todo-list__label>input:hover::before,
.ck-editor__editable.ck-content .todo-list .todo-list__label>span[contenteditable=false]>input:hover::before {
    box-shadow: 0 0 0 5px hsla(0, 0%, 0%, 0.1);
}

/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-editor__editable.ck-content .todo-list .todo-list__label>span[contenteditable=false]>input {
    -webkit-appearance: none;
    display: inline-block;
    position: relative;
    width: var(--ck-todo-list-checkmark-size);
    height: var(--ck-todo-list-checkmark-size);
    vertical-align: middle;
    border: 0;
    left: -25px;
    margin-right: -15px;
    right: 0;
    margin-left: 0;
}

/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-editor__editable.ck-content[dir=rtl] .todo-list .todo-list__label>span[contenteditable=false]>input {
    left: 0;
    margin-right: 0;
    right: -25px;
    margin-left: -15px;
}

/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-editor__editable.ck-content .todo-list .todo-list__label>span[contenteditable=false]>input::before {
    display: block;
    position: absolute;
    box-sizing: border-box;
    content: '';
    width: 100%;
    height: 100%;
    border: 1px solid hsl(0, 0%, 20%);
    border-radius: 2px;
    transition: 250ms ease-in-out box-shadow;
}

/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-editor__editable.ck-content .todo-list .todo-list__label>span[contenteditable=false]>input::after {
    display: block;
    position: absolute;
    box-sizing: content-box;
    pointer-events: none;
    content: '';
    left: calc(var(--ck-todo-list-checkmark-size) / 3);
    top: calc(var(--ck-todo-list-checkmark-size) / 5.3);
    width: calc(var(--ck-todo-list-checkmark-size) / 5.3);
    height: calc(var(--ck-todo-list-checkmark-size) / 2.6);
    border-style: solid;
    border-color: transparent;
    border-width: 0 calc(var(--ck-todo-list-checkmark-size) / 8) calc(var(--ck-todo-list-checkmark-size) / 8) 0;
    transform: rotate(45deg);
}

/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-editor__editable.ck-content .todo-list .todo-list__label>span[contenteditable=false]>input[checked]::before {
    background: hsl(126, 64%, 41%);
    border-color: hsl(126, 64%, 41%);
}

/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-editor__editable.ck-content .todo-list .todo-list__label>span[contenteditable=false]>input[checked]::after {
    border-color: hsl(0, 0%, 100%);
}

/* @ckeditor/ckeditor5-list/theme/todolist.css */
.ck-editor__editable.ck-content .todo-list .todo-list__label.todo-list__label_without-description input[type=checkbox] {
    position: absolute;
}

/* @ckeditor/ckeditor5-list/theme/list.css */
.ck-content ol {
    list-style-type: decimal;
}

/* @ckeditor/ckeditor5-list/theme/list.css */
.ck-content ol ol {
    list-style-type: lower-latin;
}

/* @ckeditor/ckeditor5-list/theme/list.css */
.ck-content ol ol ol {
    list-style-type: lower-roman;
}

/* @ckeditor/ckeditor5-list/theme/list.css */
.ck-content ol ol ol ol {
    list-style-type: upper-latin;
}

/* @ckeditor/ckeditor5-list/theme/list.css */
.ck-content ol ol ol ol ol {
    list-style-type: upper-roman;
}

/* @ckeditor/ckeditor5-list/theme/list.css */
.ck-content ul {
    list-style-type: disc;
}

/* @ckeditor/ckeditor5-list/theme/list.css */
.ck-content ul ul {
    list-style-type: circle;
}

/* @ckeditor/ckeditor5-list/theme/list.css */
.ck-content ul ul ul {
    list-style-type: square;
}

/* @ckeditor/ckeditor5-list/theme/list.css */
.ck-content ul ul ul ul {
    list-style-type: square;
}

/* @ckeditor/ckeditor5-image/theme/imageresize.css */
.ck-content img.image_resized {
    height: auto;
}

/* @ckeditor/ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized {
    max-width: 100%;
    display: block;
    box-sizing: border-box;
}

/* @ckeditor/ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized img {
    width: 100%;
}

/* @ckeditor/ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized>figcaption {
    display: block;
}

/* @ckeditor/ckeditor5-image/theme/image.css */
.ck-content .image {
    display: table;
    clear: both;
    text-align: center;
    margin: 0.9em auto;
    min-width: 50px;
}

/* @ckeditor/ckeditor5-image/theme/image.css */
.ck-content .image img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    min-width: 100%;
    height: auto;
}

/* @ckeditor/ckeditor5-image/theme/image.css */
.ck-content .image-inline {
    /*
     * Normally, the .image-inline would have "display: inline-block" and "img { width: 100% }" (to follow the wrapper while resizing).;
     * Unfortunately, together with "srcset", it gets automatically stretched up to the width of the editing root.
     * This strange behavior does not happen with inline-flex.
     */
    display: inline-flex;
    max-width: 100%;
    align-items: flex-start;
}

/* @ckeditor/ckeditor5-image/theme/image.css */
.ck-content .image-inline picture {
    display: flex;
}

/* @ckeditor/ckeditor5-image/theme/image.css */
.ck-content .image-inline picture,
.ck-content .image-inline img {
    flex-grow: 1;
    flex-shrink: 1;
    max-width: 100%;
}

/* @ckeditor/ckeditor5-image/theme/imagecaption.css */
.ck-content .image>figcaption {
    display: table-caption;
    caption-side: bottom;
    word-break: break-word;
    color: var(--ck-color-image-caption-text);
    background-color: var(--ck-color-image-caption-background);
    padding: .6em;
    font-size: .75em;
    outline-offset: -1px;
}

/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-block-align-left,
.ck-content .image-style-block-align-right {
    max-width: calc(100% - var(--ck-image-style-spacing));
}

/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-left,
.ck-content .image-style-align-right {
    clear: none;
}

/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-side {
    float: right;
    margin-left: var(--ck-image-style-spacing);
    max-width: 50%;
}

/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-left {
    float: left;
    margin-right: var(--ck-image-style-spacing);
}

/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-center {
    margin-left: auto;
    margin-right: auto;
}

/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-right {
    float: right;
    margin-left: var(--ck-image-style-spacing);
}

/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-block-align-right {
    margin-right: 0;
    margin-left: auto;
}

/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-block-align-left {
    margin-left: 0;
    margin-right: auto;
}

/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content p+.image-style-align-left,
.ck-content p+.image-style-align-right,
.ck-content p+.image-style-side {
    margin-top: 0;
}

/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-inline.image-style-align-left,
.ck-content .image-inline.image-style-align-right {
    margin-top: var(--ck-inline-image-style-spacing);
    margin-bottom: var(--ck-inline-image-style-spacing);
}

/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-inline.image-style-align-left {
    margin-right: var(--ck-inline-image-style-spacing);
}

/* @ckeditor/ckeditor5-image/theme/imagestyle.css */
.ck-content .image-inline.image-style-align-right {
    margin-left: var(--ck-inline-image-style-spacing);
}

/* @ckeditor/ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-yellow {
    background-color: var(--ck-highlight-marker-yellow);
}

/* @ckeditor/ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-green {
    background-color: var(--ck-highlight-marker-green);
}

/* @ckeditor/ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-pink {
    background-color: var(--ck-highlight-marker-pink);
}

/* @ckeditor/ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-blue {
    background-color: var(--ck-highlight-marker-blue);
}

/* @ckeditor/ckeditor5-highlight/theme/highlight.css */
.ck-content .pen-red {
    color: var(--ck-highlight-pen-red);
    background-color: transparent;
}

/* @ckeditor/ckeditor5-highlight/theme/highlight.css */
.ck-content .pen-green {
    color: var(--ck-highlight-pen-green);
    background-color: transparent;
}

/* @ckeditor/ckeditor5-block-quote/theme/blockquote.css */
.ck-content blockquote {
    overflow: hidden;
    padding-right: 1.5em;
    padding-left: 1.5em;
    margin-left: 0;
    margin-right: 0;
    font-style: italic;
    border-left: solid 5px hsl(0, 0%, 80%);
}

/* @ckeditor/ckeditor5-block-quote/theme/blockquote.css */
.ck-content[dir="rtl"] blockquote {
    border-left: 0;
    border-right: solid 5px hsl(0, 0%, 80%);
}

/* @ckeditor/ckeditor5-basic-styles/theme/code.css */
.ck-content code {
    background-color: hsla(0, 0%, 78%, 0.3);
    padding: .15em;
    border-radius: 2px;
}

/* @ckeditor/ckeditor5-font/theme/fontsize.css */
.ck-content .text-tiny {
    font-size: .7em;
}

/* @ckeditor/ckeditor5-font/theme/fontsize.css */
.ck-content .text-small {
    font-size: .85em;
}

/* @ckeditor/ckeditor5-font/theme/fontsize.css */
.ck-content .text-big {
    font-size: 1.4em;
}

/* @ckeditor/ckeditor5-font/theme/fontsize.css */
.ck-content .text-huge {
    font-size: 1.8em;
}

/* @ckeditor/ckeditor5-horizontal-line/theme/horizontalline.css */
.ck-content hr {
    margin: 15px 0;
    height: 4px;
    background: hsl(0, 0%, 87%);
    border: 0;
}