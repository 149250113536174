.methodOwnerCollaboratorTitle {
	box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.12);
	display: flex;
	width: 100%;
	padding: 12px 20px;
}
.phaseCreationRightPopup.methodOwnerCollaborator .modal-header {
	padding: 3px;
}
.phaseCreationRightPopup.methodOwnerCollaborator .modal-title.h4 {
	flex: 1;
}
.phaseCreationRightPopup.methodOwnerCollaborator .middleDetailsContainer {
	max-height: calc(100vh - 6.8rem);
	padding: 4px 35px 0;
}
