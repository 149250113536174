.myFavouritesRightPanel {
  display: flex;
  width: 100%;
  height: inherit;
}
.myFavouritesList {
  list-style: none;
  margin: 0;
  padding: 14px;
  overflow: hidden;
  overflow-y: auto;
  height: calc(100vh - 10.5rem);
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
}
.myFavouritesList li {
  display: flex;
  margin-top: 10px;
}
.myFavouritesList i {
  color: #f58220;
  vertical-align: bottom;
  line-height: 23px;
}
.myFavouritesList span {
  margin-left: 10px;
  font-size: 13px;
  line-height: 27px;
}
.myFavouritesList a {
  color: #333 !important;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
.myFavouritesList a:hover .favoriteDesc {
  text-decoration: underline;
}
.myFavouritesList a:focus {
  outline: 1px dotted #333;
}
