.custom-control-label {
	position: relative;
	margin-bottom: 0;
	vertical-align: unset !important;
}
.custom-switch {
	padding-left: 2.25rem;
}
.custom-control {
	position: relative;
	display: block;
	min-height: 1.5rem;
	padding-left: 1.5rem;
}
.custom-control-input {
	position: absolute;
	z-index: -1;
	opacity: 0;
}
.custom-control-label::after {
	position: absolute;
	top: 0.25rem;
	left: -1.5rem;
	display: block;
	width: 1rem;
	height: 1rem;
	content: '';
	background: no-repeat 50%/50% 50%;
}
.custom-switch .custom-control-label::after {
	top: calc(0.25rem + 2px) !important;
	left: calc(-2.25rem + 5px) !important;
	width: calc(1rem - 2px) !important;
	height: calc(1rem - 2px) !important;
	background-color: #adb5bd !important;
	border-radius: 0.5rem;
	transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
		box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
	transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
		-webkit-transform 0.15s ease-in-out;
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
	background-color: #f58220 !important;
	-webkit-transform: translateX(0.75rem);
	transform: translateX(0.75rem);
}
.custom-control-label::before {
	position: absolute;
	top: 0.25rem;
	left: -1.5rem;
	display: block;
	width: 1rem;
	height: 18px !important;
	pointer-events: none;
	content: '';
	background-color: #fff;
	border: #888888 solid 1px !important;
}
.custom-control-label::before,
.custom-file-label,
.custom-select {
	transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
		box-shadow 0.15s ease-in-out;
}
.custom-control-input:focus ~ .custom-control-label::before {
	box-shadow: none !important;
}
.custom-switch .custom-control-label::before {
	left: -2.25rem;
	width: 34px !important;
	pointer-events: all;
	border-radius: 1.5rem !important;
}
.custom-control-input:checked ~ .custom-control-label::before {
	color: #fff;
	border-color: #888888 !important;
	background-color: #fff !important;
	cursor: pointer !important;
}
.toggleSwitch {
	vertical-align: middle;
}
