.userDetails {
	margin-top: 30px;
	width: 100%;
	display: flex;
	flex-direction: column;
}
.userImgRound {
	width: 120px;
	height: 120px;
	border-radius: 50%;
	text-align: center;
	margin: auto;
}
.userImage {
	border-radius: 50%;
	float: left;
	width: 100px;
	height: 100px;
	margin: 2px;
	background-size: contain;
	border: 3px solid #f58220;
}
.userDetails h4 {
	display: flex;
	justify-content: center;
	margin-top: 20px;
	font-size: 18px;
}
.userActions {
	display: flex;
	list-style: none;
	flex-direction: column;
	padding: 0 20px;
	margin-top: 20px;
}
.userActions li {
	padding: 10px 10px;
	cursor: pointer;
}
.userActions li span {
	margin-right: 10px;
	font-weight: 600;
	font-size: 16px;
	vertical-align: middle;
}
.userActions li .chevronRight {
	float: right;
	font-size: 16px;
	font-weight: bolder;
	padding-top: 8px;
}
.switchUserContainer {
	display: flex;

	padding: 10px;
	background-color: #f5f9fb;
	justify-content: center;
	font-size: 14px;
}
.switchUserContainer .active {
	color: #0078d4;
}
.switchUserContainer .custom-switch .custom-control-label::after {
	background-color: #f58220 !important;
}
.switchUserContainer .custom-control-label {
	vertical-align: top !important;
}

.switchUserContainer .custom-control.custom-switch {
	margin: 0 10px;
}
.settingImage {
	float: left;
	width: 30px;
	height: 30px;
	background: url(../../assets/MySettings.png) no-repeat;
	background-size: contain;
}
.myFavoritesImage {
	float: left;
	width: 30px;
	height: 30px;
	background: url(../../assets/MyFavorites.png) no-repeat;
	background-size: contain;
}
.myContributionImage {
	float: left;
	width: 30px;
	height: 30px;
	background: url(../../assets/MyContributions.png) no-repeat;
	background-size: contain;
}
.myAchievementsImage {
	float: left;
	width: 30px;
	height: 30px;
	background: url(../../assets/MyAchievementsIcon.svg) no-repeat;
	background-size: contain;
}
.logout {
	border-top: 1px solid #d8d3d3;
	padding: 9px 20px;
	cursor: pointer;
	position: relative;
	bottom: 0;
	width: 100%;
	left: 0;
}
.logoutButton{
	position:relative !important;
	bottom:0 !important;
}
.logout i {
	margin-left: 15px;
	margin-right: 20px;
}
.userDetails h5 {
	text-align: center;
	font-size: 15px;
}
.chevronRight {
	float: left;
	width: 22px;
	height: 25px;
	background-size: contain;
	margin-top: 12px;
	margin-left: 3px;
	background: url(../../assets/ProfileArrow.svg) no-repeat;
}
.logoutIcon {
	float: left;
	width: 22px;
	height: 20px;
	background-size: contain;
	margin-right: 7px;
	margin-top: 2px;
	background: url(../../assets/LogoutIcon.svg) no-repeat;
}
.userActions li:focus {
	outline: 1px dotted #333;
	outline-offset: -7px;
}
.logout:focus {
	outline: 1px dotted #333;
}
.userActions li p {
	font-size: 13px;
	margin: 0;
}
.userActions li a {
	display: block;
	padding: 0px 0px 14px 0px;
	color: #333;
	text-decoration: none !important;
}
.adminImage {
	float: left;
	width: 30px;
	height: 30px;
	background: url(../../assets/AdminIcon.svg) no-repeat;
	background-size: contain;
}
.switchUserContainer {
	display: flex;
	padding: 10px;
	justify-content: center;
}
.switchUserContainer .custom-control-label {
	vertical-align: top !important;
}
.switchUserContainer .custom-control.custom-switch {
	margin: 0 10px;
}
.switchUserContainer > div:last-child {
	width: 101px;
}
.switchUserContainer > div:first-child {
	width: 84px;
}
