.discardAndPreviewButton{
  float: right;
  margin-left: auto;
  margin-right: 32px;
}
.PreviewAndSubmitPopUp .modal-dialog {
  max-width: 1100px;
}
.PreviewAndSubmitPopUp .descOfActivity {
  height: 66vh;
}
.PreviewAndSubmitPopUp .previewDescOfActivity{
  float: left;
    margin: 5px 17px 15px 15px;
}
.PreviewAndSubmitPopUp .activityDescriptionDetails {
  height: 81vh;
  overflow: hidden;
  overflow-y: auto;
  padding: 0 40px !important;
}
.PreviewAndSubmitPopUp .modal-title {
  width: 100%;
  font-size: 21px;
  font-weight: 600;
}
.PreviewAndSubmitPopUp .modal-body{
  padding: 0 !important;
}
.PreviewAndSubmitPopUp .modal-header{
  padding: 10px;
}
.PreviewAndSubmitPopUp button:focus{
  box-shadow: none !important;
}

.PreviewAndSubmitPopUp.historicalPreview .modal-dialog {
  max-width: 1300px;
}
.ReorderMessage {
  border-top:1px solid #4ce858;
  border-bottom:1px solid #4ce858;
  background-color: #b2fbb8;
}