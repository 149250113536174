.addNewActivityButton {
	display: flex;
}

.addNewActivityButton button {
	border: 1px solid #e4e4e4;
	padding: 3px 10px;
	border-radius: 4px;
	line-height: 28px;
	background: #f9f9f9;
	margin-top: 10px;
}

.addNewActivityButton button .phaseAddIcon {
	float: right;
	margin-left: 10px;
}

i.phaseLandEditMode {
	font-size: 12px;
	margin-left: 6px;
	vertical-align: bottom;
	cursor: pointer;
}

.deletePopup.modal .modal-dialog {
	max-width: 650px;
}

.deleteComplexity span.CustomCheckmark {
	top: 4px;
}

.deleteComplexity label.NotificationChkboxContainer {
	font-size: unset;
	margin-right: 30px;
}

.deleteComplexity span.complexityLabel {
	padding-left: 23px;
}

.deletePopupContainer {
	display: flex;
	flex-direction: column;
	padding: 10px 40px;
}

.deleteComplexity {
	display: flex;
	text-align: center;
	margin-left: 20px;
	margin-right: auto;
	/* margin-top: -10px; */
	margin-bottom: 20px;
}

.deletePopupdelinkContainer li {
	/* list-style: none; */
}

.deletePopupContainer p {
	font-weight: 400;
}

.deletePopupButton {
	text-align: center;
}

.deletePopupButton button {
	margin-right: 20px;
	padding: 3px 20px;
	font-size: 12px;
}

.deleteComplexity .NotificationChkboxContainer .CustomCheckmark:after {
	top: -1px;
}

.deletePopupdelinkContainer ul {
	/* padding: 0; */
}

.deletePopupContainer h5 {
	margin-bottom: 20px;
}

.deletePopupButton {
	text-align: center;
	margin: 10px;
}

.deletePopupButton button.templateRejectButton {
	background: #f2f2f2;
	color: #0078d4;
	border: 1px solid #edebe9;
}

.deletePopup.modal .modal-dialog .modal-header {
	font-weight: 600;
}

.deletePopup.modal .modal-dialog .modal-header i {
	cursor: pointer;
}

.activityRearrange {
	float: left;
	width: 25px;
	display: inline-block;
	height: 25px;
	margin-right: 8px;
	background-size: contain;
	background: url(../../assets/ActivityRearrange.svg) no-repeat;
}

.exitEditMode {
	float: left;
	width: 25px;
	display: inline-block;
	height: 25px;
	margin-right: 8px;
	background-size: contain;
	background: url(../../assets/ExitEditMode.svg) no-repeat;
}

span.activityEditArrangeMode>span:first-child {
	float: left;
	margin-right: 10px;
}

span.activityEditArrangeMode {
	float: right;
	cursor: pointer;
}

span.activityEditArrangeMode span {
	font-size: 12px;
	font-weight: 400;
	line-height: 28px;
}

.phasePublishModalDiv {
	max-height: 400px;
	overflow: hidden;
	overflow-y: auto;
	margin-bottom: 10px;
}

.phasePublishModal .modal-dialog {
	max-width: 1000px !important;
}

.publishNowOrLaterDiv ul {
	display: flex;
	list-style: none;
	padding-left: 20px;
}

.publishNowOrLaterDiv>div {
	display: flex;
}

.publishNowOrLaterDiv>div>div {
	margin-right: 20px;
}

.publishNowOrLaterDiv>div>div>span {
	margin-right: 10px;
	line-height: 34px;
}

.phasePublishButtons {
	text-align: center !important;
}

.phasePublishButton button {
	background: #f5f5f5;
}

.phasePublishButton button {
	border: none;
	background: #f5f5f5;
	padding: 5px 10px;
	border-radius: 4px;
	margin-top: 15px;
}

.dateAndTimeJoneDiv .timeJoneDivDropdown {
	width: 200px;
}

.dateAndTimeJoneDiv .timeJoneDivDropdown>div>div>span {
	display: none;
}

.dateAndTimeJoneDiv .timeJoneDivDropdown>div {
	min-height: 10px;
}

.dateAndTimeJoneDiv.timeZoneDiv {
	flex-basis: 35%;
}

.phasePublishModalDiv p {
	font-weight: 600;
}

.phasePublishModalDiv ul {
	list-style: none;
	padding-left: 20px;
	margin-left: 0;
	margin-bottom: 20px;
}

.laterPublishingActivitiesSection h6 {
	margin: 0 0 15px 0 !important;
	font-weight: 600 !important;
}

.publishNowOrLaterDiv .phasePublishButtons button:last-child {
	background: #0078d4;
	color: #fff;
	padding: 6px 35px;
}

.phasePublishModal .phasePublishButton {
	text-align: center;
	margin-top: 33px;
}

.phasePublishModal .phasePublishButton button {
	padding: 6px 40px;
	color: #0063af;
}

.dateAndTimeJoneDiv {
	display: flex;
	white-space: nowrap;
}

.publishNowOrLaterDiv ul label.checkBoxContainer .settingsLabel {
	font-weight: 600;
}

.react-datepicker-wrapper input {
	padding: 5px;
	width: 145px;
	border: 1px solid #ccc;
}

.react-datepicker-wrapper {
	width: 145px;
}

.dropDowncontainer.phasePublishTimePicker ul {
	display: flex;
	flex-direction: column;
	max-height: max-content;
}

.dropDowncontainer.phasePublishTimePicker .dropDownList {
	max-height: 200px;
	overflow-y: auto;
}

.dropDowncontainer.phasePublishTimePicker .dropDownBox {
	padding: 5px 9px !important;
	margin-top: 0;
}

.dropDowncontainer.phasePublishTimePicker {
	width: 122px;
	margin-right: 0;
}

.dropDowncontainer.phasePublishTimePicker .wrapHeader {
	width: 80px;
}

.phasePublishModal input#react-select-2-input {
	width: 150px !important;
	font-size: 12px !important;
	color: black !important;
}

.css-yk16xz-control,
.css-1pahdxg-control {
	min-height: 32px !important;
}

.css-tlfecz-indicatorContainer,
.css-1gtu0rj-indicatorContainer,
.css-1gtu0rj-indicatorContainer {
	padding: 2px 8px !important;
}

.css-yk16xz-control {
	width: 125px;
}

.phasePublishButtons.schedule {
	margin-left: -45px;
}

.cancelSchedulePublishDiv p {
	font-weight: 600;
}

.cancelSchedulePublishDiv>div {
	display: flex;
	margin-top: 30px;
	justify-content: center;
}

.cancelSchedulePublishDiv>div button {
	background: #f5f5f5;
	color: #0072c9;
	border: none;
	padding: 5px 45px;
	margin-right: 15px;
	font-size: 12px;
	border-radius: 4px;
}

.cancelSchedulePublishDiv>div button:last-child {
	background: #0078d4;
	color: #fff;
	padding: 5px 16px;
}

.solMethodDescrOnLandPage .ms-MessageBar-content .ms-MessageBar-icon i {
	margin-top: 4px;
	font-weight: bold;
}

li.notPublishActivity a {
	color: #0078d4;
}

.addNewPhaseButtonContainer label.NotificationChkboxContainer .CustomCheckmark:after {
	top: -1px;
}

._toggleActivityDetails {
	background: none;
	border: none;
	margin-left: 5px;
	display: flex !important;
	justify-items: center;
	align-items: center;
}

._iconExpandOnNonRealized {
	background-image: url('/src/assets/iconExpandOnNonRealized.svg');
	background-size: contain;
	background-repeat: no-repeat;
	width: 24px;
	height: 24px;
	display: inline-block;
}

._iconCollapseOnNonRealized {
	background-image: url('/src/assets/iconCollapseOnNonRealized.svg');
	background-size: contain;
	background-repeat: no-repeat;
	width: 24px;
	height: 24px;
	display: inline-block;
}

._rowOfComplexityButtonsOnNonRealized {
	background: white !important;
	padding-left: 18px;
	padding-top: .5rem !important;
	margin-left: 7px !important;
}

._leftSideForNonRealized {
	overflow-y: scroll;
	overflow-x: hidden;
	height: calc(100vh - 173px);
	background: white;
	scrollbar-width: thin;
	scrollbar-color: transparent transparent;
}

._leftSideForNonRealized:hover {
	scrollbar-width: thin;
	scrollbar-color: #aaa #fff;
}