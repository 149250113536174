.siteDownMainContainer {
    display: flex;
}
.siteDownContainer {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 34%;
    top: 30%;
}
.downTimeImage{
    width: 670px;
    height: 300px;
    background: url(../../assets/SDMDowntime.gif) no-repeat;
    background-size: contain;
}
.technicalSiteDown{
    width: 570px;
    height: 380px;
    background: url(../../assets/SDMTechnicalError.gif) no-repeat;
    background-size: contain;
    display: none;
}