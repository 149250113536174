.activityLandinPage {
	display: flex;
	flex-direction: column;
	height: calc(100vh - 111px) !important;
}


.activityMainContainer {
	display: flex;
	font-family: 'Segoe UI', sans-serif;
	padding: 0px !important;
	flex: 1 1;
	font-size: 13px;
}

.activityDescriptionDetails {
	flex: 1 1;
	order: 1;
	padding: 0 !important;
	background: #fff;
}

.activityYammerFeedContainer {
	padding: 0 !important;
	width: 300px;
	order: 3;
	display: flex;
	flex-direction: column;
	background: #fff;
	border-left: 1px solid #dcdada;
	position: relative;
}

.activityYammerFeedContainer h4 {
	display: inline-block;
	font-size: 16px;
	float: left;
	margin: 0;
	flex: 1;
}

.activityYammerTitle {
	border-bottom: 1px solid #dcdada;
	display: flex;
	padding: 12.5px 11px;
	color: #0168b7;
	background: #f3f2f2;
}

.activityYammerTitle .yammerLogout {
	margin-right: 0;
	margin-top: 0;
}

/* .activityYammerFeedContainer i {
	float: right;
	margin-top: 10px;
} */
.activityDescTitlePart {
	padding: 7px 15px;
	float: left;
	border-bottom: 1px solid #dcdada;
	width: 100%;
	text-align: left;
}

.activityDescTitlePart h4 {
	font-size: 18px;
	display: inline-block;
	margin-right: 10px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	line-height: normal;
	margin-bottom: 0;
	flex: 1;
}

.activityEdit {
	display: inline-block;
	float: right;
	border: 1px solid #f58220;
	margin-right: 10px;
	color: #000;
	background: #f58220;
	border-radius: 4px;
	line-height: normal;
}

.BreadCrumbs {
	color: #c00000;
	padding: 8px 0 0 19px;
}

.complexity {
	background: #f2f2f2;
	color: #0070c0;
	margin-left: 0px;
	font-family: 'SEGOEUIBOLD';
}

.page-heading {
	background: #f2f2f2;
	color: #0070c0;
	padding: 0px;
	font-family: 'SEGOEUIBOLD';
}

.raciData {
	background: #f2f2f2;
	font-weight: 600;
	line-height: 23px;
}

.activityDescTitlePart .activityVersion {
	float: left;
	font-size: 12px;
	line-height: 30px;
	color: #666;
}

.activityComplexity>span {
	line-height: 24px;
}

.activityDescTitlePart h4 {
	float: left;
}

.activityDescTitlePart .modifiedDate {
	float: left;
	margin-left: 10px;
	font-size: 12px;
	line-height: 27px;
	color: #666;
}

.activityComplexity {
	float: left;
	margin-left: 20px;
	line-height: 19px;
	margin-top: 3px;
}

.activityComplexity>div {
	float: right;
	margin-left: 8px;
}

.activityDescTitlePart .dropDownList {
	left: 0;
	top: 27px;
}

.activityComplexity .dropDownBox {
	margin-top: 0 !important;
	padding: 1px 9px !important;
}

.activityComplexity .dropDownBox i {
	margin-top: 1px;
}

.innerContent h2 {
	font-size: 16px;
	font-weight: 600;
}


.ck-header h1 {
	font-size: 34px !important;
}

.ck-header h2 {
	font-size: 32px !important;
}

.ck-header h3 {
	font-size: 26px !important;
}

.ck-header .sub-heading {
	font-size: 16px !important;
}

.activityLandinPage .phaseBreadcrumb {
	border-bottom: 1px solid #dcdada;
	width: 100%;
}

.activityRaciDetails {
	float: left;
	width: 25%;
	min-height: 90px;
	padding: 0 5px;
}

.ractMatrix {
	background: #f9f9f9;
	width: 100%;
	float: left;
	margin-top: 12px;
	padding: 20px;
}

.activityRaciDetails .subHeading {
	font-weight: bolder;
	line-height: 29px;
	font-size: 13px;
}

.ractMatrix .raciMsg {
	font-size: 10px;
	margin-top: 20px;
	float: left;
	font-style: italic;
}

.innerContent {
	margin: 30px 1px 15px 0px;
	float: left;
	width: 100%;
	display: flex;
	flex-direction: column;
}

.editIcon {
	width: 16px;
	height: 17px;
	background-size: contain;
	background: url(../../assets/suggestEdit.svg) no-repeat;
	float: right;
	margin-left: 8px;
	margin-top: 2px;
}

@media screen and (-ms-high-contrast: active),
screen and (forced-colors: active) {
	.editIcon {
		width: 16px;
		height: 17px;
		background-size: contain;
		background: url(../../assets/icon-Suggest-Edit.svg) no-repeat !important;
		float: right;
		margin-left: 8px;
		margin-top: 2px;
	}
}

.descOfActivity * {
	max-width: calc(98.5vw) !important;
}

.descOfActivity {
	height: calc(100vh - 12rem);
	width: calc(100vw - 15px) !important;
	overflow: hidden;
	overflow-y: auto;
	float: left;
	margin: 0px -4px 0px 15px;
	padding-right: 40px;
}

@media only screen and (min-width: 323px) and (max-width: 649px) {
	.descOfActivity {
		height: 100vh !important;
	}
}

.activityCrowdSourcing .descOfActivity {
	padding-right: 15px;
}

.noContent {
	display: none;
}

div#activity-embedded-feed {
	flex-grow: 1;
}

h4.activityLandingPageTitle {
	font-size: 18px;
	display: block;
	margin-right: 10px;
	line-height: 23px;
	margin-bottom: 4px;
	margin-top: 25px;
	font-weight: 600;
}

h1.activityLandingPageTitle {
	font-size: 20px;
}

/*.descOfActivity:hover {
  overflow-y: overlay;
}*/
.WarningMessageActivityPage .ms-MessageBar-text {
	padding: 10px;
}

.WarningMessageActivityPage .ms-MessageBar-text span {
	white-space: normal;
}

.WarningMessageActivityPage .ms-MessageBar.ms-MessageBar--warning {
	border-radius: 5px;
}

.WarningMessageActivityPage .ms-MessageBar-icon {
	padding: 12px 0;
}

.activityEdit a {
	background: #DE6804;
	text-decoration: none !important;
	display: flex;
	padding: 4px 6px;
	color: #000 !important;
}

.activityEdit.disabledEditActivity a {
	pointer-events: none;
}

.marker-green {
	background-color: #63f963;
}

.marker-yellow {
	background-color: #fdfd77;
}

.marker-pink {
	background-color: #fc7999;
}

.marker-blue {
	background-color: #72cdfd;
}

.pen-red {
	color: #e91313;
	background-color: transparent;
}

.pen-green {
	color: #180;
	background-color: transparent;
}

.activitySampleButton {
	border: none;
	background: transparent;
	color: #007bff;
}

.activitySampleButton:hover {
	text-decoration: underline;
}

.activityCrowdSourcing .activityTitleRightPart {
	width: calc(100% - 314px);
	display: flex;
	border-right: 1px solid #dcdada;
	padding: 7px 0px 7px 15px;
}

.approverApproveEditButton.btn-primary {
	background: #F58220;
	padding: 2px 18px !important;
	background-color: #F58220 !important;
	border-color: #F58220 !important;
	color: #000000 !important;
	font-size: 13px !important;
	float: right;
}


.approverApproveEditButtonActivity.btn-primary {
	background: #F58220;
	background-color: #F58220 !important;
	border-color: #F58220 !important; 
	   color: #000000 !important;
	   font-size: 13px !important;
	   float: right;
	   width: 118px;
	   height: 28px;
	   align-items: center;
	   justify-content: center;
	   display: inline-flex;
   }

.buttonFixedLength{
	background: #F58220;
	padding: 2px 18px !important;
	background-color: #F58220 !important;
	border-color: #F58220 !important;
	color: #000000 !important;
	font-size: 13px !important;
	float: right;
}

.approverApproveEditButtonPadding {
	padding-top: 1px !important;
}

.noClick {
	pointer-events: none;
	text-decoration: none;
	color: #333 !important;
}

.activityCrowdSourcing .activityDescTitlePart {
	padding: 0;
}

.approverMainWrapper {
	display: flex;
	padding: 10px;
	border: 1px solid #f2f2f2;
	box-shadow: -2px -1px 10px 0px #0000001f;
	margin: 15px 0;
}

.approverMainWrapper p {
	margin: 0;
	padding-left: 14px;
	flex: 1;
	line-height: 26px;
	color: #000;
}

.activityCrowdSourcing .WarningMessageActivityPage {
	width: calc(100% - 18.2rem);
}

.historicalPreview .nonEditable {
	width: calc(100% - 18.1rem);
	margin-left: 0;
}

.historicalPreview .ractMatrix {
	width: calc(100% - 18.2rem);
}

.historicalPreview .sidebar {
	border: none;
	background: none;
}

.historicalPreview .centered.topicDetailsCkEditor {
	padding: 0px 0px 20px 0px !important;
}

.aprroverStatusIcon {
	width: 25px;
	height: 25px;
	background: url(../../assets/approverInfoIcon.svg) no-repeat;
	float: left;
	background-size: contain;
}

.inheritedCollaboratorsIcon {
	margin-left: 5px;
	margin-top: 3px;
	width: 20px;
	height: 20px;
	background: url(../../assets/inheritedCollaboratorsIcon.svg) no-repeat;
	float: left;
	background-size: contain;
	padding-right: 30px;
}

.inheritedCollaboratorsIconRounded {
	margin-left: 5px;
	margin-top: 3px;
	width: 20px;
	height: 20px;
	background: url(../../assets/inheritedCollaboratorsIconRounded.svg) no-repeat;
	float: left;
	background-size: contain;
	padding-right: 30px;
}

.inheritedCollabaratorsLegend {
	height: 55px;
	color: #333;
	margin-bottom: 16px;
	background: #fdf4d2;
	padding: 4px;
	font-weight: 600;
	border-radius: 6px;
}

.WarningMessageActivityPage {
	margin: 30px 0 18px 0;
}

.approverMainWrapper {
	display: flex;
	padding: 10px;
	border: 1px solid #f2f2f2;
	box-shadow: -2px -1px 10px 0px #0000001f;
}

.approverMainWrapper p {
	margin: 0;
	padding-left: 14px;
	flex: 1;
}

.activityCrowdSourcing .WarningMessageActivityPage {
	width: calc(100% - 18.2rem);
}

.WarningMessageActivityPage a {
	color: #0062ad;
}

p.reviewerNameLabel {
	display: inline-block;
	margin-left: 30px;
	padding: 4px 13px;
	background: #ffa12d;
	margin-bottom: 0;
	font-size: 16px;
	border-radius: 5px;
	color: #000;
	margin-top: 7px;
}

.sideBarCard.acceptedSideBarCard {
	background: rgb(206, 246, 209);
	font-size: 14px;
	padding-top: 6px;
}

span.suggestionStatus {
	float: right;
	font-size: 11px !important;
	font-weight: bold;
	
}
span.suggestionStatusLatest{
	margin-right: 18px !important;
    margin-top: 4px !important;
}

.sideBarCard.acceptedSideBarCard .suggestionStatus {
	color: #039817;
	opacity: 0.6;
}

.sideBarCard.acceptedSideBarCard .suggestionStatus:hover {
	opacity: 1;
}

.sideBarCard.rejectedSideBarCard {
	background: rgb(245, 200, 210);
	font-size: 14px;
	padding-top: 6px;
}

.sideBarCard.rejectedSideBarCard .suggestionStatus {
	color: rgb(239, 2, 60);
	opacity: 0.6;
}

.sideBarCard.rejectedSideBarCard .suggestionStatus:hover {
	opacity: 1;
}

.toolsHeaderEditor .dropDownBox {
	margin-top: 0;
}

.toolsEditorMainContainer {
	border: 1px solid #c4c4c4;
	flex: 1;
}

.historicalPreview .toolsEditorMainContainer {
	width: 100px
}

.toolsSidebar {
	display: flex;
	padding: 43px 4px 1px 4px;
	flex-direction: column;
	overflow: hidden;
	overflow-y: auto;
	width: 286px;
	min-height: 200px;
}
.toolsSidebar_Latest{
	display: flex;
	flex-direction: column;
	overflow: hidden;
	overflow-y: auto;
	min-height: 200px;
}

.activityCrowdSourcing .toolsSidebar {
	width: 291px;
}

.activityDetailsHasttagInput {
	display: flex;
	padding: 7px 0;
	margin: 1px;
}

.hashtagsCancelIcon {
	position: relative;
	display: flex;
	max-height: 35px;
}

.hashtagsCancelIcon i:hover~.tabsTitleToolTip {
	display: block;
}

.hashtagsCancelIcon .tabsTitleToolTip {
	left: -43px;
	top: 37px;
}

.hashTagsWithCancelCheckMark .hashtagsCancelIcon i {
	font-size: 16px;
	border-top: 1px solid #3b3d41;
	border-bottom: 1px solid #3b3d41;
	border-right: 1px solid #3b3d41;
	padding: 4px 8px;
	cursor: pointer;
}

.activityDetailsHasttagInput input {
	flex: 1;
	border: 1px solid #e4e4e4;
	padding: 4px;
	font-size: 12px;
}

.activityDetailsHasttagInput i {
	font-size: 16px;
	border: 1px solid #e4e4e4;
	padding: 4px 8px;
	cursor: pointer;
}

.existingHashTagsOfActivity span {
	color: #0078d4;
	margin-right: 10px;
	cursor: pointer;
}

.addHashtagsIcon {
	padding-inline: 2px;
	border: 1px solid #110f0f;
}

.existingHashTagsOfActivity {
	display: flex;
	font-size: 13px;
}

.descOfActivity div#innerContent a {
	color: #0078d4;
}

.ck.ck-balloon-panel.ck-balloon-panel_visible {
	z-index: 9999;
}

.existingHashTagsOfActivity {
	display: flex;
	font-size: 13px;
	flex-wrap: wrap;
}

.existingHashTagsOfActivity span:hover {
	text-decoration: underline;
}

.PreviewAndSubmitPopUp .table td,
.PreviewAndSubmitPopUp .table th,
.descOfActivity .table td,
.descOfActivity .table th,
.Detailswrapper .table td,
.Detailswrapper .table th {
	border: 1px solid #000;
}

.PreviewAndSubmitPopUp.activityPreviewAndSubmitModal .ck.ck-sticky-panel__content {
	display: none;
}

.PreviewAndSubmitPopUp.activityPreviewAndSubmitModal .sidebar {
	display: none;
}

.PreviewAndSubmitPopUp.activityPreviewAndSubmitModal .ck-blurred.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-read-only {
	border: none;
	padding: 0;
	min-height: auto;
}

.activityLandinPage.activityCrowdSourcing h2#deliver {
	display: flex;
	width: calc(100% - 17.6rem);
	padding-bottom: 0;
	margin-bottom: 2px;
}

.activityLandinPage.activityCrowdSourcing h2#deliver span {
	flex: 1;
}

h2#deliver .contributorAddTemplates {
	display: flex;
	background: #f9f9f9;
	line-height: 40px;
	font-size: 12px;
	box-shadow: 0px 3px 6px #0000001c;
	border-top-left-radius: 4px;
}

h2#deliver span.contributorAddTemplatesButton {
	display: flex;
	white-space: nowrap;
}

h2#deliver .contributorAddTemplates>span:first-child {
	white-space: nowrap;
	line-height: 48px;
	margin-right: 10px;
}

.activityLandinPage.activityCrowdSourcing h2#deliver .contributorAddTemplates span.delTemplateAccessabilityIcon {
	margin-top: 7px;
}

.activityLandinPage.activityCrowdSourcing h2#deliver .contributorAddTemplates span.contributorAddTemplatesButton {
	margin-top: 0;
	margin-left: 10px;
	background: #fff;
}

.entryExit .entryCriteriaList ul li .criteriaTable>div.criteriaType {
	text-align: left;
}

.addingTemplatesEditAcivity .toolsHeaderEditor h6 {
	font-weight: 600;
}

.templateEditDownloadIcon {
	width: 18px;
	height: 18px;
	background: url(../../assets/TemplateDownload.svg) no-repeat;
	float: left;
	background-size: contain;
}

.activityRaciRoleGroupContainer .activityChildContainer {
	border-left: 0;
	background: #edf1f8;
	font-size: 12px;
}

.activityRaciRoleGroupContainer .activityListMainContainer span.titleOfActivity {
	padding: 5px 5px 5px 0px;
}

.activityRaciRoleGroupContainer .activityListMainContainer {
	background: #edf1f8;
	margin-top: 0;
	margin-bottom: 5px;
}

.activityRaciRoleGroupContainer .activityListMainContainer .activityTitle i {
	color: #000;
	padding: 4px 0px 4px 5px;
	font-size: 13px;
}

.activityRaciRoleGroupContainer .activityChildContainer .titleOfActivity a {
	font-size: 12px;
}

.activityRaciRoleGroupContainer .activityChildContainer .titleOfActivity {
	padding: 4px 5px 4px 23px !important;
}

.activityRaciRoleGroupContainer {
	max-height: 200px;
	overflow-y: auto;
}

.activityChildContainer.orphanRole {
	background: none;
}

.activityChildContainer.orphanRole .activityChildTitle {
	width: 100%;
	font-size: 12px;
}

.activityRaciRoleGroupContainer .activityRaciRoleGroupContainer {
	font-size: 12px;
}

.activityRaciRoleGroupContainer .activityContainer.activityGroup:focus {
	outline-offset: -1px;
}

.activityRaciRoleGroupContainer .activityContainer.activityGroup {
	border-radius: 4px;
}

.activityCrowdSourcing .customToolsEditor span.complexityLabel {
	margin-left: 0;
}

.raciParentContainers .dropDowncontainer.treeView label.NotificationChkboxContainer span.CustomCheckmark {
	border: 1px solid #3191e7;
}

.activityRaciRoleGroupContainer .activityListMainContainer span.titleOfActivity {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	display: block !important;
}

.activityBladeRaciButton i {
	padding: 4px 0 3px 5px;
}

.activityRaciRoleGroupContainer .activityContainer.activityGroup .activityTitle span.titleOfActivity {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	display: block !important;
}

#containerId.activityRaciRoleGroupContainer {
	height: auto !important;
}

.hashTagsWithCancelCheckMark .hashtagsCancelIcon i:focus {
	outline-offset: -1px;
}

.activityCrowdSourcing .customToolsEditor .dropDownList li input[type='checkbox']:focus {
	margin: 3px 0 0 2px !important;
	width: 15px !important;
}

.activityCrowdSourcing .toolsAndGuidanceMultiSelectFilter .CustomCheckmark {
	left: 2px;
}

.addTemplate label.NotificationChkboxContainer input[type='checkbox']:focus {
	margin: 5px 0 0 0px !important;
}

span.ActivityDeleteContainer:focus {
	outline-offset: -1px;
}

.ractMatrix .activityRaciRoleGroupContainer .activityChildContainer .titleOfActivity {
	white-space: normal;
}

.historyActivity {
	height: 100vh;
}

.ownerImg {
	/* background: url(../../assets/owner-icn.svg) no-repeat; */
	width: 36px;
	height: 36px;
	margin-right: 5px;
	cursor: pointer;
	display: block;
	border-radius: 25px;
}

.collaboratorsImg {
	background: url(../../assets/Collab-Icn.svg) no-repeat;
	width: 35px;
	height: 35px;
	cursor: pointer;
}

.imgContainer {
	border: 1px solid white;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	height: 50px;
	margin-right: 25px;
}

.ownerInfo {
	display: flex;
	flex-direction: column;
	padding-right: 14px;
	border-right: 2px solid #cac4c4;
	margin-right: 10px;
}

/* .imgContainerSolutionMethod img{
	width:30px;
	height: 30px;
} 
.collaboratorsImgSolutionMethod{
	width:30px !important;
	height: 30px !important;
	margin-right: 15px !important;
}
.ownerInfoSolutionMethod span{
	font-size: 12px;
}  */
.ownerImgVideo {
	background: url(../../assets/owner-icn.svg) no-repeat;
	width: 35px;
	height: 35px;
	margin-right: 5px;
	cursor: pointer;
	display: block;
}

.blankSpaceForActivityIcon {
	height: 20px;
	width: 13px;
	white-space: pre;
}

.blankSpaceForMatrixViewActivityIcon {
	height: 20px;
	width: 13px;
	white-space: pre;
}

._activityMainContainer {
	background: #FFFFFF 0% 0% no-repeat padding-box;
	opacity: 1;
}




._complexityContainer>span {
	justify-items: center;
	text-align: left;
	font: normal normal normal 14px/19px Segoe UI;
	letter-spacing: -0.14px;
	color: #333333;
	opacity: 1;
	padding-bottom: 3px;
	padding-right: 7px;
}

._complexityContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding-bottom: 6.5px;
}

._complexityContainerUpdateForRealize {
	padding-bottom: 2px !important;
	padding-top: 6px !important;
	padding-left: 26px !important;
}

._rowGray {
	background: #F5F5F5 !important;
	opacity: 1;
	padding-bottom: 0.15rem !important;
}

._RealizedDivStyle {
	padding-bottom: 4px !important;
	padding-top: 6px !important;
}

._rowGrayUpdatedForNonRealized {
	padding-left: 8px;
}

._rowWhite {
	background: #FFFFFF !important;
	padding-inline: 10px;
}

._complexityButtonsContainer {
	display: flex;
	align-items: center;
	background: #FFFFFF 0% 0% no-repeat padding-box;
	box-shadow: 0px 0px 4px #00000029;
	border-radius: 18px;
	opacity: 1;
	width: 286px;
	height: 29px;
	padding-inline: 2px;
}

._complexityButtons {
	background: #FFFFFF 0% 0% no-repeat padding-box;
	padding: 5px 31px 6px 31px;
	width: 164px;
	height: 25px;
	text-align: left;
	font: normal normal normal 13px/10px Segoe UI;
	letter-spacing: -0.14px;
	color: #C0C0C0;
	opacity: 1;
	border-radius: 18px;
	border: none;
}

._activeComplexityButtons {
	background: #0178D4;
	color: #FFFFFF;
	width: 150px;
	font-weight: 600;
}

._activityVersion {
	text-align: left;
	font: normal normal normal 12px/28px Segoe UI;
	letter-spacing: -0.12px;
	color: #959494;
	opacity: 1;
	white-space: nowrap;
}

._activityEdit {
	width: 118px;
	height: 28px;
	background: #F58220 0% 0% no-repeat padding-box;
	border-radius: 6px;
	opacity: 1;
	white-space: nowrap;
	display: inline-block;
	color: #000000;
	line-height: normal;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	height: 27px;
	float: right;
	border-radius: 4px;
	line-height: normal;
	font-size: 13px;
	white-space: nowrap;
}

._activityEdit a {
	padding: 4px 6px;
	text-decoration: none !important;
	color: #000 !important;
	white-space: nowrap;
	display: flex;
}

._borderRadius {
	border-radius: 5px
}

._headingsInActivity {
	text-align: left;
	font: normal normal 600 16px/24px Segoe UI;
	letter-spacing: 0px;
	color: #212121;
	opacity: 1;
	margin: 0;
	padding: 5px 0px 5px 0px;
}

._subHeadingInActivity {
	text-align: left;
	font: normal normal 600 16px/20px Segoe UI;
	letter-spacing: -0.16px;
	color: #212121;
	opacity: 1;
	padding: 0px;
	margin-bottom: 0px;
}

._raciHeadingInActivity {
	text-align: left;
	font: normal normal normal 14px/19px Segoe UI;
	letter-spacing: -0.14px;
	color: #212121;
	opacity: 1;
	margin-bottom: 0px;
}

._raciItemsInActivity {
	background: #F1F4F9 0% 0% no-repeat padding-box;
	border-radius: 4px;
	opacity: 1;
	padding: 2px 10px;
	max-width: -webkit-fill-available;
	margin: 5px 5px 0px 0px;
	display: inline-flex;
	align-items: center;
}

._raciItemsInActivity>span {
	text-align: left;
	font: normal normal normal 12px/16px Segoe UI;
	letter-spacing: -0.12px;
	color: #000000;
	opacity: 1;
	padding: 5px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

._linksInActiviy {
	white-space: nowrap;
	background: #F1F4F9 0% 0% no-repeat padding-box;
	border-radius: 4px;
	opacity: 1;
	padding: 2px 10px;
	max-width: -webkit-fill-available;
	margin: 5px 5px 0px 0px;
	display: inline-flex;
	align-items: center;
}

._linksInActiviy>a {
	text-align: left;
	font: normal normal normal 12px/16px Segoe UI;
	letter-spacing: -0.12px;
	color: #0178D4;
	opacity: 1;
	padding: 5px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

._activityDetailsHasttagsContainer {
	width: -webkit-fill-available;
}

._activityDetailsHasttagsContainer>.activityDetailsHasttagInput {
	margin: 1px 1px -18px 1px;
}

._activityLandingPageTitle {
	text-align: left;
	font: normal normal 600 20px/27px Segoe UI;
	letter-spacing: -0.2px;
	color: #212121;
	opacity: 1;
}

._activityDescription {
	text-align: left;
	font: normal normal normal 12px/20px Segoe UI;
	letter-spacing: 0px;
	color: #000000;
	opacity: 1;
}

._activityDescriptionCKEditor {
	text-align: left;
	font: normal normal normal 12px/20px Segoe UI !important;
	letter-spacing: 0px;
	color: #000000;
	opacity: 1;
}

._activityDescriptionCKEditor ul li::marker,
._activityDescriptionCKEditor ul li ul li::marker {
	color: #858585;
}

._activityDescriptionCKEditor ul {
	padding-left: 16px !important;
	margin: 0;
}

._activityDescriptionCKEditor>ul>li {
	list-style: disc;
}

._activityDescriptionCKEditor>ul>li>ul,
._activityDescriptionCKEditor>ul>li>ul>li>ul {
	margin-left: -16px !important;
}

._activityDescriptionCKEditor>ul>li>p {
	margin-bottom: 0px;
}

._taskItems {
	margin-left: -5px;
}

._activityDescription>p {
	margin-bottom: 0;
	padding: 0px 5px 5px 0px;
}

._columnDataOfSubheadings {
	padding: 0px 5px 5px 0.5px;
}

._allComlumns {
	background: #F5F5F5;
	padding-left: 8px;
	padding-right: 8px;
	;
}

._iInformationIcon {
	position: relative;
	cursor: pointer;
	width: 21px;
	height: 21px;
	opacity: 1;
	background-image: url(../../assets/i-informationIcon.svg);
	background-size: cover;
	background-repeat: no-repeat;
	display: inline-block;
	vertical-align: middle;
	margin-top: -5px;
	margin-left: 8px;

}

._tooltipRaciInfoIcon {
	position: absolute;
	left: 30px;
	top: -5px;
	width: 15vw;
	background: #1A1A1A 0% 0% no-repeat padding-box;
	color: white;
	opacity: 1;
	visibility: hidden;
	transition: opacity 300ms, visibility 300ms;
	z-index: 10;
	text-align: left;
	font: italic normal normal 10px/17px Segoe UI;
	letter-spacing: 0px;
	padding: 5px 10px 10px 10px;

}

._iInformationIcon:hover ._tooltipRaciInfoIcon,
._iInformationIcon:focus ._tooltipRaciInfoIcon {
	opacity: 1;
	visibility: visible;
}

._tooltipRaciInfoIcon::before {
	content: "";
	position: absolute;
	right: 100%;
	top: 8px;
	border-top: 8px solid transparent;
	border-bottom: 8px solid transparent;
	border-right: 12px solid #1A1A1A;
}

._tooltipRaciInfoIcon>p {
	margin: 0;
}

._addHashtagsIcon {
	background: #FFFFFF 0% 0% no-repeat padding-box;
	opacity: 1;
	float: left;
	width: 17px;
	height: 17px;
	background: url(../../assets/AddHashTagsIconUpdated.svg) no-repeat !important;
	background-size: contain;
	/* margin-top: 6px;
	margin-bottom: 2px; */
}

._ownerName {
	color: #1979c2;
	font-weight: bolder;
	white-space: nowrap;
}

._ownerInfo {
	display: flex;
	flex-direction: column;
	padding-right: 20px;
	margin-right: 20px;
	border-right: 2px solid #cac4c4;
}

._ownerInfo>a>span {
	text-align: left;
	font: normal normal bold 12px/16px Segoe UI;
	letter-spacing: -0.12px;
	color: #0078D4;
	opacity: 1;
}

._ownerInfo>a {
	margin-top: -3px;
}

._ownerInfo>span {
	line-height: 0.8;
	font: normal normal normal 12px / 16px Segoe UI;
}

._imgContainer {
	border: 1px solid white;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: wrap;
}

._collaboratorsImg {
	background: url(../../assets/Collab-Icn.svg) no-repeat;
	width: 36px;
	height: 36px;
	cursor: pointer;
}

._columnOfThree {
	border: 1px solid #dee2e6;
}

._activityDescription ul li::marker {
	color: #858585;
}

._activityDescription ul {
	padding-left: 15px;
	margin-bottom: 0px;
}

._linksInActiviyBR {
	width: -webkit-fill-available !important;
}

._complexityButtonsContainerSingle {
	width: auto !important;
}

._addHashtagsIconContainer {
	padding: 0px !important;
}

._contentNotAvailable {
	font: normal normal normal 12px / 20px Segoe UI !important;
	letter-spacing: 0px;
	color: #000000;
	opacity: 1;
}

._activityMainContainer {
	background: #F5F5F5;
	height: calc(100vh - 11rem);
}

._yellowBar>div {
	height: 34px;
	padding-inline: 19px;
}

._existingHashTagsOfActivity .hashTagsContainer>span {
	align-items: center;
	background: #f1f4f9 0 0 no-repeat padding-box;
	border-radius: 11px;
	color: #0178d4;
	display: inline-flex;
	font: normal normal normal 13px / 20px Segoe UI;
	height: 21px;
	justify-content: center;
	letter-spacing: 0;
	opacity: 1;
	padding: 3px 13px;
	width: max-content;
}

._handle_ckeditorContentScroll {
	overflow-y: hidden;
	overflow-x: auto;
	width: 100%;
	scrollbar-width: thin;
}

._handle_ckeditorContentScroll::-webkit-scrollbar {
	height: 3px !important;
}

._setBorderBox {
	border: 1px solid #dee2e6;
}

._removeBorderBox {
	border: none !important;
}

._paddingYCollaborator {
	padding-top: .30rem;
	padding-bottom: .30rem;
}

._ownerInfoForNonRealized {
	padding-right: 10px !important;
	margin-right: 10px !important;
}

._activityLandingPageNonRealized {
	background: '#F5F5F5';
	overflow-y: scroll;
	overflow-x: hidden;
	height: calc(100vh - 175px);
	scrollbar-width: thin;
	scrollbar-color: transparent transparent;
}

._activityLandingPage {
	background: #F5F5F5;
	overflow-y: scroll;
	overflow-x: hidden;
	height: calc(100vh - 111px);
	scrollbar-width: thin;
	scrollbar-color: transparent transparent;
}

._activityLandingPageNonRealized:hover,
._activityLandingPage:hover {
	scrollbar-width: thin;
	scrollbar-color: #aaa #fff;
}

/* Styling for the disabled complexity buttons */
._complexityButtons:disabled {
	background-color: #f0f0f0;
	/* Light gray background to indicate disabled */
	color: #aaa;
	/* Lighter text color */
	cursor: not-allowed;
	/* Makes it clear that the button is not clickable */
}

.styled-Preview-list {
	text-align: left;
	font: normal normal normal 12px / 20px Segoe UI !important;
	letter-spacing: 0px;
	color: #000000;
	opacity: 1;
	list-style-type: disc;
	margin-left: 16px;
	/* Add some indentation */
	padding-left: 0;
}

.styled-Preview-list li::marker {
	color: #858585;
}

.deliverablesForActvity>ul {
	margin-left: -40px !important;
	margin-bottom: 0px !important;
}

.deliverablesForActvity>p {
	margin-bottom: 0px !important;
}

.TaskActivity>p {
	margin-bottom: 0px !important;
}

._ownerForPreview {
	border-right: none !important;
}

.NewPreviewAndSubmitPopUp {
	width: 90vw !important;
	max-width: 90% !important;
}

.bulletParagraph p {
	margin-left: 16px;
	margin-bottom: 0px;
	/* display: list-item; */
}

.bulletParagraph p::marker {
	/* color: #858585 !important; */
	margin-right: 10px;
}

._FeedbackDivActvity {
	display: flex;
	align-items: center;
	justify-content: flex-end !important;
	background-color: #F5F5F5;
}

._complexityContainerUpdateForNonRealize {
	padding-bottom: 0px !important;
}

.NonRealizedNewLayout {
	padding: 5px 0px 6px 0px !important;
}

.NonRealizedNewLayoutFullScreen {
	padding: 5px 0px 4px 0px !important;
}

._activityDescription ul ul {
	padding-left: 40px !important;
}

._activityDescription>ul>li>p {
	margin-bottom: 0px;
}

._activityDescription>ol>li>p {
	margin-bottom: 0px;
}

._activityDescription ol ol {
	padding-left: 40px;
}


._activityDescription ol {
	padding-left: 18px;
	margin-bottom: 0px;
}

._activityDescription ul ol {
	padding-left: 36px !important;
	margin-bottom: 0px;
}

._activityDescription ol ul {
	padding-left: 40px;
	margin-bottom: 0px;
}

._activityDescription ol li {
	padding-left: 4px;
}

._nonRealizedocument {
	margin-top: -9px;
}   

._leftColumnActivty {
	text-align: end;
	padding: 0px 10px 10px 0px;
	flex: 0 0 150px;
	max-width: 150px;
	width: 150px;
}

._rigthColumnActivty {
	flex: 1;
	max-width: calc(100% - 150px);
	width: auto;
	padding: 0px 10px 0px 10px;
}

._activityLandingPageUpdateHistoric {
	margin-top: 50px !important;
	height: calc(100vh - 54px) !important;
}

._headingContainerActivity {
	display: flex;
	justify-content: center;
	align-items: center;
	max-width: calc(100% - 291px);
}

.editor-container-activity-contributor {
	margin-left: 10px;
	max-width: calc(100% - 300px);
}

._subHeadingActivity {
	flex: 0 0 150px;
	max-width: 150px;
	width: 150px;
	font: normal normal 600 14px/19px Segoe UI;
	letter-spacing: -0.14px;
	color: #222629;
	opacity: 1;
	margin-left: 10px;
}

._subHeadingActivityRightSide {
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

._subHeadingActivityRightSide ._paragraph {
	margin: 0;
	white-space: nowrap;
	font: normal normal 600 14px/19px Segoe UI;
	letter-spacing: -0.14px;
	color: #222629;
	opacity: 1;
	font-weight: normal;
}

._contributorAddTemplatesButtonActivity {
	font: normal normal 600 14px / 19px Segoe UI;
	white-space: nowrap;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	cursor: pointer !important;
	color: #222629;
	height: 35px !important;
	width: auto;
	letter-spacing: -0.14px;
	border-radius: 4px;
	border: 1px solid #e4e4e4;
	opacity: 1;
	margin: 0;
	margin-bottom: 5px;
}
.Activty_MainContainer{
	max-width: calc(100% - 275px);
  }
  .ActivityContributionEntry{
	margin-top: 10px !important;
  }
  .ActivityContributionTask{
	margin-top: 15px !important;
  }
  .toolsSidebarLatest{
	width: auto !important;
  }
  .ToolsEditorContinaer{
width: calc(100% - 290px);
  }
  .deliverable_Contibution{
	width: 101%;
  }
  .Spinner_Activty{
	margin-top: 250px !important;
  }
  ._deliverablesActivity{
	margin-top: 6px !important;
  }
._deliverablesDescriptionActivity{
	line-height: 0.6 !important;

}

.phaseAddIcon_Preview{
	margin-top: 6px !important;
}
.disableButton_Preview{
	background: #f0f0f0 !important;
	color: #aaa !important;
	cursor: not-allowed !important;
}
.disableButton_PreviewIcon{
	color: #aaa !important;
	cursor: not-allowed !important;
}
._Previewbtns{
	margin-left: 10px !important;
}
._Publish_Disable{
	opacity: 0.5 !important;
}
.Latest_ActivtyCriteria{
	width: 106% !important;
	padding: 4px !important;
}
.relatededitContent_latest{
	right: 8px !important;
}
.add_latest_activty{
   right: -31px  !important;
} 
.entryEditCriteriaInputdesc{
	width: 100%;
}
.entryEditCriteriaInputdesc_latest{
	width: 99.5% !important;
	
}
.TaskLatest_Button{
	right: 313px !important;
}
.AddEntryBtnLatest{
	right: 276px !important;
}
.TaskWidth_latest{
	
}
._TaskWidth_latest
{
/* overflow: hidden !important; 	 */

}
.ToolsEditor_Latest{
	min-height: auto !important;
}
.selectedEntryTab_Latest{
	color: #222629 !important;
    font: normal normal 600 14px / 19px Segoe UI !important;
    letter-spacing: -.14px;
    opacity: 1;
	padding-bottom: 5px !important;
}
.toolsSidebar_latest{
	width: 270px !important;
}
.sideBarCardLatest_Activity{
	width: 250px !important;

}
.suggestionStatusLatestTools{
	margin-right: 2px !important;
	margin-top: 4px !important;
}
.relatededitContentDelete_latest{
	right:52px !important;

}
.disableIconsCross{
	background: url('../../assets/CrossIconDisabled.svg') !important;
}
.disableIconsAdd{
	background: url('../../assets/AddIconDisabled.svg') !important;
}
.AddEntryBrn_latest{
	right: 15px !important;
}