.topicsCrowdSourcingContainer {
  height: 73vh !important;
  display: flex;
  font-family: "Segoe UI", sans-serif;
  flex: 1 1;
  padding: 15px;
  margin-top: 40px;
  /* position: fixed; */
  background: #f2f2f2;
  width: 100%;
  min-height: 82vh;
  overflow: auto;
}

.topicsAccordianList {
  order: 1;
  margin-right: 2px;
}

.listOfTopicsMainContainer {
  height: calc(100vh - 10.3rem);
  overflow: hidden;
  padding-bottom: 15px;
  overflow-y: auto;
}

.topicsDescriptionDetails {
  flex: 1 1;
  order: 2;
  padding: 0 !important;
  background: #fff;
  overflow: hidden;
  overflow-x: scroll;
}

.yammerFeedContainer {
  padding: 0 0 0 2px !important;
  order: 3;
  width: 300px;
  display: flex;
  flex-direction: column;
  background: #f2f2f2;
  position: relative;
}

.yammerTitle {
  display: flex;
  border-bottom: 1px solid #e0e0e0;
  color: #0168b7;
  padding: 9px 9px 0px 9px;
  background: #f3f2f2;
}

.yammerTitle h4 {
  font-size: 16px;
  float: left;
  margin: 0;
  line-height: 23px;
  font-weight: 600;
  flex: 1;
}

.yammerTitle i {
  float: right;
  font-size: 16px;
  color: #fff;
}

.topicsAccordianLeftMenu {
  display: flex;
  border-bottom: 2px solid #f2f2f2;
  height: 45px;
}

.displayNone {
  display: none !important;
}

.topicsAccordianLeftMenu ul {
  float: left;
  padding: 0px;
  display: flex;
  list-style: none;
  margin: 0;
}

.topicsAccordianLeftMenu span {
  padding: 0 20px;
}

.topicsAccordianLeftMenu li {
  float: left;
  padding: 8px 13.5px 10px 21px;
  list-style: none;
  text-align: center;
  flex-grow: 1;
  flex-basis: 0;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  color: #666;
}

.topicsLeftMenuCollapseIcon {
  width: 41px;
  height: 43px;
  background: #de6804;
  cursor: pointer;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.2470588235294);
}

.topicsLeftMenuCollapseIcon i {
  color: #fff;
  font-size: 16px;
  vertical-align: -webkit-baseline-middle;
  padding: 8px 12px 11px 12px;
}

.TopicsParentWrapper {
  display: flex;
  padding: 10px;
  background: #fff;
  cursor: pointer;
}

.TopicsTitleSpan {
  margin-left: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 600;
}

.TopicsChildWrapper {
  padding: 5px 0;
}

.TopicsChildWrapper ul {
  background: #fafafa;
  list-style: none;
  font-size: 13px;
  text-align: left;
  padding: 0;
  margin-bottom: 0;
}

.topicsAccordianViewContainer {
  border-bottom: 1px solid #eae0e0;
}

.topicsAccordianViewContainer.tutorial {
  border-bottom: none;
}

.topicDescTitlePart {
  padding: 9px 30px 5px 18px;
  float: left;
  border-bottom: 2px solid #f2f2f2;
  width: 100%;
  text-align: left;
}
.topicDescTitlePartNewView {
  background-color: #f5f5f5 !important;
  padding: 5px 0px 5px 18px !important;
}

.topicDescTitlePart h4 {
  font-size: 18px;
  float: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
}

.DoNotShowTopicsChildWrapper {
  display: none;
}

.TopicsChildWrapper ul li {
  display: flex;
  line-height: 24px;
  padding: 0px 15px 0px 42px;
}

.TopicsParentWrapper i {
  font-size: 12px;
  font-weight: bold;
  padding-top: 3px;
}

.showHamberger {
  display: none;
}

.showTopicsListLeftMenu {
  width: 311px;
  background: #fff;
}

.topicsMenuCollapsedHamberger {
  width: 41px;
  height: 41px;
  background: #de6804;
  color: #fff;
  cursor: pointer;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.2470588235294);
}

.topicsMenuCollapsedHamberger i {
  font-size: 25px;
  margin: auto;
  padding-left: 9px;
  padding-top: 1px;
}

.TopicsChildWrapper li.ActiveLink {
  background: #3191e7;
}

.TopicsChildWrapper li a.ActiveLink {
  color: #fff;
}

.topicDescriptionDivContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.topicRightDescriptionDiv {
  width: 27.3%;
  padding: 10px 15px 33px 5px;
  min-width: 310px;
  border-left: 1px solid #e2e2e2;
  position: relative;
  overflow-y: scroll;
  display: flex !important;
}

.topicRightDescriptionDiv .topicsOwner {
  float: right;
  padding-bottom: 5px;
  position: absolute;
}

.topicDescriptionDiv {
  width: 75%;
  font-size: 13px;
  padding: 19px 20px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 130px);
  overflow: hidden;
  overflow-y: auto;
  overflow-x: auto;
}

.topicPolygonDescriptionDiv {
  width: 75%;
  font-size: 13px;
  padding: 10px 5px 33px 15px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 12rem);
  overflow: hidden;
  overflow-y: auto;
  overflow-x: hidden !important;
}

.topicPolygonDescriptionDiv h3 {
  font-size: 26px;
}

.topicPolygonDescriptionDiv h2 {
  font-size: 32px;
}

.topicPolygonDescriptionDiv h1 {
  font-size: 34px;
}

.topicDescriptionDiv h3 {
  font-size: 26px;
}

.topicDescriptionDiv h2 {
  font-size: 32px;
}

.topicDescriptionDiv h1 {
  font-size: 34px;
}

.topicVisioImage {
  margin-bottom: 30px;
}

.topicEdit {
  display: inline-block;
  float: right;
  border: 1px solid #f58220;
  margin-right: 10px;
  color: #f58220;
  border-radius: 4px;
  line-height: normal;
  font-size: 13px;
  margin-left: 10px;
}

.topicEdit a {
  background: #f58220;
  padding: 4px 6px;
  text-decoration: none !important;
  color: #000 !important;
}

.editIcon {
  width: 16px;
  height: 17px;
  background-size: contain;
  background: url(../../assets/suggestEdit.svg) no-repeat;
  float: right;
  margin-left: 8px;
  margin-top: 2px;
}

.topicDescTitlePart button.activityPreviewButton {
  background: #fff;
  padding: 2px 18px !important;
  background-color: #fff;
  border-color: #f58220 !important;
}

.topicDescTitlePart button:hover {
  background: #bababa;
}

.topicDescTitlePart a {
  float: right;
}

#embedded-feed {
  flex-grow: 1;
  padding: 0 0 0 4px !important;
}

.yammerFeedContainer #embedded-feed iframe {
  height: 98% !important;
}

#yammer-login {
  text-align: center;
  padding: 10px;
  display: block;
  position: absolute;
  top: 45%;
  left: 22%;
}

#yammer-login img {
  display: flex;
  position: absolute;
  top: 50%;
  left: 26%;
}

span.topicVersion {
  float: left;
  font-size: 13px;
  line-height: 27px;
  color: #666;
}

.polygonTopicTitle {
  width: 100% !important;
}

.topicTitle {
  line-height: 40px;
  margin-bottom: 0;
  width: 100%;
}

.topicDetailsTitle {
  margin-right: auto !important;
  width: 95%;
  word-wrap: break-word;
  line-height: 40px;
  margin-bottom: 0;
}

#topicTextDescription .page-heading {
  display: none;
}

.topicYammerFeedMain {
  order: 3;
}

.yammerMenuCollapsedHamberger {
  position: absolute;
  right: 0;
  background: #fff;
  cursor: pointer;
  padding: 9px 8px 9px 8px;
  box-shadow: 0px 0px 3px 0.5px rgba(0, 0, 0, 0.2470588235294);
  border-left: 1px solid #f2f2f2;
}

.showYammerHamberger {
  display: none;
}

.yammerRightMenuCollapseIcon {
  background: #de6804;
  cursor: pointer;
  float: right;
  padding: 0px 5px;
  margin-bottom: 10px;
}

.yammerFeedCollapsedIcon {
  background: #de6804;
  padding: 1px 6.5px 0px 6.5px;
}

.showTopicsYammerMenu {
  height: 100%;
  display: flex;
}

.yammerFeedCollapsedIcon i {
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  vertical-align: text-top;
}

.TopicsChildWrapper ul li a.NotActiveLink {
  color: #0062ad;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.TopicsChildWrapper li.ActiveLink {
  background: #0062ad;
}
.topicEdit {
  margin-right: 0px !important;
}

.disabledEditTopic  {
  display: none;
}

.topicsDescriptionDetails.topicEditPage .sidebar {
  border: none;
  background: none;
}

.topicEditPage .centered.topicDetailsCkEditor {
  padding: 0 !important;
}

.topicPreviewAndSubmitPopUp h4.topicTitle {
  margin-bottom: 5px;
}

.topicsDescriptionDetails.topicEditPage .WarningMessageActivityPage {
  margin: 10px 0 30px 0;
  width: calc(100% - 18.2rem);
}

.topicsDescriptionDetails.topicEditPage .topicDescriptionDiv .table {
  width: 100% !important;
}

.topicDescriptionDiv p a,
.topicDescriptionDiv a {
  color: #0078d4;
}

.topicsDescriptionDetails.topicEditPage .topicPolygonDescriptionDiv .table {
  width: 100% !important;
}

.topicPolygonDescriptionDiv p a,
.topicPolygonDescriptionDiv a {
  color: #0078d4;
}

.image.image-style-align-left {
  margin-right: auto;
  float: left;
}

.image.image-style-align-right {
  margin-left: auto;
  float: right;
}

.image {
  margin: auto;
  text-align: center;
}

.TopicsParentWrapper:focus {
  outline-offset: -1px;
}

.topicsCrowdSourcingContainer.topicsCrowdSourcingContent {
  position: static;
  margin-top: 84px;
}

.topicsCrowdSourcingContainer.topicsCrowdSourcingContent .topicDescriptionDiv {
  height: auto;
  overflow: auto;
}

.topicsCrowdSourcingContainer.topicsCrowdSourcingContent
  .topicPolygonDescriptionDiv {
  height: auto;
  overflow: auto;
}

.topicFixedPart {
  width: 100%;
  position: fixed;
  z-index: 99;
  background: #f2f2f2;
}

.topicFixedPart .topicDescTitlePart {
  padding: 0;
  background: #fff;
  margin: 15px 15px 0 15px;
  width: calc(100% - 1.9rem);
}

ins {
  text-decoration: none;
  background-color: #d4fcbc;
}

del {
  text-decoration: line-through;
  background-color: #fbb6c2;
  color: #555;
}

.topicsRightMainContainer {
  display: flex;
  margin-left: 50px;
  transition: all 0.2s ease-in;
}

.topicsRightMainContainer.expanded {
  margin-left: 115px;
  transition-duration: 200ms;
}

.topicDescriptionDiv .table td,
.topicDescriptionDiv .table th {
  border: 1px solid #333333;
}

.topicDescriptionDiv .table tr {
  border-color: #333333;
}

.topicPolygonDescriptionDiv .table td,
.topicPolygonDescriptionDiv .table th {
  border: 1px solid #333333;
}

.topicPolygonDescriptionDiv .table tr {
  border-color: #333333;
}

.topicsLandingPage .topicFixedPart .phaseBreadcrumb {
  position: static;
}

span.popUpMessage {
  font-weight: 700;
}

span.popUpMessageTop {
  font-weight: 700;
  font-size: 13px;
}

#topicPreviewIframe {
  position: relative;
  width: 100%;
  height: calc(100vh - 0.7rem);
  border: none;
}

/* .existingHashTagsOfTopic span { */
/* color: #0078d4; */
/* margin-right: 10px; */
/* cursor: pointer; */
/* color: #0078d4; */
/* margin-right: 10px; */
/* border: 1px solid #3191E7; */
/* border-radius: 4px; */
/* opacity: 1; */
/* cursor: pointer; */
/* padding: 2px 20px; */
/* } */

.existingHashTagsOfTopic {
  display: flex;
  font-size: 13px;
  flex-wrap: wrap;
}

.hashTagsColor {
  color: #0078d4;
  text-decoration: none;
  cursor: pointer;
}

.topicDetailsHasttagInput {
  display: flex;
  padding: 7px 0;
}

.topicDetailsHasttagInput input {
  flex: 1;
  border: 1px solid #e4e4e4;
  padding: 4px;
  font-size: 12px;
}

.topicDetailsHasttagInput i {
  font-size: 16px;
  border: 1px solid #e4e4e4;
  padding: 4px 8px;
  cursor: pointer;
}

.dropDowncontainer.TopicsHashtagsInput input {
  width: 100%;
  padding: 7px;
}

.dropDowncontainer.hashtagsDropDownContainer {
  width: 100%;
  margin-right: 0;
}

div#topicsHashtagsInput {
  flex: 1;
}

.topicDetailsHasttagInput {
  display: flex;
  padding: 7px 0;
}

.dropDowncontainer.TopicsHashtagsInput .dropDownList.hashtagDpList {
  width: 250px;
  top: 36px;
}

.existingHashTagsOfTopic span:hover {
  text-decoration: underline;
}

.topicPreviewAndSubmitPopUp .existingHashTagsOfTopic {
  margin-bottom: 10px;
}

.relatedAndYammerFeedTab {
  display: flex;
  flex: 1;
}

.relatedAndYammerFeedTab ul {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 13px;
}

.relatedAndYammerFeedTab ul li {
  margin-right: 10px;
  cursor: pointer;
  padding-bottom: 5px;
}

.associateContentAddingLink {
  margin: 10px 0px;
  padding: 6px 13px;
  background: #f8f9fa;
  color: #0262ab;
  cursor: pointer;
  width: fit-content;
}

.associateContentAddingLink:hover {
  text-decoration: underline;
}

.relatedContentUpdateModal.modal .modal-dialog {
  max-width: 700px;
}

.associatedContentModalSection p {
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 600;
}

.associatedContentModalSection input {
  width: 100%;
  border: 1px solid #e3e3e3;
  font-size: 13px;
}

.associatedContentModalSection {
  margin-bottom: 20px;
  margin: 10px 30px 20px 30px;
}

.associatedContentModalButtons {
  margin-bottom: 30px;
  text-align: center;
}

.associatedContentModalButtons button {
  border: none;
  background: #0078d4;
  padding: 4px 20px;
  border-radius: 4px;
  font-size: 13px;
}

.associatedContentModalButtons button:first-child {
  background: #e4e4e4;
  color: #0078d4;
  margin-left: auto;
  margin-right: 20px;
}

.associatedContentModalButtons button:last-child {
  background: #0078d4;
  color: #fff;
  margin-right: auto;
}

.topicsOwner {
  display: flex;
  float: right;
}

.topicsOwnerDetails {
  display: flex;
  padding-left: 20px;
  border-left: 1px solid #e4e4e4;
}

.topicRightDescriptionDiv .topicsOwner .topicsOwnerDetails {
  width: 71%;
}

.topicsOwnerDetails .alternativeOfh6 {
  font-size: 13px !important;
}

.topicsOwnerDetailsPM {
  display: flex;
  padding-left: 20px;
  border-left: 1px solid #e4e4e4;
  margin-left: auto;
}

.topicsOwnerDetails img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-top: 2px;
}

.topicsOwnerDetailsPM img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-top: 2px;
}

.topicsOwnerPersonalDetails {
  margin-left: 8px;
}

.topicsOwnerPersonalDetails > p {
  margin-bottom: 0px;
  padding: 1px;
  text-align: left;
  font: normal normal normal 13px / 16px Segoe UI;
  letter-spacing: -0.12px;
  color: #212121;
  opacity: 1;
}

/* .topicsOwnerPersonalDetails span{
	text-align: left;
    font: normal normal bold 16px / 16px Segoe UI;
    letter-spacing: -0.12px;
    color: #0078D4;
    opacity: 1;
} */

.associateContentPanel ul {
  max-height: 400px;
  overflow: auto;
  padding-bottom: 12% !important;
}

.associateContentPanel ul li div:first-child {
  flex: 1;
}

.associateContentPanel ul li div:first-child a {
  color: #0262ab !important;
}

.associateContentPanel ul li div:last-child i {
  font-size: 13px;
  margin-left: 5px;
  color: #000;
  margin-top: 7px;
  cursor: pointer;
}

.topicsOwnerEditIcon {
  float: left;
  width: 29px;
  height: 29px;
  background: url(../../assets/TopicOwnerEditIcon.svg) no-repeat;
  background-size: contain;
  cursor: pointer;
}

.topicsOwnerHistoryIcon {
  float: left;
  width: 29px;
  height: 29px;
  background: url(../../assets/TopicHistoryIcon.svg) no-repeat;
  background-size: contain;
  cursor: pointer;
}

.topicsOwnerDeleteIcon {
  float: left;
  width: 29px;
  height: 29px;
  background: url(../../assets/TopicOwnerDeleteIcon.svg) no-repeat;
  background-size: contain;
  cursor: pointer;
}

.topicsOwnerEditDetails {
  display: flex;
  padding: 10px 10px 10px 0px;
}

.topicOwnerHistoryPopUp .modal-dialog {
  margin-left: auto !important;
  margin: 0;
  max-width: 40% !important;
}

.topicOwnerHistoryPopUp .modal-body {
  padding: 20px 55px !important;
}

.topicOwnerHistoryPopUp .modal-body table {
  width: 100%;
  border: 1px solid #666;
  border-collapse: collapse;
  text-align: center;
  background: #f2f2f2;
  font-size: 13px;
}

.topicOwnerHistoryPopUp .modal-body th,
.topicOwnerHistoryPopUp .modal-body td {
  border: 1px solid #666;
  padding: 15px;
}

.topicOwnerHistoryPopUp .modal-body th {
  width: 50%;
}

span.historyVirsionNumber {
  cursor: pointer;
  color: #0078d4;
}

span.historyVirsionNumber:hover {
  text-decoration: underline;
}

.topicsCrowdsourcingHistoryModalContainer .topicsMenuContainer {
  display: flex;
  border-bottom: 1px solid #e4e4e4;
  margin-bottom: 10px;
  background: #f3f6ff;
  padding-left: 40px;
}

.topicsCrowdsourcingHistoryModalContainer .topicsMenuContainer .topicsMenu li {
  padding: 10px 3px 10px 3px;
  margin: 0 25px 0 0px;
}

.topicsCrowdsourceHistoryModal
  .topicsCrowdsourcingHistoryModalContainer
  .topicDescriptionDiv {
  padding: 10px 24px 33px 40px;
}

.topicsCrowdsourceHistoryModal
  .topicsCrowdsourcingHistoryModalContainer
  .topicPolygonDescriptionDiv {
  padding: 10px 24px 33px 40px;
}

.modal-dialog.topicsCrowdsourceHistoryModal {
  max-width: 1200px;
}

.topicsCrowdsourcingHistoryModalContainer .topicsMenu span {
  padding: 0;
}

.modal-dialog.topicsCrowdsourceHistoryModal .modal-title span {
  margin-bottom: 0;
  font-size: 12px;
  color: #666;
}

.modal-dialog.topicsCrowdsourceHistoryModal .modal-header {
  padding: 10px 20px 10px 40px;
}

.modal-dialog.topicsCrowdsourceHistoryModal .modal-header h5 {
  margin-bottom: 0;
}

.modal-dialog.topicsCrowdsourceHistoryModal .modal-body {
  min-height: 343px;
  padding: 0;
}

.ck.ck-dropdown .ck-dropdown__panel.ck-dropdown__panel_nw,
.ck.ck-dropdown .ck-dropdown__panel.ck-dropdown__panel_sw {
  right: 0;
  top: 0 !important;
}

.topicOwnerCollaboratorIcon {
  width: 23px;
  height: 23px;
  background: url(../../assets/MethodOwnerIcon.svg) no-repeat;
  float: right;
  background-size: contain;
  margin-left: 8px;
  margin-top: 2px;
  cursor: pointer;
}

.relatedContentPolygonContainer {
  position: absolute;
  display: block;
  top: 76%;
  width: 23%;
}

.relatedContentContainer {
  position: absolute;
  display: block;
  top: 35%;
  width: 23%;
}

.relatedContentTabs {
  display: flex;
  list-style: none;
  padding: 0px;
  flex-direction: row;
}

.selectedContentPanel {
  display: flex;
  flex-direction: column;
  color: #a95813 !important;
  border-bottom: 1px solid #a95813 !important;
  width: 50%;
  text-align: left;
  font: normal normal normal 14px / 20px Segoe UI;
  cursor: pointer;
}

.otherContentPanel {
  display: flex;
  border-bottom: 1px solid #7e7e7e !important;
  text-align: left;
  font: normal normal normal 14px / 20px Segoe UI;
  letter-spacing: -0.14px;
  color: #7e7e7e;
  opacity: 1;
  width: 50%;
  cursor: pointer;
}

.topicOwnerCollaborators {
  position: relative;
  right: 5px;
  margin-left: auto;
  /* padding: 11px; */
}

.topicPreviewAndSubmitPopUp .sidebar {
  display: none;
}

.topicPreviewAndSubmitPopUp .ck.ck-sticky-panel {
  display: none;
}

.topicPreviewAndSubmitPopUp
  .ck-blurred.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-read-only {
  border: none;
}

.hideApprovalCKEditor {
  display: none;
}

.topicScheduledMessageBar
  .ms-MessageBar.ms-MessageBar--warning.ms-MessageBar-multiline,
.deliveryMangementDescription
  .ms-MessageBar.ms-MessageBar--warning.ms-MessageBar-multiline {
  display: flex;
  margin-bottom: 45px;
  font-size: 12px;
}

.topicScheduledMessageBar
  .ms-MessageBar.ms-MessageBar--warning.ms-MessageBar-multiline
  .ms-MessageBar-content,
.deliveryMangementDescription
  .ms-MessageBar.ms-MessageBar--warning.ms-MessageBar-multiline
  .ms-MessageBar-content {
  background: #e5ad67;
  display: flex;
  border-radius: 7px;
}

.topicScheduledMessageBar
  .ms-MessageBar.ms-MessageBar--warning.ms-MessageBar-multiline
  .ms-MessageBar-content
  button,
.deliveryMangementDescription
  .ms-MessageBar.ms-MessageBar--warning.ms-MessageBar-multiline
  .ms-MessageBar-content
  button {
  border: none;
  background: #ffffff;
  color: #0078d4;
  padding: 3px 10px;
  border-radius: 4px;
  margin-left: 10px;
}

.topicScheduledMessageBar
  .ms-MessageBar.ms-MessageBar--warning.ms-MessageBar-multiline
  .ms-MessageBar-content
  .ms-MessageBar-text
  p,
.deliveryMangementDescription
  .ms-MessageBar.ms-MessageBar--warning.ms-MessageBar-multiline
  .ms-MessageBar-content
  .ms-MessageBar-text
  p {
  font-size: 11px;
  color: #2b314c;
  font-weight: 600;
}

.topicScheduledMessageBar
  .ms-MessageBar.ms-MessageBar--warning.ms-MessageBar-multiline
  .ms-MessageBar-content
  span.ms-MessageBar-innerText
  span
  p {
  display: initial;
}

.addRoleNameContainer.topicCategoryName
  .NotificationChkboxContainer
  .CustomCheckmark:after {
  top: -1px;
}

.portfolio-section {
  margin-bottom: 20px;
}

.portolio-container p {
  font-size: 14px;
  color: #1f3a4e;
}

.portolio-container h3 {
  color: #5a82a7;
}

.portolio-container h5 {
  color: #1f3a4e;
  font-size: 14px;
  font-weight: bold;
}

ul.tabs {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

ul.tabs li {
  background: none;
  color: #bfc0c2;
  display: inline-block;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 16px;
  border-bottom: 1px solid #bfc0c2;
}

ul.tabs li.current,
ul.tabs li.active {
  background: white;
  color: #5a82a7;
  border-bottom: 3px solid #ffa12d;
  font-weight: bold;
  font-size: 16px;
}

.tab-content {
  display: none;
  background: white;
  padding: 15px 0;
}

.tab-content.current,
.tab-content.active {
  display: block;
}

.consumption-grid {
  display: flex;
  flex-wrap: wrap;
}

.consumption-grid-item {
  width: 31%;
  margin-right: 13px;
  color: #1f3a4e;
}

.consumption-grid-item p {
  font-size: 13px;
}

.portolio-container table {
  border: 1px solid #c9c9c9;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

.portolio-container table tr {
  border: 0;
  padding: 0.35em;
}

.portolio-container table th,
table td {
  padding: 0.625em;
  text-align: left;
}

table td {
  color: #1f3a4e;
}

.portolio-container table th {
  font-size: 0.85em;
  color: #707070;
  font-weight: normal;
  border-bottom: 1px solid #c9c9c9;
}

@media screen and (max-width: 600px) {
  .portolio-container table {
    border: 0;
    color: #1f3a4e;
    font-size: 14px;
  }

  .portolio-container table caption {
    font-size: 1.3em;
  }

  .portolio-container table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .portolio-container table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: 0.625em;
  }

  .portolio-container table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 0.8em;
    text-align: right;
    color: #1f3a4e;
  }

  .portolio-container table td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  .portolio-container table td:last-child {
    border-bottom: 0;
  }
}

.assessCustomerPulse h3 {
  color: #1f3a4e;
}

.track-Consumption {
  width: 75%;
}

.closeTheLoop {
  display: flex;
}

.closeTheLoop .left-content p {
  margin: 0;
}

.portolio-container.closeTheLoop .table-blue {
  width: 60%;
  font-size: 12px;
  color: #1f3a4e;
}

.portolio-container.closeTheLoop .table-blue table {
  border-radius: 4px;
}

.portolio-container .table-blue th {
  background-color: #bfdeff;
  font-weight: bold;
  color: #1f3a4e;
}

.portolio-container .table-blue tr td {
  overflow-wrap: break-word;
}

.portolio-container .table-blue tbody {
  background-color: #dcedff;
}

.portolio-container .table-blue p {
  margin: 0;
  font-size: 12px;
}

.portolio-container :not(.table-blue) tr {
  border-bottom: 1px solid #c9c9c9;
}

.left-content {
  width: 40%;
  margin-right: 20px;
}

.left-content p {
  font-size: 12px;
  color: #707070;
}

.hex-container-right {
  width: 30%;
}

.left-container {
  width: 100%;
  padding-inline: 2rem;
}

.portolio-container .feedbackTable th {
  text-align: center;
  color: #1f3a4e;
  font-weight: bold;
  border-right: 1px solid #c9c9c9;
}

.portolio-container .feedbackTable td {
  background-color: #f4f8fd;
}

.portolio-container .feedbackTable p {
  color: #858585;
  font-size: 11px;
  margin: 0;
  font-weight: normal;
}

.portolio-container .feedbackTable td {
  border-right: 1px solid #c9c9c9;
}

.portolio-container .feedbackTable td ul li {
  margin-bottom: 10px;
}

.portolio-container .trackoverviewTable th {
  font-weight: normal;
  text-align: left;
}

.portolio-container .trackoverviewTable tr {
  border-bottom: 1px solid #c9c9c9;
}

.portolio-container .trackoverviewTable td {
  font-weight: 600;
  background-color: #f4f8fd;
  overflow-wrap: break-word;
}

.cpeSurveyTable thead tr th:first-child {
  width: 15%;
}

.cpeSurveyTable thead tr th:nth-child(2) {
  width: 50%;
}

.cpeSurveyTable tr td {
  font-weight: 600;
}

/*********** Hexagons *******************/
#hexGrid {
  overflow: hidden;
  width: 70%;
  margin: 0 auto;
  padding: 0.866% 0;
  font-family: "Raleway", sans-serif;
  font-size: 15px;
}

#hexGrid:after {
  content: "";
  display: block;
  clear: both;
}

.hex-container-right #hexGrid {
  width: 100%;
}

.hex {
  position: relative;
  list-style-type: none;
  float: left;
  overflow: hidden;
  visibility: hidden;
  outline: 1px solid transparent;
  transform: rotate(-60deg) skewY(30deg) translatez(-1px);
}

.hex * {
  position: absolute;
  visibility: visible;
  outline: 1px solid transparent;
}

.hexIn {
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;
  color: #fff;
  transform: skewY(-30deg) rotate(60deg);
}

.hexIn.hex-blue {
  background-color: #9bc4f0;
  border-color: #9bc4f0;
  color: #9bc4f0;
}

.hexIn.hex-blue:hover {
  background-color: #3191e7;
  border-color: #3191e7;
  color: #3191e7;
}

.hexIn.hex-blue:hover .textSpan a {
  color: white;
  font-weight: 600;
  cursor: pointer;
}

.hexIn.hex-yellow {
  background-color: #ffcd90;
  border-color: #ffcd90;
  color: #ffcd90;
}

.hexIn.hex-yellow:hover {
  background-color: #ffa12d;
  border-color: #ffa12d;
  color: #ffa12d;
}

.hexIn.hex-yellow:hover .textSpan a {
  color: #844a02;
  font-weight: 600;
  cursor: pointer;
}

.hexIn.hex-purple {
  background-color: #9974d8;
  border-color: #9974d8;
  color: #9974d8;
  font-weight: 600;
}

.hexIn.hex-purple h1 {
  color: white;
}

.hexIn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.hex h1 {
  font-size: 14px;
  color: #1f3a4e;
  margin: 0;
  /* position: relative;
	top: 36%; */
  padding: 5%;
}

.hex .hexIn h1 {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  max-width: 600px;
}

.hex h1:hover {
  text-decoration: underline;
}

.hex-container-right .hexIn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.hex-container-right .hex h1 {
  font-size: 10px;
  padding: 0 5%;
  margin: 0;
  /* position: relative;
	top: 35%; */
}

.hex-container-right .hex-blue {
  background-color: #9bc4f0;
  border-color: #9bc4f0;
  color: #9bc4f0;
}

.hex-container-right .hex-blue:hover {
  background-color: #3191e7;
  border-color: #3191e7;
  color: #3191e7;
}

.hex-container-right .hex-blue:hover .textSpan a {
  font-weight: 600;
  cursor: pointer;
}

.hex-container-right .hex-yellow {
  background-color: #ffcd90;
  border-color: #ffcd90;
  color: #ffcd90;
}

.hex-container-right .hex-yellow:hover {
  background-color: #ffa12d;
  border-color: #ffa12d;
  color: #ffa12d;
}

.hex-container-right .hex-yellow:hover .textSpan a {
  color: #844a02;
  font-weight: 600;
  cursor: pointer;
}

.hex-container-right .hex-purple {
  background-color: #9974d8;
  border-color: #9974d8;
  color: #9974d8;
}

.hex-container-right .hex-purple .textSpan a {
  color: white;
}

.hex-container-right .hex a {
  color: #3e5c76;
}

.hex-container-right .hex-grey {
  background-color: #ebebeb;
  border-color: #ebebeb;
  color: #ebebeb;
}

.hex-container-right .hex-grey a {
  color: #858585;
}

.hex-container-right .hex-grey.assessDelivery:hover,
.hex-container-right .hex-grey.assessCustomer:hover,
.hex-container-right .hex-grey.portfolioCom:hover {
  background-color: #9bc4f0;
  border-color: #9bc4f0;
  color: #9bc4f0;
}

.hex-container-right .hex-grey.trackcon:hover,
.hex-container-right .hex-grey.monitorFinancial:hover,
.hex-container-right .hex-grey.monitorDeal:hover {
  background-color: #ffa12d;
  border-color: #ffa12d;
  color: #ffa12d;
}

.hex-container-right .hex-grey.portfolioGov:hover {
  background-color: #9974d8;
  border-color: #9974d8;
  color: #9974d8;
}

.hex-container-right .hex-grey.portfolioGov:hover h1 {
  color: #ffffff;
}

.hex-container-right .hex-grey.trackcon:hover a,
.hex-container-right .hex-grey.monitorFinancial:hover a,
.hex-container-right .hex-grey.monitorDeal:hover a,
.hex-container-right .hex-grey.assessDelivery:hover a,
.hex-container-right .hex-grey.assessCustomer:hover a,
.hex-container-right .hex-grey.portfolioCom:hover a {
  color: #204f80;
}

/* .hexIn.monitorFinancial h1 {
	top: 30%;
}
.hexIn.assessDelivery h1{
	top: 30%;
} */
/* .hexIn.portfolioCom h1{
	top: 30%;
} */

/*** SPACING AND SIZING ****************************/

@media (min-width: 1201px) {
  /* <- 2-3  hexagons per row */
  .hex {
    width: 32.666%;
    /* = (100-2) / 3 */
    padding-bottom: 37.72%;
    /* =  width / sin(60) */
  }

  .hex:nth-child(5n + 1),
  .hex:nth-child(5n + 2) {
    transform: translateX(50%) rotate(-60deg) skewY(30deg);
  }

  .hex:nth-child(5n + 3),
  .hex:nth-child(5n + 4),
  .hex:nth-child(5n + 5) {
    margin-top: -8.564%;
    margin-bottom: -8.564%;
  }

  .hex:nth-child(5n + 2),
  .hex:nth-child(5n + 4) {
    margin-right: 1%;
    margin-left: 1%;
  }

  .hex:nth-child(5n + 1) {
    margin-left: 0.5%;
  }

  .hex:nth-child(5n + 3),
  .hex:nth-child(5n + 6) {
    clear: left;
  }
}

@media (max-width: 1200px) {
  /* <- 1-2  hexagons per row */
  .hex {
    width: 49.5%;
    /* = (100-1) / 2 */
    padding-bottom: 57.158%;
    /* =  width / sin(60) */
  }

  .hex:nth-child(3n + 1) {
    transform: translateX(50%) rotate(-60deg) skewY(30deg);
  }

  .hex:nth-child(3n + 2),
  .hex:nth-child(3n + 3) {
    margin-top: -13.423%;
    margin-bottom: -13.423%;
  }

  .hex:nth-child(3n + 1) {
    margin-left: 0.5%;
  }

  .hex:nth-child(3n + 3) {
    margin-left: 1%;
  }

  .hex:nth-child(3n + 2),
  .hex:nth-child(3n + 4) {
    clear: left;
  }

  .hex h1 {
    font-size: 12px;
  }
}

@media (max-width: 400px) {
  #hexGrid {
    font-size: 13px;
  }
}

.billed-rev-table .sn-Column {
  width: 11%;
}

.billed-rev-table .task-Column {
  width: 60%;
}

.billed-rev-table tr td:nth-child(3) {
  text-align: center;
}

.billed-rev-table .roles-Column {
  text-align: center;
}

.roles-involved {
  width: 20%;
}

.billed-rev-table span,
.roles-involved-table span {
  margin-right: 10px;
}

.billed-rev-table tr td {
  font-weight: 600;
}

.italic-text {
  margin-top: 10px;
}

.consumptionImg {
  margin-bottom: 30px;
}

.trackConHeading {
  color: #1f3a4e !important;
}

.content-list li {
  margin-bottom: 10px;
}

.phaseRealizeValueTable tr td {
  vertical-align: top;
}

.comingsoon {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.comingsoon h4 {
  color: #1f3a4e;
  font-weight: normal;
}

.monthlyCadanceImg {
  width: 100%;
}

.main-container-td {
  display: flex;
  /* width: 100%;
	height: 100%;
	margin: 0;
	justify-content: flex-start; */
}

#portfolioRobs .cpeSurveyTable tr td,
#portfolioRobs .cpeSurveyTable tr th {
  border-right: 1px solid #c9c9c9;
}

#portfolioRobs .tableList {
  padding-left: 18px;
}

#portfolioRobs h3 {
  color: #1f3a4e;
}

.historyContainer {
  overflow: auto !important;
  min-height: 500px;
}

/* Complex Program changes */
#RoleRaciTable {
  border: 1px solid #c9c9c9;
  border-collapse: collapse;

  width: 90%;
  font-family: Segoe UI;
  margin: 0px 15px 0px 15px;
}

#RoleRaciTable td {
  border: 1px solid #c9c9c9;
  border-collapse: collapse;
  padding: 6px 15px 6px 15px;
  font: normal normal normal 14px/20px Segoe UI;
}

#RoleRaciTable th {
  border: 1px solid #c9c9c9;
  border-collapse: collapse;
  text-align: left;
  padding: 6px 15px 6px 15px;
  color: #6742a6;
}

#leftTable {
  border-collapse: separate !important;
  border-spacing: 0px;
}

#rightTable {
  border-collapse: separate !important;
}

#rightTable td {
  padding: 0px 15px 0px 15px;
}

#rightTable th {
  padding: 0px 15px 0px 15px;
}

.triangle-down {
  width: 0px;
  height: 0px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #a683e4;
  position: relative;
  left: 45%;
  /* top: 2px; */
}

/* Tile 1 */
td.comProgTile {
  border: 1px solid transparent;
}

td.comProgTile:hover {
  box-shadow: 0px 3px 6px #00000029;
  -moz-border-radius: 4px 4px 4px 4px;
  -webkit-border-radius: 4px 4px 4px 4px;
  border-radius: 4px;
  border: 1px solid #ffa12d !important;
  background-color: #ffe9c4 !important;
}

td.comProgTile:hover p {
  background-color: #ffe9c4 !important;
}

td.comProgTile:hover p:first-child {
  text-decoration: underline;
}

.purpleColumn:hover p {
  text-decoration: underline;
  cursor: pointer;
}

.compProgTooltip:before {
  content: "";
  position: relative;
  border-top: 15px solid #a683e4;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  top: 80px;
  left: 0%;
  margin-left: -5px;
  margin-top: -5px;
}

.mrg-top-30 {
  margin-top: 30px;
}

/* Complex Program changes end*/

.hex-container-right #hexGrid a span {
  font-size: 10px;
}

.hex-container-right #hexGrid a span:hover {
  text-decoration: underline;
}

.hexIn span {
  color: #1f3a4e;
  padding: 5%;
}

.hexIn span:hover {
  text-decoration: underline;
}

.hexIn.hex-purple span {
  color: white;
  font-weight: normal;
}

.externalContAccordion ul li {
  list-style: none;
}

.externalContAccordion .accordion {
  width: 100%;
}

.externalContAccordion .accordion > li:first-child {
  border: none;
}

.externalContAccordion .accordion > li {
  position: relative;
}

.externalContAccordion .accordion .link a {
  position: relative;
  display: block;
  padding: 15px 20px;
  color: #1b3746;
  font-weight: bold;
  text-decoration: none;
  font-size: 18px;
}

.externalContAccordion .accordion .link {
  margin: 0;
}

.externalContAccordion .accordion .link {
  background: white;
  font-size: 16px;
  color: #5a82a7;
  font-weight: bold;
  border-top: 1px solid #e4d1b8;
  padding: 8px;
  cursor: pointer;
}

.externalContAccordion .accordion .active .link {
  text-decoration: underline solid #ffa12d 20%;
}

.externalContAccordion .accordion .link:focus {
  outline: 0 !important;
}

.externalContAccordion .externalCont-arrow-icon {
  background: url(../../assets/accordionArrow.png) no-repeat;
  font-size: 15px;
  width: 18px;
  height: 13px;
  color: #5a82a7;
  font-weight: bold;
  display: inline;
  float: right;
  background-size: contain;
  margin-top: 5px;
}

.externalContAccordion .accordion .active .externalCont-arrow-icon {
  transform: rotate(-180deg);
  transition: 0.3s;
}

.submenu {
  display: none;
  background: white;
  margin-top: 15px;
}

.accordion .submenu > li a {
  display: block;
  padding: 10px 15px;
  text-decoration: none;
  color: #1f3a4e;
  padding: 10px 15px 10px 20px;
}

.accordion .submenu > li {
  list-style: disc;
  margin-bottom: 10px;
}

.accordion .submenu > li a:hover {
  background: white;
  color: #1f3a4e;
  padding: 10px 15px 10px 20px;
}

.bannerActivity {
  top: 5px;
  /* height: 25px; */
  height: auto;

  /* UI Properties */
  background: #ffb860 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  display: flex;
  position: relative;
  margin-bottom: 10px;
}

.banner-text {
  letter-spacing: 0.22px;
  color: #030303;
  opacity: 1;
  font-size: 12px;
  text-align: center;
  padding: 2px;
}

.associateContentPanelBusinessRule ul li i {
  font-size: 22px;
  vertical-align: middle;
  margin-right: 2px;
  color: #0078d4;
}

.associateContentPanelBusinessRule ul li {
  list-style: none;
  width: 100%;
  border-bottom: 1px solid #dee2e6;
}

.associateContentPanelBusinessRule ul {
  list-style: none;
  padding-left: 10px !important;
}

.associateContentPanelBusinessRule ul li div:first-child a {
  color: #0262ab !important;
  font-size: 13px !important;
}

.topicDesc {
  display: flex;
}

.polygonDesc {
  /* width: 70%!important; */
  float: left;
}

.polygonShape {
  width: 30% !important;
  float: left;
  padding: 2px;
}
.topicsOwnerDetailsForPreview {
  margin-right: 60px !important;
}

.topicPolygonDescriptionDiv::-webkit-scrollbar {
  display: none;
}

.topicDescriptionDiv::-webkit-scrollbar {
  width: 10px;
}

.topicDescriptionDiv::-webkit-scrollbar-track {
  border-radius: 10px;
}

.topicDescriptionDiv::-webkit-scrollbar-thumb {
  background-color: #c0c0c0;
  border-radius: 10px;
  border: 2px solid transparent;
  background-clip: padding-box;
}

.NewPreviewContainer {
  overflow: auto;
}

.topicLandingMainContainerLatestPreview {
  height: calc(100vh - 12rem);
}

.topicsDescriptionDetails .topicDesc .ck-content {
  padding-top: 10px !important;
}

.hr-tag {
  position: absolute;
  top: 60px;
  width: 93%;
  color: #e2e2e2;
  padding-left: 8px;
}

.hrTagTwo {
  /* width: 93%; */
  color: #e2e2e2;
}

.badge {
  background: #f1f4f9 0% 0% no-repeat padding-box;
  color: #0178d4 !important;
  margin-right: 7px;
  padding: 6px 12px !important;
  margin-bottom: 8px;
}

.badge a {
  color: #0178d4 !important;
  text-decoration: none !important;
  font : normal normal normal 12px/16px Segoe UI !important;
}

.ac-text {
  font: normal normal 600 16px/20px Segoe UI;
  margin-bottom: 0.5rem;
  color: #212121;
}
