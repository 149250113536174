.activityFirstLeftContainer {
    /* background-color: red; */
    border: 2px solid #f2f2f2;
    background-color: white;

}

.activityFirstRightContainer {
    /* background-color: yellow; */
    border: 2px solid #f2f2f2;
    background-color: white;
}

.activityEditButtons {
    text-align: end;
    padding: 10px 0px;
}

.activityButton {
    padding: 7px 27px;
    border-radius: 7px;
    font-size: 12px;
    border: 0px;
    background: #666;
    color: white;
    margin-right: 10px;
}

.activityPublishButton {
    margin-right: 10px;
}

.activityPublishButton button {
    margin-right: 0px !important;
    border: none;
    font-size: 12px;
}

.activityPublishButton i {
    font-size: 12px;
    color: #000;
    vertical-align: middle;
}

.activityPublishButton button {
    background: #f58220 !important;
    color: #000 !important;
}

.activityPublishButton {
    background: #f58220;
    /* padding: 7px 15px; */
    padding: 5.5px 15px 6.5px 15px;
    border-radius: 7px;
    cursor: pointer;
}

.borderStyle {
    /* border-bottom: 2px solid #f2f2f2; */
}

.activityFirstContainer {
    margin: 0px 10px 10px 10px !important;
    border: 0.5px solid #e9e4e4;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgb(208 205 205);

}

/* .containerTitle{
    padding: 5px 5px 5px 0px;
} */
.containerTitle {
    display: flex;
    padding: 10px 0;
    /* UI Properties */
    font: normal normal 600 14px/19px Segoe UI !important;
    letter-spacing: -0.14px;
    color: #333333 !important;
    opacity: 1;
    /* Add 10px padding on top and bottom */
}

.containerTitle h6 {
    margin: 0;
    /* Reset any default margin */
}

.activityOwnersLabel {
    /* font: normal normal 600 14px/19px Segoe UI; */
    /* height: 40px; */
    background: #F2F2F2 0% 0% no-repeat padding-box;
    opacity: 1;
    /* padding: 10px 0px 10px 10px; */
}

.activityLabels {
    text-align: right;
    /* font-size: 14px !important; */
    font: normal normal normal 14px / 19px Segoe UI !important;
    padding-left: 5px !important;
    padding-right: 0px !important;
    /* font-weight: 600; */
}

.activityLabelstoplabels {
    text-align: right;
    /* font-size: 14px !important; */
    font: normal normal normal 14px / 19px Segoe UI !important;
    /* padding-left: 5px !important;
    padding-right: 0px !important; */
    padding: 6px 0px 6px 5px !important;
    /* font-weight: 600; */
    letter-spacing: -0.14px;
    opacity: 1;
}

.RaciTitles {

    margin-bottom: 2px !important;
    font: normal normal normal 13px/17px Segoe UI !important;
    letter-spacing: -0.13px;
    color: #222629;
    opacity: 1;
}

input.NewActivityTitle {
    width: 100%;
    background: #fff;
    border: 1px solid #e8e9ec;
    padding: 5px 10px;
    font-size: 14px;
}

.red-asterisk {
    color: red !important;
}

.activityEditHashtagsInput input {
    border: none !important;
    outline: none !important;
    width: 150%;
    /* Make input take full width of the container */
    font-size: 14px;
    padding: 6px;
    box-sizing: border-box;
    /* Ensure padding is included in the width */
    overflow: hidden;
    /* Prevents the input box from expanding */
    white-space: nowrap;
    /* Prevents text from wrapping to the next line */
    text-overflow: ellipsis;
    /* Adds '...' to indicate overflowed text */
    text-align: left;
    font: normal normal normal 14px/17px Segoe UI;
    letter-spacing: -0.13px;
    color: #333333;
    opacity: 1;
    padding-left: 11px;
}

.activityEditHashtagsInput input::placeholder {
    text-align: left;
    font: normal normal normal 13px/17px Segoe UI;
    letter-spacing: -0.13px;
    color: #848484 !important;
    opacity: 1;
}

.activityEditHashtagsInput {
    height: 31px;
    width: 100%;
    background: #fff;
    border: 1px solid #e8e9ec !important;
    overflow: hidden;
    /* Prevents the container from expanding */
}



/* .activityEditHashtagsInput {
    width: 100%;
    background: #fff;
    border: 1px solid #e8e9ec !important;
} */

/* .activityHashtags .activityEditHashtagsInput .hashtagsDropDownContainerWithTags {
    width: 100%;
	background: #fff;
	border: 1px solid #e8e9ec !important;
	padding: 5px 10px;
  } */
/* .activityEditHashtagsInput input {
    border: none !important;
    outline: none !important;
    width: 40%;
    font-size: 14px;
    padding-left: 10px;
} */
.newActivityEditHashtags .hashTagsWithCancelCheckMark {
    height: 30px;
    /* margin-top: 4px; */
}

.newActivityEditHashtags .hashTagsWithCancelCheckMark .hashtagsEditActivityCancelIcon i {
    font-size: 14px;
    border: 1px solid #e8e9ec !important;
    padding: 4px 10px;
    cursor: pointer;
    /* color: #0178D4; */
    font-weight: 900;
}

.newActivityEditHashtags .hashTagsWithCancelCheckMark .hashtagsEditActivityCancelIcon i:focus {
    outline-offset: -1px;
}

.hashtagsEditActivityCancelIcon {
    display: flex;
    /* max-height: 35px; */
    height: 31px;
    position: relative;
}

.Detailswrapper.activityEditTopic {
    /* min-height: 145px; */
}

ul.afterSaveEditList.beforEditActivitySavedList li {
    /* display: inline-block; */
    display: inline-flex;
    background: #0078d4;
    padding: 3px 13px;
    margin-right: 10px;
    border-radius: 5px;
    margin-bottom: 8px;
    color: white;
    height: 26px;
    text-align: left;
    font: normal normal normal 13px/17px Segoe UI;
    letter-spacing: -0.13px;
}

ul.afterSaveEditList.beforEditActivitySavedList li a:hover {
    color: white;
    text-align: left;
    font: normal normal normal 13px/17px Segoe UI;
    letter-spacing: -0.13px;
    color: #FFFFFF;
    opacity: 1;
}

ul.afterSaveEditList.beforEditActivitySavedList li a {
    color: white;
    text-align: left;
    font: normal normal normal 13px/17px Segoe UI;
    letter-spacing: -0.13px;
    color: #FFFFFF;
    opacity: 1;
}

ul.afterSaveEditList.beforEditActivitySavedList li i {
    font-size: 9px;
    font-weight: bolder;
    margin-top: 2px;
    margin-left: 10px;
}

.ActivityEditMainDiv {
    overflow-y: auto;
    /* Allows vertical scrolling */
    max-height: calc(100vh - 100px);
    overflow-x: hidden;
    /* Hide horizontal scrollbar */
    background-color: #f3f2f2;
}

.activityEditContentDiv {

    margin-top: 15px;
}

.breadCrumbContainer {
    background-color: white;
}

.activityButtonContainer {
    background-color: white;
}

ul.afterSaveListEdit.beforEditSavedListEdit li {
    display: inline-flex;
    background: #0078d4;
    padding: 3px 13px;
    margin-right: 10px;
    border-radius: 5px;
    margin-bottom: 8px;
    height: 26px;
    color: white;
    text-align: left;
    font: normal normal normal 13px/17px Segoe UI;
    letter-spacing: -0.13px;
}

ul.afterSaveListEdit.beforEditSavedListEdit li i {
    font-size: 9px;
    /* margin-left: 10px; */
    color: white;
    font-weight: bolder;
    margin-top: 2px;
    margin-left: 5px;
}

ul.afterSaveListEdit.beforEditSavedListEdit li a {
    /* margin-left: 10px; */
    color: white;
    font-size: 13px !important;
}

input.DetailswrapperNeweditActivityTitle {
    height: 30px !important;
    width: 100%;
    background: #fff;
    border: 1px solid #e8e9ec;
    padding: 5px 10px;
    text-align: left;
    font: normal normal normal 14px/19px Segoe UI;
    letter-spacing: -0.14px;
    /* color: #333333; */
    opacity: 1;
}

.EditInputFields {
    height: 32px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 1px #00000040;
    border: 1px solid #CECECE;
    border-radius: 2px;
    opacity: 1;
    width: 100%;
    padding: 5px;
    margin-bottom: 6px;
    font: normal normal normal 13px/17px Segoe UI !important;
    letter-spacing: -0.13px;
    /* color: #333333; */
    color: #848484;
    opacity: 1;
}

.EditComplexityBtn {
    display: flex;
    justify-content: flex-end;
    padding: 10px 0;
}

.checkbox-list {
    display: flex;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
}



/*  */

.inputSMMIFlag {
    min-height: auto !important;
}

.messageOfEntryExitIcon {
    float: right;
    width: 21px;
    height: 21px;
    background: url(../../assets/i-icon.svg) no-repeat;
    background-size: contain;
    margin: 0 5px;
    cursor: pointer;
}

.descOfMessageOfEntryExit:hover .messageOfEntryExitIcon,
.descOfMessageOfEntryExit:focus .messageOfEntryExitIcon {
    display: none;
}

.editraciChildContainers ul li i {
    font-weight: bolder;
    vertical-align: middle;
    margin-left: 10px;
    font-size: 11px;
    cursor: pointer;
}

.editraciChildContainers {
    margin-top: 2px;
    margin-right: 30px;
    min-height: 109px;
}

.editraciChildContainers ul {
    margin-bottom: 0;
    padding: 10px;
    list-style: none;
}

.editraciChildContainers ul li {
    padding: 5px;
    background: lightblue;
    color: lightblue;


}

.editraciChildContainers .activityRaciRoleGroupContainer {
    padding: 0 5px;
    font-size: 12px;
}

.editraciChildContainers .activityListMainContainer.activityGroupParent {
    margin-top: 5px;
}

.editraciChildContainers {
    max-height: 150px;
    overflow-y: auto;
    padding-bottom: 5px;
}

.editraciChildContainers .activityRaciRoleGroupContainer {
    max-height: initial;
    overflow-y: auto;
}

.editdropDownList {
    position: absolute;
    width: 100%;
    background-color: white;
    border: 1px solid #ccc;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1000;
    left: -1px;
    top: 32px;
}

.editdropDownList ul {
    list-style: none;
    padding: 10px 0px;
    /* padding: 0;
    margin: 0; */
}

.editdropDownList ul li {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0px 10px 5px 10px;
}

.editdropDownList ul li:hover {
    background-color: #f2f2f2;
}

.editdropDownList ul li.complexityLabel {
    font-size: 14px !important;
}


/* .messageOfEntryExitIcon {
	float: right;
	width: 17px;
	height: 29px;
	background: url(../../assets/i-icon.svg) no-repeat;
	background-size: contain;
	margin: 0 5px;
	cursor: pointer;
} */
.messageOfEntryExitHoverIcon {
    display: none;
    float: right;
    width: 21px;
    height: 21px;
    background: url(../../assets/i-icon.svg) no-repeat;
    background-size: contain;
    margin: 0 5px;
    cursor: pointer;
}

/* .descOfMessageOfEntryExit:hover .messageOfEntryExitIcon,
.descOfMessageOfEntryExit:focus .messageOfEntryExitIcon {
	display: none;
} */
.descOfMessageOfEntryExit:hover .messageOfEntryExitHoverIcon,
.descOfMessageOfEntryExit:focus .messageOfEntryExitHoverIcon {
    display: block;
}



.editdropdown-container {
    display: flex;
    align-items: center;
    /* border: 1px solid #ccc; */
    border: 1px solid #E8E9ED;
    opacity: 1;
    padding: 4px 6px;
    border-radius: 4px;
    position: relative;
    height: 30px;
}

.editdropdown-input {
    height: 28px !important;
    border: none;
    outline: none;
    flex-grow: 1;
    font: normal normal normal 12px/16px Segoe UI;
    letter-spacing: -0.12px;
    color: #333333;
    opacity: 1;
}

.editicon-container {
    display: flex;
    align-items: center;
}

.editicon-container .ms-Icon {
    cursor: pointer;
    margin-left: 8px;
}

/* .editdropDownList {
	position: absolute;
	width: 100%;
	background-color: white;
	border: 1px solid #ccc;
	max-height: 200px;
	overflow-y: auto;
	z-index: 1000;
} */

.racidropdownnew {
    display: flex !important;
    align-items: center !important;
    padding: 1px 8px !important;
    margin-top: 6px;
    margin-bottom: 0px;
}

#toolsDropdown ul li label {
    margin-top: 6px;
}

.CustomCheckmark {
    cursor: pointer;
    margin-right: 8px;
    border-radius: 4px;
}

.templateDropdownclass .CustomCheckmark {
    top: 3px !important;
}

.complexityLabel {
    flex-grow: 1;
}

.raci-role-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* flex-direction: column; */
    gap: 5px;
    /* Increased space between each role item */
    /* padding: 5px 7px; */
    /* Space around the entire list */
    /* border: 1px solid #f2f2f2; */
    /* border-top: none !important; */
    /* border: 1px solid #E8E9ED; */
    opacity: 1;
    margin-top: 4px;
}

.raci-role-item {
    height: 26px;
    /* width: max-content; */
    /* width: 100%; */
    max-width: 100%;
    width: fit-content;
    display: flex;
    align-items: center;
    /* background-color: #f5faff; */
    /* Light background color for the role item */
    /* border: 1px solid #f2f2f2; */
    /* border: 2px solid #3191e75e; */
    background: #F1F4F9 0% 0% no-repeat padding-box;
    border: 1px solid #0079D4;
    /* Border color matching the design */
    border-radius: 4px;
    /* Rounded corners */
    padding: 5px 7px;
    /* margin: 2px; */
    /* Increased padding inside the role item */
    /* box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1); */
    /* Subtle shadow for depth */
}

.raci-role-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    /* Ensures the content spans the full width */
}

.raci-role-name {
    /* font-size: 14px; */
    /* Font size for the role name */
    /* color: #0078d4; */
    /* Text color matching the design */
    /* font-weight: 500; */
    /* Medium weight for better readability */
    font: normal normal normal 12px / 16px Segoe UI;
    letter-spacing: -0.12px;
    color: #0178D4;
    opacity: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.raci-role-remove-icon {
    font-weight: bolder;
    margin-top: 2px;
    font-size: 9px;
    /* Size of the remove icon */
    color: #0078d4;
    /* Icon color matching the design */
    cursor: pointer;
    /* Pointer cursor for better UX */
    margin-left: 8px;
    /* Space between the role name and icon */
}

.raci-role-remove-icon:hover {
    color: #005a9e;
    /* Darker shade on hover for better feedback */
}

.acitivityHeaderVersionDiv {
    display: flex;
    /* margin-right: 45px; */
    margin-left: 20px;
    line-height: 29px;
    font-size: 13px;
    flex: 1;
}

.acitivityHeaderVersionDiv ul {
    display: flex;
    list-style: none;
    padding-left: 0px !important;
    border-left: 2px solid #e0dfdf;
    border-right: 2px solid #e0dfdf;
    margin: 0px 5px;
    /* margin: 0 35px 0 1px; */
}

.acitivityHeaderVersionDiv .complexityrightsidespans ul li label.checkBoxContaineredit {
    /* padding-left: 21px !important; */
    /* margin-left: 11px; */
}

.acitivityHeaderVersionDiv ul li label.checkBoxContaineredit span.settingsLabel {
    font-size: 14px;
}

.activityVersioningDiv.acitivityHeaderVersionDiv p {
    margin-bottom: 0;
}

.activityVersioningDiv.acitivityHeaderVersionDiv p:first-child {
    flex: 1;
    margin-left: 56px;
}

.activityVersioningDiv.acitivityHeaderVersionDiv ul li label.checkBoxContaineredit {
    padding-bottom: 0;
}

ul.editactivityComplexityMenuWrapper .activityVersioningDiv.acitivityHeaderVersionDiv ul li {
    padding: 0px 0.5px 10px 5px;

}

.editacitivityCheckmark {
    /* top: 3px !important;
    margin-top: 0px !important;
    width: 10px !important;
    height: 10px !important; */
}

.checkBoxContaineredit .editacitivityCheckmark:after {
    top: 1px !important;
    left: 1px !important;
    height: 6px !important;
    width: 6px !important;
}

.dropDowncontainer.editCategorySelectionDropdown {
    width: 96%;
    background: #fff;
    border: 1px solid #e8e9ec;
}

.dropDowncontainer.editCategorySelectionDropdown input {
    border: none;
    width: 91%;
    font: normal normal normal 14px/19px Segoe UI;
    letter-spacing: -0.14px;
    color: #333333;
    opacity: 1;
}

.dropDowncontainer.editCategorySelectionDropdown i {
    font-size: 12px;
    font-weight: bolder;
}

.div#editCategorySelectionDropdown label.NotificationChkboxContainer {
    font-size: unset !important;
    margin-bottom: 0;
}

div#editCategorySelectionDropdown label.NotificationChkboxContainer input[type='checkbox']:focus {
    width: 15.5px !important;
    height: 15.5px !important;
    top: -1px !important;
    left: -3px !important;
}

div#editCategorySelectionDropdown span.CustomCheckmark {
    top: 2px;
    left: 0;
}

div#editCategorySelectionDropdown {
    width: 40%;
}

.dropDowncontainer.edittopicsDropdown {
    width: 100%;
    /* margin-right: 50px; */
    background: #fff;
    border: 1px solid #e8e9ec;
}

.dropDowncontainer.edittopicsDropdown input {
    border: none;
    width: 94%;
    font: normal normal normal 14px/19px Segoe UI;
    letter-spacing: -0.14px;
    color: #333333;
    opacity: 1;
}

.dropDowncontainer.edittopicsDropdown i {
    font-size: 12px;
    font-weight: bolder;
}

.div#edittopicsDropdown label.NotificationChkboxContainer {
    font-size: unset !important;
    margin-bottom: 0;
}

div#edittopicsDropdown label.NotificationChkboxContainer input[type='checkbox']:focus {
    width: 15.5px !important;
    height: 15.5px !important;
    top: -1px !important;
    left: -3px !important;
}

div#edittopicsDropdown span.CustomCheckmark {
    top: 2px;
    left: 0;
}

div#edittopicsDropdown {
    width: 60%;
}

.neweditActivityfieldsWrapper>div input {
    width: 90%;
    border: 1px solid #e8e9ec;
    padding: 5px 10px;
    font-size: 12px;
}

.neweditActivityfieldsWrapper>div i {
    font-size: 15px;
    font-weight: bolder;
    margin-left: 0px !important;
    cursor: pointer;
}

/* .neweditActivityfieldsWrapper > div {
            display: flex;
        } */
.neweditActivityfieldsWrapper>div input {
    width: 90%;
}

.neweditActivityfieldsWrapper.topicsSelectionDropdown {
    display: flex;
    /* margin-bottom: 20px; */
}

.dropDowncontainer.toolsDropdown {
    width: 100%;
    background: #fff;
    border: 1px solid #e8e9ec;
    padding: 0px 10px !important;
    font-size: 14px !important;
}

.dropDowncontainer.toolsDropdown .dropDownList {
    margin-left: -9px;
}

.templateDropdownclass {
    margin-left: -10px !important;
    margin-top: 2px !important;
}

.toolErrorMsgedit {
    color: red !important;
    float: right;
    margin-right: 393px;
}

.dropDowncontainer.toolsDropdown input {
    border: none;
    width: 97%;
    font: normal normal normal 14px/19px Segoe UI;
    letter-spacing: -0.14px;
    color: #222629;
    opacity: 1;
    padding: 5px 10px 5px 0px;
}

.dropDowncontainer.toolsDropdown i {
    font-size: 12px;
    font-weight: bolder;
}

.div#toolsDropdown label.NotificationChkboxContainer {
    font-size: unset !important;
    margin-bottom: 0;
}

div#toolsDropdown label.NotificationChkboxContainer input[type='checkbox']:focus {
    width: 15.5px !important;
    height: 15.5px !important;
    top: -1px !important;
    left: -3px !important;
}

div#toolsDropdown span.CustomCheckmark {
    top: 2px;
    left: 0;
}

div#toolsDropdown {
    width: 100%;
}

.addeditMoreTools {
    cursor: pointer;
    color: #0078d4;
    font: normal normal normal 12px/16px Segoe UI;
    letter-spacing: -0.12px;
    color: #0178D4;
}

.processGroupDropdown {
    width: 50%;
}

@media screen and (min-width: 1024px) and (max-width: 1150px) {
    .entryEditCriteriaInput {
        width: 86.3%;
        padding: 5px 0px 5px 6px;
        margin: 8px 0px 0px 0px;
        border: 2px solid #f2f2f2;
        font: normal normal normal 14px / 19px Segoe UI;
        letter-spacing: -0.14px;
        color: #222629;
        opacity: 1;

    }

    .ckdivtasks .taskcustomheight {
        width: 96.5% !important;
    }

    .entryEditCriteriadropdown {
        height: 30px;
        width: 100%;
        /* width: 224px !important; */
        margin: 8px 0px 0px 0px;
        /* padding: 5px 0px; */
        /* border: 2px solid #f2f2f2; */
    }

    .entryEditCriteriadropdown .wrapHeader {
        width: auto;
    }

    .exitEditCriteriaInput {
        width: 90%;
        padding: 5px 0px 5px 6px;
        margin: 8px 0px 0px 0px;
        border: 2px solid #f2f2f2;
        font: normal normal normal 14px / 19px Segoe UI;
        letter-spacing: -0.14px;
        color: #222629;
        opacity: 1;


    }

    .entryExitCriteriadropdown {
        height: 30px;
        width: 100% !important;
        margin: 8px 0px 0px 0px;


    }

    .entryExitCriteriadropdown .wrapHeader {
        width: auto;
    }

    .entryEditCriteriaInputdesc {
        width: 86%;
        /* padding: 4px 0px; */
        padding: 4px 0px 4px 8px;
        margin: 4px 0px 0px 0px;
        border: 2px solid #f2f2f2;

    }
}

@media screen and (min-width: 1151px) and (max-width: 1306px) {
    .entryEditCriteriaInput {
        width: 90.3%;
        padding: 5px 0px 5px 6px;
        margin: 8px 0px 0px 0px;
        border: 2px solid #f2f2f2;
        font: normal normal normal 14px / 19px Segoe UI;
        letter-spacing: -0.14px;
        color: #222629;
        opacity: 1;

    }

    .ckdivtasks .taskcustomheight {
        width: 98% !important;
    }

    .entryEditCriteriadropdown {
        height: 30px;
        width: 100%;
        /* width: 224px !important; */
        margin: 8px 0px 0px 0px;
        /* padding: 5px 0px; */
        /* border: 2px solid #f2f2f2; */
    }

    .exitEditCriteriaInput {
        width: 94.2%;
        padding: 5px 0px 5px 6px;
        margin: 8px 0px 0px 0px;
        border: 2px solid #f2f2f2;
        font: normal normal normal 14px / 19px Segoe UI;
        letter-spacing: -0.14px;
        color: #222629;
        opacity: 1;


    }

    .entryExitCriteriadropdown {
        height: 30px;
        width: 100% !important;
        margin: 8px 0px 0px 0px;
    }

    .entryExitCriteriadropdown .wrapHeader {
        width: auto;
    }

    .entryEditCriteriaInputdesc {
        width: 97%;
        /* padding: 4px 0px; */
        padding: 4px 0px 4px 8px;
        margin: 4px 0px 0px 0px;
        border: 2px solid #f2f2f2;

    }
}

@media screen and (min-width:1307px) and (max-width: 1507px) {
    .entryEditCriteriaInput {
        width: 93.2%;
        /* width: 365px !important; */
        padding: 5px 0px 5px 6px;
        margin: 8px 0px 0px 0px;
        border: 2px solid #f2f2f2;
        font: normal normal normal 14px / 19px Segoe UI;
        letter-spacing: -0.14px;
        color: #222629;
        opacity: 1;

    }

    .entryEditCriteriadropdown {
        height: 30px;
        width: 100%;
        /* width: 224px !important; */
        margin: 8px 0px 0px 0px;
        /* padding: 5px 0px; */
        /* border: 2px solid #f2f2f2; */
    }

    .entryExitCriteriadropdown {
        width: 100%;
        margin: 8px 0px 0px 0px;
        height: 30px;
    }

    .exitEditCriteriaInput {
        width: 96.2%;
        padding: 5px 0px 5px 6px;
        margin: 8px 0px 0px 0px;
        border: 2px solid #f2f2f2;
        font: normal normal normal 14px / 19px Segoe UI;
        letter-spacing: -0.14px;
        color: #222629;
        opacity: 1;


    }

    .entryExitCriteriadropdown .wrapHeader {
        width: auto;
    }

    .entryEditCriteriaInputdesc {
        width: 98%;
        /* padding: 4px 0px; */
        padding: 4px 0px 4px 8px;
        margin: 4px 0px 0px 0px;
        border: 2px solid #f2f2f2;

    }

}

@media screen and (min-width: 1508px) and (max-width: 1696px) {
    .entryEditCriteriaInput {
        width: 95%;
        padding: 5px 0px 5px 6px;
        margin: 8px 0px 0px 0px;
        border: 2px solid #f2f2f2;
        font: normal normal normal 14px / 19px Segoe UI;
        letter-spacing: -0.14px;
        color: #222629;
        opacity: 1;
    }

    .entryEditCriteriadropdown {
        height: 30px;
        width: 100%;
        margin: 8px 0px 0px 0px;
        /* padding: 5px 0px; */
        /* border: 2px solid #f2f2f2; */
    }

    .exitEditCriteriaInput {
        width: 98%;
        padding: 5px 0px 5px 6px;
        margin: 8px 0px 0px 0px;
        border: 2px solid #f2f2f2;
        font: normal normal normal 14px / 19px Segoe UI;
        letter-spacing: -0.14px;
        color: #222629;
        opacity: 1;


    }

    .entryExitCriteriadropdown {
        width: 100%;
        margin: 8px 0px 0px 0px;
        height: 30px;
    }

    .entryExitCriteriadropdown .wrapHeader {
        width: auto;
    }

    /* .entryEditCriteriaInputdesc{
        width: 600px !important;
        padding: 4px 0px;
        margin:5px 0px;
        border: 2px solid #f2f2f2; 
    
    } */
    .entryEditCriteriaInputdesc {
        width: 100%;
        /* padding: 4px 0px; */
        padding: 4px 0px 4px 8px;
        margin: 5px 0px;
        border: 2px solid #f2f2f2;

    }

}

@media screen and (min-width: 1697px) {
    .entryEditCriteriaInput {
        width: 96%;
        padding: 5px 0px 5px 6px;
        margin: 8px 0px 0px 0px;
        border: 2px solid #f2f2f2;
        font: normal normal normal 14px / 19px Segoe UI;
        letter-spacing: -0.14px;
        color: #222629;
        opacity: 1;

    }

    .entryEditCriteriadropdown {
        width: 100%;
        margin: 8px 0px 0px 0px;
        height: 30px;


        /* padding: 5px 0px; */
        /* border: 2px solid #f2f2f2; */
    }

    .exitEditCriteriaInput {
        width: 100%;
        padding: 5px 0px 5px 6px;
        margin: 8px 0px 0px 0px;
        border: 2px solid #f2f2f2;
        font: normal normal normal 14px / 19px Segoe UI;
        letter-spacing: -0.14px;
        color: #222629;
        opacity: 1;


    }

    .entryExitCriteriadropdown {
        height: 30px;
        width: 100%;
        margin: 8px 0px 0px 0px;


    }

    .entryExitCriteriadropdown .wrapHeader {
        width: auto;
    }

    .entryEditCriteriaInputdesc {
        width: 102%;
        /* padding: 4px 0px; */
        padding: 4px 0px 4px 8px;
        margin: 5px 0px;
        border: 2px solid #f2f2f2;

    }
}



div.entryExitCriteriadropdown div.dropDownBox {
    height: 30px;
    padding: 2px 9px !important;
    border: 2px solid #f2f2f2;
    font: normal normal normal 14px / 19px Segoe UI;
    letter-spacing: -0.14px;
    color: #222629;
    opacity: 1;
}

div.entryEditCriteriadropdown div.dropDownBox {
    height: 30px;
    padding: 2px 9px !important;
    border: 2px solid #f2f2f2;
    font: normal normal normal 14px / 19px Segoe UI;
    letter-spacing: -0.14px;
    color: #222629;
    opacity: 1;

}

.AddEntryBtn {
    right: 13px !important;
}

.relatededitContentDelete {
    width: 30px;
    height: 30px;
    /* background: #FFFFFF 0% 0% no-repeat padding-box; */
    border: 0px solid #CECECE;
    opacity: 1;
    position: absolute;
    right: 48px;
    background: url('../../assets/CrossIcon.svg');
}

.relatedContentAdd {
    width: 30px;
    height: 30px;
    /* background: #FFFFFF 0% 0% no-repeat padding-box; */
    border: 0px solid #CECECE;
    opacity: 1;
    position: absolute;
    /* bottom: 19px; */
    right: 15px;
    background: url('../../assets/AddIcon.svg');
}

.relatededitintitalContentDelete {
    width: 32px;
    height: 32px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #CECECE;
    opacity: 1;
    position: absolute;
    right: 48px;
}

.taskalignment {
    margin-top: 126px !important;
}

.dropDowncontainer.templateseditList {
    width: 100%;
    background: #fff;
    border: 1px solid #e8e9ec;
    padding: 2px 10px;
    font-size: 14px;
}

.dropDowncontainer.templateseditList input {
    border: none;
    width: 97%;
}

.dropDowncontainer.templateseditList i {
    font-size: 12px;
    font-weight: bolder;
}

.div#templateseditList label.NotificationChkboxContainer {
    font-size: unset !important;
    margin-bottom: 0;
}

div#templateseditList label.NotificationChkboxContainer input[type='checkbox']:focus {
    width: 15.5px !important;
    height: 15.5px !important;
    top: -1px !important;
    left: -3px !important;
}

div#templateseditList span.CustomCheckmark {
    top: 2px;
    left: 0;
}

div#templateseditList {
    width: 100%;
}

ul.newActivityeditTemplateList li .newActivityTemplateListItem {
    background: #F2F2F2 0% 0% no-repeat padding-box;
    opacity: 1;
    padding: 6px 10px;
    width: 90%;
    display: flex;
    align-items: center;
    /* margin-top: 10px; */
}

ul.newActivityeditTemplateList li {
    display: flex;
    flex-direction: column;
}

ul.newActivityeditTemplateList li .addNewTemplatesToActivitynew {
    margin-top: 0;
}

ul.newActivityeditTemplateList li img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    margin-bottom: 0;
}

ul.newActivityeditTemplateList li i {
    font-weight: bold;
    margin-left: 10px;
    cursor: pointer;
}

ul.newActivityeditTemplateList li span {
    margin-top: 0px;
}

.newActivityTemplateListItemEditacForm {
    display: flex;
    width: 100%;
    background: #F2F2F2 0% 0% no-repeat padding-box;
    opacity: 1;
    border: 1px solid #ebe8e8;
    ;
}

.deleditTemplateAccessabilityDiv {
    display: flex;
    padding: 5px;
    box-shadow: 0px 1px 4px #0000001f;
    margin-bottom: 8px;
    background: #fff;
    width: 100%;
    margin-top: 5px;
}

.deleditTemplateAccessabilityDiv>div:first-child {
    flex: 1;
    line-height: 27px;
    font-weight: 600;
}

.deleditTemplateAccessabilityDiv .accessabilityCheckDiv {
    margin-right: 10px;
    margin-top: 4px;
}

.entryExitCriteriadiv .deleditTemplateAccessabilityDiv {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 0;
}

.DetailswrapperTitle .activityEditor {
    width: auto;
}

/* .menuedititem li{
    font-size: 13px !important;
} */


.menuedititem li {
    list-style: none;
    /* Remove default list styling */

}

.menuedititem li:hover {
    background-color: #e0e0e0;
    /* Change background color on hover */
    border-color: #ccc;
    /* Darken border color on hover */
}

.menuedititem li label {
    display: flex;
    /* Use flexbox for aligning content */
    align-items: center;
    /* Center items vertically */
    cursor: pointer;
    /* Change cursor to pointer to indicate clickability */
}

.menuedititem li .NotificationChkboxContainer {
    display: flex;
    /* Use flexbox to align checkbox and label */
    align-items: center;
    /* Center items vertically */
}

.menuedititem li input[type="checkbox"] {
    margin-right: 10px;
    /* Add space between checkbox and label */
}

.menuedititem li .complexityLabel {
    font-size: 13px;
    /* Adjust font size */
    color: #333;
    /* Dark text color */
    padding-top: 0px;
    padding-left: 0px;
}

.dropDownList.userActivityDropdown {
    margin-top: -31px !important;
}

.activityracilabel {
    font-size: 13px !important;
}

.acitivityracicheckbox {
    margin-top: -9px !important;
}

ul.editactivityComplexityMenuWrapper li.activeComplexity {
    border-bottom: 4px solid #f58220;
    font-weight: 600;
    background: #F5F5F5 0% 0% no-repeat padding-box;
    opacity: 1;
    font: normal normal 600 16px/21px Segoe UI;
    letter-spacing: -0.16px;
    color: #333333;
    opacity: 1;
    height: 42px;
}

/* ul.editactivityComplexityMenuWrapper li {
	float: left;
	padding: 10px 7px 4px 11px;
	list-style: none;
	text-align: center;
	cursor: pointer;
	color: #666;
} */
ul.editactivityComplexityMenuWrapper {
    float: left;
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
}

ul.editactivityComplexityMenuWrapper li.activeComplexity {
    border-bottom: 4px solid #f58220;
    font-weight: 600;
}

ul.editactivityComplexityMenuWrapper li {
    float: left;
    /* padding: 10px 6.5px 4px 11px; */
    /* padding: 36px; */
    height: 40px;
    width: 140px;
    list-style: none;
    text-align: center;
    cursor: pointer;
    padding: 9px;
    font: normal normal normal 16px/21px Segoe UI;
    letter-spacing: -0.16px;
    color: #707070;
    opacity: 1;
}

.relatedContentAnchoractivity {
    /* display: flex;
    background: #f1f5f9;
    width: 98%;
    border-radius: 3px;
    border: 1px solid #4d87f6;
    text-decoration: none;
    margin-top: 7px; */

    display: flex;
    align-items: center;
    background-color: #f5faff;
    /* border: 2px solid #3191e75e;
     */
    border: 1px solid #0079D4;
    background: #F1F4F9 0% 0% no-repeat padding-box;
    font: normal normal normal 12px/16px Segoe UI;
    border-radius: 4px;
    margin-top: 5px;
    padding: 7px 7px;
    /* box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1); */

    width: 92%;
    margin-left: 14px;
    margin-right: 14px;
    overflow: hidden;
    /* Prevents overflow */
    text-overflow: ellipsis;
    /* Adds the dots (...) */
    white-space: nowrap;
    /* Prevents the text from wrapping */



}

.acitivityOwnerRemove {
    font-size: 9px !important;
    font-weight: bolder;
    color: #707070;
    margin-left: 4px;
    top: -4px;
    position: relative;
}

.relatedContentAnchoractivity:hover {
    text-decoration: none;
}

/* .relatedContentAnchoractivity span {
    padding:5px 16px;
} */


.addNewTemplatesToActivitynew {
    display: flex;
    flex-direction: column;
    background: #fff;
    padding: 0px 20px 0px 20px;
    margin-bottom: 20px;
}

.addNewTemplatesToActivitynew .dropDowncontainer.phasesDropdown {
    margin-right: 0;
    margin-left: 15px;
}

.addNewTemplatesToActivitynew .dropDowncontainer.phasesDropdown input {
    padding: 5px 10px;
    font-size: 12px;
}

.addNewTemplatesToActivitynew .dropDownBox {
    margin-top: 0;
    padding: 4px 9px !important;
    border: none;
}

.addNewTemplatesToActivitynew .dropDownBox i {
    font-size: 9px;
}

/* .addNewTemplatesToActivitynew button.dragDropButton {
    margin-left: 20px;
} */

.addNewTemplatesToActivitynew .entryCriteriaDdown {
    width: 50%;
}

.modal-body-acitivity-scrollable {
    max-height: 450px;
    /* Adjust the height as needed */
    overflow-y: auto;
}

.placeholderraci {
    font-size: 14px !important;
}

button.activityeditSaveasDraftDpButton {
    position: absolute;
    font-size: 12px;
    padding: 5px 16px 5px 9px;
    top: 25px;
    left: 0px !important;
    white-space: nowrap;
    background: #f58220;
    /* padding: 7px 15px; */
    /* padding: 5.5px 15px 6.5px 15px; */
    /* border-radius: 7px; */
    border: none;
}

/* button.activityeditSaveasDraftDpButton {
	padding: 6px 26px !important;
} */


.dropDowncontainer.editeditproceduresCategorySelectionDropdown {
    width: 96%;
    background: #fff;
    border: 1px solid #e8e9ec;
}

.dropDowncontainer.editeditproceduresCategorySelectionDropdown input {
    border: none;
    width: 91%;
    font: normal normal normal 14px/19px Segoe UI;
    letter-spacing: -0.14px;
    color: #333333;
    opacity: 1;
}

.dropDowncontainer.editeditproceduresCategorySelectionDropdown i {
    font-size: 12px;
    font-weight: bolder;
}

.div#editeditproceduresCategorySelectionDropdown label.NotificationChkboxContainer {
    font-size: unset !important;
    margin-bottom: 0;
}

div#editeditproceduresCategorySelectionDropdown label.NotificationChkboxContainer input[type='checkbox']:focus {
    width: 15.5px !important;
    height: 15.5px !important;
    top: -1px !important;
    left: -3px !important;
}

div#editeditproceduresCategorySelectionDropdown span.CustomCheckmark {
    top: 2px;
    left: 0;
}

div#editeditproceduresCategorySelectionDropdown {
    width: 40%;
}

.dropDowncontainer.editproceduresDropdown {
    width: 100%;
    /* margin-right: 50px; */
    background: #fff;
    border: 1px solid #e8e9ec;
}

.dropDowncontainer.editproceduresDropdown input {
    border: none;
    width: 94%;
    font: normal normal normal 14px/19px Segoe UI;
    letter-spacing: -0.14px;
    color: #333333;
    opacity: 1;
}

.dropDowncontainer.editproceduresDropdown i {
    font-size: 12px;
    font-weight: bolder;
}

.div#editproceduresDropdown label.NotificationChkboxContainer {
    font-size: unset !important;
    margin-bottom: 0;
}

div#editproceduresDropdown label.NotificationChkboxContainer input[type='checkbox']:focus {
    width: 15.5px !important;
    height: 15.5px !important;
    top: -1px !important;
    left: -3px !important;
}

div#editproceduresDropdown span.CustomCheckmark {
    top: 2px;
    left: 0;
}

div#editproceduresDropdown {
    width: 60%;
}

.viewiconacitivity {
    margin-left: 5px;
    margin-top: 4px;
    padding-right: 4px;
}

.mediumstylecomponent {
    margin-left: 125px !important;
}

.neweditActivityTemplateListItemIcons {
    display: flex;
    /* margin-top: 15px !important; */
    align-items: center;
}

span.neweditActivityTemplateListItemIcons button {
    border: none;
    background: none;
    padding-right: 0px;
}

.mergedPhaseLandPage span.activityEditArrangeMode {
    margin-right: 150px;
}

.neweditActivityTemplateListItemIcons a i {
    margin-left: 4px !important;
    width: 10px;
    height: 22px;
}

.neweditActivityTemplateListItemIcons a {
    display: flex;
}

.neweditActivityTemplateListItemIcons i[data-icon-name='Cancel'] {
    margin-left: 10px;
    margin-top: 2px;
}

#addTemplateContainerId .neweditActivityTemplateListItemIcons a i {
    width: 15px;
    margin: 0 5px;
}

#addTemplateContainerId .neweditActivityTemplateListItemIcons a {
    padding-right: 5px;
}

#addTemplateContainerId .neweditActivityTemplateListItemIcons i[data-icon-name='Cancel'] {
    margin-left: 2px;
}

i.editActivityTemplateIcon {
    font-size: 12px;
    float: right;
}

.editprocessdropdown {
    position: static !important;
}

.hashTagsContaineredit>span {
    border: 1px solid #e4e4e4;
    padding: 3px 9px;
    border-radius: 5px;
    margin: 4px 10px 2px 0px;
    display: inline-flex;
    font-size: 13px;
    background: #0078d4;
    color: #fff;
    height: 26px;
    font: normal normal normal 13px / 17px Segoe UI;
    letter-spacing: -0.13px;
}

.hashTagsContaineredit span i {
    font-size: 9px !important;
    font-weight: bolder;
    margin-left: 8px;
    cursor: pointer;
    margin-top: 2px;
    padding: 0;
    border: none;
}

.hashTagsContaineredit span i:focus {
    outline: 1px dotted #fff !important;
}

.dropDowncontainer.hashtagsDropDownContainer .hashTagsContaineredit input {
    width: 150px;
}

.hashTagsContaineredit {}

.editusersdropdown {
    background-color: white;
    padding: 1px 5px 1px 1px;
    border-radius: 31px;
    width: fit-content;
    border: 1px solid #e8e9ec;
    display: flex;
    height: 26px;
}

.editusersdropdown .collabaratorPersonaImage {
    position: relative;
    top: -6px;
    margin-left: 2px;
}

.editusersdropdown .collabaratorPersonaImage img {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin: 0px;
}

.editusersdropdown .collabaratorPersonaTitle {
    text-align: left;
    font: normal normal normal 13px/17px Segoe UI;
    letter-spacing: -0.13px;
    color: #222629;
    opacity: 1;
    padding-top: 2px;
}

.usercolorchnageedit {
    background-color: rgb(238, 234, 234) !important;
}

.addEditRightLabel {
    /* font: normal normal normal 13px/17px Segoe UI !important;
    letter-spacing: -0.13px; */
    /* color: #222629 !important; */
    height: 17px;
    text-align: left;
    font: normal normal normal 14px/19px Segoe UI;
    letter-spacing: -0.14px;
    color: #222629;
    opacity: 1;
}

.racidropdowncontainer {
    margin-top: 12px !important;
}

.racidropdowndowncontainer {
    margin-bottom: 12px !important;
}

.complexitiesmaindiv {
    display: flex;
    /* flex-direction: column; */
    justify-content: space-between;
    /* Ensures the div is at the bottom */
    height: 100%;
}

.activityVersioningLi {
    display: flex;
    align-items: center;
}

.complexityrightsidespans {
    font: normal normal normal 14px/19px Segoe UI;
    letter-spacing: -0.14px;
    color: #707070;
    opacity: 1;
    display: flex;
    align-items: center;
}

.complexityrightsidespans ul {
    list-style: none;
    /* Removes default bullet points */
    padding: 0;
    /* Removes padding */
    margin: 0;
    /* Removes margin */
}

.complexityrightsidespans ul li {
    text-align: center;
    /* Centers the text inside the list item */
}

.complexityrightsidespans {
    display: flex;
    /* Enables Flexbox */
    justify-content: center;
    /* Centers ul horizontally */
}

.complexityrightsidespans ul li {
    display: flex;
    /* Enables Flexbox for li */
    justify-content: center;
    /* Centers items horizontally inside li */
    align-items: center;
    /* Centers items vertically inside li */
    margin: 10px 0;
    /* Optional: adds spacing between li items */
}

.checkBoxContaineredit {
    display: flex;
    /* Flexbox for the label */
    align-items: center;
    /* Centers the radio button and label vertically */
    justify-content: center;
    /* Centers the content horizontally */
}

.editacitivityCheckmarkradio {
    position: absolute !important;
    height: 10px;
    width: 10px;
    margin-left: -106px;
    margin-top: 2px;
}

.taskcustomheight .ck-editor__editable_inline {
    min-height: 80px;
}

.taskmaincontainer {
    display: flex;
    align-items: flex-start;
    margin-bottom: 8px !important;
    /* Align the second div at the bottom of the first div */
}

.ckdivtasks {
    display: flex;
    flex-direction: column;
}

.ckdivtasksicon {
    display: flex;
    align-items: flex-end;
    /* Ensure button is aligned at the bottom of the first div */
    justify-content: flex-end;
    /* Place the button at the bottom right */
}

.relatededitContentDelete {
    margin-left: 10px;
    /* Adjust the margin between the first div and the button */
}

.taskmaincontainer .align-bottom {
    align-self: flex-end;
    /* Ensure the button aligns to the bottom */
}

.acitivityeditStyle {
    min-height: 0 !important;
}

.Racisearchicon {
    width: 20px;
    height: 20px;
    background: url(../../assets/Search-raci.svg) no-repeat;
    background-size: contain;
}

/* .Dropdownraciicon{
    width: 20px;
	height: 20px;
	background: url(../../assets/racidropdown1.svg) no-repeat;
	background-size: contain;
} */
.menuedititemtopics {
    left: -1px !important;
}

.newToolDialogHeading {
    font: normal normal 600 22px/30px Segoe UI !important;
    letter-spacing: -0.22px;
    color: #333333;
    opacity: 1;
}

.templateInnerContent {
    font: normal normal normal 13px / 17px Segoe UI !important;
    letter-spacing: -0.13px;
    color: #333333;
}

.dropDowncontainer.templateTypeDropdown {
    font: normal normal normal 13px / 17px Segoe UI !important;
    letter-spacing: -0.13px;
    color: #333333;
}

.dropDowncontainer.templateFocusDropdown {
    font: normal normal normal 13px / 17px Segoe UI !important;
    letter-spacing: -0.13px;
    color: #333333;
}

.dropDowncontainer.complexityDropdown {
    font: normal normal normal 13px / 17px Segoe UI !important;
    letter-spacing: -0.13px;
    color: #333333;

}

.cancelActivityToolContent {
    /* background: #FFFFFF 0% 0% no-repeat padding-box !important; */
    border: 1px solid #CECECE;
    background: #666666 0% 0% no-repeat padding-box;
    border-radius: 8px;
    opacity: 1;
    text-align: left;
    font: normal normal 600 14px/19px Segoe UI;
    letter-spacing: -0.16px;
    /* color: #333333; */
    color: #FFFFFF;
    padding: 5px 25px;
}

.addBtnActivityToolContent {
    background: #0079D4 0% 0% no-repeat padding-box;
    border-radius: 8px;
    opacity: 1;
    text-align: left;
    font: normal normal 600 14px/19px Segoe UI;
    letter-spacing: -0.16px;
    color: #FFFFFF;
    padding: 4px 30px;
    margin-left: 5px;
    border-color: #0079D4;
}

.addToolModalFooter {
    border-top: none !important;
    padding: 0px 16px 10px 16px !important;
}

.addNewTemplatesFieldcheckbox .NotificationChkboxContainer {
    display: block;
    position: relative;
    cursor: pointer;
    /* font-size: 22px; */
    -webkit-user-select: none;
    user-select: none;
    text-align: left;
    font: normal normal normal 13px/17px Segoe UI;
    letter-spacing: -0.13px;
    color: #333333;
    opacity: 1;
}

.addNewTemplatesFieldcheckbox .NotificationChkboxContainer .CustomCheckmark {
    top: 2px;
}

.addNewTemplatesFieldcheckbox .NotificationChkboxContainer .CustomCheckmark:after {
    top: -2px;
}

.addNewTemplatesFieldsName span {
    text-align: left;
    font: normal normal 600 14px/19px Segoe UI;
    letter-spacing: -0.14px;
    color: #333333;
    opacity: 1;
}

.addNewTemplatesFieldsName input {
    height: 32px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 1px #00000040;
    border: 1px solid #E6E9EE;
    border-radius: 2px;
    opacity: 1;
    text-align: left;
    font: normal normal normal 13px/17px Segoe UI;
    letter-spacing: -0.13px;
    color: #333333;
}

.addNewTemplatesFields .addNewTemplateFieldsLabel {
    text-align: left;
    font: normal normal 600 14px/19px Segoe UI;
    letter-spacing: -0.14px;
    color: #333333;
    opacity: 1;
}

.addNewTemplatesFields>div {
    width: 50%;
}

#templateTypeDropdown {
    height: 30px;
    margin-top: 4px;
}

.addNewTemplatesFields .dropDowncontainer.templateFocusDropdown,
.addNewTemplatesFields .dropDowncontainer.phasesDropdown {
    margin-left: 0px;
    margin-right: 0px;
}

.addNewTemplatesFields .deliverablesPhaseContainer {
    border: none;
    /* border: 1px solid #e8e9ec; */
    height: 30px;
    margin-left: 0px !important;
    margin-top: 4px;
}

.addNewTemplatesFields .addNewTemplatesDescription textarea {
    width: 100% !important;
    border: 1px solid #e4e4e4;
    margin-bottom: 0px;
    min-height: 75px;
    font: normal normal normal 14px / 17px Segoe UI;
    letter-spacing: -0.13px;
    color: #333333;
    margin-top: 4px;
}

.addNewTemplatesFields .addNewTemplatesDescription p {
    text-align: left;
    font: normal normal normal 13px/17px Segoe UI;
    letter-spacing: -0.13px;
    color: #333333;
    opacity: 1;
    margin-bottom: 5px;
}

.addNewTemplatesFile {
    display: flex;
    flex-direction: column;
}

.addNewTemplatesFile .addNewTemplateFieldsLabel {
    text-align: left;
    font: normal normal 600 14px / 19px Segoe UI;
    letter-spacing: -0.14px;
    color: #333333;
    opacity: 1;
    margin-top: 4px;
}

.addNewTemplateFileLabel {
    text-align: center;
}

.addMarginNewTemplate {
    margin-right: 30px !important;
}

input::placeholder {
    text-align: left;
    font: normal normal normal 13px/17px Segoe UI !important;
    letter-spacing: -0.13px;
    color: #848484 !important;
    opacity: 1;
}

.activityEditPage input {
    height: 30px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    /* box-shadow: 0px 0px 1px #00000040; */
    /* border: 1px solid #E6E9EE; */
    border-radius: 2px;
    opacity: 1;

    text-align: left;
    font: normal normal normal 13px/17px Segoe UI !important;
    letter-spacing: -0.13px;
    color: #333333 !important;
}

.ownerCollaborator li {
    background: #E6E9EE 0% 0% no-repeat padding-box;
    border: 1px solid #E8E9ED;
    border-radius: 12px;
    opacity: 1;
}

.addNewTemplatesFieldsName {
    margin-top: 4px;
}

.addNewTemplatesWithOutOutputdata {
    margin-bottom: 4px;
}

#templateFocusDropdown,
#templateEditFocusDropdown {
    margin-top: 4px;
}

div#templateEditFocusDropdown .dropDowncontainer.templateEditFocusDropdown {
    margin-left: 0px;
}

#complexityDropdown,
#templateEditTypeDropdown {
    margin-top: 4px;
}

.newActivityEditHashtags .dropDowncontainer.hashtagsDropDownContainerWithTags {
    height: 30px;
}

.addNewTemplatesFields.addNewTemplatesFieldsHashTags input {
    height: 28px !important;
}

.newActivityEditHashtags .hashTagsWithCancelCheckMark .hashtagsCancelIcon i {
    font-size: 14px;
    border-top: 1px solid #3b3d41;
    border-bottom: 1px solid #3b3d41;
    border-right: 1px solid #3b3d41;
    padding: 3px 8px;
    cursor: pointer;
}

.addNewActivityHashtags .dropDowncontainer.hashtagsDropDownContainerWithTags {
    width: 100%;
    height: 30px;
    margin-right: 0;
    display: flex;
    border: 1px solid #3b3d41;
    flex-wrap: wrap;
    background: #fff;
}

.addNewActivityHashtags .hashtagsCancelIcon {
    height: 30px;
}

.newEditActivityAttributes .NewActivitycomplexityWRapper ul li span.filterLabel {
    padding-left: 15px;
}

.newEditActivityComplexity .CustomCheckmark {
    top: 1px;
}

.newEditActivityBusinessRule .addPolygonTopicFieldContenUrl {
    width: 45.2%;
}

.newEditActivityBusinessRule .addPolygonTopicFieldContenUrl input {
    height: 30px;
}

.relatedContentAnchoractivity span {
    overflow: hidden;
    /* Prevents overflow */
    text-overflow: ellipsis;
    /* Adds the dots (...) */
    white-space: nowrap;
    /* Prevents the text from wrapping */
    display: inline-block;
    /* Ensures the span behaves properly for ellipsis */
    width: 100%;
    /* Ensures the span respects the parent width */
}