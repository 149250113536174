.polygonTopicBuilder {
	border-top: 1px solid lightgrey;
    width: 100%;
    display: flex;
    flex-direction: row;
    height: 436px;
}
.selectedCategoryLabel{
    font: normal normal normal 16px/21px Segoe UI;
    letter-spacing: -0.16px;
    color: #222629;
    opacity: 1;
}
.polygonTopicCateogry{
    padding-bottom: 20px;
}
.selectedTopicCategory {
    text-align: left;
    font: normal normal normal 13px Segoe UI;
    letter-spacing: -0.13px;
    color: #6C6C6C;
    opacity: 1;
    margin-top: 5px;
}
.polygonSelectionNote {
    text-align: left;
    font: normal normal normal 13px/17px Segoe UI;
    letter-spacing: -0.13px;
    color: #6C6C6C;
}
.polygonSelection {
    display: flex;
    flex-direction: row;
    margin-top: 6px;
}
.polygonSelection i, i:focus {
    outline: none !important;
}
.polygonSelection button {
    border: 1px solid #707070;
    background: #F1F5F9 0% 0% no-repeat padding-box;
    padding: 3px 10px;
    border-radius: 3px;
}
.polygonSelection button:first-child{
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
}
.polygonSelection button:last-child{
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
}
.polygonSelection input {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    opacity: 1;
    padding: 3px 10px;
    width: 30px;
}
.polygonSelectionBuilder{
    width: 74%;
    padding: 10px 14px;
}
.polygonSelectedShape{
    width: 26%;
    padding-top: 14px;
    display: flex;
    justify-content: center;
    min-width: 310px;
}
.polygonPreviewArea{
    width: 100%;
    height: 280px;
}
.polygonTopicslisting{
    height: 240px;
    overflow-y: auto;
    margin-top: 10px;
}
.polygonTopicslisting table{
    width: 100%;
}
.polygonTopicslisting tr:nth-child(odd) {
    background: #F1F5F9 0% 0% no-repeat padding-box;
    color: #fff;
}
.polygonTopicslisting td{
    border: 1px solid #707070;
}
.polygonTopicslisting td{
    text-align: left;
    font: normal normal normal 16px/21px Segoe UI;
    letter-spacing: -0.16px;
    color: #222629;
    opacity: 1;
    padding: 6px;
}
.deletePopupPolygon{
    max-width: 100% !important;
    display: flex !important;
    position: absolute !important;
    bottom: 0px !important;
    width: 100% !important;
    margin: 0px !important;
}
.cancelPolygondelete{
    float: right;
}
.deletePopupPolygonContainer{
    display: flex;
    flex-direction: row;
}
.alertIconPolygon{
    height: 40px;
    width: 40px;
    background: url(../../assets/Pollygon/icon-Alert.svg) no-repeat;
    float: left;
	background-size: contain;
	margin: 4px 15px 0px 0px;
}
.deletePolygonPopupButtons{
    text-align: center;
    margin: 10px 70px;
}
.deleteRejectButton{
    background: #BFBFBF 0% 0% no-repeat padding-box !important;
    border-radius: 3px;
    opacity: 1;
    font: normal normal normal 14px/19px Segoe UI ;
    letter-spacing: -0.14px;
    color: #21242B !important;
    padding: 2px 20px !important;
    margin-right: 5px;
}
.deleteAcceptButton{
    background: #0178D4 0% 0% no-repeat padding-box !important;
    border-radius: 3px;
    opacity: 1;
    font: normal normal normal 14px/19px Segoe UI;
    letter-spacing: -0.14px;
    color: #FFFFFF;
    padding: 2px 20px !important;
}