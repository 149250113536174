.hide {}

#feedbackComp {
	position: fixed;
	width: 150px;
	transition: all 100ms ease 0s;
	right: -150px;
	top: 151px;
	border-radius: 0px 0px 0px 5px;
	-webkit-box-shadow: -2px 2px 14px -4px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: -2px 2px 14px -4px rgba(0, 0, 0, 0.75);
	box-shadow: 1px 2px 14px -4px rgba(0, 0, 0, 0.75);
}

#feedbackComp.expand {
	position: fixed;
	width: 150px;
	transition: all 100ms ease 0s;
	top: 151px;
	right: 0px;
}

#feedbackComp {
	background-color: #DE6804;
	color: #fff;
	z-index: 99;
}

#feedbackComp .handle {
	background-color: #DE6804;
	left: -33px;
	position: absolute;
	top: 0px;
	cursor: pointer;
	border-radius: 5px 0px 0px 5px;
}

#feedbackComp .handlebrdShadowoutside {
	-webkit-box-shadow: -3px 0px 4px 1px rgba(189, 182, 189, 1);
	-moz-box-shadow: -3px 0px 4px 1px rgba(189, 182, 189, 1);
	box-shadow: -3px 0px 4px 1px rgba(189, 182, 189, 1);
}

#feedbackComp .handlebrdShadowinside {
	-webkit-box-shadow: inset -11px 0px 11px -8px rgb(136, 136, 136);
	-moz-box-shadow: inset -11px 0px 11px -8px rgb(136, 136, 136);
	box-shadow: inset -11px 0px 11px -8px rgb(136, 136, 136);
}

.feedbackAndFavoriteDiv {
	display: flex;
	font-size: 12px;
	cursor: pointer;
}

.globalFeedbackThumbUp {
	width: 18px;
	height: 19px;
	float: left;
	background: url(../../assets/ThumsUpOrangeFeedback.svg) no-repeat;
	background-size: contain;
	margin-bottom: 5px;
}

.feedbackDiv,
.globalFavoriteDiv {
	padding: 15px 13px 15px 15px;
	display: flex;
	flex-direction: column;
	align-items: center;
	color: #000;
}

.globalFavoriteIcon {
	width: 18px;
	height: 19px;
	float: left;
	background: url(../../assets/favoritesSolidWhite.svg) no-repeat;
	background-size: contain;
	margin-bottom: 5px;
}

.globalFavoriteOutlineIcon {
	width: 18px;
	height: 19px;
	float: left;
	background: url(../../assets/globalFavoriteOutline.svg) no-repeat;
	background-size: contain;
	margin-bottom: 5px;
}

div.feedbackIcon {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.filledStars i {
	font-size: 6px;
	margin-right: 3px;
}

span.filledStars {
	display: flex;
	border-top: 1px solid #cacaca;
	padding: 1px 3px;
	margin-left: 2px;
}

.feedbackIcon .globalFeedbackThumbUp {
	width: 13px;
	height: 13px;
	margin-bottom: 4px;
	margin-top: 4px;
}

#feedbackComp.expand div.feedbackIcon {
	display: none;
}

.crossIcon i {
	font-size: 15px;
	padding: 5px 9px;
}

div.crossIcon {
	display: none;
}

.handle.handlebrdShadowoutside.handlebrdShadowinside div.crossIcon {
	display: block;
}

.deliveryMargin #feedbackComp.expand {
	position: absolute;
}

.deliveryMargin div#feedbackComp {
	position: absolute;
	top: 2px;
}

.handle.handlebrdShadowoutside:focus,
.feedbackAndFavoriteDiv .feedbackDiv:focus,
.feedbackAndFavoriteDiv .globalFavoriteDiv:focus {
	outline: 1px dotted #333;
}

.feedbackComps {
	outline: none;
}

.feedbackComps:focus {
	padding: 1px;
	border: 1px solid #253B62;
}