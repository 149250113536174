.userPreference .modal-dialog {
	max-width: 400px;
}
.userPreference .modal-body {
	padding: 0 !important;
}
.userPreference p {
	background: #fadfbc;
	padding: 36px 48px;
	text-align: center;
	font-weight: 600;
}
.userPreferenceButtons {
	text-align: center;
	padding: 10px 0 31px 0;
}
.userPreferencePopUpCancel.btn-primary {
	background: #666666;
	padding: 2px 18px !important;
	background-color: #666666 !important;
	border-color: #666666 !important;
	margin-right: 20px;
	color: #fff;
}
.userPreferenceAccept.btn-info {
	background: #f58220;
	padding: 2px 18px !important;
	background-color: #f58220 !important;
	border-color: #f58220 !important;
	margin-inline-start: 10px;
	color: #000 !important;
}
.userPreference button:focus {
	box-shadow: none !important;
	outline: 1px dotted #333 !important;
}

.reviewedPopUp p {
	font-weight: 600;
}
.userPreferenceButtons button.userPreferenceAccept.btn.btn-primary {
	background-color: #f58220 !important;
	background: #f58220;
	border-color: #f58220 !important;
	color: #000 !important;
}
