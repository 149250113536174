.myAchievementsMainContainer {
	display: flex;
}
.myAchievementsLandingPage {
	display: flex;
	background: #f2f2f2;
	min-height: 90vh;
	width: 100%;
	flex-direction: column;
}
.myAchievementsLeftAndMainContainer {
	width: 100%;
	display: flex;
	margin-top: 39px;
}
.myAchievementsLeftMenuContainer {
	background: #2b314c;
	color: #fff;
	position: fixed;
	height: calc(100vh - 5.8rem);
	top: 102px;
	left: 0;
	width: 55px;
	-webkit-transition: all 0.2s ease-in;
	transition: all 0.2s ease-in;
}
.myAchievementsLeftMenuContainer.expanded {
	width: 120px;
	-webkit-transition-duration: 0.2s;
	transition-duration: 0.2s;
}
.myAchievementsLeftMenuContainer ul {
	list-style: none;
	padding: 36px 0 0;
}
.myAchievementsLeftMenuContainer ul a.NavLink {
	padding: 10px 8px 10px 12px;
	font-size: 13px;
	margin: 25px 0;
}
.myAchievementsTypeIcon {
	width: 20px;
	height: 20px;
	margin-right: 8px;
}
.myAchievementsLeftMenuIcon {
	font-size: 14px;
	padding: 10px 13px 10px 14px;
	color: #f58220;
	position: absolute;
	bottom: 4px;
	cursor: pointer;
	right: 6px;
}
.myAchievementsLeftMenuIcon i {
	cursor: pointer;
}
.myAchievementsContainer.expanded {
	margin: 0px 0px 0 120px;
	-webkit-transition-duration: 0.2s;
	transition-duration: 0.2s;
}
.myAchievementsContainer {
	margin: 0px 0px 0 55px;
	-webkit-transition: all 0.2s ease-in;
	transition: all 0.2s ease-in;
	flex: 1 1;
}
.selectCommunityBar {
	display: flex;
	/* padding: 10px 10px; */
	background: #fff;
	font-size: 12px;
	color: #fff;
}
/* .selectCommunityBar>span{
    background: #4137F8;
} */
.selectCommunityBar > div {
	display: flex;
	padding: 10px 10px;
	width: 546px;
	background-image: linear-gradient(to right, #4137f8, #fff);
}
.selectCommunityDpDownContainer {
	position: relative;
	margin: 0 10px;
	flex: 1;
}
.selectedCommunityContent i {
	color: #fff !important;
}
.selectCommunityDpDown {
	/* display: none; */
	color: #333;
	position: absolute;
	top: 25px;
	left: 0;
	width: 400px;
	min-height: 100px;
	z-index: 1000;
	float: left;
	min-width: 160px;
	padding: 5px 0;
	margin: 2px 0 0;
	font-size: 14px;
	text-align: left;
	list-style: none;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #ccc;
	border: 1px solid rgba(0, 0, 0, 0.15);
	border-radius: 4px;
	box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}

.selectCommunityDpDownTabMenu ul {
	display: flex;
	margin: 0;
	padding: 0;
	list-style: none;
	font-weight: 600;
}
.selectCommunityDpDownTabMenu ul li {
	padding: 6px 69px;
}
.selectCommunityDpDownList ul {
	display: flex;
	margin: 0;
	padding: 0;
	list-style: none;
	flex-direction: column;
}
.selectCommunityDpDownList ul li {
	padding: 10px 10px;
	display: flex;
}
.selectCommunityDpDownList ul li input {
	margin: 0;
}
.myAchievementsStatsMainContainer {
	display: flex;
}
.myAchievementsStatsContainer {
	margin: 10px 10px 10px 10px;
	/* border: 1px solid #666; */
	flex: 1;
	min-height: 81vh;
	box-shadow: 0px 1px 5px #006fff1f;
	background: #fff;
	position: relative;
	border-radius: 5px;
}
.myAchievementsStatsMyPerformances h5 {
	font-size: 15px;
	margin: 4px 11px 12px 11px;
}
.myAchievementsUserProfilePic {
	width: 70px;
	height: 70px;
	border-radius: 50%;
	text-align: center;
	border: 1px solid #e6e6e6;
	position: absolute;
}
.myAchievementsUserProfileDetails {
	display: flex;
	padding: 15px 20px 10px 20px;
	position: relative;
	flex-direction: column;
}
.myAchievementsUserProfileTitle {
	display: flex;
	flex-direction: column;
	background: #f6f6f6;
	margin-left: 42px;
	padding-left: 28px;
	flex: 1;
	border-radius: 8px;
	border-bottom-right-radius: 0;
}
.myAchievementsUserProfileTitle h6 {
	font-size: 16px !important;
	/* margin-left: 10px; */
	margin-bottom: 1px;
	border-bottom: 1px solid #dee2e6;
	padding: 3px 0 3px 10px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 99%;
}
.myAchievementsUserProfileTitle span {
	font-size: 11px;
	margin-left: 10px;
}
.myAchievementsUserProfileBadgeBar {
	border: 1px solid #fbe1c2;
	padding: 1px;
	background: #fbe1c2;
	border-radius: 20px;
	margin-left: 10px;
	margin: 1px 0 3px 77px;
	border-top-right-radius: 0px;
	flex: 1;
}
.myAchievementsUserProfileRank {
	display: flex;
	padding: 5px 20px;
}
.myAchievementsUserProfileRank div span {
	font-weight: 600;
}
.myAchievementsUserProfileRank div h6 {
	font-weight: 400;
}
.myAchievementsUserProfileRank div {
	flex: 1;
	text-align: center;
}
.myAchievementsStatsMyPerformances {
	display: flex;
	padding: 5px 10px;
	flex-direction: column;
	margin-top: 0px;
}
.myAchievementsLeaderBoardContainer {
	display: flex;
	margin-top: 20px;
	flex-direction: column;
}
ul.myAchievementsLeaderBoardTab {
	padding: 0;
	list-style: none;
	margin: 0;
	display: flex;
	flex-direction: column;
	width: 100%;
}
ul.myAchievementsLeaderBoardTab li {
	box-shadow: 1px 1px 3px 2px #0000001f;
	width: 100%;
	min-height: 51px;
	margin-bottom: 14px;
	padding: 6px 14px;
	display: flex;
}
.myAchievementsLeaderBoardUserImg {
	width: 35px;
	height: 35px;
	border-radius: 50%;
	text-align: center;
	border: 1px solid #e6e6e6;
}
.myAchievementsLeaderBoardUserImg img {
	width: 35px;
	height: 35px;
	border-radius: 50%;
}
.myAchievementsLeaderBoardUserDetails {
	display: flex;
	flex: 1;
}
.myAchievementsLeaderBoardUserDetails span:last-child {
	font-size: 14px;
	font-weight: 600;
	margin-top: 8px;
	margin-left: 10px;
}
.leaderBoardRank {
	width: 52px;
	padding: 7px 8px;
	text-align: center;
	color: #f58220;
}
.myAchievementsLeaderBoardContainer h6 {
	padding: 10px 15px 20px 15px;
}
.myAchievementsContributionsContainer {
	display: flex;
	flex-direction: column;
	flex: 1;
}
.myAchievementsContributionsContainer .myAchievementsStatsContainer {
	min-height: 33vh;
	padding: 10px;
}
.myAchievementsContributionsContainer
	.myAchievementsStatsContainer:first-child {
	margin: 10px 10px 10px 10px;
	padding: 15px 10px;
}
.myAchievementsContributionsContainer .myAchievementsStatsContainer:last-child {
	margin: 10px 10px 10px 10px;
}
.myAchievementsContributionsDiv {
	display: flex;
	font-size: 12px;
	padding: 4px;
	margin-bottom: 10px;
}
.myAchievementsContributionsDiv > span {
	padding: 11px 10px;
	background: #f2f2f2;
}
.myAchievementsContributionsDiv span:first-child {
	flex: 1 1;
	border-bottom-left-radius: 4px;
	border-top-left-radius: 4px;
}
span.myAchievementsContributionsCount {
	border: 1px solid green;
	color: green;
	font-weight: bold;
	width: 46px;
	text-align: center;
	border-radius: 4px;
	font-size: 14px;
}
.myAchievementsContributionsDiv.pendingReview span,
.myAchievementsContributionsDiv.pendingReview
	span.myAchievementsContributionsCount {
	background: #ffa12d47;
}
.myAchievementsContributionsDiv.rejectedReview span {
	background: #dc1d3740;
}
.myAchievementsContributionsDiv.rejectedReview
	span.myAchievementsContributionsCount {
	color: #dc1d37;
	border: 1px solid #dc1d37;
}
.myAchievementsContributionsDiv.rejectedReview
	span.myAchievementsContributionsCount
	span,
.myAchievementsContributionsDiv.pendingReview
	span.myAchievementsContributionsCount
	span {
	background: none;
}
.myAchievementsContributionsDiv.pendingReview
	span.myAchievementsContributionsCount {
	border: 1px solid #ffa12d;
	color: #ffa12d;
	font-weight: bold;
}
.myAchievementsTypeIcon {
	/* width: 25px;
	height: 25px; */
	/* background: url(../../assets/MyAchievementsMeIcon.svg) no-repeat; */
	float: left;
	background-size: contain;
}
.myAchievementsLeftMenuContainer ul a.NavLink:hover svg path,
li.selectedMyAchievementTab a.NavLink svg path {
	fill: #f58220;
}
li.selectedMyAchievementTab a.NavLink {
	color: #f58220;
}
.myAchievementsProgressBar {
	display: flex;
	flex-direction: column;
}
.myAchievementsProgressBar p h6 {
	font-size: 11px;
}
progress {
	width: 100%;
	height: 5px;
	background-color: #93c4f0;
	color: #3191e7;
	border-radius: 100px;
}
progress::-webkit-progress-bar {
	background-color: #93c4f0;
	border-radius: 200px;
}
progress::-webkit-progress-value {
	background-color: #3191e7 !important;
	border-radius: 200px;
}
progress::-moz-progress-bar {
	background-color: #3191e7 !important;
}
.myAchievementsProgressBar p {
	margin-bottom: 5px;
}
.myAchievementsNewBadgeIcon {
	width: 150px;
	height: 130px;
	background: url(../../assets/NewBadgeIcon.svg) no-repeat;
	float: left;
	background-size: contain;
}
.myAchievementsRewardsContainer > h6 {
	margin-top: 13px;
}
.myAchievementsEmptyBadgeIcon {
	width: 150px;
	height: 115px;
	background: url(../../assets/emptyboxIcon.svg) no-repeat;
	float: left;
	background-size: contain;
	margin-top: 15px;
}
.myAchievementsClickToOpen {
	width: 150px;
	height: 150px;
	background: url(../../assets/ClicktoOpenIcon.svg) no-repeat;
	background-size: contain;
	margin: 0 auto;
}
.myAchievementsRewardsContainer {
	padding: 15px 10px;
}
.myAchievementsCongratzDiv {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 20px;
}
.myAchievementsShareColleagues {
	display: flex;
	background: #665eff;
	color: #fff;
	margin: 0 20px;
}
.myAchievementsShareColleagues a {
	padding: 9px 21px;
	color: #fff;
	display: flex;
	width: 100%;
}
.myAchievementsShareColleagues span {
	flex: 1;
	font-size: 12px;
	padding: 3px;
}
.myAchievementsCongratzDiv h4 {
	font-size: 18px;
}
.myAchievementsStatsContainer h6 {
	font-size: 14px;
}
.myAchievementsClickToOpenDiv {
	display: flex;
	flex-direction: column;
	padding: 20px 0px;
	align-items: center;
}
.myAchievementsRewardsContainer h4 {
	font-size: 18px;
}
.myAchievementsStatsContainer p {
	margin-bottom: 10px;
	font-size: 12px;
	background: #f8f8fa;
	padding: 5px 10px;
	border-radius: 10px;
}
.myAchievementsStatsContainer p > div {
	position: relative;
	margin-top: 14px;
}
.progressBarLevelTitlesDesc span i {
	font-size: 7px;
	margin: 0 5px;
}
.progressBarLevelTitlesDesc .valueTitle i {
	color: #3191e7;
}
.progressBarLevelTitlesDesc .maxTitle i {
	color: #93c4f0;
}
.progressBarLevelTitlesDesc {
	font-size: 9px;
	margin: 15px 0 3px 0;
}
.selectCommunityDpDownList {
	font-size: 12px;
}
.myAchievementsUserProfilePic img.userImage {
	width: 70px;
	height: 70px;
	margin: 0;
}
.myAchievementsStatsContainer .myPerformanceTrendsGraph canvas {
	height: 162px !important;
	width: 269px !important;
}
.selectCommunityDpDownContainer div i {
	vertical-align: middle;
	margin-left: 10px;
}
.selectCommunityBar > div i {
	color: #000;
	font-weight: bold;
	font-size: 12px;
	cursor: pointer;
}
span.communitySolMethodName {
	width: 300px;
}
.myAchievementsLandingPage .phaseBreadcrumb {
	background: #fff;
}
.myAchievementsLeaderBoardHeader {
	box-shadow: 1px 1px 3px 2px #0000001f;
	width: 100%;
	min-height: 36px;
	margin-bottom: 14px;
	padding: 5px 24px;
	display: flex;
	font-size: 14px;
	font-weight: 600;
}
.myAchievementsLeaderBoardHeader span:first-child {
	flex: 1;
}

.myAchievementsShareColleagues a:hover {
	text-decoration: none;
	color: #fff;
}
.comingSoon {
	position: relative;
	display: flex;
	min-height: 84vh;
}
.comingSoon h3 {
	position: absolute;
	left: 45%;
	top: 50%;
}
.profileBadges {
	width: 15px;
	height: 15px;
	background: url(../../assets/Badge1.svg) no-repeat;
	float: left;
	background-size: contain;
	margin-right: 4px;
	margin-left: 0 !important;
}
.profileBadge {
	width: 29px;
	height: 28px;
	background: url(../../assets/Badge1.svg) no-repeat;
	float: left;
	background-size: contain;
	position: absolute;
	left: 6px;
	bottom: -5px;
}
.myAchievementsUserProfileProgressBar {
	margin: 8px 20px -4px 20px;
	position: relative;
}
.myAchievementsUserProfileProgressBar progress {
	width: 100%;
	height: 5px;
}
.separator span.separatorLine {
	border-left: 1px solid #707070;
	display: flex;
	height: 19px;
	margin-left: 1px;
}
.separator {
	position: absolute;
	top: -7px;
	left: 25%;
	display: flex;
	flex-direction: column;
	font-size: 9px;
}
.separator.seventyFive {
	left: 75%;
}
.separator.fifty {
	left: 50%;
}
.separator.zero {
	left: -2px;
}
.separator.hundred {
	left: 99.5%;
}
.progressBarLevelTitles {
	font-size: 10px;
	font-weight: 600;
	display: flex;
	margin-top: 12px;
}
.progressBarLevelTitles span:nth-child(2) {
	text-align: center;
	flex: 1;
	font-weight: bold;
}
.progressBarLevelTitles span:last-child(2) {
	float: right;
}
.noContributionTrends {
	position: absolute;
	text-align: center;
	top: 50%;
	left: 25%;
}
.myPerformanceTrendsGraph {
	margin-top: 20px;
}
.myPerformanceTrendsGraph p {
	margin: 90px auto;
	text-align: center;
}
.myAchievementsContributionsContainer .myAchievementsStatsContainer h6 {
	margin-bottom: 10px;
	margin-left: 5px;
	margin-top: 13px;
}
.myAchievementsDashboardTile.maximized .myPerformanceTrendsGraph canvas {
	height: 390px !important;
}
.myPerformanceTrendsGraph canvas {
	background-color: #f8f9fa;
	height: 250px !important;
}
.separator.zero span:first-child {
	margin-left: 0;
}

.separator span:first-child {
	margin-left: -4px;
}
.myAchievementsStatsContainer p .separator {
	top: -11px;
}
.progressBarLevelTitlesDesc span:last-child {
	float: right;
	margin-right: 10px;
}
.myAchievementsDashboard {
	display: flex;
	background: #fff;
	min-height: 84vh;
	flex-wrap: wrap;
	padding: 20px;
}
.myAchievementsDashboardTile {
	box-shadow: 0px 1px 5px #006fff1f;
	border-radius: 5px;
	width: 375px;
	height: 360px;
	margin: 20px 10px 0px 10px;
	padding: 10px;
}
.myAchievementsLandingPage .phaseBreadcrumb {
	background: #fff;
	box-shadow: 0px 0px 1px 0px rgb(0 0 0 / 25%);
	border-bottom: 1px solid #f3f2f1;
	position: fixed;
	width: 100%;
}
.dashBoardGraphExpand {
	display: flex;
	justify-content: flex-end;
	margin-bottom: 5px;
}
.dashBoardTitlePart {
	display: flex;
}
.dashBoardTitlePart h6 {
	flex: 1;
}
.dashBoardGraphExpand i {
	font-weight: 600;
	font-size: 12px;
	cursor: pointer;
}
.dashBoardGraphContainer {
	padding: 5px 15px 15px 15px;
}

.myAchievementsDashboard.dashboardMaximize .myAchievementsDashboardTile {
	display: none;
}

.myAchievementsDashboardTile.maximized {
	display: block !important;
	width: 100%;
	height: calc(100vh - 10rem) !important;
}
/* .myAchievementsDashboardTile.maximized .myPerformanceTrendsGraph canvas {
  height: calc(100vh - 18rem) !important;
} */
.graphLegends {
	display: inline-flex;
	font-size: 13px;
	white-space: nowrap;
	cursor: pointer;
	padding: 2px 5px;
	border-radius: 4px;
}
.graphLegendsColorIndicator {
	width: 12px;
	height: 12px;
	margin-right: 5px;
	float: left;
	margin-top: 4px;
	background: #3191e7;
}
.graphLegendsColorIndicator.appsAvg {
	background: #ffa12d;
}
.dashBoardGraphContainer p {
	margin-bottom: 0;
}
.dashBoardGraphContainer.avgAppliactionsGraph .myPerformanceTrendsGraph {
	margin-top: 2px;
}
.dashBoardGraphContainer.avgAppliactionsGraph p .graphLegends {
	font-size: 12px;
}
.dashBoardGraphContainer.avgAppliactionsGraph p {
	text-align: center;
}
.separator.zero.actualPoints {
	top: 1px;
}
.myAchievementsUserProfileProgressBar .separator.zero.actualPoints {
	top: 6px;
}
.rightSecContainer .phaselandMiddleContainer.matrixView .breadcrumbsAndFilters {
	width: calc(100% - 15rem);
}
.rightSecContainer.expand
	.phaselandMiddleContainer.matrixView
	.breadcrumbsAndFilters {
	width: 100%;
}
.rightSecContainer .phaselandMiddleContainer.matrixView .filterTagsContainer {
	width: calc(100% - 15.4rem);
}
.rightSecContainer.expand
	.phaselandMiddleContainer.matrixView
	.filterTagsContainer {
	width: 96%;
}
