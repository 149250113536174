.phaseBreadcrumb i {
  font-size: 10px;
  font-weight: bold;
  margin: 3px 6px 0 6px;
  vertical-align: text-bottom;
}
.phaseBreadcrumb {
  display: inline-flex;
  padding: 10px;
  font-size: 12px;
  align-items: baseline;
}
.phaseBreadcrumb > i {
  font-size: 11px;
  font-weight: 400;
  margin: 1px 6px;
}
.phaseBreadcrumb div {
  display: flex;
}
.rightSecContainer.expand .breadcrumbsAndFilters .phaseBreadcrumb {
  width: 43%;
  /* position: fixed; */
}
.rightSecContainer .breadcrumbsAndFilters .phaseBreadcrumb {
  width: 27%;
  /* position: fixed; */
}
.phaseBreadcrumb span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.phaseBreadcrumb a {
  color: #0078d4 ;
}
