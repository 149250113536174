.activitiesList {
  display: block;
  width: 100%;
  margin-bottom: 15px;
}

.activityContainer {
  display: flex;
  position: relative;
  width: 100%;
}

/* .activityGroup {
  cursor: pointer;
} */
.activityListMainContainer {
  background: #f6f6fb;
  border-radius: 3px;
  display: block;
  margin-top: 10px;
}

.mergedPhaseLandPage .activityListMainContainer {
  margin-right: 150px;
}

.activityTitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  float: left;
  display: inline-grid;
  width: 100%;
}

.activitytypeColor {
  border-left: 4px solid #f58220;
  border-top-left-radius: 3px;
}

.moreEllipsis {
  float: right;
  position: relative;
}

.moreEllipsis i {
  vertical-align: middle;
  padding-top: 8px;
  font-weight: bolder;
  margin-right: 10px;
}

.activityTitle i {
  color: #f58220;
  font-size: 10px;
  vertical-align: middle;
  cursor: pointer;
  float: left;
  padding-top: 2px;
  padding: 12px 0px 9px 5px;
}

.activityChildContainer {
  display: flex;
  flex-direction: column;
  border-left: 4px solid #fccd95;
  background: #f9f9fc;
}

.activityChildList {
  display: block;
}

.activityChildContainer .activitytypeColor {
  border-left: 4px solid #fccd95;
  border-top-left-radius: unset;
  border-bottom-left-radius: unset;
}

.activityChildContainer .activityChildTitle {
  /* padding: 8.5px 5px 8.5px 15px; */
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-flex;
  clear: both;
  width: 56%;
  position: relative;
  flex: 1;
}

.titleOfActivity {
  float: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 4px;
  display: inline-grid;
  padding: 9px 5px 9px 5px;
  cursor: pointer;
}

.complexityOfActivity {
  display: inline-flex;
  text-align: right;
  margin-left: auto;
}

.raciWrapper {
  display: inline-block;
  width: 25px;
  font-size: 14px;
  font-weight: bold;
  color: #505ec7;
  padding-top: 7px !important;
  text-align: center;
  cursor: pointer;
}

.raciWrapper.complexityIcon i {
  color: #81bc06 !important;
  font-weight: bolder;
  margin-right: 20px;
}

.showMoreMenu {
  list-style-type: none !important;
  list-style: none !important;
  background-color: white !important;
  border: 1px solid lightgrey !important;
  width: 273px;
  right: -4px;
  box-shadow: 0px 1px 4px #ccc;
  z-index: 99;
  top: 31px;
  position: absolute !important;
  font-size: 13px;
  padding: 10px;
  min-height: 53px;
}

.showMoreMenu ul {
  padding-left: 0px !important;
  margin-bottom: 0px !important;
}

li.moreItemLi.templateInProgress a.templateLink {
  pointer-events: unset;
  display: flex;
  cursor: default;
}

.showMoreMenu li {
  list-style: none;
  float: left;
  display: block;
  width: 100%;
  margin-bottom: 4px;
  background: #e1f0d9;
  padding: 4px 4px 4px 8px;
}

.ellipseMenuItemImg {
  margin-right: 10px;
  float: left;
  height: 20px;
  width: 17px;
  margin-top: 2px;
}

.showMoreArrow {
  width: 26px;
  height: 12px;
  background-size: contain;
  position: absolute;
  top: -12px;
  right: 4px;
  background: url(../../assets/beakImage.png) no-repeat;
}

.titleOfActivity a {
  text-decoration: none;
  color: #333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  float: left;
}

.titleOfActivity a:hover {
  text-decoration: underline;
  color: #333;
}

.faded {
  opacity: 0.2 !important;
  pointer-events: none;
}

.delivery .activitytypeColor {
  border-left: 4px solid #665eff !important;
}

.delivery .activityTitle i {
  color: #665eff;
}

.delivery .activityChildContainer {
  border-left: 4px solid #aeaaf3;
}

.orangeDelivery .activityChildContainer {
  border-left: 4px solid #fccd95;
}

.mainTitleOfActivity {
  margin-top: 25px;
}

.hide {
  opacity: 0 !important;
  pointer-events: none;
}

.templateLink,
.templateLink:hover {
  text-decoration: none;
  color: #000;
}

.templateLink:hover span {
  text-decoration: underline !important;
}

.templateInProgress {
  background-color: #f5f5f5 !important;
  text-decoration: none !important;
}

.ellipseMenuItem {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 86%;
  float: left;
}

.activityContainer .activityGroup {
  cursor: pointer;
}

.activityGroupParent span.titleOfActivity {
  font-weight: 500;
  display: block;
  margin-left: 4px !important;
  flex: 1;
  padding: 9px 5px 9px 0px;
}

.activityGroupParent .activityChildContainer .activityContainer.activityGroup span.titleOfActivity {
  font-weight: 500;
  padding: 9px 5px 9px 10px;
}

.activityGroupParent .activityChildContainer span.titleOfActivity {
  font-weight: 400;
  display: inline-grid;
  padding: 8.5px 5px 8.5px 8px;
}

.activityGroupParent .activityTitle {
  width: 100%;
  display: flex;
}

.activityListMainContainer span.titleOfActivity {
  margin-left: 14px;
}

.complexityLegend {
  width: 17px;
  height: 15px;
  background-size: contain;
  background: url(../../assets/complexityLegend.svg) no-repeat;
  float: right;
  margin-left: 9px;
}

.templateInProgress .templateLink:hover span {
  text-decoration: none !important;
}

li.noTemplatesShowMore {
  cursor: default !important;
  text-decoration: none !important;
}

.titleOfActivity a:focus {
  outline-offset: -1px;
}

.activityChildTitle.noActivities .titleOfActivity {
  background: #fae7d3;
  margin: 1px 0;
  margin-left: 0 !important;
}

.activityChildTitle.noActivities {
  width: 100%;
}

a.ActivityEditPhaseLand i {
  font-size: 12px;
  margin-top: 8px;
  color: #333;
  margin-right: 10px;
}

i.phaseLandEditMode {
  font-size: 12px;
  margin-left: 6px;
  vertical-align: bottom;
  cursor: pointer;
  margin-top: 8px;
  margin-right: 10px;
}

i.ActivityDeletePhaseLand {
  cursor: pointer;
  margin-right: 6px;
}

.ActivityDeletePhaseLand.disable {
  opacity: 0.5;
}

.activityGroupAdditionDiv .activityTitle,
.activityGroupAdditionDiv .activityChildTitle {
  flex: 1;
}

.activityGroupAdditionDiv .activityContainer {
  margin-right: 0px;
  position: relative;
}

.activityGroupAdditionDiv .activityChildContainer {
  width: 100%;
  position: relative;
}

.draftActivity {
  position: absolute;
  background: #0078d4;
  top: 0px;
  left: 1px;
  font-size: 8px;
  color: #fff;
  padding: 0px 5px;
}

a.ActivityEditPhaseLand,
.ActivityDeleteContainer {
  position: relative;
}

a.ActivityEditPhaseLand i:hover~.tabsTitleToolTip,
i.ActivityDeletePhaseLand:hover~.tabsTitleToolTip {
  display: block;
}

a.ActivityEditPhaseLand .tabsTitleToolTip,
.ActivityDeleteContainer .tabsTitleToolTip {
  left: -53px;
  white-space: nowrap;
  top: 25px;
  padding: 6px 7px;
  font-size: 12px;
}

.ActivityEditIcon {
  float: left;
  width: 23px;
  height: 23px;
  background: url(../../assets/editIcon.svg) no-repeat;
  background-size: contain;
  cursor: pointer;
  margin-top: 6px;
}

.ActivityEditIconDisabled {
  float: left;
  width: 23px;
  height: 23px;
  background: url(../../assets/editIconDisabled.svg) no-repeat;
  background-size: contain;
  cursor: pointer;
  margin-top: 6px;
}

.ActivityDeleteIconDisabled {
  float: left;
  width: 24px;
  height: 24px;
  background: url(../../assets/TopicOwnerDeleteIconDisabled.svg) no-repeat;
  background-size: contain;
  cursor: pointer;
  margin-top: 6px;
  margin-left: 3px;
}

.ActivityDeleteIcon {
  float: left;
  width: 29px;
  height: 29px;
  background: url(../../assets/TopicOwnerDeleteIcon.svg) no-repeat;
  background-size: contain;
  cursor: pointer;
  margin-top: 3px;
}

.activityOwnerHistoryIcon {
  float: left;
  width: 29px;
  height: 29px;
  background: url(../../assets/TopicHistoryIcon.svg) no-repeat;
  background-size: contain;
  cursor: pointer;
  margin-top: 3px;
}

.activityReferredIcon {
  float: left;
  width: 13px;
  height: 13px;
  background: url(../../assets/ReferChainIcon.svg) no-repeat;
  background-size: contain;
  margin-top: 12px;
  margin-right: 10px;
}

span.ActivityEditIcon.disable,
span.ActivityDeleteIcon.disable {
  opacity: 0.7;
  pointer-events: none;
}

.phaseCreationRightPopup.methodOwnerCollaborator.topicOwnerHistoryPopUp .modal-title.h4 span {
  font-size: 16px;
}

.activityChildContainer.subGroupChild {
  flex-direction: row;
}

.subGroupContainer .activityListMainContainer.activityGroupParent {
  width: 100%;
  flex-direction: column;
}

.activityChildContainer>div {
  display: flex;
}

.activityChildContainer>ul {
  list-style: none;
  padding: 0;
}

.subGroupContainer {
  box-shadow: 0px 2px 1px #0000001f;
}

.addNewSubGroupButton button {
  float: left;
  display: inline-flex;
  border: none;
  color: #fff;
  margin: 15px 0;
  padding: 5px 31px 5px 27px;
  background: #0078d4;
  border-radius: 4px;
}

.subGroupContainer .activityChildContainer {
  box-shadow: 0px 2px 1px #0000001f;
  padding: 1px 1px 1px 2.5px;
  background: #fff;
}

.activityGroupParent .activityTitle span.ActivityEditIcon {
  margin-top: 0;
  width: 24px;
  height: 22px;
  margin: 8px 4px;
}

.activityGroupParent .activityTitle span.phaseAddIcon {
  margin: 8px 4px;
}

span.groupColor {
  width: 14px;
  background: #f58220;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.addNewSubGroupButton span {
  display: flex;
  color: #0262ab;
  font-weight: 600;
  margin: 10px 0;
}

.addNewSubGroupButton i {
  font-size: 12px;
  font-weight: bold;
  margin: 12px 5px;
  color: #0078d4;
}

.activityGroupAdditionDiv .activityChildContainer .activityChildTitle span.titleOfActivity {
  padding: 9px 5px 9px 15px;
}

.activityChildContainer .activityContainer.activityGroup span.activitytypeColor {
  border-left: 4px solid #f58220;
}

.compact-picker.compactPicker .flexbox-fix {
  display: none !important;
}

.phaseCreationRightPopup .modal-header .modal-title.h4 h3 {
  font-size: 23px;
}

.subGroupContainer .activityChildContainer .activityListMainContainer.activityGroupParent .activityContainer.activityGroup {
  background: #fff;
  box-shadow: 0px 0px 1px #0000001f;
}

.subGroupContainer .activityChildContainer .activityListMainContainer.activityGroupParent .activityContainer.activityGroup .activityTitle {
  border: 0.5px solid #e4e4e5;
}

.orangeDelivery .activityTitle i {
  color: #f58220;
}

.orangeDelivery .activitytypeColor {
  border-left: 4px solid #f58220 !important;
}

.orangeDelivery .delivery .activityChildContainer {
  border-left: 4px solid #aeaaf3;
}

.metrixViewGroupsContainer .activityGroupParent span.titleOfActivity a {
  display: contents;
}

.topicsCrowdsourceHistoryModal.adminHistoryPreview .innerContent ul {
  padding-left: 28px;
}

.topicsCrowdsourceHistoryModal.adminHistoryPreview .myContributionPreviewContainer h6 {
  margin: 20px 0;
}

.contributorAddTemplates>span:first-child {
  font-weight: 600;
  font-size: 12px;
}

span.contributorAddTemplatesButton {
  display: flex;
  border: 1px solid #e4e4e4;
  width: 170px;
  padding: 4px 11px;
  line-height: 27px;
  margin-top: 8px;
  font-size: 12px;
  cursor: pointer;
}

span.contributorAddTemplatesButtonTask {
  display: flex;
  border: 1px solid #e4e4e4;
  width: 110px;
  padding: 4px 11px;
  line-height: 27px;
  margin-top: 8px;
  font-size: 12px;
  cursor: pointer;
}

span.contributorAddTemplatesButton span.phaseAddIcon {
  margin-left: 14px;
}

span.contributorAddTemplatesButtonTask span.phaseAddIcon {
  margin-left: 14px;
}

.phaseCreationRightPopup.addTemplate .collabaratorsParentContainers input {
  width: 90%;
}

.topicsCrowdsourceHistoryModal .addingTemplatesEditAcivity .templateContentContainer ul li>div {
  display: flex;
}

.solMethodDescrOnLandPage span.titleOfActivity,
.deliveryMangementDescription span.titleOfActivity {
  display: flex !important;
}

.solMethodDescrOnLandPage span.titleOfActivity a,
.deliveryMangementDescription span.titleOfActivity a {
  flex: 1;
}

.activityRoleGroupModalContainer {
  display: flex;
  width: 100%;
}

.activityRoleGroupModalContainer .roleCategoryContainer {
  width: 25%;
  padding: 0 5px;
}

.fade.templatesingleSelectionPopUp.rolegroupModal.modal.show .modal-dialog.modal-md.modal-dialog-centered {
  max-width: 1100px !important;
  width: 1100px;
}

.fade.templatesingleSelectionPopUp.rolegroupModal.modal.show .modal-dialog.modal-md.modal-dialog-centered .modal-content {
  min-height: 300px;
}

.NotificationChkboxContainer input:indeterminate~.CustomCheckmark {
  background-color: #3191e7;
  border: 1px solid #3191e7;
}

.treeViewCheckBox .treeViewCheckBoxParent .NotificationChkboxContainer span.CustomCheckmark.isIndeterminate:after {
  position: absolute;
  left: 3px;
  opacity: 1;
  top: -5px;
  color: #fff;
  font-size: 13px;
  font-weight: bold;
  content: "\2212";
  font-family: FabricMDL2Icons;
}

.NotificationChkboxContainer input:indeterminate~.CustomCheckmark::after {
  display: block;
}

.NotificationChkboxContainer input:checked~.CustomCheckmark.isIndeterminate:after {
  display: block;
}

.CustomCheckmark.isIndeterminate {
  position: absolute;
  height: 15px;
  width: 15px;
  background-color: #fff;
  border: 1px solid rgba(102, 102, 102, 1);
}

.activityBladeRaciButton span {
  letter-spacing: 4px;
  font-weight: 600;
  color: #0078d4;
}

.activityBladeRaciButton i {
  color: #0078d4;
  font-size: 13px;
}

.activityBladeRaciButton i {
  color: #0078d4;
  font-size: 10px;
  margin-right: 5px;
  padding: 3px 4px 0px 0 !important;
}

.rolegroupModal .activityChildContainer.orphanRole {
  border-left: 0;
}

.rolegroupModal .activityRaciRoleGroupContainer .activityTitle {
  font-size: 12px;
}

.rolegroupModal .activityChildContainer.orphanRole span.titleOfActivity {
  padding: 5px 5px 5px 18px;
}

.rolegroupModal .activityRaciRoleGroupContainer .activityChildContainer .titleOfActivity {
  padding: 4px 5px 4px 18px !important;
}

.roleGroupModalClose {
  margin: 10px 0;
  text-align: center;
}

.roleGroupModalClose button {
  background: #f1eded;
  font-size: 13px;
  padding: 3px 30px;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: #0078d4;
}

.rolegroupModal .activityRaciRoleGroupContainer .activityChildContainer .activityChildTitle {
  font-size: 12px;
}

span.parentRoleText {
  align-items: end;
  margin-left: auto;
}

.adminHistoryPreview .activityChildContainer.orphanRole span.titleOfActivity {
  padding: 5px;
}

.activityRaciRoleGroupContainer .activityContainer.activityGroup .activityTitle span.titleOfActivity {
  overflow: hidden;

  white-space: nowrap;

  text-overflow: ellipsis;

  display: block !important;
}

.ulTask {
  display: block !important;
  list-style-type: none !important;
  margin-block-start: 0em !important;
  margin-block-end: 0em !important;
  margin-inline-start: 0px !important;
  margin-inline-end: 0px !important;
  padding-inline-start: 30px !important;
}

.liTask {
  margin-inline-start: -30px !important;
}

ul li div ul li {
  list-style: disc;
}

div ul li ul li {
  list-style: disc;
}

div.descT ul {
  display: block !important;
  list-style-type: none !important;
  margin-block-start: 0em !important;
  margin-block-end: 0em !important;
  margin-inline-start: 0px !important;
  margin-inline-end: 0px !important;
  padding-inline-start: 20px !important;
}

.activityStyle {
  margin-left: 0 !important;
  padding-left: 0 !important;
  white-space: normal;
}

.mandatoryIcon {
  background: url(../../assets/icon-M.svg) no-repeat;
  height: 20px;
  width: 20px;
  white-space: pre;
  position: relative;
  top: 5px
}

.businessIcon {
  background: url(../../assets/icon-B\ 1.svg) no-repeat;
  height: 20px;
  width: 20px;
  white-space: pre;
  position: relative;
  top: 5px;
}

.contentIcons {
  width: 80% !important;
}

._moreEllipsis {
  margin-right: 0 !important;
}

._selectedActivityOnNonRealized .activityLink {
  outline: none !important;
}

._selectedActivityOnNonRealized {
  background: #E5E5E5 !important;
}

._topBorderOfActvityCardOnNonRealized {
  border-top: 1px solid #dee2e6;
  margin-top: -10px;
  padding-top: 10px;
}

._cardStyleForNonRealized {
  border-top: none;
  padding-top: 0 !important;
  margin-top: 10px;
}

._spaceForAllignment {
  padding-inline: 6.5px;
}

.activityGroupParent .activityTitle .titleOfActivity {
  padding-left: 17px;
}

._draftedActivityTitle {
  padding-left: 25px;
}