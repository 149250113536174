.activityDiscardPopUp .modal-dialog {
	max-width: 400px;
}
.activityDiscardPopUp .modal-body {
	padding: 0 !important;
}
.activityDiscardPopUp p {
	background: #fadfbc;
	padding: 36px 48px;
	text-align: center;
	font-weight: 600;
}
.discardPopUpActivityButtons {
	text-align: center;
	padding: 10px 0 31px 0;
}
.activityDiscardPopUpCancel {
	background: #bababa;
	padding: 2px 18px !important;
	background-color: #bababa !important;
	border-color: #bababa !important;
	margin-right: 20px;
}
.activityDiscardPopUpAccept {
	background: #f58220;
	padding: 2px 18px !important;
	background-color: #f58220 !important;
	border-color: #f58220 !important;
	margin-inline-start: 10px;
}
.activityDiscardPopUp button:focus {
	box-shadow: none !important;
}

.reviewedPopUp p {
	font-weight: 600;
}
.activityDiscardPopUpCancel.btn-primary:focus {
	outline: 1px dotted #333;
}
