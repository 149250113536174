.announcementFlyoutContainer {
	position: absolute;
	right: 45px;
	top: 67px;
	width: 500px;
	z-index: 999;
}

.announcementFlyoutTitleClose {
	font-size: 11px;
	color: #666;
	background: white;
	display: flex;
}

.announcementFlyoutTitleClose span {
	flex: 1 1;
	font-weight: bold;
	margin-left: 35px;
	font-size: 11px;
}

.announcementFlyoutTitleClose i {
	font-weight: bolder;
	cursor: pointer;
	vertical-align: bottom;
	margin-left: 4px;
}

.announcementFlyoutContainer ul {
	margin: 0;
	padding: 0;
	list-style: none;
	font-size: 13px;
	width: 100%;
}

.announcementDivChild ul li:hover {
	background: #0464ad;
}

.announcementFlyoutContainer ul li {
	background: #665eff;
	border-radius: 4px;
	border-bottom: 1px solid #fff;
	/* padding: 7px 10px; */
	color: #fff;
	font-size: 14px;
	display: flex;
	cursor: pointer;
	flex-direction: column;
}

.announcementIcon {
	width: 10px;
	height: 10px;
	background: url(../../assets/Announcements.svg) no-repeat;
	background-size: contain;
	float: left;
	margin: 6px 4px 0px 4px;
}

.approvedAnnouncementIconDiv {
	width: 18px;
	height: 17px;
	background: url(../../assets/Approvedbig.svg) no-repeat;
	background-size: contain;
	float: left;
	margin: 4px 5px 0px 0px;
}

.rejectedAnnouncementIconDiv {
	width: 18px;
	height: 17px;
	background: url(../../assets/Rejectedbig.svg) no-repeat;
	background-size: contain;
	float: left;
	margin: 4px 5px 0px 0px;
}

.pendingAnnouncementIconDiv {
	width: 18px;
	height: 17px;
	background: url(../../assets/WaitingforReviewAnnouncement.svg) no-repeat;
	background-size: contain;
	float: left;
	margin: 4px 5px 0px 0px;
}

.announcementFlyoutContainer ul li>div {
	flex: 1;
	cursor: pointer;
	display: flex;
	padding: 7px 14px;
}

.announcementFlyoutContainer ul li>div>div {
	flex: 1;
}

span.announcementUpdate {
	font-size: 12px;
	padding-top: 3px;
	color: #fff;
}

.announcementIconDiv {
	width: 17px;
	height: 17px;
	background: url(../../assets/Announcement.svg) no-repeat;
	background-size: contain;
	float: left;
	margin: 3px 6px 0px 0px;
}

.featreIconDiv {
	width: 17px;
	margin-right: 10px;
	margin-top: 0px;
	height: 17px;
	display: inline-block;
	vertical-align: bottom;
	border-radius: 50%;
}

/* span.announcementIconDiv {
	background: #fecd92;
} */
.featreIconDiv {
	background: #b8d6ff;
}

span.announcementIconDiv i,
.featreIconDiv i {
	color: #0078d4;
	font-size: 12px;
	margin-left: 2px;
}

.announcementDivChild {
	display: flex;
}

.announcementDivChild ul li>div>div>span {
	margin-left: 24px;
	display: flex;
	white-space: nowrap;
}

.announcementDivChild ul li {
	flex: 1;
	flex-direction: row;
	border-bottom: none;
	padding: 0;
	font-size: 13px;
}

.announcementDivChild ul li>div>div {
	flex: 1;
}

.announcementDivChild {
	display: flex;
	padding: 0 !important;
	max-height: 180px !important;
	overflow-y: auto;
}

.announcementFlyoutContainer ul li span i {
	font-size: 9px;
	margin: 5px 0 0px 5px;
}

.announcementCount {
	background: #fff;
	width: 14px;
	height: 14px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	margin: 5px 0 5px 5px;
}

.announcementFlyoutContainer ul li>div>span {
	display: flex;
}

span.announcementCount span {
	font-size: 9px;
	text-align: center;
	color: #000;
}

.announcementDivChild ul li>div>div>span .notificationEntityName>span {
	max-width: 370px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	display: block;
}

.announcementDivChild.rejectedContributions .notificationEntityName span {
	max-width: 370px !important;
}

.announcementDivChild.approvedContributions .notificationEntityName span {
	max-width: 370px;
}

.announcementDivChild.pendingContributions .notificationEntityName span {
	max-width: 370px;
}

.announcementDivChild ul li>div>div>span .notificationEntityName:hover .tabsTitleToolTip {
	display: block;
}

.announcementDivChild ul li>div>div>span .notificationEntityName .tabsTitleToolTip {
	top: 23px;
	left: -102px;
	width: 300px;
	white-space: normal;
}

.announcementDivChild ul li>div>div>span .notificationEntityName {
	position: relative;
}

.announcementDivChild ul li>div>div>span .notificationEntityName .tabsTitleToolTip .tabsToolTipArrow {
	left: 126px;
}

.announcementDivChild ul li:last-child .tabsTitleToolTip span.tabsToolTipArrow {
	top: 32px;
	transform: rotate(-180deg);
	left: 81px !important;
}

.announcementDivChild ul li:last-child .tabsTitleToolTip {
	top: -35px !important;
	width: 350px;
}

.markAsAllReadIcon {
	width: 9px;
	height: 9px;
	background: url(../../assets/MarkAllReadIcon.svg) no-repeat;
	background-size: contain;
	float: right;
	margin: 3px 6px 0px 6px !important;
}

.markAsAllReadDiv {
	display: flex;
	margin-right: auto;
	white-space: nowrap;
	cursor: pointer;
}

.dismissAllDiv {
	cursor: pointer;
}

.notificationEntityAsRead {
	width: 14px;
	height: 14px;
	background: url(../../assets/MarkReadIcon.svg) no-repeat;
	background-size: contain;
	float: right;
	margin: 3px 6px 0px 6px !important;
}

.announcementDivChild ul li>div>div>span>span:first-child {
	flex: 1;
	display: flex;
}