.popUpClose {
  cursor: pointer;
  font-size: 13px;
    background: #ffffff;
    margin-top: -14px;
    color: #999;
    font-weight: bold;
}
.newUserPreference p{
  font-size: 13px;
  line-height: 21px;
  margin-bottom: 30px;
  margin-top: 10px;
}
.newUserPreference h6 {
  font-size: 18px;
  text-transform: capitalize;
  margin-bottom: 15px;
}
.newUserPreference button.consultingButton.btn.btn-primary {
  background: #43425D;
    border-color: #43425D;
    margin-bottom: 15px;
    font-size: 14px;
}
.newUserPreference button.supportButton.btn.btn-info {
  background-color: #3B86FF;
  border-color: #3B86FF;
  font-size: 14px;
}
.newUserPreference.modal-body {
  padding: 20px;
}