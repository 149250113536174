.discardAndPreviewButton {
	float: right;
	margin-left: auto;
	margin-right: 32px;
}
.PreviewAndSubmitPopUp .modal-dialog {
	max-width: 1100px;
}
.PreviewAndSubmitPopUp .descOfActivity {
	height: 66vh;
}
.PreviewAndSubmitPopUp .previewDescOfActivity {
	float: left;
	margin: 5px 17px 15px 15px;
}
.PreviewAndSubmitPopUp .activityDescriptionDetails {
	height: 81vh;
	overflow: hidden;
	overflow-y: auto;
	padding: 0 40px !important;
}
.PreviewAndSubmitPopUp .modal-title {
	width: 100%;
	font-size: 21px;
	font-weight: 600;
}
.PreviewAndSubmitPopUp .modal-body {
	padding: 0 !important;
}
.PreviewAndSubmitPopUp .modal-header {
	padding: 10px;
}
.PreviewAndSubmitPopUp button:focus {
	box-shadow: none !important;
}
.historicalPreview .discardAndPreviewButton a {
	color: #333;
	font-size: 17px;
}
.historicalPreview .discardAndPreviewButton {
	margin-right: 5px;
}
.historicalPreview .discardAndPreviewButton a i {
	font-weight: 600;
}
.historicalPreview .modal-header .modal-title {
	font-size: 19px;
	font-weight: 600;
}
.rejectionReasonModal .rejectionCard {
	display: flex;
	margin: 10px 50px;
}
.rejectionReasonModal .rejectionCard .itemStatus {
	margin-top: 14px;
	display: flex;
	line-height: 18px;
}
.rejectionReasonModal .rejectionCard textarea {
	flex: 1;
	border: 1px solid #e4e4e4;
	margin-left: 30px;
}
.rejectionReasonModal .topicDescriptionDiv h5 {
	margin-bottom: 20px;
	margin-left: 54px;
	font-size: 16px;
}
.rejectionReasonModal span.erroMsg {
	margin: 10px 10px 10px 54px;
}
.hideHistoryPreview {
	display: none !important;
}
.sideBarCard.userAddedItem.accepted {
	background: rgb(206, 246, 209);
}
.sideBarCard.userAddedItem.rejected {
	background: rgb(245, 200, 210);
}
.sideBarCard.userDeletedToolsAndGuidance.accepted {
	background: rgb(206, 246, 209);
}
.sideBarCard.userDeletedToolsAndGuidance.rejected {
	background: rgb(245, 200, 210);
}

.sideBarCard.userAddedItem.accepted
	span.suggestionStatus.myContributionSuggestionStatus {
	color: #5ad86b;
}

.sideBarCard.userAddedItem.rejected
	span.suggestionStatus.myContributionSuggestionStatus {
	color: rgb(239, 2, 60);
}
.sideBarCard.userDeletedToolsAndGuidance.accepted
	span.suggestionStatus.myContributionSuggestionStatus {
	color: #5ad86b;
}

.sideBarCard.sideBarCard.userDeletedToolsAndGuidance.rejected
	span.suggestionStatus.myContributionSuggestionStatus {
	color: rgb(239, 2, 60);
}
.rejectionRequiredFields {
	display: flex;
	flex-direction: column;
	width: 67%;
}
.criteria-history-tab-container {
	width: calc(100% - 0.8rem);
	margin-left: 13px;
}

ul.entry-criteria-history-tabs,
ul.exit-criteria-history-tabs {
	margin: 0px;
	padding: 0px;
	list-style: none;
}
ul.entry-criteria-history-tabs li,
ul.exit-criteria-history-tabs li {
	background: none;
	color: #222;
	display: inline-block;
	padding: 10px 10px;
	cursor: pointer;
}
ul.entry-criteria-history-tabs li.currentEntryTab,
ul.exit-criteria-history-tabs li.currentExitTab {
	background: white;
	border-bottom: 3px solid #f58220;
}
ul.entry-criteria-history-tabs li.currentEntryTab,
ul.exit-criteria-history-tabs li.currentExitTab {
	background: white;
	border-bottom: 3px solid #f58220;
}
.entry-criteria-history-tab-content,
.exit-criteria-history-tab-content {
	display: none;
	background: white;
	padding: 15px;
	border: 1px solid #eee;
	min-height: 200px;
	overflow-y: auto;
}

.entry-criteria-history-tab-content.currentEntryTab,
.exit-criteria-history-tab-content.currentExitTab {
	display: inherit;
}
#criteriaSuggestionCard p{
	margin-bottom: 0;
	width:fit-content
}
span#criteriaSuggestionCard.descEn{
	width: fit-content;
}

.deliverables-container-historical h2{
	padding: 12px;
    border: 1px solid #c4c4c4;
    border-bottom: 0;
    width: calc(100% - var(--ck-sample-sidebar-width));
    margin-bottom: 0;
	font-size: 14px;
}

