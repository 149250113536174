.topicTutorialsSearchDiv {
	display: flex;
}

.addNewTutorials {
	padding: 4px;
	/* border: 1px solid #f58220; */
	font-size: 13px;
	cursor: pointer;
	border-radius: 4px;
}

.addNewTutorials:hover {
	background: #DE6804 !important;
	color: #FFFFFF !important;
}

.tutorialsSearchFilterWrapper {
	display: flex;
	flex: 1;
	background: #e4e4e4;
	margin-right: 10px;
	padding: 5px;
	border-radius: 4px;
	height: 43px;
	font-size: 14px;
}

.tutorialsSearchFilterWrapper input {
	flex: 1;
	border: none;
	background: #e4e4e4;
	padding: 4px;
}

.topicTutorialsVideoSection ul {
	padding: 30px 30px;
	margin: 0;
	list-style: none;
}

.topicDescriptionDiv .existingHashTagsOfTopic .addHashtagsIconContainer {
	/* margin-bottom: 10px; */
}

.topicTutorialsVideoSection ul li {
	width: 346px;
	display: inline-block;
	min-height: 200px;
	border-radius: 10px;
	background: #eedfd4;
	position: relative;
	margin: 10px 20px 10px 10px;
}

.tutorialThumbnailBottom span {
	float: right;
	color: #333;
	padding-right: 5px;
}

.tutorialThumbnailBottom span.sampleTemplateRating {
	float: left;
	margin-left: 10px;
	cursor: unset;
}

.referActivityGroupContainer label.NotificationChkboxContainer input[type='checkbox']:focus {
	margin: 5px 0 0 2px !important;
	width: 15px !important;
}

.tutorialThumbnailBottom span.sampleTemplateRating>span:last-child {
	font-size: 13px;
	margin-top: 3px;
}

.tutorialUploadScreen .modal-dialog {
	max-width: 1000px;
}

.tutorialUploadScreen .modal-body {
	padding: 30px 40px;
	font-weight: 600;
}

/* .tutorialUploadScreen .modal-body li {
	line-height: 40px;
} */

.tutorialUploadScreen .modal-body ul {
	margin-bottom: 30px;
	padding: 0 15px;
}

span.dontShowThisMessage input {
	margin-right: 10px;
	margin-left: 24px;
}

span.dontShowThisMessage {
	margin-right: 130px;
	font-weight: 400;
}

.tutorialNextButton {
	margin: 30px;
	display: flex;
}

.tutorialThumbnailTop h5 {
	font-weight: bold;
	font-size: 17px;
	min-height: 40px;
	max-height: 40px;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

.tutorialInputFields {
	display: flex;
	flex-direction: column;
	margin: 15px 0;
}

.tutorialInputFields input {
	border: 1px solid #e4e4e4;
	padding: 5px;
	margin-top: 0;
}

.addTutorialButtons {
	margin: 5px;
}

.addTutorialButtons button {
	border: none;
	background: #0078d4;
	padding: 4px 50px;
	border-radius: 4px;
}

.addTutorialButtons button:first-child {
	background: #e4e4e4;
	color: #0078d4;
	margin-left: auto;
	margin-right: 20px;
}

.addTutorialButtons button:last-child {
	background: #0078d4;
	color: #fff;
	margin-right: auto;
}

.tutorialNextButton button {
	border: none;
	background: #0078d4;
	margin: 0 auto;
	padding: 4px 50px;
	border-radius: 4px;
	color: #fff;
}

.tutorialThumbnailBottom {
	position: absolute;
	bottom: 0;
	background: #f5f5f5;
	width: 100%;
	padding: 5px 0px 8px 0px;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	float: right;
}

.topicTutorialsMainDiv {
	flex-basis: 50%;
}

.topicTutorialsSearchDiv {
	display: flex;
	margin: 20px 50px;
}

.addNewTutorials {
	padding: 0px 8px;
	/* border: 1px solid #f58220; */
	font-size: 12px;
	margin: 7px 10px 2px 10px;
	cursor: pointer;
	border-radius: 4px;
	/* color: #f58220; */
	height: 32px;
	position: relative;
}

.addNewTutorials:hover,
.addNewTutorials:focus {
	background: #DE6804;
	color: #fff;
}

.addNewTutorials:hover .tabsTitleToolTip,
.addNewTutorials:focus .tabsTitleToolTip,
.tutorialSortIcon:focus .tabsTitleToolTip,
.tutorialSortIcon:hover .tabsTitleToolTip,
.hashtagInput:focus+.tabsTitleToolTip,
.hashtagInput:hover+.tabsTitleToolTip {
	display: block;
}

.tutorialSortIcon .tabsTitleToolTip,
.hashtagInput+.tabsTitleToolTip {
	left: -42px;
	top: 37px;
	padding: 1px 12px;
	text-align: center;
}

.addNewTutorials .tabsTitleToolTip span {
	font-size: 12px;
	white-space: nowrap;
	margin-right: 0;
}

.addNewTutorials .tabsTitleToolTip {
	left: -42px;
	top: 37px;
	padding: 1px 12px;
	text-align: center;
}

.tutorialsSearchFilterWrapper {
	display: flex;
	flex: 1;
	background: #e4e4e4;
	margin-right: 10px;
	padding: 5px;
}

.tutorialsSearchFilterWrapper input {
	flex: 1;
	border: none;
	background: #e4e4e4;
	padding: 4px;
}

.tutorialThumbnailBottom {
	position: absolute;
	bottom: 0;
	background: #f5f5f5;
	width: 100%;
	padding: 5px 0px 8px 0px;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	float: right;
}

.tutorialsModal {
	width: 80%;
}

.dropDowncontainer.sortDropdown:hover {
	background-color: #e4e4e4 !important;
}

.addNewTutorials i {
	font-size: 16px;
	font-weight: bolder;
	/* color: #f58220;
	transform: rotate(-90deg); */
	margin: 3px 0px;
}

.addNewTutorials:hover i,
.addNewTutorials:focus i {
	color: #fff;
}

.dropDowncontainer.sortDropdown i {
	font-size: 18px;
	margin-right: 10px;
	margin-top: 5px;
	line-height: 19px;
	vertical-align: sub;
	cursor: pointer;
}

.dropDowncontainer.sortDropdown i {
	font-size: 18px;
	margin-right: 10px;
	margin-top: 5px;
	line-height: 19px;
	vertical-align: sub;
}

.tutorialThumbnailTop {
	display: flex;
	flex-direction: column;
	padding: 10px 15px;
}

.thumbnailPlayButton {
	width: 50px;
	height: 50px;
	background: url(../../assets/play-button-blue.svg) no-repeat;
	background-size: contain;
	cursor: pointer;
}

.howToUpLoadImage {
	float: right;
	width: 300px;
	height: 345px;
	background: url(../../assets/HowToUploadTutorial.svg) no-repeat;
	background-size: contain;
	margin: 0 auto;
}

.stepsToFollowDiv {
	display: flex;
}

.addTutorialButtons div {
	margin: 15px auto;
	display: flex;
}

.tutorialsSearchFilterWrapper .tutorialsSearch {
	flex: 1;
	display: flex;
	margin-right: 10px;
}

.tutorialsSearchFilterWrapper .tutorialsSearch i {
	margin: 4px 15px 2px 30px;
	font-size: 20px;
	color: #666;
}

.addNewTutorials span {
	margin-right: 10px;
	vertical-align: top;
	padding-top: 1px;
	display: inline-flex;
	line-height: 30px;
	font-size: 15px;
}

.addTutorialDetails {
	display: flex;
	flex-direction: column;
	flex: 1;
}

.category-label {
	font-weight: 600;
}

.addTutorialDetails select {
	border: 1px solid #e4e4e4;
	padding: 5px;
}

.addTutorialDetails select:after {
	color: #e4e4e4;
}

.minutes,
.seconds {
	width: 100%;
	border-radius: 4px;
}

.timeformat {
	margin-top: 25px;
	font-weight: bold;
}

.tutorialInputFields.duration {
	flex-direction: row;
	margin: 0;
}

.addTutorialPopup {
	display: flex;
	font-size: 13px;
}

.tutorialUploadScreen .addTutorialYourContribution ul li {
	width: 250px !important;
	line-height: 22px;
	min-height: 140px;
}

.tutorialUploadScreen .addTutorialYourContribution ul li .thumbnailPlayButton {
	width: 30px;
	height: 30px;
	margin: 0px;
}

.dropDowncontainer.sortDropdown {
	padding: 5px 5px;
}

.tutorialSortIcon {
	float: left;
	cursor: pointer;
	width: 13px;
	height: 13px;
	background: url(../../assets/SortIcon.svg) no-repeat;
	background-size: contain;
	margin-top: 5px;
	margin-right: 10px;
}

.tutorialAddFields {
	display: flex;
}

.addTutorialDetails {
	display: flex;
}

.addTutorialYourContribution {
	display: flex;
}

.addTutorialYourContribution ul li {
	display: flex;
	flex-direction: column;
}

.hashTagsOfTutorials {
	display: flex;
	overflow: hidden;
	font-size: 13px;
}

.hashTagsOfTutorials span {
	color: #0078d4;
	margin-right: 10px;
	cursor: pointer;
}

.tutorialPublishedDate>span {
	padding-top: 5px;
}

.tutorialratings {
	flex: 1;
}

.tutorialPublishedDate span {
	font-size: 12px;
}

.tutorialAddFields>div {
	flex: 1;
}

.tutorialPreview {
	height: 230px;
	width: 310px;
}

.tutorialPreviewBlank {
	border-radius: 10px;
	background: #e4e4e4;
	height: 100%;
	width: 100%;
	margin: 10px;
	position: relative;
}

.tutorialPreviewBlank>p {
	text-align: center;
	position: absolute;
	top: 43%;
	left: 22%;
}

.tutorialPreview>p {
	margin-left: 10px;
}

.addTutorialYourContribution {
	display: flex;
	border-left: 1px solid #e4e4e4;
	margin-left: 20px;
}

.dropDowncontainer.tutorialToolsDropdown {
	width: 100%;
	border: 1px solid #e4e4e4;
}

.dropDowncontainer.tutorialToolsDropdown input {
	width: 90%;
	border: none;
}

.dropDowncontainer.tutorialToolsDropdown input {
	width: 95%;
	border: none;
	padding: 5px 5px;
	margin-top: 0;
}

.tutorialsHashTags {
	display: flex;
	flex-direction: column;
	margin-top: 15px;
	margin-bottom: -5px;
}

.tutorialsHashTags textarea {
	border: 1px solid #e4e4e4;
	margin-top: 5px;
}

.tutorialInputFields>span {
	font-size: 14px;
}

.addTutorialPopup .dropDowncontainer.tutorialToolsDropdown label.NotificationChkboxContainer {
	font-size: 14px;
}

.dropDowncontainer.tutorialToolsDropdown span.complexityLabel {
	margin-left: 25px;
}

.dropDowncontainer.tutorialToolsDropdown li {
	line-height: normal;
}

.dropDowncontainer.tutorialToolsDropdown span.CustomCheckmark {
	top: 3px;
}

.tutorialThumbnailTitle {
	display: flex;
}

.addTutorialYourContribution .tutorialThumbnailTop h5 {
	font-size: 13px;
	flex: 1 1;
	min-height: 29px;
	max-height: 29px;
	margin-bottom: 0;
}

.tutorialThumbnailTitle i {
	font-size: 11px;
	margin-left: 9px;
	cursor: pointer;
}

.dropDowncontainer.tutorialToolsDropdown i {
	cursor: pointer;
	font-size: 10px;
}

.addTutorialYourContribution .tutorialThumbnailBottom svg {
	width: 10px !important;
	height: 10px !important;
}

.addTutorialYourContribution .tutorialPublishedDate span {
	font-size: 9px;
}

.tutorialToolsTags span {
	border: 1px solid #e4e4e4;
	padding: 5px 9px;
	border-radius: 24px;
	margin: 2px 10px 2px 0px;
	display: inline-flex;
	font-size: 11px;
}

.tutorialToolsTags span i {
	font-size: 9px;
	font-weight: bolder;
	margin-left: 8px;
	cursor: pointer;
	margin-top: 2px;
}

.tutorialsHashTags span {
	margin-bottom: 0px;
}

.tutorialsHashTags input {
	border: 1px solid #e4e4e4;
	padding: 5px;
}

.hashTagsContainer>span {
	border: 1px solid #e4e4e4;
	padding: 2px 9px;
	border-radius: 24px;
	/* margin: 4px 10px 5px 0px; */
	display: inline-flex;
	font-size: 11px;
	background: #0078d4;
	color: #fff;
}

.hashTagsContainer span i {
	font-size: 9px !important;
	font-weight: bolder;
	margin-left: 8px;
	cursor: pointer;
	margin-top: 2px;
	padding: 0;
	border: none;
}

.hashTagsContainer span i:focus {
	outline: 1px dotted #fff !important;
}

.dropDowncontainer.hashtagsDropDownContainer .hashTagsContainer input {
	width: 150px;
}

.thumbnailPlayButtonContainer {
	display: flex;
	padding-top: 12px;
}

.thumbnailPlayButtonContainer span {
	font-size: 11px;
	margin: 4px auto 0 8px;
	color: #0078d4;
}

.addTutorialYourContribution .topicTutorialsVideoSection {
	overflow: hidden;
	overflow-y: auto;
	height: 68vh;
	width: 310px;
}

.tutorialsHashTags p {
	font-weight: normal;
	margin-top: 5px;
	margin-bottom: 10px;
}

.totorialUploaderDetails img {
	width: 32px;
	height: 32px;
	border-radius: 50%;
	margin-right: 10px;
}

.tutorialsVideo iframe {
	width: 100%;
}

.tutorialUploadScreen i {
	cursor: pointer;
}

.stepsToFollowDiv li {
	line-height: 21px !important;
	margin-bottom: 12px;
}

.dropDowncontainer.tutorialToolsDropdown ul {
	max-height: 190px;
}

.tutorialThumbnailTop a {
	margin: 3px auto 24px auto;
}

.globalHashtagsSearch .dropDowncontainer.sortDropdown:hover {
	background-color: #f4f4f4 !important;
	font-size: 12px !important;
}

.globalHashtagsSearch .dropDowncontainer.sortDropdown {
	position: relative;
	width: 123px;
	float: left;
	margin-right: 0px;
	font-size: 12px;
	padding-left: 20px;
}

.topicTutorialDeleteIcon {
	float: right;
	width: 13px;
	height: 13px;
	background: url(../../assets/video-delete.svg) no-repeat;
	background-size: contain;
	cursor: pointer;
	margin-top: 3px;
	margin-left: 8px;
	color: #acacac;
}

.tutorialTitlePart>div {
	display: flex;
	margin-left: auto;
}

.tutorialTitlePart {
	display: flex;
}

.tutorialTitlePart>div i {
	font-size: 13px;
	margin-right: 10px;
	cursor: pointer;
	color: #383838;
}

.addTutorialPopup.editTutorialPopUp {
	padding: 21px 50px;
	overflow: hidden;
	overflow-y: auto;
	height: calc(100vh - 9rem);
}

.addTutorialPopup.editTutorialPopUp .tutorialInputFields>div>input {
	width: 85%;
}

.addTutorialPopup.editTutorialPopUp .tutorialInputFields>div>span {
	margin-left: 10px;
}

.addTutorialPopup.editTutorialPopUp .tutorialInputFields>div>span input[type='checkbox'] {
	margin-right: 7px;
}

.addTutorialPopup.editTutorialPopUp .tutorialInputFields>span {
	font-weight: 600;
}

.editTutorials .cTyMuf:before {
	padding-top: 0 !important;
}

.grid-container {
	display: flex;
}

.tutorialGrid {
	margin: 10px;
	text-align: left;
	line-height: 1.5;
	font-size: 13px;
}

.tutorialGridNew {
	margin: 0px 10px 10px 10px !important;
}

.tableHeadingNew {
	padding: 0px 10px 0px 0px !important;
}

.main-container {
	width: 100%;
	height: 100%;
	display: flex;
	margin: 0;
	justify-content: flex-start;
	align-items: center;
	background-color: #fafafa;
}

.table-container {
	display: flex;
	flex-flow: column nowrap;
	background-color: white;
	width: 100%;
	margin: 0 auto;
	border-radius: 0px;
	border: 0px;
	box-shadow: none;
}

.show {
	display: block;
}

.hidden {
	display: none !important;
}

.wrapper {
	width: 100%;
	max-width: 1000px;
	margin: 1em auto;
	padding: 1em;
}

.tableHeading {
	width: 100%;
	max-width: 1000px;
	margin: 0;
	padding: 10px 10px 0 0;
	color: #507496;
}

/* Table column sizing ================================== */
.project-cell {
	width: 39%;
	font-weight: 500;
	cursor: pointer;
}

.project-cell:hover {
	color: #328cd3;
}

.project-cell.column-heading {
	font-weight: normal;
}

.duration-cell {
	width: 10%;
}

.rating-cell {
	width: 18%;
}

.date-created-cell {
	width: 12%;
}

.status-cell {
	width: 12%;
}

.edit-cell {
	width: 3%;
	padding: 0;
}

.edit-icon-cell {
	width: 6%;
	color: #9A9A9A;
}

.Rtable {
	display: flex;
	flex-wrap: wrap;
	margin: 0;
	padding: 0;
	box-shadow: none;
	font-size: 13px;
	color: #676767;
}

.Rtable .Rtable-row {
	width: 100%;
	display: flex;
	border-bottom: 1px solid #eee;
}

.Rtable .Rtable-row:hover {
	background-color: #F2f2f2;
}

.Rtable-row.video-thumbnail:hover {
	background: white;
}

.Rtable-row.Rtable-row--head {
	border-bottom: 0;
	background: #E5E4FC;
}

.Rtable .Rtable-row .Rtable-cell {
	box-sizing: border-box;
	flex-grow: 1;
	padding: 7px 10px;
	overflow: hidden;
	list-style: none;
}

.Rtable .Rtable-row .Rtable-cell.column-heading {
	background-color: #E5E4FC;
	color: #3A2529;
	padding: 8px 10px;
	margin-right: 1px;
}

.Rtable .Rtable-row .Rtable-cell .Rtable-cell--heading {
	display: none;
}

.Rtable .Rtable-row .Rtable-cell .Rtable-cell--content a {
	font-size: 2em;
	color: #333;
}

.Rtable .Rtable-row .Rtable-cell .Rtable-cell--content .webinar-date {
	font-weight: 700;
}

.Rtable .status-inprogress {
	color: #DD7A2D;
}

.Rtable .status-complete {
	color: #36C02D;
}

.Rtable-cell.project-cell a {
	color: #2C2C2D;
}

.Rtable-cell.project-cell a:hover {
	color: #328cd3;
	text-decoration: none;
}

.duration-cell.column-heading {
	padding-left: 6px !important;
}

.rating-cell.column-heading {
	padding-left: 3px !important;
}

.date-created-cell.column-heading {
	padding-left: 0 !important;
}

.no-flexbox .Rtable {
	display: block;
}

.no-flexbox .Rtable.Rtable-cell {
	width: 100%;
}

.Rtable .sampleTemplateRating .star {
	stroke: black;
	stroke-width: 2px;
	stroke-linejoin: round;
}

.Rtable .star-container {
	padding-right: 1px !important;
}

.video-thumbnail-top {
	height: 185px;
	background: transparent linear-gradient(271deg, #FFE8CA 0%, #EAEFFD 100%) 0% 0% no-repeat;
	border-radius: 8px;
	position: relative;
	margin: 15px 0;
}

.video-title {
	padding: 20px 20px 0;
}

.video-title h5 {
	font-weight: bold;
	color: #4D4F5C;
}

.thumbnailPlayButton {
	margin: 3px auto 24px;
}

.video-duration {
	background: #8F8F8F;
	color: white;
	padding: 5px 7px;
	position: absolute;
	bottom: 30px;
	margin: 0;
	right: 20px;
	font-size: 14px;
}

.video-close {
	position: absolute;
	right: 20px;
	top: 20%;
	font-size: 20px;
}

.starRating i {
	margin-right: 3px;
	font-size: 11px;
	color: #f3bd0c;
}

.video-created-date {
	color: #676767;
	margin-bottom: 10px;
	font-size: 14px;
}

.video-expand {
	width: 50px;
	height: 50px;
	background: url(../../assets/video-expand.svg) no-repeat;
	position: absolute;
	right: -12px;
	top: 10%;
}

.iframe-container {
	margin: 0 auto;
	width: 44%;
	position: relative;
	bottom: 14%;
}

iframe {
	height: 360px;
}

.tutorialPreviewBlank iframe {
	height: 230px;
	border-radius: 10px;
	width: 100%;
}

.hiddenRow {
	display: none !important;
}

.TabYourContribution {
	text-align: center;
}


.addTutorialLink {
	color: #4D4F5C;
	font-size: 14px;
	font-weight: bold;
	text-decoration: underline;
	margin-top: 10px;
	position: relative;
	left: 26%;
}

.p1 {
	padding: 1% !important;
}

.tooltipInfo {
	position: relative;
	display: inline-block;
}

.tooltipInfo .tooltiptext {
	visibility: hidden;
	width: 350px;
	background-color: black;
	color: #fff;
	text-align: left;
	border-radius: 3px;
	padding: 5px 0;
	position: absolute;
	font-size: 13px;
	z-index: 1;
	font-weight: normal;
	left: -24px;
	bottom: 24px;
	padding: 10px;
	box-shadow: 0px 1px 4px #ccc;
}

.tooltipInfo .tooltiptext::after {
	content: "";
	position: absolute;
	top: 100%;
	left: 12%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: black transparent transparent transparent;
}

.tooltipInfo:hover .tooltiptext {
	visibility: visible;
}

.infoIcon {
	margin-left: 10px;
}

.minsContainer {
	margin-right: 10px;
	color: #333333;
	font-weight: normal;
}

.secContainer {
	margin-left: 10px;
	color: #333333;
	font-weight: normal;
}

.secContainer label,
.minsContainer label {
	margin-bottom: 3px;
}

.dropDowncontainerVideo {
	position: relative !important;
	width: 50% !important;
	float: left !important;
	margin-right: 30px !important;
}

.dropDowncontainerVideo .wrapHeader {
	margin-right: 5px;
	width: 90% !important;
	overflow: hidden;
	white-space: nowrap;
	float: left;
	text-overflow: ellipsis;
	font-weight: 400 !important;

}

.topictuTorialContainer {
	display: flex;
	width: 100%;
	flex-direction: row;
	flex-wrap: wrap;
}

#editTutorialModal .saveContinueDetailsContainer {
	position: absolute;
}

/* Responsive ==================================== */
@media all and (max-width: 1242px) {
	.edit-icon-cell {
		width: 7%;
	}
}

@media all and (min-width: 1900px) {
	.edit-icon-cell {
		width: 4%;
	}
}

.noCursor {
	cursor: default !important;
}

.edit-icon-cell>div>i:focus {
	outline: 0.5px dotted #CCCCCC !important;
}

.sampleTemplateRating>div:focus {
	outline: 0.5px dotted #CCCCCC !important;
}

.nameOfTableData:focus {
	outline: #353535 solid 1px;
	display: inline-block;
}