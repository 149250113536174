._groupHeader {
    height: 19px;
    text-align: left;
    font: normal normal 600 14px/19px 'Segoe UI';
    letter-spacing: 0.35px;
    color: #324461;
    text-transform: capitalize;
    opacity: 1;
    margin-top: 1rem;
    margin-bottom: 2.5px;
}

._groupHeaderLinks {
    height: 19px;
    text-align: left;
    font: normal normal normal 14px/19px 'Segoe UI';
    letter-spacing: 0.34px;
    color: #333333 !important;
    opacity: 1;
    padding: 0;
    text-decoration: none;
}

._groupHeaderLinks:hover {
    text-decoration: underline;
}

._horizontalLine {
    margin-top: 0;
    margin-bottom: 10px;
}

._additionalResourceBox {
    padding-inline: 18px !important;
}