.arrow-navigation {
    display: flex;
    align-items: center;
    margin-top: 5px;
  }
  
  .navigation-info {
    font: normal normal normal 10px/12px Segoe UI;
    letter-spacing: -0.1px;
    color: #212121;
    opacity: 1;
  }
  
  .arrow-button {
    background-color: white;
    border: none;
    font-size: 25px;
    cursor: pointer;
    outline: none;
    padding-right: 0px;
  }
  .activityTopArrowIcon:focus {
    outline: none !important;  /* Removes the focus outline */
    border: none !important;   /* Removes any border */
  }
  .activityTopArrowIcon:active {
    outline: none !important;  /* Removes the focus outline */
    border: none !important;   /* Removes any border */
  }
  .arrow-button:disabled {
    color: gray;
    cursor: not-allowed;
  }
  .activityTopArrowIcon {
     float: left;
     width: 10px;
     height: 19px;
    background: url(../../assets/Up.svg) no-repeat;
    background-size: contain;
    /* /* margin-top: 12px; */
    margin-right: 7px; 
  }
  .activityBottomArrowIcon {
    float: left;
    width: 10px;
    height: 19px;
    background: url(../../assets/Down.svg) no-repeat;
    background-size: contain;
    /* margin-top: 12px;
    margin-right: 10px; */
  }
  .Navigationpagecount{
    font-size: 14px !important;
letter-spacing: -0.1px;

margin-right: 6px;
  }
  /* Add this to your ArrowNavigation.css file */
.NavigationDivresuable div:focus, .NavigationDivresuable button:focus {
  outline: none;
}
.NavigationDivresuable{
  min-width: 28px;
  max-width: auto;
  height: 25px;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0 0 5px;
  outline: none;
  align-items: center;
  border-right: 1.5px solid #d8d8d8;
  padding-inline: 1px 8px;

}
.arrowButton{
  border: none;
  padding: 0px !important;
  background-color: #f5f5f5;
  outline: none;
  
}
.arrowButton.disabled {
  pointer-events: none; /* Prevent clicking */
  opacity: 0.5; /* Optional, to visually indicate the disabled state */
}
.arrowButton:focus {
  outline: none !important;
}
.arrowButton:active {
  outline: none !important;
}


