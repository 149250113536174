.topicLandingMainContainer {
	display: flex;
	margin-top: 50px;
}

.topicsLandingPage {
	display: flex;
	background: #f2f2f2;
	min-height: 80vh;
	width: 100%;
	flex-direction: column;
}

.topicsLandingPage .phaseBreadcrumb {
	background: #fff;
	box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.2470588235294);
	width: 100%;
	position: fixed;
	z-index: 9;
	top: 50px;
}

.topicsContainer {
	margin: 35px 30px 0 90px;
	transition: all 0.2s ease-in;
	flex: 1;
}

.topicsMenuWrapper {
	width: 100%;
	display: flex;
	background: #fff;
	box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.247058823529412);
	margin-top: 15px;
	justify-content: flex-end;
}

.topicsMenuDescription {
	width: 100%;
	display: flex;
	background: #fff;
	box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.247058823529412);
	margin-top: 8px;
	padding: 15px 15px 15px 10px;
	font-size: 14px;
	line-height: 1.3;
}

.topicsMenuDescription span {
	font-weight: 700;
	margin-right: 8px;
}

.topicsMenuDescriptionLatest {
	display: flex;
	align-items: center;
	font-size: 14px;
	margin-right: auto;
	padding-left: 10px;
}

.topicsMenu {
	float: left;
	padding: 8px;
	display: contents;
	margin: 0;
	padding: 0;
	list-style: none;
	font-weight: 600;
}

.topicsMenu span {
	padding: 0 70px;
}

.topicsMenu li {
	float: left;
	padding: 10px 24.5px 10px 21px;
	list-style: none;
	text-align: center;
	flex-grow: 1;
	flex-basis: 0;
	cursor: pointer;
	color: #666;
}

.selectedTopicMenu {
	border-bottom: 4px solid #f58220;
	opacity: 1 !important;
	color: #333 !important;
}

.topicsListContainer {
	width: 100%;
	display: flex;
	background: #fff;
	box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.247058823529412);
	margin-top: 10px;
	min-height: 80vh;
	flex-direction: column;
}

.topicsListWrapper {
	margin: 4px;
	padding: 30px 20px 20px 5px;
	min-width: 0;
	flex-basis: 32%;
}

.topicsListWrapperNew {
	padding: 0px 20px 20px 5px !important;
}

.topicsList {
	display: flex;
	width: 100%;
	flex-direction: row;
	flex-wrap: wrap;
}

.topicsListWrapper h5 {
	font-size: 14px;
	text-align: left;
	margin-bottom: 0px;
	flex: 1;
	line-height: inherit;
}

.topicsListWrapper li {
	list-style: none;
	font-size: 13px;
	text-align: left;
	padding: 1px 0;
	display: flex;
}

.topicsListWrapper ul {
	margin: 0;
	padding: 0;
	margin-bottom: 30px;
	margin-left: 10px;
}

.topicsListWrapper li a {
	color: #0078d4;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	flex: 1;
	line-height: 23px;
}

.topicsMatrixMenuContainer ul {
	display: flex;
	margin: 0;
}

.topicsMatrixMenuContainer ul li {
	list-style: none;
	padding: 3px 10px;
	margin-right: 20px;
	font-size: 13px;
	box-shadow: 1px 0px 7px 1px #0000001f;
}

.topicsMatrixMenuContainer {
	padding-top: 20px;
}

.initiatingImg {
	float: left;
	width: 14px;
	height: 12px;
	background: url(../../assets/InitiatingImg.svg) no-repeat;
	background-size: contain;
}

.planingImage {
	float: left;
	width: 14px;
	height: 12px;
	background: url(../../assets/planninImg.svg) no-repeat;
	background-size: contain;
}

.executingImage {
	float: left;
	width: 14px;
	height: 12px;
	background: url(../../assets/InitiatingImg.svg) no-repeat;
	background-size: contain;
}

.closingImage {
	float: left;
	width: 14px;
	height: 12px;
	background: url(../../assets/InitiatingImg.svg) no-repeat;
	background-size: contain;
}

.monitoringImage {
	float: left;
	width: 14px;
	height: 12px;
	background: url(../../assets/monitoring.svg) no-repeat;
	background-size: contain;
}

.topicsMatrixMenuContainer ul li span {
	margin: 0 5px;
	cursor: pointer;
}

.slectedMatrix {
	background: #f58220;
}

.topicLandingFilters {
	display: none;
}

.topicLandingMainContainer div#filtersRightPanel {
	position: fixed;
}

.topicsMenuWrapper button.filtersButton {
	margin-top: 10px;
	display: flex;
	padding: 3px 11px;
}

.topicsMenuWrapper button.filtersButton i {
	margin-top: 3px;
}

p.noTopicsFound {
	font-size: 13px;
	color: #666;
}

.topicFilterTagsDiv .filterTagsContainer {
	border-bottom: none;
}

p.noWorkInstructions {
	margin: 12rem auto;
	text-align: center;
	font-size: 24px;
	color: #666666;
}

.filtersCategoryPanel .spinner {
	position: inherit;
	top: 50%;
	left: 50%;
	padding-left: 50px;
}

.filtersCategoryPanel .spinner .spinner-border {
	width: 1.2rem;
	border: 2px solid #3291e7;
	border-right-color: transparent;
	height: 1.2rem;
}

.spinner.dynamicFilterSpinner .spinner-border {
	width: 1.2rem;
	border: 2px solid #3291e7;
	border-right-color: transparent;
	height: 1.2rem;
}

.noProcedure {
	padding-left: 15px;
	font-size: 15px;
	padding-top: 10px;
}

.topicsLeftMenuContainer {
	background: #2b314c;
	color: #fff;
	position: fixed;
	height: calc(100vh - 5rem);
	top: 89px;
	left: 0;
	width: 55px;
	transition: all 0.2s ease-in;
}

.topicsLeftMenuContainer ul {
	list-style: none;
	padding: 36px 0 0 0;
}

.topicsLeftAndMainContainer {
	width: 100%;
}

.topicsLeftMenuContainer ul li:hover {
	background-color: #1c2139;
}

.topicsLeftMenuContainer ul li:hover {
	background-color: #1c2139;
}

.topicsLeftMenuContainer ul a.NavLink {
	padding: 10px 15px;
	font-size: 13px;
	margin: 25px 0;
}

.topicsLeftMenuIcon {
	font-size: 14px;
	padding: 10px 13px 10px 14px;
	color: #f58220;
	position: absolute;
	bottom: 4px;
	cursor: pointer;
	right: 6px;
}

.topicsLeftMenuContainer ul a.NavLink:hover path {
	fill: #f58220;
}

.topicsLeftMenuContainer ul a.NavLink.SelectedTopicsTab path {
	fill: #f58220;
}

.topicTypeIcon {
	width: 20px;
	height: 20px;
	margin-right: 8px;
}

.topicsLeftMenuContainer ul a.NavLink:focus {
	outline: 1px dotted #fff !important;
}

.topicsLeftMenuContainer.expanded {
	width: 120px;
	transition-duration: 200ms;
}

.topicsContainer.expanded {
	margin: 35px 25px 0 140px;
	transition-duration: 200ms;
}

.topicsContainerVideoTutorial.expanded {
	margin: 35px 15px 0 15px;
	transition-duration: 200ms;
}

a.NavLink.SelectedTopicsTab {
	color: orange;
}

.topicsLeftMenuIcon i {
	cursor: pointer;
}

.topicLandingMainContainer.topicParentWrapper .topicsLeftAndMainContainer {
	display: flex;
}

/* filter css */

#filtersRightPanel {
	width: 300px;
	position: absolute;
	top: 0px;
	right: 0;
	flex-direction: column;
	min-width: 0;
	word-wrap: break-word;
	background-color: #fff;
	background-clip: border-box;
	border: 1px solid rgba(0, 0, 0, 0.125);
	border-radius: 0.25rem;
	z-index: 9999;
}

.filtersPanelBody {
	height: calc(100vh - 3.4rem);
	/* height: calc(100vh - 6.5rem); */
	overflow: hidden;
	overflow-y: auto;
	padding-bottom: 100px;
}

.filtersPanelBodyLatest {
	/* height: calc(100vh - 3.4rem); */
	height: calc(100vh - 6.5rem);
	overflow: hidden;
	overflow-y: auto;
	padding-bottom: 100px;
}

.filtertagsStylefont {
	font-size: 14px;
}

.filterPanelHeader {
	display: flex;
	padding: 10px;
	background: #e4e9ed;
}

.filterPanelFooter {
	padding: 10px;
	border-top: 1px solid #f2f2f2;
}

.filterPanelFooter .btn:first-child {
	padding: 4px 35px;
	font-size: 14px;
	border-radius: 10px;
	margin-right: 10px;
}

.filterPanelFooter .btn:last-child {
	padding: 4px 35px;
	font-size: 14px;
	border-radius: 10px;
	border: 1px solid #f2f2f2;
}

.filterPanelHeader h5 {
	flex: 1;
}

.filterPanelHeader i {
	cursor: pointer;
}

.filtersCategoryPanel {
	display: flex;
	padding: 10px;
	flex-direction: column;
	border-bottom: 1px solid #e4e4e4;
}

.filtersCategoryPanel ul {
	margin-left: 0;
	list-style: none;
	padding: 0;
	margin-bottom: 0;
}

span.filterLabel {
	font-size: 12px;
	padding-left: 23px;
}

.filtersCategoryPanel .filterChkboxContainer {
	margin-bottom: 0;
	padding-left: 10px;
	padding-bottom: 0px;
}

.filtersCategoryPanel .filterChkboxContainer span.filterLabel {
	padding-left: 0;
	font-size: 12px;
}

.filterChkboxContainer .checkmark {
	height: 12px;
	width: 12px;
	border: 1px solid #3291e7 !important;
}

.filtersCategoryPanel .checkBoxContainer .checkmark:after {
	left: 1px;
	top: 1px;
}

.filtersCategoryPanel p {
	font-size: 12px;
	margin: 6px 0 0 11px;
	cursor: pointer;
	color: #0062ad;
	font-weight: 600;
}

.noFiltersFound {
	color: #666666 !important;
	cursor: unset !important;
}

.filtersearch h6 {
	flex: 1;
}

.filtersearch {
	display: inline-flex;
}

.filtersCategoryPanel label.NotificationChkboxContainer {
	margin-bottom: 0;
	font-size: 16px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.filtersCategoryPanel .NotificationChkboxContainer .CustomCheckmark:after {
	top: -1px;
	font-size: 9px;
}

.filtersCategoryPanel label.NotificationChkboxContainer .CustomCheckmark {
	width: 12px;
	height: 12px;
	top: 5px;
	border: 1px solid #3291e7 !important;
	border-radius: 0px;
}

.rolesSearch {
	display: flex;
	border: 1px solid #d7e7ff;
	background: #d7e7ff;
}

.rolesSearch input {
	border: none;
	flex: 1;
	background: #d7e7ff;
	font-size: 12px;
}

.rolesSearch i {
	cursor: pointer;
	font-size: 16px;
	font-weight: bold;
	margin: 1px 5px;
}

.filtersearch i {
	transform: rotate(-90deg);
	font-weight: bolder;
}

.filtersearch span {
	background: #d7e7ff;
	border-radius: 50%;
	width: 24px;
	height: 24px;
	cursor: pointer;
}

.filtersearch i {
	transform: rotate(-90deg);
	font-weight: bolder;
	font-size: 11px;
	margin-left: 7px;
	vertical-align: text-top;
	margin-top: 3px;
}

.globalHashtagsSearch {
	display: flex;
	padding: 1px;
	background-color: #f4f4f4;
	position: relative;
	border: 1px solid #e0e0e0;
	border-radius: 4px;
	margin: 7px;
	font-size: 13px;
	height: 32px;
}

.globalHashtagsSearch i {
	margin-right: 5px;
	padding: 4px 1px 1px 5px;
	color: #666;
	font-size: 15px;
}

.globalHashtagsSearch .dropDowncontainer.hashtagsDropDownContainer input {
	padding: 4px 5px 5px 5px;
	border: none;
	background-color: #f4f4f4;
	width: 193px;
}

.extractFiltersIcon {
	float: left;
	width: 14px;
	height: 12px;
	background: url(../../assets/fileexportIcon.svg) no-repeat;
	background-size: contain;
	margin-top: 4px;
	margin-left: 7px;
}

.extractFiltersIconWhite {
	display: none;
	float: left;
	width: 14px;
	height: 12px;
	background: url(../../assets/fileexportIconWhite.svg) no-repeat;
	background-size: contain;
	margin-top: 4px;
	margin-left: 7px;
}

.extractFiltersButton>span:hover .extractFiltersIconWhite {
	display: block;
}

.extractFiltersButton>span:hover .extractFiltersIcon {
	display: none;
}

.topicsContainer .filterPanelHeader {
	display: flex;
}

.topicsContainer .filterPanelHeader>div {
	display: flex;
}

.extractFiltersButton>span {
	display: flex;
	border: 1px solid #343a40;
	font-size: 12px;
	padding: 2px 8px;
	cursor: pointer;
	border-radius: 4px;
}

.extractFiltersButton>span:hover {
	background: #343a40;
	color: #fff;
}

.extractFiltersButton {
	margin: 15px 0;
}

.extractFiltersContainer {
	padding: 10px 100px;
}

.extractFiltersContainer .extractFileContainer {
	margin-top: 7px;
}

.extractFiltersContainer .extractDetailsButton {
	padding: 5px 20px;
	color: #fff;
	margin-left: 0;
}

.modal-dialog.ExtractModalPopUp {
	max-width: 400px;
}

.topicsCatogoryHeader {
	padding: 9px 11px 9px 15px;
	background: #e5e4fc;
	margin-bottom: 10px;
	display: flex;
}

.topicEditCollaborators {
	float: right;
	width: 20px;
	margin: 2px 5px 0 0;
	height: 20px;
	background: url(../../assets/EditTopicCollaborators.svg) no-repeat;
	background-size: contain;
	cursor: pointer;
}

.addTopicByOwnerIcon {
	float: right;
	width: 19px;
	height: 19px;
	background: url(../../assets/AddNewTopic.svg) no-repeat;
	background-size: contain;
	cursor: pointer;
	margin-top: 2px;
}

.topicsListWrapper li span.draftActivity {
	position: static;
	background: #0078d4;
	top: 0px;
	left: 1px;
	font-size: 10px;
	color: #fff;
	padding: 0px 5px;
	height: 16px;
	margin-top: 3px;
	margin-right: 5px;
	border-bottom-right-radius: 5px;
}

.topicOwnerEditIcon {
	float: left;
	width: 19px;
	height: 19px;
	background: url(../../assets/TopicOwnerEditIcon.svg) no-repeat;
	background-size: contain;
	cursor: pointer;
}

.topicOwnerDeleteIcon {
	float: right;
	width: 19px;
	height: 19px;
	background: url(../../assets/TopicOwnerDeleteIcon.svg) no-repeat;
	background-size: contain;
	cursor: pointer;
	margin-right: 9px;
}

.topicOwnerDeletIcon {
	float: right;
	width: 19px;
	height: 19px;
	background: url(../../assets/TopicOwnerDeletIcon.svg) no-repeat;
	background-size: contain;
	cursor: pointer;
	margin-right: 9px;
}

.bigTopicOwnerDeletIcon {
	width: 28px !important;
	height: 28px !important;
}

.methodOwnerMainContainer .sequentialPhaseList .sequentialdraggblePhaseList .sequentialPhaseItem {
	position: relative;
}

.topicEditCatogoryIcon {
	position: relative;
}

.topicEditCatogoryIcon .topicEditCollaborators:hover~.tabsTitleToolTip,
.topicEditCatogoryIcon a:hover~.tabsTitleToolTip {
	display: block;
}

.topicEditCatogoryIcon .tabsTitleToolTip {
	white-space: nowrap;
	padding: 6px 10px;
	left: -48px;
	top: 25px;
}

.deletePopup.topicDeletePopup .modal-header {
	padding-left: 57px;
}

.deletePopupOkButton button {
	border: none;
	background: #0078d4;
	border-radius: 4px;
	color: #fff;
}

.deletePopupOkButton {
	text-align: center;
	margin: 10px;
}

.topicsListWrapper li div a {
	display: inline-flex;
}

.relatedContentUpdateModal.modal .modal-dialog .modal-title.h4 {
	font-size: 19px;
}

.methodPhaseLeftMenu li:focus {
	outline-offset: -1px;
}

.topicBuilderPreviewPopUp .modal-header {
	padding: 10px 10px 10px 20px;
}

.topicsCrowdsourceHistoryModal figure.table {
	width: 700px !important;
}

.phaseCreationRightPopup.manageRoles.topicCategory {
	max-width: 90% !important;
}

.topicsMenuWrapper .addDeliveryPlaybookButton {
	padding: 6px;
	border: none;
	/* margin-left: 59px; 
	width: auto;*/
	width: auto;

}

.addDeliveryPlaybookButton button {
	display: flex;
	flex: 1 1;
	text-align: left;
}

.topicCategorySequenceContainer {
	display: flex;
}

.topicCategoryContainer {
	width: 400px;
	border-right: 1px solid #e4e4e4;
}

.topicSequenceContainer {
	flex: 1;
}

.phaseCreationRightPopup.manageRoles.topicCategory .methodOwnerMiddleContainer {
	padding: 0;
}

.topicSequenceContainer .sequentialPhaseList {
	padding: 10px;
	margin: 0;
}

.topicSequenceContainer .sequentialPhaseList .sequentialdraggblePhaseList {
	flex-direction: row;
	flex-wrap: wrap;
}

.topicSequenceContainer .sequentialPhaseList .sequentialdraggblePhaseList .sequentialPhaseItem {
	width: 230px;
	margin-right: 15px;
	background: #f6f6fb;
	display: flex;
	min-height: 60px;
	align-items: center;
}

.topicBulderContainer .topicsMenu li.selectedTopicMenu {
	border-bottom-color: #0078d4 !important;
	color: #0078d4 !important;
}

.topicCategoryContainer .addRoleNameContainer .dropDowncontainer {
	width: 100%;
}

.topicCategoryContainer .addRoleNameContainer .dropDowncontainer .dropDownBox {
	padding: 6px 9px !important;
	border: 1px solid #e4e4e4;
}

.topicCategoryContainer .addRoleNameContainer input {
	width: 100%;
}

.topicSequenceContainer .sequentialPhaseList .sequentialdraggblePhaseList>div {
	flex-direction: row;
	display: flex;
	flex-wrap: wrap;
}

.topicSequenceContainer .sequentialPhaseList .sequentialdraggblePhaseList .sequentialPhaseItem i {
	margin-top: 2px;
}

.topicCategoryContainer .addRoleNameContainer .dropDowncontainer .dropDownList {
	top: 38px;
}

.topicCategoryContainer .addRoleNameContainer .dropDowncontainer .dropDownList.coOwner {
	top: 1px;
}

.topicCategoryContainer .addRoleNameContainer .dropDowncontainer .dropDownList.coOwner label.NotificationChkboxContainer {
	font-size: 13px;
}

.topicCategoryContainer .addRoleNameContainer .collabaratorsChildContainers {
	width: 100%;
}

.topicCategoryContainer .addRoleNameContainer ul.afterSaveListTopic.collaboratorsApprovers {
	padding: 10px;
	border: 1px solid #e8e9ec;
	width: 100%;
}

.topicCategoryContainer .addRoleNameContainer ul.afterSaveListTopic.collaboratorsApprovers i {
	font-size: 11px;
	margin-left: 9px;
	font-weight: bold;
	vertical-align: middle;
}

.addRoleNameContainer.topicCategoryName>div {
	display: flex;
}

.addRoleNameContainer.topicCategoryName label.NotificationChkboxContainer {
	font-size: 12px;
	margin-left: 16px;
	margin-bottom: 0;
	margin-top: 5px;
}

.addRoleNameContainer.topicCategoryName label.NotificationChkboxContainer input[type='checkbox']:focus {
	outline: 1px dotted black !important;
	height: 15px;
	width: 15px;
	margin: 3.5px 2px 4px 3px !important;
}

.addRoleNameContainer.topicCategoryName input {
	width: 77%;
}

.addRoleNameContainer.topicCategoryName label.NotificationChkboxContainer span.CustomCheckmark {
	left: 3px;
	top: 3.5px;
}

.addRoleNameContainer.topicCategoryName label.NotificationChkboxContainer span.complexityLabel {
	margin-top: 15px;
	margin-left: 24px;
}

.topicCategory ul.methodPhaseLeftMenu li:first-child {
	width: 414px;
}

.topicCategory ul.methodPhaseLeftMenu li {
	font-weight: 600;
	text-align: left;
	font-size: 15px;
}

.topicsCatogoryHeader span.phaseCollaboratorAddIcon {
	margin: 0;
	width: 21px;
	height: 21px;
	margin-top: 2px;
}

.topicsCatogoryHeader span.ActivityDeleteIcon {
	width: 26px;
	height: 26px;
	margin: 0;
	margin-top: -1px;
}

.topicEditCatogoryIcon .ActivityDeleteIcon:hover~.tabsTitleToolTip,
.topicEditCatogoryIcon .phaseCollaboratorAddIcon:hover~.tabsTitleToolTip,
.topicEditCatogoryIcon .addTopicByOwnerIcon:hover~.tabsTitleToolTip {
	display: block;
}

.topicSequenceContainer .sequentialPhaseList .sequentialdraggblePhaseList .OaGms {
	padding: 0 !important;
	cursor: pointer;
	margin: 0 15px 15px 0;
	flex: 0 0 31.3%;
}

.topicSequenceContainer .sequentialPhaseList .sequentialdraggblePhaseList .OaGms:before {
	padding: 0 !important;
}

.supportMessage {
	margin: 4px;
	padding: 20px 15px 20px 20px;
	flex-basis: 100%;
	color: #4D4F5C;
	font-size: 16px;
	text-align: center;
}

.errorIcon {
	color: #c0c2ca;
	text-align: center;
	margin-bottom: 10px;
	font-size: 39px;
}

span.TopicCategoryName.disable {
	opacity: 0.7;
	pointer-events: none;
}

.topicSelectedContainer {
	height: 27px !important;
}

.topicSelectionContainer {
	width: 125px;
	height: 54px;
	border: 1px solid #0078D4;
	position: absolute;
	top: 22px;
	right: 0px;
	background-color: #FFFFFF;
	border-radius: 3px;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: space-around;
}

.topicSelectionContainer a {
	color: #333333 !important;
	text-align: left !important;
	font: normal normal normal 11px/15px Segoe UI !important;
	letter-spacing: -0.11px !important;
	color: #333333 !important;
	opacity: 1 !important;
	padding: 5px 10px 5px 10px !important;
	height: 100% !important;
	text-decoration: none
}

.topicSelectionContainer a:hover {
	background-color: #E1F0FB;
	text-decoration: none
}

.hexagonTopicChilds {
	padding: 0px !important;
	margin-left: 20px;
}

.red-asterisk {
	color: red;
}

.hidden {
	display: none;
}

.expandPolygonTopic {
	margin-left: -0.5px;
	margin-top: 7px;
	float: left;
	width: 21px;
	height: 17px;
	background-size: contain;
	cursor: pointer;
	background: url(../../assets/expandPolygonTopicIcon.svg) no-repeat;
}

.collapsePolygonTopic {
	margin-left: -0.5px;
	margin-top: 7px;
	float: left;
	width: 21px;
	height: 17px;
	background-size: contain;
	cursor: pointer;
	background: url(../../assets/collapsePolygonTopicIcon.svg) no-repeat;
}

.addingWhiteSpacesForExpandIcon {
	padding-inline: 7px;
}

.singleTopic>a {
	padding-left: 2px;
}

.polygonTopicIcons {
	cursor: pointer;
	color: #535353;
	margin-left: -0.5px;
	margin-top: 6px;
	padding-right: 5.5px;
	float: left;
	font-size: 9px;
	vertical-align: middle;
}

.filtertagsStyle {
	display: inline-block;

	border: 1px solid #e4e4e4;
	border-radius: 5px;
	font-size: 13px;
	font: normal normal normal 13px / 17px Segoe UI;
	height: 26px;
	letter-spacing: -.13px;
	margin: 4px 5px 2px 0;
	padding: 3px 9px;
}
.filtertagsStyle_Nonrealized{
	margin: 4px 5px 4px 0 !important;	

}
.Complexity_Non_Realized{

	background: rgb(235, 243, 255) !important;
}
.filtersPanelBodyLatestNonRealized{
    height: calc(100vh - 14.9rem);
    overflow: hidden;
    overflow-y: auto;
    padding-bottom: 100px;
}
.filtersRightPanelNonRealized{
	margin-top: 36px !important;
}