.dropDowncontainer {
	position: relative;
	width: 152px;
	float: left;
	margin-right: 30px;
}
.dropDownBox {
	display: block;
	min-height: 25px;
	overflow: hidden;
	width: 100%;
	cursor: pointer;
	position: relative;
	padding: 2px 9px !important;
	border: 1px solid #3b3d41;
	box-shadow: none;
	text-decoration: none;
	color: #272B2F;
	background: #fff;
	border-radius: 3px;
	/* margin-top: 4px; To remove the whiteness and unneccsary dotted lines that comes */
	font-size: 12px;
}
.wrapHeader {
	margin-right: 5px;
	width: 110px;
	overflow: hidden;
	white-space: nowrap;
	float: left;
	text-overflow: ellipsis;
}
.dropDownBox i {
	font-size: 11px;
	float: right;
	margin-top: 3px;
}
.dropDowncontainer.complexityFilter .wrapHeader {
	width: 56px;
}
.dropDowncontainer.complexityDisabled .wrapHeader {
	width: 56px;
}
.dropDownList {
	list-style: none;
	display: none;
	padding: 12px 0 5px 0;
	top: 34px;
	z-index: 99999;
	box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.247058823529412);
	position: absolute;
	background: #fff;
	border: 1px solid #ccc;
	border-top: 0;
	width: 100%;
	border-radius: 3px;
}
.dropDownList ul {
	overflow-y: auto;
	overflow-x: hidden;
	width: 100%;
	margin: 0 !important;
	padding: 0 !important;
	max-height: 36vh;
}
.dropDownList ul li {
	width: 100%;
	display: block;
	cursor: pointer;
	margin: 0;
	float: left;
	list-style: none;
}
.dropDownList ul li {
	font-family: 'SegoeUI', 'Segoe UI';
	font-weight: 400;
	font-style: normal;
	font-size: 12px;
	margin-bottom: 0px;
	vertical-align: middle;
	padding-left: 30px;
	padding-top: 0px;
	cursor: pointer;
	width: 100%;
	position: relative;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	padding-bottom: 0px;
	color: #333333;
	float: left;
}
.dropDownList ul li {
	padding: 3px 12px 6px 12px !important;
}
.dropdownShow {
	display: block !important;
}
.dropDownList ul li:hover {
	background: #f5f5f5;
}
.roleFilter .dropDownList {
	width: 180px;
}
.disableDropdown {
	/* opacity: 0.6; */
	pointer-events: none;
}
.dropdownHeader,
.dropDowncontainer:hover {
	font-size: 16px !important;
	background-color: #fff !important;
}
.dropDownList ul li:focus {
	outline-offset: -1px;
}

.customToolsEditor .dropDownList ul {
	max-height: 200px;
}

.newDropDownBox{
	display: flex;
	justify-content: space-between;
}
.newDropDownContainer{
	width: 113px !important;
}
.newWrapHeader{
	width: unset !important;
}
.activeComplexityRow{
	border: 1px solid #e0e0e0;
	background: #f4f4f4;
}