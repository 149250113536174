.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
  }
  .spinner .spinner-border {
    display: inline-block;
    vertical-align: text-bottom;
    border: 2px solid #f58220;
    border-right-color: transparent;
    border-radius: 50%;
    animation: spinner-border 0.75s linear infinite;
  }
  .CrowdSourcingLoader .modal-content{
    border: 0px;
  }