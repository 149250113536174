.knownIssue {
	padding: 20px;
	font-size: 13px;
}

.knownIssue li {
	line-height: 25px;
}

.knownIssuesLink a:hover {
	text-decoration: underline !important;
}
.knownIssuesLink > a {
	color: #0062ad;
}
