.deliveryManagementMenuIcon {
	float: left;
}

.deliveryMgntRightMenu li {
	cursor: pointer;
}
.deliveryMgntRightMenu {
	display: flex;
	flex-direction: column;
	padding: 0 !important;
	list-style: none;
	float: right;
	height: 100%;
	background: #f6f6f6;
	overflow: hidden;
	overflow-y: auto;
}
.deliveryMgntRightMenu li {
	display: block;
	font-size: 12px;
	position: relative;
}
.deliveryMgntRightMenu li hr {
	border-bottom: 1px solid #cccaca;
	width: 80%;
	border-top: none;
	clear: both;
	margin: 0 auto !important;
}
.deliveryMgntTitle {
	font-size: 13px;
	float: left;
	margin-bottom: 0;
	padding: 15px 5px 15px 10px;
	background: #f6f6f6;
	border-bottom: 1px solid #cccaca;
	display: flex;
}
.deliveryMgntTitle h4 {
	font-size: 13px;
	flex: 1;
}
.deliveryMgntTitle button {
	width: 30px;
	height: 30px;
	padding: 0;
	margin: 0;
	border: none;
	box-shadow: none;
	background: none;
}
.deliveryMgntRightMenu li span {
	display: flex;
}
.deliveryManagementMenu {
	padding: 0 !important;
	width: 150px;
	order: 3;
	position: fixed;
	right: 0;
	height: 92%;
	display: flex;
	flex-direction: column;
	background: #f6f6f6;
	z-index: 99;
}

.deliveryMenuActive {
	background: #665eff !important;
	color: #fff !important;
}
.deliveryMgntRightMenu a,
.deliveryMgntRightMenu a:hover {
	color: #22243e;
	text-decoration: none;
}
.deliveryMgntRightMenu a {
	padding: 20px 15px;
	float: left;
	width: 100%;
}
.deliveryMgntRightMenu a:focus {
	outline-offset: -1px;
}
.deliveryMgntRightMenu li > div {
	flex: 1;
	display: flex;
	background: #a5a0ff;
}
.deliveryMgntTitle.coOwnersLeftMenu {
	display: flex;
	flex-direction: column;
}
.deliveryMgntRightMenu a.deliveryMenuActive ~ .phaseEditDeletMore {
	background: #665eff !important;
}
.deliveryMgntRightMenu a.deliveryMenuActive ~ .phaseEditDeletMore i {
	color: #fff;
}
.deliveryManagementMenu .extractPopupMainContainer {
	top: 12px;
	left: -309px;
}
.deliveryManagementMenu
	.extractFileContainer
	label.checkBoxContainer.filterChkboxContainer {
	margin-right: 29px;
	display: flex;
}
.deliveryManagementMenu .extractFileContainer {
	margin: 15px 0;
}
.extractFileContainer label.checkBoxContainer.filterChkboxContainer {
	padding-left: 7px;
	margin-right: 31px;
	/* font-size: 13px;
	line-height: unset; */
	padding-bottom: 0;
}
.extractFileContainer
	label.checkBoxContainer.filterChkboxContainer
	input:focus {
	width: 14px !important;
	height: 14px !important;
	margin: 6px 0px -4px 0px;
}
.deliveryManagementMenu .extractSelOfComplexity .dropDownBox {
	padding: 7px 9px !important;
	margin-top: 6px;
}
.deliveryManagementMenu .extractMethodButtons .extractDetailsButton,
.deliveryManagementMenu .extractMethodButtons .extractSummeryButton {
	padding: 5px 20px;
}
.deliveryMgntTitle.coOwnersLeftMenu {
	display: flex;
	flex-direction: row;
}
.deliveryMgntTitle i.deliveryExtractIcon {
	margin-top: 29px;
	margin-right: 4px;
	cursor: pointer;
}
.deliveryManagementMenu .extractSelOfComplexity .dropDownList {
	top: 41px;
}
