.customizedScroll::-webkit-scrollbar {
  width: 0 !important;
  display: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.customizedScroll {
  -ms-overflow-style: none;
}

.customizedScroll {
  overflow: -moz-scrollbars-none;
}

.disabledContent {
  cursor: default !important;
  opacity: 0.3 !important;
}

.pointer {
  cursor: pointer;
}

.errorMsg {
  color: red !important;
}

.bold {
  font-weight: bold !important;
}

.note {
  color: #666666;
  font-style: italic;
}

.noClickEvents {
  pointer-events: none;
}

button:disabled {
  opacity: 0.5;
}

.main-page-container {
  /* min-width: 1300px; */
  width: 100%;
  overflow: visible;
}


.mandatoryProcessStepHeading {
  font-size: 10px;
  float: left;
  font-style: italic;
}

.ms-MessageBar-content>div>i {
  padding-top: 2px !important;
}

:root {
  --toastify-color-error: #d04436;
}

.anchorTag {
  color: #0068d9 !important;
}

.ms-MessageBar-innerText>span>p {
  margin-top: 2px;
}

.ms-MessageBar-innerText>span>p>a {
  text-decoration: underline;
}

.skipToMain {
  left: -999px;
  position: absolute;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
  z-index: 100;
}

.skipToMain button {
  color: #253b62 !important;
}

.skipToMain:focus-within {
  color: #253b62;
  background-color: #d3d3d3;
  left: auto;
  top: 0;
  width: 100%;
  height: 64px;
  overflow: auto;
  padding: 15px;
  border: 2px dotted #000;
  outline: none !important;
  text-align: center;
  font-size: 1rem;
  z-index: 9999;
}

.leftMenuExpand {
  border-radius: 1%;
  display: block;
  background: #de6804;
  padding: 2px;
  width: 30px;
  border: 1px solid #de6804;
  position: absolute;
  left: 1px;
  z-index: 99;
  margin-top: 15px;
}

/* Media query for (300%-400%) zoom */
@media (min-resolution: 2dppx),
(min-resolution: 192dpi),
(min-resolution: 3dppx),
(min-resolution: 288dpi),
(min-resolution: 4dppx),
(min-resolution: 384dpi) {

  .navbar-brand.collapse {
    width: 196px !important;
  }

  .sdmNavBar.navbar {
    height: 68px;
  }

  .newHomePageContainer {
    height: auto !important;
  }

  .navbar-toggler {
    top: 2px;
    right: 2px;
    position: absolute;
  }

  .sdmMainMenuMobile.navbar-nav {
    top: 20px;
    right: 2px;
  }

  .announcementFlyoutContainer {
    display: none;
  }

  .sdmMainMenuMobile.navbar-nav {
    top: 17px;
    right: 2px;
    position: absolute;
  }

  .topicsLeftMenuContainer ul a.NavLink {
    padding: 3px 2px;
    margin: 0;
  }

  .topicsLeftMenuContainer ul {
    padding: 0;
  }

  .topicsLeftMenuContainer {
    height: calc(100vh - 5.5rem);
    top: 86px;
    z-index: 9999
  }

  .ratingWrapper {
    width: 58vw;
  }

  .topicDescriptionDiv {
    height: auto !important;
  }

  .rightSecContainer,
  .newHomePageContainer {
    min-height: auto !important;
    height: auto !important;
  }

  .phasesContentQuick7 {
    max-height: none !important;
    height: auto !important;
  }

  .left-menu {
    height: auto !important;
  }

  .descrpOfProjectDeliveryPhases,
  .phaseLeftNavMenu {
    overflow-x: hidden;
    overflow-y: scroll;
    height: calc(100vh - 172px) !important;
  }

  .descOfActivity,
  .activityLandinPage {
    height: auto !important;
  }

  .topicsLeftMenuIcon {
    right: -8px
  }

  .phaseBreadcrumb {
    padding: 2px;
  }

  .phasesContentQuick5 {
    max-height: none !important;
    height: auto !important;
  }

  .dropdownmenu {
    right: -3px;
    left: auto;
  }

  .navbar-collapse.collapse.show .rightSecNavBar.navbar-nav {
    position: absolute;
    left: 0;
    top: 34px;
    width: 100vw;
  }

  .navSearchBox,
  #searchArtifact {
    width: 50vw
  }

  .navbar-collapse.collapse.show #searchArtifact {
    margin-left: 7px;
  }

  .rightSecNavBar ul li {
    margin: 0;
    padding-left: 0 !important;
  }


}

.star-container:focus {
  border: 1px solid black;
  padding: 2px;
  border-radius: 4px;
}

.searchSpinner {
  position: absolute;
  right: 50% !important;
}


.hidingConsultingSupport {
  display: none !important;
}

.svgStyles {
  max-height: 500px;
}

.svgStyles>path {
  cursor: pointer;
}

.polygonShape>svg>g>a:hover,
.polygonShape>svg>g>a:focus,
.polygonShape>svg>g>a:active,
.polygonShape>svg>a {
  text-decoration: none;
  color: inherit;
  outline: none !important;
}

.polygonTopicDetailsLeftSide {
  /* width: 100%; */
}

.topicVisioImage>svg {
  width: 100%;
}

.alternativeOfh6 {
  font-size: 1rem;
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
  margin-top: 0;
}

.ms-Dropdown-items>div {
  padding-inline: 5px;
}

.togglerActive:focus,
.togglerBlur:focus {
  border: 1px solid black;
  outline: auto;
}

#feedbackmodel {
  outline: none !important;
}

#ChromeClose:focus {
  outline: 2px dotted rgb(74, 72, 72) !important;
}

#closeButton:focus {
  outline: 2px dotted #fff !important;
}

.activityRaciRoleGroupContainer::-webkit-scrollbar,
.descOfActivity::-webkit-scrollbar {
  width: 10px;
}

.activityRaciRoleGroupContainer::-webkit-scrollbar-track,
.descOfActivity::-webkit-scrollbar-track {
  border-radius: 10px;
}

.activityRaciRoleGroupContainer::-webkit-scrollbar-thumb,
.descOfActivity::-webkit-scrollbar-thumb {
  background-color: #C0C0C0;
  border-radius: 10px;
  border: 2px solid transparent;
  background-clip: padding-box;
}

.checkmark:focus {
  outline: 1px solid #000 !important;
  outline-offset: 3px;
}

.existingHashTagsOfTopic>span,
._existingHashTagsOfActivity>span {
  align-items: center;
  background: #f1f4f9 0 0 no-repeat padding-box;
  border-radius: 11px;
  color: #0178d4;
  display: inline-flex;
  font: normal normal normal 13px / 20px Segoe UI;
  height: 21px;
  justify-content: center;
  letter-spacing: 0;
  opacity: 1;
  width: max-content;
  padding: 0px 13px 3px 13px;
  margin-top: 2px;
  margin-bottom: 2px;
  margin-inline: 2px;
}



._PolygonBorder .activityDescription .ck.ck-editor,
._PolygonBorder .topicDetailsCkEditor .ck.ck-editor {
  width: 100% !important;
}

._activityLandingPage #previewTempList ul {
  padding-left: 16.5px !important;
}

._activityLandingPage #previewTempList ul li::marker {
  color: #858585;
  /* Change to your desired bullet color */
}


._activityDescriptionCKEditor .ck-editor__editable p {
  /* display: list-item !important; */
  /* list-style-type: disc !important; */
  margin-left: 18.5px;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

._activityLandingPage .ck-editor__editable ul {
  /* margin-left: 18.5px; */
}

._activityDescriptionCKEditor .ck-editor__editable p::marker {
  color: #858585;
}

._activityDescriptionCKEditor .centered.topicDetailsCkEditor {
  padding-left: 0px !important;
}

._activityDescriptionCKEditor .ck-editor__editable ul {
  margin-left: 3px;
}

._activityDescriptionCKEditorForDel p {
  margin-left: 16.5px !important;
}

._activityDescriptionCKEditorForDel ul {
  margin-left: 0px !important;
}

._activityDescriptionCKEditorForTempGui p,
._activityDescriptionCKEditorForTemp p {
  margin-left: 1.5px !important;
}

._activityDescriptionCKEditorForTemp ul {
  margin-left: 1px !important;
}

._activityDescriptionCKEditorForTempGui ul {
  margin-left: 1.5px !important;
}

.ck-editor__editable p::marker,
.ck-editor__editable ul li::marker {
  color: #858585 !important;
}

.NewPreviewAndSubmitPopUp ._activityDescriptionCKEditor .centered.topicDetailsCkEditor ul {
  margin-top: 1px;
}

.NewPreviewAndSubmitPopUp .ck.ck-editor {
  width: 100%;
}

._alignSuggestedActivityDescription {
  margin-left: -10px !important;
}

._acceptedSuggestions {
  box-sizing: border-box;
  background: var(--ck-color-suggestion-marker-insertion-background);
  border-bottom: 3px solid var(--ck-color-suggestion-marker-insertion-border);
  border-top: 3px solid var(--ck-color-suggestion-marker-insertion-border);
}

._rejectedSuggestions {
  background: var(--ck-color-suggestion-marker-deletion-background);
  border-bottom: 3px solid var(--ck-color-suggestion-marker-deletion-border);
  border-top: 3px solid var(--ck-color-suggestion-marker-deletion-border);
  text-decoration: line-through;
  text-decoration-color: var(--ck-color-suggestion-marker-deletion-stroke);
  text-decoration-thickness: 3px;
}

.PreviewAndSubmitPopUp .centered .topicDetailsCkEditor ul table {
  width: 100vw;
}

._unOrderedList ol {
  padding-left: 11px;
  margin-bottom: 0;
}

._unOrderedList ol li {
  padding-left: 6px;
}

._activityDescriptionCKEditorForTemp li>p,
._activityDescriptionCKEditorForTempGui li>p {
  margin-left: 0px !important;
}

._polygonShapeCrowdsourcing .triangleContent {
  right: 282px !important;
  margin-top: 25px !important
}

._polygonShapeCrowdsourcing .hexagonContent {
  right: 236px !important;
  margin-top: 25px !important
}

._polygonShapeCrowdsourcing .pentagonTopicList {
  right: 270px !important;
  margin-top: -30px !important;
}

._polygonShapeCrowdsourcing .squareContent {
  right: 238px !important;
  margin-top: 6px !important;
}

._activityDescriptionCKEditorForDel li>p {
  margin-left: 0px !important;
}

._activityDescriptionCKEditor p {
  margin-left: 0;
}

._activityDescriptionCKEditor ol {
  padding-left: 13px;
}

._hidingClickVision {
  pointer-events: none;
}

._activityDescriptionCKEditorForDel>.App>main>div,
._activityDescriptionUpdate>.App>main>div {
  padding-left: 0px !important;
}


/* */

._adjustSidebar {
  background-color: white;
  border: none;
  padding: 0px 0px 0px 1rem !important;
}


._headingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: calc(100% - 280px);
  max-height: 33px;
  box-sizing: border-box;
}

._subHeading {
  flex: 0 0 150px;
  max-width: 150px;
  width: 150px;
  font: normal normal 600 14px/19px Segoe UI;
  letter-spacing: -0.14px;
  color: #222629;
  opacity: 1;
}

._subHeadingRightSide {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

._delTemplateAccessabilityIcon {
  width: 40px;
  height: 40px;
  background: url(./assets/DoaccessabilityInfoIcon.svg) no-repeat center center;
  background-size: contain;
  display: inline-block;
}

._rigthColumnActivty #Description,
._rigthColumnActivty .centered.topicDetailsCkEditor {
  padding-left: 0px !important;
}

/* ._rigthColumnActivty #Description .ck-editor__editable {
  height: 177px !important;

} */

._rigthColumnActivty .criteria-tab-container {
  margin-left: 0px !important;
  margin-top: 0px;
  min-width: calc(100% - 290px);
  box-sizing: border-box;
}

/* .rightSecContainer .topicDetailsCkEditor .row {
  width: calc(100% - 180px);
} */


._rigthColumnActivty #task-section .contributorAddTemplatesButton,
._rigthColumnActivty #_reviewerTask .contributorAddTemplatesButton,
._rigthColumnActivty #_approverTask .contributorAddTemplatesButton {
  top: 8px
}

._leftColumnActivty label {
  font: normal normal normal 14px/19px Segoe UI;
  letter-spacing: -0.14px;
  color: #333333;
  display: inline-flex;
}

._rigthColumnActivty .toolsEditorMainContainer {
  margin-top: 32px;
}

._rigthColumnActivty #toolDropdown .ms-Dropdown-container .ms-Dropdown {
  top: -13px;
  left: 284px;
}

._rigthColumnActivty #toolDropdown .ms-Dropdown-container .ms-Dropdown span:first-of-type {
  padding: 5px 10px;
  box-sizing: border-box;
  border-radius: 1px;
  font: normal normal normal 13px / 17px Segoe UI;
  letter-spacing: -0.13px;
  color: #333333;
  opacity: 1;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #CECECE !important;
  height: 28px !important;
}

._rigthColumnActivty .toolsSidebar {
  /* width: 280px; */
  padding-left: 12px;
  width: 280px;
}

._rigthColumnActivty .toolsSidebar_latest {
  /* width: 280px; */
  padding-left: 12px;
  width: 271px;
  min-height: auto !important;
}


._crowdsourcingButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  height: 28px;
  width: auto;
  padding: 0px 16px;
  border: 1px solid #CDCED0;
  border-radius: 3px;
  cursor: pointer;
  gap: 8px;
  position: relative;
  font: normal normal 600 14px/18px Segoe UI;
  letter-spacing: -0.14px;
  color: #212121;
  opacity: 1;
}

.phaseAddIcon {
  width: 16px;
  height: 16px;
  background: url(./assets/addPhaseIcon.svg) no-repeat center center;
  background-size: contain;
  display: inline-block;
}


._rigthColumnActivty #toolDropdown {
  width: calc(100% - 196px);
  margin-top: -20px;
  display: flex;
  justify-content: end;
  height: 18px;
  position: static;
}

._rigthColumnActivty #DeliverablesEditor {
  width: 101%;
}

._rigthColumnActivty ._previewIconBlack {
  display: inline-block;
  margin-right: 5px;
}

._rigthColumnActivty .newActivityTemplateListItemIcons {
  padding-left: 5px;
  width: 6%;
  display: inline-flex;
  top: 3px;
  position: relative;
}

._rigthColumnActivty .newActivityTemplateListItemIcons .templateEditDownloadIcon {
  margin-top: 16px;
}

._rigthColumnActivty .newActivityTemplateListItemIcons button {
  margin-top: 18px !important;
}
._rigthColumnActivty .newActivityTemplateListItemIcons_Latest {
  padding-left: 5px;
  width: 6%;
  display: inline-flex;
  top: 0px !important;
  position: relative;
}

._rigthColumnActivty .newActivityTemplateListItemIcons_Latest button {
  margin-top: 0px !important;
}

._rigthColumnActivty .task-tab-container {
  padding: 15px;
}

._isDisabledButton {
  cursor: not-allowed !important;
  opacity: 0.9;
}

._crowdsourcingRow {
  margin-left: 0.5rem !important;
  margin-bottom: 0px;
}

._rigthColumnTools .customToolsEditor {
  width: calc(100% + 14px) !important;
}

._rigthColumnTools .toolsSidebar {
  width: 290px;
}

._rigthColumnTools .toolsEditorMainContainer {
  flex: 0 1 auto !important;
  width: calc(100% - 294px);
}

._rigthColumnTools .sideBarCard {
  min-width: calc(100% - 10px);
  padding-right: 0px;
  margin-right: 0 !important;
}

._rigthColumnTools .sideBarCard i {
  font-weight: 600;
  font-size: 13px;
  color: #b7b0b0;
  margin-right: 23px;
}

._ActivtyRowMargin {
  margin-bottom: 13px;
}

._crowdsorcingPage {
  overflow-y: unset;
  overflow-x: unset;
}

._crowdsorcingPage ._rowWhite {
  border: 1px solid #CECECE;
}

._crowdsorcingPage ._allComlumns {
  background: #F5F5F5;
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100vh - 180px);
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  width: calc(100vw - 20px);
}

._crowdsorcingPage ._allComlumns {
  scrollbar-width: thin;
  scrollbar-color: #aaa #fff;
}

._DeliverablesCkEditor .newActivityTemplateListItemIcons {
  margin-top: 0px !important;
}

.ms-Callout-main {
  max-height: none !important;
}

._DeliverablesCkEditor {
  display: flex;
  align-items: center;
}

._crowdsorcingPage .phaseBreadcrumb {
  padding-left: 0px !important;
}

._crowdsorcingPage ._complexityContainer {
  padding-left: 17px !important
}

._crowdsorcingPage ._allComlumns {
  padding-left: 0;
  width: calc(100vw - 20px);
}

._crowdsorcingPage .ck.ck-content {
  color: red;
  font: normal normal normal 13px/17px Segoe UI;
  letter-spacing: -0.13px;
  color: #333333;
  opacity: 1;
  padding: 0px 13.5px !important;
}

._crowdsorcingPage .templateContentContainer {

  padding: 13px 3px
}

#SupportAndActivitiesEditor .ck-editor__editable,
#TipsAndTechniqueEditor .ck-editor__editable {
  padding: 3px 13px !important;
}

._crowdsorcingPage .ToolsContentContainer ul li {
  padding-bottom: 5px;
}

._crowdsorcingPage .sidebar,
._crowdsorcingPage .toolsSidebar {
  scrollbar-color: transparent transparent !important;
}

._crowdsorcingPage .exitEditCriteriaInput,
._crowdsorcingPage .entryEditCriteriaInput {
  width: 100%;
}

._crowdsorcingPage .relatedContentAdd,
._crowdsorcingPage .relatededitContentDelete {
  position: static;
  margin: 0;
  padding: 0;
}

._crowdsorcingPage .entry-criteria-tab-content,
._crowdsorcingPage .exit-criteria-tab-content {
  /* padding: 0px !important; */
  margin: 0px !important;
  width: 100%;
}

#tab-exit,
#tab-output,
#tab-input,
#tab-entry {
  padding: 0px !important;
  margin: 0px !important;
}

#tab-exit .dropDownList,
#tab-output .dropDownList,
#tab-entry .dropDownList,
#tab-input .dropDownList {
  width: 224px !important;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #CECECE;
  font: normal normal normal 13px / 17px Segoe UI;
  letter-spacing: -0.13px;
  color: #333333;
  opacity: 1;
}

._crowdsorcingPage .entry-criteria-tab-content,
._crowdsorcingPage .exit-criteria-tab-content {
  min-height: auto !important;
}

._crowdsorcingPage div.exitEditCriteriadropdown div.dropDownBox,
._crowdsorcingPage div.entryEditCriteriadropdown div.dropDownBox {
  padding-top: 5px !important;
  width: 224px !important;
  height: 30px !important;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #CECECE;
  border-radius: 2px;
  font: normal normal normal 13px / 17px Segoe UI;
  letter-spacing: -0.13px;
  color: #333333;
  opacity: 1;
}

.criteria-tab-container .entry-criteria-tab-content,
.criteria-tab-container .exit-criteria-tab-content {
  padding-left: 0px !important;
}

._crowdsorcingPage .inputBox {
  margin-inline: 10px 6px
}

#tab-exit .inputBox,
#tab-entry .inputBox {
  width: calc(100% - 316px);
}
.templateContentContainer_latest ul li{
  margin-bottom: -10px !important;
  padding-bottom: 0px !important;
}
#tab-output .inputBox,
#tab-input .inputBox {
  width: calc(100% - 83px);
  margin-left: 0px !important;
}
.downloadTemplate_Activty{
  align-items: end !important;
}
.DescriptionIDAct .ck-sidebar-item {
  /* Your styles here */
  top: 0px !important;
}
._rightColumnActivity_Latest .ck-editor__editable_inline {
  min-height: auto !important;
  height: auto !important;
}

._rightColumnActivity_Latest .customToolsEditor{
  min-height: 168px !important;
}
._rightColumnActivity_Latest .customToolsEditor .toolsEditorMainContainer{
  height: auto !important;
}
/* ._rightColumnActivity_Latest .row-editor>div:first-child, main .ck-editor[ role='application'] .ck.ck-content .ck-editor__editable_inline{
  min-height: auto !important;
  height: auto !important;
} */
 .newActivityTemplateListItem_Latest{
  display: flex !important;
  align-items: center !important;
  margin-bottom: 10px !important;
 }
 .Entry_Exit{
  margin-left: 13px !important;
 }
._crowdsorcingPage .inputBox>input {
  border-radius: 2px;
  padding-inline: 10px;
  height: 30px;
  opacity: 1;
  border: 1px solid #CECECE;
 }
 .Entry_Exit_Output{
  padding-left: 13px !important;
 }
._crowdsorcingPage ul.entry-criteria-tabs li,
._crowdsorcingPage ul.exit-criteria-tabs li {
  font: normal normal 600 14px / 19px Segoe UI;
  letter-spacing: -0.14px;
  color: #222629;
  opacity: 1;
  padding-top: 0px !important;
  margin-bottom: 6px !important;
}

.entry-criteria-tab-content,
.exit-criteria-tab-content {
  border: 0px;
}



.entryExitCriTab .entryEditCriteriadropdown,
.entryExitCriTab .entryEditCriteriaInput,
.entryExitCriTab .addPolygonTopicFieldSave,
.entryExitCriTab .exitEditCriteriaInput {
  margin-top: 0px !important;
}

.entryExitCriTab .addPolygonTopicRelatedInputContainer {
  padding-block: 4px;
}

.entryExitCriTab .addPolygonTopicFieldSave {
  height: 30px;
  overflow: hidden;
  scrollbar-width: none !important;
}

.entryExitCriTab .toolsSidebar {
  margin-top: 33px;
  padding: 0px;
  margin-left: 13px;
}

#taskInputBox .inputBox {
  margin-inline: 15px -1px;
}

#taskInputBox .inputBox {
  width: calc(100% - 109px);
}

#taskInputBox .ck-editor__editable_inline {
  min-height: 63px;
  cursor: auto;
}

#taskInputBox .addPolygonTopicRelatedInputContainer {
  padding-bottom: 13px;
}

#taskInputBox>div {
  display: inline-block;
}

._taskRow {
  width: calc(100% - 286px);
}
 .contributionBreadcrumb{
  width: 185% !important;
  background: white !important;
 }
._taskRow>div>div:focus {
  outline: 0px solid !important;
  cursor: auto;
 }
 .complexity-info-container {
	height: auto;
	width: auto;
	position: absolute;
	left: 0px;
	z-index: 9999;
	padding: 10px;
	color: black;
}

.complexity-message-text {
	background-color: white;
}
._ComplexityLatestContribution{
  display: inline-block !important;
   position: relative !important;
   cursor: default !important;
}
._crowdsorcingPage .exit-criteria-tab-content,
._crowdsorcingPage .entry-criteria-tab-content {
  overflow: hidden;
}