.linkContainer {
  display: flex;
  position: relative;
  width: 100%;
}
.leftLinkListMainContainer {
  background: #f6f6fb;
  border-radius: 3px;
  display: block;
  margin-top: 10px;
}
.leftLinkTitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  float: left;
  display: inline-grid;
  width: 100%;
}
.leftLinkTypeColor {
  border-left: 4px solid #f58220;
  border-top-left-radius: 3px;
}
.leftLinkTitle i {
  color: #f58220;
  font-size: 10px;
  vertical-align: middle;
  cursor: pointer;
  float: left;
  padding-top: 2px;
  padding: 12px 10px 9px 10px;
}
.leftLinkChildContainer {
  display: flex;
  flex-direction: column;
  border-left: 4px solid #fccd95;
  background: #f9f9fc;
}
.leftLinkChildContainer .leftLinkChildTitle {
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-flex;
  clear: both;
  width: 56%;
  position: relative;
  flex: 1;
}
.titleOfLink {
  float: left;
  white-space: initial;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 4px;
  display: inline-grid;
  padding: 9px 5px 9px 5px;
  cursor: pointer;
  text-align: left;
  font: normal normal 600 14px Segoe UI;
  letter-spacing: -0.14px;
  color: #2B314C;
  opacity: 1;
}
.titleOfLink a {
  text-decoration: none;
  color: #333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  float: left;
}
.titleOfLink a:hover {
  text-decoration: underline;
  color: #333;
}
.leftOrangeDelivery .leftLinkChildContainer {
  border-left: 4px solid #fccd95;
}

.linkContainer .leftLinkGroup {
  cursor: pointer;
}
.leftLinkGroupParent span.titleOfLink {
  font-weight: 500;
  display: block;
  margin-left: 4px !important;
  flex: 1;
  padding: 9px 5px 9px 0px;
}
.leftLinkGroupParent
  .leftLinkChildContainer
  .linkContainer.leftLinkGroup
  span.titleOfLink {
  font-weight: 500;
  padding: 9px 5px 9px 0px;
}
.leftLinkGroupParent .leftLinkChildContainer span.titleOfLink {
  font-weight: 400;
  display: inline-grid;
  padding: 8.5px 5px 8.5px 30px;
  text-align: left;
  font: normal normal normal 14px Segoe UI;
  letter-spacing: -0.14px;
  color: #2B314C;
  opacity: 1;
}
.leftLinkGroupParent .leftLinkTitle {
  width: 100%;
  display: flex;
}
.leftLinkListMainContainer span.titleOfLink {
  margin-left: 14px;
}
.titleOfLink a:focus {
  outline-offset: -1px;
}
.leftLinkChildTitle.noActivities .titleOfLink {
  background: #fae7d3;
  margin: 1px 0;
  margin-left: 0 !important;
}
.leftLinkChildTitle.noActivities {
  width: 100%;
}
.leftLinkChildContainer > div {
  display: flex;
}
span.groupColor {
  width: 14px;
  background: #f58220;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.activityGroupAdditionDiv
  .leftLinkChildContainer
  .leftLinkChildTitle
  span.titleOfLink {
  padding: 9px 5px 9px 15px;
}
.leftLinkChildContainer
  .linkContainer.leftLinkGroup
  span.leftLinkTypeColor {
  border-left: 4px solid #f58220;
}
.phaseCreationRightPopup .modal-header .modal-title.h4 h3 {
  font-size: 23px;
}
.leftAddGroupButtonAction {
	display: inline-flex;
	border: 1px solid #4d87f6;
	background: #f1f5f9;
	flex-direction: column;
	margin-bottom: 24px;
	border-radius: 4px;
}
.leftAddGroupButtonAction .selectAddGroupButton {
	padding: 8px 54px 8px 20px;
}
.selectAddGroupButton {
	color: #0262ab;
	cursor: pointer;
	font-size: 14px;
}
.addExternalLinkForm{
  background: #F3F3F3 0% 0% no-repeat padding-box;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  padding: 10px;
}

.addLinkFields {
	display: flex;
	flex-direction: column;
	margin-top: 10px;
	font-size: 13px;
}

.addDuplicateLinkFields {
	display: flex;
	flex-direction: column;
	margin-top: 10px;
	font-size: 13px;
}
.addDuplicateLinkFields #group-link-text {
  border: 1px solid red !important;
}
.addDuplicateLinkFields span{
  text-align: right;
  color: red;
}
.addDuplicateLinkFields input {
	width: 100%;
	max-height: 40px;
	border: 1px solid #0078D4;
	font-size: 12px;
  border-radius: 3px;
}

.addLinkFields input {
	width: 100%;
	max-height: 40px;
	border: 1px solid #0078D4;
	font-size: 12px;
  border-radius: 3px;
}

 /* Styles for the dropdown label */
 .dropdown-label {
  display: block;
  margin-bottom: 5px;
  font-weight: 600;
}

#left-group-select {
  width: 94% !important;
  float: left !important;
}



  /* Styles for the custom radio button and label */
  .custom-radio {
    margin-right: 10px !important;
    min-height: 25px !important;
    width: 2% !important;
    accent-color: #0078D4 !important; 
  }
  
  .custom-option-label {
    margin: 2px !important;
    font: normal normal normal 12px/16px Segoe UI;
    letter-spacing: -0.16px;
    color: #333333;
  }

  #specifyColor { 
    accent-color: red; 
} 

.addGroupLeftPanelAction {
	background: #f1f5f9 !important;
	flex-direction: column !important;
	margin-bottom: 30px !important;
	border-radius: 4px !important;
}
.addGroupLeftPanelAction .addGroupButton {
	background-color: #ffffff;
  border: 1px solid #0078D4;
  float: left;
	cursor: pointer;
	font-size: 20px;
  padding: 1px 5px 5px 5px;
  border-radius: 4px;
  width: 5%;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.addGroupForm{
  background: #F3F3F3 0% 0% no-repeat padding-box;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  padding: 10px;
  width: 99%;
}

.addGroupFields {
	display: flow-root;
	margin-top: 10px;
	font-size: 13px;
}
.addGroupFields input {
	max-height: 40px;
	border: 1px solid #0078D4;
	font-size: 12px;
  border-radius: 3px;
}
.addDuplicateGroup{
  display: flow-root;
	margin-top: 10px;
	font-size: 13px;
}
.addDuplicateGroup input{
  max-height: 40px;
	border: 1px solid red;
	font-size: 12px;
  border-radius: 3px;
}
.addGroupForm span {
  margin-left: 10%;
  color: red;
}
.entryGroupName{
  display: flex;
  flex-direction: column;
  flex: 1 1;
  width: 50%;
  float: left;
  margin-left: 10%;
}
.leftGroupButtons{
  margin-left: 15px;
  float: left;
}
.leftGrouupCancelButton {
  color: #000;
  padding: 5px 20px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #D6D6D6;
  border-radius: 8px;
  font-size: 12px !important;
}
.leftGroupSaveButton {
border: none;
font-size: 12px !important;
color: #fff;
margin-right: 10px;
padding: 5px 20px;
background: #0079D4 0% 0% no-repeat padding-box;
border-radius: 8px;
}

.leftEditGroupName{
  display: flex;
  margin-left: 1%;
  width: 70%;
}
.leftEditGroupName input {
  border-radius: 3px;
  width: 100%;
  border: 1px dashed #0078D4;
  text-align: left;
  font: normal normal normal 16px/21px Segoe UI;
  letter-spacing: -0.16px;
  color: #333333;
}
.leftEditGroupName i {
  float: left;
  font-size: 20px;
  font-weight: bold;
  border-radius: 3px;
  width: 8%;
  border: 1px dashed #0078D4;
  color: black !important;
  cursor: pointer;
  padding: 0px 0px 0px 5px !important;
}



/* For the Delete & Edit icon in Left Panel group */
.leftGroupIcons{
  margin-right: 3%;
}
.leftGroupIcons i {
  padding: 6px!important;
  margin-top: 4px;
  font-size: 14px !important;
  color: #9A9A9A !important;
}
/* Delete Icon */
.leftGroupDeleteIcon {
	float: right;
	width: 14px;
  height: 14px;
	background: url(../../../assets/video-delete.svg) no-repeat;
	background-size: contain;
	cursor: pointer;
  margin: 10px 8px;
	color: #acacac;
}
/* For the Delete & Edit icon in Left Panel link */
.leftLinkIcons{
  margin-right: 3%;
}
.leftLinkIcons i {
  padding: 6px!important;
  margin-top: 4px;
  font-size: 14px !important;
  color: #9A9A9A !important;
}
.leftLinkDeleteIcon {
	float: right;
	width: 14px;
  height: 14px;
	background: url(../../../assets/video-delete.svg) no-repeat;
	background-size: contain;
	cursor: pointer;
  margin: 10px 8px;
	color: #acacac;
}

/* Delete popup having warning and buttons for actions */
.deleteLeftGroupPopUp{
  background: black;
  width: 346px;
  border-radius: 8px;
  z-index: 9;
  font-size: 13px;
  display: flex;
  color: white;
  margin-right: 2%;

}
.deleteLeftGroupPopUp span {
  margin-top: 8px;
  margin-left: 8px;
  margin-right: 10px;
}
.deleteLeftGroupPopUp button:hover {
  font-size: 12px !important;
  background: #ffffff;
  color: black;
  font-weight: 600;
  border-radius: 6px;
  padding: 4px 14px;
}
.deleteLeftGroupPopUp button {
  font-size: 12px !important;
  background: #9A9A9A;
  font-weight: 600;
  color: #000;
  border-radius: 6px;
  padding: 4px 14px;
  margin-top: 3px;
}



.deleteGroupWarning{
  border: 1px solid #FF1313;
  border-radius: 6px;
}
.deleteGroupWarning i:first-child{
  color: #FF1313;
  float: left;
  padding-left: 6px;
  padding-right: 6px;
}
.deleteGroupWarning span {
  color: #FF1313;
}

.deleteGroupWarning i {
  color: #FF1313;
  float: right;
  padding-right: 6px;
  cursor: pointer;
}



.leftLinkChildContainer .leftLinkChildTitle {
  width: 100%;
  display: flex;
}
.leftInputLinkEdit{
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  width: 40%;
  float: left;
  margin-right: 13px;
}
.leftInputLinkUrlEdit{
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  width: 58%;
  float: left;
}
.leftEditQuickLinkInput{
  border: 1px dashed #0078D4 !important;
  border-radius: 3px !important;
  box-shadow: 0px 0px 2px #00000040 !important;
  text-align: left;
  font: normal normal normal 15px/20px Segoe UI !important;
  letter-spacing: -0.15px;
  color: #343434;
  opacity: 1;
}

.leftBtnGroup{
  margin-left: 70%;
}
.leftCancelButton {
    margin-top: 10px;
    color: #000;
    padding: 5px 20px;
    margin-right: 10px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #D6D6D6;
    border-radius: 8px;
    font-size: 12px !important;
}
.leftSaveButton {
  margin-top: 10px;
  border: none;
  font-size: 12px !important;
  color: #fff;
  padding: 5px 20px;
  background: #0079D4 0% 0% no-repeat padding-box;
  border-radius: 8px;
}
.leftEditQuickLinkForm{
  padding: 2%;
}

.left-btn-cancel{
  flex:1;
  padding: 10px; 
  margin: 0; /* Remove margin if you want the buttons to be aligned without space */
  border: none;
  color: #A2A2A2;
  font-weight: bold; /* Make text bold */
  cursor: pointer;
  border-radius: 0px; /* Rounded corners */
  font-size: 16px; /* Adjust font size as needed */
}

.left-btn-next  {
  flex:1;
  /* background: #7DB7E4 0% 0% no-repeat padding-box !important; */
  background: #0079D4 0% 0% no-repeat padding-box;
  padding: 10px !important; 
  margin: 0 !important; /* Remove margin if you want the buttons to be aligned without space */
  border: none !important;
  color: #FFFFFF !important;
  font-weight: bold !important; /* Make text bold */
  cursor: pointer !important;
  border-radius: 0px !important; /* Rounded corners */
  font-size: 16px !important; /* Adjust font size as needed */
  box-shadow: 0px 0px 6px #00000029;
}
.previewLeftPanel{
  margin-left: 30%;
  width: 35%;
}
.preivewPhase{
  background: #253B62 0% 0% no-repeat padding-box;
  padding: 10%;
  border-radius: 4px;
}
.preivewPhase span{
  color: white;
  padding: 20%;
}
.previewGroups{
  background: #253B62 0% 0% no-repeat padding-box;
  padding: 5% 5% 0% 5%;
  text-align: left;
  display: grid;
}
.previewGroupsLeftPanel{
  margin-top: 2%;
  border-radius: 4px;
  max-height: 400px;
  overflow-y: scroll; /* Hide vertical scrollbar */
  overflow-x: hidden; /* Hide horizontal scrollbar */
}
.previewGroups span {
  color: #7CA1C6;
  margin-left: 5%;
  font-size: 14px;
  white-space: initial;
  word-wrap: break-word;
}
.previewLinks{
  margin-top: 3%;
}
.previewLinks a {
  color: white !important;
  margin-left: 5%;
  display: flex;
  margin-top: 2%;
  font-size: 14px;
  white-space: initial;
  word-wrap: break-word;
}
.manageLeftPanelLinkBtn{
  display: flex !important;
  justify-content: flex-end !important;
  gap: 10px !important;
  border-radius: 8px !important;
}
.manageLeftPanelLinkBtn button:first{
  border: 1px solid #D6D6D6 !important;
  border-radius: 8px !important;
}

.manageLeftPanelLinkBtn button{
  background: #0079D4 0% 0% no-repeat padding-box;
  border-radius: 8px !important;
}

.methodPhaseLeftMenu li span {
  font-weight: bold !important;
}
.modal-header .close span {
  font-weight: bold !important;
  font-size: xx-large !important;
  color: black !important;
}
.leftPanelQuicklinkIitem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
}

.addDuplicateLinkFields #group-link-url {
  border: 1px solid red !important;
}
.duplicateGroup{
  border: 1px solid red;
  border-radius: 4px;
  margin-top: 10px;
}
.duplicateGroup span{
  color: red;
}
.leftInputLinkEdit span{
  color: red;
}
.leftInputLinkUrlEdit span{
  color: red;
}
.previewGroupsLeftPanel::-webkit-scrollbar-thumb { 
  background: #4FE4FD; 
}
.previewGroupsLeftPanel::-webkit-scrollbar-track { 
  background: #253B62; 
}
.leftSelectGroup span {
  color: red;
}