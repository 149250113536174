.templateCrowdSourcingMainContainer {
	display: flex;
	flex-direction: column;
	/* margin-top: 62px; */
}
.templateCrowdSourcingContainer {
	display: flex;
	margin-top: 39px;
}
.templateFixedHeaderPart {
	padding: 30px 30px 0 30px;
	background: #f4f4f4;
	position: fixed;
	z-index: 999;
	width: 100%;
}
.listOfTemplateCrSourcing {
	padding: 0 30px;
	margin-top: 118px;
	background: #f4f4f4;
	min-height: 65vh;
}
.templateCrSourcingListWrapper {
	display: flex;
	width: 100%;
	flex-direction: column;
}
.newTableParentRow .newTableRow > .newWrapper {
	border-bottom: none;
	border-top: none;
}
.newTableParentRow .newColumn {
	cursor: default;
}
.newTableParentRow.header .newTableRow > .newWrapper {
	border-bottom: none;
	background: #fff;
}
.newTableParentRow.header .newColumn.newActTd {
	font-weight: 600;
	padding: 12px 20px !important;
}
.newColumn.approveAndReject.newActTd {
	width: 200px;
}
.newColumn.templateComplexity.newActTd {
	width: 120px;
}
.newColumn.uploadDetails.newActTd {
	width: 180px;
	display: flex;
	flex-direction: column;
}
.newTableParentRow .newTableRow > .newWrapper:nth-last-child(2) {
	flex-grow: 1;
}
.newTableParentRow .newTitleComment,
.newTableParentRow .newTitleCommentModuleReporter,
.newTableParentRow .newAttributes {
	flex-grow: unset;
	width: 350px;
}
.newTableParentRow .newColumn {
	-webkit-flex-shrink: 1;
	flex-shrink: 1;
}
.newTableParentRow.header .newWrapper {
	min-height: auto;
}
.imageOfTemplateResult {
	float: left;
	width: 34px;
	height: 34px;
	background-size: contain;
	margin-right: 20px;
	margin-top: 5px;
}
.templateCrowdPreviewDownload {
	color: #0078d4;
	margin-top: 4px;
}
.templateCrowdPreviewDownload span {
	text-decoration: underline;
	cursor: pointer;
}
.newTableParentRow .newActTd {
	padding: 23px 20px 20px 20px !important;
	align-self: normal;
}
.newColumn.approveAndReject.newActTd button:first-child {
	background: #0078d4;
	border: none;
	padding: 2px 9px;
	color: #fff;
	margin-right: 10px;
	border-radius: 4px;
}
.newColumn.approveAndReject.newActTd button:nth-child(2) {
	color: #0070c0;
	border: none;
	padding: 2px 16px;
	border-radius: 4px;
	background: #f3f2f1;
}
.templateChildWrapper .newTableParentRow .newTableRow > .newWrapper {
	border-bottom: none;
}
.templateReviewerParent {
	border-bottom: 1px solid #e5e5e5;
	display: flex;
	flex-direction: column;
	background: #fff;
	margin-top: 4px;
	position: relative;
	min-height: 120px;
	padding-bottom: 20px;
}
.templateCrSourcingListWrapper h4 {
	font-size: 18px;
	margin-bottom: 20px;
	margin-left: 20px;
	margin-top: 5px;
}
span.relatedTemplates {
	font-size: 11px;
	margin-left: 22px;
	cursor: pointer;
	color: #0078c0;
	position: absolute;
	bottom: 10px;
	left: 49px;
}
.templateCrowdSourcingBreadcrumb {
	position: fixed;
	background: #fff;
	width: 100%;
	z-index: 99;
}
.templateCrowdSourcingPreview {
	display: flex;
	flex-direction: row;
	background-color: #fff !important;
	padding: 2px;
}
.templateCrowdSourcingIframe {
	width: auto;
	height: calc(100vh - 3.7rem);
	border: none;
	flex: 1;
}
.templateCrowdSourcingPanelHeader {
	background: #eaedf4;
	display: flex;
}
.rejectedCommentsSection .status.rejectedStatus {
	flex: 1;
}
.templateCrowdTitleDiv {
	display: flex;
	flex-direction: column;
}
.templateSuggestedPreview,
.templateCurrentPreview {
	width: 50%;
	margin-right: 5px;
}
.templateCurrentPreview {
	margin-right: 0px !important;
}
.templateCrowdSourcingPanelHeaderDiv > div {
	display: flex;
	margin-left: auto;
}
.templateCrowdSourcingPanelHeaderDiv {
	flex: 1;
	display: flex;
	flex-direction: row;
	padding: 10px;
}
.templateCrowdSourcingPanelHeader > span {
	font-size: 14px;
	font-weight: 600;
	line-height: 29px;
}
.templateCrowdSourcingPanelHeader > div a {
	color: #0078c0 !important;
	text-decoration: underline !important;
	margin: 5px 10px 0px 0px;
	font-size: 12px;
	cursor: pointer;
}
.templateCrowdSourcingPanelHeader > div i {
	margin-left: 60px;
	font-size: 16px;
	cursor: pointer;
}
button.templatePreviewReject {
	background: #f5f5f5;
	font-size: 12px;
	padding: 0 15px;
	margin-right: 20px;
	border: 1px solid #43425d33;
	border-radius: 4px;
	margin-left: 20px;
}
button.templatePreviewApprove {
	background: #0078d4;
	color: #fff;
	border: none;
	font-size: 12px;
	padding: 0 18px;
	border-radius: 4px;
}
.approverComplexityModal .modal-body {
	padding: 40px;
}
.approverComplexityModal .modal-body .approverPublishMessage p {
	font-weight: 600;
}
.approverComplexityModal .modal-body p {
	color: #43425d;
	font-size: 14px;
	text-align: center;
}
.approverComplexityModal .modal-header {
	font-weight: 600;
}
.approverComplexityModal .modal-header i {
	font-weight: 600;
	cursor: pointer;
}
.approverComplexityModal .modal-body form {
	border: 1px solid #43425d1a;
	padding: 10px 25px;
	font-size: 13px;
	margin-bottom: 70px;
	margin-top: 40px;
	background: white;
}
.publishCancelButtons {
	text-align: center;
}
.publishCancelButtons button.btn.btn-primary {
	margin-right: 20px;
	padding: 2px 20px;
	font-size: 12px;
	background-color: #0078d4;
	border-color: #0078d4;
}
.publishCancelButtons button.btn.btn-primary:first-child {
	background: #f3f2f1;
	border: 1px solid #43425d1a;
	color: #0062ad;
}
.templateRejectModalPopUp button.btn-primary.templateRejectButton {
	background: #f3f2f1 !important;
	border: 1px solid #43425d1a;
	color: #0062ad !important;
}
.approverComplexityModal .modal-body form label.NotificationChkboxContainer {
	font-size: 13px;
	display: inline-block;
	margin-right: 45px;
}
.approverComplexityModal .modal-body form label.NotificationChkboxContainer .complexityLabel {
	position: relative;
	margin-left: 30px;
	font-size: 14px;
	display: inline-block;
	font-weight: 400;
}
.approverComplexityModal .modal-body form label.NotificationChkboxContainer .CustomCheckmark {
	top: 3px;
}
.approverComplexityModal .NotificationChkboxContainer .CustomCheckmark:after {
	top: -1px;
}
.templateRejectModalPopUp .modal-content {
	padding: 50px;
	text-align: center;
}
.templateRejectModalPopUp .modal-content p {
	font-size: 14px;
	margin-bottom: 35px;
}
.templateRejectModalPopUp .modal-content button.btn-primary {
	padding: 2px 20px;
	margin-right: 20px;
	font-size: 13px;
	background: #0078d4;
}
.templateRejectModalPopUp .modal-content button.btn-primary a {
	color: #fff !important;
	text-decoration: none !important;
}
.tempPreviewInfoIcon {
	float: left;
	width: 18px;
	height: 20px;
	margin-top: 7px;
	background: url(../../assets/infoIcon.svg) no-repeat;
	background-size: contain;
	margin-left: 10px;
	position: relative;
}
.successMsgIcon {
	float: left;
	width: 18px;
	height: 20px;
	margin-top: 1px;
	margin-right: 15px;
	background: url(../../assets/successMsgIcon.svg) no-repeat;
	background-size: contain;
}
.templateCrowdPreviewDownload a {
	color: #0078d4;
	text-decoration: underline !important;
	font-size: 12px;
	padding: 0;
	vertical-align: top;
}
.templateCrowdSourcingPanelHeader .tempPreviewInfoIcon .tabsShowMoreMenu {
	left: -51px;
	width: 350px;
	display: none;
}
.templateCrowdSourcingPanelHeader .tempPreviewInfoIcon:hover .tabsShowMoreMenu {
	display: block;
}
.templateCrowdSourcingPanelHeader .tempPreviewInfoIcon .tabsShowMoreMenu .tabsShowMoreArrow {
	left: 41px;
}
.templateCrowdSourcingPanelHeader .tempPreviewInfoIcon .tabsShowMoreMenu .menuItemUL {
	padding: 10px;
	font-size: 12px;
	font-weight: 400;
}
.approverSuccessMsg {
	border: 1px solid #00c48c;
	padding: 6px 10px;
	background: #00c48c33;
	white-space: nowrap;
	display: flex;
	margin-bottom: 10px;
	margin-left: 35px;
}
.approverSuccessMsg span {
	margin-bottom: 0;
	font-size: 12px;
}
.templateCrSourcingTitleWrapper {
	display: flex;
}
.templateCrSourcingListWrapper h4 span {
	font-size: 12px;
	font-weight: 400;
}
.statusText {
	color: darkgreen;
	margin-top: 25px;
	display: flex;
	font-weight: 600;
	margin-bottom: 0;
	float: right;
	margin-right: 25px;
}
.templateFixedHeaderPart .ms-MessageBar {
	padding: 5px;
}
a.approveAllNavigation {
	margin: 18px;
	display: inline-flex;
	color: #0070c0 !important;
}
button.templateSubmitButton.submittedforApprover {
	width: 149px;
	padding: 7px;
}
.approverPublishMessage {
	color: #0078d4;
}

.approverMessage,
.retainMessage {
	text-align: initial !important;
	color: black !important;
}
.noteMessage {
	text-align: initial !important;
	margin-top: 40px;
	color: #3191e7 !important;
}
.retainMessage {
	display: inline-block;
}
.retainTemplate .checkBoxContainer {
	display: inline-block;
	padding: 0px;
	margin-left: 15px;
}
.retainTemplate .filterChkboxContainer .checkmark {
	height: 14px;
	width: 14px;
}

.retainTemplate span.filterLabel {
	padding-left: 10px;
}

.templateApproverContainer {
	border: 1px solid #fff9f9;
	background: #e4e9ed;
	padding: 15px 60px;
}

.templateRejectModalPopUp .modal-content textarea {
	margin: 0px 5px 50px 0px;
	height: 169px;
	width: 357px;
	border: 1px solid #e4e4e4;
}
.reviewerRejectMessage p {
	font-weight: 600;
	margin-bottom: 10px !important;
}

p.reviewerRejectMessagePara {
	text-align: center;
	margin-left: 4px;
	font-weight: 400 !important;
}

.templateCrowdSourcingPreview .templateCrowdSourcingPanelHeader > div {
	margin-left: auto;
	margin-right: 10px;
}

.templateCrowdSourcingIframe {
	width: 100%;
	height: calc(100vh - 3.7rem);
	border: none;
	flex: 1 1;
}
.templateRejectModalPopUp.reviewerRejectMessage.modal-dialog {
	max-width: 900px;
}
.templateRejectModalPopUp.reviewerRejectMessage .reviwerRejectedRatingContainer {
	padding: 0;
}
.templateRejectModalPopUp.reviewerRejectMessage.modal-dialog .modal-content {
	min-height: 60vh;
}
.associateContentPanel {
	font-size: 13px;
	display: flex;
	flex-direction: column;
}
.associateContentPanel ul {
	padding: 0;
	margin-bottom: 0px !important;
	display: flex;
	flex-direction: column;
}
.associateContentPanel h6 {
	padding: 10px 10px;
}
.associateContentPanel ul li {
	list-style: none;
	float: left;
	display: flex;
	width: 100%;
	padding: 2px 1px;
	color: #0078d4;
	border-bottom: 1px solid #dee2e6;
	padding: 4px 10px;
}
.associateContentPanel ul li i {
	font-size: 22px;
	vertical-align: middle;
	margin-right: 2px;
}
.associateContentPanel a {
	color: #000 !important;
}
