.topicTutorialVideosMainDiv {
    display: flex;
    flex-direction: column;
}

.topicTutorialsVideoDiv {
    display: flex;
}

.tutorialsVideo {
    width: 100%;
    height: 361px;
}

.totorialUploadPublishedDate {
    margin-left: auto;
    margin-top: -4px;
    text-align: left;
    font: normal normal normal 12px/16px Segoe UI;
    letter-spacing: -0.12px;
    color: #212121;
    opacity: 1;
}

h4.totorialVideoTitle {
    font-weight: bold;
    margin-bottom: 3px;
}

.TutorialChildWrapperSearch {
    border: 1px solid #e4e4e4;
    display: flex;
    border-radius: 17px;
    margin: 10px 20px;
    background: #e4e4e4 !important;
}

.TutorialChildWrapperSearch input {
    border: none;
    background: #e4e4e4;
    border-radius: 10px;
}

.TutorialChildWrapperSearch span.tutorialSortIcon {
    width: 10px;
    height: 10px;
    margin-top: 3px;
}

.TopicsChildWrapper {
    padding: 5px 0;
    background: #fafafa;
}

.TutorialChildWrapperSearch .dropDowncontainer.sortDropdown {
    padding: 0;
    width: 77px;
    margin: 5px 0 0 0;
    font-size: 10px !important;
}

.topicDetailsHasttagsContainer p {
    margin-bottom: -5px;
}

.topicDetailsHasttagsContainer .hashTagsContainer {
    margin-bottom: 10px;
}

.tutorialHashtags {
    padding-bottom: 6px;
    display: block;
    white-space: normal;
    width: 100%;
    overflow: hidden;
}

.topicTutorialsVideoDetailsSection {
    display: flex;
    padding: 15px;
}

.totorialTitleDetails {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.TutorialChildWrapperSearch .dropDowncontainer.sortDropdown .dropDownList {
    width: 95px;
}

.tutorialHashtags span {
    color: #0078d4;
    margin-right: 10px;
    float: left;
}

.totorialUploaderDetails {
    display: flex;
    flex-direction: column;
    margin-top: 14px;
}

.totorialUploaderDetails div:first-child {
    display: flex;
    margin-bottom: 5px;
}

.totorialUploaderDetails h4 {
    display: inline-flex;
    margin-top: 5px;
    display: inline-flex;
    margin-top: 5px;
    font-size: 12px;
    font-style: normal;
    font-variant: normal;
    font-weight: bold;
    font-family: "Segoe UI";
    letter-spacing: -0.12px;
    color: #0078D4;
    opacity: 1;

}

.topicDescriptionDiv.tutorialDecsDiv {
    height: calc(100vh - 7.3rem);
}

.topicTutorialVideosMainDiv .existingHashTagsOfTopic {
    margin-bottom: 12px;
}

.topicsDescriptionDetails .bold-600 {
    font-weight: 600;
}

.topicsDescriptionDetails .bold-500 {
    font-weight: 500;
}

.topicsDescriptionDetails .mrg-top-25 {
    margin-top: 25px;
}

.topicsDescriptionDetails .mrg-btm-10 {
    margin-bottom: 10px;
}

.topicsRightMainContainerVideo {
    display: flex;
    transition: all 0.2s ease-in;
}

.tutorialContainer {
    position: relative !important;
}

._toolsHeading {
    text-align: left;
    font: normal normal bold 12px/16px Segoe UI;
    letter-spacing: -0.12px;
    color: #0078D4;
    opacity: 1;
}

._toolsList {
    padding-inline: 5px !important;
    text-align: left;
    font: normal normal normal 12px/18px Segoe UI;
    letter-spacing: -0.12px;
    color: #212121;
    opacity: 1;
}

._toolsList a {
    padding-inline: 5px 0px !important;
    text-align: left;
    font: normal normal normal 12px/18px Segoe UI;
    letter-spacing: -0.12px;
    color: #212121;
    opacity: 1;
}

._titleRow {
    height: 34px;
}

.topicDescriptionDiv .ratingWrapper {
    font: normal normal 600 14px/19px Segoe UI;
    letter-spacing: 0px;
    color: #888888;
    opacity: 1;
}

._verticalLine {
    width: 1px;
    height: 33px;
    background-color: #707070;
    margin-inline: 15px;
}

._ownerInfoCollaborators>div {
    margin-top: 1.5px !important;
    margin-bottom: 1.5px !important;
}

._toolsContainer {
    padding-top: 10px;
}