.logo {
	float: left;
	width: inherit;
	height: 30px;
	background-size: contain !important;
	margin-right: 8px;
	background: url(../../assets/SDMPlusLogo.svg) no-repeat;
}

@media screen and (-ms-high-contrast: active),
screen and (forced-colors: active) {
	.logo {
		float: left;
		width: 100%;
		height: 50px;
		background-size: contain;
		margin-right: 8px;
		background: url(../../assets/Logo-WhiteSDM.svg) no-repeat !important;
	}
}

.sdmNavBar.navbar {
	/* padding-bottom: 0 !important; */
	padding-bottom: 0 !important;
	line-height: normal;
	background-color: #fff !important;
	box-shadow: 0px 3px 15px -9px #0000001f;
	align-items: center;
	height: 50px;
}

.sdmNavBar #basic-navbar-nav {
	height: 50px;
}

.sdmNavBar .navbar-brand {
	color: #fff !important;
	background-color: #fff;
	color: #fff;
	font-size: 24px;
	font-weight: bolder;
	margin-right: 2px;
	transition: all 0.2s ease-in;
}

.navbar-brand.collapse {
	width: 10vw !important;
	display: flex !important;
}

.navbar-brand.expand {
	width: 300px;
	transition-duration: 200ms;
}

.navbar-expand .navbar-collapse {
	background-color: white;
}

.sdmNavBar .sdmMainMenu {
	margin: 0 !important;
}

.sdmMainMenu.navbar-nav {
	gap: 2rem;
	display: flex;
	align-items: center;
	justify-content: space-around;
	flex-direction: row !important;
	width: 32vw;
	padding-inline: 1rem;
}

.sdmMainMenu>ul {
	list-style: none;
	display: flex;
	flex-direction: row;
	padding: 0;
	margin: 0;
}

.sdmNavBar .navbar-nav a {
	white-space: nowrap;
	font: normal normal normal 13px/19px Segoe UI;
	letter-spacing: -0.14px;
	color: #324461 !important;
	opacity: 1;
	display: flex;
	align-items: center;
	height: 50px;
	box-sizing: border-box;
}

.rightSecNavBar {
	display: flex;
	float: right;
	background: white;
	margin-right: 0 !important;
}

.navSearchBox {
	display: flex;
	width: 30vw;
	/* background-color: #f5f5f5; */
	/* margin: 15px 8px 15px 0px; */
	position: relative;
	/* border-top-right-radius: 4px; */
	/* border-bottom-right-radius: 4px; */
	/* border: 1px solid #DFDFDF; */
	height: 28px;
	/* border-left: 0; */
}

.navSearchBox input {
	flex-grow: 1;
	border: none !important;
	background-color: white;
	color: #4D4F5C;
	overflow: hidden;
	font-size: 12px;
	margin-left: 10px;
	border-radius: 4px;
	;
}

.navSearchBox i {
	margin-right: 10px;
	padding: 5px 1px 1px 5px;
	font-size: 15px;
}

#navSearchBar~.globalSearchIcon {
	background: #4CB6EA 0% 0% no-repeat padding-box;
	color: white;
	padding: 7px 7px 8px 8px;
	border-bottom-right-radius: 4px;
	border-top-right-radius: 4px;
	cursor: pointer;
	margin-right: -1px;
	height: 28px;
	margin-top: -1px;
}

input#navSearchBar:-webkit-autofill,
input#navSearchBar:-webkit-autofill:hover,
input#navSearchBar:-webkit-autofill:focus,
input#navSearchBar:-webkit-autofill:active {
	-webkit-box-shadow: 0 0 0 30px white inset !important;
	font-size: 12px;
}

#searchArtifact {
	border: 1px solid #DFDFDF;
	border-radius: 4px;
	width: 145px;
}

#searchArtifact .dropdown-125 {
	border-color: #DFDFDF !important;
	border-right: 0;
	color: #4D4F5C !important;
	border-bottom-right-radius: 0;
	border-top-right-radius: 0;
}

#searchArtifact #Dropdown6-option,
#searchArtifact .title-98 {
	border-color: #DFDFDF !important;
	border-radius: 4px;
	border-width: 0;
	height: 26px;
	line-height: 26px;
	font-size: 12px;
	color: #4D4F5C;
	background-color: #F3F5FE;
}

#searchArtifact .caretDownWrapper-99 {
	line-height: 26px;
}

#searchArtifact .title-143,
#searchArtifact .title-156,
#searchArtifact .title-146,
#searchArtifact .title-147,
#searchArtifact .title-150,
#searchArtifact .title-153,
#searchArtifact .ms-Dropdown-title {
	border-color: #DFDFDF !important;
	height: 26px;
	border: 0;
	font-size: 12px;
	line-height: 26px;
}

#searchArtifact .checkbox-149,
#searchArtifact .checkbox-155,
#searchArtifact .checkbox-146,
#searchArtifact label.ms-Checkbox-label .ms-Checkbox-checkbox {
	height: 15px !important;
	width: 15px !important;
}

label.ms-Checkbox-label .ms-Checkbox-checkbox,
label.ms-Checkbox-label .ms-Checkbox-checkbox:hover {
	height: 15px !important;
	width: 15px !important;
	border-color: #CFCFCF;
}

.ms-Checkbox-checkbox:hover {
	border-color: #CFCFCF;
}

.ms-Checkbox-label {
	margin-bottom: 0;
}

.searchSection {
	display: flex;
	align-items: center;
}

.ms-Dropdown:focus,
.ms-Dropdown:after,
.dropdown-97:focus::after {
	outline: 0;
	border: 0;
}

.ms-Checkbox.is-enabled.ms-Dropdown-item {
	min-height: 28px;
}

#searchArtifact .dropdownItemSelected-154:hover .ms-Checkbox-checkbox {
	width: 15px;
	height: 15px;
}

.externalAppsIcon {
	font-size: 25px;
	color: #777;
}

.externalAppsIcon i {
	vertical-align: middle;
}

.copilotAppsIcon {
	font-size: 25px;
	color: #777;
}

.addResourcesIcon,
.notificationsIcon,
.whatsNewIcon,
.questionMark {
	font-size: 24px;
	color: #777;
}

.rightSecNavBar ul {
	margin: 0;
	padding: 0;
	display: flex;
	padding: 0rem 0.5rem 0rem 3rem;
}

.rightSecNavBar ul li {
	justify-content: center;
	list-style: none;
	float: left;
	cursor: pointer;
	align-items: flex-end;
	/* padding: 5px 8px 9px 9px; */
	/* margin-left: 5px; */
}

.sdmLogo {
	padding: 9px 0 9px 13px;
	background-color: #2b314c;
}

span#logoTextCollapse {
	padding: 0px 90px 0px 0px;
}

.rightSecNavBar ul li:hover {
	background: #ecebf1;
}

.rightSecNavBar ul li i {
	vertical-align: middle;
	font-size: 32px;
	color: #424242;
	width: 27px;
}

.userImg {
	width: 24px;
	height: 24px;
	background-size: contain;
	border-radius: 50%;
	/* margin-top: 5px; */
}

.dropdown-toggle::after {
	display: inline-block;
	margin-left: 0.255em;
	vertical-align: middle !important;
	content: '';
	border-top: 0.3em solid;
	border-right: 0.3em solid transparent;
	border-bottom: 0;
	border-left: 0.3em solid transparent;
}

.sdmMainMenu .dropright .dropdown-toggle:after {
	margin-left: 20px !important;
}

.navIconActive {
	background: #e4e4e4;
}

.rightSecNavBar ul li.navIconActive:hover {
	background: #e4e4e4;
}

.leftNavPhaseMenu li a.NavLink:hover {
	color: #f58220;
}

.sdmMainMenu a.nav-link.topMenuItemActive {
	background: #e4e4e4 !important;
}

.sdmMainMenu a.nav-link:hover {
	background: #F2F2F2 0% 0% no-repeat !important;
}

.sdmMainMenu .show.dropdown.nav-item {
	background: #e4e4e4 !important;
}

.sdmMainMenu a.nav-link.topMenuItemActive:hover {
	background: #e4e4e4 !important;
}

.sdmNavBar .show.dropdown-menu a.nav-link {
	padding: 0px !important;
}

.sdmMainMenu .show.dropdown.nav-item a.nav-link:hover {
	background: #e4e4e4 !important;
}

.sdmMainMenu .dropdown {
	display: none;
}

.sdmMainMenuMobile [title="Remote Scale PMO Playbook"],
.sdmMainMenuMobile .addDeliveryPlaybookButton,
.sdmMainMenu .addDeliveryPlaybookButton {
	display: none;
}

.sdmNavBar .dropright.nav-item a.dropdown-toggle.nav-link {
	padding: 8px !important;
}

.sdmNavBar .dropdown-item.active,
.sdmNavBar .dropdown-item:active {
	color: #333;
	text-decoration: none;
	background-color: #e4e4e4;
}

.sdmNavBar .dropdown-item:hover {
	color: #333;
	text-decoration: none;
	background-color: #e4e4e4;
}

.sdmNavBar .show.dropdown-menu a.nav-link.deliveryPlayBook.dropdown-item {
	padding: 8px !important;
}

.notificationIcon {
	float: left;
	width: 19px;
	height: 19px;
	/* margin-top: 8px; */
	background: url(../../assets/BellIcon.svg) no-repeat;
	background-size: contain;
	position: relative;
}

.announcementsIcon {
	float: left;
	width: 18px;
	height: 18px;
	margin-top: 12px;
	background: url(../../assets/Announcements.svg) no-repeat;
	background-size: contain;
}

.supportFeedbackIcon {
	float: left;
	width: 22px;
	height: 25px;
	background-size: contain;
	/* margin-top: 11px; */
	background: url(../../assets/SupportFeedback.svg) no-repeat;
}

.othertoolsIcon {
	float: left;
	width: 18px;
	height: 18px;
	/* margin-top: 12px; */
	background: url(../../assets/OtherTools.svg) no-repeat;
	background-size: contain;
}

._addResourcesIcon {
	float: left;
	width: 19px;
	height: 19px;
	/* margin-top: 12px; */
	background: url(../../assets/_addResourcesIcon.svg) no-repeat;
	background-size: contain;
}

._helpAndSupport {
	float: left;
	width: 18px;
	height: 18px;
	background: url(../../assets/_helpAndSupport.svg) no-repeat;
	background-size: contain;
}

.copilotIcon {
	float: left;
	width: 24px;
	height: 22px;
	/* margin-top: 10px; */
	background: url(../../assets/Copilot/CopilotLogo.svg) no-repeat;
	background-size: contain;
}

button.navbar-toggler.collapsed {
	background: #F3F5FE;
	border: 0;
	border-radius: 0;
}

.navbar-light .navbar-toggler {
	border: 0;
	background: #F3F5FE;
	border-radius: 0;
}

.navbar-collapse.collapse.show .rightSecNavBar ul li .othertoolsIcon,
.navbar-collapse.collapse.show .rightSecNavBar ul li ._helpAndSupport,
.navbar-collapse.collapse.show .rightSecNavBar ul li .supportFeedbackIcon,
.navbar-collapse.collapse.show .rightSecNavBar ul li .announcementsIcon,
.navbar-collapse.collapse.show .rightSecNavBar ul li .notificationIcon {
	float: left !important;
}

.navbar-collapse.collapse.show .rightSecNavBar ul {
	flex-direction: row;
}

.navbar-collapse.collapse.show .rightSecNavBar ul li {
	width: 100%;
	justify-content: center;
	display: flex;
	align-items: self-start;
}

.navbar-collapse.collapse.show .othertoolsIcon,
.navbar-collapse.collapse.show ._helpAndSupport,
.navbar-collapse.collapse.show .announcementsIcon {
	margin-top: 10px;
}

.navbar-collapse.collapse.show .notificationIcon {
	margin-top: 5px;
}

.navbar-collapse.collapse.show .navSearchBox {
	background-color: #F3F5FE;
	margin: 15px;
	/* margin-left: 0; */
}

.navbar-collapse.collapse.show .li#helpAndSupport {
	padding: 0 !important;
}

.navbar-collapse.collapse.show .navSearchBox input {
	background-color: white;
}

.navbar-collapse.collapse.show .navSearchBox i {
	padding: 5px 10px 5px 10px;
	margin: 0;
	background-color: white;
}

.navbar-collapse.collapse.show .rightSecNavBar.navbar-nav {
	box-shadow: 0 3px 10px #0000001f;
	height: auto;
}

.navbar-collapse.collapse.show .rightSecNavBar ul li i {
	float: left;
}

.navbar-collapse.collapse.show .userImg {
	float: left;
}

li#helpAndSupport {
	/* padding: 5px 5px 9px 5px !important; */
}

.sdmGlobalSearch {
	/* overflow: hidden; */
	display: flex;
	width: 100%;
	border: 1px solid #DFDFDF;
	border-radius: 4px;
}

.searchSuggestionResults {
	display: flex;
	flex-direction: column;
	position: absolute;
	width: 30vw;
	right: 0;
	top: 31px;
	background: white;
	box-shadow: 0px 3px 10px #0000001f;
	max-height: 261px;
	overflow: hidden;
	overflow-y: auto;
	z-index: 1;
}

.rightSecNavBar .searchSuggestionResults ul {
	display: block;
	margin: 0;
}

.rightSecNavBar .searchSuggestionResults ul li {
	list-style: none;
	float: left;
	cursor: pointer;
	padding: 4px 10px;
	width: 100%;
	font-size: 13px;
	line-height: 23px;
	margin-left: 0;
}

.suggestionImg {
	float: left;
	width: 22px;
	height: 25px;
	margin-right: 10px;
	background-size: contain;
}

.suggestion-title {
	text-align: left;
	width: 80%;
	display: inline-block;
}

.sdmGlobalSearch input:focus {
	outline: None !important;
}

.searchSuggestionResults .navbar-nav.nav-tabs {
	background-color: white;
}

#split-suggestion-tabs {
	background-color: white;
}

.split-suggestion-tabs-section {
	width: 100%;
}

button.ms-Button.ms-Button--action.ms-Button--command.ms-Pivot-link {
	line-height: 15px;
	height: 30px;
	font-size: 12px;
	color: #ABACAF;
	border: 0;
	margin-bottom: 5px;
}

.ms-Pivot {
	border-bottom: 1px solid #D4D4D4;
	overflow-x: auto;
}

.ms-Label {
	/* padding: 8px; */
}

.ms-Label>li>span {
	font-weight: normal !important;
}

button.ms-Button.ms-Button--action.ms-Button--command.ms-Pivot-link.is-selected {
	color: #4D4F5C;
	border-bottom: 2px solid #ABACAF;
}

button.ms-Button.ms-Button--action.ms-Button--command.ms-Pivot-link.is-selected:before {
	content: none;
}

.loadingSpinner .spinner-border-sm {
	width: 15px;
	height: 15px;
	border-width: 2px;
	margin-top: 3px;
}

.loadingSpinner .spinner-border {
	display: inline-block;
	vertical-align: unset;
	border: 2px solid #f58220;
	border-right-color: transparent;
	border-radius: 50%;
	background-color: transparent;
	animation: spinner-border 0.75s linear infinite;
}

input[type='text']::-ms-clear {
	display: none;
}

span.notificationCount {
	position: absolute;
	background: #ee0000;
	width: 15px;
	height: 15px;
	border-radius: 50%;
	float: right;
	display: flex;
	justify-content: center;
	right: -3px;
	top: -4px;
}

span.notificationCount span {
	font-size: 10px;
	text-align: center;
	color: #fff;
}

a.sdmLogo:focus {
	outline: 1px dotted #fff !important;
	outline-offset: -1px;
}

.sdmNavBar .navbar-nav a:focus {
	outline: 1px dotted #333;
}

.rightSecNavBar ul li:focus {
	outline: 1px dotted #333;
	outline-offset: -1px;
}

.rightSecNavBar .custom-control.custom-switch:focus {
	outline: 1px dotted #333;
}

.navbar-collapse.collapse.show #searchArtifact {
	margin-left: 15px;
}

.navSearchBox.inputError {
	border: 1px solid red;
}

.full-screen-icon {
	background: url(../../assets/icon-expand.svg) no-repeat;
	position: absolute;
	right: 5px;
	margin-top: 10px;
	height: 13px;
	width: 15px;
	cursor: pointer;
}

.inputErrorMsg i {
	font-size: 10px !important;
	color: red !important;
}

.inputErrorMsg {
	color: red !important;
	padding: 10px;
	cursor: default;
}

.searchSuggestionResultsTabsMenu ul {
	display: flex !important;
}

.searchSuggestionResultsTabsMenu {
	display: flex;
}

.rightSecNavBar .searchSuggestionResultsTabsMenu ul li {
	padding: 5px 47px !important;
	width: 50%;
	flex: 1;
	background: #fff;
}

ul.searchSuggestionsHashtags li {
	display: flex;
}

ul.searchSuggestionsHashtags li span:first-child {
	flex: 1;
}

ul.searchSuggestionsHashtags li span:last-child {
	font-size: 12px;
}

li.selectedGlobalHashtagTab {
	background: #0078d4 !important;
	color: #fff;
}

ul.searchSuggestionsHashtags {
	background: #fff;
	margin-bottom: 10px !important;
}

ul.searchSuggestionKeywords {
	background: #fff;
}

span.announcementsCount span {
	font-size: 10px;
	text-align: center;
	color: #fff;
}

span.announcementsCount {
	position: absolute;
	background: #ee0000;
	width: 15px;
	height: 15px;
	border-radius: 50%;
	float: right;
	display: flex;
	justify-content: center;
	right: -10px;
	top: -10px;
}

.announcementsIcon {
	position: relative;
}

.dropdownmenu.right li a.dropdownLink {
	overflow: hidden;
	text-overflow: ellipsis;
}

.sdmMainMenuMobile.navbar-nav {
	display: none;
}

.hashtagHeading {
	display: flex;
	font-size: 12px;
	padding: 4px 8px;
	background-color: white;
}

.hashtagHeading span.tags {
	flex: 1 1;
	font-weight: bold;
	color: #4D4F5C;
}

.hashtagHeading span.resultsFound {
	color: #BFBFBF;
	background-color: white;
}

.errMsg {
	color: #4D4F5C;
	text-align: center;
	font-size: 13px;
	padding: 20px;
}

.navbar-collapse.collapse.show .rightSecNavBar #split-suggestion-tabs li {
	display: block;
}

@media screen and (max-width: 820px) {
	.sdmMainMenuMobile.navbar-nav {
		width: 60vw;
		gap: 10px;
		align-items: center;
		display: flex;
		flex-direction: row;
		margin-left: 10px;
		margin-right: 0px !important;
		justify-content: center;
	}

	.sdmMainMenu.navbar-nav {
		display: none;
	}
}

@media screen and (max-width: 456px) {
	#navbar-dropdown-6 ul#More.dropdownmenu {
		height: 90px;
		width: 100px;
		overflow: auto;
	}
}

/* Changes to highlight RaciGrid when active */
#topMenu_5:focus,
#topMenuMob_5:focus {
	background: #e4e4e4 !important;
}

#topMenuMob_5.nav-link.topMenuItemActive {
	background: #e4e4e4 !important;
}

/* Changes to highlight RaciGrid when active */
.recentSearchHeading {
	padding: 10px 10px 2px 10px;
	margin-left: 0 !important;
	color: #4D4F5C;
	font-size: 12px;
	font-weight: 600;
	font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
	height: 30px;
	line-height: 15px;
}

.recentSearchList {
	transition: background 0s ease;
	color: #4D4F5C;
	margin-inline: 0px !important;
	padding-inline: 16px !important;
	position: relative;
}

.recentSearchList:hover {
	background: #ecebf1 !important;
	padding-right: 25px !important;
}

.HistoryIcon {
	float: left;
	width: 29px;
	height: 29px;
	background: url(../../assets/HistoryIcon.svg) no-repeat;
	background-size: contain;
	cursor: pointer;
}

.closeButton {
	display: none;
	position: absolute;
	right: -7px;
	top: 4%;
	cursor: pointer;
	font-size: 22px;
	color: #757171;
	border-radius: 50%;
	background: #ecebf1;
	width: 40px;
	padding-inline: 5px;
}

.recentSearchList:hover .closeButton {
	display: inline;
}

.closeButton:hover {
	font-weight: 600;
	font-size: 28px;
}

.searchinputFocus {
	outline: none;
}

.searchinputFocus:focus {
	padding: 1px;
	border: 1px solid #253B62;
}


.navbar {
	padding: 0px 10px 0px 10px !important;
}

._rightSideTopMenu {
	gap: 10px;
	padding-inline: 10px;
}


._rightSideTopMenu li {
	display: flex;
	align-items: center !important;
	width: 32px;
	height: 50px;
	box-sizing: border-box;
}

._navbarRightSideIncludingSearchBox {
	gap: 2rem;
	justify-content: flex-end;
}

._searcchListItem {
	padding-inline: 0px !important;
}